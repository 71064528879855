import './PersonTypeAccountSummary.css';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowForwardIos } from '@material-ui/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import {
  envars,
  getDecodedCookie,
  modifyCookie,
  setCookie,
} from '../../../../shared/utils/functions';
import { GET_PERSONTYPES } from '../../../../shared/graphQL/queries';
import {
  AZURE_SESSION_TIME_OUT,
  PERSONTYPE,
} from '../../../../shared/constants/gobal-constants';
import { NewPotencialCookieConfig } from '../../../../components/NewClientePotencial/cookieConfig';
import jwt_encode from 'jwt-encode';

export default function PersonTypeAccountSummary() {
  const [accountType, setAccountType] = useState(null);
  const [reload, setReload] = useState(false);
  const { loading, error, data } = useQuery(GET_PERSONTYPES);

  const [animationOut, setAnimationOut] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('creatingAccount', true);
  }, [data]);

  useEffect(() => {
    const email = sessionStorage.getItem('email');

    if (email) {
      // Creo la cookie donde almacenare la info
      const config = new NewPotencialCookieConfig(email, false);
      const configCoded = jwt_encode(config, envars('SECRETINNERJWT'));
      const timeout = AZURE_SESSION_TIME_OUT;
      setCookie('potencialCustomerConfigRC', configCoded, {
        'max-age': timeout,
        path: '/',
      });
      // setCookie('potencialCustomerConfigRC', configCoded, { 'max-age': 45 * 60 * 1000, path: '/' });

      // Uso la cookie
      const configDecoded = getDecodedCookie('potencialCustomerConfigRC');
      //console.log("Config: ");

      if (configDecoded && configDecoded?.accountType) {
        setReload(true);
        setAccountType(configDecoded?.accountType);
      }
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener los tipos de cuentas', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (accountType && !reload) {
      goTo();
    }
  }, [accountType, reload]);

  const goTo = () => {
    // alert(JSON.stringify(accountType));
    modifyCookie(
      'potencialCustomerConfigRC',
      'accountType',
      accountType,
      true,
      45 * 60 * 1000
    );
    !PERSONTYPE[accountType?.name]?.isLegal &&
      modifyCookie(
        'potencialCustomerConfigRC',
        'assistUnit',
        '',
        true,
        45 * 60 * 1000
      );
    setAnimationOut(true);
    setTimeout(() => {
      navigate(
        `/onboarding-digital/dashboard/apertura-de-cuenta/unidad-de-asistencia`
      );
    }, 500);
    // navigate(`/onboarding-digital/${PERSONTYPE[accountType.name].isLegal ? 'assistance-unit' : 'assistance-unit'}`);
  };

  return (
    <div className="accountSumary">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <div className="vAlign person-type-container">
          <h3 className="formTitle">¿Cómo desea invertir?</h3>
          {!loading && !error && data && data?.personTypeList && (
            <div className="cards-container-summary-account">
              {data?.personTypeList?.map((p) => (
                <div
                  key={p?.id}
                  className={`boxButton card-element`}
                  onClick={() => {
                    setReload(false);
                    setAccountType(p);
                  }}
                >
                  <div className="flexLeft">
                    <div className="iconContainer">
                      <img src={PERSONTYPE[p?.name].icon} alt="" />
                    </div>
                    <div>
                      <h4 className="rwf-card-title">{p?.name}</h4>
                      <p className="card-text">{PERSONTYPE[p?.name].desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {loading && (
            <div className="w-100 d-flex justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
