import './ModalInvestorProfile.css';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

const ModalInvestorProfile = ({ title, message }) => {
  const [showModal, setShowModal] = useState(false);
  const separateParagraphs = message.split('\n');

  return (
    <>
      {/* <button type="button" className="btn-modal-info" data-bs-toggle="modal" data-bs-target="#exampleModal">
        <FontAwesomeIcon icon={faInfo} /> 
      </button> */}

      <button
        type="button"
        className="btn-modal-info"
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faInfo} />
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header investor-profile-modal-header">
              <h5 className="modal-title mip-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {separateParagraphs.map((paragraph, index) => (
                <p className="mip-text" key={index}>
                  {paragraph}
                </p>
              ))}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal-parval-info-container"
        style={{ display: showModal ? 'block' : 'none' }}
      >
        <div
          className="modal-parval-info"
          style={{ display: showModal ? 'block' : 'none' }}
        >
          <div className="modal-header-parval-info">
            <h5 className="modal-title mip-title" id="exampleModalLabel">
              {title}
            </h5>
          </div>

          <div className="modal-body-parval-info">
            {separateParagraphs.map((paragraph, index) => (
              <p className="mip-text" key={index}>
                {paragraph}
              </p>
            ))}
          </div>

          <div className="modal-buttons-parval-info">
            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button> */}
            <button
              type="button"
              className="btn btn-secondary button-modal-parval-info"
              onClick={() => setShowModal(false)}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalInvestorProfile;
