import './SourceOfFundsPage.css';
import React, { useState } from 'react';
import ProgressBar from '../../../../../components/ClientePotencial/ProgressBar/ProgressBar';
import ProfessionalClientForm from '../../../../../components/ClientePotencial/forms/Physical person/ProfessionalClientForm/ProfessionalClientForm';
import SourceOfFundsForm from '../../../../../components/ClientePotencial/forms/Physical person/SourceOfFundsForm/SourceOfFundsForm';

const SourceOfFundsPage = () => {
  const [validateForm, setValidateForm] = useState(false);
  const [goToBack, setGoToBack] = useState(false);

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  const handleFormValidation = () => {
    setValidateForm(true);
  };

  const handleBackNavigation = () => {
    setGoToBack(true);
  };

  return (
    <div className="page-container" id="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="bff-title" style={{ fontWeight: '800' }}>
          Fuentes de ingreso u origen de los fondos
        </h3>
        <h4>
          Debe suministrarnos el soporte documental del origen de los fondos
          seleccionados
        </h4>

        <SourceOfFundsForm
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          goToBack={goToBack}
        />

        <ProgressBar
          handleClick={handleFormValidation}
          handleBackNavigation={handleBackNavigation}
          width={'10%'}
          percentage={'10%'}
        />
      </div>
    </div>
  );
};

export default SourceOfFundsPage;
