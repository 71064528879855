import React, { useEffect, useState } from 'react';
import './ProgressMeter.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  GET_COMPANY_TYPES,
  GET_FORM_RESPONSE_STATUS_LIST,
  GET_INVESTMENT_PROFILE_SECTION,
  GET_RISK_ENGINE_PARAMETERS,
  GET_RISK_EVALUATION,
  GET_RISK_LEVEL_LIST,
  VALIDATE_FORM,
} from '../../graphQL/queries';
import {
  setIsFormCompleted,
  setPerfilObtenido,
  setRequestFormComplete,
  setRequestFormCompletionLoading,
  setValidation,
} from '../../store/slices/clientePotencial';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { CircularProgress, LinearProgress } from '@mui/material';
import {
  GET_KNOW_YOUR_CLIENT_PDF,
  SEND_COMPLETED_FORM_NOTIFICATION,
  UPDATE_ACCOUNT_STATUS_IN_FORMRESPONSE,
  UPDATE_FORM_RESPONSE_RISK_LEVEL,
} from '../../graphQL/mutations';
import { base64toFile, setErrorNotify, setSuccessNotify } from '../../utils/functions';
import ConfirmationModal from '../../../components/ClientePotencial/ConfirmationModal/ConfirmationModal';
import { WarningTooltip } from '../Tooltips/Tooltips';

const ProgressMeter = ({ isInAccount }) => {
  const [generateKnowYourClientPDF] = useLazyQuery(GET_KNOW_YOUR_CLIENT_PDF, {
    fetchPolicy: 'network-only',
  });
  const [getInvestmentProfileSectionData, investmentProfileSectionData] =
    useLazyQuery(GET_INVESTMENT_PROFILE_SECTION);
  const [validateForm, validateFormData] = useLazyQuery(VALIDATE_FORM);
  const [getRiskEvaluation] = useLazyQuery(GET_RISK_EVALUATION);
  const [getFormResponseStatusList] = useLazyQuery(GET_FORM_RESPONSE_STATUS_LIST);
  const [updateFormResponseRiskLevel] = useMutation(
    UPDATE_FORM_RESPONSE_RISK_LEVEL
  );
  const [updateAccountStatusInFormResponse] = useMutation(
    UPDATE_ACCOUNT_STATUS_IN_FORMRESPONSE
  );
  const [sendNotification] = useMutation(SEND_COMPLETED_FORM_NOTIFICATION);
  const {
    perfilObtenido,
    isFormCompleted,
    formResponse,
    hasInvestmentProfile,
    validation,
    requestFormComplete,
    isFormDisqualified,
    requestFormCompletionLoading,
  } = useSelector((state) => state.clientePotencialSlice);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [finalProcess, setFinalProcess] = useState(false);
  const [declarationResponse, setDeclarationResponse] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (formResponse) {
      getInvestmentProfileSectionData({
        variables: {
          formResponseId: formResponse?.id,
        },
      });

      validateForm({
        variables: {
          formResponseId: formResponse?.id,
        },
      });
    }
  }, [formResponse]);

  useEffect(() => {
    if (validateFormData.data?.validateForm) {
      const validations = validateFormData.data?.validateForm;
      let completedSections = 0;
      let sections = 0;
      for (const key in validations) {
        if (
          key !== 'clientErrors' &&
          key !== 'isValid' &&
          key !== '__typename'
        ) {
          sections++;
          if (!validations[key].length) {
            completedSections++;
          }
        }
      }
      
      setProgress((completedSections / sections) * 100);
      if (validation) {
        dispatch(setValidation(validations));
      }
    }
  }, [validateFormData]);

  useEffect(() => {
    if (investmentProfileSectionData?.data?.investmentProfileSection) {
      const profile =
        investmentProfileSectionData?.data?.investmentProfileSection
          ?.investmentProfile?.name;
      const profileType =
        investmentProfileSectionData?.data?.investmentProfileSection
          ?.investmentProfileType?.name;
      dispatch(setPerfilObtenido(profile + ' - ' + profileType));
    }
  }, [investmentProfileSectionData]);

  const generateAndDownloadForm = async () => {
    setGenerateLoading(true);
    const response = await generateKnowYourClientPDF({
      variables: {
        formResponseId: formResponse?.id,
      },
    });
    if (response.error?.message) {
      toast('Hubo un error al generar y obtener el formulario', {
        type: 'error',
        theme: 'colored',
      });
    } else {
      const file = base64toFile(
        response.data?.knowYourClientDownloadPDF,
        'Conozca-su-cliente.pdf'
      );
      const urlFile = URL.createObjectURL(file);
      if (urlFile) {
        const iframe = document.createElement('iframe');
        iframe.src = urlFile;
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
      }
    }
    setGenerateLoading(false);
  };

  const completeForm = async () => {
    if (progress < 100) {
      const validation = await validateForm({
        variables: {
          formResponseId: formResponse?.id,
        },
      });

      dispatch(setValidation(validation?.data?.validateForm));
      toast(
        'El formulario esta incompleto, por favor revisar los campos resaltados.',
        {
          theme: 'colored',
          type: 'error',
        }
      );
    } else {
      setFinalProcess(true);
    }
  };

  useEffect(() => {
    if (declarationResponse) {
      riskEvaluation();
    }
    setFinalProcess(false);
    setDeclarationResponse(null);
  }, [declarationResponse]);

  useEffect(() => {
    if(requestFormComplete){
      completeForm();
      dispatch(setRequestFormComplete(false));
    }
  }, [requestFormComplete])

  const riskEvaluation = async () => {
    dispatch(setRequestFormCompletionLoading(true))
    try {
      const riskEvaluationResponse = await getRiskEvaluation({
        variables: {
          formResponseId: formResponse?.id,
        }
      });

      const riskLevel = riskEvaluationResponse.data
        ?.riskEngineEvaluation?.riskLevelSearched;

      if (riskLevel) {
        await updateFormResponseRiskLevel({
          variables: {
            formResponseId: formResponse?.id,
            riskLevelId: riskLevel.id || '',
          },
        });

        const completeStatusId = (await getFormResponseStatusList()).data?.formResponseStatusList
        ?.find((status) => status.name === 'Completado por el cliente')?.id;

        await updateAccountStatusInFormResponse({
          variables: {
            formResponseId: formResponse?.id,
            formResponseStatusId: completeStatusId,
          },
        });

        dispatch(setIsFormCompleted(true));

        await sendNotification({
          variables: {
            formCompletedNotification: {
              formResponseId: formResponse?.id,
            },
          },
        });

        setSuccessNotify('El formulario ha sido completado exitosamente');
      } else {
        setErrorNotify('Hubo un error al completar el proceso');
      }
    } catch (error) {
      console.error('Hubo un error al completar el proceso', error);
      setErrorNotify('Hubo un error al completar el proceso');
    }
    dispatch(setRequestFormCompletionLoading(false));
  };

  return (
    <div className="progress-meter-card">
      {finalProcess ? (
        <ConfirmationModal declarationResponse={setDeclarationResponse} />
      ) : null}
      <div className={isInAccount ? "contProgress" : "contProgress-no-account"}>
        <div className="row" style={{ width: '100%', marginLeft: 0 }}>
          <div className="col-6 formStatus" style={{ padding: 0 }}>
            {isFormCompleted && isInAccount ? <p>Completado</p> : isFormDisqualified && isInAccount ? <p>Descalificado</p> : <p>En Progreso</p>}
          </div>
        </div>
        <p
          className="p-0"
          style={{
            fontSize: 12,
            textAlign: 'end',
            marginTop: '-30px',
            marginBottom: '-5px',
          }}
        >
          {isInAccount ? progress.toFixed(0) : 0}%
        </p>
        <div className="progress-bars-container">
          <div className="progress-bar" style={{ width: '100%' }}>
            <div
              className="progres-bard-content"
              style={{ width: isInAccount ? `${progress}%` : '0%' }}
            ></div>
          </div>
        </div>
        {isInAccount && !isFormDisqualified ? <div className="contRevision">
          {requestFormCompletionLoading ? (
            <LinearProgress
              style={{
                width: 'calc(100% - 35px)',
                borderRadius: 5,
                marginRight: 10,
              }}
              color="warning"
            />
          ) : (
            !isFormCompleted ? <WarningTooltip title={'¿Quiéres finalizar el llenado del formulario?'} placement="bottom" arrow>
              <button
                disabled={isFormCompleted}
                className={
                  'btnOutline' + (isFormCompleted ? '' : ' btnFormNotCompleted')
                }
                type="button"
                onClick={() => dispatch(setRequestFormComplete(true))}
              >
                Finalizar Proceso
              </button>
            </WarningTooltip> :
            <button
              disabled={isFormCompleted}
              className={
                'btnOutline' + (isFormCompleted ? '' : ' btnFormNotCompleted')
              }
              type="button"
              onClick={() => dispatch(setRequestFormComplete(true))}
            >
              Finalizar Proceso
            </button>
          )}
          {!generateLoading ? (
            <p className="iconPrint" onClick={generateAndDownloadForm}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="21"
                viewBox="0 0 17 21"
                fill="none"
              >
                <path
                  d="M14.8219 4.10166H12.4512V1.42088C12.4512 1.25682 12.3316 1.11572 12.1656 1.11572H4.83438C4.66836 1.11572 4.54883 1.25682 4.54883 1.42088V4.10166H2.17812C1.28164 4.10166 0.564453 4.8301 0.564453 5.71604V11.1498C0.564453 12.0357 1.28164 12.7642 2.17812 12.7642H3.88477V15.3793C3.88477 15.5434 4.0043 15.6845 4.17031 15.6845H12.833C12.999 15.6845 13.1186 15.5434 13.1186 15.3793V12.7642H14.8252C15.7217 12.7642 16.4389 12.039 16.4389 11.1498V5.71604C16.4355 4.8301 15.7184 4.10166 14.8219 4.10166ZM5.14648 1.70635H11.8535V4.10166H5.14648V1.70635ZM12.5176 15.0938H4.48242V12.4623V9.84385H12.5176V12.4623V15.0938ZM15.8379 11.1498C15.8379 11.7109 15.3896 12.1735 14.8219 12.1735H13.1152V9.54854C13.1152 9.38447 12.9957 9.25322 12.8297 9.25322H4.17031C4.0043 9.25322 3.88477 9.38447 3.88477 9.54854V12.1735H2.17812C1.61035 12.1735 1.16211 11.7109 1.16211 11.1498V5.71604C1.16211 5.15494 1.61035 4.69229 2.17812 4.69229H4.83105H12.1656H14.8186C15.3863 4.69229 15.8346 5.15494 15.8346 5.71604V11.1498H15.8379Z"
                  fill="#B4B4B4"
                />
                <path
                  d="M13.3876 6.20166H10.313C10.147 6.20166 10.0142 6.33291 10.0142 6.49697C10.0142 6.66104 10.147 6.79229 10.313 6.79229H13.3876C13.5536 6.79229 13.6864 6.66104 13.6864 6.49697C13.6864 6.33291 13.5536 6.20166 13.3876 6.20166Z"
                  fill="#B4B4B4"
                />
                <path
                  d="M6.1626 11.4847H7.52061C7.68662 11.4847 7.81943 11.3534 7.81943 11.1894C7.81943 11.0253 7.68662 10.894 7.52061 10.894H6.1626C5.99658 10.894 5.86377 11.0253 5.86377 11.1894C5.86377 11.3534 5.99658 11.4847 6.1626 11.4847Z"
                  fill="#B4B4B4"
                />
                <path
                  d="M10.5056 12.2065H6.1626C5.99658 12.2065 5.86377 12.3378 5.86377 12.5019C5.86377 12.6659 5.99658 12.7972 6.1626 12.7972H10.5089C10.6749 12.7972 10.8077 12.6659 10.8077 12.5019C10.8077 12.3378 10.6716 12.2065 10.5056 12.2065Z"
                  fill="#B4B4B4"
                />
                <path
                  d="M10.5056 13.519H6.1626C5.99658 13.519 5.86377 13.6503 5.86377 13.8144C5.86377 13.9784 5.99658 14.1097 6.1626 14.1097H10.5089C10.6749 14.1097 10.8077 13.9784 10.8077 13.8144C10.8077 13.6503 10.6716 13.519 10.5056 13.519Z"
                  fill="#B4B4B4"
                />
              </svg>
            </p>
          ) : (
            <CircularProgress style={{ color: '#C4C4C4' }} size={30} />
          )}
        </div> : null}
      </div>
      {hasInvestmentProfile && isInAccount ? (
        <div className="inversionista mb-2">
          <strong className="mb-1">Inversionista:</strong>
          {perfilObtenido && perfilObtenido !== ''
            ? perfilObtenido
            : 'No Profesional | Conservador'}
        </div>
      ) : null}
    </div>
  );
};

export default ProgressMeter;
