import './AffidavitProvisionInformationForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

const AffidavitProvisionInformationForm = ({
  validateForm,
  setValidateForm,
  goToBack,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const acceptsTermsAndConditions = watch('acceptsTermsAndConditions');

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-juridica/tarjeta-de-firmas');
    }
  }, [goToBack]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveData = () => {};

  return (
    <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-12 columns">
          <div className="text-container">
            <p>
              Por medio del presente documento declaro (declaramos) bajo fe de
              juramento que todos los dineros, capitales, haberes, valores o
              títulos utilizados en los negocios realizados con o a través de
              Parallax Valores Puesto de Bolsa, S. A., no tienen relación alguna
              con actividades producto del delito de legitimación de capitales,
              y por lo tanto no guardan vinculación con actividades ilícitas,
              así como tampoco proceden de ningún delito previsto en cualquier
              ley penal vigente. Además afirmo (afirmamos) que todas las
              informaciones declaradas en este formulario son ciertas y autorizo
              (autorizamos) a Parallax Valores Puesto de Bolsa, S. A. a
              comprobar, por cualquier medio, la veracidad de las mismas. El
              (los) firmante (s) (&quot;el Cliente&quot;) declara haber leído,
              comprendido y expresamente acepta los términos y condiciones
              generales de contratación de Parallax Valores Puesto de Bolsa, S.
              A. El Cliente además ratifica su intención de someter los
              contratos y operaciones efectuadas con o a través de Parallax
              Valores Puesto de Bolsa, S. A., a arbitraje institucional de
              conformidad con las disposiciones establecidas en la Ley 50-87 de
              fecha 4 de junio de 1987 sobre Cámara de Comercio y Producción y
              en el Reglamento del Consejo de Conciliación y Arbitraje de la
              Cámara de Comercio y Producción de Santo Domingo, vigente a la
              fecha de suscripción del presente documento. El Cliente deberá
              mantener actualizada la información requerida en el presente
              formulario durante la relación comercial con Parallax Puesto de
              Bolsa, S.A., como condición previa para ejecutar cualquier
              transacción o cuando se evidencia un hecho que haga percibir un
              cambio en la trayectoria de actividades o manejo de la cuenta por
              parte de El Cliente. El Cliente autoriza a Parallax Valores Puesto
              de Bolsa, S. A. a suministrar y solicitar a las Sociedades de
              Información Crediticia (SIC) conforme a las disposiciones de la
              Ley 172-13, la información crediticia disponible. El Cliente da fe
              que completó el presente formulario en forma personal, en plena
              facultad de sus condiciones y sin coacción alguna.
            </p>

            <label htmlFor="accepts-conditions" className="label-field">
              <input
                type="checkbox"
                className="me-2"
                id="accepts-conditions"
                {...register('acceptsTermsAndConditions', {
                  required: true,
                })}
              />{' '}
              <span className="term-conditions-text">
                Declaro haber leído, comprendido y expresamente acepto los
                términos y condiciones generales de contratación de Parallax
                Valores Puesto de Bolsa, S. A. *
              </span>
            </label>
            {errors.acceptsTermsAndConditions && (
              <p className="text-danger">
                Debe marcar la casilla si esta de acerdo con los generales de
                contratación de Parallax Valores Puesto de Bolsa, S. A.
              </p>
            )}
          </div>
        </div>
      </div>

      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default AffidavitProvisionInformationForm;
