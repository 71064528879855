import './TitleOperationsFpForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const TitleOperationsFpForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [fatcaProxyOptions, setFatcaProxyOptions] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  const ahorroSalario = watch('ahorroSalario');
  const inversionesAnteriores = watch('inversionesAnteriores');
  const negocioPropio = watch('negocioPropio');
  const herencia = watch('herencia');
  const salariosOingresosActuales = watch('salariosOingresosActuales');
  const fondoPension = watch('fondoPension');
  const ventasActivos = watch('ventasActivos');

  //   useEffect(() => {
  //     if(ahorroSalario || inversionesAnteriores || negocioPropio || herencia || salariosOingresosActuales || fondoPension || ventasActivos) {
  //       setDisabledButton(false);
  //     }
  //     else {
  //       setDisabledButton(true);
  //     }

  //   }, [ahorroSalario, inversionesAnteriores, negocioPropio, herencia, salariosOingresosActuales, fondoPension, ventasActivos]);

  useEffect(() => {
    //getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveDataInBD = () => {
    // navigate('/formularios/persona-fisica/resultado');
  };

  const addRemoveTitleOperations = () => {};

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div
        className="w-100 d-flex justify-content-start px-5 mb-5"
        style={{ height: 40 }}
      >
        <h3 className="formTitle" style={{ color: '#3d675e' }}>
          Operaciones de títulos valores y financieros
        </h3>
      </div>

      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <div className="sof-options-container">
            <label htmlFor="BonosBcrd" className="label-field me-1">
              <input
                type="checkbox"
                id="BonosBcrd"
                value="Bonos BCRD / Hacienda"
                onClick={() =>
                  addRemoveTitleOperations('Bonos BCRD / Hacienda')
                }
                {...register('BonosBcrd')}
              />{' '}
              Bonos BCRD / Hacienda
            </label>

            <label
              htmlFor="fondosAbiertosCerrados"
              className="label-field me-1"
            >
              <input
                type="checkbox"
                id="fondosAbiertosCerrados"
                value="Fondos mutuos"
                onClick={() =>
                  addRemoveTitleOperations('Fondos Abiertos y Cerrados')
                }
                {...register('fondosAbiertosCerrados')}
              />{' '}
              Fondos Abiertos y Cerrados
            </label>

            <label htmlFor="operacionesAPlazo" className="label-field me-1">
              <input
                type="checkbox"
                id="operacionesAPlazo"
                value="Operaciones a Plazo (Forwards)"
                onClick={() =>
                  addRemoveTitleOperations('Operaciones a Plazo (Forwards)')
                }
                {...register('operacionesAPlazo')}
              />{' '}
              Operaciones a Plazo (Forwards)
            </label>

            <label htmlFor="prestamosDeTitulos" className="label-field me-1">
              <input
                type="checkbox"
                id="prestamosDeTitulos"
                value="Préstamos de Títulos Valores (Mutuo)"
                onClick={() =>
                  addRemoveTitleOperations(
                    'Préstamos de Títulos Valores (Mutuo)'
                  )
                }
                {...register('prestamosDeTitulos')}
              />{' '}
              Préstamos de Títulos Valores (Mutuo)
            </label>

            <label htmlFor="acciones" className="label-field me-1">
              <input
                type="checkbox"
                id="acciones"
                value="acciones"
                onClick={() => addRemoveTitleOperations('Acciones')}
                {...register('acciones')}
              />{' '}
              Acciones
            </label>
          </div>
        </div>

        <div className="col-md-5 col-sm-12">
          <div className="sof-options-container">
            <label htmlFor="contratosDeReportos" className="label-field me-1">
              <input
                type="checkbox"
                id="contratosDeReportos"
                value="Contratos de Reportos (Repos)"
                onClick={() =>
                  addRemoveTitleOperations('Contratos de Reportos (Repos)')
                }
                {...register('contratosDeReportos')}
              />{' '}
              Contratos de Reportos (Repos)
            </label>

            <label htmlFor="bonosCorporativos" className="label-field me-1">
              <input
                type="checkbox"
                id="bonosCorporativos"
                value="Bonos Corporativos/Papeles Comerciales"
                onClick={() =>
                  addRemoveTitleOperations(
                    'Bonos Corporativos/Papeles Comerciales'
                  )
                }
                {...register('bonosCorporativos')}
              />{' '}
              Bonos Corporativos/Papeles Comerciales
            </label>

            <label htmlFor="derivadosDeCobertura" className="label-field me-1">
              <input
                type="checkbox"
                id="derivadosDeCobertura"
                value="Derivados de Cobertura (No especulativos)"
                onClick={() =>
                  addRemoveTitleOperations(
                    'Derivados de Cobertura (No especulativos)'
                  )
                }
                {...register('derivadosDeCobertura')}
              />{' '}
              Derivados de Cobertura (No especulativos)
            </label>

            <label htmlFor="prestamoDeMargen" className="label-field me-1">
              <input
                type="checkbox"
                id="prestamoDeMargen"
                value="Préstamo de margen"
                onClick={() => addRemoveTitleOperations('prestamoDeMargen')}
                {...register('prestamoDeMargen')}
              />{' '}
              Préstamo de margen
            </label>
          </div>
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="submit"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default TitleOperationsFpForm;
