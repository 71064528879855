import {
  DeleteOutline,
  FileUploadOutlined,
  VisibilityOutlined,
  // Autorenew,
  // PublishedWithChanges,
  // Draw,
  CheckCircleOutlined,
  InfoOutlined,
  ErrorOutline,
} from '@mui/icons-material';
import axios from 'axios';
import { CircularProgress, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  REMOVE_DOCUMENT,
  // REMOVE_SIGNATURE_PROCESS,
  SAVE_DOCUMENT_SECTION,
  SAVE_FILE_MANAGER_CLIENT,
  // SAVE_SIGNATURE_PROCESS,
} from '../../../shared/graphQL/mutations';
import {
  // envars,
  getCookie,
  setErrorNotify,
  setFileManagerToken,
  setSuccessNotify,
} from '../../../shared/utils/functions';
import './document.css';
import {
  // GENERATED_BROKERAGE_CONTRACT_PDF,
  // GENERATED_CEVALDOOM_CONTRACT_PDF,
  // GENERATED_KNOW_YOUR_CLIENT_PDF,
  GET_SIGNATURE_PROCESS_BY_DOCUMENT,
  GET_VERIFICATION_MECHANISM_LIST,
} from '../../../shared/graphQL/queries';
// import { brokerageContractConfigToSign, cevaldomContractConfigToSign, formatSignatureProcess, knowYourClientConfigToSign } from '../../../shared/utils/viafirmaUtils';
import { useSelector } from 'react-redux';
import {
  ErrorTooltip,
  InfoTooltip,
  SuccessTooltip,
} from '../../../shared/UIElements/Tooltips/Tooltips';
// import { Buffer } from 'buffer';

const ClientPersonTypeName = {
  Fisica: 'Fisica',
  Juridica: 'Juridica',
};

const ClientAccountTypeName = {
  Individual: 'Individual',
  Mancomunada: 'Mancomunada',
};

// const SelfGeneratedDocuments = {
//     knowYorClientForm: 'Formulario conozca su cliente',
//     cevaldomContract: 'Contrato CEVALDOM',
//     brokerageContract: 'Contrato de corretaje',
// }

const FileManagerOffice = {
  'Oficina Abraham Lincoln': 5,
  'Oficina San Francisco de Macorís': 6,
  'Oficina Santiago de los Caballeros': 7,
  'Oficina Alameda - Tesorería': 10,
  'Oficina La Romana': 9,
};

export default function Document({
  status,
  documentSection,
  currentDocumentType,
  documentStatus,
  formResponseData,
  deleteDocument,
  deleteResponse,
  setSection,
  setCurrentFileManager,
  currentFileManager,
  setPreviewDocument,
  removedDocument,
  setDocumentSection,
  // principalLegalRepresentative,
}) {
  const inputRef = useRef(null);
  const [readLoading, setReadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  // const [generateLoading, setGenerateLoading] = useState(false);
  // const [signLoading, setSignLoading] = useState(false);
  // const [removeSignLoading, setRemoveSignLoading] = useState(false);
  const [isIdentificationPhase, setIsIdentificationPhase] = useState(false);
  const { phase, isFormDisqualified } = useSelector((state) => state.clientePotencialSlice);
  const [removeDocument, { error: removeDocumentError }] =
    useMutation(REMOVE_DOCUMENT);
  const [saveFileManager, { error: saveFileManagerError }] = useMutation(
    SAVE_FILE_MANAGER_CLIENT
  );
  const [saveDocumentSection, { error: saveDocumentSectionError }] =
    useMutation(SAVE_DOCUMENT_SECTION);
  const { error: verificationMechanismsError, data: verificationMechanisms } =
    useQuery(GET_VERIFICATION_MECHANISM_LIST);
  // const [generateKnowYourClientPDF] = useLazyQuery(GENERATED_KNOW_YOUR_CLIENT_PDF, {
  //     fetchPolicy: 'network-only'});
  // const [generateCevaldomContractPDF] = useLazyQuery(GENERATED_CEVALDOOM_CONTRACT_PDF, {
  //     fetchPolicy: 'network-only'});
  // const [generateBrokerageContractPDF] = useLazyQuery(GENERATED_BROKERAGE_CONTRACT_PDF, {
  //     fetchPolicy: 'network-only'});
  const [getSignatureProcess, { data: signatureProcess }] = useLazyQuery(
    GET_SIGNATURE_PROCESS_BY_DOCUMENT,
    {
      fetchPolicy: 'network-only',
    }
  );
  // const [removeSignatureProcess] = useMutation(REMOVE_SIGNATURE_PROCESS);
  // const [saveSignatureProcess] = useMutation(SAVE_SIGNATURE_PROCESS);

  useEffect(() => {
    if (phase) {
      setIsIdentificationPhase(phase?.name === 'Identificación');
    }
  }, [phase]);

  useEffect(() => {
    if (documentSection?.documents?.length) {
      getSignatureProcess({ variables: { documentId: getDocument()?.id } });
    }
  }, [documentSection]);

  useEffect(() => {
    if (saveFileManagerError?.message) {
      setUploadLoading(false);
      setErrorNotify(`Lo siento, no fue posible obtener información del File Manager. 
            Por favor, póngase en contacto con el administrador para obtener asistencia.`);
    }
  }, [saveFileManagerError]);

  useEffect(() => {
    if (removeDocumentError?.message) {
      setDeleteLoading(false);
      setErrorNotify(`Lo siento, no fue posible eliminar el documento. 
            Por favor, póngase en contacto con el administrador para obtener asistencia.`);
    }
  }, removeDocumentError);

  useEffect(() => {
    if (saveDocumentSectionError?.message) {
      setDeleteLoading(false);
      setErrorNotify(`Lo siento, no fue posible guardar la sección. 
            Por favor, póngase en contacto con el administrador para obtener asistencia.`);
    }
  }, saveDocumentSectionError);

  useEffect(() => {
    if (verificationMechanismsError?.message) {
      setDeleteLoading(false);
      setErrorNotify(`Lo siento, no fue posible obtener datos de los documentos. 
            Por favor, póngase en contacto con el administrador para obtener asistencia.`);
    }
  }, verificationMechanismsError);

  const checkBorder = (documentType) => {
    if (documentType.isSelfGenerated && !checkIsUploaded(documentType)) {
      return 'autogenerated';
    } else if (checkIsUploaded(documentType)) {
      const doc = checkIsUploaded(documentType);
      if (doc?.status.name.includes('Firmado')) {
        return 'signed';
      } else if (doc?.status.name.includes('Enviado a Firma')) {
        return 'send-to-sign';
      } else if (doc?.status.name.includes('Adjuntado')) {
        return 'adjunted';
      } else if (doc?.status.name.includes('Autogenerado')) {
        return 'normal';
      } else {
        return 'rejected';
      }
    } else {
      return 'normal';
    }
  };

  const checkStatus = (documentType) => {
    const doc = checkIsUploaded(documentType);
    if (doc) {
      return doc.status;
    } else {
      return documentStatus?.documentStatus?.find(
        (ds) => ds.name === 'Pendiente'
      );
    }
  };

  const statusTextColor = (status) => {
    if (status?.name === 'Pendiente') {
      return '#aab3d6';
    } else if (status?.name?.includes('Enviado a Firma')) {
      return '#83a84b';
    } else if (status?.name?.includes('Firmado')) {
      return '#5da292';
    } else if (status?.name?.includes('Completado')) {
      return '#f4c12a';
    } else if (status?.name?.includes('Autogenerado')) {
      return '#AA98A9';
    } else if (status?.name?.includes('Adjuntado')) {
      return '#088F8F';
    } else {
      return '#f07064';
    }
  };

  const checkIsUploaded = (documentType) => {
    return documentSection?.documents.find(
      (d) => d.documentType.id === documentType.id
    );
  };

  const complexStatus = (status) => {
    const statusName = status.name;
    let complexStatusName = statusName;
    if (statusName !== 'Pendiente' && statusName !== 'Completado') {
      let phaseStatus = '';

      switch (formResponseData?.formResponses[0]?.phase.name) {
        case 'Identificación':
          phaseStatus = 'Revisión Rep. Negocios';
          break;
        case 'Revisión':
          phaseStatus = 'Revisión Rep. Servicios';
          break;
        case 'Aprobación':
          phaseStatus = 'Revisión Cumplimiento';
          break;
        default:
          phaseStatus = '';
          break;
      }

      complexStatusName += ` - ${phaseStatus} Pendiente`;
    }

    return complexStatusName;
  };

  const saveDocuments = async (file, documentType, fileManager) => {
    let formattedDocument;
    const { firstName, lastName } =
      formResponseData?.formResponses[0]?.lead.primaryContact;
    const fileToSend = file;
    const idClient = fileManager.clientId;
    const documentTypeFileId = documentType.fileManagerDocumentTypeId;
    const idDocument = documentTypeFileId;

    if (!idDocument) {
      setErrorNotify(`Este tipo de documento no existe en el sistema del File Manager.
            Por favor, póngase en contacto con el administrador para obtener asistencia.`);
      return;
    }

    const fileId =
      idClient &&
      (await saveDocumentFileManager(fileToSend, idClient, idDocument));

    if (fileId) {
      formattedDocument = {
        fileName: file.name,
        fileManagerFileId: fileId,
        modifiedBy: `${firstName} ${lastName}`,
        lastActivity: 'Subida Draft',
        documentTypeId: documentType.id,
        statusId:
          documentStatus.documentStatus.find((ds) =>
            ds.name.includes('Adjuntado')
          )?.id || '',
        verificationMechanismId:
          verificationMechanisms.verificationMechanismList.find(
            (vm) => vm.name === 'Manual'
          ).id || '',
      };
    }

    if (!formattedDocument) {
      setErrorNotify(`Lo siento, no fue posible subir el archivo. 
            Por favor, póngase en contacto con el administrador para obtener asistencia.`);
      return;
    }

    if (documentSection) {
      const actualDocuments = documentSection.documents.map((ad) => ({
        id: ad.id,
        fileName: ad.fileName,
        fileManagerFileId: ad.fileManagerFileId,
        modifiedBy: ad.modifiedBy,
        lastActivity: ad.lastActivity,
        documentTypeId: ad.documentType.id,
        statusId: ad.status.id,
        verificationMechanismId: ad.verificationMechanism.id,
      }));

      const response = await saveDocumentSection({
        variables: {
          documentSection: {
            id: documentSection.id,
            formResponseId: formResponseData?.formResponses[0]?.id,
            documentInputTypes: [...actualDocuments, formattedDocument],
          },
        },
      });

      setDocumentSection(response.data?.saveDocumentSection);
    } else {
      const response = await saveDocumentSection({
        variables: {
          documentSection: {
            formResponseId: formResponseData?.formResponses[0]?.id,
            documentInputTypes: [formattedDocument],
          },
        },
      });

      setDocumentSection(response.data?.saveDocumentSection);
    }
    setSuccessNotify('Archivo cargado con éxito');
  };

  useEffect(() => {
    if (deleteResponse) {
      deleteFile();
    }
  }, [deleteResponse]);

  const saveDocumentFileManager = async (file, idClient, idDocument) => {
    try {
      const formData = new FormData();

      formData.append('file', file, file.name);
      formData.append('idClient', idClient.toString());
      formData.append('idDocumento', idDocument.toString());
      formData.append('idStage', '1');
      formData.append('user', 'CRM');

      const response = await axios.post(
        '/middleware/archivo-central/maintenance/documents/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        return response.data?.id;
      }
    } catch (e) {
      setErrorMap(e);
    }
  };

  const readDocument = async (document) => {
    setReadLoading(true);
    await setFileManagerToken();
    const localURL = await readDocumentFileManager(document.fileManagerFileId);
    localURL && setPreviewDocument(localURL);
    setReadLoading(false);
  };

  const deleteDocumentFileManager = async (id) => {
    try {
      const response = await axios.delete(
        `/middleware/archivo-central/maintenance/documents/delete/${id}`
      );

      return response.status === 200;
    } catch (e) {
      setErrorMap(e);
    }
  };

  const readDocumentFileManager = async (id, download = false) => {
    try {
      const response = await axios.get(
        `/middleware/archivo-central/maintenance/documents/view/${id}`,
        { responseType: 'blob' }
      );
      if (response.status === 200) {
        if (download) {
          return response.data;
        } else {
          return URL.createObjectURL(response.data);
        }
      }
    } catch (e) {
      setErrorMap(e);
    }
  };

  const createFileManager = async (access_token) => {
    if (!formResponseData.formResponses[0]) {
      return;
    }
    const { lead } = formResponseData.formResponses[0];

    if (
      lead.personType?.name === 'Persona Física' ||
      lead.personType?.name === 'Persona Jurídica'
    ) {
      const type =
        lead.personType?.name === 'Persona Física' ? 'Fisica' : 'Juridica';
      const { identification, identificationType, primaryContact, branch } =
        lead;
      const fileManagerClientData = {
        accessToken: access_token,
        clientAccountTypeName: ClientAccountTypeName.Individual,
        clientEmail: primaryContact.email,
        clientIdentification: identification,
        clientIdentificationTypeName: identificationType.name.toLowerCase(),
        clientName: `${primaryContact.firstName} ${primaryContact.lastName}`,
        clientPersonTypeName: ClientPersonTypeName[type],
        executiveInfo: { id: 151 },
        executiveOffice: {
          id: FileManagerOffice[branch?.name || 'Oficina Abraham Lincoln'],
        },
      };

      if (
        !currentFileManager?.clientId ||
        !currentFileManager?.clientAccounts.find(
          (fm) =>
            fm.identification === fileManagerClientData.clientIdentification
        )
      ) {
        const response = await saveFileManager({
          variables: {
            fileManagerClient: fileManagerClientData,
          },
        });

        if (response.data?.saveFileManagerClient) {
          setCurrentFileManager(response.data?.saveFileManagerClient);
          return response.data?.saveFileManagerClient;
        }
      }
    }
  };

  const setErrorMap = (e) => {
    const errorMap = e.response?.data?.message;
    if (errorMap) {
      setErrorNotify(errorMap);
    } else {
      setErrorNotify(e.message);
    }
  };

  const handleFile = async (file, documentType) => {
    if (file.type !== 'application/pdf') {
      setErrorNotify('Solo se aceptan documentos PDF');
      return;
    }
    setUploadLoading(true);
    const errorMessage = `Lo siento, no fue posible obtener información del File Manager. 
        Por favor, póngase en contacto con el administrador para obtener asistencia.`;
    let access_token = getCookie('FILE_MANAGER', false);
    if (!access_token) {
      await setFileManagerToken();
      access_token = getCookie('FILE_MANAGER', false);
    }
    if (access_token) {
      const response = await createFileManager(access_token);
      if (currentFileManager || response) {
        await saveDocuments(
          file,
          documentType,
          currentFileManager ? currentFileManager : response
        );
      } else {
        setErrorNotify(errorMessage);
      }
    } else {
      setErrorNotify(errorMessage);
    }
    setUploadLoading(false);
  };

  const getDocument = () => {
    return documentSection?.documents?.find(
      (d) => d.documentType.id === currentDocumentType.id
    );
  };

  const deleteFile = async () => {
    setDeleteLoading(true);
    await setFileManagerToken();
    const response = await deleteDocumentFileManager(
      getDocument().fileManagerFileId
    );
    if (response) {
      await removeDocument({ variables: { documentId: getDocument().id } });
      await setSection();
      setSuccessNotify('Documento eliminado con éxito');
      removedDocument();
    }
    setDeleteLoading(false);
  };

  // const canGenerated = (documentType) => {
  //     if(documentType.name.includes(SelfGeneratedDocuments.knowYorClientForm)){
  //         return true;
  //     }
  //     else if(documentType.name.includes(SelfGeneratedDocuments.cevaldomContract)){
  //         return true;
  //     }
  //     else if(documentType.name.includes(SelfGeneratedDocuments.brokerageContract)){
  //         return true;
  //     }
  //     else{
  //         return false;
  //     }
  // }

  // const generateDocument = async (documentType) => {
  //     setGenerateLoading(true);
  //     const errorMessage = `Lo siento, no fue posible generar el documento en este momento.
  //     Por favor, póngase en contacto con el administrador para obtener asistencia.`
  //     if(documentType.name.includes(SelfGeneratedDocuments.knowYorClientForm)){
  //         const response = await generateKnowYourClientPDF({ variables: { formResponseId: formResponseData?.formResponses[0]?.id } });
  //         if(response.error?.message){
  //             setErrorNotify(errorMessage);
  //         }
  //         else{
  //             await setSection();
  //             setSuccessNotify('Documento Formulario conozca su cliente generado');
  //         }
  //     }
  //     else if(documentType.name.includes(SelfGeneratedDocuments.cevaldomContract)){
  //         const response = await generateCevaldomContractPDF({ variables: { formResponseId: formResponseData?.formResponses[0]?.id } });
  //         if(response.error?.message){
  //             setErrorNotify(errorMessage);
  //         }
  //         else{
  //             await setSection();
  //             setSuccessNotify('Documento Contrato CEVALDOM generado');
  //         }
  //     }
  //     else if(documentType.name.includes(SelfGeneratedDocuments.brokerageContract)){
  //         const response = await generateBrokerageContractPDF({ variables: { formResponseId: formResponseData?.formResponses[0]?.id } });
  //         if(response.error?.message){
  //             setErrorNotify(errorMessage);
  //         }
  //         else{
  //             await setSection();
  //             setSuccessNotify('Documento Contrato de corretaje generado');
  //         }
  //     }
  //     setGenerateLoading(false);
  // }

  // const getUploadFileLinkAndToken = async () => {
  //     try {
  //         const response = await axios.get(
  //             `/middleware/viafirma/uploads/link/pdf`,
  //         );

  //         if(response.status === 200){
  //             return{
  //                 link: response.data.link,
  //                 token: response.data.token,
  //             }
  //         }
  //     } catch (error) {
  //         setSignLoading(false);
  //         setErrorNotify(`Lo siento, hubo un error al obtener la información para subir el documento de firma.
  //         Por favor, póngase en contacto con el administrador para obtener asistencia.`);
  //     }
  // }

  // const uploadFile = async (file) => {
  //     const config = await getUploadFileLinkAndToken();

  //     if(!config?.link){
  //         return;
  //     }

  //     try {
  //         const response = await axios.put(
  //             '/middleware/vot' + config?.link.replace('https://s3.eu-west-1.amazonaws.com/pro-documents-viafirma/uploads', ''),
  //             file,
  //         );

  //         if(response.status === 200){
  //             return config.token;
  //         }
  //     } catch (error) {
  //         setSignLoading(false);
  //         setErrorNotify(`Lo siento, hubo un error al subir el documento para firma.
  //         Por favor, póngase en contacto con el administrador para obtener asistencia.`);
  //     }
  // }

  // const sendToSign = async (
  //     recipients,
  //     customization,
  //     document,
  //     policies
  //     ) => {
  //     const credentials = envars('VIAFIRMA_RESPONSE_USERNAME') + ':' + envars('VIAFIRMA_RESPONSE_PASSWORD');
  //     const auth = `Basic ${Buffer.from(credentials).toString('base64')}`;

  //     const data = {
  //         groupCode: 'mapprisk',
  //         workflow: {
  //             type: 'WEB'
  //         },
  //         callbackURL: `${envars('CRM_URL')}viafirma/response`,
  //         callbackAuthorization: auth,
  //         callbackType: 'JSON',
  //         recipients,
  //         customization,
  //         messages: [
  //             {
  //                 document,
  //                 policies
  //             }
  //         ]
  //     }

  //     try {
  //         const response = await axios.post(
  //             `/middleware/viafirma/set`,
  //             data,
  //         )

  //         if(response.status === 200){
  //             return response.data;
  //         }
  //     } catch (error) {
  //         setSignLoading(false);
  //         setErrorNotify(`Lo siento, hubo un error al enviar el documento para firma.
  //         Por favor, póngase en contacto con el administrador para obtener asistencia.`);
  //     }
  // }

  // const sentToSign = async (documentType) => {
  //     setSignLoading(true);

  //     if(!principalLegalRepresentative){
  //         setSignLoading(false);
  //         setErrorNotify(`Necesita un representante legal principal para este proceso.`);
  //         return;
  //     }

  //     let access_token = getCookie('FILE_MANAGER', false);
  //     if(!access_token){
  //         await setFileManagerToken();
  //         access_token = getCookie('FILE_MANAGER', false);
  //     }
  //     const document = getDocument();
  //     const downloaded = await readDocumentFileManager(document.fileManagerFileId, true)

  //     if(!downloaded){
  //         setErrorNotify(`Lo siento, no fue posible generar el documento en este momento.
  //         Por favor, póngase en contacto con el administrador para obtener asistencia.`);
  //         setSignLoading(false);
  //         return;
  //     }

  //     const file = new File(
  //         [downloaded],
  //         `${documentType.name}_${formResponseData?.formResponses[0]?.lead.identification}.pdf`,
  //         { lastModified: new Date().getTime(), type: downloaded.type }
  //     );

  //     const tokenReference = await uploadFile(file);
  //     if(tokenReference){
  //         let recipients, customization, document, policies;

  //         if(documentType.name.includes('Formulario conozca su cliente')){
  //             const format = knowYourClientConfigToSign(tokenReference, principalLegalRepresentative, formResponseData?.formResponses[0]?.lead);
  //             recipients = format.recipients;
  //             customization = format.customization;
  //             document = format.document;
  //             policies = format.policies;
  //         }
  //         else if(documentType.name.includes('Contrato de corretaje')){
  //             const format = brokerageContractConfigToSign(tokenReference, principalLegalRepresentative);
  //             recipients = format.recipients;
  //             customization = format.customization;
  //             document = format.document;
  //             policies = format.policies;
  //         }
  //         else{
  //             const format = cevaldomContractConfigToSign(tokenReference, principalLegalRepresentative);
  //             recipients = format.recipients;
  //             customization = format.customization;
  //             document = format.document;
  //             policies = format.policies;
  //         }

  //         const response = await sendToSign(
  //             recipients,
  //             customization,
  //             document,
  //             policies
  //         );

  //         if(response){
  //             const signatureResponseFormatted = formatSignatureProcess(
  //                 recipients,
  //                 response,
  //             );

  //             const documentToSign = getDocument();
  //             const signatureResponse = await saveSignatureProcess({ variables: { documentId: documentToSign.id, signatureProcess: signatureResponseFormatted } });

  //             if(signatureResponse?.errors?.length){
  //                 setErrorNotify(`Lo siento, no fue posible guardar el proceso de firma.
  //                 Por favor, póngase en contacto con el administrador para obtener asistencia.`)
  //             }

  //             await getSignatureProcess({ variables: { documentId: documentToSign.id } });

  //             const actualDocuments = documentSection.documents.map((ad) => ({
  //                 id: ad.id,
  //                 fileName: ad.fileName,
  //                 fileManagerFileId: ad.fileManagerFileId,
  //                 modifiedBy: ad.modifiedBy,
  //                 lastActivity: ad.lastActivity,
  //                 documentTypeId: ad.documentType.id,
  //                 statusId: documentToSign.id === ad.id ? documentStatus.documentStatus.find((ds) => ds.name.includes('Enviado a Firma'))?.id : ad.id,
  //                 verificationMechanismId: ad.verificationMechanism.id
  //             }))

  //             const sectionResponse = await saveDocumentSection({ variables: { documentSection: {
  //                 id: documentSection.id,
  //                 formResponseId: formResponseData?.formResponses[0]?.id,
  //                 documentInputTypes: actualDocuments,
  //             }}});

  //             setDocumentSection(sectionResponse.data?.saveDocumentSection);

  //             setSuccessNotify('Documento enviado para firma exitosamente');
  //         }
  //     }

  //     setSignLoading(false);
  // }

  const getSignProcess = () => {
    return signatureProcess?.signatureProcessByDocumentId;
  };

  const checkSignStatus = () => {
    return getSignProcess()?.status;
  };

  const getSignIcon = (status) => {
    let icon;
    switch (status) {
      case 'RESPONSED':
        icon = (
          <CheckCircleOutlined style={{ color: '#50C878', margin: '0 7px' }} />
        );
        break;
      case 'RECEIVED':
        icon = <InfoOutlined style={{ color: '#0096FF', margin: '0 7px' }} />;
        break;
      case 'REJECTED':
      case 'EXPIRED':
      case 'ERROR':
      default:
        // icon = <ErrorOutline style={{ color: '#FF3131', cursor: 'pointer', margin: '0 7px' }} onClick={() => removeSignProcess()} />;
        icon = <ErrorOutline style={{ color: '#FF3131', margin: '0 7px' }} />;
        break;
    }
    return icon;
  };

  const getSignMessageColor = (status) => {
    let color;
    switch (status) {
      case 'RESPONSED':
        color = (
          <SuccessTooltip title={signMessage(status)} placement="top" arrow>
            {getSignIcon(status)}
          </SuccessTooltip>
        );
        break;
      case 'RECEIVED':
        color = (
          <InfoTooltip title={signMessage(status)} placement="top" arrow>
            {getSignIcon(status)}
          </InfoTooltip>
        );
        break;
      case 'REJECTED':
      case 'EXPIRED':
      case 'ERROR':
      default:
        color = (
          <ErrorTooltip title={signMessage(status)} placement="top" arrow>
            {getSignIcon(status)}
          </ErrorTooltip>
        );
        break;
    }
    return color;
  };

  const signMessage = (status) => {
    let message;
    switch (status) {
      case 'RESPONSED':
        message = 'El proceso de firma fue completado exitosamente.';
        break;
      case 'RECEIVED':
        message =
          'El proceso de firma está en curso. Por favor, aguarde mientras los firmantes completan el proceso.';
        break;
      case 'REJECTED':
        message =
          'El proceso finalizó, ya que uno de los firmantes rechazó la firma. Comuniquese con su representante para iniciar un nuevo proceso de firma.';
        break;
      case 'EXPIRED':
        message =
          'El proceso finalizó, ya que no se completaron las firmas a tiempo. Comuniquese con su representante para iniciar un nuevo proceso de firma.';
        break;
      default:
      case 'ERROR':
        message =
          'Hubo un problema con el proceso de firma. Comuniquese con su representante para iniciar un nuevo proceso de firma.';
        break;
    }
    return message;
  };

  // const removeSignProcess = async () => {
  //     if(checkSignStatus() === 'RESPONSED' || checkSignStatus() === 'RECEIVED'){
  //         return;
  //     }
  //     setRemoveSignLoading(true);
  //     const removeResponse = await removeSignatureProcess({ variables: { signatureProcessId: getSignProcess()?.id } });
  //     if(removeResponse?.errors?.length){
  //         setErrorNotify(`Lo siento, no fue posible eliminar el proceso de firma.
  //         Por favor, póngase en contacto con el administrador para obtener asistencia.`)
  //     }
  //     else{
  //         await getSignatureProcess({ variables: { documentId: getDocument()?.id } });
  //         setSection();
  //         setSuccessNotify('Proceso de firma removido exitosamente');
  //     }
  //     setRemoveSignLoading(false);
  // }

  return (
    <Col
      md={4}
      className={
        checkBorder(currentDocumentType) +
        '-border document d-flex justify-content-between'
      }
    >
      <div
        className="document-left-side d-flex flex-column justify-content-center"
        style={{ padding: '20px 5px 20px 25px' }}
      >
        <p
          className="m-0 p-0 fw-bold"
          style={{ fontWeight: 600, fontSize: 16, color: 'rgb(87, 87, 87)' }}
        >
          {currentDocumentType.name}
        </p>
        {status.length ? (
          <p
            className="m-0 p-0"
            style={{
              fontWeight: 500,
              fontSize: 14,
              marginTop: 10,
              color: statusTextColor(checkStatus(currentDocumentType)),
            }}
          >
            {complexStatus(checkStatus(currentDocumentType))}
          </p>
        ) : null}
      </div>
      {currentDocumentType.isSelfGenerated ||
      checkIsUploaded(currentDocumentType) ? (
        <div
          className="document-right-side"
          style={{ padding: '20px 25px 20px 5px' }}
        >
          <div
            className="buttons d-flex d-flex align-items-center justify-content-end"
            style={{ height: 25, marginBottom: 15 }}
          >
            {/* { currentDocumentType.isSelfGenerated && canGenerated(currentDocumentType) && checkIsUploaded(currentDocumentType) && !generateLoading && checkSignStatus() !== 'RESPONSED' && checkSignStatus() !== 'RECEIVED' ? <IconButton title='Actualizar documento' style={{ color: '#7ecb7c' }} onClick={() => generateDocument(currentDocumentType)}>
                        <PublishedWithChanges />
                    </IconButton> : null } */}
            {/* { checkIsUploaded(currentDocumentType) && generateLoading ? <CircularProgress size={18} style={{ color: '#7ecb7c', margin: '0 11px' }} /> : null } */}
            {checkIsUploaded(currentDocumentType) &&
            currentDocumentType.isVisible &&
            !readLoading ? (
              <IconButton onClick={() => readDocument(getDocument())}>
                <VisibilityOutlined />
              </IconButton>
            ) : null}
            {readLoading ? (
              <CircularProgress
                size={18}
                style={{ color: '#75777a', margin: '0 11px' }}
              />
            ) : null}
            {!currentDocumentType.isSelfGenerated && !deleteLoading ? (
              <IconButton
                disabled={!isIdentificationPhase || isFormDisqualified}
                onClick={() => deleteDocument(getDocument())}
              >
                <DeleteOutline />
              </IconButton>
            ) : null}
            {deleteLoading ? (
              <CircularProgress
                size={18}
                style={{ color: '#75777a', margin: '0 11px' }}
              />
            ) : null}
            {checkSignStatus() /*&& !removeSignLoading*/
              ? getSignMessageColor(checkSignStatus())
              : null}
            {/* { removeSignLoading ? <CircularProgress size={18} style={{ color: '#FF3131', margin: '0 11px' }} /> : null } */}
            {/* { currentDocumentType.requireSignature && checkIsUploaded(currentDocumentType) && !getSignProcess()?.id && !signLoading ? <IconButton title='Enviar a Firma Digital' style={{
                        backgroundColor: '#7ecb7c',
                        borderRadius: 20,
                        color: 'white',
                        margin: 'auto 5px',
                        padding: 4,
                    }} onClick={() => sentToSign(currentDocumentType)}>
                            <Draw style={{fontSize: 20 }} />
                    </IconButton> : null }
                    { signLoading ? <CircularProgress size={18} style={{ color: '#7ecb7c', margin: '0 11px' }} /> : null } */}
          </div>
          <p
            className="m-0 p-0 text-end pe-2"
            style={{
              fontWeight: 500,
              fontSize: 14,
              color: '#aab3d6',
              marginTop: 10,
            }}
          >
            {currentDocumentType.isSelfGenerated
              ? ''
              : `Modificado por: ${checkIsUploaded(currentDocumentType)?.modifiedBy ?? 'Administración'}`}
          </p>
        </div>
      ) : null}
      {!currentDocumentType.isSelfGenerated &&
      !checkIsUploaded(currentDocumentType) ? (
        <div
          className="document-upload-btn m-0 p-0 d-flex align-items-center justify-content-center"
          style={{ width: 70, borderLeft: '2px solid white' }}
        >
          {!uploadLoading ? (
            <IconButton
              disabled={!isIdentificationPhase || isFormDisqualified}
              style={{ color: isIdentificationPhase && !isFormDisqualified ? '#aab3d6' : '#acadb177' }}
              onClick={() => inputRef.current.click()}
            >
              <FileUploadOutlined style={{ fontSize: 30 }} />
            </IconButton>
          ) : (
            <CircularProgress style={{ color: '#aab3d6' }} size={30} />
          )}
        </div>
      ) : null}
      {/* { currentDocumentType.isSelfGenerated && !checkIsUploaded(currentDocumentType) && canGenerated(currentDocumentType) ? <div className="document-upload-btn m-0 p-0 d-flex align-items-center justify-content-center" style={{ width: 70, borderLeft: '2px solid white' }}>
                { !generateLoading ? <IconButton style={{color: '#75777a'}} title='Generar Documento' onClick={() => generateDocument(currentDocumentType)}>
                        <Autorenew style={{fontSize: 30}} />
                    </IconButton>
                    : <CircularProgress style={{color: '#75777a', }} size={30} />
                }
            </div> : null } */}
      <input
        type="file"
        onChange={(event) => {
          handleFile(event.target.files.item(0), currentDocumentType);
          inputRef.current.value = null;
        }}
        hidden
        accept="application/pdf"
        ref={inputRef}
      />
    </Col>
  );
}
