import './confirmationModal.css';
import { InfoOutlined } from '@mui/icons-material';

export default function ConfirmationModal({ declarationResponse }) {
  return (
    <div className="custom-confirmation-modal">
      <div className="custom-confirmation-modal-body animate__animated animate__fadeInUpBig">
        <InfoOutlined style={{ fontSize: 150, color: '#c9dae1' }} />
        <h2 className="fw-bold text-dark mt-4">Finalizar proceso</h2>
        <p className="text-justify mt-3" style={{ fontSize: 18 }}>
          Declaro que todas las informaciones que he puesto en este formulario
          son veraces y a la vez autorizo a PARVAL, a efectuar cualquier tipo de
          investigación respecto de mi situación financiera y crediticia y del
          origen lícito de fondos, ante los organismos y fuentes que esta última
          considere oportunos a los fines de evaluarme.
        </p>
        <p className="text-justify" style={{ fontSize: 18 }}>
          Al seleccionar la opción <span className="fw-bold">acepto</span>{' '}
          confirmo haber completado los formularios relacionados a la solicitud
          de apertura de cuenta en PARVAL.
        </p>
        <p className="text-justify" style={{ fontSize: 18 }}>
          Toda información y documentos suministrados a PARVAL quedan sujetos a
          revisión, reservándose éste el derecho de autorizar o no la apertura
          de la cuenta de PARVAL.
        </p>
        <p className="text-justify" style={{ fontSize: 18 }}>
          En caso de no haber enviado por correo electrónico o cargado en la
          página web los documentos requeridos, deberás completar los mismos a
          los fines de que la solicitud pueda ser procesada. Hacemos la salvedad
          de que además de la información y documentos requeridos, pudieran
          requerirse adicionales.
        </p>
        <div className="custom-buttons mt-3 mb-2">
          <button
            type="button"
            className="btn btn-secondary btn-lg"
            onClick={() => declarationResponse(false)}
          >
            <span className="fw-bold">No acepto</span>
          </button>
          <button
            type="button"
            className="btn btn-warning btn-lg ms-4"
            onClick={() => declarationResponse(true)}
          >
            <span className="fw-bold">Acepto</span>
          </button>
        </div>
      </div>
    </div>
  );
}
