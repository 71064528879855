import { useForm } from 'react-hook-form';
import './IdentityVerification.css';
import { useNavigate, useParams } from 'react-router-dom';
import Toast from '../../../shared/UIElements/Toast/Toast';
import {
  faUser,
  faCreditCard,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  validarCedulaDominicana,
  validateNonSelectedOptionInDropdown,
} from '../../../shared/utils/validators';
import { useEffect, useState, h, VNode, useRef } from 'react';
import 'animate.css';
import { validateInputCedula } from '../../../shared/utils/functions';
import { useQuery } from '@apollo/client';
import { GET_FORM_TYPES } from '../../../shared/graphQL/queries';

const IdentityVerification = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const identityDocumentType = watch('identityDocumentType');
  const identityDocumentNumber = watch('identityDocumentNumber');
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [
    identificationTypesForPhisicalPerson,
    setIdentificationTypesForPhisicalPerson,
  ] = useState([]);
  const navigate = useNavigate();
  const { accountType } = useParams();
  const [cedulaErrorMessage, setCedulaErrorMessage] = useState('');

  const buttonRef = useRef(null);

  const btnSubmitRef = useRef();
  const [clientid] = useState('5faed671d00458001b74dbe4');
  const [flowid] = useState('62c4a24dca76d6001c7bb5f5');

  // setup callbacks
  const handleLoaded = ({ detail }) => {
    console.log('loaded payload', detail);
  };

  const handleUserStartedSdk = ({ detail }) => {
    console.log('started payload', detail);
  };

  const handleUserFinishedSdk = ({ detail }) => {
    console.log('finished payload', detail);
  };

  const handleExitedSdk = ({ detail }) => {
    console.log('exited payload', detail);
  };

  const handleClick = () => {
    console.log('Botón clickeado');
    // Realiza las acciones deseadas cuando se hace clic en el botón (Probando)
  };

  useEffect(() => {
    const buttonElement = buttonRef?.current;
    buttonElement?.addEventListener('click', handleClick);
    buttonElement?.addEventListener('metamap:loaded', handleLoaded);
    buttonElement?.addEventListener(
      'metamap:userStartedSdk',
      handleUserStartedSdk
    );
    buttonElement?.addEventListener(
      'metamap:userFinishedSdk',
      handleUserFinishedSdk
    );
    buttonElement?.addEventListener('metamap:exitedSdk', handleExitedSdk);
  }, []);

  // useEffect(() => {
  //     if((localStorage.getItem("identityDocumentType") !== undefined) && (localStorage.getItem("identityDocumentNumber") !== undefined)) {
  //         setValue("identityDocumentType", localStorage.getItem("identityDocumentType"));
  //         setValue("identityDocumentNumber", localStorage.getItem("identityDocumentNumber"));
  //     }
  // }, []);

  const clickMetaMapBtn = () => {
    //const metaBtn = document.getElementById("metaBtn");
    //metaBtn.click();
    const buttonElement = buttonRef.current;
    buttonElement.click();
  };

  const doTradicionalVerfication = () => {
    btnSubmitRef.current.click();
  };

  const saveDateAndNavigate = () => {
    localStorage.setItem('identityDocumentType', identityDocumentType);
    localStorage.setItem('identityDocumentNumber', identityDocumentNumber);

    if (accountType === 'physical-person') {
      navigate('/request-way-to-fill-form');
    } else if (accountType === 'legal-person') {
      navigate('/formularios/persona-juridica/informacion-general');
    }
  };

  return (
    <div className="page-container" id="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" />
      </div>

      <div className="principal-container">
        <h3 className="formTitle">
          Para continuar tu solicitud necesitamos verificar si ya eres cliente
          nuestro con una identificación.
        </h3>

        <form
          onSubmit={handleSubmit(saveDateAndNavigate)}
          className="iv-form"
          id="form"
        >
          <div className="contField">
            <label htmlFor="identityDocumentType" className="iv-field-title">
              Tipo de documento
            </label>
            <div
              className="input-group mb-3"
              style={{ height: '10px !important' }}
            >
              <select
                className="form-select iv-form-field"
                defaultValue={'DEFAULT'}
                {...register('identityDocumentType', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {accountType === 'legal-person'
                  ? identificationTypes.map((identificationType) => (
                      <option
                        key={identificationType.id}
                        value={identificationType.id}
                      >
                        {identificationType.name}
                      </option>
                    ))
                  : identificationTypesForPhisicalPerson.map(
                      (identificationType) => (
                        <option
                          key={identificationType.id}
                          value={identificationType.id}
                        >
                          {identificationType.name}
                        </option>
                      )
                    )}
              </select>
            </div>
            {errors.identityDocumentType && (
              <p className="text-danger">
                Debe seleccionar el tipo de documento de identidad
              </p>
            )}
          </div>

          <div className="contField">
            <label htmlFor="identityDocumentNumber" className="iv-field-title">
              Documento de identidad
            </label>
            <div
              className="input-group flex-nowrap"
              style={{ height: '10px !important' }}
            >
              {identityDocumentType === '100000001' ? (
                <input
                  id="cedula"
                  className="iv-form-field"
                  type="text"
                  maxLength="13"
                  pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                  placeholder="000-0000000-0"
                  onInput={validateInputCedula}
                  {...register('identityDocumentNumber', {
                    required: true,
                  })}
                />
              ) : (
                <input
                  type="text"
                  className="form-control iv-form-field"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                  {...register('identityDocumentNumber', {
                    required: true,
                  })}
                />
              )}
            </div>
            {errors.identityDocumentNumber?.type === 'required' && (
              <p className="text-danger">
                Debe ingresar el número de su documento de identidad
              </p>
            )}
            {cedulaErrorMessage !== '' ? (
              <p className="text-danger">{cedulaErrorMessage}</p>
            ) : null}

            <div className=" d-flex justify-content-center">
              <button
                type="button"
                className="mt-5 iv-btn-continue"
                onClick={clickMetaMapBtn}
              >
                Verificación de identidad Biométrica &#160;
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            </div>
            <button
              type="submit"
              className=""
              id="btn-submit"
              ref={btnSubmitRef}
            >
              submit
            </button>

            <metamap-button
              ref={buttonRef}
              id="metaBtn"
              clientId={clientid} // from your Metamap dashboard
              flowId={flowid} // from your Metamap dashboard
              color="#505DED" // to setup main color of buttons in your metamap
              textcolor="#FFFFFF" // to setup text color of buttons in your metamap
              metadata='{"user_id":"1234778", "fixedLanguage":"es"}' // custom data
              style={{ display: 'none' }}
            />
          </div>
        </form>
        {/* <Link to="#" onClick={doTradicionalVerfication} className="tradicional-verification" id="liveToastBtn">Deseo realizar la verificacion tradicional</Link> */}

        {/* Este componente Toast contiene el boton de la verificacion tradicional */}
        <Toast
          handleClick={doTradicionalVerfication}
          typeButton={'submit'}
          title={'Formulario incompleto'}
          message={'Debe completar el formulario antes de poder enviarlo'}
          selectorClass={'tradicional-verification'}
          selector={'a'}
          textSelector={'Deseo realizar la verificacion tradicional'}
        />
      </div>
    </div>
  );
};

export default IdentityVerification;
