import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Link, useNavigate } from 'react-router-dom';
import './Registration.css';
// import axios from 'axios';
import Spinner from '../../../../shared/UIElements/Spinner/Spinner';
import { useForm } from 'react-hook-form';
import jwt_decode from 'jwt-decode';
import {
  getAuthorizationConfig,
  validateEmail,
} from '../../../../shared/utils/functions';
import ReCAPTCHA from 'react-google-recaptcha';
import { useChangeSessionContext } from '../../../../context/SessionContextProvider';

export default function Registration() {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm();

  const email = watch('email');
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  const branch = watch('branch');
  const [reCaptchaChecked, setReCaptchaChecked] = useState(false);
  const [reCaptchaErrorMessage, setReCaptchaErrorMessage] = useState('');

  // email errors
  const [existingEmailError, setExistingEmailError] = useState('');
  const [emailFormatInvalid, setEmailFormatInvalid] = useState('');
  // password errors
  const [differentPasswordsError, setDifferentPasswordsError] = useState(false);
  const [passwordErrorFirstCapitalLetter, SetPasswordErrorFirstCapitalLetter] =
    useState(false);
  const [passwordHasSpecialCharacters, setPasswordHasSpecialCharacters] =
    useState(true);
  // confirm password errors
  const [
    confirmPasswordErrorFirstCapitalLetter,
    setConfirmPasswordErrorFirstCapitalLetter,
  ] = useState(false);

  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [startValidateFields, setStartValidateFields] = useState(false);
  const setInitializeSessionExpirationCount = useChangeSessionContext();

  useEffect(() => {
    document.body.classList.add('bgLogin');
    // Lógica que se ejecutará en cada cambio del formulario
    //console.log("Se ha producido un cambio en el formulario");

    if (
      email !== undefined &&
      password !== undefined &&
      confirmPassword !== undefined
    ) {
      if (startValidateFields === true) {
        validateFields(email, password, confirmPassword);
      }
      setStartValidateFields(false);
    }
  }, [startValidateFields]);

  const createUser = () => {
    setStartValidateFields(true);

    if (reCaptchaChecked === false) {
      setReCaptchaErrorMessage('Debe marcar la casilla de verificación');
    } else {
      setReCaptchaErrorMessage('');
    }

    if (
      existingEmailError === '' &&
      differentPasswordsError === false &&
      passwordErrorFirstCapitalLetter === false &&
      passwordHasSpecialCharacters === true &&
      confirmPasswordErrorFirstCapitalLetter === false &&
      reCaptchaChecked === true
    ) {
      // setShowSpinner(true);
      setExistingEmailError('');
      localStorage.setItem('email', email);
      localStorage.setItem('password', password);
      // setShowSpinner(false);
      setInitializeSessionExpirationCount(true);
      navigate('/register/select-branch-your-choice');
    }
  };

  const validateEmailToRegistration = (email) => {
    const validEmail = validateEmail(email);

    if (!validEmail && email.length > 0) {
      setEmailFormatInvalid('Debe ingresar un correo electrónico válido');
    }
  };

  const validateIfPasswordsAreEquals = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      setDifferentPasswordsError(true);
    }
  };

  const validatePassword = (password) => {
    if (!isNaN(password[0]) || password[0] !== password[0]?.toUpperCase()) {
      SetPasswordErrorFirstCapitalLetter(true);
    }
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (
      !isNaN(confirmPassword[0]) ||
      confirmPassword[0] !== confirmPassword[0]?.toUpperCase()
    ) {
      setConfirmPasswordErrorFirstCapitalLetter(true);
    }
  };

  const validateIfPasswordsHaveSpecialCharacters = (
    password,
    confirmPassword
  ) => {
    const specialCharacters = [
      '*',
      '/',
      '.',
      '+',
      '-',
      '^',
      '#',
      '@',
      '~',
      '%',
      '-',
      ',',
      '=',
      '!',
      ';',
      ':',
      '[',
      ']',
      '{',
      '}',
      '(',
      ')',
      '~',
      '\\',
      '&',
      '$',
      '?',
    ];

    if (password.length > 0 || confirmPassword.length > 0) {
      for (const specialCharacter of specialCharacters) {
        if (
          password.includes(specialCharacter) &&
          confirmPassword.includes(specialCharacter)
        ) {
          setPasswordHasSpecialCharacters(true);
          break;
        } else {
          setPasswordHasSpecialCharacters(false);
        }
      }
    }
  };

  const verifyExistingUser = (email) => {};

  const validateFields = (email, password, confirmPassword) => {
    setExistingEmailError('');
    setDifferentPasswordsError(false);
    SetPasswordErrorFirstCapitalLetter(false);
    setPasswordHasSpecialCharacters(true);
    setConfirmPasswordErrorFirstCapitalLetter(false);
    setEmailFormatInvalid('');

    validateEmailToRegistration(email);
    validatePassword(password);
    validateConfirmPassword(confirmPassword);
    validateIfPasswordsAreEquals(password, confirmPassword);
    validateIfPasswordsHaveSpecialCharacters(password, confirmPassword);
    verifyExistingUser(email);
  };

  const verifyJWTAndRedirect = (accessToken) => {
    const decodedToken = jwt_decode(accessToken);
    const realClientToken = decodedToken.token;

    // Aqui se verifica si el cleinte es un cliente real o un cliente potencial
  };

  const getGoogleResponse = (provider, data) => {
    setShowSpinner(true);
    console.log(provider, data);
  };

  function onChangeReCAPTCHA(value) {
    console.log('Captcha value:', value);
    localStorage.setItem('captchaResponse', value);
    setReCaptchaChecked(true);
  }

  return (
    <div className="contLogin">
      {showSpinner ? <Spinner /> : null}
      <br />
      <div className="logo-login-container">
        <img className="logoParval" alt="logo" src="/logo.png" />
      </div>
      <Container>
        <Row>
          <Col className="tab ">
            <Link to="/onboarding-digital/login">
              <strong>Iniciar sesión</strong>
            </Link>
          </Col>
          <Col className="tab selected">
            <strong>Registrarse</strong>
          </Col>
        </Row>
      </Container>
      <div className="loginForm">
        {/* <button className='btnGoogle'>Registrarse Usando Google</button> */}
        <form
          className="formLoginRegister"
          onSubmit={handleSubmit(() => setStartValidateFields(true))}
        >
          <p
            style={{ textAlign: 'center', fontSize: '14px', padding: '10px 0' }}
          >
            O crea tu propia cuenta
          </p>
          {differentPasswordsError ? (
            <div className="text-danger mb-3 w-100">
              • Las contraseñas deben ser iguales.
            </div>
          ) : null}
          {passwordErrorFirstCapitalLetter ? (
            <div className="text-danger mb-3 w-100">
              • La contraseña debe iniciar con una letra mayúscula
            </div>
          ) : null}

          {confirmPasswordErrorFirstCapitalLetter ? (
            <div className="text-danger mb-3 w-100">
              • La confirmación de contraseña debe iniciar con una letra
              mayúscula
            </div>
          ) : null}
          {!passwordHasSpecialCharacters ? (
            <div className="text-danger mb-3 w-100">
              • La contraseña debe incluir por lo menos un caracter especial
            </div>
          ) : null}

          <input
            type="text"
            placeholder="Introduce un correo electrónico"
            {...register('email', {
              required: true,
            })}
          />
          {errors.email?.type === 'required' && (
            <p className="text-danger">El correo electrónico es requerido</p>
          )}
          {existingEmailError !== '' ? (
            <div className="text-danger mb-3">{existingEmailError}</div>
          ) : null}
          {emailFormatInvalid !== '' ? (
            <div className="text-danger mb-3">{emailFormatInvalid}</div>
          ) : null}

          <input
            type="password"
            placeholder="Contraseña (mínimo 8 caracteres)"
            autoComplete="off"
            {...register('password', {
              required: true,
              minLength: 8,
            })}
          />
          {errors.password?.type === 'required' && (
            <p className="text-danger">El campo contraseña es requerido</p>
          )}
          {errors.password?.type === 'minLength' && (
            <p className="text-danger">
              La contraseña debe tener 8 caracteres como mínimo
            </p>
          )}

          <input
            type="password"
            placeholder="Confirma tu Contraseña"
            autoComplete="off"
            {...register('confirmPassword', {
              required: true,
            })}
          />
          {errors.confirmPassword?.type === 'required' && (
            <p className="text-danger">
              El campo confirmación de contraseña es requerido
            </p>
          )}
          {errors.confirmPassword?.type === 'minLength' && (
            <p className="text-danger">
              La confirmación de contraseña debe tener 8 caracteres como mínimo
            </p>
          )}

          <ReCAPTCHA
            sitekey={'6LckfBkmAAAAAFaSVDphAekJ0P6mdzwKrvmaysh2'}
            onChange={onChangeReCAPTCHA}
          />

          {reCaptchaErrorMessage !== '' ? (
            <p className="text-danger w-100 mt-2">{reCaptchaErrorMessage}</p>
          ) : null}

          <button
            type="submit"
            style={{ marginBottom: 0, width: '100%' }}
            className="btnSubmit mt-3"
          >
            Crear Nuevo Usuario
          </button>
          {/* <Link style={{marginBottom: 0}} className='btnSubmit' to="/dashboard" onClick={createUser} >Crear Nuevo Usuario</Link> */}
        </form>
      </div>
      <Nav style={{ marginTop: '10px' }} className="justify-content-center">
        <Nav.Item>
          <Nav.Link
            className="px-2"
            href="https://www.facebook.com/ParvalRD/"
            target="_blank"
          >
            <FacebookIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="px-2"
            href="https://www.instagram.com/parvalrd/"
            target="_blank"
            eventKey="link-1"
          >
            <InstagramIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="px-2"
            href="https://www.linkedin.com/company/parval-puesto-de-bolsa/"
            target="_blank"
            eventKey="link-2"
          >
            <LinkedInIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            className="px-2"
            href="https://twitter.com/parval_rd/"
            target="_blank"
            eventKey="link-2"
          >
            <TwitterIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <p className="linkYellow">info@parval.com.do</p>
      <p style={{ textAlign: 'center', display: 'center' }}>809-560-0909</p>
      <p style={{ textAlign: 'center', display: 'center' }}>
        Copyright 2023. Todos los derechos reservados.
      </p>
    </div>
  );
}
