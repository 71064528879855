import { currencyFormat } from '../../../shared/utils/functions';
import './consolidatedPosition.css';

export default function ConsolidatedPosition({ consolidatedPosition }) {
  return consolidatedPosition ? (
    <div className="consolidated-position cp">
      <p className="consolidated-position-title p-0 mb-1">
        Posición Consolidada {consolidatedPosition.coin}$
      </p>
      <strong className="consolidated-position-amount">
        {currencyFormat(consolidatedPosition.amount)}
      </strong>
    </div>
  ) : null;
}
