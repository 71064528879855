import './DeclarationOfBeneficiariesForm.css';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import {
  GET_BENEFICIARIES_SECTION,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_IDENTITYTYPES,
  GET_POLITIC_EXPOSURE_TYPES,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CHECK_POLITIC_EXPOSURE,
  REMOVE_BENEFICIARY,
  SAVE_BENEFICIARIES_SECTION,
  SAVE_BENEFICIARY,
} from '../../../../../shared/graphQL/mutations';
import { useDispatch } from 'react-redux';
import {
  loadPersons,
  setSelectedSection,
} from '../../../../../shared/store/slices/clientePotencial';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  comprexAddress,
  convertBooleanToInput,
  convertInputToBoolean,
  formatSimple,
  mergeObjectsWithSameId,
  parseSimple,
  preFormat,
  validateInputCedula,
  validateInputRnc,
} from '../../../../../shared/utils/functions';
import { v4 } from 'uuid';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const DeclarationOfBeneficiariesForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [formResponseId, setFormResponseId] = useState('');
  const { data: identificationTypeList } = useQuery(GET_IDENTITYTYPES);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isContrapart, persons, hasBCRDAccount, isFormCompleted, isFormDisqualified, validation, isForeign } =
    useSelector((state) => state.clientePotencialSlice);
  const [saveBeneficiary, { data: saveBeneficiaryResponse }] = useMutation(
    SAVE_BENEFICIARY,
    {
      refetchQueries: [
        {
          query: GET_BENEFICIARIES_SECTION,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [saveBeneficiariesSection] = useMutation(SAVE_BENEFICIARIES_SECTION, {
    refetchQueries: [
      {
        query: GET_BENEFICIARIES_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [removeBeneficiary] = useMutation(REMOVE_BENEFICIARY, {
    refetchQueries: [
      {
        query: GET_BENEFICIARIES_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [getBeneficiariesSectionData, beneficiariesSectionData] = useLazyQuery(
    GET_BENEFICIARIES_SECTION
  );
  const [beneficiariesSectionId, setBeneficiariesSectionId] = useState('');
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const [politicExposureId, setPoliticExposureId] = useState('');
  const [fatcaId, setFatcaId] = useState('');
  const [notCheck, setNotCheck] = useState(false);
  const [existingBeneficiary, setExistingBeneficiary] = useState(false);
  const { data: politicExposureTypes } = useQuery(GET_POLITIC_EXPOSURE_TYPES);
  const [
    checkPoliticExposure,
    { data: checkPoliticExposureResponse, error: checkPoliticExposureError },
  ] = useMutation(CHECK_POLITIC_EXPOSURE);

  const nombres = watch('nombres');
  const apellidos = watch('apellidos');
  const tipoDocumento = watch('tipoDocumento');
  const numeroDoc = watch('numeroDoc');
  const ocupacion = watch('ocupacion');
  const telefonoContacto = watch('telefonoContacto');
  const direccion = watch('direccion');
  const correoElectronico = watch('correoElectronico');

  const personaPoliticamenteExpuesta = watch('personaPoliticamenteExpuesta');
  const cargoPep = watch('cargoPep');
  const institucion = watch('institucion');
  const fechaInicioCargo = watch('fechaInicioCargo');
  const ultimaFechaCargo = watch('ultimaFechaCargo');
  const tipoExposicionPolitica = watch('tipoExposicionPolitica');
  const nombreDelFamiliar = watch('nombreDelFamiliar');
  const tipoRelacionFamiliar = watch('tipoRelacionFamiliar');
  const pepActivo = watch('pepActivo');

  const tieneNacionalidadEU = watch('tieneNacionalidadEU');
  const esCiudadanoEU = watch('esCiudadanoEU');
  const esResidenteEU = watch('esResidenteEU');
  const seguroSocial = watch('seguroSocial');
  const codigoPostalEU = watch('codigoPostalEU');
  const telefonoEU = watch('telefonoEU');
  const tieneRepresentanteEU = watch('tieneRepresentanteEU');
  const tieneTIN = watch('tieneTIN');
  const tin = watch('tin');
  const tarjetaVerde = watch('tarjetaVerde');
  const direccionEU = watch('direccionEU');

  const [loadingSection, setLoadingSection] = useState(true);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (
      typeof numeroDoc === 'string' &&
      getIdentificationTypeName(tipoDocumento) ===
        'Cedula de Identidad y Electoral' &&
      !notCheck
    ) {
      if (numeroDoc.length > 12) {
        verifiyIfIsPep();
      } else {
        setValue('personaPoliticamenteExpuesta', 'No');
      }
    }
  }, [numeroDoc, notCheck]);

  useEffect(() => {
    if (
      getIdentificationTypeName(tipoDocumento) !==
        'Cedula de Identidad y Electoral' &&
      personaPoliticamenteExpuesta === 'Si' &&
      !notCheck
    ) {
      setValue('personaPoliticamenteExpuesta', 'No');
      setValue('pepActivo', 'No');
      setValue('cargoPep', null);
      setValue('institucion', null);
      setValue('fechaInicioCargo', null);
      setValue('ultimaFechaCargo', null);
      setValue('tipoExposicionPolitica', 'DEFAULT');
      setValue('nombreDelFamiliar', null);
      setValue('tipoRelacionFamiliar', null);
    }
  }, [tipoDocumento, notCheck]);

  const getIdentificationTypeName = (tipoDocumento) => {
    const identificationType =
      identificationTypeList?.identificationTypeList?.find(
        (x) => x.id === tipoDocumento
      );
    return identificationType?.name;
  };

  const getPoliticExposureTypeName = (politicExposureTypeId) => {
    const politicExposureType =
      politicExposureTypes?.politicExposureTypes?.find(
        (x) => x.id === politicExposureTypeId
      );
    return politicExposureType?.name;
  };

  const verifiyIfIsPep = () => {
    checkPoliticExposure({
      variables: {
        identification: numeroDoc,
      },
    });
  };

  useEffect(() => {
    if (checkPoliticExposureResponse) {
      setValue(
        'personaPoliticamenteExpuesta',
        convertBooleanToInput(
          checkPoliticExposureResponse.checkPoliticExposure.isPEP
        )
      );
      if (checkPoliticExposureResponse.checkPoliticExposure.isPEP === true) {
        setValue(
          'cargoPep',
          checkPoliticExposureResponse.checkPoliticExposure.charge
        );
        setValue(
          'tipoExposicionPolitica',
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .id
        );
        if (
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .name === 'Familiar'
        ) {
          setValue(
            'nombreDelFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure.relativeName
          );
          setValue(
            'tipoRelacionFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure
              .relativeRelationship
          );
        }
        toast('Esta es una Persona Politicamente Expuesta', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        toast('Esta no es una Persona Politicamente Expuesta', {
          type: 'success',
          theme: 'colored',
        });
      }
    }
  }, [checkPoliticExposureResponse]);

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if(formResponseByIdentification?.data?.formResponses?.length){
      setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);

      getBeneficiariesSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (beneficiariesSectionData?.data) {
      setBeneficiariesSectionId(
        beneficiariesSectionData?.data?.beneficiariesSection?.id
      );
      setLoadingSection(false);
    }
  }, [beneficiariesSectionData]);

  useEffect(() => {
    if (saveBeneficiaryResponse) {
      const beneficiaryId = saveBeneficiaryResponse?.saveBeneficiary?.id;
      const beneficiariesIds = [];
      const beneficiaries =
        beneficiariesSectionData?.data?.beneficiariesSection?.beneficiaries;
      let data = {};

      if (beneficiaries !== null && beneficiaries !== undefined) {
        for (let beneficiary of beneficiaries) {
          beneficiariesIds.push(beneficiary.id);
        }

        beneficiariesIds.push(beneficiaryId);
      } else {
        beneficiariesIds.push(beneficiaryId);
      }

      if (beneficiariesSectionId && beneficiariesSectionId !== '') {
        data = {
          beneficiaryIds: beneficiariesIds,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: beneficiariesSectionId,
        };
      } else {
        data = {
          beneficiaryIds: beneficiariesIds,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        };
      }

      saveBeneficiariesSection({
        variables: {
          beneficiariesSection: data,
        },
      });
    }
  }, [saveBeneficiaryResponse]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedBeneficiaryId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const handleBackNavigation = () => {
    if (isContrapart) {
      if (hasBCRDAccount) {
        dispatch(setSelectedSection('Información De Mi Empresa'));
        navigate(
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
        );
      } else {
        if(!isForeign){
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/cuentas-bancarias'
          );
        }
        else{
          dispatch(setSelectedSection('Información De Mi Empresa'));
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
          );
        }
      }
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/referencias-comerciales'
      );
    }
  };

  const navigateToNextPage = () => {
    if (!isContrapart) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/tarjeta-de-firmas'
      );
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/documentos'
      );
    }
  };

  const addBeneficiary = async () => {
    const emailIsInUse =
      beneficiariesSectionData?.data?.beneficiariesSection?.beneficiaries?.find(
        (beneficiary) =>
          beneficiary.id !== selectedBeneficiaryId &&
          correoElectronico === beneficiary.email
      );

    if (emailIsInUse) {
      toast('Ya existe un beneficiario con este correo', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    const identificationIsInUse =
      beneficiariesSectionData?.data?.beneficiariesSection?.beneficiaries?.find(
        (beneficiary) =>
          beneficiary.id !== selectedBeneficiaryId &&
          numeroDoc === beneficiary.identificationNumber &&
          beneficiary.identificationType.id === tipoDocumento
      );

    if (identificationIsInUse) {
      toast('Ya existe un beneficiario con esta identificación', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    btnCloseModalRef.current.click();
    reset();

    let data = {};

    // Si selectedBeneficiaryId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarde, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (selectedBeneficiaryId && selectedBeneficiaryId !== '') {
      data = {
        id: selectedBeneficiaryId,
        address: direccion,
        firstName: nombres,
        identificationNumber: numeroDoc,
        identificationTypeId: tipoDocumento,
        lastName: apellidos,
        occupation: ocupacion,
        phoneNumber: telefonoContacto,
        email: correoElectronico,
        politicExposureInputType:
          personaPoliticamenteExpuesta === 'Si'
            ? {
                id: politicExposureId ? politicExposureId : null,
                isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                active: convertInputToBoolean(pepActivo),
                charge: cargoPep,
                relativeName: nombreDelFamiliar,
                relativeRelationship: tipoRelacionFamiliar,
                politicExposureTypeId: tipoExposicionPolitica,
                chargeStartDate: fechaInicioCargo
                  ? parseSimple(preFormat(fechaInicioCargo))
                  : null,
                chargeEndDate: ultimaFechaCargo
                  ? parseSimple(preFormat(ultimaFechaCargo))
                  : null,
                institution: institucion,
              }
            : {},
        contactFatcaInputType: {
          id: fatcaId,
          haveUnitedStatesNationality:
            convertInputToBoolean(tieneNacionalidadEU),
          isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
          isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
          socialSecurityNumber: seguroSocial,
          zipInUnitedStates: codigoPostalEU,
          phoneInUnitedStates: telefonoEU,
          haveRepresentativeInUnitedStates:
            convertInputToBoolean(tieneRepresentanteEU),
          haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
          taxIdentificationNumber: tin,
          greenCard: tarjetaVerde,
          addressInUnitedStates: direccionEU,
        },
      };

      setSelectedBeneficiaryId('');
    } else {
      data = {
        id: v4(),
        address: direccion,
        firstName: nombres,
        identificationNumber: numeroDoc,
        identificationTypeId: tipoDocumento,
        lastName: apellidos,
        occupation: ocupacion,
        phoneNumber: telefonoContacto,
        email: correoElectronico,
        politicExposureInputType:
          personaPoliticamenteExpuesta === 'Si'
            ? {
                isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                active: convertInputToBoolean(pepActivo),
                charge: cargoPep,
                relativeName: nombreDelFamiliar,
                relativeRelationship: tipoRelacionFamiliar,
                politicExposureTypeId: tipoExposicionPolitica,
                chargeStartDate: fechaInicioCargo
                  ? parseSimple(preFormat(fechaInicioCargo))
                  : null,
                chargeEndDate: ultimaFechaCargo
                  ? parseSimple(preFormat(ultimaFechaCargo))
                  : null,
                institution: institucion,
              }
            : {},
        contactFatcaInputType: {
          haveUnitedStatesNationality:
            convertInputToBoolean(tieneNacionalidadEU),
          isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
          isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
          socialSecurityNumber: seguroSocial,
          zipInUnitedStates: codigoPostalEU,
          phoneInUnitedStates: telefonoEU,
          haveRepresentativeInUnitedStates:
            convertInputToBoolean(tieneRepresentanteEU),
          haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
          taxIdentificationNumber: tin,
          greenCard: tarjetaVerde,
          addressInUnitedStates: direccionEU,
        },
      };
    }

    const saveData = await saveBeneficiary({
      variables: {
        beneficiary: data,
      },
    });

    const exists = persons.beneficiaries.find(
      (beneficiary) => beneficiary.id === saveData.data?.saveBeneficiary?.id
    );

    loadPersons(dispatch, {
      beneficiaries: exists
        ? persons.beneficiaries.map((beneficiary) => {
            if (beneficiary.id === exists.id) {
              return exists;
            } else {
              return beneficiary;
            }
          })
        : [...persons.beneficiaries, saveData.data?.saveBeneficiary],
      shareholders: persons.shareholders,
      signatores: persons.signatores,
      legalRepresentatives: persons.legalRepresentatives,
      counselMembers: persons.counselMembers,
    });
  };

  const deleteCardElement = async (id) => {
    await removeBeneficiary({
      variables: {
        beneficiaryId: id,
      },
    });

    loadPersons(dispatch, {
      legalRepresentatives: persons.legalRepresentatives,
      shareholders: persons.shareholders,
      signatores: persons.signatores,
      counselMembers: persons.counselMembers,
      beneficiaries:
        persons.beneficiaries.filter((beneficiary) => beneficiary.id !== id) ??
        [],
    });
  };

  const handleEdit = (id) => {
    setNotCheck(true);
    setSelectedBeneficiaryId(id);
    const selectedBeneficiary =
      beneficiariesSectionData?.data?.beneficiariesSection?.beneficiaries?.find(
        (x) => x.id === id
      );

    if (id && selectedBeneficiary) {
      setForm(selectedBeneficiary);
    }
  };

  const setForm = (selectedBeneficiary) => {
    selectedBeneficiary?.firstName &&
      setValue('nombres', selectedBeneficiary?.firstName);
    selectedBeneficiary?.lastName &&
      setValue('apellidos', selectedBeneficiary?.lastName);
    if (selectedBeneficiary?.identificationType?.id) {
      setValue('tipoDocumento', selectedBeneficiary?.identificationType.id);
    }
    if (
      selectedBeneficiary?.identificationNumber ||
      selectedBeneficiary?.identification
    ) {
      setValue(
        'numeroDoc',
        selectedBeneficiary?.identificationNumber ||
          selectedBeneficiary?.identification
      );
    }
    if (selectedBeneficiary?.occupation || selectedBeneficiary?.position) {
      setValue(
        'ocupacion',
        selectedBeneficiary?.occupation || selectedBeneficiary?.position
      );
    }
    selectedBeneficiary?.phoneNumber &&
      setValue('telefonoContacto', selectedBeneficiary?.phoneNumber);
    selectedBeneficiary?.address &&
      setValue('direccion', selectedBeneficiary?.address);
    selectedBeneficiary?.email &&
      setValue('correoElectronico', selectedBeneficiary?.email);
    selectedBeneficiary?.politicExposure?.id &&
      setValue(
        'personaPoliticamenteExpuesta',
        selectedBeneficiary?.politicExposure?.isPep ? 'Si' : 'No'
      );
    selectedBeneficiary?.politicExposure?.id &&
      setPoliticExposureId(selectedBeneficiary?.politicExposure?.id);

    if (
      selectedBeneficiary?.politicExposure?.id &&
      selectedBeneficiary?.politicExposure?.isPep
    ) {
      setValue(
        'pepActivo',
        selectedBeneficiary?.politicExposure?.politicExposureDetails?.active
          ? 'Si'
          : 'No'
      );
      setValue(
        'cargoPep',
        selectedBeneficiary?.politicExposure?.politicExposureDetails?.charge
      );
      setValue(
        'institucion',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.institution
      );
      setValue(
        'fechaInicioCargo',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.chargeStartDate
          ? formatSimple(
              new Date(
                selectedBeneficiary?.politicExposure?.politicExposureDetails?.chargeStartDate
              )
            )
          : null
      );
      setValue(
        'ultimaFechaCargo',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.chargeEndDate
          ? formatSimple(
              new Date(
                selectedBeneficiary?.politicExposure?.politicExposureDetails?.chargeEndDate
              )
            )
          : null
      );
      setValue(
        'tipoExposicionPolitica',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.politicExposureType?.id
      );
      setValue(
        'nombreDelFamiliar',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.relativeName
      );
      setValue(
        'tipoRelacionFamiliar',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.relativeRelationship
      );
    }

    if (selectedBeneficiary?.contactFatca) {
      setFatcaId(selectedBeneficiary?.contactFatca?.id);
      if (
        selectedBeneficiary?.contactFatca?.haveUnitedStatesNationality !==
          null &&
        selectedBeneficiary?.contactFatca?.haveUnitedStatesNationality !==
          undefined
      ) {
        setValue(
          'tieneNacionalidadEU',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.haveUnitedStatesNationality
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen !== null &&
        selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen !== undefined
      ) {
        setValue(
          'esCiudadanoEU',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.isUnitedStatesResident !== null &&
        selectedBeneficiary?.contactFatca?.isUnitedStatesResident !== undefined
      ) {
        setValue(
          'esResidenteEU',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.isUnitedStatesResident
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.haveRepresentativeInUnitedStates !==
          null &&
        selectedBeneficiary?.contactFatca?.haveRepresentativeInUnitedStates !==
          undefined
      ) {
        setValue(
          'tieneRepresentanteEU',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.haveRepresentativeInUnitedStates
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.haveTaxIdentificationNumber !==
          null &&
        selectedBeneficiary?.contactFatca?.haveTaxIdentificationNumber !==
          undefined
      ) {
        setValue(
          'tieneTIN',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      selectedBeneficiary?.contactFatca?.socialSecurityNumber &&
        setValue(
          'seguroSocial',
          selectedBeneficiary?.contactFatca?.socialSecurityNumber
        );
      selectedBeneficiary?.contactFatca?.zipInUnitedStates &&
        setValue(
          'codigoPostalEU',
          selectedBeneficiary?.contactFatca?.zipInUnitedStates
        );
      selectedBeneficiary?.contactFatca?.phoneInUnitedStates &&
        setValue(
          'telefonoEU',
          selectedBeneficiary?.contactFatca?.phoneInUnitedStates
        );
      selectedBeneficiary?.contactFatca?.taxIdentificationNumber &&
        setValue(
          'tin',
          selectedBeneficiary?.contactFatca?.taxIdentificationNumber
        );
      selectedBeneficiary?.contactFatca?.greenCard &&
        setValue('tarjetaVerde', selectedBeneficiary?.contactFatca?.greenCard);
      selectedBeneficiary?.contactFatca?.addressInUnitedStates &&
        setValue(
          'direccionEU',
          selectedBeneficiary?.contactFatca?.addressInUnitedStates
        );
    }
  };

  const copiarContacto = watch('copiarContacto');
  const contacto = watch('contacto');

  useEffect(() => {
    let shareholders = [];
    persons.shareholders.forEach((sh) => {
      if (sh.personType.name === 'Persona Física') {
        shareholders.push({
          id: sh.id,
          firstName: sh.shareholderPersonDetails.firstName ?? '',
          lastName: sh.shareholderPersonDetails.lastName ?? '',
          identificationType: sh.identificationType,
          identification: sh.identification,
          position: sh.shareholderPersonDetails.charge,
          isInDarkList: sh.isInDarkList,
          politicExposure: sh.shareholderPersonDetails.politicExposure,
          nationalityCountry: sh.country,
          contactFatca: sh.contactFatca,
        });
      }
    });

    const signatores = persons.signatores.map((sig) => ({
      id: sig.id,
      firstName: sig.firstName,
      lastName: sig.lastName,
      identification: sig.identification,
      position: sig.charge,
      email: sig.email,
    }));

    const counselMembers = persons.counselMembers.map((counselMember) => ({
      id: counselMember.id,
      firstName: counselMember.firstName,
      lastName: counselMember.lastName,
      identificationType: counselMember.identificationType,
      identification: counselMember.identification,
      position: counselMember.position,
      nationalityCountry: counselMember.nationalityCountry,
      isInDarkList: counselMember.isInDarkList,
      politicExposure: counselMember.politicExposure,
      contactFatca: counselMember.contactFatca,
    }));

    const legalRepresentatives = persons.legalRepresentatives.map((legal) => ({
      id: legal.id,
      firstName: legal.firstName,
      lastName: legal.lastName,
      email: legal.correspondenceEmail?.email,
      identificationType: legal.identificationType,
      identification: legal.identification,
      position: legal.position,
      nationalityCountry: legal.nationalityCountry,
      isInDarkList: legal.isInDarkList,
      politicExposure: legal.politicExposure,
      contactFatca: legal.contactFatca,
      address: comprexAddress(legal.address),
    }));

    let shareholdersBeneficiaries = [];

    persons.shareholders.forEach((sh) => {
      sh.beneficiaries.forEach((beneficiary) =>
        shareholdersBeneficiaries.push({
          id: beneficiary.id,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          email: beneficiary.email,
          identificationType: beneficiary.identificationType,
          identification: beneficiary.identificationNumber,
          position: beneficiary.occupation,
          politicExposure: beneficiary.politicExposure,
          phoneNumber: beneficiary.phoneNumber,
          address: beneficiary.address,
          contactFatca: beneficiary.contactFatca,
        })
      );
    });

    let personsToCopy = [
      ...shareholders,
      ...signatores,
      ...legalRepresentatives,
      ...shareholdersBeneficiaries,
      ...counselMembers,
    ];

    personsToCopy = mergeObjectsWithSameId(personsToCopy);

    const idsToRemove = new Set(
      persons.beneficiaries.map((item) => item.identificationNumber)
    );
    personsToCopy = personsToCopy.filter(
      (item) => !idsToRemove.has(item.identification)
    );

    setContacts(personsToCopy);
  }, [persons]);

  useEffect(() => {
    if (contacto && contacto !== 'DEFAULT') {
      const personToCopy = contacts.find((contact) => contact.id === contacto);
      reset();
      setValue('contacto', contacto);
      setValue('copiarContacto', copiarContacto);
      setForm(personToCopy);
    }
  }, [contacto]);

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'beneficiariesSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Declaración de beneficiarios directos/ indirectos/ relacionados
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={
                'Declaración de beneficiarios directos/ indirectos/ relacionados'
              }
              description={
                "El formulario 'Declaración de Beneficiarios Directos/Indirectos/Relacionados' ofrece un panorama detallado de todos los beneficiarios vinculados a la empresa, ya sean directos, indirectos o relacionados. Este espacio está diseñado para recopilar información esencial que permita identificar y comprender las conexiones y beneficiarios que desempeñan un papel significativo en la estructura y dirección de la entidad. La declaración busca proporcionar transparencia y claridad sobre aquellos individuos o entidades que tienen un interés directo o indirecto en la empresa."
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'beneficiaries'
                      ? 'Es necesario que agregues por lo menos un beneficiario.'
                      : `Hay uno o varios baneficiarios con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>

          <h4 className="bff-sub-title">
            Si tienes más de un beneficiario, puedes agregarlos todos aquí.
          </h4>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {beneficiariesSectionData?.data?.beneficiariesSection?.beneficiaries?.map(
                (beneficiary, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={beneficiary?.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardItems
                        key={index}
                        nameElement={
                          beneficiary?.firstName + ' ' + beneficiary?.lastName
                        }
                        identityDocument={beneficiary?.identificationNumber}
                        idElement={beneficiary?.id}
                        deleteElement={deleteCardElement}
                        handleEdit={handleEdit}
                        setIsUpdating={setIsUpdating}
                        showDeleteButton={!isFormCompleted && !isFormDisqualified}
                        detalle={
                          beneficiary?.politicExposure?.isPep
                            ? 'Persona politicamente expuesta'
                            : null
                        }
                      />
                    </div>
                  );
                }
              )}
              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    reset();
                    setExistingBeneficiary(false);
                    setSelectedBeneficiaryId('');
                    setFatcaId('');
                    setPoliticExposureId('');
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">Agregar Beneficiario</h4>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="button"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <form
              onSubmit={handleSubmit(addBeneficiary)}
              className="fields-modal-container"
            >
              <div className="fields-modal-header">
                <h4 className="modal-title" id="exampleModalLabel">
                  {!isFormCompleted && !isFormDisqualified
                    ? selectedBeneficiaryId
                      ? 'Editar'
                      : 'Agregar'
                    : 'Ver'}{' '}
                  Beneficiario
                </h4>
                <button
                  type="button"
                  ref={btnCloseModalRef}
                  onClick={() => {
                    reset();
                    setExistingBeneficiary(false);
                    setSelectedBeneficiaryId('');
                    setFatcaId('');
                    setPoliticExposureId('');
                  }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="row fields-modal-content">
                <div className="row">
                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="field-form col-md-4 col-sm-12">
                      <label htmlFor="copiar-contacto" className="label-field">
                        ¿Copiar información de otro contacto?{' '}
                      </label>
                      <div className="selectWrapper">
                        <select
                          id="copiar-contacto"
                          defaultValue="No"
                          {...register('copiarContacto')}
                        >
                          <option value="No">No</option>
                          <option value="Si">Si</option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {!isFormCompleted && !isFormDisqualified && copiarContacto === 'Si' ? (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="contacto" className="label-field">
                        Contacto <span>*</span>
                      </label>
                      <div className="selectWrapper">
                        <select
                          id="contacto"
                          defaultValue={'DEFAULT'}
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('contacto', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {contacts.map((contact) => {
                            return (
                              <option key={contact?.id} value={contact?.id}>
                                {contact?.firstName + ' ' + contact?.lastName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.copiarContacto && (
                        <p className="text-danger">
                          Debe seleccionar una opción
                        </p>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="nombres" className="label-field">
                      Nombres <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="nombres"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('nombres', {
                        required: true,
                      })}
                    />
                    {errors.nombres?.type === 'required' && (
                      <p className="text-danger">
                        El campo Nombres es requerido
                      </p>
                    )}
                    {selectedBeneficiaryId &&
                      !nombres &&
                      errors.nombres?.type !== 'required' && (
                        <ErrorText hasError={hasError('firstName')} />
                      )}
                  </div>

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="apellidos" className="label-field">
                      Apellidos <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="apellidos"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('apellidos', {
                        required: true,
                      })}
                    />
                    {errors.apellidos?.type === 'required' && (
                      <p className="text-danger">
                        El campo Apellidos es requerido
                      </p>
                    )}
                    {selectedBeneficiaryId &&
                      !apellidos &&
                      errors.apellidos?.type !== 'required' && (
                        <ErrorText hasError={hasError('lastName')} />
                      )}
                  </div>

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="tipo-documento" className="label-field">
                      Tipo de documento <span>*</span>
                    </label>
                    <div className="selectWrapper">
                      {' '}
                      <select
                        id="tipo-documento"
                        defaultValue={'DEFAULT'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('tipoDocumento', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {identificationTypeList?.identificationTypeList.map(
                          (identificationType) => {
                            return (
                              <option
                                key={identificationType.id}
                                value={identificationType.id}
                              >
                                {identificationType.name}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    {errors.tipoDocumento && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                    {selectedBeneficiaryId &&
                      (!tipoDocumento || tipoDocumento === 'DEFAULT') &&
                      errors.tipoDocumento && (
                        <ErrorText hasError={hasError('identificationType')} />
                      )}
                  </div>

                  {tipoDocumento &&
                  identificationTypeList?.identificationTypeList &&
                  identificationTypeList?.identificationTypeList
                    ?.find((i) => i.id === tipoDocumento)
                    ?.name.toLowerCase()
                    .includes('cedula') ? (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="cedula" className="label-field">
                        Número de documento de identidad <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        maxLength="13"
                        pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                        placeholder="000-0000000-0"
                        id="cedula"
                        disabled={isFormCompleted || isFormDisqualified}
                        onInput={() => {
                          setNotCheck(false);
                          validateInputCedula();
                        }}
                        {...register('numeroDoc', {
                          required: true,
                        })}
                      />
                      {errors.numeroDoc?.type === 'required' && (
                        <p className="text-danger">
                          El campo Número de documento es requerido
                        </p>
                      )}
                      {existingBeneficiary ? (
                        <p className="text-danger">
                          Ya existe un beneficiario registrado con ese número de
                          documento.
                        </p>
                      ) : null}
                      {selectedBeneficiaryId &&
                        !numeroDoc &&
                        errors.numeroDoc?.type !== 'required' && (
                          <ErrorText
                            hasError={hasError('identificationNumber')}
                          />
                        )}
                    </div>
                  ) : tipoDocumento &&
                    identificationTypeList?.identificationTypeList &&
                    identificationTypeList?.identificationTypeList
                      ?.find((i) => i.id === tipoDocumento)
                      ?.name.toLowerCase()
                      .includes('rnc') ? (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="rnc" className="label-field">
                        Número de documento de identidad <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        maxLength="11"
                        pattern="[0-9]{3}-?[0-9]{5}-?[0-9]{1}"
                        placeholder="000-00000-0"
                        disabled={isFormCompleted || isFormDisqualified}
                        onInput={validateInputRnc}
                        id="rnc"
                        {...register('numeroDoc', {
                          required: true,
                        })}
                      />
                      {errors.numeroDoc?.type === 'required' && (
                        <p className="text-danger">
                          El campo Número de documento es requerido
                        </p>
                      )}
                      {existingBeneficiary ? (
                        <p className="text-danger">
                          Ya existe un beneficiario registrado con ese número de
                          documento.
                        </p>
                      ) : null}
                      {selectedBeneficiaryId &&
                        !numeroDoc &&
                        errors.numeroDoc?.type !== 'required' && (
                          <ErrorText
                            hasError={hasError('identificationNumber')}
                          />
                        )}
                    </div>
                  ) : (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="numero-doc" className="label-field">
                        Número de documento de identidad <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        placeholder=""
                        id="numero-doc"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('numeroDoc', {
                          required: true,
                        })}
                      />
                      {errors.numeroDoc?.type === 'required' && (
                        <p className="text-danger">
                          El campo Número de documento es requerido
                        </p>
                      )}
                      {existingBeneficiary ? (
                        <p className="text-danger">
                          Ya existe un beneficiario registrado con ese número de
                          documento.
                        </p>
                      ) : null}
                      {selectedBeneficiaryId &&
                        !numeroDoc &&
                        errors.numeroDoc?.type !== 'required' && (
                          <ErrorText
                            hasError={hasError('identificationNumber')}
                          />
                        )}
                    </div>
                  )}

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="ocupacion" className="label-field">
                      Ocupación{' '}
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="ocupacion"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('ocupacion')}
                    />
                  </div>

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="telefono-contacto" className="label-field">
                      Teléfono contacto{' '}
                    </label>
                    <input
                      type="number"
                      className="input-form"
                      placeholder=""
                      id="telefono-contacto"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('telefonoContacto')}
                    />
                  </div>

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="direccion" className="label-field">
                      Dirección{' '}
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="direccion"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('direccion')}
                    />
                  </div>

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="correo-electronico" className="label-field">
                      Correo electrónico <span>*</span>
                    </label>
                    <input
                      type="email"
                      className="input-form"
                      placeholder=""
                      id="correo-electronico"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('correoElectronico', {
                        required: true,
                      })}
                    />
                    {errors.correoElectronico?.type === 'required' && (
                      <p className="text-danger">Este campo es requerido</p>
                    )}
                    {selectedBeneficiaryId &&
                      !correoElectronico &&
                      errors.correoElectronico?.type !== 'required' && (
                        <ErrorText hasError={hasError('email')} />
                      )}
                  </div>
                </div>
              </div>

              <div className="row fields-modal-content">
                <div className="fields-modal-header">
                  <h4 className="modal-title" id="exampleModalLabel">
                    Persona políticamente expuesta
                  </h4>
                </div>
                <div className="row">
                  <div className="field-form  col-md-4 col-sm-12">
                    <label
                      htmlFor="persona-politicamente-expuesta"
                      className="label-field"
                    >
                      ¿Es una persona políticamente expuesta?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="persona-politicamente-expuesta"
                        defaultValue={'No'}
                        disabled={
                          (isFormCompleted || isFormDisqualified) ||
                          ((getIdentificationTypeName(tipoDocumento) ===
                            'Cedula de Identidad y Electoral' &&
                            !checkPoliticExposureError?.message) ||
                          getIdentificationTypeName(tipoDocumento) === undefined
                            ? true
                            : false)
                        }
                        {...register('personaPoliticamenteExpuesta', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  {personaPoliticamenteExpuesta === 'Si' ? (
                    <>
                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="tipoExposicionPolitica"
                          className="label-field"
                        >
                          Tipo de exposición política <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tipoExposicionPolitica"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoExposicionPolitica', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {politicExposureTypes?.politicExposureTypes?.map(
                              (option) => (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.tipoExposicionPolitica && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {selectedBeneficiaryId &&
                          (!tipoExposicionPolitica ||
                            tipoExposicionPolitica === 'DEFAULT') &&
                          errors.tipoExposicionPolitica && (
                            <ErrorText
                              hasError={hasError('politicExposureType')}
                            />
                          )}
                      </div>

                      <div className="field-form  col-md-12 col-sm-12">
                        <label htmlFor="cargo-pep" className="label-field">
                          Cargo <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="cargo-pep"
                          disabled={isFormCompleted || isFormDisqualified}
                          readOnly={
                            checkPoliticExposureResponse &&
                            (getIdentificationTypeName(tipoDocumento) ===
                              'Cedula de Identidad y Electoral' ||
                            getIdentificationTypeName(tipoDocumento) ===
                              undefined
                              ? true
                              : false)
                          }
                          {...register('cargoPep', {
                            required: true,
                          })}
                        />
                        {errors.cargoPep?.type === 'required' && (
                          <p className="text-danger">
                            El campo Cargo es requerido
                          </p>
                        )}
                        {selectedBeneficiaryId &&
                          !cargoPep &&
                          errors.cargoPep?.type !== 'required' && (
                            <ErrorText hasError={hasError('charge')} />
                          )}
                      </div>

                      {getPoliticExposureTypeName(tipoExposicionPolitica) ===
                      'Familiar' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="tipoRelacionFamiliar"
                            className="label-field"
                          >
                            Indique su parentesco con ese familiar{' '}
                            <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="tipoRelacionFamiliar"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoRelacionFamiliar', {
                              required: true,
                              maxLength: 100,
                            })}
                          />
                          {errors.tipoRelacionFamiliar?.type === 'required' && (
                            <p className="text-danger">
                              Este campo es requerido
                            </p>
                          )}
                          {errors.tipoRelacionFamiliar?.type ===
                            'maxLength' && (
                            <p className="text-danger">
                              Este campo solo puede tener 100 caracteres como
                              máximo
                            </p>
                          )}
                          {selectedBeneficiaryId &&
                            !tipoRelacionFamiliar &&
                            errors.tipoRelacionFamiliar?.type !==
                              'required' && (
                              <ErrorText
                                hasError={hasError('relativeRelationship')}
                              />
                            )}
                        </div>
                      ) : null}

                      {getPoliticExposureTypeName(tipoExposicionPolitica) ===
                      'Familiar' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="nombreDelFamiliar"
                            className="label-field"
                          >
                            Nombre completo del familiar <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="nombreDelFamiliar"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('nombreDelFamiliar', {
                              required: true,
                              maxLength: 100,
                            })}
                          />
                          {errors.nombreDelFamiliar?.type === 'required' && (
                            <p className="text-danger">
                              Este campo es requerido
                            </p>
                          )}
                          {errors.nombreDelFamiliar?.type === 'maxLength' && (
                            <p className="text-danger">
                              Este campo solo puede tener 100 caracteres como
                              máximo
                            </p>
                          )}
                          {selectedBeneficiaryId &&
                            !nombreDelFamiliar &&
                            errors.nombreDelFamiliar?.type !== 'required' && (
                              <ErrorText hasError={hasError('relativeName')} />
                            )}
                        </div>
                      ) : null}

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="pep-activo" className="label-field">
                          ¿Está activo en el cargo?{' '}
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="pep-activo"
                            defaultValue={'No'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('pepActivo', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="fecha-inicio-cargo"
                          className="label-field"
                        >
                          Fecha de inicio en el cargo <span>*</span>
                        </label>
                        <input
                          type="date"
                          className="input-form"
                          placeholder=""
                          id="fecha-inicio-cargo"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('fechaInicioCargo', {
                            required: true,
                          })}
                        />
                        {errors.fechaInicioCargo?.type === 'required' && (
                          <p className="text-danger">
                            El campo Fecha de inicio en el cargo es requerido
                          </p>
                        )}
                        {selectedBeneficiaryId &&
                          !fechaInicioCargo &&
                          errors.fechaInicioCargo?.type !== 'required' && (
                            <ErrorText hasError={hasError('chargeStartDate')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="ultima-fecha-cargo"
                          className="label-field"
                        >
                          Última fecha en el cargo{' '}
                          <span>{pepActivo === 'No' ? '*' : ''}</span>
                        </label>
                        <input
                          type="date"
                          disabled={(isFormCompleted || isFormDisqualified) || pepActivo === 'Si'}
                          className="input-form"
                          placeholder=""
                          id="ultima-fecha-cargo"
                          {...register('ultimaFechaCargo', {
                            required: pepActivo === 'No',
                          })}
                        />
                        {errors.ultimaFechaCargo?.type === 'required' && (
                          <p className="text-danger">
                            El campo Última fecha en el cargo es requerido
                          </p>
                        )}
                        {selectedBeneficiaryId &&
                          !ultimaFechaCargo &&
                          errors.ultimaFechaCargo?.type !== 'required' && (
                            <ErrorText hasError={hasError('chargeEndDate')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="institucion" className="label-field">
                          Institución <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="institucion"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('institucion', {
                            required: true,
                          })}
                        />
                        {errors.institucion?.type === 'required' && (
                          <p className="text-danger">
                            El campo Institución es requerido
                          </p>
                        )}
                        {selectedBeneficiaryId &&
                          !institucion &&
                          errors.institucion?.type !== 'required' && (
                            <ErrorText hasError={hasError('institution')} />
                          )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="row fields-modal-content">
                <div className="fields-modal-header">
                  <h4 className="modal-title" id="exampleModalLabel">
                    Foreign Account Tax Compliance Act
                  </h4>
                </div>
                <div className="row">
                  <div className="field-form  col-md-4 col-sm-12">
                    <label
                      htmlFor="tieneNacionalidadEU"
                      className="label-field"
                    >
                      ¿Tiene nacionalidad de EE.UU.?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="tieneNacionalidadEU"
                        defaultValue={'No'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('tieneNacionalidadEU', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="field-form  col-md-4 col-sm-12">
                    <label htmlFor="esCiudadanoEU" className="label-field">
                      ¿Es ciudadano de los EE.UU.?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="esCiudadanoEU"
                        defaultValue={'No'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('esCiudadanoEU', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="field-form  col-md-4 col-sm-12">
                    <label htmlFor="esResidenteEU" className="label-field">
                      ¿Es residente de los EE.UU.?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="esResidenteEU"
                        defaultValue={'No'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('esResidenteEU', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  {tieneNacionalidadEU === 'Si' ||
                  esCiudadanoEU === 'Si' ||
                  esResidenteEU === 'Si' ? (
                    <>
                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="codigoPostalEU" className="label-field">
                          Código Postal en los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="codigoPostalEU"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('codigoPostalEU')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="telefonoEU" className="label-field">
                          Télefono en los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="telefonoEU"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('telefonoEU')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="tieneRepresentanteEU"
                          className="label-field"
                        >
                          ¿Tiene un apoderado en los EE.UU.?{' '}
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tieneRepresentanteEU"
                            defaultValue={'No'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tieneRepresentanteEU', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="tieneTIN" className="label-field">
                          ¿Tiene identidad para el pago de impuestos (TIN)?{' '}
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tieneTIN"
                            defaultValue={'No'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tieneTIN', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      {tieneTIN === 'Si' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="tin" className="label-field">
                            Número de Identificación Tributaria de los EE.UU.
                            (TIN) <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="tin"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tin', {
                              required: true,
                            })}
                          />
                          {errors.tin?.type === 'required' && (
                            <p className="text-danger">
                              El campo Número de Identificación Tributaria de
                              los EE.UU. (TIN) es requerido
                            </p>
                          )}
                          {selectedBeneficiaryId &&
                            !tin &&
                            errors.tin?.type !== 'required' && (
                              <ErrorText
                                hasError={hasError('taxIdentificationNumber')}
                              />
                            )}
                        </div>
                      ) : null}

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="tarjetaVerde" className="label-field">
                          Tarjeta de Identidad de los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="tarjetaVerde"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('tarjetaVerde')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="direccionEU" className="label-field">
                          Dirección en los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="direccionEU"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('direccionEU')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="seguroSocial" className="label-field">
                          Número de Seguro Social EE.UU. <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="seguroSocial"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('seguroSocial', {
                            required: true,
                          })}
                        />
                        {errors.seguroSocial?.type === 'required' && (
                          <p className="text-danger">
                            El campo Número de Seguro Social EE.UU. es requerido
                          </p>
                        )}
                        {selectedBeneficiaryId &&
                          !seguroSocial &&
                          errors.seguroSocial?.type !== 'required' && (
                            <ErrorText
                              hasError={hasError('socialSecurityNumber')}
                            />
                          )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {!isFormCompleted && !isFormDisqualified ? (
                <div className="row d-flex justify-content-center">
                  <button type="submit" className="mt-5 mb-5 btn-add">
                    Guardar
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeclarationOfBeneficiariesForm;
