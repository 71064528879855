import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './personType.css';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProgressBar } from '../../../shared/store/slices/clientePotencial';
import {
  deleteCookie,
  envars,
  getDecodedCookie,
  modifyCookie,
} from '../../../shared/utils/functions';
import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { GET_PERSONTYPES } from '../../../shared/graphQL/queries';
import { CircularProgress } from '@mui/material';
import {
  azureUserFlowUri,
  AZURE_SESSION_TIME_OUT,
  PERSONTYPE,
} from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';

export default function PersonType() {
  const [accountType, setAccountType] = useState(null);
  const [reload, setReload] = useState(false);
  const { loading, error, data } = useQuery(GET_PERSONTYPES);

  const [animationOut, setAnimationOut] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgressBar({ show: true, value: '50%' }));
    const configDecoded = getDecodedCookie('potencialCustomerConfig');

    if (configDecoded && configDecoded.accountType) {
      setReload(true);
      setAccountType(configDecoded.accountType);
    }

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener los tipos de cuentas', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (accountType && !reload) {
      goTo();
    }
  }, [accountType, reload]);

  const goBack = () => {
    deleteCookie('potencialCustomerConfig');
    window.location.replace(azureUserFlowUri());
  };

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    modifyCookie(
      'potencialCustomerConfig',
      'accountType',
      accountType,
      true,
      timeout
    );
    !PERSONTYPE[accountType.name].isLegal &&
      modifyCookie('potencialCustomerConfig', 'assistUnit', '', true, timeout);
    setAnimationOut(true);
    setTimeout(() => {
      navigate(`/onboarding-digital/assistance-unit`);
    }, 500);
  };

  return (
    <div className="containerOnboarding">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="d-none d-md-block">
            <div className="banner">
              <img
                className="bg"
                src="/images/onboarding/bannerOnboarding_5.jpg"
                alt=""
              />
            </div>
          </Col>
          <Col className="contForm">
            <p className="linkBack" onClick={goBack}>
              <FontAwesomeIcon icon={faAngleLeft} /> Volver
            </p>

            <div className="vAlign">
              <strong className="title">¿Cómo desea invertir?</strong>
              <p className="subTitle">
                Si está pensando en diversificar sus inversiones, lo ideal es
                que lo haga a través de diferentes productos financieros tomando
                en cuenta sus riesgos, plazos y rendimientos.
              </p>
              {/* { config?.firstName }  */}
              <div className="newForm">
                {!loading && !error && data && data.personTypeList && (
                  <div className="cards-container">
                    {data.personTypeList.map((p) => (
                      <div
                        key={p.id}
                        className={`boxButton ${accountType && accountType.id === p.id ? 'selected' : ''}`}
                        onClick={() => {
                          setReload(false);
                          setAccountType(p);
                        }}
                      >
                        <div className="flexLeft">
                          <div className="iconContainer">
                            <img src={PERSONTYPE[p.name].icon} alt="" />
                          </div>
                          <div>
                            <h4 className="rwf-card-title">{p.name}</h4>
                            <p className="card-text">
                              {PERSONTYPE[p.name].desc}
                            </p>

                            <Link
                              target="_blank"
                              to={`/docs/${PERSONTYPE[p.name].pdfName}.pdf`}
                              className="pdf-link"
                              onClick={(e) => e.stopPropagation()}
                            >
                              Ver Requisitos
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {loading && (
                  <div className="w-100 d-flex justify-content-center p-5">
                    <CircularProgress color="warning" />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
