import './App.css';
import { DataContext } from './context/DataContext';
import { NotificationProvider } from './context/NotificationContext';
import { BrowserRouter } from 'react-router-dom';
import ClienteRoutes from './components/ClienteReal/Routes/Routes';
import ClientePotencialApp from './pages/ClientePotencial/Index/Index';
import { ToastContainer } from 'react-toastify';
import ClientePotencialRoutes from './components/NewClientePotencial/Routes';
import { useMutation } from '@apollo/client';
import { envars, getCookie, setCookie } from './shared/utils/functions';
import React, { useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { GET_APP_AUTH } from './shared/graphQL/mutations';
import CommonIndex from './pages/Common';

function App() {
  const [getAuth, { data, loading, error }] = useMutation(GET_APP_AUTH);
  const [searchingCookie, setSearchingCookie] = useState(false);

  useEffect(() => {
    if (!getCookie('CRM_AUTH', false)) {
      setSearchingCookie(true);
      getAuth({ variables: { appId: envars('CRM_APPID') } });
    }
  }, []);

  if (searchingCookie) {
    if (loading) console.log('loading');
    else if (error) console.log(`Error! ${error.message}`);
    else {
      if (data) {
        const jwt = jwt_decode(data.appClientToken.token);
        const time = jwt.exp - jwt.iat;
        setCookie('CRM_AUTH', data.appClientToken.token, {
          'max-age': time,
          path: '/',
        });
      }
    }
  }
  return (
    <>
      <BrowserRouter>
        <DataContext>
          <NotificationProvider>
            <ClientePotencialApp />
            <ClientePotencialRoutes />
            <ClienteRoutes />
            <CommonIndex />
          </NotificationProvider>
        </DataContext>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
}

export default App;
