import { useLazyQuery } from '@apollo/client';
import ActivityBar from '../../../components/ClientePotencial/ActivityBar/ActivityBar';
import CardStockBroker from '../../../components/ClientePotencial/CardStockBroker/CardStockBroker';
import CardSummaryAddAccount from '../../../components/ClientePotencial/CardSummaryAddAccount/CardSummaryAddAccount';
import CardSummaryOfAccounts from '../../../components/ClientePotencial/CardSummaryOfAccounts/CardSummaryOfAccounts';
import './SubDasboardSummaryOfAccounts.css';
import { useEffect } from 'react';
import {
  GET_FORM_RESPONSES_BY_PRIMARY_CONTACT,
  GET_INVESTMENT_PROFILE_SECTION,
} from '../../../shared/graphQL/queries';
import { Breadcrumb } from 'react-bootstrap';
import { useState } from 'react';
import { CircularProgress } from '@mui/material';

const SubDasboardSummaryOfAccounts = () => {
  const [formResponses, setFormResponses] = useState([]);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponsesByPrimaryContact, formResponsesByPrimaryContact] =
    useLazyQuery(GET_FORM_RESPONSES_BY_PRIMARY_CONTACT);
  const [getInvestmentProfileSectionData] = useLazyQuery(
    GET_INVESTMENT_PROFILE_SECTION
  );
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    getFormResponsesByPrimaryContact({
      variables: {
        primaryContactEmail: selectedFormResponse?.lead?.primaryContact?.email,
      },
    });
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponses && formResponses.length > 0) {
      setShowSpinner(false);
    }
  }, [formResponses]);

  useEffect(() => {
    if (formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact) {
      processFormResponses(
        formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
      );
    }
  }, [formResponsesByPrimaryContact]);

  async function processFormResponses(formResponsesByPrimaryContact) {
    if (formResponsesByPrimaryContact) {
      const updatedFormResponses = [];

      for (const formResponse of formResponsesByPrimaryContact) {
        const { ...objectProperties } = formResponse;

        try {
          const profileCompletionPercentage =
            await getProfileCompletionPercentaje(formResponse?.id);
          let hasInvestmentProfileValue = false;
          if (formResponse?.form?.personType?.name === 'Persona Física') {
            hasInvestmentProfileValue = true;
          } else {
            hasInvestmentProfileValue =
              formResponse?.lead?.companyLeadDetails?.applicantType?.name.includes(
                'Negocio'
              );
          }

          if (
            !updatedFormResponses.some((elem) => elem.id === formResponse.id)
          ) {
            updatedFormResponses.push({
              ...objectProperties,
              profileCompletionPercentaje: profileCompletionPercentage,
              hasInvestmentProfile: hasInvestmentProfileValue,
            });
          }
        } catch (error) {
          console.error('Error fetching profile completion percentage:', error);
          setShowSpinner(false);
        }
      }

      setFormResponses(updatedFormResponses);
    }
  }

  const getProfileCompletionPercentaje = (formResponseIdParam) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        // Realizar la consulta GraphQL
        const response = await getInvestmentProfileSectionData({
          variables: {
            formResponseId: formResponseIdParam,
          },
        });

        // Acceder a la propiedad específica que deseas y resolver la Promise con ese valor
        const profileCompletionPercentage =
          response?.data?.investmentProfileSection?.profileCompletionPercentage;
        resolve(profileCompletionPercentage);
      } catch (error) {
        // Rechazar la Promise en caso de error
        reject(error);
      }
    });
  };

  const orderedFormResponses = () => {
    return formResponses?.sort(
      (a, b) => a.formattedFormResponseId.localeCompare(b.formattedFormResponseId),
    );
  }

  return (
    <div className="sub-dashboard" style={{ height: 'inherit' }}>
      <div className="row sub-dashboard-soa-header">
        <div className="col-9">
          <Breadcrumb>
            <Breadcrumb.Item active>
              <strong>Resumen de Cuenta /</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <div className="col-3">
          {selectedFormResponse?.lead?.accountRepresentative &&
          formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
            ?.length ? (
            <CardStockBroker
              imageUrl={
                formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact?.find(
                  (fr) =>
                    fr?.lead?.identification?.replace(/-/g, '') ===
                    selectedFormResponse?.lead?.identification?.replace(/-/g, '')
                )?.lead?.accountRepresentative?.imageURL
              }
            />
          ) : null}
        </div>
      </div>

      <div className="row sub-dashboard-soa-title-container">
        <div className="col-12" style={{ wordWrap: 'break-word' }}>
          <p>
            <strong>Bienvenido, </strong>
            {selectedFormResponse?.lead?.primaryContact?.firstName +
              ' ' +
              selectedFormResponse?.lead?.primaryContact?.lastName}
          </p>
        </div>
      </div>

      <div className="sub-dashboard-soa-content">
        <h3>Cuentas</h3>

        <div className="row" style={{ alignItems: 'flex-start' }}>
          <div className="col-9 sub-dashboard-soa-add-cards">
            {showSpinner ? (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ width: 300, marginRight: 16 }}
              >
                <CircularProgress color="warning" />
              </div>
            ) : (
              <>
                {orderedFormResponses()?.map((formResponse, index) => (
                  <CardSummaryOfAccounts
                    key={index}
                    name={
                      formResponse?.lead?.primaryContact?.firstName +
                      ' ' +
                      formResponse?.lead?.primaryContact?.lastName
                    }
                    personType={formResponse?.form?.personType?.name}
                    formattedFormResponseId={
                      formResponse?.formattedFormResponseId
                    }
                    formResponse={formResponse}
                  />
                ))}
              </>
            )}

            <CardSummaryAddAccount
              routeToNavigate={
                '/onboarding-digital/dashboard/apertura-de-cuenta/unidad-de-asistencia'
              }
            />
          </div>

          <div className="col-3 sub-dashboard-activity-bar-container">
            <ActivityBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubDasboardSummaryOfAccounts;
