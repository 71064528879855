import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import Layout from '../../../layout/index.js';
import { Link } from 'react-router-dom';
export default function Profile() {
  return (
    <Layout>
      <Container fluid>
        <Row>
          <Col>
            <div className="box">
              <Container style={{ padding: '20px' }} fluid>
                <Row>
                  <Col md={2}>
                    <label>Foto:</label>
                  </Col>
                  <Col>
                    <label>Foto:</label>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Nombre Completo:</label>
                  </Col>
                  <Col>
                    <input type="text" placeholder="Correo" value="" />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Cédula:</label>
                  </Col>
                  <Col>
                    <input type="text" placeholder="Correo" value="" />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Email:</label>
                  </Col>
                  <Col>
                    <input type="text" placeholder="Correo" value="" />
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <label>Código Cliente:</label>
                  </Col>
                  <Col>
                    <input type="text" placeholder="Correo" value="" />
                  </Col>
                </Row>
              </Container>
            </div>
            <Container fluid>
              <Row>
                <Col md={10}></Col>
                <Col md={2}>
                  <Link className="btnSubmit" to="/dashboard">
                    Guardar Cambios
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
