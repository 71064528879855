import './WorkAddressForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const WorkAddressForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [paisObtenidoDeLaApi, setPaisObtenidoDeLaApi] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');
  const [provinces, setProvinces] = useState([]);
  const [towns, setTowns] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [nombrePaisSeleccionado, setNombrePaisSeleccionado] = useState('');
  const [idPaisSeleccionado, setIdPaisSeleccionado] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [countriesToDropdown, setCountriesToDropdown] = useState([]);

  const pais = watch('pais');
  const provincia = watch('provincia');
  const municipio = watch('municipio');
  const sector = watch('sector');
  const calle = watch('calle');
  const casaNumero = watch('casaNumero');
  const edificio = watch('edificio');
  const numeroApartamento = watch('numeroApartamento');

  // useEffect(() => {
  //   // if(pais !== "680da2a1-169c-e711-8111-c4346bdcf161") {

  //   // }
  //   setValue("calle", "");
  // }, [pais]);

  useEffect(() => {
    if (countries.length > 0) {
      if (paisObtenidoDeLaApi !== '') {
        //alert(paisObtenidoDeLaApi);
        setValue('pais', paisObtenidoDeLaApi);
        setPaisObtenidoDeLaApi('');
      }

      for (let pais of countries) {
        if (!countriesToDropdown.find((x) => x.label === pais.name)) {
          setCountriesToDropdown((prevState) => [
            ...prevState,
            { label: pais?.name, id: pais?.id },
          ]);
        }
      }
    }
  }, [countries]);

  useEffect(() => {
    if (countriesToDropdown) {
      // console.log(countriesToDropdown);
      // console.log(top100Films);
    }
  }, [countriesToDropdown]);

  useEffect(() => {
    if (idPaisSeleccionado !== '') {
      const paisSeleccionado = countries.find(
        (x) => x.id === idPaisSeleccionado
      );
      setNombrePaisSeleccionado(paisSeleccionado?.name);
    }
  }, [idPaisSeleccionado]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveDataInBD = () => {
    navigate('/formularios/persona-fisica/pep');
  };

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
  };

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle">Dirección Laboral</h3>

          <div className="mb-2">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={countriesToDropdown}
              sx={{ width: 320 }}
              value={selectedValue}
              onChange={handleChange}
              renderInput={(params) => <TextField {...params} label="País" />}
            />
          </div>

          {/* <div className='field-form'>
              <label htmlFor="pais" className='label-field'>País <span>*</span></label>
              <select  id='pais' defaultValue={'DEFAULT'} {...register('pais', {
                  validate: validateNonSelectedOptionInDropdown
              })}>
                  <option value="DEFAULT" disabled>Selecciona</option>
                  {countries.map(country => <option key={country.id} value={country.id}>{country.name}</option>)}
              </select>
              {errors.pais && <p className='text-danger'>Debe seleccionar una opción</p>}
            </div> */}

          {
            // 680da2a1-169c-e711-8111-c4346bdcf161 es el Id de República Dominicana
            nombrePaisSeleccionado === 'República Dominicana' ? (
              <>
                <div className="field-form">
                  <label htmlFor="provincia" className="label-field">
                    Provincia <span>*</span>
                  </label>
                  <select
                    id="provincia"
                    defaultValue={'DEFAULT'}
                    {...register('provincia', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {provinces.map((province) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                  {errors.provincia && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="municipio" className="label-field">
                    Municipio <span>*</span>
                  </label>
                  <select
                    id="municipio"
                    defaultValue={'DEFAULT'}
                    {...register('municipio', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {towns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.name}
                      </option>
                    ))}
                  </select>
                  {errors.municipio && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="sector" className="label-field">
                    Sector <span>*</span>
                  </label>
                  <select
                    id="sector"
                    defaultValue={'DEFAULT'}
                    {...register('sector', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {sectors.map((sector) => (
                      <option key={sector.id} value={sector.id}>
                        {sector.name}
                      </option>
                    ))}
                  </select>
                  {errors.sector && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="field-form">
                  <label htmlFor="direccion" className="label-field">
                    Dirección <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="direccion"
                    {...register('direccion', {
                      required: true,
                    })}
                  />
                  {errors.direccion?.type === 'required' && (
                    <p className="text-danger">
                      El campo Dirección es requerido
                    </p>
                  )}
                </div>
              </>
            )
          }
        </div>

        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle" style={{ visibility: 'hidden' }}>
            Non visible
          </h3>

          {nombrePaisSeleccionado === 'República Dominicana' ? (
            <>
              <div className="field-form">
                <label htmlFor="calle" className="label-field">
                  Calle <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="calle"
                  {...register('calle', {
                    required: true,
                    maxLength: 60,
                  })}
                />
                {errors.calle?.type === 'required' && (
                  <p className="text-danger">El campo Calle es requerido</p>
                )}
                {errors.calle?.type === 'maxLength' && (
                  <p className="text-danger">
                    Solo puede ingresar 60 caracteres como máximo
                  </p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="casaNumero" className="label-field">
                  Casa No. <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="casaNumero"
                  {...register('casaNumero', {
                    required: true,
                    maxLength: 60,
                  })}
                />
                {errors.casaNumero?.type === 'required' && (
                  <p className="text-danger">El campo Casa No. es requerido</p>
                )}
                {errors.casaNumero?.type === 'maxLength' && (
                  <p className="text-danger">
                    Solo puede ingresar 60 caracteres como máximo
                  </p>
                )}
              </div>

              <div className="minor-fields-container">
                <div className="field-form minor-fields">
                  <label htmlFor="edificio" className="label-field">
                    Edificio
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="edificio"
                    {...register('edificio', {
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.edificio?.type === 'required' && (
                    <p className="text-danger">
                      El campo Edificio es requerido
                    </p>
                  )}
                  {errors.edificio?.type === 'maxLength' && (
                    <p className="text-danger">
                      Solo puede ingresar 60 caracteres como máximo
                    </p>
                  )}
                </div>

                <div className="field-form minor-fields">
                  <label htmlFor="numeroApartamento" className="label-field">
                    No. apartamento
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="numeroApartamento"
                    {...register('numeroApartamento', {
                      required: true,
                      maxLength: 60,
                    })}
                  />
                  {errors.numeroApartamento?.type === 'required' && (
                    <p className="text-danger">
                      El campo Número apartamento es requerido
                    </p>
                  )}
                  {errors.numeroApartamento?.type === 'maxLength' && (
                    <p className="text-danger">
                      Solo puede ingresar 60 caracteres como máximo
                    </p>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default WorkAddressForm;
