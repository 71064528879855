import React, { createContext, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Notifications } from '@mui/icons-material';
import './NotificationContext.css';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [showRedDot, setShowRedDot] = useState(false);

  const notifyInfo = (message) => {
    toast(<CustomNotification message={message} />, {
      position: toast.POSITION.TOP_RIGHT,
      closeButton: false,
      autoClose: 5000,
    });
    setShowRedDot(true);
  };
  const resetRedDot = () => {
    setShowRedDot(false);
  };

  return (
    <NotificationContext.Provider
      value={{ notifyInfo, resetRedDot, showRedDot }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

const CustomNotification = ({ message }) => {
  return (
    <div className="custom-notification">
      <Notifications />
      <p>{message}</p>
    </div>
  );
};

export const useNotification = () => useContext(NotificationContext);
