import './CommercialReferencesPage.css';
import ProgressBar from '../../../../../components/ClientePotencial/ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CommercialReferencesForm from '../../../../../components/ClientePotencial/forms/Reusable forms/CommercialReferencesForm/CommercialReferencesForm';
import { getAuthorizationConfig } from '../../../../../shared/utils/functions';

const CommercialReferencesPage = () => {
  const [goToBack, setGoToBack] = useState(false);
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);
  const [isLegalPersonInitialized, setIsLegalPersonInitialized] =
    useState(false);
  const [titlePage, setTitlePage] = useState('');

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  const handleBackNavigation = () => {
    setGoToBack(true);
  };

  const navigateToNextPage = () => {
    if (isLegalPersonInitialized) {
      navigate('/formularios/persona-juridica/marketing-sobre-parval');
    } else {
      navigate('/formularios/persona-fisica/apoderados-o-tutores');
    }
  };

  return (
    <div className="page-container" id="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container3">
        <h3 className="bff-title">{titlePage}</h3>

        <h4 className="bff-sub-title">
          Si tienes más de una referencia, puedes agregarlas todas aquí.
        </h4>

        <CommercialReferencesForm
          goToBack={goToBack}
          setDisableButton={setDisableButton}
        />

        <ProgressBar
          handleClick={navigateToNextPage}
          handleBackNavigation={handleBackNavigation}
          width={'56.25%'}
          percentage={'56.25%'}
          disableButton={disableButton}
        />
      </div>
    </div>
  );
};

export default CommercialReferencesPage;
