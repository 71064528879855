import './MainContactInformationForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import {
  convertBooleanToInput,
  convertInputToBoolean,
} from '../../../../../shared/utils/functions';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import {
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import {
  GET_FATCA_SECTION,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_LEADS,
  GET_LINKAGE_TYPES,
  GET_PARVAL_RELATIONSHIPS,
  GET_PRIMARY_CONTACT,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  SAVE_FATCA_SECTION,
  SAVE_LEAD,
  SAVE_PRIMARY_CONTACT,
  SAVE_PRIMARY_CONTACT_DETAILS,
} from '../../../../../shared/graphQL/mutations';
import { useSelector } from 'react-redux';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';
import { Error } from '@mui/icons-material';

const MainContactInformationForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { data: parvalRelationships } = useQuery(GET_PARVAL_RELATIONSHIPS);
  const { data: linkageTypes } = useQuery(GET_LINKAGE_TYPES);
  const [formResponseId, setFormResponseId] = useState('');
  const [fatcaSectionId, setFatcaSectionId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [
    getPrimaryContactData,
    { data: primaryContactData, loading: loadingContact },
  ] = useLazyQuery(GET_PRIMARY_CONTACT);
  const [
    getFactaSectionData,
    { data: factaSectionData, loading: loadingFatcaSection },
  ] = useLazyQuery(GET_FATCA_SECTION);
  const [
    getFormResponseByIdentification,
    { data: formResponseByIdentification, loading: loadingFormResponse },
  ] = useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [savePrimaryContact] = useMutation(SAVE_PRIMARY_CONTACT, {
    refetchQueries: [
      {
        query: GET_PRIMARY_CONTACT,
        variables: {
          primaryContactEmail: sessionStorage.getItem('email'),
        },
      },
    ],
  });
  const [savePrimaryContactDetails] = useMutation(
    SAVE_PRIMARY_CONTACT_DETAILS,
    {
      refetchQueries: [
        {
          query: GET_PRIMARY_CONTACT,
          variables: {
            primaryContactEmail: sessionStorage.getItem('email'),
          },
        },
      ],
    }
  );
  const [saveFATCASection] = useMutation(SAVE_FATCA_SECTION, {
    refetchQueries: [
      {
        query: GET_FATCA_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [saveLead] = useMutation(SAVE_LEAD, {
    refetchQueries: [
      {
        query: GET_LEADS,
        variables: {
          primaryContactEmail: sessionStorage.getItem('email'),
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const { isContrapart, isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const [loadingSection, setLoadingSection] = useState(true);
  const [initialLoading, setInitialLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const nombres = watch('nombres');
  const apellidos = watch('apellidos');
  const correoContacto = watch('correoContacto');
  const telefonoContacto = watch('telefonoContacto');
  const telefonoSecundario = watch('telefonoSecundario');
  const poseeTinOEin = watch('poseeTinOEin');
  const tinOEin = watch('tinOEin');
  const poseeGIIN = watch('poseeGIIN');
  const giin = watch('giin');
  const tieneRelacion = watch('tieneRelacion');
  const detalleRelacion = watch('detalleRelacion');
  const grupoVinculacion = watch('grupoVinculacion');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      setValue('tipoIdentificacion', selectedFormResponse?.lead?.identificationType?.id);
      setValue('numeroIdentificacion', selectedFormResponse?.lead?.identification);
      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }

    setInitialLoading(true);
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.formResponses) {
      setFormResponseId(formResponseByIdentification?.formResponses[0]?.id);
      getFactaSectionData({
        variables: {
          formResponseId: formResponseByIdentification?.formResponses[0]?.id,
        },
      });

      if (
        formResponseByIdentification?.formResponses[0]?.lead
      ) {
        setValue(
          'tieneRelacion',
          convertBooleanToInput(
            formResponseByIdentification?.formResponses[0]?.lead
            ?.isRelatedToParval
          )
        );
        if (
          formResponseByIdentification?.formResponses[0]?.lead
            ?.linkageType?.id
        ) {
          setValue(
            'grupoVinculacion',
            formResponseByIdentification?.formResponses[0]?.lead
              ?.linkageType?.id
          );
        }

        if (
          formResponseByIdentification?.formResponses[0]?.lead
            ?.parvalRelationship?.id
        ) {
          setValue(
            'detalleRelacion',
            formResponseByIdentification?.formResponses[0]?.lead
              ?.parvalRelationship?.id
          );
        }
      }
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (factaSectionData?.fatcaSection) {
      setFatcaSectionId(factaSectionData?.fatcaSection?.id);
      if (factaSectionData?.fatcaSection?.haveTaxIdentificationNumber) {
        setValue(
          'poseeTinOEin',
          convertBooleanToInput(
            factaSectionData?.fatcaSection?.haveTaxIdentificationNumber
          )
        );
        setValue(
          'tinOEin',
          factaSectionData?.fatcaSection?.taxIdentificationNumber
        );
      } else if (
        factaSectionData?.fatcaSection?.haveTaxIdentificationNumber === false
      ) {
        setValue(
          'poseeTinOEin',
          convertBooleanToInput(
            factaSectionData?.fatcaSection?.haveTaxIdentificationNumber
          )
        );
      }

      if (
        factaSectionData?.fatcaSection
          ?.haveGlobalIntermediaryIdentificationNumber
      ) {
        setValue(
          'poseeGIIN',
          convertBooleanToInput(
            factaSectionData?.fatcaSection
              ?.haveGlobalIntermediaryIdentificationNumber
          )
        );
        setValue(
          'giin',
          factaSectionData?.fatcaSection?.globalIntermediaryIdentificationNumber
        );
      } else if (
        factaSectionData?.fatcaSection
          ?.haveGlobalIntermediaryIdentificationNumber === false
      ) {
        setValue(
          'poseeGIIN',
          convertBooleanToInput(
            factaSectionData?.fatcaSection
              ?.haveGlobalIntermediaryIdentificationNumber
          )
        );
      }
    } else {
      setValue('poseeTinOEin', 'DEFAULT');
      setValue('poseeGIIN', 'DEFAULT');
    }
  }, [factaSectionData]);

  useEffect(() => {
    getPrimaryContactData({
      variables: {
        primaryContactEmail: sessionStorage.getItem('email'),
      },
    });
  }, []);

  useEffect(() => {
    if (primaryContactData) {
      setValue('nombres', primaryContactData?.primaryContact.firstName);
      setValue('apellidos', primaryContactData?.primaryContact.lastName);
      setValue('correoContacto', primaryContactData?.primaryContact.email);

      if (primaryContactData?.primaryContact?.primaryContactDetails) {
        setValue(
          'telefonoContacto',
          primaryContactData?.primaryContact?.primaryContactDetails?.phoneNumber
        );
        setValue(
          'telefonoSecundario',
          primaryContactData?.primaryContact?.primaryContactDetails?.secondaryPhoneNumber
        );
      }
    }
  }, [primaryContactData]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/representante-legal'
    );
  };

  const saveData = () => {
    if (isFormCompleted || isFormDisqualified) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/descripcion-del-negocio'
      );
      return;
    }

    let data = {
      email: correoContacto,
      firstName: nombres,
      lastName: apellidos,
    };

    savePrimaryContact({
      variables: {
        primaryContact: data,
      },
    });

    if (primaryContactData?.primaryContact?.primaryContactDetails?.id) {
      const id = primaryContactData?.primaryContact?.primaryContactDetails?.id;

      data = {
        id: id,
        primaryContactEmail: correoContacto,
        phoneNumber: telefonoContacto,
        secondaryPhoneNumber: telefonoSecundario,
      };
    } else {
      data = {
        primaryContactEmail: correoContacto,
        phoneNumber: telefonoContacto,
        secondaryPhoneNumber: telefonoSecundario,
      };
    }

    savePrimaryContactDetails({
      variables: {
        primaryContactDetails: data,
      },
    });

    // Primero verificar si la seccion existe
    if (fatcaSectionId !== null && fatcaSectionId !== undefined) {
      if (poseeTinOEin === 'Si' && poseeGIIN === 'Si') {
        data = {
          id: fatcaSectionId,
          formResponseId: formResponseId,
          haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
          taxIdentificationNumber: tinOEin,
          haveGlobalIntermediaryIdentificationNumber:
            convertInputToBoolean(poseeGIIN),
          globalIntermediaryIdentificationNumber: giin,
        };
      } else {
        if (poseeTinOEin !== 'Si' && poseeGIIN !== 'Si') {
          data = {
            id: fatcaSectionId,
            formResponseId: formResponseId,
            haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
            haveGlobalIntermediaryIdentificationNumber:
              convertInputToBoolean(poseeGIIN),
          };
        } else if (poseeTinOEin !== 'Si') {
          data = {
            id: fatcaSectionId,
            formResponseId: formResponseId,
            haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
            haveGlobalIntermediaryIdentificationNumber:
              convertInputToBoolean(poseeGIIN),
            globalIntermediaryIdentificationNumber: giin,
          };
        } else if (poseeGIIN !== 'Si') {
          data = {
            id: fatcaSectionId,
            formResponseId: formResponseId,
            haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
            taxIdentificationNumber: tinOEin,
            haveGlobalIntermediaryIdentificationNumber:
              convertInputToBoolean(poseeGIIN),
          };
        }
      }
    } else {
      if (poseeTinOEin === 'Si' && poseeGIIN === 'Si') {
        data = {
          formResponseId: formResponseId,
          haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
          taxIdentificationNumber: tinOEin,
          haveGlobalIntermediaryIdentificationNumber:
            convertInputToBoolean(poseeGIIN),
          globalIntermediaryIdentificationNumber: giin,
        };
      } else {
        if (poseeTinOEin !== 'Si' && poseeGIIN !== 'Si') {
          data = {
            formResponseId: formResponseId,
            haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
            haveGlobalIntermediaryIdentificationNumber:
              convertInputToBoolean(poseeGIIN),
          };
        } else if (poseeTinOEin !== 'Si') {
          data = {
            formResponseId: formResponseId,
            haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
            haveGlobalIntermediaryIdentificationNumber:
              convertInputToBoolean(poseeGIIN),
            globalIntermediaryIdentificationNumber: giin,
          };
        } else if (poseeGIIN !== 'Si') {
          data = {
            formResponseId: formResponseId,
            haveTaxIdentificationNumber: convertInputToBoolean(poseeTinOEin),
            taxIdentificationNumber: tinOEin,
            haveGlobalIntermediaryIdentificationNumber:
              convertInputToBoolean(poseeGIIN),
          };
        }
      }
    }

    saveFATCASection({
      variables: {
        fatcaSection: data,
      },
    });

    data = {
      primaryContactEmail: correoContacto,
      identification: selectedFormResponse?.lead?.identification,
      identificationTypeId: selectedFormResponse?.lead?.identificationType.id,
      parvalRelationshipId: tieneRelacion === 'Si' ? detalleRelacion : undefined,
      linkageTypeId: tieneRelacion === 'Si' ? grupoVinculacion : undefined,
      personTypeId: selectedFormResponse?.formResponse?.personType?.id,
    };

    saveLead({
      variables: {
        lead: data,
      },
    });

    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/descripcion-del-negocio'
    );
  };

  const filterRelationships = () => {
    if (!isContrapart) {
      return parvalRelationships?.parvalRelationships?.filter(
        (relationship) =>
          relationship.name !== 'Parval será cliente de La Contraparte' &&
          relationship.name !==
            'La Contraparte realizará una operación con PARVAL' &&
          relationship.name !== 'Ambas'
      );
    } else {
      return parvalRelationships?.parvalRelationships
        ?.filter(
          (relationship) =>
            relationship.name === 'Parval será cliente de La Contraparte' ||
            relationship.name ===
              'La Contraparte realizará una operación con PARVAL' ||
            relationship.name === 'Ambas'
        )
        .reverse();
    }
  };

  useEffect(() => {
    if (
      initialLoading &&
      !loadingContact &&
      !loadingFatcaSection &&
      !loadingFormResponse
    ) {
      setLoadingSection(false);
      setInitialLoading(false);
    }
  }, [
    initialLoading,
    loadingContact,
    loadingFatcaSection,
    loadingFormResponse,
  ]);

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key !== 'clientErrors' && key !== 'isValid' && key !== '__typename') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const isSectionError = () => {
    return fieldsByComplexSections['Contacto Principal'].some((flied) =>
      validationErrors?.includes(flied)
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div className="row fields-container">
          <h3 className="formTitle">
            Contacto Principal
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Contacto Principal'}
              description={
                'Este formulario se enfoca en centralizar la información clave para establecer una comunicación efectiva con la empresa. En esta sección, se capturan los datos del contacto principal, proporcionando un canal central para la interacción. Este formulario facilita la inclusión de información esencial como nombres, apellidos, correo electrónico, cargo y número telefónico del contacto principal.'
              }
            />
            {isSectionError() && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={'Hay campos incompletos en esta sección'}
                />
              </span>
            )}
          </h3>
        </div>

        {loadingSection ? (
          <div
            className="w-100 d-flex align-items-center justify-content-center p-5"
            style={{ height: '70%' }}
          >
            <CircularProgress color="warning" />
          </div>
        ) : (
          <>
            <div className="row fields-container">
              <div className="row">
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="nombres" className="label-field">
                    Nombres <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="nombres"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('nombres', {
                      required: true,
                    })}
                  />
                  {errors.nombres?.type === 'required' && (
                    <p className="text-danger">El campo Nombres es requerido</p>
                  )}
                  {!nombres && errors.nombres?.type !== 'required' && (
                    <ErrorText hasError={hasError('firstName')} />
                  )}
                </div>

                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="apellidos" className="label-field">
                    Apellidos <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="apellidos"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('apellidos', {
                      required: true,
                    })}
                  />
                  {errors.apellidos?.type === 'required' && (
                    <p className="text-danger">
                      El campo Apellidos es requerido
                    </p>
                  )}
                  {!apellidos && errors.apellidos?.type !== 'required' && (
                    <ErrorText hasError={hasError('lastName')} />
                  )}
                </div>

                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="correo-contacto" className="label-field">
                    Correo de contacto <span>*</span>
                  </label>
                  <input
                    type="email"
                    className="input-form"
                    placeholder=""
                    id="correo-contacto"
                    readOnly
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('correoContacto', {
                      required: true,
                    })}
                  />
                  {errors.correoContacto?.type === 'required' && (
                    <p className="text-danger">
                      El campo Apellidos es requerido
                    </p>
                  )}
                  {!correoContacto &&
                    errors.correoContacto?.type !== 'required' && (
                      <ErrorText hasError={hasError('email')} />
                    )}
                </div>
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="telefono-contacto" className="label-field">
                    Teléfono de contacto{' '}
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="telefono-contacto"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('telefonoContacto')}
                  />
                </div>
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="telefono-contacto-secundario" className="label-field">
                    Teléfono secundario{' '}
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="telefono-contacto-secundario"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('telefonoSecundario')}
                  />
                </div>
              </div>
            </div>

            <div className="row fields-container">
              <p className="subsection-title">
                RELATIVO A FATCA (FOREIGN ACCOUNT TAX COMPLIANCE ACT){' '}
              </p>
              <div className="row">
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="posee-tin-o-ein" className="label-field">
                    ¿Posee la empresa un Tax Identification Number (TIN) o
                    Employer Identification Number (EIN)? <span>*</span>
                  </label>
                  <div className="selectWrapper">
                    <select
                      id="posee-tin-o-ein"
                      defaultValue={'DEFAULT'}
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('poseeTinOEin', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {errors.poseeTinOEin && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                  {(!poseeTinOEin || poseeTinOEin === 'DEFAULT') &&
                    !errors.poseeTinOEin && (
                      <ErrorText
                        hasError={hasError('haveTaxIdentificationNumber')}
                      />
                    )}
                </div>
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="poseeGIIN" className="label-field">
                    ¿Posee la empresa un Global Intermediary Identification
                    Number? <span>*</span>
                  </label>
                  <div className="selectWrapper">
                    <select
                      id="poseeGIIN"
                      defaultValue={'DEFAULT'}
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('poseeGIIN', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {errors.poseeGIIN && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                  {(!poseeGIIN || poseeGIIN === 'DEFAULT') &&
                    !errors.poseeGIIN && (
                      <ErrorText
                        hasError={hasError(
                          'haveGlobalIntermediaryIdentificationNumber'
                        )}
                      />
                    )}
                </div>
              </div>
            </div>

            <div className="row fields-container">
              {poseeTinOEin === 'Si' ? (
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="tin-o-ein" className="label-field">
                    TIN o EIN <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="tin-o-ein"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('tinOEin', {
                      required: true,
                      maxLength: 30,
                    })}
                  />
                  {errors.tinOEin?.type === 'required' && (
                    <p className="text-danger">Este campo es requerido</p>
                  )}
                  {errors.tinOEin?.type === 'maxLength' && (
                    <p className="text-danger">
                      Solo puede ingresar 30 caracteres como máximo
                    </p>
                  )}
                  {!tinOEin && errors.tinOEin?.type !== 'required' && (
                    <ErrorText hasError={hasError('taxIdentificationNumber')} />
                  )}
                </div>
              ) : null}

              {poseeGIIN === 'Si' ? (
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="giin" className="label-field">
                    Global Intermediary Identification Number <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="giin"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('giin', {
                      required: true,
                      maxLength: 30,
                    })}
                  />
                  {errors.giin?.type === 'required' && (
                    <p className="text-danger">Este campo es requerido</p>
                  )}
                  {errors.giin?.type === 'maxLength' && (
                    <p className="text-danger">
                      Solo puede ingresar 30 caracteres como máximo
                    </p>
                  )}
                  {!giin && errors.giin?.type !== 'required' && (
                    <ErrorText
                      hasError={hasError(
                        'globalIntermediaryIdentificationNumber'
                      )}
                    />
                  )}
                </div>
              ) : null}
            </div>

            <div className="row fields-container">
              <p className="subsection-title">RELACIÓN CON PARVAL</p>
              <div className="row">
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="tieneRelacion" className="label-field">
                  ¿Tiene relación con parval? <span>*</span>
                  </label>
                  <div className="selectWrapper">
                    <select
                      id="tieneRelacion"
                      defaultValue={'DEFAULT'}
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('tieneRelacion', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {errors.tieneRelacion && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                { tieneRelacion === 'Si' ? <>
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                    <label htmlFor="detalle-relacion" className="label-field">
                      Detalle de relación <span>*</span>
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="detalle-relacion"
                        defaultValue={'DEFAULT'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('detalleRelacion', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {filterRelationships()
                          ? filterRelationships().map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))
                          : null}
                      </select>
                    </div>
                    {errors.detalleRelacion && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                    {(!detalleRelacion || detalleRelacion === 'DEFAULT') &&
                      !errors.detalleRelacion && (
                        <ErrorText hasError={hasError('parvalRelationship')} />
                      )}
                  </div>

                  {!isContrapart ? (
                    <div className="field-form col-lg-6 col-sm-12 ps-4">
                      <label htmlFor="grupo-vinculacion" className="label-field">
                        Grupo de vinculación <span>*</span>
                      </label>
                      <div className="selectWrapper">
                        <select
                          id="grupo-vinculacion"
                          defaultValue={'DEFAULT'}
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('grupoVinculacion', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {linkageTypes?.linkageTypes.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      {errors.grupoVinculacion && (
                        <p className="text-danger">Debe seleccionar una opción</p>
                      )}
                      {(!grupoVinculacion || grupoVinculacion === 'DEFAULT') &&
                        !errors.grupoVinculacion && (
                          <ErrorText hasError={hasError('linkageType')} />
                        )}
                    </div>
                  ) : null}
                </> : null}
                
              </div>
            </div>
          </>
        )}

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default MainContactInformationForm;
