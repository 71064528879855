import './FatcaForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';

const FatcaForm = ({ validateForm, setValidateForm, goToBack }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [fatcaProxyOptions, setFatcaProxyOptions] = useState([]);

  // TODO algunos de esos campos llevan N/A como valor por defecto
  const tieneNacionalidadEstadounidense = watch(
    'tieneNacionalidadEstadounidense'
  );
  const esCiudadanoEstadounidense = watch('esCiudadanoEstadounidense');
  const esResidenteEstadounidense = watch('esResidenteEstadounidense');
  const tieneSosialSecurityNumber = watch('tieneSosialSecurityNumber');
  const numeroSeguridadSocialEstadounidense = watch(
    'numeroSeguridadSocialEstadounidense'
  );
  const numeroIdentificacionPagoImpuestos = watch(
    'numeroIdentificacionPagoImpuestos'
  );
  const tarjetaResidentePermanente = watch('tarjetaResidentePermanente');
  const direccionEstadosUnidos = watch('direccionEstadosUnidos');
  const telefonoResidencial = watch('telefonoResidencial');
  const codigoPostal = watch('codigoPostal');
  const tieneApoderadoEstadosUnidos = watch('tieneApoderadoEstadosUnidos');

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-fisica/beneficiario-final');
    }
  }, [goToBack]);

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    //getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveDataInBD = () => {
    navigate('/formularios/persona-fisica/cliente-profesional');
  };

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-md-6 col-sm-12 pe-4 columns">
          <div className="field-form">
            <label
              htmlFor="tiene-nacionalidad-estadounidense"
              className="label-field"
            >
              ¿Tiene nacionalidad de E.E.U.U.? <span>*</span>
            </label>
            <select
              id="tiene-nacionalidad-estadounidense"
              defaultValue={'DEFAULT'}
              {...register('tieneNacionalidadEstadounidense', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {fatcaProxyOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {errors.tieneNacionalidadEstadounidense && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          <div className="field-form">
            <label
              htmlFor="es-ciudadano-estadounidense"
              className="label-field"
            >
              ¿Es ciudadano de los E.E.U.U.? <span>*</span>
            </label>
            <select
              id="es-ciudadano-estadounidense"
              defaultValue={'DEFAULT'}
              {...register('esCiudadanoEstadounidense', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {fatcaProxyOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {errors.esCiudadanoEstadounidense && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          <div className="field-form">
            <label
              htmlFor="es-residente-estadounidense"
              className="label-field"
            >
              ¿Es residente de los E.E.U.U.? <span>*</span>
            </label>
            <select
              id="es-residente-estadounidense"
              defaultValue={'DEFAULT'}
              {...register('esResidenteEstadounidense', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {fatcaProxyOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {errors.esResidenteEstadounidense && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {tieneNacionalidadEstadounidense === '1' ? (
            <>
              <div className="field-form">
                <label
                  htmlFor="tiene-sosial-security-number"
                  className="label-field"
                >
                  ¿Posee un número de seguro social (social security number)?{' '}
                  <span>*</span>
                </label>
                <select
                  id="tiene-sosial-security-number"
                  defaultValue={'DEFAULT'}
                  {...register('tieneSosialSecurityNumber', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {fatcaProxyOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {errors.tieneSosialSecurityNumber && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>

              {tieneSosialSecurityNumber === '1' ? (
                <>
                  <div className="field-form">
                    <label
                      htmlFor="numero-seguridad-social-estadounidense"
                      className="label-field"
                    >
                      Número de seguridad social E.E.U.U. <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="numero-seguridad-social-estadounidense"
                      {...register('numeroSeguridadSocialEstadounidense', {
                        required: true,
                      })}
                    />
                    {errors.numeroSeguridadSocialEstadounidense?.type ===
                      'required' && (
                      <p className="text-danger">Este campo es requerido</p>
                    )}
                  </div>
                </>
              ) : null}

              <div className="field-form">
                <label
                  htmlFor="numero-identificacion-pago-impuestos"
                  className="label-field"
                >
                  Número de identificación para el pago de impuestos{' '}
                  <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="numero-identificacion-pago-impuestos"
                  {...register('numeroIdentificacionPagoImpuestos', {
                    required: true,
                  })}
                />
                {errors.numeroIdentificacionPagoImpuestos?.type ===
                  'required' && (
                  <p className="text-danger">Este campo es requerido</p>
                )}
              </div>

              <div className="field-form">
                <label
                  htmlFor="tarjeta-residente-permanente"
                  className="label-field"
                >
                  Tarjeta de residente permanente (green card) <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="tarjeta-residente-permanente"
                  {...register('tarjetaResidentePermanente', {
                    required: true,
                  })}
                />
                {errors.tarjetaResidentePermanente?.type === 'required' && (
                  <p className="text-danger">Este campo es requerido</p>
                )}
              </div>

              <div className="field-form">
                <label
                  htmlFor="direccion-estados-unidos"
                  className="label-field"
                >
                  Dirección en los Estados Unidos <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="direccion-estados-unidos"
                  {...register('direccionEstadosUnidos', {
                    required: true,
                  })}
                />
                {errors.direccionEstadosUnidos?.type === 'required' && (
                  <p className="text-danger">Este campo es requerido</p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="telefono-residencial" className="label-field">
                  Teléfono residencial <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="telefono-residencial"
                  {...register('telefonoResidencial', {
                    required: true,
                  })}
                />
                {errors.telefonoResidencial?.type === 'required' && (
                  <p className="text-danger">Este campo es requerido</p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="codigo-postal" className="label-field">
                  Código postal <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="codigo-postal"
                  {...register('codigoPostal', {
                    required: true,
                  })}
                />
                {errors.codigoPostal?.type === 'required' && (
                  <p className="text-danger">Este campo es requerido</p>
                )}
              </div>

              <div className="field-form">
                <label
                  htmlFor="tiene-apoderado-estados-unidos"
                  className="label-field"
                >
                  ¿Tiene un apoderado en los Estados Unidos? <span>*</span>
                </label>
                <select
                  id="tiene-apoderado-estados-unidos"
                  defaultValue={'DEFAULT'}
                  {...register('tieneApoderadoEstadosUnidos', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {fatcaProxyOptions.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {errors.tieneApoderadoEstadosUnidos && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default FatcaForm;
