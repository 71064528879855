import IconPersonaFisica from '../../shared/icons/iconPersonaFisica.svg';
import IconPersonaJuridica from '../../shared/icons/iconPersonaJuridica.svg';
import { BusinessCenter, Paid } from '@mui/icons-material';
import IconSantoDomingo from '../../shared/icons/iconSantoDomingo.svg';
import IconSantiago from '../../shared/icons/iconSantiago.svg';
import IconMacoris from '../../shared/icons/iconMacoris.svg';
import React from 'react';

export const COOKIENAMES = {
  identity: 'identity',
  user: 'user',
  profile: 'profile',
};

export const PRODUCTSELEMENTS = {
  moneda: {
    type: 'text',
    name: 'Moneda',
  },
  posicion: {
    type: 'numero',
    name: 'Posición',
  },
  tipo_cambio: {
    type: 'monto',
    name: 'Tipo cambio',
  },
  titulo: {
    type: 'text',
    name: 'Título',
  },
  valor_adquisicion: {
    type: 'monto',
    name: 'Valor adquisición',
  },
  cupon: {
    type: 'monto',
    name: 'Cupón',
  },
  fecha_ingreso: {
    type: 'fecha',
    name: 'Fecha de ingreso',
  },
  fecha_inicio: {
    type: 'fecha',
    name: 'Fecha de inicio',
  },
  fecha_vencimiento: {
    type: 'fecha',
    name: 'Fecha de vencimiento',
  },
  pago_vencimiento: {
    type: 'monto',
    name: 'Pago vencimiento',
  },
  rend: {
    type: 'monto',
    name: 'Rendimiento',
  },
  valor_nominal: {
    type: 'monto',
    name: 'Valor nominal',
  },
  valor_transado: {
    type: 'monto',
    name: 'Valor transado',
  },
  tipo_pago_interes: {
    type: 'text',
    name: 'Tipo pago interés',
  },
  cantidad_titulos: {
    type: 'monto',
    name: 'Cantidad títulos',
  },
  'Cantidad de Títulos': {
    type: 'monto',
    name: 'Cantidad de títulos',
  },
  'Interés acumulado': {
    type: 'monto',
    name: 'Interés acumulado',
  },
  'Pago de Interés': {
    type: 'text',
    name: 'Tipo pago interés',
  },
};

export const VARIABLEFIJAAPIDATE = {
  '01': 'enero',
  '02': 'febrero',
  '03': 'marzo',
  '04': 'abril',
  '05': 'mayo',
  '06': 'junio',
  '07': 'julio',
  '08': 'agosto',
  '09': 'septiembre',
  10: 'octubre',
  11: 'noviembre',
  12: 'diciembre',
};

export const PERSONTYPE = {
  'Persona Física': {
    icon: IconPersonaFisica,
    pdfName: 'persona-fisica',
    desc: `Es una cuenta para profesionales independientes (contadores, abogados, ingenieros, entre otros)
        o personas con un oficio, que realizan actividades generadoras de obligaciones. Se identifican con su nombre y apellido.`,
    isLegal: false,
  },
  'Persona Jurídica': {
    icon: IconPersonaJuridica,
    pdfName: 'persona-juridica',
    desc: `Son las entidades identificadas con una denominación social que se constituyen de acuerdo a las disposiciones de la ley sobre Sociedades Comerciales y Empresas Individuales de Responsabilidad Limitada establecidas en la ley No.479-08.`,
    isLegal: true,
  },
};

export const CLIENTTYPE = {
  negocios: {
    name: 'Negocios',
    icon: <BusinessCenter style={{ height: 70, width: 70 }} />,
    isBusiness: true,
  },
  tesorería: {
    name: 'Tesorería',
    icon: <Paid style={{ height: 70, width: 70 }} />,
    isBusiness: false,
  },
};

export const BRANCHTYPE = {
  lincoln: {
    icon: IconSantoDomingo,
  },
  macorís: {
    icon: IconMacoris,
  },
  caballeros: {
    icon: IconSantiago,
  },
};

const USERFLOWURL =
  'https://clientesparvalqa.b2clogin.com/clientesparvalqa.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN&client_id=816c8c48-e616-40ab-8962-834ee1a67930&nonce=defaultNonce&redirect_uri={{enviroment}}&scope=openid&response_type=id_token&prompt=login';

export const azureUserFlowUri = function () {
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;
  const destinationUrl = `${protocol}//${host}:${port}/redirect-actived-user-flow`;
  return USERFLOWURL.replace('{{enviroment}}', destinationUrl);
};

export const AZURE_SESSION_TIME_OUT = 3600;

export const SOCIALNETWORKS = {
  facebook: 'https://www.facebook.com/ParvalRD/',
  instagram: 'https://www.instagram.com/parvalrd/',
  linkedin: 'https://www.linkedin.com/company/parval-puesto-de-bolsa/',
  twitter: 'https://twitter.com/parval_rd',
};

export const CUSTOMERSERVICECONTACT = {
  email: 'info@parval.com.do',
  telephone: '809-560-0909 Ext.: 23333',
};
