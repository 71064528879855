import './AccountManager.css';
import NotificationIcon from '../NotificationIcon/NotificationIcon';
import { useNavigate } from 'react-router-dom';
import {
  GET_DUE_DILIGENCE_SECTION,
  GET_FORM_RESPONSES_BY_PRIMARY_CONTACT,
} from '../../../shared/graphQL/queries';
import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { getDomain } from '../../../shared/utils/functions';
import { useNotification } from '../../../context/NotificationContext';

const AccountManager = () => {
  const [getFormResponsesByPrimaryContact, formResponsesByPrimaryContact] =
    useLazyQuery(GET_FORM_RESPONSES_BY_PRIMARY_CONTACT);
  const [getDueDiligenceSectionData] = useLazyQuery(GET_DUE_DILIGENCE_SECTION);
  const [displayOptions, setDisplayOptions] = useState(false);
  const [selectedFormResponse, setFormResponse] = useState({});
  const [formResponseId, setFormResponseId] = useState('');
  const { resetRedDot, showRedDot } = useNotification();

  const navigate = useNavigate();

  useEffect(() => {
    setFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    getFormResponsesByPrimaryContact({
      variables: {
        primaryContactEmail: selectedFormResponse?.lead?.primaryContact?.email,
      },
    });
  }, [selectedFormResponse]);

  useEffect(() => {
    if (
      formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact &&
      formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
        ?.length > 0
    ) {
      const formResponseSearched =
        formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact.find(
          (elem) => elem?.lead?.identification === selectedFormResponse?.lead?.identification
        );
      setFormResponseId(formResponseSearched?.formattedFormResponseId);
    }
  }, [formResponsesByPrimaryContact]);

  const changeFormResponse = async (formResponse) => {
    sessionStorage.setItem('selectedFormResponse', JSON.stringify(formResponse));
    const currentDomain = getDomain();

    const dueDiligence = await getDueDiligenceSectionData({
      variables: { formResponseId: formResponse.id },
    });

    if (dueDiligence?.dueDiligenceSection?.isObligatedSubject) {
      sessionStorage.setItem(
        'obligatedSubject',
        JSON.stringify(dueDiligence.dueDiligenceSection.obligatedSubjectType)
      );
    } else {
      sessionStorage.removeItem('obligatedSubject');
    }

    if (
      formResponse?.lead?.companyLeadDetails?.applicantType.name.includes(
        'Negocio'
      )
    ) {
      sessionStorage.setItem('hasInvestmentProfile', true);
    } else {
      sessionStorage.setItem('hasInvestmentProfile', false);
    }

    if (formResponse?.form?.personType?.name === 'Persona Jurídica') {
      window.location.href =
        currentDomain +
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-general';
    } else {
      window.location.href =
        currentDomain +
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/datos-generales';
    }
  };

  const gotoActualFormResponse = () => {
    const formResponse = JSON.parse(sessionStorage.getItem('selectedFormResponse'));
    
    if (formResponse?.form?.name === 'Persona Jurídica') {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-general'
      );
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/datos-generales'
      );
    }
  };

  return (
    <div className="accmanager-container">
      <div
        className="accmanagerPadding"
        style={{ borderBottom: '1px solid #F4F7FE' }}
      >
        <div className="acc-name">
          <div className="acc-names-container">
            <span>
              {selectedFormResponse?.lead?.primaryContact?.firstName +
                ' ' +
                selectedFormResponse?.lead?.primaryContact?.lastName}
            </span>
            <span
              className="mt-2 acc-option"
              onClick={() => gotoActualFormResponse()}
            >
              {formResponseId ? formResponseId : ''}
            </span>
          </div>
          {formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
            ?.length > 1 && (
            <div
              className="acc-icons"
              onClick={() => setDisplayOptions(!displayOptions)}
            >
              <ExpandLess style={{ magin: '0' }} />
              <ExpandMore style={{ magin: '0' }} />
            </div>
          )}
        </div>

        {displayOptions && (
          <div
            className={`acc-options
                        ${formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact?.length > 2 ? ' acc-scrollable' : ''}
                    `}
          >
            {formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact?.map(
              (elem) =>
                elem?.formattedFormResponseId !== formResponseId && (
                  <div
                    className="mb-1"
                    key={elem?.formattedFormResponseId}
                    onClick={() => changeFormResponse(elem)}
                  >
                    <p className="m-0 p-0 my-2 acc-option">
                      {elem?.formattedFormResponseId}
                    </p>
                  </div>
                )
            )}
          </div>
        )}
      </div>
      <div className="am-footer">
        <NotificationIcon />

        <div className="am-sumary-text">
          <span
            onClick={() => {
              navigate('/onboarding-digital/dashboard/resumen-de-cuentas');
              resetRedDot();
            }}
          >
            Resumen de Cuentas
          </span>
        </div>
      </div>
    </div>
  );
};

export default AccountManager;
