import { Route, Routes, useLocation } from 'react-router-dom';
import ClientePotencialIndex from '../../pages/NewClientePotencial/Index';
import ProgressBar from './ProgressBar/ProgressBar';
import { useSelector } from 'react-redux';

export default function ClientePotencialRoutes() {
  const { progressBar } = useSelector((state) => state.clientePotencialSlice);
  const location = useLocation();

  return (
    <>
      {progressBar &&
      progressBar.show &&
      location.pathname.includes('/onboarding-digital') ? (
        <ProgressBar width={progressBar.value} />
      ) : null}
      <Routes>
        <Route path={'onboarding-digital'} element={<ClientePotencialIndex />}>
          <Route path={'person-type'} />
          <Route path={'assistance-unit'} />
          <Route path={'sub-type'} />
          <Route path={'select-branch'} />
          <Route path={'company-type'} />
          <Route path={'select-business-representative'} />
          <Route path={'identity-verification'} />
          <Route path={'waiting-side'} />
        </Route>
      </Routes>
    </>
  );
}
