import './PasswordRecoveryRequestNewPassword.css';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const PasswordRecoveryRequestNewPassword = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm();
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');

  // password errors
  const [passwordErrors, setPasswordErrors] = useState([]);

  /*useEffect(() => {
        // Lógica que se ejecutará en cada cambio del formulario
        console.log("Se ha producido un cambio en el formulario");
        console.log(passwordErrors);

        if(password !== undefined && confirmPassword !== undefined) {
            validateFields(password, confirmPassword);
        }
    }, [password, confirmPassword]);*/

  const validateIfPasswordsAreEquals = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      setPasswordErrors((prevState) => [
        ...prevState,
        'Las contraseñas deben ser iguales.',
      ]);
    }
  };

  const validatePassword = (password) => {
    if (!isNaN(password[0]) || password[0] !== password[0]?.toUpperCase()) {
      setPasswordErrors((prevState) => [
        ...prevState,
        'La contraseña debe iniciar con una letra mayúscula.',
      ]);
    }
  };

  const validateConfirmPassword = (confirmPassword) => {
    if (
      !isNaN(confirmPassword[0]) ||
      confirmPassword[0] !== confirmPassword[0]?.toUpperCase()
    ) {
      setPasswordErrors((prevState) => [
        ...prevState,
        'La confirmación de contraseña debe iniciar con una letra mayúscula.',
      ]);
    }
  };

  const validateIfPasswordsHaveSpecialCharacters = (
    password,
    confirmPassword
  ) => {
    const specialCharacters = [
      '*',
      '/',
      '.',
      '+',
      '-',
      '^',
      '#',
      '@',
      '~',
      '%',
      '-',
      ',',
      '=',
      '!',
      ';',
      ':',
      '[',
      ']',
      '{',
      '}',
      '(',
      ')',
      '~',
      '\\',
      '&',
      '$',
    ];

    let hasSpecialCharacter = false;

    if (password.length > 0 || confirmPassword.length > 0) {
      for (const specialCharacter of specialCharacters) {
        if (
          password.includes(specialCharacter) &&
          confirmPassword.includes(specialCharacter)
        ) {
          hasSpecialCharacter = true;
          break;
        }
      }

      if (hasSpecialCharacter == false) {
        setPasswordErrors((prevState) => [
          ...prevState,
          'La contraseña debe incluir por lo menos un caracter especial.',
        ]);
      }
    }
  };

  const validateFields = (password, confirmPassword) => {
    setPasswordErrors([]);

    validatePassword(password);
    validateConfirmPassword(confirmPassword);
    validateIfPasswordsAreEquals(password, confirmPassword);
    validateIfPasswordsHaveSpecialCharacters(password, confirmPassword);
  };

  const completeRegister = () => {
    if (password !== undefined && confirmPassword !== undefined) {
      validateFields(password, confirmPassword);
    }
  };

  return (
    <div className="rdr-form-container">
      <div className="rdr-logo-login-container">
        <img className="logoParval" alt="logo" src="/logo.png" />
      </div>
      <h3 className="rdr-title mt-4">Recuperación de contraseña</h3>
      <form className="rdr-form" onSubmit={handleSubmit(completeRegister)}>
        {passwordErrors.length > 0
          ? passwordErrors.map((error, index) => {
              return (
                <div className="text-danger mb-3 w-100" key={index}>
                  • {error}
                </div>
              );
            })
          : null}

        <input
          type="password"
          placeholder="Nueva Contraseña (mínimo 8 caracteres)"
          autoComplete="off"
          {...register('password', {
            required: true,
            minLength: 8,
          })}
        />
        {errors.password?.type === 'required' && (
          <p className="text-danger">El campo contraseña es requerido</p>
        )}
        {errors.password?.type === 'minLength' && (
          <p className="text-danger">
            La contraseña debe tener 8 caracteres como mínimo
          </p>
        )}

        <input
          type="password"
          placeholder="Confirma tu Nueva Contraseña"
          autoComplete="off"
          {...register('confirmPassword', {
            required: true,
            minLength: 8,
          })}
        />
        {errors.confirmPassword?.type === 'required' && (
          <p className="text-danger">
            El campo confirmación de contraseña es requerido
          </p>
        )}
        {errors.confirmPassword?.type === 'minLength' && (
          <p className="text-danger">
            La confirmación de contraseña debe tener 8 caracteres como mínimo
          </p>
        )}

        <button
          type="submit"
          style={{ marginBottom: 0, width: '100%' }}
          className="btnSubmit"
        >
          Recuperar Contraseña
        </button>
      </form>
    </div>
  );
};

export default PasswordRecoveryRequestNewPassword;
