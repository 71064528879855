import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './waitingSide.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProgressBar } from '../../../shared/store/slices/clientePotencial';
import {
  getDecodedCookie,
  getRedirectURIOnboarding,
} from '../../../shared/utils/functions';
import { azureUserFlowUri } from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';

export default function WaitingSide() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const configDecoded = getDecodedCookie('potencialCustomerConfig');

    if (!configDecoded?.primaryContact) {
      showToast('No posee acceso a esta ruta');
      window.location.replace(azureUserFlowUri());
      return;
    }

    const redirect = getRedirectURIOnboarding(configDecoded);

    if (redirect?.navigate) {
      showToast(redirect.toast);
      navigate(redirect.navigate);
      return;
    }

    let width = '100%';
    const intervalId = setInterval(() => {
      dispatch(setProgressBar({ show: true, value: width }));
      width = width === '100%' ? '0%' : '100%';
    }, 700);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const showToast = (message) => {
    toast(message, {
      type: 'error',
      theme: 'colored',
    });
  };

  return (
    <div className="cliente-potencial-container-register h-100">
      <Container className="p-0 m-0 h-100" fluid>
        <Row className="h-100">
          <Col className="cliente-potencial-register-right-side">
            <img className="logoParval" alt="logo" src="/logo.png" />
            <div
              className="cliente-potencial-register-form-container"
              style={{ maxWidth: 'none' }}
            >
              <div
                className="cliente-potencial-register-form-content"
                style={{ width: '100%' }}
              >
                <div
                  className="cliente-potencial-register-form"
                  style={{ marginTop: '-75px' }}
                >
                  <div className="mb-5">
                    <div className="cliente-potencial-register-gif-image mx-auto">
                      Aqui va SLIDer de carga
                    </div>
                  </div>
                </div>
                <div className="cliente-potencial-register-title text-center">
                  <h3
                    className="fw-bold px-4"
                    style={{ color: '#3f4c5e', fontSize: 50 }}
                  >
                    Necesitamos unos minutos, estamos verificando tu
                    identidad...
                  </h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
