import './ObligatedSubjectForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import {
  GET_DUE_DILIGENCE_SECTION,
  GET_DUE_DILIGENCE_SECTION_FORM,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { SAVE_DUE_DELIGENCE_SECTION } from '../../../../../shared/graphQL/mutations';
import { useSelector } from 'react-redux';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const ObligatedSubjectForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { typeObligatedSubject, isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const [formResponseId, setFormResponseId] = useState('');
  const [getDueDiligenceSection, dueDiligenceSection] = useLazyQuery(
    GET_DUE_DILIGENCE_SECTION,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [getDueDiligenceForm, dueDiligenceForm] = useLazyQuery(
    GET_DUE_DILIGENCE_SECTION_FORM
  );
  const [saveDueDiligenceSection] = useMutation(SAVE_DUE_DELIGENCE_SECTION, {
    refetchQueries: [
      {
        query: GET_DUE_DILIGENCE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });

  const [validationErrors, setValidationErrors] = useState([]);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [obligatedSubject, setObligatedSubject] = useState(null);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [answerInputTypes, setAnswerInputTypes] = useState([]);

  useEffect(() => {
    const form = watch((data) => {
      Object.entries(data).forEach(([key, value]) => {
        if (key.includes('radio-')) {
          const question = key.replace('radio-', '');
          const answer = value;
          setAnswerInputTypes((inputAnswers) => {
            let newAnswers = [...inputAnswers];
            if (answer === 'Si' || answer === 'No') {
              const preAnswer = newAnswers.find(
                (newAnswer) => newAnswer.dueDiligenceQuestionId === question
              );

              if (preAnswer) {
                newAnswers = newAnswers.map((newAnswer) => {
                  if (
                    preAnswer.dueDiligenceQuestionId ===
                    newAnswer.dueDiligenceQuestionId
                  ) {
                    if (preAnswer.id) {
                      return {
                        id: newAnswer?.id,
                        dueDiligenceQuestionId: question,
                        answer: answer === 'Si',
                      };
                    } else {
                      return {
                        dueDiligenceQuestionId: question,
                        answer: answer === 'Si',
                      };
                    }
                  } else {
                    return newAnswer;
                  }
                });
              } else {
                newAnswers.push({
                  dueDiligenceQuestionId: question,
                  answer: answer === 'Si',
                });
              }
            } else {
              newAnswers = newAnswers.filter(
                (newAnswer) => newAnswer.dueDiligenceQuestionId !== question
              );
            }

            return newAnswers;
          });
        }
      });
    });
    return () => form.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
    setObligatedSubject(JSON.parse(sessionStorage.getItem('obligatedSubject')));
  }, []);

  useEffect(() => {
    if (typeObligatedSubject) {
      setObligatedSubject(typeObligatedSubject);
    }
  }, [typeObligatedSubject]);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data?.formResponses?.length > 0) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      getDueDiligenceForm({
        variables: {
          applicantTypeId:
            formResponseByIdentification?.data?.formResponses[0]?.lead
              ?.companyLeadDetails?.applicantType.id,
          subApplicantTypeId:
            formResponseByIdentification?.data?.formResponses[0]?.lead
              ?.companyLeadDetails?.subApplicantType.id,
        },
      });

      getDueDiligenceSection({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (
      dueDiligenceSection.data?.dueDiligenceSection?.id &&
      dueDiligenceForm.data?.dueDiligenceForm?.id
    ) {
      setGlobalLoading(false);

      if (
        dueDiligenceSection.data?.dueDiligenceSection
          ?.dueDiligenceSectionDetails.id
      ) {
        getFormInfo();
      }
    }
  }, [dueDiligenceSection, dueDiligenceForm]);

  const getFormInfo = () => {
    setValue(
      'nombreOficialResponsable',
      dueDiligenceSection.data?.dueDiligenceSection?.dueDiligenceSectionDetails
        ?.noMoneyLaunderingOfficialName
    );
    setValue(
      'cargo',
      dueDiligenceSection.data?.dueDiligenceSection?.dueDiligenceSectionDetails
        ?.noMoneyLaunderingOfficialPosition
    );
    setValue(
      'razonNoOficialResponsable',
      dueDiligenceSection.data?.dueDiligenceSection?.dueDiligenceSectionDetails
        ?.noMoneyLaunderingOfficialReason
    );

    const answers =
      dueDiligenceSection.data?.dueDiligenceSection?.dueDiligenceSectionDetails?.dueDiligenceAnswers?.map(
        (answer) => ({
          id: answer.id,
          answer: answer.answer,
          dueDiligenceQuestionId: answer.dueDiligenceQuestion.id,
        })
      );

    answers.forEach((answer) => {
      setValue(
        `radio-${answer.dueDiligenceQuestionId}`,
        answer.answer ? 'Si' : 'No'
      );
    });

    dueDiligenceForm?.data?.dueDiligenceForm?.questions.forEach((question) => {
      const exists = answers.find(
        (answer) => answer.dueDiligenceQuestionId === question.id
      );

      if (!exists) {
        setValue(`radio-${question.id}`, 'N/A');
      }
    });

    dueDiligenceForm?.data?.dueDiligenceForm?.constructionCompaniesQuestions.forEach(
      (question) => {
        const exists = answers.find(
          (answer) => answer.dueDiligenceQuestionId === question.id
        );

        if (!exists) {
          setValue(`radio-${question.id}`, 'N/A');
        }
      }
    );
  };

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/direcciones-de-oficinas'
    );
  };

  const nombreOficialResponsable = watch('nombreOficialResponsable');
  const cargo = watch('cargo');
  const razonNoOficialResponsable = watch('razonNoOficialResponsable');

  const saveData = async () => {
    const data = {
      id: dueDiligenceSection?.data?.dueDiligenceSection?.id,
      formResponseId: formResponseId,
      isObligatedSubject:
        dueDiligenceSection?.data?.dueDiligenceSection?.isObligatedSubject,
      obligatedSubjectTypeId: obligatedSubject.id,
      dueDiligenceFormId: dueDiligenceForm.data?.dueDiligenceForm?.id,
      noMoneyLaunderingOfficialName: nombreOficialResponsable,
      noMoneyLaunderingOfficialPosition: cargo,
      noMoneyLaunderingOfficialReason: razonNoOficialResponsable,
      answerInputTypes: answerInputTypes,
    };

    await saveDueDiligenceSection({
      variables: {
        dueDiligenceSection: data,
      },
    });

    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/principales-accionistas'
    );
  };

  const getQuestion = (questionString) => {
    return dueDiligenceForm?.data?.dueDiligenceForm?.questions.find(
      (question) => question.question.includes(questionString)
    );
  };

  const getConstructionCompaniesQuestion = (questionString) => {
    return dueDiligenceForm?.data?.dueDiligenceForm?.constructionCompaniesQuestions.find(
      (question) => question.question.includes(questionString)
    );
  };

  const getAnswer = (questionId) => {
    return (
      answerInputTypes.find(
        (answerInputType) =>
          answerInputType.dueDiligenceQuestionId === questionId
      ) ?? {}
    );
  };

  const isConstructionCompanyWithoutComplianceProgram = () => {
    let hasComplianceProgram = false;
    let hasApprovedComplianceProgram = false;
    let hasOfficer = false;

    const isConstructionCompany =
      dueDiligenceSection.data?.dueDiligenceSection?.dueDiligenceSectionDetails
        ?.obligatedSubjectType &&
      dueDiligenceSection.data?.dueDiligenceSection?.dueDiligenceSectionDetails
        .obligatedSubjectType.name === 'Empresas constructoras';

    const hasComplianceProgramQuestion = getQuestion(
      'Tiene la entidad un programa de cumplimiento'
    );

    if (hasComplianceProgramQuestion) {
      const hasComplianceProgramAnswer = getAnswer(
        hasComplianceProgramQuestion.id
      );
      hasComplianceProgram = !!hasComplianceProgramAnswer.answer;
    }

    const hasApprovedComplianceProgramQuestion = getQuestion(
      'El programa de cumplimiento es aprobado'
    );

    if (hasApprovedComplianceProgramQuestion) {
      const hasApprovedComplianceProgramAnswer = getAnswer(
        hasApprovedComplianceProgramQuestion.id
      );
      hasApprovedComplianceProgram =
        !!hasApprovedComplianceProgramAnswer.answer;
    }

    const complianceOfficerQuestion = getQuestion(
      'La entidad ha designado a un Oficial de Cumplimiento'
    );

    if (complianceOfficerQuestion) {
      const complianceOfficerAnswer = getAnswer(complianceOfficerQuestion.id);
      hasOfficer = !!complianceOfficerAnswer.answer;
    }

    const constructionCompanyWithoutComplianceProgram =
      isConstructionCompany &&
      !hasComplianceProgram &&
      !hasApprovedComplianceProgram &&
      !hasOfficer;

    if (!constructionCompanyWithoutComplianceProgram) {
      const realEstateQuestion = getConstructionCompaniesQuestion(
        'La empresa realiza actividades de ventas de inmuebles'
      );
      const registeredCompany = getConstructionCompaniesQuestion(
        'La empresa se encuentra registrada en la DGII'
      );

      for (const answerInputType of answerInputTypes) {
        if (
          answerInputType.dueDiligenceQuestionId === realEstateQuestion?.id ||
          answerInputType.dueDiligenceQuestionId === registeredCompany?.id
        ) {
          answerInputType.answer = null;
        }
      }
    }

    return constructionCompanyWithoutComplianceProgram;
  };

  const hasComplianceOfficer = () => {
    const complianceOfficerQuestion = getQuestion(
      'La entidad ha designado a un Oficial de Cumplimiento'
    );

    if (complianceOfficerQuestion) {
      const complianceOfficerAnswer = getAnswer(complianceOfficerQuestion.id);
      return !!complianceOfficerAnswer.answer;
    }

    return false;
  };

  const isRealEstateCompanyWithoutComplianceProgram = () => {
    const complianceOfficerQuestion = getConstructionCompaniesQuestion(
      'La empresa realiza actividades de ventas de inmuebles'
    );

    if (complianceOfficerQuestion) {
      const complianceOfficerAnswer = getAnswer(complianceOfficerQuestion.id);
      return !!complianceOfficerAnswer.answer;
    }

    return false;
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'dueDiligenceSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const isSectionError = () => {
    return fieldsByComplexSections['Sujeto Obligado'].some((flied) =>
      validationErrors?.includes(flied)
    );
  };

  return (
    <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic h-100">
      <div className="row fields-container mt-3">
        <h3 className="formTitle">
          Sujeto Obligado
          <ModalInfo
            Icon={<FontAwesomeIcon icon={faCircleInfo} />}
            title={'Sujeto Obligado'}
            description={
              'Se entiende por Sujeto Obligado la persona física o jurídica que, en virtud de la Ley 155-17 está obligada al cumplimiento de obligaciones destinadas a prevenir, detectar, evaluar y mitigar el riesgo de lavado de activos.'
            }
          />
          {isSectionError() && (
            <span style={{ marginLeft: 30 }}>
              <ModalInfo
                color="white"
                bgcolor="#B90E0A"
                Icon={
                  <Error
                    style={{
                      color: '#B90E0A',
                      fontSize: 25,
                      marginTop: '-5px',
                    }}
                  />
                }
                description={'Esta sección no ha sido completada'}
              />
            </span>
          )}
        </h3>
      </div>

      {!globalLoading ? (
        <>
          <>
            <div className="row fields-container">
              <div className="col-10">
                <table className="table table-bordered">
                  <tbody>
                    {dueDiligenceForm?.data?.dueDiligenceForm?.questions?.map(
                      (question, index) => (
                        <tr key={question.id}>
                          <td style={{ width: '75%' }}>
                            <label className="label-field">
                              {index + 1}. {question.question} <span>*</span>
                            </label>
                            {errors[question.id] && (
                              <p className="text-danger">
                                Debe seleccionar una opción
                              </p>
                            )}
                            {isSectionError() && !errors[question.id] && !watch(`radio-${question.id}`)  && (
                              <div className='mt-4'>
                                <ErrorText hasError={true} />
                              </div>
                            )}
                          </td>
                          <td style={{ width: '25%' }}>
                            <div className="sof-radio-container">
                              <div className="me-2">
                                <input
                                  type="radio"
                                  value="Si"
                                  className="me-1"
                                  disabled={
                                    (isFormCompleted || isFormDisqualified) ||
                                    obligatedSubject?.name ===
                                      'No aplica Sujeto Obligado'
                                  }
                                  {...register(`radio-${question.id}`, {
                                    required: true,
                                  })}
                                />
                                <label>Si</label>
                              </div>

                              <div className="me-2">
                                <input
                                  type="radio"
                                  value="No"
                                  className="me-1"
                                  disabled={
                                    (isFormCompleted || isFormDisqualified) ||
                                    obligatedSubject?.name ===
                                      'No aplica Sujeto Obligado'
                                  }
                                  {...register(`radio-${question.id}`, {
                                    required: true,
                                  })}
                                />
                                <label>No</label>
                              </div>

                              <div className="me-2">
                                <input
                                  type="radio"
                                  value="N/A"
                                  className="me-1"
                                  disabled={
                                    (isFormCompleted || isFormDisqualified) ||
                                    obligatedSubject?.name ===
                                      'No aplica Sujeto Obligado'
                                  }
                                  {...register(`radio-${question.id}`, {
                                    required: true,
                                  })}
                                />
                                <label>N/A</label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}

                    {isConstructionCompanyWithoutComplianceProgram()
                      ? dueDiligenceForm?.data?.dueDiligenceForm?.constructionCompaniesQuestions?.map(
                          (question, index) => (
                            <tr key={question.id}>
                              <td style={{ width: '75%' }}>
                                <label className="label-field">
                                  {index +
                                    1 +
                                    dueDiligenceForm?.data?.dueDiligenceForm
                                      ?.questions.length}
                                  . {question.question} <span>*</span>
                                </label>
                                {errors[`radio-${question.id}`] && (
                                  <p className="text-danger">
                                    Debe seleccionar una opción
                                  </p>
                                )}
                                {isSectionError() && !errors[question.id] && (
                                  <ErrorText hasError={true} />
                                )}
                              </td>
                              <td style={{ width: '25%' }}>
                                <div className="sof-radio-container">
                                  <div className="me-2">
                                    <input
                                      type="radio"
                                      value="Si"
                                      className="me-1"
                                      disabled={
                                        (isFormCompleted || isFormDisqualified) ||
                                        obligatedSubject?.name ===
                                          'No aplica Sujeto Obligado'
                                      }
                                      {...register(`radio-${question.id}`, {
                                        required: true,
                                      })}
                                    />
                                    <label>Si</label>
                                  </div>

                                  <div className="me-2">
                                    <input
                                      type="radio"
                                      value="No"
                                      className="me-1"
                                      disabled={
                                        (isFormCompleted || isFormDisqualified) ||
                                        obligatedSubject?.name ===
                                          'No aplica Sujeto Obligado'
                                      }
                                      {...register(`radio-${question.id}`, {
                                        required: true,
                                      })}
                                    />
                                    <label>No</label>
                                  </div>

                                  <div className="me-2">
                                    <input
                                      type="radio"
                                      value="N/A"
                                      className="me-1"
                                      disabled={
                                        (isFormCompleted || isFormDisqualified) ||
                                        obligatedSubject?.name ===
                                          'No aplica Sujeto Obligado'
                                      }
                                      {...register(`radio-${question.id}`, {
                                        required: true,
                                      })}
                                    />
                                    <label>N/A</label>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      : null}
                  </tbody>
                </table>
                {hasComplianceOfficer() ? (
                  <div className="d-flex alig-items-center mt-2">
                    <div>
                      <label className="label-field">
                        Nombre oficial Responsable <span>*</span>
                      </label>
                      <div className="field-form">
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="nombre-oficial-responsable"
                          disabled={isFormCompleted || isFormDisqualified}
                          readOnly={
                            obligatedSubject?.name ===
                            'No aplica Sujeto Obligado'
                          }
                          {...register('nombreOficialResponsable', {
                            required: true,
                          })}
                        />
                        {errors.nombreOficialResponsable?.type ===
                          'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                        {isSectionError() &&
                          !nombreOficialResponsable &&
                          !errors.nombreOficialResponsable?.type ===
                            'required' && <ErrorText hasError={true} />}
                      </div>
                    </div>

                    <div className="ms-4">
                      <label className="label-field">
                        Cargo <span>*</span>
                      </label>
                      <div className="field-form">
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="nombre-oficial-responsable"
                          disabled={isFormCompleted || isFormDisqualified}
                          readOnly={
                            obligatedSubject?.name ===
                            'No aplica Sujeto Obligado'
                          }
                          {...register('cargo', {
                            required: true,
                          })}
                        />
                        {errors.cargo?.type === 'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                        {isSectionError() &&
                          !cargo &&
                          !errors.cargo?.type === 'required' && (
                            <ErrorText hasError={true} />
                          )}
                      </div>
                    </div>
                  </div>
                ) : null}

                {isRealEstateCompanyWithoutComplianceProgram() ? (
                  <div className="d-flex alig-items-center mt-2">
                    <div>
                      <label className="label-field">
                        Favor explicar razones por la cual no posee un Programa
                        de Cumplimiento y Oficial de Cumplimiento <span>*</span>
                      </label>
                      <div className="field-form">
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="nombre-oficial-responsable"
                          disabled={isFormCompleted || isFormDisqualified}
                          readOnly={
                            obligatedSubject?.name ===
                            'No aplica Sujeto Obligado'
                          }
                          {...register('razonNoOficialResponsable', {
                            required: true,
                          })}
                        />
                        {errors.razonNoOficialResponsable?.type ===
                          'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                        {isSectionError() &&
                          !razonNoOficialResponsable &&
                          !errors.razonNoOficialResponsable?.type ===
                            'required' && <ErrorText hasError={true} />}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>

          <div className="buttons-control-container">
            <button
              type="submit"
              className="btn-back"
              onClick={handleBackNavigation}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              &#160; Atrás
            </button>
            <button
              type={isFormCompleted || isFormDisqualified ? 'button' : 'submit'}
              onClick={() => {
                (isFormCompleted || isFormDisqualified) &&
                  navigate(
                    '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/principales-accionistas'
                  );
              }}
              className={`btn-continue`}
            >
              Siguiente &#160;
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </>
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center p-5">
          <CircularProgress color="warning" />
        </div>
      )}
    </form>
  );
};

export default ObligatedSubjectForm;
