import './NationalityInformationForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';

const NationalityInformationForm = ({
  validateForm,
  setValidateForm,
  goToBack,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [nationalities, setNationalities] = useState([]);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');
  const [provinces, setProvinces] = useState([]);
  const [towns, setTowns] = useState([]);
  const [sectors, setSectors] = useState([]);

  const fechaNacimiento = watch('fechaNacimiento');
  const lugarNacimiento = watch('lugarNacimiento');
  const tieneSegundaNacionalidad = watch('tieneSegundaNacionalidad');
  const segundaNacionalidad = watch('segundaNacionalidad');

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-fisica/informacion-de-contacto');
    }
  }, [goToBack]);

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  // useEffect(() => {
  //   getFormInfo();
  // }, []);

  // const getFormInfo = () => {

  // }

  const saveDataInBD = () => {
    navigate('/formularios/persona-fisica/informacion-de-residencia');
  };

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12 pe-4 columns">
          <div className="field-form">
            <label htmlFor="fecha-nacimiento" className="label-field">
              Fecha de nacimiento
            </label>
            <input
              type="date"
              className="input-form"
              placeholder=""
              id="fecha-nacimiento"
              {...register('fechaNacimiento', {
                required: true,
              })}
            />
            {errors.fechaNacimiento?.type === 'required' && (
              <p className="text-danger">
                El campo Fecha de Nacimiento es requerido
              </p>
            )}
          </div>

          <div className="field-form">
            <label htmlFor="lugar-nacimiento" className="label-field">
              Lugar de Nacimiento
            </label>
            <input
              type="text"
              className="input-form"
              placeholder=""
              id="lugar-nacimiento"
              {...register('lugarNacimiento', {
                required: true,
              })}
            />
            {errors.lugarNacimiento?.type === 'required' && (
              <p className="text-danger">
                El campo Lugar de Nacimiento es requerido
              </p>
            )}
          </div>

          <div className="field-form">
            <label htmlFor="tiene-segunda-nacionalidad" className="label-field">
              ¿Posee una segunda nacionalidad?
            </label>
            <select
              id="tiene-segunda-nacionalidad"
              defaultValue={'DEFAULT'}
              {...register('tieneSegundaNacionalidad', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value={'Si'}>Si</option>
              <option value={'No'}>No</option>
            </select>
            {errors.tieneSegundaNacionalidad && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {tieneSegundaNacionalidad === 'Si' ? (
            <div className="field-form">
              <label htmlFor="segunda-nacionalidad" className="label-field">
                Segunda nacionalidad
              </label>
              <select
                id="segunda-nacionalidad"
                defaultValue={'DEFAULT'}
                {...register('segundaNacionalidad')}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {nationalities.map((nationality) => (
                  <option key={nationality.id} value={nationality.id}>
                    {nationality.name}
                  </option>
                ))}
              </select>
              {errors.segundaNacionalidad && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>
          ) : null}
        </div>
      </div>
      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default NationalityInformationForm;
