/* eslint-disable array-callback-return */
import './SubSectionCard.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CLIENTEPOTENCIALROUTES } from '../../../shared/constants/app-constants';
import { CLIENTEPOTENCIALORIGINROUTE } from '../../../pages/ClientePotencial/Index/Index';
import React, { useEffect, useState } from 'react';
import { ExpandMore, ExpandLess, Error } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedSection } from '../../../shared/store/slices/clientePotencial';
import { ErrorTooltip } from '../../../shared/UIElements/Tooltips/Tooltips';
import { fieldsByComplexSections } from '../../../shared/utils/errorsValidation';

const DASHBOARDROUTE = CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.self;

const ACCOUNTOPPENINGNAME =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening.name;

// RUTAS DE PERSONA JURIDICA
const COMPANYINFORMATIONCHILDRENROUTES =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening
    .children.companyInformation.children;
const FINANCIALINFORMATIONCHILDRENROUTES =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening
    .children.financialInformation.children;
const PARVALCUSTOMERINFORMATIONCHILDRENROUTES =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening
    .children.parvalCustomerInformation.children;

// RUTAS DE PERSONA FISICA
const CUSTOMERIDENTIFICATIONROUTES =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening
    .children.customerIdentification.children;
const FINANCIALINFORMATIONCHILDRENROUTESPHYSICALPERSON =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening
    .children.financialInformationPhysicalPerson.children;
const RELATEDCHILDRENROUTES =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening
    .children.related.children;

// COMMON
const DOCUMENTS =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children.accountOpening
    .children.documents;

const SubSectionCard = ({ errors, error, name, index, child = {} }) => {
  const location = useLocation();
  const [routesToNavigate, setRoutesToNavigate] = useState({});
  const [displayMenu, setDispplayMenu] = useState(false);
  const {
    selectedSection,
    hasBCRDAccount,
    isListedOnStock,
    isContrapart,
    isForeign,
    typeObligatedSubject,
    validation,
  } = useSelector((state) => state.clientePotencialSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let selectedFormResponse = {};

  useEffect(() => {
    if (sessionStorage.getItem('selectedFormResponse')) {
      selectedFormResponse = JSON.parse(sessionStorage.getItem('selectedFormResponse'));
    }

    if (selectedFormResponse?.form?.personType?.name === 'Persona Jurídica') {
      // RUTAS PARA PERSONA JURIDICA
      if (name === 'Información De Mi Empresa') {
        setRoutesToNavigate(COMPANYINFORMATIONCHILDRENROUTES);
      } else if (name === 'Información de Cliente Parval') {
        setRoutesToNavigate(PARVALCUSTOMERINFORMATIONCHILDRENROUTES);
      } else if (name === 'Información Financiera e Inversiones') {
        setRoutesToNavigate(FINANCIALINFORMATIONCHILDRENROUTES);
      }
    } else {
      // RUTAS PARA PERSONA FISICA
      if (name === 'Identificación del cliente') {
        setRoutesToNavigate(CUSTOMERIDENTIFICATIONROUTES);
      } else if (name === 'Información Financiera e Inversiones') {
        setRoutesToNavigate(FINANCIALINFORMATIONCHILDRENROUTESPHYSICALPERSON);
      } else if (name === 'Relacionados') {
        setRoutesToNavigate(RELATEDCHILDRENROUTES);
      }
    }
    if (name === 'Documentos') {
      const route = DOCUMENTS.name.replace(
        '{{type}}',
        selectedFormResponse?.form?.personType?.name === 'Persona Jurídica'
          ? 'persona-juridica'
          : 'persona-fisica'
      );
      setRoutesToNavigate(route);
    }
  }, []);

  useEffect(() => {
    if (selectedSection === name) {
      setDispplayMenu(true);
    } else {
      setDispplayMenu(false);
    }
  }, [selectedSection]);

  const contrapartChildren = ['Referencias Comerciales', 'Tarjeta de Firmas'];

  const isRoute = (route) => {
    const base = CLIENTEPOTENCIALORIGINROUTE + DASHBOARDROUTE + '/';
    const sectionName = ACCOUNTOPPENINGNAME;
    return location.pathname.includes(base + sectionName + '/' + route)
      ? 'active-potencial-client'
      : '';
  };

  const mapSectionErrors = {
    'Cuentas Bancarias': 'bankAccountSectionErrors',
    'Referencias Comerciales': 'commercialReferencesSectionErrors',
    'Declaración de Beneficiarios Directos / Indirectos /Relacionados':
      'beneficiariesSectionErrors',
    'Tarjeta de Firmas': 'signaturesSectionErrors',
    'Instrumentos financieros negociados previamente':
      'financialInstrumentsSectionErrors',
    'Instrucción de Liquidación': 'settlementInstructionSectionErrors',
    'Miembros del Consejo': 'counselMembersSectionErrors',
    'Correos Electrónicos de Correspondencia': 'correspondenceEmailSectionErrors',
    Direcciones: 'addressSectionErrors',
    'Empresas Afiliadas': 'relatedCompaniesSectionErrors',
    Accionistas: 'companyShareholdersSectionErrors',
    'Representantes Legales': 'legalRepresentativesSectionErrors',
    Generales2: 'complex',
    'Cliente Profesional': 'investorClassificationSectionErrors',
    'Marque las operaciones de títulos valores y financieras que desea realizar y comprende':
      'complex',
    'Objetivos / Tolerancia al riesgo': 'complex',
    'Distribución de Activos Líquidos': 'complex',
    Generales: 'complex',
    'Contacto Principal': 'complex',
    'Descripción del Negocio': 'complex',
    'Datos Complementarios': 'complex',
    'Sujeto Obligado': 'complex',
  };

  const manageError = (section, name) => {
    let nameSection =
      name === 'Información Financiera e Inversiones' && section === 'Generales'
        ? 'Generales2'
        : section;
    const search = mapSectionErrors[nameSection];

    let response;

    if (search === 'complex') {
      const complexSectionsErrors = [];
      for (const key in validation) {
        if (
          key !== 'clientErrors' &&
          key !== 'isValid' &&
          key !== '__typename' &&
          key !== 'bankAccountSectionErrors' &&
          key !== 'commercialReferencesSectionErrors' &&
          key !== 'beneficiariesSectionErrors' &&
          key !== 'signaturesSectionErrors' &&
          key !== 'financialInstrumentsSectionErrors' &&
          key !== 'investorClassificationSectionErrors' &&
          key !== 'settlementInstructionSectionErrors' &&
          key !== 'counselMembersSectionErrors' &&
          key !== 'correspondenceEmailSectionErrors'
        ) {
          complexSectionsErrors.push(...validation[key]);
        }
      }
      response = complexSectionsErrors.some((complexSection) =>
        fieldsByComplexSections[nameSection].includes(complexSection.fieldId)
      );
    } else {
      response = errors.includes(search);
    }

    return response;
  };

  return (
    <div
      className="sub-section-card cursor-pointer"
      onClick={() => {
        if (!Object.keys(child).length) {
          navigate(routesToNavigate);
        }
        dispatch(setSelectedSection(name));
      }}
    >
      <div className="sub-section-card-name-menu">
        {error ? (
            <ErrorTooltip title={'Aquí tienes secciones incompletas'}>
              <Error className="me-2" style={{ color: '#B90E0A' }} />
            </ErrorTooltip>
          ) : (
            <div className="sub-section-card-name-menu-number">
              <p>{index + 1}</p>
            </div>
          )}
        <span
          className={`me-2${Object.keys(child).length ? '' : ` ${isRoute(routesToNavigate)}`}`}
        >
          {name}
        </span>
        <span className="arrow">
          {name !== 'Documentos' ? (
            displayMenu === true ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )
          ) : null}
        </span>
      </div>
      <div className="sub-section-card-children">
        {displayMenu === true ? (
          <ul>
            {Object.keys(child).length
              ? Object.keys(child).map((key) => {
                  if (
                    (!isContrapart ||
                      !contrapartChildren.includes(child[key])) &&
                    (!hasBCRDAccount || child[key] !== 'Cuentas Bancarias') &&
                    (!(isContrapart && isForeign) || child[key] !== 'Cuentas Bancarias') &&
                    (!(!isContrapart || !isForeign) || child[key] !== 'Instrucción de Liquidación') &&
                    (!(isContrapart && isListedOnStock) ||
                      child[key] !==
                        'Declaración de Beneficiarios Directos / Indirectos /Relacionados') &&
                    (sessionStorage.getItem('obligatedSubject') ||
                      typeObligatedSubject ||
                      child[key] !== 'Sujeto Obligado')
                  ) {
                    return (
                      <li
                        key={key}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        {manageError(child[key], name) ? (
                          <ErrorTooltip title={'Esta sección esta incompleta'}>
                            <Error
                              className="me-2"
                              style={{ color: '#B90E0A' }}
                            />
                          </ErrorTooltip>
                        ) : null}
                        <Link
                          to={routesToNavigate[key]}
                          className={isRoute(routesToNavigate[key])}
                        >
                          <span>{child[key]}</span>
                        </Link>
                      </li>
                    );
                  }
                })
              : null}
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default SubSectionCard;
