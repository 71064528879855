import { useEffect, useState } from 'react';
import {
  getAuthorizationConfigCookie,
  notAuthorizedTokenClienteReal,
  gatewayError,
  requestCanceled,
} from '../../../shared/utils/functions';
import { Skeleton } from '@mui/material';
import { Container, Col, Row, Breadcrumb } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './tasaProductos.css';
import { useDispatch } from 'react-redux';
import Top from '../../../components/ClienteReal/Top/Top';
import CortoPlazo from '../../../components/ClienteReal/CortoPlazo/CortoPlazo';
import RentaFija from '../../../components/ClienteReal/RentaFija/RentaFija';
import RentaVariable from '../../../components/ClienteReal/RentaVariable/RentaVariable';

export default function TasaProductos() {
  const options = [
    { id: 1, title: 'CORTO PLAZO' },
    { id: 2, title: 'RENTA FIJA' },
    { id: 6, title: 'RENTA VARIABLE' },
  ];
  const [dataOptions, setDataOptions] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selector = useSelector((state) => state.clienteRealSlice);
  const dispatch = useDispatch();

  const {
    profile,
    currentAccount,
    session: { showSessionModal },
  } = selector;

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getRates();
  };

  const getRates = async () => {
    setDataLoading(true);
    const config = getAuthorizationConfigCookie();
    try {
      const res = await axios.get(
        '/middleware/api/rate-yield/products',
        config
      );
      if (res.data && res.data.data) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
      if (!requestCanceled(error)) {
        notAuthorizedTokenClienteReal(error, dispatch, showSessionModal);
        await gatewayError(getRates, error);
      }
    }

    setDataLoading(false);
  };

  const setData = async (data) => {
    const result = options.map((o) => {
      return {
        optionId: o.id,
        data: data.filter((d) => d.category.id === o.id),
      };
    });

    setDataOptions(result);
  };

  const routerOptions = (option) => {
    let selected;

    switch (option.id) {
      case options[0].id:
      default:
        selected = (
          <CortoPlazo
            data={dataOptions.find((d) => d.optionId === options[0].id)}
          />
        );
        break;
      case options[1].id:
        selected = (
          <RentaFija
            data={dataOptions.find((d) => d.optionId === options[1].id)}
          />
        );
        break;
      case options[2].id:
        selected = (
          <RentaVariable
            data={dataOptions.find((d) => d.optionId === options[2].id)}
          />
        );
        break;
    }

    return selected;
  };

  return (
    <div className="cliente-real-dashboard-tasa-producto">
      <Top
        profile={profile}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              {currentAccount.titular ? 'Titular' : 'Cotitular'}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{currentAccount.idClient}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              <strong>Tasas y Productos</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title={
          <p
            className="p-0 m-0"
            style={{ fontWeight: 600, fontSize: 30, color: '#4c4c4c' }}
          >
            Tasas y Productos
          </p>
        }
      />
      <div className="content">
        <div className="tasa-producto-container">
          <div className="tasa-producto-box box p-0">
            <div className="tasa-producto-top">
              {options.map((o) => (
                <div
                  className={
                    'tasa-producto-btn ms-3' +
                    (o.id === selectedOption.id ? ' tasa-producto-active' : '')
                  }
                  key={o.id}
                  onClick={() => setSelectedOption(o)}
                >
                  <p className="p-0 m-0">{o.title}</p>
                </div>
              ))}
            </div>
            <div className="tasa-producto-content">
              <Container fluid>
                <Row>
                  {dataLoading ? (
                    <Col
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingBottom: 30,
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        height={590}
                        animation="wave"
                        style={{ backgroundColor: '#dddddd', borderRadius: 10 }}
                      />
                    </Col>
                  ) : (
                    <Col>{routerOptions(selectedOption)}</Col>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
