/* eslint-disable no-useless-escape */

import './GeneralInformationForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  convertBooleanToInput,
  convertInputToBoolean,
  formatSimple,
  parseSimple,
  preFormat,
} from '../../../../../shared/utils/functions';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_COMPANY_ECONOMIC_DETAILS_SECTION,
  GET_COMPANY_ORIGINS,
  GET_COMPANY_RNC_INFO,
  GET_COUNTRIES,
  GET_EQUITY_TYPES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_GENERAL_INFORMATION,
  GET_INVESTMENT_PROFILE_SECTION,
  GET_SOCIETY_TYPES,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import {
  GENERATE_INVESTMENT_PROFILE,
  SAVE_GENERAL_INFORMATION,
} from '../../../../../shared/graphQL/mutations';
import { useDispatch } from 'react-redux';
import {
  setHasBCRDAccount,
  setIsListedOnStock,
  setIsForeign,
} from '../../../../../shared/store/slices/clientePotencial';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { useSelector } from 'react-redux';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';

const GeneralInformationForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [formResponseId, setFormResponseId] = useState('');
  const { data: companyOrigins } = useQuery(GET_COMPANY_ORIGINS);
  const [orderCompanyOrigins, setOrderCompanyOrigins] = useState([]);
  const { data: countries } = useQuery(GET_COUNTRIES);
  const { data: equityTypes } = useQuery(GET_EQUITY_TYPES);
  const { data: societyTypeList } = useQuery(GET_SOCIETY_TYPES);
  const [companyType, setCompanyType] = useState(null);
  const [getGeneralInformationData, generalInformationData] = useLazyQuery(
    GET_GENERAL_INFORMATION,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getCompanyRncInfo, companyRncInfo] =
    useLazyQuery(GET_COMPANY_RNC_INFO);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [getCompanyEconomicDetailsSectionData] = useLazyQuery(
    GET_COMPANY_ECONOMIC_DETAILS_SECTION
  );
  const [saveCompanyInformationSection] = useMutation(
    SAVE_GENERAL_INFORMATION,
    {
      refetchQueries: [
        {
          query: GET_GENERAL_INFORMATION,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [generateInvestmentProfile] = useMutation(GENERATE_INVESTMENT_PROFILE, {
    refetchQueries: [
      {
        query: GET_INVESTMENT_PROFILE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const { isTreasury, isFormCompleted, isFormDisqualified, validation, isContrapart } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const [loadingSection, setLoadingSection] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const dispatch = useDispatch();

  const nombre = watch('nombre');
  const numRegistroMercantil = watch('numRegistroMercantil');
  const origenEmpresa = watch('origenEmpresa');
  const fechaConstitucion = watch('fechaConstitucion');
  const rnc = watch('rnc');
  const tipoSociedad = watch('tipoSociedad');
  const poseeAccionesPortador = watch('poseeAccionesPortador');
  const cotizaEnBolsa = watch('cotizaEnBolsa');
  const [lugarConstitucion, setLugarConstitucion] = useState(null);
  const [inputLugarConstitucion, setInputLugarConstitucion] = useState('');
  const domiciliadoEnRd = watch('domiciliadoEnRd');
  const fechaVencimientoNumRegistroMercantil = watch(
    'fechaVencimientoNumRegistroMercantil'
  );
  const aporteACapital = watch('aporteACapital');
  const tieneBCDR = watch('tieneBCDR');
  const dtc = watch('dtc');
  const custodio = watch('custodio');
  const cuentaNombre = watch('cuentaNombre');

  useEffect(() => {
    if (countries?.countries?.length > 0 && lugarConstitucion) {
      setValue('lugarConstitucion', {
        label: lugarConstitucion?.label,
        id: lugarConstitucion?.id,
      });
      setLugarConstitucion({
        label: lugarConstitucion?.label,
        id: lugarConstitucion?.id,
      });
    }
  }, [countries]);

  useEffect(() => {
    if (
      equityTypes?.equityTypes?.length > 0 &&
      companyType?.id &&
      !generalInformationData?.data?.companyInformationSection?.equityType
    ) {
      const equityType = equityTypes.equityTypes.find((type) =>
        type.name.includes(
          companyType.name.includes('Mixt') ? 'Mixt' : companyType.name
        )
      );
      if (equityType) {
        setValue('aporteACapital', equityType?.id);
      }
    }
  }, [equityTypes, companyType, generalInformationData]);

  useEffect(() => {
    setLoadingSection(true);
    localStorage.setItem('creatingAccount', false);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      setValue('rnc', selectedFormResponse?.lead?.identification);

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data?.formResponses?.length > 0) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      getGeneralInformationData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });

      getCompanyEconomicDetailsSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then((economicDetails) => {
        setCompanyType(
          economicDetails.data?.companyEconomicDetailsSection?.companyType
        );
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (generalInformationData?.data && countries?.countries) {
      getFormInfo();
    }
  }, [generalInformationData, countries]);

  useEffect(() => {
    if (companyOrigins?.companyOrigins) {
      setOrderCompanyOrigins([...companyOrigins.companyOrigins].reverse());
    }
  }, [companyOrigins]);

  useEffect(() => {
    if (companyRncInfo?.data?.companyRncInfoByRnc) {
      setValue(
        'nombre',
        companyRncInfo?.data?.companyRncInfoByRnc?.businessName
      );
    }
  }, [companyRncInfo]);

  const getFormInfo = async () => {
    if (generalInformationData?.data?.companyInformationSection) {
      if (
        !generalInformationData?.data?.companyInformationSection?.commercialName
      ) {
        getCompanyRncInfo({
          variables: {
            companyRnc: rnc?.replace(/\-/g, ''),
          },
        });
      } else {
        setValue(
          'nombre',
          generalInformationData?.data?.companyInformationSection
            ?.commercialName
        );
      }
      setValue(
        'numRegistroMercantil',
        generalInformationData?.data?.companyInformationSection
          ?.registeredNumber
      );
      if (
        generalInformationData?.data?.companyInformationSection?.companyOrigin
          ?.id
      ) {
        setValue(
          'origenEmpresa',
          generalInformationData?.data?.companyInformationSection?.companyOrigin
            ?.id
        );
      }
      setValue(
        'fechaConstitucion',
        generalInformationData?.data?.companyInformationSection
          ?.constitutionDate
          ? formatSimple(
              new Date(
                generalInformationData?.data?.companyInformationSection?.constitutionDate
              )
            )
          : null
      );
      if (
        generalInformationData?.data?.companyInformationSection?.societyType?.id
      ) {
        setValue(
          'tipoSociedad',
          generalInformationData?.data?.companyInformationSection?.societyType
            ?.id
        );
      }
      if (
        generalInformationData?.data?.companyInformationSection?.country?.id
      ) {
        setValue(
          'lugarConstitucion',
          generalInformationData?.data?.companyInformationSection?.country?.id
        );
      }
      if (
        generalInformationData?.data?.companyInformationSection
          ?.bearerShares !== null
      ) {
        setValue(
          'poseeAccionesPortador',
          convertBooleanToInput(
            generalInformationData?.data?.companyInformationSection
              ?.bearerShares
          )
        );
      }
      if (
        generalInformationData?.data?.companyInformationSection
          ?.isListedOnStock !== null
      ) {
        setValue(
          'cotizaEnBolsa',
          convertBooleanToInput(
            generalInformationData?.data?.companyInformationSection
              ?.isListedOnStock
          )
        );
      }
      if (
        generalInformationData?.data?.companyInformationSection?.equityType
          ?.id !== undefined
      ) {
        setValue(
          'aporteACapital',
          generalInformationData?.data?.companyInformationSection?.equityType
            ?.id
        );
      }
      if (
        generalInformationData?.data?.companyInformationSection
          ?.registeredNumberExpirationDate
      ) {
        setValue('domiciliadoEnRd', 'Si');
      } else {
        setValue('domiciliadoEnRd', 'No');
      }
      if (
        generalInformationData?.data?.companyInformationSection
          ?.hasBCRDAccount !== null
      ) {
        setValue(
          'tieneBCDR',
          convertBooleanToInput(
            generalInformationData?.data?.companyInformationSection
              ?.hasBCRDAccount
          )
        );
      }
      setValue(
        'dtc',
        generalInformationData?.data?.companyInformationSection?.clearingNumber
      );
      setValue(
        'custodio',
        generalInformationData?.data?.companyInformationSection?.custodian
      );
      setValue(
        'cuentaNombre',
        generalInformationData?.data?.companyInformationSection?.accountHeld
      );
      setValue(
        'fechaVencimientoNumRegistroMercantil',
        generalInformationData?.data?.companyInformationSection
          ?.registeredNumberExpirationDate
          ? formatSimple(
              new Date(
                generalInformationData?.data?.companyInformationSection?.registeredNumberExpirationDate
              )
            )
          : null
      );

      if (
        generalInformationData?.data?.companyInformationSection?.country?.id
      ) {
        setValue('lugarConstitucion', {
          label:
            generalInformationData?.data?.companyInformationSection?.country
              ?.name,
          id: generalInformationData?.data?.companyInformationSection?.country
            ?.id,
        });
        setLugarConstitucion({
          label:
            generalInformationData?.data?.companyInformationSection?.country
              ?.name,
          id: generalInformationData?.data?.companyInformationSection?.country
            ?.id,
        });
      }
    } else {
      getCompanyRncInfo({
        variables: {
          companyRnc: rnc?.replace(/\-/g, ''),
        },
      });
    }

    setLoadingSection(false);
  };

  const saveData = () => {
    if (isFormCompleted || isFormDisqualified) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/representante-legal'
      );
      return;
    }

    let data = {};

    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (!generalInformationData?.data?.companyInformationSection?.id) {
      // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        commercialName: nombre,
        registeredNumber: numRegistroMercantil,
        companyOriginId: origenEmpresa,
        societyTypeId: tipoSociedad,
        countryId: lugarConstitucion?.id,
        constitutionDate: parseSimple(preFormat(fechaConstitucion)),
        registeredNumberExpirationDate: fechaVencimientoNumRegistroMercantil
          ? parseSimple(preFormat(fechaVencimientoNumRegistroMercantil))
          : null,
        bearerShares: convertInputToBoolean(poseeAccionesPortador),
        isListedOnStock: convertInputToBoolean(cotizaEnBolsa),
        equityTypeId: aporteACapital,
        hasBCRDAccount: convertInputToBoolean(tieneBCDR),
        clearingNumber: dtc,
        custodian: custodio,
        accountHeld: cuentaNombre,
      };
    } else {
      // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        id: generalInformationData.data.companyInformationSection?.id,
        commercialName: nombre,
        registeredNumber: numRegistroMercantil,
        companyOriginId: origenEmpresa,
        societyTypeId: tipoSociedad,
        countryId: lugarConstitucion?.id,
        constitutionDate: parseSimple(preFormat(fechaConstitucion)),
        registeredNumberExpirationDate: fechaVencimientoNumRegistroMercantil
          ? parseSimple(preFormat(fechaVencimientoNumRegistroMercantil))
          : null,
        bearerShares: convertInputToBoolean(poseeAccionesPortador),
        isListedOnStock: convertInputToBoolean(cotizaEnBolsa),
        equityTypeId: aporteACapital,
        hasBCRDAccount: convertInputToBoolean(tieneBCDR),
        clearingNumber: dtc,
        custodian: custodio,
        accountHeld: cuentaNombre,
      };
    }

    saveCompanyInformationSection({
      variables: {
        companyInformationSection: data,
      },
    });

    if(!isContrapart){
      generateInvestmentProfile({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }

    dispatch(setIsListedOnStock(convertInputToBoolean(cotizaEnBolsa)));
    dispatch(setIsForeign(foreignOrigin()));
    dispatch(setHasBCRDAccount(convertInputToBoolean(tieneBCDR)));

    if(isContrapart && foreignOrigin()){
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/instruccion-de-liquidacion'
      );
    }
    else{
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/representante-legal'
      );
    }
  };

  const filterSocietyType = () => {
    if (societyTypeList?.societyTypeList?.length) {
      const list = societyTypeList?.societyTypeList;
      if (companyType?.name === 'Privada') {
        return list.filter((l) => l.name !== 'Institución Gubernamental');
      } else if (companyType?.name === 'Pública') {
        return list.filter((l) => l.name === 'Institución Gubernamental');
      } else {
        return list;
      }
    }
  };

  const foreignOrigin = () => {
    return companyOrigins?.companyOrigins
      ?.find((x) => x.id === origenEmpresa)
      ?.name?.includes('Extranjera');
  };

  useEffect(() => {
    if (lugarConstitucion) {
      const isDominican = lugarConstitucion?.label === 'REPUBLICA DOMINICANA';
      const result = isDominican
        ? companyOrigins?.companyOrigins?.find((x) =>
            x.name.includes('Nacional')
          )
        : companyOrigins?.companyOrigins?.find((x) =>
            x.name.includes('Extranjera')
          );
      if (
          result &&
          !generalInformationData?.data?.companyInformationSection?.companyOrigin
            ?.id
        ) {
        setValue('origenEmpresa', result?.id);
      }
    }
  }, [lugarConstitucion]);

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key !== 'clientErrors' && key !== 'isValid' && key !== '__typename') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const isSectionError = () => {
    return fieldsByComplexSections.Generales.some((flied) =>
      validationErrors?.includes(flied)
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div
          className="row fields-container"
          style={{ height: loadingSection ? '85%' : 'auto' }}
        >
          <h3 className="formTitle">
            Información General
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Información General'}
              description={
                'Este formulario, abarca aspectos fundamentales relacionados con la identidad y características esenciales de la empresa. Proporciona un vistazo integral a diversos aspectos como su estructura legal, origen, y detalles clave que definen su naturaleza operativa. Al completar este formulario, se obtiene una visión panorámica que facilita la comprensión general de la empresa.'
              }
            />
            {isSectionError() && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={'Hay campos incompletos en esta sección'}
                />
              </span>
            )}
          </h3>
          {loadingSection ? (
            <div
              className="w-100 d-flex align-items-center justify-content-center p-5"
              style={{ height: '100%' }}
            >
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="row">
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="rnc" className="label-field">
                  RNC o equivalente<span>*</span>
                </label>
                <input
                  type="text"
                  disabled
                  className="input-form field-disable"
                  placeholder=""
                  id="rnc"
                  {...register('rnc', {
                    required: true,
                  })}
                />
                {errors.rnc?.type === 'required' && (
                  <p className="text-danger">
                    El campo RNC o equivalente es requerido
                  </p>
                )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="nombre" className="label-field">
                  Nombre / Razón social <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="nombre"
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('nombre', {
                    required: true,
                  })}
                />
                {errors.nombre?.type === 'required' && (
                  <p className="text-danger">
                    El campo Nombre / Razón Social es requerido
                  </p>
                )}
                {!nombre && errors.nombre?.type !== 'required' && (
                  <ErrorText hasError={hasError('commercialName')} />
                )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="tipo-sociedad" className="label-field">
                  Tipo de sociedad <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="tipo-sociedad"
                    disabled={isFormCompleted || isFormDisqualified}
                    defaultValue="DEFAULT"
                    {...register('tipoSociedad', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {filterSocietyType()?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.tipoSociedad && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!tipoSociedad || tipoSociedad === 'DEFAULT') &&
                  !errors.tipoSociedad && (
                    <ErrorText hasError={hasError('societyType')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label
                  htmlFor="posee-acciones-portador"
                  className="label-field"
                >
                  ¿Posee acciones al portador? <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="posee-acciones-portador"
                    disabled={isFormCompleted || isFormDisqualified}
                    defaultValue={'DEFAULT'}
                    {...register('poseeAccionesPortador', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
                {errors.poseeAccionesPortador && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!poseeAccionesPortador ||
                  poseeAccionesPortador === 'DEFAULT') &&
                  !errors.poseeAccionesPortador && (
                    <ErrorText hasError={validationErrors?.length} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="cotiza-en-bolsa" className="label-field">
                  ¿Cotiza en bolsa? <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="cotiza-en-bolsa"
                    disabled={isFormCompleted || isFormDisqualified}
                    defaultValue={'DEFAULT'}
                    {...register('cotizaEnBolsa', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
                {errors.cotizaEnBolsa && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!cotizaEnBolsa || cotizaEnBolsa === 'DEFAULT') &&
                  !errors.cotizaEnBolsa && (
                    <ErrorText hasError={validationErrors?.length} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="domiciliado-en-rd" className="label-field">
                  ¿Está domiciliado en RD?<span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="domiciliado-en-rd"
                    disabled={isFormCompleted || isFormDisqualified}
                    defaultValue={'DEFAULT'}
                    {...register('domiciliadoEnRd', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
                {errors.domiciliadoEnRd && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!domiciliadoEnRd || domiciliadoEnRd === 'DEFAULT') &&
                  !errors.domiciliadoEnRd && (
                    <ErrorText hasError={validationErrors?.length} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4 mt-1 special-autocomplete">
                <label htmlFor="lugarConstitucion" className="label-field">
                  Lugar de constitución <span>*</span>
                </label>
                <Autocomplete
                  disablePortal
                  disabled={isFormCompleted || isFormDisqualified}
                  id="controllable-states-demo"
                  value={lugarConstitucion}
                  onChange={(event, newValue) => {
                    setLugarConstitucion(newValue);
                  }}
                  inputValue={inputLugarConstitucion}
                  onInputChange={(event, newInputValue) => {
                    setInputLugarConstitucion(newInputValue);
                  }}
                  options={
                    countries?.countries?.length
                      ? countries?.countries?.map((type) => ({
                          label: type.name,
                          id: type.id,
                        }))
                      : []
                  }
                  renderInput={(params) => (
                    <TextField
                      placeholder={
                        countries?.countries?.length
                          ? 'Busca y selecciona'
                          : 'Cargando...'
                      }
                      {...params}
                      {...register('lugarConstitucion', { required: true })}
                    />
                  )}
                />
                <p
                  className="text-danger"
                  style={{
                    marginTop: 8,
                    visibility: errors.lugarConstitucion ? 'visible' : 'hidden',
                  }}
                >
                  Debe seleccionar una opción
                </p>
                {!lugarConstitucion && !errors.lugarConstitucion && (
                  <ErrorText hasError={hasError('country')} />
                )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="origen-empresa" className="label-field">
                  Origen de la empresa <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="origen-empresa"
                    disabled={isFormCompleted || isFormDisqualified}
                    defaultValue={'DEFAULT'}
                    {...register('origenEmpresa', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {orderCompanyOrigins.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.origenEmpresa && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!origenEmpresa || origenEmpresa === 'DEFAULT') &&
                  !errors.origenEmpresa && (
                    <ErrorText hasError={hasError('companyOrigin')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="fecha-constitucion" className="label-field">
                  Fecha de constitución <span>*</span>
                </label>
                <input
                  type="date"
                  className="input-form"
                  placeholder=""
                  id="fecha-constitucion"
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('fechaConstitucion', {
                    required: true,
                  })}
                />
                {errors.fechaConstitucion?.type === 'required' && (
                  <p className="text-danger">
                    El campo Fecha de constitución es requerido
                  </p>
                )}
                {!fechaConstitucion &&
                  errors.fechaConstitucion?.type !== 'required' && (
                    <ErrorText hasError={hasError('constitutionDate')} />
                  )}
              </div>

              {!isContrapart || (cotizaEnBolsa === 'No' || !cotizaEnBolsa) ? (
                <>
                  <div className="field-form col-lg-6 col-sm-12 ps-4">
                    <label
                      htmlFor="num-registro-mercantil"
                      className="label-field"
                    >
                      No. registro mercantil <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      disabled={isFormCompleted || isFormDisqualified}
                      id="num-registro-mercantil"
                      {...register('numRegistroMercantil', {
                        required: true,
                      })}
                    />
                    {errors.numRegistroMercantil?.type === 'required' && (
                      <p className="text-danger">
                        El campo No. registro mercantil es requerido
                      </p>
                    )}
                    {!numRegistroMercantil &&
                      errors.numRegistroMercantil?.type !== 'required' && (
                        <ErrorText hasError={hasError('registeredNumber')} />
                      )}
                  </div>
                </>
              ) : null}
              {!isContrapart || (cotizaEnBolsa === 'No' || !cotizaEnBolsa) ? (
                <>
                  <div className="field-form col-lg-6 col-sm-12 ps-4">
                    <label
                      htmlFor="fecha-vencimiento-num-registro-mercantil"
                      className="label-field"
                    >
                      Fecha vencimiento de N° registro mercantil <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="input-form"
                      placeholder=""
                      disabled={isFormCompleted || isFormDisqualified}
                      id="fecha-vencimiento-num-registro-mercantil"
                      {...register('fechaVencimientoNumRegistroMercantil', {
                        required: true,
                      })}
                    />
                    {errors.fechaVencimientoNumRegistroMercantil?.type ===
                      'required' && (
                      <p className="text-danger">
                        El campo Fecha vencimiento de N° registro mercantil es
                        requerido
                      </p>
                    )}
                    {!fechaVencimientoNumRegistroMercantil &&
                      errors.fechaVencimientoNumRegistroMercantil?.type !==
                        'required' && (
                        <ErrorText
                          hasError={hasError('registeredNumberExpirationDate')}
                        />
                      )}
                  </div>
                </>
              ) : null}

              {isTreasury ? (
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="tiene-bcdr" className="label-field">
                    ¿Tiene cuenta en el Banco Central? <span>*</span>
                  </label>
                  <div className="selectWrapper">
                    <select
                      id="tiene-bcdr"
                      disabled={isFormCompleted || isFormDisqualified}
                      defaultValue={'DEFAULT'}
                      {...register('tieneBCDR', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {errors.tieneBCDR && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                  {(!tieneBCDR || tieneBCDR === 'DEFAULT') &&
                    !errors.tieneBCDR && (
                      <ErrorText hasError={validationErrors?.length} />
                    )}
                </div>
              ) : null}

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="aporteACapital" className="label-field">
                  Aporte a capital <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="aporteACapital"
                    defaultValue="DEFAULT"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('aporteACapital', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {equityTypes?.equityTypes?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.aporteACapital && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!aporteACapital || aporteACapital === 'DEFAULT') &&
                  !errors.aporteACapital && (
                    <ErrorText hasError={hasError('equityType')} />
                  )}
              </div>

              {foreignOrigin() && poseeAccionesPortador === 'Si' ? (
                <>
                  <div className="field-form col-lg-6 col-sm-12 ps-4">
                    <label htmlFor="dtc" className="label-field">
                      DTC/Número de compensación <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      disabled={isFormCompleted || isFormDisqualified}
                      id="dtc"
                      {...register('dtc', {
                        required: true,
                      })}
                    />
                    {errors.dtc?.type === 'required' && (
                      <p className="text-danger">
                        El campo DTC/Número de compensación es requerido
                      </p>
                    )}
                    {!dtc && errors.dtc?.type !== 'required' && (
                      <ErrorText hasError={hasError('clearingNumber')} />
                    )}
                  </div>

                  <div className="field-form col-lg-6 col-sm-12 ps-4">
                    <label htmlFor="custodio" className="label-field">
                      Custodio <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      disabled={isFormCompleted || isFormDisqualified}
                      id="custodio"
                      {...register('custodio', {
                        required: true,
                      })}
                    />
                    {errors.custodio?.type === 'required' && (
                      <p className="text-danger">
                        El campo Custodio es requerido
                      </p>
                    )}
                    {!custodio && errors.custodio?.type !== 'required' && (
                      <ErrorText hasError={hasError('custodian')} />
                    )}
                  </div>

                  <div className="field-form col-lg-6 col-sm-12 ps-4">
                    <label htmlFor="cuentaNombre" className="label-field">
                      Cuenta a nombre de <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      disabled={isFormCompleted || isFormDisqualified}
                      id="cuentaNombre"
                      {...register('cuentaNombre', {
                        required: true,
                      })}
                    />
                    {errors.cuentaNombre?.type === 'required' && (
                      <p className="text-danger">
                        El campo Cuenta a nombre de es requerido
                      </p>
                    )}
                    {!cuentaNombre &&
                      errors.cuentaNombre?.type !== 'required' && (
                        <ErrorText hasError={hasError('accountHeld')} />
                      )}
                  </div>
                </>
              ) : null}
            </div>
          )}
        </div>

        <div className="single-button-control-container">
          <button
            type="submit"
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default GeneralInformationForm;
