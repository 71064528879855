import React from 'react';
import './layout.css';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import Menu from './Menu';
import Footer from './Footer';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';

export default function Sidebar() {
  return (
    <div>
      {/* <img className="logo" alt="logo" src="/logo.png"/> */}
      <Nav className="justify-content-center">
        <Nav.Item>
          <Nav.Link className="px-2" href="/home">
            <FacebookIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="px-2" eventKey="link-1">
            <InstagramIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="px-2" eventKey="link-2">
            <LinkedInIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="px-2" eventKey="link-2">
            <TwitterIcon fontSize="small" style={{ color: '#5C5D5F' }} />
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Profile />
      <Menu />
      <Footer />
    </div>
  );
}

export function Profile() {
  return (
    <Container style={{ padding: '60px 5% 30px' }}>
      <Row>
        <Col>
          <div
            className="profile"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <div className="contProfileImage">
              {/* <img width="90" alt="profile" height="90" src="https://pbs.twimg.com/profile_images/1485050791488483328/UNJ05AV8_400x400.jpg"/> */}
            </div>
            <div className="contProfileText">
              <strong>Cristal Kennedy</strong>
              <p>FED043</p>
              <p>Persona Física</p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
