import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import './cortoPlazo.css';
import { Container, Row } from 'react-bootstrap';
import { ram } from '../../../shared/utils/functions';

export default function CortoPlazo({ data }) {
  return (
    <div className="cliente-real-corto-plazo">
      {data && data.data && data.data.length ? (
        <div className="corto-plazo-container">
          <Container fluid>
            {data.data.map((d) => (
              <Row
                className="corto-plazo-content m-3 mb-4 mt-0"
                key={d.id * ram()}
              >
                <div className="m-0 p-0">
                  <p className="p-0 m-0 corto-plazo-name">
                    {d.name.includes(' - ') ? (
                      <>
                        <span style={{ fontWeight: 700 }}>
                          {d.name.split(' - ')[0]}
                        </span>
                        <span style={{ fontWeight: 500 }}>
                          {' - ' + d.name.split(' - ')[1]}
                        </span>
                      </>
                    ) : (
                      <span style={{ fontWeight: 700 }}>{d.name}</span>
                    )}
                  </p>
                  <p className="p-0 m-0 corto-plazo-desc mt-1">
                    {d.description}
                  </p>
                  <TableContainer className="mt-3">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow style={{ border: 'none' }}>
                          {d.table.table.headers.map((h) => (
                            <TableCell
                              className="corto-plazo-row-cell"
                              align="center"
                              key={h.order * ram()}
                            >
                              {h.name}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {d.table.table.data.map((row) => (
                          <TableRow
                            key={row.id * ram()}
                            style={{ border: 'none' }}
                          >
                            {d.table.table.headers.map((h, index) => (
                              <TableCell
                                key={index}
                                style={{
                                  border: 'none',
                                  color: 'rgb(82, 82, 82)',
                                }}
                                align="center"
                              >
                                {row[h.name]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Row>
            ))}
          </Container>
        </div>
      ) : (
        <div className="no-data">
          <strong className="fs-3">No hay data que mostrar.</strong>
        </div>
      )}
    </div>
  );
}
