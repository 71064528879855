import { Error } from '@mui/icons-material';
import './errorText.css';

export const ErrorText = ({ hasError }) => {
  return (
    !!hasError && (
      <div
        className="error-div"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Error style={{ color: '#B90E0A', marginRight: 2 }} />
        <p className="p-0 m-0 error-text">Campo requerido</p>
      </div>
    )
  );
};
