/* eslint-disable no-extra-boolean-cast */
/* eslint-disable array-callback-return */

import './subDashboardDocumentsRepository.css';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_DOCUMENT_COMPLETION_OPTIONS,
  GET_DOCUMENT_SECTION,
  GET_DOCUMENT_STATUS,
  GET_FILE_MANAGER_CLIENT,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
  REQUIRED_DOCUMENTS,
} from '../../../shared/graphQL/queries';
import { toast } from 'react-toastify';
import { Button, CircularProgress } from '@mui/material';
import PDFViewer from '../../../components/ClientePotencial/PDFViewer/PDFViewer';
import { FormControl, MenuItem, Select } from '@mui/material';
import Document from '../../../components/ClientePotencial/Document/Document';
import ConfirmationDialog from '../../../shared/UIElements/ConfirmationDialog/ConfirmationDialog';
import {
  getCookie,
  setErrorNotify,
  setFileManagerToken,
  setSuccessNotify,
} from '../../../shared/utils/functions';
import { FactCheck, PlaylistRemoveOutlined } from '@mui/icons-material';
import ModalInfo from '../../../shared/UIElements/ModalInfo/ModalInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { InfoTooltip } from '../../../shared/UIElements/Tooltips/Tooltips';
import { setRequestFormComplete } from '../../../shared/store/slices/clientePotencial';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SAVE_DOCUMENT_SECTION } from '../../../shared/graphQL/mutations';

export default function SubDashboardDocumentsRepository() {
  const formResponse = JSON.parse(sessionStorage.getItem('selectedFormResponse'));
  const email = sessionStorage.getItem('email');
  const [documentSection, setDocumentSection] = useState(null);
  const [currentFileManager, setCurrentFileManager] = useState(null);
  const [previewDocument, setPreviewDocument] = useState(null);
  const [status, setStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedDocumentCompletion, setSelectedDocumentCompletion] = useState('');
  const [documentsFilter, setDocumentsFilter] = useState([]);
  const [globalLoad, setGlobalLoad] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [confirmationResponse, setConfirmationResponse] = useState(null);
  const [documentToDelete, setDocumentToDelete] = useState(null);
  const dispatch = useDispatch();

  const { isFormCompleted, isFormDisqualified, requestFormCompletionLoading } = useSelector((state) => state.clientePotencialSlice);

  const { error: documentStatusError, data: documentStatus } =
    useQuery(GET_DOCUMENT_STATUS);
  const { data: documentsCompletionOptions } = useQuery(GET_DOCUMENT_COMPLETION_OPTIONS);
  const { error: formResponseError, data: formResponseData } = useQuery(
    GET_FORM_RESPONSE_BY_IDENTIFICATION,
    {
      variables: {
        pagination: {
          filter: formResponse?.lead?.identification,
        },
      },
    }
  );
  const [
    getFileManager,
    { error: getfileManagerError, data: fileManagerData },
  ] = useLazyQuery(GET_FILE_MANAGER_CLIENT);
  const [
    getRequiredDocuments,
    { error: getRequiredDocumentsError, data: requiredDocuments },
  ] = useLazyQuery(REQUIRED_DOCUMENTS);
  const [
    getSection,
    { data: sectionData, loading: sectionLoad, error: sectionError },
  ] = useLazyQuery(GET_DOCUMENT_SECTION, {
    fetchPolicy: 'cache-and-network',
  });
  const [getLegalRepresentativeSection, { data: representativeData }] =
    useLazyQuery(GET_LEGAL_REPRESENTATIVES_SECTION_DATA);
  const [saveDocumentSection] =
    useMutation(SAVE_DOCUMENT_SECTION);

  useEffect(() => {
    if (documentStatus?.documentStatus) {
      setSelectedStatus('todos');
      setStatus([
        { id: 'todos', name: 'Todos' },
        ...documentStatus.documentStatus,
      ]);
    }
  }, [documentStatus]);

  useEffect(() => {
    if(
      selectedDocumentCompletion &&
      selectedDocumentCompletion !== '0' &&
      sectionData?.documentSection?.documentsCompletionOption?.id !== selectedDocumentCompletion
    ){
      if(documentSection?.id){
        saveDocumentSection({
          variables: {
            documentSection: {
              id: documentSection?.id,
              formResponseId: formResponseData?.formResponses[0]?.id,
              documentInputTypes: documentSection?.documents?.length ? documentSection?.documents?.map((document) => ({
                id: document.id,
                fileName: document.fileName,
                fileManagerFileId: document.fileManagerFileId,
                modifiedBy: document.modifiedBy,
                lastActivity: document.lastActivity,
                documentTypeId: document.documentType.id,
                statusId: document.status.id,
                verificationMechanismId: document.verificationMechanism.id,
              })) : [],
              documentsCompletionOptionId: selectedDocumentCompletion,
            },
          },
        }).then((data) => { setDocumentSection(data?.saveDocumentSection); setSuccessNotify('Sección actualizada con exito!'); });
      } else {
        saveDocumentSection({
          variables: {
            documentSection: {
              formResponseId: formResponseData?.formResponses[0]?.id,
              documentInputTypes: [],
              documentsCompletionOptionId: selectedDocumentCompletion,
            },
          },
        }).then((data) => { setDocumentSection(data?.saveDocumentSection); setSuccessNotify('Sección actualizada con exito!'); });
      }
    }
  }, [selectedDocumentCompletion]);

  useEffect(() => {
    if (documentStatusError?.message) {
      setErrorNotify('Hubo un error al obenter información de los documentos');
    }
  }, [documentStatusError]);

  useEffect(() => {
    setCurrentFileManager(fileManagerData?.fileManagerClient);
  }, [fileManagerData]);

  useEffect(() => {
    if (sectionError?.message) {
      setErrorNotify('Hubo un error al obenter los documentos');
    }
  }, [sectionError]);

  useEffect(() => {
    setDocumentSection(sectionData?.documentSection);
    if (
      !sectionLoad &&
      requiredDocuments?.requiredDocuments &&
      status?.length &&
      selectedStatus?.length !== '0'
    ) {
      filtering();
      setGlobalLoad(false);
    }
  }, [sectionData, status, requiredDocuments, sectionLoad]);
  
  useEffect(() => {
    if(
      documentSection?.documentsCompletionOption?.id &&
      documentsCompletionOptions?.documentsCompletionOptions?.length
    ){
      setSelectedDocumentCompletion(documentSection?.documentsCompletionOption?.id);
    }
  }, [documentSection]);

  const setFileManager = async () => {
    await setFileManagerToken();
    const access_token = getCookie('FILE_MANAGER', false);
    if (access_token) {
      getFileManager({ variables: { email, accessToken: access_token } });
    }
  };

  useEffect(() => {
    if (formResponseData?.formResponses?.length) {
      setSection();
      setFileManager();
      getLegalRepresentativeSection({
        variables: { formResponseId: formResponseData.formResponses[0].id },
      });
      getRequiredDocuments({
        variables: { formResponseId: formResponseData.formResponses[0].id },
      });
    }
  }, [formResponseData]);

  const setSection = async () => {
    await getSection({
      variables: { formResponseId: formResponseData.formResponses[0].id },
    });
  };

  useEffect(() => {
    if (formResponseError?.message || getfileManagerError?.message) {
      setErrorNotify('Hubo un error al obtener los datos del cliente');
    }
  }, [formResponseError, getfileManagerError]);

  useEffect(() => {
    if (getRequiredDocumentsError) {
      toast('Hubo un error al cargar unos datos', {
        type: 'error',
        theme: 'colored',
      });
    }
  }, [getRequiredDocumentsError]);

  const closePreview = () => {
    setPreviewDocument(null);
  };

  const checkDocumentTypeNotUploaded = () => {
    return requiredDocuments.requiredDocuments.filter((documentType) => {
      if (
        !documentSection?.documents.find(
          (d) => d.documentType.id === documentType.id
        )
      ) {
        return documentType;
      }
    });
  };

  const checkDocumentTypeUploaded = (statusId) => {
    return requiredDocuments.requiredDocuments.filter((documentType) => {
      if (
        documentSection?.documents.find(
          (d) =>
            d.documentType.id === documentType.id && d.status.id === statusId
        )
      ) {
        return documentType;
      }
    });
  };

  const reorderBy = (elements) => {
    return elements.sort(
      (a, b) => Number(b.isSelfGenerated) - Number(a.isSelfGenerated)
    );
  };

  const filtering = () => {
    let postDocuments = [];
    const selectedStatusObject = documentStatus.documentStatus.find(
      (ds) => ds.id === selectedStatus
    );

    if (selectedStatus.includes('todos')) {
      postDocuments = requiredDocuments.requiredDocuments?.filter((type) => {
        if (type.isSelfGenerated) {
          if (
            documentSection?.documents.find(
              (d) => d.documentType.id === type.id
            )
          ) {
            return type;
          }
        } else {
          return type;
        }
      });
    } else if (
      selectedStatusObject &&
      selectedStatusObject.name === 'Pendiente'
    ) {
      postDocuments = checkDocumentTypeNotUploaded()?.filter(
        (type) => !type.isSelfGenerated
      );
    } else {
      postDocuments = checkDocumentTypeUploaded(selectedStatusObject.id);
    }

    setDocumentsFilter(postDocuments);
  };

  useEffect(() => {
    if (selectedStatus?.length && requiredDocuments?.requiredDocuments) {
      filtering();
    }
  }, [selectedStatus, requiredDocuments]);

  const deleteDocument = async (document) => {
    setDocumentToDelete(document);
    setOpenConfirmation(true);
    setConfirmationText(
      `¿Seguro que desea eliminar el documento ${document.documentType.name}?`
    );
  };

  const closeConfirmationModal = (response) => {
    setConfirmationResponse(response);
    setOpenConfirmation(false);
  };

  const removedDocument = () => {
    setConfirmationResponse(false);
    setDocumentSection({
      ...documentSection,
      documents: documentSection.documents.filter(
        (d) => d.id !== documentToDelete.id
      ),
    });
    setDocumentToDelete(null);
  };

  return (
    <>
      {previewDocument ? (
        <PDFViewer src={previewDocument} close={closePreview} />
      ) : null}
      <ConfirmationDialog
        text={confirmationText}
        openModal={openConfirmation}
        closeModal={closeConfirmationModal}
        color={'#a81c2c'}
      />
      <div className="sub-dashboard-documents">
        <Row className="p-0 m-0 h-100">
          <Col className="d-none d-md-block">
            <Row className="content repository-content flex-column">
              <div className="repository-content-head">
                <h3 className="p-0 m-0">Administración de Documentos
                <ModalInfo
                  Icon={<FontAwesomeIcon icon={faCircleInfo} />}
                  title={'Administración de Documentos'}
                  description={
                    'Puedes cargar los documentos en cualquier momento o enviárselos por correo a tu representante de negocios para que te asista con la carga.'
                  }
                />
                </h3>
                {
                  !isFormCompleted && !isFormDisqualified ? (
                    requestFormCompletionLoading ? 
                    <div className="d-flex align-items-center justify-content-center p-5">
                      <CircularProgress color="info" />
                    </div> :
                    <InfoTooltip title={'¿Quiéres finalizar el llenado del formulario?'} placement="top" arrow>
                      <Button
                        variant="contained"
                        color='primary'
                        endIcon={<FactCheck />}
                        onClick={() => dispatch(setRequestFormComplete(true))}
                      >
                        Finalizar Proceso
                      </Button>
                    </InfoTooltip>
                  ) : null
                }
              </div>

              <div className="repository-status m-0 p-0 mt-4 mb-4">
                <p
                  className="m-0 p-0 fw-bold"
                  style={{ fontSize: 17, color: 'rgb(87, 87, 87)' }}
                >
                  Indica cómo quisieras completar esta solicitud de documentos *
                </p>
                <FormControl
                  style={{ width: 400, marginTop: 20 }}
                  disabled={isFormCompleted || isFormDisqualified}
                >
                  <Select
                    style={{ borderRadius: 10 }}
                    value={documentsCompletionOptions?.documentsCompletionOptions?.length ? selectedDocumentCompletion : '0'}
                    onChange={(event) =>
                      setSelectedDocumentCompletion(event.target.value)
                    }
                  >
                    {documentsCompletionOptions?.documentsCompletionOptions?.length ? (
                      documentsCompletionOptions?.documentsCompletionOptions.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={'0'}>Cargando...</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>
              
              <div className="repository-status m-0 p-0 mt-4">
                <p
                  className="m-0 p-0 fw-bold"
                  style={{ fontSize: 17, color: 'rgb(87, 87, 87)' }}
                >
                  Estatus
                </p>
                <FormControl style={{ width: 400, marginTop: 20 }}>
                  <Select
                    style={{ borderRadius: 10 }}
                    value={status?.length ? selectedStatus : '0'}
                    onChange={(event) =>
                      setSelectedStatus(event.target.value)
                    }
                  >
                    {status?.length ? (
                      status.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={'0'}>Cargando...</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </div>

              <div className="repository-content-table">
                <Row className="documents-container">
                  {!globalLoad ? (
                    !!documentsFilter?.length ? (
                      reorderBy([...documentsFilter]).map(
                        (currentDocumentType) => (
                          <Document
                            key={currentDocumentType.id}
                            status={status}
                            documentSection={documentSection}
                            currentDocumentType={currentDocumentType}
                            documentStatus={documentStatus}
                            formResponseData={formResponseData}
                            deleteDocument={deleteDocument}
                            deleteResponse={
                              confirmationResponse &&
                              documentToDelete?.documentType?.id ===
                                currentDocumentType.id
                            }
                            setSection={setSection}
                            setCurrentFileManager={setCurrentFileManager}
                            currentFileManager={currentFileManager}
                            setPreviewDocument={setPreviewDocument}
                            removedDocument={removedDocument}
                            setDocumentSection={setDocumentSection}
                            principalLegalRepresentative={representativeData?.legalRepresentativesSection?.legalRepresentatives.find(
                              (legalRepresentative) =>
                                legalRepresentative?.legalRepresentativeType?.name.includes(
                                  'Principal'
                                )
                            )}
                          />
                        )
                      )
                    ) : (
                      <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: 500 }}
                      >
                        <PlaylistRemoveOutlined
                          style={{ color: '#697abe', fontSize: 60 }}
                        />
                        <p
                          className="fw-bold mt-3"
                          style={{ fontSize: 30, color: '#697abe' }}
                        >
                          No se encontraron documentos
                        </p>
                      </div>
                    )
                  ) : (
                    <div
                      className="d-flex flex-column justify-content-center align-items-center"
                      style={{ height: 500 }}
                    >
                      <CircularProgress
                        style={{ color: '#697abe' }}
                        size={60}
                      />
                      <p
                        className="fw-bold mt-3"
                        style={{ fontSize: 30, color: '#697abe' }}
                      >
                        Buscando documentos
                      </p>
                    </div>
                  )}
                </Row>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
