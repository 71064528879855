import './rentaVariable.css';
import { Col, Container, Row } from 'react-bootstrap';
import {
  formatName,
  ram,
  variableFijoFecha,
} from '../../../shared/utils/functions';

export default function RentaVariable({ data }) {
  return (
    <div className="cliente-real-renta-variable">
      {data && data.data && data.data.length ? (
        <div className="renta-variable-container">
          <Container fluid>
            {data.data.map((d) => (
              <Row
                className="renta-variable-content mb-4 mt-0"
                key={d.id * ram()}
              >
                <p
                  className="p-0 m-0 fw-bold fs-5 ms-4"
                  style={{ color: 'rgb(82, 82, 82)' }}
                >
                  {formatName(d.name)}
                </p>
                <div className="renta-variable-products">
                  <Row>
                    {d.table.table.data.map((p) => (
                      <Col
                        key={p.id}
                        className="renta-variable-product"
                        style={{ color: 'rgb(82, 82, 82)' }}
                      >
                        <div className="renta-variable-product-top">
                          <img src={p['Emisor Logo']} alt={p['Emisor']} />
                          <p className="p-0 m-0 fw-bold fs-5">{p['Emisor']}</p>
                        </div>
                        <div className="renta-variable-product-content">
                          <div
                            className="renta-variable-product-content-element"
                            style={{ height: 32 }}
                          >
                            <p className="p-0 m-0">A Discreción</p>
                          </div>
                          <div
                            className="renta-variable-product-content-element"
                            style={{ height: 32 }}
                          >
                            <p className="p-0 m-0">{p['Moneda']}</p>
                          </div>
                          {d.table.table.headers.map((h) =>
                            h.name === 'Emisor' ||
                            h.name === 'Moneda' ? null : (
                              <div
                                className="renta-variable-product-content-element"
                                key={h.name}
                              >
                                <p className="p-0 m-0">{h.name}:</p>
                                <p className="p-0 m-0 fw-bold">
                                  {h.name === 'Vencimiento'
                                    ? variableFijoFecha(p[h.name])
                                    : p[h.name]}
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Row>
            ))}
          </Container>
        </div>
      ) : (
        <div className="no-data">
          <strong className="fs-3">No hay data que mostrar.</strong>
        </div>
      )}
    </div>
  );
}
