import './OfficeAddressDominicanRepublicForm.css';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

const OfficeAddressDominicanRepublicForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const { isSameDirectionRD: isSameDirectionRDState } = useSelector(
    (state) => state.clientePotencialSlice
  );

  const [paisObtenidoDeLaApi, setPaisObtenidoDeLaApi] = useState('');

  // Dom Rep Form
  const ciudadRD = watch('ciudadRD');
  const numeroRD = watch('numeroRD');
  const apartamentoRD = watch('apartamentoRD');
  const codigoPostal = watch('codigoPostal');
  const telefono1RD = watch('telefono1RD');
  const faxRD = watch('faxRD');
  const provinciaRD = watch('provinciaRD');
  const calleRD = watch('calleRD');
  const residencialRD = watch('residencialRD');
  const sectorRD = watch('sectorRD');
  const telefono2RD = watch('telefono2RD');

  //   useEffect(() => {
  //     console.log("valor: ", isSameDirectionRD);

  //     if(isSameDirectionRD === true) {
  //         setValue("ciudadRD", ciudad);
  //         setValue("numeroRD", numero);
  //         setValue("apartamentoRD", apartamento);
  //         setValue("codigoPostalRD", codigoPostal);
  //         setValue("telefono1RD", telefono1);
  //         setValue("faxRD", fax);
  //         setValue("provinciaRD", provincia);
  //         setValue("calleRD", calle);
  //         setValue("residencialRD", residencial);
  //         setValue("sectorRD", sector);
  //         setValue("telefono2RD", telefono2);
  //     }
  //     else {
  //         setValue("ciudadRD", "");
  //         setValue("numeroRD", "");
  //         setValue("apartamentoRD", "");
  //         setValue("codigoPostalRD", "");
  //         setValue("telefono1RD", "");
  //         setValue("faxRD", "");
  //         setValue("provinciaRD", "");
  //         setValue("calleRD", "");
  //         setValue("residencialRD", "");
  //         setValue("sectorRD", "");
  //         setValue("telefono2RD", "");
  //     }

  //   }, [isSameDirectionRD]);

  //   useEffect(() => {
  //     if(isSameDirectionRD === true) {
  //         setValue("isSameDirectionRD", false);
  //     }

  //   }, [ciudadRD, numeroRD, apartamentoRD, telefono1RD, faxRD, provinciaRD, calleRD, residencialRD, sectorRD, telefono2RD]);

  useEffect(() => {
    if (countries.length > 0) {
      if (paisObtenidoDeLaApi !== '') {
        setValue('pais', paisObtenidoDeLaApi);
        setPaisObtenidoDeLaApi('');
      }
    }
  }, [countries]);

  const getFormInfo = () => {};

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/direccion-oficina-principal'
    );
  };

  const saveData = () => {};

  return (
    <>
      {/* <CompletedVerification roteToNavigate={""} /> */}

      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div className="row fields-container mt-3">
          <div className="col-10">
            <h3 className="form-title">
              Dirección de la Oficina en la República Dominicana{' '}
            </h3>
          </div>
        </div>

        <div className="row fields-container">
          <div className="col-md-5 col-sm-12 columns">
            <div className="field-form">
              <label htmlFor="ciudadRD" className="label-field">
                Ciudad <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="ciudadRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('ciudadRD', {
                  required: true,
                })}
              />
              {errors.ciudadRD?.type === 'required' && (
                <p className="text-danger">El campo ciudad es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="numeroRD" className="label-field">
                Número <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="numeroRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('numeroRD', {
                  required: true,
                })}
              />
              {errors.numeroRD?.type === 'required' && (
                <p className="text-danger">El campo Número es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="apartamentoRD-oficina-principal"
                className="label-field"
              >
                Apto. / Suite
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="numeroRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('apartamentoRD')}
              />
              {/* {errors.apartamentoRD?.type === 'required' && <p className='text-danger'>El campo Apto. / Suite es requerido</p>} */}
            </div>

            <div className="field-form">
              <label htmlFor="codigo-postalRD" className="label-field">
                Código postal
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="codigo-postalRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('codigoPostalRD')}
              />
              {/* {errors.codigoPostalRD?.type === 'required' && <p className='text-danger'>El campo Apto. / Suite es requerido</p>} */}
            </div>

            <div className="field-form">
              <label htmlFor="telefono1RD" className="label-field">
                Teléfono 1 <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="telefono1RD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('telefono1RD', {
                  required: true,
                })}
              />
              {errors.telefono1RD?.type === 'required' && (
                <p className="text-danger">El campo Teléfono 1 es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="faxRD" className="label-field">
                Fax
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="faxRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('faxRD')}
              />
            </div>
            {/* 
                    {
                        countryMainOffice === "República Dominicana" ?
                            <div className='dr-same-direction-container checkbox1'>
                                <input type="checkbox" className='me-2' id="dr-same-direction" {...register('isSameDirectionMainOffice')} />
                                <label className='dr-same-direction-text' htmlFor="dr-same-direction me-1">Marque si esta es la misma dirección de la oficina principal.</label>
                            </div>
                        : null
                    } */}
          </div>

          <div className="col-md-5 col-sm-12 columns">
            <div className="field-form">
              <label htmlFor="provinciaRD" className="label-field">
                Provincia / Estado <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="provinciaRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('provinciaRD', {
                  required: true,
                })}
              />
              {errors.provinciaRD?.type === 'required' && (
                <p className="text-danger">
                  El campo provincia / Estado es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="calleRD" className="label-field">
                Calle <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="calleRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('calleRD', {
                  required: true,
                })}
              />
              {errors.calleRD?.type === 'required' && (
                <p className="text-danger">El campo calle es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="residencialRD" className="label-field">
                Residencial / Edificio{' '}
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="residencialRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('residencialRD')}
              />
              {/* {errors.residencialRD?.type === 'required' && <p className='text-danger'>El campo residencial / Edificio es requerido</p>} */}
            </div>

            <div className="field-form">
              <label htmlFor="sectorRD" className="label-field">
                Sector
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="sectorRD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('sectorRD')}
              />
              {/* {errors.residencialRD?.type === 'required' && <p className='text-danger'>El campo sector es requerido</p>} */}
            </div>

            <div className="field-form">
              <label htmlFor="telefono2RD" className="label-field">
                Teléfono 2 <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="telefono2RD"
                onInput={() => setValue('isSameDirectionRD', false)}
                {...register('telefono2RD', {
                  required: true,
                })}
              />
              {errors.telefono2RD?.type === 'required' && (
                <p className="text-danger">El campo Teléfono 2 es requerido</p>
              )}
            </div>

            {/* {
                        countryMainOffice === "República Dominicana" ?
                            <div className='dr-same-direction-container checkbox2'>
                                <input type="checkbox" className='me-2' id="dr-same-direction" {...register('isSameDirectionMainOffice')} />
                                <label className='dr-same-direction-text' htmlFor="dr-same-direction me-1">Marque si esta es la misma dirección de la oficina principal.</label>
                            </div>
                        : null
                    } */}
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button type="submit" className={`btn-continue`}>
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default OfficeAddressDominicanRepublicForm;
