import { gql } from '@apollo/client';

export const GET_NOTE_CREATED = gql`
  subscription NoteCreated($entityId: String!) {
    noteCreated(entityId: $entityId) {
      id
      creationDate
      modificationDate
      title
      text
      author {
        id
        firstName
        lastName
      }
      messageType {
        id
        name
      }
    }
  }
`;
