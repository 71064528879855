import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import './redirectActiveUserFlow.css';
import jwt_decode from 'jwt-decode';
import jwt_encode from 'jwt-encode';
import axios from 'axios';
import {
  encode64,
  envars,
  getCookie,
  setCookie,
} from '../../../shared/utils/functions';
import {
  AZURE_SESSION_TIME_OUT,
  COOKIENAMES,
} from '../../../shared/constants/gobal-constants';
import { useDispatch } from 'react-redux';
import { setSessionAuth } from '../../../shared/store/slices/clienteReal';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  setAccessToken,
  setHasInvestmentProfile,
  setPrimaryContact,
  setSelectedSection,
} from '../../../shared/store/slices/clientePotencial';
import { SAVE_PRIMARY_CONTACT } from '../../../shared/graphQL/mutations';
import { NewPotencialCookieConfig } from '../../../components/NewClientePotencial/cookieConfig';
import { toast } from 'react-toastify';
import {
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_PERSONTYPES,
} from '../../../shared/graphQL/queries';

const RedirectNums = Object.freeze({
  createPrimaryContact: 1,
  createLead: 2,
  toCP: 3,
  toUBI: 4,
  toUBIChangePassword: 5,
});

export default function RedirectActivedUserFlow() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hash } = useLocation();

  const [message, setMessage] = useState('');
  const [savePrimaryContact, { data, error }] =
    useMutation(SAVE_PRIMARY_CONTACT);
  const [names, setNames] = useState({ first: '', last: '' });
  const [email, setEmail] = useState('');
  const [idToken, setIdToken] = useState('');
  const [redirectConfig, setRedirectConfig] = useState(null);
  const [errorPage, setErrorPage] = useState(false);
  const [errorDescription, setErrorDescription] = useState('');
  const [exp, setExp] = useState(0);
  const [getFormResponseByIdentification] = useLazyQuery(
    GET_FORM_RESPONSE_BY_IDENTIFICATION
  );
  const [getPersonTypes] = useLazyQuery(GET_PERSONTYPES);

  useEffect(() => {
    if (hash) {
      const id_token = hash.split('#id_token=')[1];
      const isKnownError = hash.split('#error=')[1];

      if (id_token) {
        setMessage(
          'Estamos verficando su usuario, esto puede tardar unos segundos...'
        );
        setIdToken(id_token);
        init(id_token);
      } else {
        callError(isKnownError);
      }
    }
  }, []);

  const callError = (isKnownError, message) => {
    const defaultMessage =
      'El servicio detectó un error. Vuelva a realizar la autenticación e inténtelo de nuevo.';
    setErrorPage(true);
    if (isKnownError) {
      const code = isKnownError.split('error_description=')[1].slice(0, 11);
      switch (code) {
        case 'AADB2C90035':
          setErrorDescription(
            'El servicio no está disponible temporalmente. Vuelva a intentarlo después de unos minutos.'
          );
          break;
        case 'AADB2C90043':
          setErrorDescription('El recurso al que intentas acceder no existe.');
          break;
        case 'AADB2C90111':
          setErrorDescription(
            'Su cuenta se ha bloqueado. Contacte con la persona responsable de soporte técnico para desbloquearla y vuelva a intentarlo.'
          );
          break;
        case 'AADB2C90114':
          setErrorDescription(
            'Su cuenta se bloqueó temporalmente para impedir un uso no autorizado. Vuelva a intentarlo más tarde.'
          );
          break;
        case 'AADB2C90244':
          setErrorDescription(
            'Hay demasiadas solicitudes en este momento. Espere un poco y vuelva a intentarlo.'
          );
          break;
        case 'AADB2C90289':
          setErrorDescription(
            'Se ha producido un error en la conexión al proveedor de identidades. Inténtelo de nuevo más tarde.'
          );
          break;
        case 'AADB2C99002':
          setErrorDescription(
            'Este usuario no existe. Intente crearse una cuenta primero.'
          );
          break;
        default:
          setErrorDescription(defaultMessage);
          break;
      }
    } else {
      setErrorDescription(message ? message : defaultMessage);
    }
  };

  const isTokenExpired = async (decode) => {
    try {
      await axios.get('/middleaware/check-token-validation', {
        headers: { exp: decode.exp },
      });
      setExp(decode.exp);
      return false;
    } catch (error) {
      callError(
        false,
        'Esta autenticación se ha vencido. Vuelva a iniciar sesión.'
      );
      return true;
    }
  };

  const init = async (id_token) => {
    const decode = jwt_decode(id_token);
    const response = await isTokenExpired(decode);

    if (response) {
      return;
    }

    const { parvalConfigRedirect, family_name, given_name } = decode;

    if (!parvalConfigRedirect) {
      callError();
      return;
    }

    const redirectParse = JSON.parse(parvalConfigRedirect);

    setNames({ first: given_name, last: family_name });
    setEmail(redirectParse.email);

    const intervalId = setInterval(() => {
      if (getCookie('CRM_AUTH', false)) {
        clearInterval(intervalId);
        setRedirectConfig(redirectParse);
      }
    });
  };

  useEffect(() => {
    if (redirectConfig) {
      redirectFunction(redirectConfig);
    }
  }, [redirectConfig]);

  const redirectFunction = async (configuration) => {
    const { redirect, lead, clientId } = configuration;

    switch (redirect) {
      case RedirectNums.createLead:
        sessionStorage.setItem('email', email);
        saveToken();
        setToCreateLead();
        toast('Para poder continuar, debe completar estas informaciones', {
          type: 'info',
          theme: 'colored',
        });
        break;
      case RedirectNums.toCP:
        sessionStorage.setItem('email', email);
        saveToken();

        getFormResponseByIdentification({
          variables: {
            pagination: {
              filter: lead.identification,
            },
          },
        }).then((response) => {
          sessionStorage.setItem('selectedFormResponse', JSON.stringify(response.data.formResponses[0]));
          if (
            response.data.formResponses[0]?.lead?.companyLeadDetails?.applicantType?.name.includes(
              'Negocio'
            )
          ) {
            sessionStorage.setItem('hasInvestmentProfile', true);
            dispatch(setHasInvestmentProfile(true));
          } else {
            sessionStorage.setItem('hasInvestmentProfile', false);
            dispatch(setHasInvestmentProfile(false));
          }
          if (response.data.formResponses[0]?.form?.personType.name === 'Persona Jurídica') {
            dispatch(setSelectedSection('Información De Mi Empresa'));
          } else {
            dispatch(setSelectedSection('Identificación del cliente'));
          }
          navigate('/onboarding-digital/dashboard/resumen-de-cuentas', {
            replace: true,
          });
        });
        break;
      case RedirectNums.toUBI:
        await loginToUbi(email, clientId);
        break;
      case RedirectNums.toUBIChangePassword:
        await changeUbiPassword(clientId, email);
        await loginToUbi(email);
        break;
      case RedirectNums.createPrimaryContact:
      default:
        sessionStorage.setItem('email', email);
        saveToken();
        savePrimaryContact({
          variables: {
            primaryContact: {
              email,
              firstName: names.first,
              lastName: names.last,
            },
          },
        });
        break;
    }
  };

  const saveToken = () => {
    const session = JSON.stringify({ access_token: idToken, exp: exp });
    sessionStorage.setItem('access_token', session);
    dispatch(setAccessToken(idToken));
  };

  const changeUbiPassword = async (userId, email) => {
    const config = {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    };
    try {
      const res = await axios.put(
        '/middleware/api/ubi-srm/update-old-user',
        {
          userId,
          email,
          password: email,
        },
        config
      );
      if (res.data && res.data.code === 200) {
        return true;
      } else {
        callError();
      }
    } catch (error) {
      throw new Error();
    }
  };

  const loginToUbi = async (email, clientId) => {
    try {
      const res = await axios.post('/middleware/api/ubibroker/login', {
        username: email,
        password: email,
      });

      if (res.data.access_token) {
        verifyJWTAndRedirect(res.data.access_token);
        dispatch(setSessionAuth(true));
        // setCookie(COOKIENAMES.identity, redirectConfig, { 'max-age': 3600, path: '/' });
        navigate('/cliente-real/dashboard/inicio', { replace: true });
      }
    } catch (error) {
      if (error.response.status === 403) {
        const res = await changeUbiPassword(clientId, email);
        if (res) {
          await loginToUbi(email, clientId);
        }
      } else {
        callError();
      }
    }
  };

  const verifyJWTAndRedirect = (accessToken) => {
    let decodedToken = jwt_decode(accessToken);
    // eslint-disable-next-line no-unused-vars
    const { token, ...values } = decodedToken;
    decodedToken = { ...values, token: accessToken };
    const time = decodedToken.exp - decodedToken.iat;
    setCookie(COOKIENAMES.user, JSON.stringify(decodedToken), {
      'max-age': time,
      path: '/',
    });
  };

  useEffect(() => {
    if (data) {
      setToCreateLead();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      callError(
        false,
        'Hubo un error al crear el contacto primario para este usuario.'
      );
    }
  }, [error]);

  const setToCreateLead = async () => {
    const personTypes = await getPersonTypes();
    const personType = personTypes.data?.personTypeList.find(
      (person) => person.name === 'Persona Jurídica'
    ); // REMOVER DESPUES
    const config = new NewPotencialCookieConfig(
      email,
      true,
      names.first,
      names.last,
      true,
      personType
    );
    const configCoded = jwt_encode(config, envars('SECRETINNERJWT'));
    const timeout = AZURE_SESSION_TIME_OUT;
    setCookie('potencialCustomerConfig', configCoded, {
      'max-age': timeout,
      path: '/',
    });
    dispatch(setPrimaryContact(true));
    // navigate('/onboarding-digital/person-type', { replace: true });
    navigate('/onboarding-digital/assistance-unit', { replace: true }); // REMOVER DESPUES
  };

  useEffect(() => {
    if (errorDescription) {
      const queryParams = new URLSearchParams();
      queryParams.append('type', encode64(errorDescription));
      const url = `/error?${queryParams.toString()}`;
      navigate(url);
    }
  }, [errorDescription]);

  return (
    <div className="redirect-active-user-flow-container h-100">
      <div className="logo-redirect-active-container">
        <img className="logoParval" alt="logo" src="/logo.png" />
      </div>
      <Row className="p-0 m-0 h-100">
        <Col className="h-100 d-flex align-items-center justify-content-center">
          <div className="redirect-active-user-flow-content d-flex flex-column align-items-center justify-content-center">
            {!errorPage ? (
              <>
                <h1 className="mb-5 text-center">
                  Bienvenido a la ventana de redirección
                </h1>
                <CircularProgress size={70} style={{ color: '#009688' }} />
                <p className="mt-5 fs-4 text-center" style={{ maxWidth: 500 }}>
                  {message}
                </p>
              </>
            ) : null}
          </div>
        </Col>
      </Row>
    </div>
  );
}
