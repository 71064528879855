import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

export default function TopBar() {
  return (
    <div className="topBar">
      <Container fluid>
        <Row>
          <Col style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <div className="contProfileImage">
              {/* <img alt="profile" width="100" height="100" src="https://pbs.twimg.com/profile_images/1485050791488483328/UNJ05AV8_400x400.jpg"/> */}
            </div>
            <div>
              <strong>Su Corredor De Valores:</strong>
              <p>Leivy Castillo</p>
              <p>
                809-560-0909 <strong>Ext: 2015</strong>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
