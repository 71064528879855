import './RequestWayToFillForm.css';
import {
  faGlobe,
  faBuilding,
  faBuildingUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const RequestWayToFillForm = () => {
  const [distributionChannels, setDistributionChannels] = useState([]);
  const navigate = useNavigate();

  const icons = {
    'c6579040-3c81-eb11-a812-0022481f4af3': faGlobe,
    '93fd4c58-3c81-eb11-a812-0022481f4af3': faBuilding,
    '7afd756b-3c81-eb11-a812-0022481f4af3': faBuildingUser,
  };

  const routesToNavigate = {
    'c6579040-3c81-eb11-a812-0022481f4af3': '',
    '93fd4c58-3c81-eb11-a812-0022481f4af3': '',
    '7afd756b-3c81-eb11-a812-0022481f4af3': '',
  };

  const handleClick = (channelId) => {
    localStorage.setItem('distributionChannel', channelId);
    assignRouteToNavigateToObject();

    if (routesToNavigate[channelId] !== '') {
      navigate(routesToNavigate[channelId]);
    }
  };

  const assignRouteToNavigateToObject = () => {
    const accountTypeToStartRegister = localStorage.getItem(
      'accountTypeToStartRegister'
    );

    if (accountTypeToStartRegister === 'physical-person') {
      routesToNavigate['c6579040-3c81-eb11-a812-0022481f4af3'] =
        '/formularios/persona-fisica/informacion-basica';
      routesToNavigate['93fd4c58-3c81-eb11-a812-0022481f4af3'] =
        '/formularios/persona-fisica/informacion-basica';
    } else if (accountTypeToStartRegister === 'legal-person') {
      routesToNavigate['c6579040-3c81-eb11-a812-0022481f4af3'] =
        '/formularios/persona-juridica/informacion-general';
    }
  };

  return (
    <div className="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="rwf-title">
          ¿Cómo desea realizar su solicitud de apertura de cuenta?
        </h3>
        <div className="cards-container">
          {distributionChannels.map((channel, index) => {
            return (
              <div
                key={index}
                className="fill-form-way-card"
                onClick={() => handleClick(channel.id)}
              >
                <FontAwesomeIcon
                  icon={icons[channel.id]}
                  className="card-icon"
                />
                <h4 className="rwf-card-title">{channel.name}</h4>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RequestWayToFillForm;
