import './ProfessionalClientLpForm.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { faAngleLeft, faAngleRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import {
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_INVESTMENT_EXPERIENCE_CHECKLIST,
  GET_INVESTMENT_KNOWLEDGE_CHECKLIST,
  GET_INVESTMENT_PROFILE_SECTION,
  GET_INVESTOR_CLASIFICATION_SECTION,
  GET_PATRIMONIAL_CAPACITY_CHECKLIST,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GENERATE_INVESTMENT_PROFILE,
  SAVE_INVESTOR_CLASIFICATION_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { useDispatch } from 'react-redux';
import {
  setPerfilObtenido,
  setSelectedSection,
} from '../../../../../shared/store/slices/clientePotencial';
import { useSelector } from 'react-redux';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { Error } from '@mui/icons-material';

const ProfessionalClientLpForm = () => {
  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { data: investmentExperienceCheckList } = useQuery(
    GET_INVESTMENT_EXPERIENCE_CHECKLIST
  );
  const { data: patrimonialCapacityCheckList } = useQuery(
    GET_PATRIMONIAL_CAPACITY_CHECKLIST
  );
  const { data: investmentKnowledgeCheckList } = useQuery(
    GET_INVESTMENT_KNOWLEDGE_CHECKLIST
  );
  const [
    investmentExperienceCheckListIds,
    setInvestmentExperienceCheckListIds,
  ] = useState([]);
  const [patrimonialCapacityCheckListIds, setPatrimonialCapacityCheckListIds] =
    useState([]);
  const [investmentKnowledgeCheckListIds, setInvestmentKnowledgeCheckListIds] =
    useState([]);
  const [formResponseId, setFormResponseId] = useState('');
  const [
    saveInvestorClassificationSection,
    { data: saveInvestorClassificationSectionResponse },
  ] = useMutation(SAVE_INVESTOR_CLASIFICATION_SECTION, {
    refetchQueries: [
      {
        query: GET_INVESTOR_CLASIFICATION_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [
    getInvestorClassificationSectionData,
    investorClassificationSectionData,
  ] = useLazyQuery(GET_INVESTOR_CLASIFICATION_SECTION);
  const [generateInvestmentProfile, { data: investmentProfileResponse }] =
    useMutation(GENERATE_INVESTMENT_PROFILE, {
      refetchQueries: [
        {
          query: GET_INVESTMENT_PROFILE_SECTION,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    });
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [perfilObtenidoState, setPerfilObtenidoState] = useState('');
  const resultModalRef = useRef();
  const dispatch = useDispatch();
  const [loadingSection, setLoadingSection] = useState(true);
  const [validationError, setValidationError] = useState(false);
  const { isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );

  const investmentExperienceValues1 = watch('investmentExperienceValues1');
  const investmentExperienceValues2 = watch('investmentExperienceValues2');
  const investmentExperienceValues3 = watch('investmentExperienceValues3');

  const patrimonialCapacityValues1 = watch('patrimonialCapacityValues1');

  const investmentKnowledgeValues1 = watch('investmentKnowledgeValues1');
  const investmentKnowledgeValues2 = watch('investmentKnowledgeValues2');
  const investmentKnowledgeValues3 = watch('investmentKnowledgeValues3');
  const investmentKnowledgeValues4 = watch('investmentKnowledgeValues4');
  const investmentKnowledgeValues5 = watch('investmentKnowledgeValues5');
  const investmentKnowledgeValues6 = watch('investmentKnowledgeValues6');
  const investmentKnowledgeValues7 = watch('investmentKnowledgeValues7');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse)?.length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );
      getInvestorClassificationSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (
      investorClassificationSectionData.data?.investorClassificationSection
        ?.investmentExperienceChecks
    ) {
      const ids =
        investorClassificationSectionData.data?.investorClassificationSection?.investmentExperienceChecks?.map(
          (elem) => elem?.id
        );
      setInvestmentExperienceCheckListIds(ids);
      let index = 1;

      if (investmentExperienceCheckList?.investmentExperienceCheckList) {
        for (let elem of investmentExperienceCheckList?.investmentExperienceCheckList) {
          const check =
            investorClassificationSectionData?.data?.investorClassificationSection?.investmentExperienceChecks?.find(
              (x) => x?.id === elem?.id
            );

          if (check) {
            if (index === 1) {
              setValue('investmentExperienceValues1', 'Si');
            } else if (index === 2) {
              setValue('investmentExperienceValues2', 'Si');
            } else if (index === 3) {
              setValue('investmentExperienceValues3', 'Si');
            }
          } else {
            if (index === 1) {
              setValue('investmentExperienceValues1', 'No');
            } else if (index === 2) {
              setValue('investmentExperienceValues2', 'No');
            } else if (index === 3) {
              setValue('investmentExperienceValues3', 'No');
            }
          }
          index++;
        }
      }
    }

    if (
      investorClassificationSectionData.data?.investorClassificationSection
        ?.patrimonialCapacityChecks
    ) {
      const ids =
        investorClassificationSectionData.data?.investorClassificationSection?.patrimonialCapacityChecks?.map(
          (elem) => elem?.id
        );
      setPatrimonialCapacityCheckListIds(ids);
      let index = 1;

      if (patrimonialCapacityCheckList?.patrimonialCapacityCheckList) {
        for (let elem of patrimonialCapacityCheckList?.patrimonialCapacityCheckList) {
          const check =
            investorClassificationSectionData?.data?.investorClassificationSection?.patrimonialCapacityChecks?.find(
              (x) => x?.id === elem?.id
            );
          if (check) {
            if (index === 1) {
              setValue('patrimonialCapacityValues1', 'Si');
            }
          } else {
            if (index === 1) {
              setValue('patrimonialCapacityValues1', 'No');
            }
          }
          index++;
        }
      }
    }

    if (
      investorClassificationSectionData.data?.investorClassificationSection
        ?.investmentKnowledgeChecks
    ) {
      const ids =
        investorClassificationSectionData.data?.investorClassificationSection?.investmentKnowledgeChecks?.map(
          (elem) => elem?.id
        );
      setInvestmentKnowledgeCheckListIds(ids);
      let index = 1;
      if (investmentKnowledgeCheckList?.investmentKnowledgeCheckList) {
        for (let elem of investmentKnowledgeCheckList?.investmentKnowledgeCheckList) {
          const check =
            investorClassificationSectionData?.data?.investorClassificationSection?.investmentKnowledgeChecks?.find(
              (x) => x?.id === elem?.id
            );

          if (check) {
            if (index === 1) {
              setValue('investmentKnowledgeValues1', 'Si');
            } else if (index === 2) {
              setValue('investmentKnowledgeValues2', 'Si');
            } else if (index === 3) {
              setValue('investmentKnowledgeValues3', 'Si');
            } else if (index === 4) {
              setValue('investmentKnowledgeValues4', 'Si');
            } else if (index === 5) {
              setValue('investmentKnowledgeValues5', 'Si');
            } else if (index === 6) {
              setValue('investmentKnowledgeValues6', 'Si');
            } else if (index === 7) {
              setValue('investmentKnowledgeValues7', 'Si');
            }
          } else {
            if (index === 1) {
              setValue('investmentKnowledgeValues1', 'No');
            } else if (index === 2) {
              setValue('investmentKnowledgeValues2', 'No');
            } else if (index === 3) {
              setValue('investmentKnowledgeValues3', 'No');
            } else if (index === 4) {
              setValue('investmentKnowledgeValues4', 'No');
            } else if (index === 5) {
              setValue('investmentKnowledgeValues5', 'No');
            } else if (index === 6) {
              setValue('investmentKnowledgeValues6', 'No');
            } else if (index === 7) {
              setValue('investmentKnowledgeValues7', 'No');
            }
          }

          index++;
        }
      }
    }
  }, [investorClassificationSectionData]);

  useEffect(() => {
    if (
      saveInvestorClassificationSectionResponse?.saveInvestorClassificationSection
    ) {
      // Genero el perfil del inversionista y obtengo el resultado
      generateInvestmentProfile({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }
  }, [saveInvestorClassificationSectionResponse]);

  useEffect(() => {
    if (investmentProfileResponse?.generateInvestmentProfile) {
      const profile =
        investmentProfileResponse?.generateInvestmentProfile?.investmentProfile
          ?.name;
      const profileType =
        investmentProfileResponse?.generateInvestmentProfile
          ?.investmentProfileType?.name;
      setPerfilObtenidoState(profile + ' - ' + profileType);
      dispatch(setPerfilObtenido(profile + ' - ' + profileType));
      resultModalRef?.current?.click();
    }
  }, [investmentProfileResponse]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/instrumentos-financieros'
    );
  };

  const insertOrDeleteIds = (id, wordKey, answer, index) => {
    if (wordKey === 'Experience') {
      if (answer === 'Si') {
        if (investmentExperienceCheckListIds?.includes(id) === false) {
          setInvestmentExperienceCheckListIds([
            ...investmentExperienceCheckListIds,
            id,
          ]);
        }
      } else {
        const filteredArray = investmentExperienceCheckListIds?.filter(
          (x) => x !== id
        );
        setInvestmentExperienceCheckListIds(filteredArray);
      }
      setValue(`investmentExperienceValues${index}`, answer);
    } else if (wordKey === 'Capacity') {
      if (answer === 'Si') {
        if (patrimonialCapacityCheckListIds?.includes(id) === false) {
          setPatrimonialCapacityCheckListIds([
            ...patrimonialCapacityCheckListIds,
            id,
          ]);
        }
      } else {
        const filteredArray = patrimonialCapacityCheckListIds?.filter(
          (x) => x !== id
        );
        setPatrimonialCapacityCheckListIds(filteredArray);
      }
      setValue(`patrimonialCapacityValues${index}`, answer);
    } else if (wordKey === 'Knowledge') {
      if (answer === 'Si') {
        if (investmentKnowledgeCheckListIds?.includes(id) === false) {
          setInvestmentKnowledgeCheckListIds([
            ...investmentKnowledgeCheckListIds,
            id,
          ]);
        }
      } else {
        const filteredArray = investmentKnowledgeCheckListIds?.filter(
          (x) => x !== id
        );
        setInvestmentKnowledgeCheckListIds(filteredArray);
      }
      setValue(`investmentKnowledgeValues${index}`, answer);
    }
  };

  const saveDataInBD = () => {
    if (isFormCompleted || isFormDisqualified) {
      dispatch(setSelectedSection(''));
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/documentos'
      );
      return;
    }

    let data = {};

    const sectionId =
      investorClassificationSectionData?.data?.investorClassificationSection
        ?.id;

    //Si companyOfficesSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (sectionId !== null && sectionId !== undefined) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        id: sectionId,
        investmentExperienceCheckIds: investmentExperienceCheckListIds,
        investmentKnowledgeCheckIds: investmentKnowledgeCheckListIds,
        patrimonialCapacityCheckIds: patrimonialCapacityCheckListIds,
      };
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        investmentExperienceCheckIds: investmentExperienceCheckListIds,
        investmentKnowledgeCheckIds: investmentKnowledgeCheckListIds,
        patrimonialCapacityCheckIds: patrimonialCapacityCheckListIds,
      };
    }

    saveInvestorClassificationSection({
      variables: {
        investorClassificationSection: data,
      },
    });
  };

  useEffect(() => {
    setValidationError(false);
    for (const key in validation) {
      if (key === 'investorClassificationSectionErrors') {
        setValidationError(!!validation[key]?.length);
      }
    }
  }, [validation]);

  return (
    <>
      <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
        <div
          className="row fields-container"
          style={{ height: loadingSection ? '80%' : 'auto' }}
        >
          <h3 className="formTitle">
            Cliente profesional
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Cliente profesional'}
              description={
                "El formulario 'Cliente Profesional' consiste en una serie de preguntas diseñadas para identificar a aquellos clientes que cumplen con el perfil de profesionales en el ámbito financiero. Estas preguntas abordan aspectos clave, como las operaciones realizadas en los mercados de valores nacionales o extranjeros, el volumen y frecuencia de transacciones, la posesión de activos significativos, la experiencia laboral en cargos directivos o en el consejo de administración de entidades financieras, y la posesión de certificaciones relevantes en el campo, como la certificación de corredor de valores. Las respuestas a estas preguntas contribuyen a la categorización precisa del cliente como un profesional en el ámbito financiero o no."
              }
            />
            {validationError && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={'Hay campos incompletos en esta sección'}
                />
              </span>
            )}
          </h3>

          {loadingSection ? (
            <div className="w-100 d-flex justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <>
              <div className="row">
                {investmentExperienceCheckList?.investmentExperienceCheckList?.map(
                  (option, index) => (
                    <div
                      className="field-form col-lg-6 col-sm-12 ps-4"
                      key={index}
                    >
                      <label
                        htmlFor={'investmentExperienceValues' + (index + 1)}
                        className="label-field text-justify"
                      >
                        {' '}
                        {option.name} <span>*</span>
                      </label>
                      <div className="selectWrapper">
                        <select
                          id={'investmentExperienceValues' + (index + 1)}
                          defaultValue={'DEFAULT'}
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register(
                            'investmentExperienceValues' + (index + 1),
                            {
                              validate: validateNonSelectedOptionInDropdown,
                            }
                          )}
                          onChange={(e) =>
                            insertOrDeleteIds(
                              option?.id,
                              'Experience',
                              e.target.value,
                              index + 1
                            )
                          }
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          <option value="Si">Si</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      {index + 1 === 1
                        ? errors.investmentExperienceValues1 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 1 &&
                        (!investmentExperienceValues1 ||
                          investmentExperienceValues1 === 'DEFAULT') &&
                        !errors.investmentExperienceValues1 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 2
                        ? errors.investmentExperienceValues2 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 2 &&
                        (!investmentExperienceValues2 ||
                          investmentExperienceValues2 === 'DEFAULT') &&
                        !errors.investmentExperienceValues2 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 3
                        ? errors.investmentExperienceValues3 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 3 &&
                        (!investmentExperienceValues3 ||
                          investmentExperienceValues3 === 'DEFAULT') &&
                        !errors.investmentExperienceValues3 && (
                          <ErrorText hasError={validationError} />
                        )}
                    </div>
                  )
                )}

                {patrimonialCapacityCheckList?.patrimonialCapacityCheckList?.map(
                  (option, index) => (
                    <div
                      className="field-form col-lg-6 col-sm-12 ps-4"
                      key={index}
                    >
                      <label
                        htmlFor={'patrimonialCapacityValues' + (index + 1)}
                        className="label-field text-justify"
                      >
                        {' '}
                        {option.name} <span>*</span>
                      </label>
                      <div className="selectWrapper">
                        <select
                          id={'patrimonialCapacityValues' + (index + 1)}
                          defaultValue={'DEFAULT'}
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register(
                            'patrimonialCapacityValues' + (index + 1),
                            {
                              validate: validateNonSelectedOptionInDropdown,
                            }
                          )}
                          onChange={(e) =>
                            insertOrDeleteIds(
                              option?.id,
                              'Capacity',
                              e.target.value,
                              index + 1
                            )
                          }
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          <option value="Si">Si</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      {index + 1 === 1
                        ? errors.patrimonialCapacityValues1 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 1 &&
                        (!patrimonialCapacityValues1 ||
                          patrimonialCapacityValues1 === 'DEFAULT') &&
                        !errors.patrimonialCapacityValues1 && (
                          <ErrorText hasError={validationError} />
                        )}
                    </div>
                  )
                )}

                {investmentKnowledgeCheckList?.investmentKnowledgeCheckList?.map(
                  (option, index) => (
                    <div
                      className="field-form col-lg-6 col-sm-12 ps-4"
                      key={index}
                    >
                      <label
                        htmlFor={'investmentKnowledgeValues' + (index + 1)}
                        className="label-field text-justify"
                      >
                        {' '}
                        {option.name.replace(/\//g, ' / ')} <span>*</span>
                      </label>
                      <div className="selectWrapper">
                        <select
                          id={'investmentKnowledgeValues' + (index + 1)}
                          defaultValue={'DEFAULT'}
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register(
                            'investmentKnowledgeValues' + (index + 1),
                            {
                              validate: validateNonSelectedOptionInDropdown,
                            }
                          )}
                          onChange={(e) =>
                            insertOrDeleteIds(
                              option?.id,
                              'Knowledge',
                              e.target.value,
                              index + 1
                            )
                          }
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          <option value="Si">Si</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                      {index + 1 === 1
                        ? errors.investmentKnowledgeValues1 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 1 &&
                        (!investmentKnowledgeValues1 ||
                          investmentKnowledgeValues1 === 'DEFAULT') &&
                        !errors.investmentKnowledgeValues1 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 2
                        ? errors.investmentKnowledgeValues2 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 2 &&
                        (!investmentKnowledgeValues2 ||
                          investmentKnowledgeValues2 === 'DEFAULT') &&
                        !errors.investmentKnowledgeValues2 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 3
                        ? errors.investmentKnowledgeValues3 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 3 &&
                        (!investmentKnowledgeValues3 ||
                          investmentKnowledgeValues3 === 'DEFAULT') &&
                        !errors.investmentKnowledgeValues3 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 4
                        ? errors.investmentKnowledgeValues4 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 4 &&
                        (!investmentKnowledgeValues4 ||
                          investmentKnowledgeValues4 === 'DEFAULT') &&
                        !errors.investmentKnowledgeValues4 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 5
                        ? errors.investmentKnowledgeValues5 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 5 &&
                        (!investmentKnowledgeValues5 ||
                          investmentKnowledgeValues5 === 'DEFAULT') &&
                        !errors.investmentKnowledgeValues5 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 6
                        ? errors.investmentKnowledgeValues6 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 6 &&
                        (!investmentKnowledgeValues6 ||
                          investmentKnowledgeValues6 === 'DEFAULT') &&
                        !errors.investmentKnowledgeValues6 && (
                          <ErrorText hasError={validationError} />
                        )}
                      {index + 1 === 7
                        ? errors.investmentKnowledgeValues7 && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )
                        : null}
                      {index + 1 === 7 &&
                        (!investmentKnowledgeValues7 ||
                          investmentKnowledgeValues7 === 'DEFAULT') &&
                        !errors.investmentKnowledgeValues7 && (
                          <ErrorText hasError={validationError} />
                        )}
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>

      <button
        type="button"
        ref={resultModalRef}
        style={{ display: 'none' }}
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Mostrar modal
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Resultado
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Perfil obtenido: {perfilObtenidoState}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  dispatch(setSelectedSection(''));
                  navigate(
                    '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/documentos'
                  );
                }}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfessionalClientLpForm;
