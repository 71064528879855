import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import Sidebar from './Sidebar';
import TopBar from './TopBar';
import './layout.css';

export default function Layout({ children }) {
  return (
    <Container className="mainContainer" fluid>
      <Row>
        <Col xs lg="2" className="p-0 sidebar">
          <Sidebar />
        </Col>
        <Col xs lg="10" className="p-0">
          <Container fluid className="p-0">
            <TopBar />
            <div className="pageContainer">{children}</div>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
