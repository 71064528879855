import './SignatureCardForm.css';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import {
  loadPersons,
} from '../../../../../shared/store/slices/clientePotencial';
import {
  REMOVE_SIGNATORY,
  SAVE_SIGNATURES_SECTION,
} from '../../../../../shared/graphQL/mutations';
import {
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_GENERAL_INFORMATION,
  GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
  GET_SIGNATURES_SECTION,
  GET_SIGNATURE_CARDS_PDF_BY_FORM_RESPONSES,
  GET_SIGNATURE_TYPES,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  comprexAddress,
  mergeObjectsWithSameId,
  setSuccessNotify,
  validateInputCedula,
} from '../../../../../shared/utils/functions';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { FormControl, MenuItem, Select } from '@mui/material';

const SignatureCardForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const dispatch = useDispatch();
  const [formResponseId, setFormResponseId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [saveSignaturesSection] = useMutation(SAVE_SIGNATURES_SECTION, {
    refetchQueries: [
      {
        query: GET_SIGNATURES_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [removeSignatory] = useMutation(REMOVE_SIGNATORY, {
    refetchQueries: [
      {
        query: GET_SIGNATURES_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const { data: signatureTypes } = useQuery(GET_SIGNATURE_TYPES);
  const [getSignaturesSectionData, signaturesSectionData] = useLazyQuery(
    GET_SIGNATURES_SECTION, { }
  );
  const [signaturesSectionId, setSignaturesSectionId] = useState('');
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [
    // getSignatureCardsPdfByFormResponses,
    signatureCardsPdfByFormResponses,
  ] = useLazyQuery(GET_SIGNATURE_CARDS_PDF_BY_FORM_RESPONSES);
  const [getGeneralInformationData, generalInformationData] = useLazyQuery(
    GET_GENERAL_INFORMATION
  );
  const [getLegalRepresentativesSectionData, legalRepresentativesSectionData] =
    useLazyQuery(GET_LEGAL_REPRESENTATIVES_SECTION_DATA);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedSignatoryId, setSelectedSignatoryId] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [loadingSignatureSend, setLoadingSignatureSend] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const { persons, isFormCompleted, isFormDisqualified, validation } =
    useSelector((state) => state.clientePotencialSlice);
  const [loadingSection, setLoadingSection] = useState(true);
  const [contacts, setContacts] = useState([]);

  const [selectedGlobalSignatureType, setSelectedGlobalSignatureType] = useState('');

  const nombre = watch('nombre');
  const apellidos = watch('apellidos');
  const documentoIdentidad = watch('documentoIdentidad');
  const email = watch('email');
  const cargo = watch('cargo');
  const esRepresentante = watch('esRepresentante');
  const tipoFirma = watch('tipoFirma');

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data?.formResponses) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      getSignaturesSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });

      getGeneralInformationData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });

      getLegalRepresentativesSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (signaturesSectionData?.data?.signaturesSection) {
      setSignaturesSectionId(
        signaturesSectionData?.data?.signaturesSection?.id
      );
      setSelectedGlobalSignatureType(signaturesSectionData?.data?.signaturesSection?.signatureType?.id || '');
      // console.log("Prueba signature: ", signaturesSectionData?.data?.signaturesSection?.signaturesCard?.signatories.some(x => x.isRepresentative === true));

      if (
        signaturesSectionData?.data?.signaturesSection?.signaturesCard
          ?.signatories?.length <= 4
      ) {
        setShowWarning(false);

        // getSignatureCardsPdfByFormResponses({
        //   variables: {
        //     formResponseId:
        //       formResponseByIdentification?.data?.formResponses[0]?.id,
        //   },
        // });
      } else {
        setShowWarning(true);
      }
    }
  }, [signaturesSectionData]);

  // useEffect(() => {
  //     console.log("Prueba: ", signatureCardsPdfByFormResponses?.data);
  //     if(signatureCardsPdfByFormResponses?.data?.signaturesCardPDFDataByFormResponse) {
  //         if(signaturesSectionData?.data?.signaturesSection?.signaturesCard?.signatories?.length > 0) {
  //             generatePdfFile();
  //         }
  //     }

  // }, [signatureCardsPdfByFormResponses]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedSignatoryId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
    );
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/miembros-del-consejo'
    );
  };

  const addSignatureCard = async () => {
    const emailIsInUse =
      signaturesSectionData?.data?.signaturesSection?.signaturesCard?.signatories?.find(
        (signatory) =>
          signatory.id !== selectedSignatoryId && email === signatory.email
      );

    if (emailIsInUse) {
      toast('Ya existe un firmante con este correo', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    const identificationIsInUse =
      signaturesSectionData?.data?.signaturesSection?.signaturesCard?.signatories?.find(
        (signatory) =>
          signatory.id !== selectedSignatoryId &&
          documentoIdentidad === signatory.identification
      );

    if (identificationIsInUse) {
      toast('Ya existe un firmante con esta identificación', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    btnCloseModalRef.current.click();
    reset();

    let data = {};

    if (esRepresentante !== true) {
      setValue('esRepresentante', false);
    }

    //Si companyOfficesSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (signaturesSectionId && signaturesSectionId !== '') {
      // Si selectedBeneficiaryId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
      if (selectedSignatoryId && selectedSignatoryId !== '') {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: signaturesSectionId,
          signaturesCardId:
            signaturesSectionData?.data?.signaturesSection?.signaturesCard?.id,
          signatoryInputTypes: {
            id: selectedSignatoryId,
            firstName: nombre,
            lastName: apellidos,
            identification: documentoIdentidad,
            charge: cargo,
            email: email,
            isRepresentative: false,
            signatureTypeId: signaturesSectionData?.data?.signaturesSection?.signatureType?.id,
          },
        };

        setSelectedSignatoryId('');
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: signaturesSectionId,
          signaturesCardId:
            signaturesSectionData?.data?.signaturesSection?.signaturesCard?.id,
          signatoryInputTypes: {
            firstName: nombre,
            lastName: apellidos,
            identification: documentoIdentidad,
            charge: cargo,
            email: email,
            isRepresentative: false,
            signatureTypeId: signaturesSectionData?.data?.signaturesSection?.signatureType?.id,
          },
        };
      }
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        signatoryInputTypes: {
          firstName: nombre,
          lastName: apellidos,
          identification: documentoIdentidad,
          charge: cargo,
          email: email,
          isRepresentative: false,
          signatureTypeId: signaturesSectionData?.data?.signaturesSection?.signatureType?.id,
        },
      };
    }

    const saveData = await saveSignaturesSection({
      variables: {
        signaturesSection: data,
      },
    });

    loadPersons(dispatch, {
      legalRepresentatives: persons.legalRepresentatives,
      shareholders: persons.shareholders,
      signatores:
        saveData.data?.saveSignaturesSection?.signaturesCard?.signatories ?? [],
      beneficiaries: persons.beneficiaries,
      counselMembers: persons.counselMembers,
    });
  };

  const deleteCardElement = async (id) => {
    await removeSignatory({
      variables: {
        signatoryId: id,
      },
    });

    loadPersons(dispatch, {
      legalRepresentatives: persons.legalRepresentatives,
      shareholders: persons.shareholders,
      signatores:
        persons.signatores.filter((signatory) => signatory.id !== id) ?? [],
      beneficiaries: persons.beneficiaries,
      counselMembers: persons.counselMembers,
    });
  };

  const handleEdit = (id) => {
    setSelectedSignatoryId(id);
    const selectedSignatory =
      signaturesSectionData?.data?.signaturesSection?.signaturesCard?.signatories?.find(
        (x) => x.id === id
      );
    if (id && selectedSignatory) {
      setForm(selectedSignatory);
    }
  };

  const setForm = (selectedSignatory) => {
    selectedSignatory?.firstName &&
      setValue('nombre', selectedSignatory?.firstName);
    selectedSignatory?.lastName &&
      setValue('apellidos', selectedSignatory?.lastName);
    selectedSignatory?.identification &&
      setValue('documentoIdentidad', selectedSignatory?.identification);
    if (selectedSignatory?.charge || selectedSignatory?.position) {
      setValue(
        'cargo',
        selectedSignatory?.charge || selectedSignatory?.position
      );
    }
    selectedSignatory?.email && setValue('email', selectedSignatory?.email);
    selectedSignatory?.isRepresentative &&
      setValue('esRepresentante', selectedSignatory?.isRepresentative);
    setValue('tipoFirma', signaturesSectionData?.data?.signaturesSection?.signatureType?.id);
  };

  const generatePdfFile = () => {
    setLoadingSignatureSend(true);
    const recipients = [];
    // const signatories = signatureCardsPdfByFormResponses?.data?.signaturesCardPDFDataByFormResponse?.signatories;
    const signatories =
      signaturesSectionData?.data?.signaturesSection?.signaturesCard
        ?.signatories;
    const metadataList = getMetadataList(signatories);
    const legalRepresentative =
      legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives.find(
        (x) => x.legalRepresentativeType?.name === 'Principal'
      );
    const commercialName =
      generalInformationData?.data?.companyInformationSection?.commercialName;

    const {
      clientCode,
      identification,
      isNewAccount,
      isIncludingNewSignature,
      isSupersedingPreviousSignatureCard,
    } =
      signatureCardsPdfByFormResponses?.data
        ?.signaturesCardPDFDataByFormResponse;

    recipients.push({
      key: 'client',
      mail: legalRepresentative?.email,
      name: commercialName,
      id: identification,
      order: 1,
    });

    let index = 1;
    for (let signatory of signatories) {
      recipients.push({
        key: 'signer' + index,
        mail: signatory?.email,
        name: signatory?.firstName + ' ' + signatory?.lastName,
        id: signatory?.identification,
        order: 1,
      });

      index++;
    }

    const data = {
      groupCode: 'mapprisk',
      workflow: {
        type: 'WEB',
      },
      recipients: recipients,
      customization: {
        requestMailBody:
          'Hola {{recipient.name}}. <br /><br />Ya puedes revisar y firmar el contrato. Haz click en el siguiente enlace y sigue las instrucciones.',
      },
      messages: [
        {
          document: {
            templateCode: 'parval_firmante' + signatories?.length,
            formRequired: true,
            formDisabled: true,
          },
        },
      ],
      metadataList: metadataList,
    };

    axios
      .post('/middleware/viafirma/set', data)
      .then((res) => {
        // console.log("res: ", res);
        setLoadingSignatureSend(false);

        toast(
          'Se han enviado los correos a los firmantes, por favor revisar los correos y completar las firmas.',
          {
            type: 'success',
            theme: 'colored',
          }
        );
      })
      .catch((error) => {
        console.error(error);
        setLoadingSignatureSend(false);
        toast('Ha ocurrido un error enviando los documentos para las firmas.', {
          type: 'error',
          theme: 'colored',
        });
      });
  };

  const getMetadataList = (signatories) => {
    let metadaList = [];
    const legalRepresentative =
      legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives.find(
        (x) => x.legalRepresentativeType?.name === 'Principal'
      );
    const commercialName =
      generalInformationData?.data?.companyInformationSection?.commercialName;
    const {
      clientCode,
      identification,
      isNewAccount,
      isIncludingNewSignature,
      isSupersedingPreviousSignatureCard,
    } =
      signatureCardsPdfByFormResponses?.data
        ?.signaturesCardPDFDataByFormResponse;

    // console.log("Respuesta servicio: ", signatureCardsPdfByFormResponses?.data?.signaturesCardPDFDataByFormResponse);
    // console.log("Representante: ", legalRepresentative);

    if (signatories.length == 1) {
      metadaList = [
        {
          key: 'day',
          value: new Date().getDate().toString(),
        },
        {
          key: 'month',
          value: (new Date().getMonth() + 1).toString(),
        },
        {
          key: 'year',
          value: new Date().getFullYear().toString(),
        },
        {
          key: 'clientCode',
          value: clientCode,
        },
        {
          key: 'newAccount',
          value: isNewAccount ? 'Si' : 'No',
        },
        {
          key: 'newSign',
          value: isIncludingNewSignature ? 'Si' : 'No',
        },
        {
          key: 'oldSigns',
          value: isSupersedingPreviousSignatureCard ? 'Si' : 'No',
        },
        {
          key: 'clientName',
          value: commercialName,
        },
        {
          key: 'clientRnc',
          value: identification,
        },
        {
          key: 'clientLegalRepresentativeName',
          value:
            legalRepresentative?.firstName +
            ' ' +
            legalRepresentative?.lastName,
        },
        {
          key: 'clientLegalRepresentativeIdentification',
          value: legalRepresentative?.identification,
        },
        {
          key: 'signerName1',
          value: signatories[0]?.firstName + ' ' + signatories[0].lastName,
        },
        {
          key: 'signerIdentification1',
          value: signatories[0]?.identification,
        },
        {
          key: 'signerPosition1',
          value: signatories[0]?.charge,
        },
        {
          key: 'signerEmail1',
          value: signatories[0]?.email,
        },
        {
          key: 'signerSignType1',
          value: convertSignatureTypeName(signatories[0]?.signatureType?.name),
        },
        {
          key: 'signerEmpowered1',
          value: signatories[0]?.isRepresentative ? 'Yes' : 'No',
        },
      ];
    } else if (signatories.length == 2) {
      metadaList = [
        {
          key: 'day',
          value: new Date().getDate().toString(),
        },
        {
          key: 'month',
          value: (new Date().getMonth() + 1).toString(),
        },
        {
          key: 'year',
          value: new Date().getFullYear().toString(),
        },
        {
          key: 'clientCode',
          value: clientCode,
        },
        {
          key: 'newAccount',
          value: isNewAccount ? 'Si' : 'No',
        },
        {
          key: 'newSign',
          value: isIncludingNewSignature ? 'Si' : 'No',
        },
        {
          key: 'oldSigns',
          value: isSupersedingPreviousSignatureCard ? 'Si' : 'No',
        },
        {
          key: 'clientName',
          value: commercialName,
        },
        {
          key: 'clientRnc',
          value: identification,
        },
        {
          key: 'clientLegalRepresentativeName',
          value:
            legalRepresentative?.firstName +
            ' ' +
            legalRepresentative?.lastName,
        },
        {
          key: 'clientLegalRepresentativeIdentification',
          value: legalRepresentative?.identification,
        },
        {
          key: 'signerName1',
          value: signatories[0]?.firstName + ' ' + signatories[0].lastName,
        },
        {
          key: 'signerIdentification1',
          value: signatories[0]?.identification,
        },
        {
          key: 'signerPosition1',
          value: signatories[0]?.charge,
        },
        {
          key: 'signerEmail1',
          value: signatories[0]?.email,
        },
        {
          key: 'signerSignType1',
          value: convertSignatureTypeName(signatories[0]?.signatureType.name),
        },
        {
          key: 'signerEmpowered1',
          value: signatories[0]?.isRepresentative ? 'Yes' : 'No',
        },
        {
          key: 'signerName2',
          value: signatories[1]?.firstName + ' ' + signatories[1].lastName,
        },
        {
          key: 'signerIdentification2',
          value: signatories[1]?.identification,
        },
        {
          key: 'signerPosition2',
          value: signatories[1]?.charge,
        },
        {
          key: 'signerEmail2',
          value: signatories[1]?.email,
        },
        {
          key: 'signerSignType2',
          value: convertSignatureTypeName(signatories[1]?.signatureType.name),
        },
        {
          key: 'signerEmpowered2',
          value: signatories[1]?.isRepresentative ? 'Yes' : 'No',
        },
      ];
    } else if (signatories.length == 3) {
      metadaList = [
        {
          key: 'day',
          value: new Date().getDate().toString(),
        },
        {
          key: 'month',
          value: (new Date().getMonth() + 1).toString(),
        },
        {
          key: 'year',
          value: new Date().getFullYear().toString(),
        },
        {
          key: 'clientCode',
          value: clientCode,
        },
        {
          key: 'newAccount',
          value: isNewAccount ? 'Si' : 'No',
        },
        {
          key: 'newSign',
          value: isIncludingNewSignature ? 'Si' : 'No',
        },
        {
          key: 'oldSigns',
          value: isSupersedingPreviousSignatureCard ? 'Si' : 'No',
        },
        {
          key: 'clientName',
          value: commercialName,
        },
        {
          key: 'clientRnc',
          value: identification,
        },
        {
          key: 'clientLegalRepresentativeName',
          value:
            legalRepresentative?.firstName +
            ' ' +
            legalRepresentative?.lastName,
        },
        {
          key: 'clientLegalRepresentativeIdentification',
          value: legalRepresentative?.identification,
        },
        {
          key: 'signerName1',
          value: signatories[0]?.firstName + ' ' + signatories[0].lastName,
        },
        {
          key: 'signerIdentification1',
          value: signatories[0]?.identification,
        },
        {
          key: 'signerPosition1',
          value: signatories[0]?.charge,
        },
        {
          key: 'signerEmail1',
          value: signatories[0]?.email,
        },
        {
          key: 'signerSignType1',
          value: convertSignatureTypeName(signatories[0]?.signatureType.name),
        },
        {
          key: 'signerEmpowered1',
          value: signatories[0]?.isRepresentative ? 'Yes' : 'No',
        },
        {
          key: 'signerName2',
          value: signatories[1]?.firstName + ' ' + signatories[1].lastName,
        },
        {
          key: 'signerIdentification2',
          value: signatories[1]?.identification,
        },
        {
          key: 'signerPosition2',
          value: signatories[1]?.charge,
        },
        {
          key: 'signerEmail2',
          value: signatories[1]?.email,
        },
        {
          key: 'signerSignType2',
          value: convertSignatureTypeName(signatories[1]?.signatureType.name),
        },
        {
          key: 'signerEmpowered2',
          value: signatories[1]?.isRepresentative ? 'Yes' : 'No',
        },
        {
          key: 'signerName3',
          value: signatories[2]?.firstName + ' ' + signatories[2].lastName,
        },
        {
          key: 'signerIdentification3',
          value: signatories[2]?.identification,
        },
        {
          key: 'signerPosition3',
          value: signatories[2]?.charge,
        },
        {
          key: 'signerEmail3',
          value: signatories[2]?.email,
        },
        {
          key: 'signerSignType3',
          value: convertSignatureTypeName(signatories[2]?.signatureType.name),
        },
        {
          key: 'signerEmpowered3',
          value: signatories[2]?.isRepresentative ? 'Yes' : 'No',
        },
      ];
    } else if (signatories.length == 4) {
      metadaList = [
        {
          key: 'day',
          value: new Date().getDate().toString(),
        },
        {
          key: 'month',
          value: (new Date().getMonth() + 1).toString(),
        },
        {
          key: 'year',
          value: new Date().getFullYear().toString(),
        },
        {
          key: 'clientCode',
          value: clientCode,
        },
        {
          key: 'newAccount',
          value: isNewAccount ? 'Si' : 'No',
        },
        {
          key: 'newSign',
          value: isIncludingNewSignature ? 'Si' : 'No',
        },
        {
          key: 'oldSigns',
          value: isSupersedingPreviousSignatureCard ? 'Si' : 'No',
        },
        {
          key: 'clientName',
          value: commercialName,
        },
        {
          key: 'clientRnc',
          value: identification,
        },
        {
          key: 'clientLegalRepresentativeName',
          value:
            legalRepresentative?.firstName +
            ' ' +
            legalRepresentative?.lastName,
        },
        {
          key: 'clientLegalRepresentativeIdentification',
          value: legalRepresentative?.identification,
        },
        {
          key: 'signerName1',
          value: signatories[0]?.firstName + ' ' + signatories[0].lastName,
        },
        {
          key: 'signerIdentification1',
          value: signatories[0]?.identification,
        },
        {
          key: 'signerPosition1',
          value: signatories[0]?.charge,
        },
        {
          key: 'signerEmail1',
          value: signatories[0]?.email,
        },
        {
          key: 'signerSignType1',
          value: convertSignatureTypeName(signatories[0]?.signatureType.name),
        },
        {
          key: 'signerEmpowered1',
          value: signatories[0]?.isRepresentative ? 'Yes' : 'No',
        },
        {
          key: 'signerName2',
          value: signatories[1]?.firstName + ' ' + signatories[1].lastName,
        },
        {
          key: 'signerIdentification2',
          value: signatories[1]?.identification,
        },
        {
          key: 'signerPosition2',
          value: signatories[1]?.charge,
        },
        {
          key: 'signerEmail2',
          value: signatories[1]?.email,
        },
        {
          key: 'signerSignType2',
          value: convertSignatureTypeName(signatories[1]?.signatureType.name),
        },
        {
          key: 'signerEmpowered2',
          value: signatories[1]?.isRepresentative ? 'Yes' : 'No',
        },
        {
          key: 'signerName3',
          value: signatories[2]?.firstName + ' ' + signatories[2].lastName,
        },
        {
          key: 'signerIdentification3',
          value: signatories[2]?.identification,
        },
        {
          key: 'signerPosition3',
          value: signatories[2]?.charge,
        },
        {
          key: 'signerEmail3',
          value: signatories[2]?.email,
        },
        {
          key: 'signerSignType3',
          value: convertSignatureTypeName(signatories[2]?.signatureType.name),
        },
        {
          key: 'signerEmpowered3',
          value: signatories[2]?.isRepresentative ? 'Yes' : 'No',
        },
        {
          key: 'signerName4',
          value: signatories[3]?.firstName + ' ' + signatories[3].lastName,
        },
        {
          key: 'signerIdentification4',
          value: signatories[3]?.identification,
        },
        {
          key: 'signerPosition4',
          value: signatories[3]?.charge,
        },
        {
          key: 'signerEmail4',
          value: signatories[3]?.email,
        },
        {
          key: 'signerSignType4',
          value: convertSignatureTypeName(signatories[3]?.signatureType.name),
        },
        {
          key: 'signerEmpowered4',
          value: signatories[3]?.isRepresentative ? 'Yes' : 'No',
        },
      ];
    }

    return metadaList;
  };

  const recolectarFirmas = () => {
    const hayFirmantes =
      signaturesSectionData?.data?.signaturesSection?.signaturesCard
        ?.signatories?.length > 0
        ? true
        : false;
    const hayRepLegalPrincipal =
      legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives.some(
        (x) => x.legalRepresentativeType?.name === 'Principal'
      );
    const hayCommercialName =
      generalInformationData?.data?.companyInformationSection
        ?.commercialName !== undefined
        ? true
        : false;

    if (hayFirmantes && hayRepLegalPrincipal && hayCommercialName) {
      generatePdfFile();
    }

    if (!hayFirmantes) {
      toast(
        'Debe agregar por lo menos un firmante para poder recolectar las firmas.',
        {
          type: 'error',
          theme: 'colored',
        }
      );
    }

    if (!hayRepLegalPrincipal) {
      toast(
        'Debe agregar un representante legal de tipo principal para poder recolectar las firmas.',
        {
          type: 'error',
          theme: 'colored',
        }
      );
    }

    if (!hayCommercialName) {
      toast(
        'Debe completar el formulario de generales para poder recolectar las firmas.',
        {
          type: 'error',
          theme: 'colored',
        }
      );
    }
  };

  const convertSignatureTypeName = (signatureTypeName) => {
    let newSignatureTypeName = '';
    if (signatureTypeName === 'Conjunta') {
      newSignatureTypeName = 'conjunta';
    } else if (signatureTypeName === 'Individual') {
      newSignatureTypeName = 'individual';
    }

    return newSignatureTypeName;
  };

  useEffect(() => {
    if(
      selectedGlobalSignatureType &&
      selectedGlobalSignatureType !== signaturesSectionData?.data?.signaturesSection?.signatureType?.id
    ){
      let data;
      if(signaturesSectionId){
        data = {
          id: signaturesSectionId,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          signatureTypeId: selectedGlobalSignatureType,
          signaturesCardId:
            signaturesSectionData?.data?.signaturesSection?.signaturesCard?.id,
          signatoryInputTypes: signaturesSectionData?.data?.signaturesSection?.signaturesCard?.signatories?.map((signatory) => ({
            id: signatory?.id,
            firstName: signatory?.firstName,
            lastName: signatory?.lastName,
            identification: signatory?.identification,
            charge: signatory?.charge,
            email: signatory?.email,
            isRepresentative: signatory?.isRepresentative,
            signatureTypeId: selectedGlobalSignatureType,
          })),
        };
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          signatureTypeId: selectedGlobalSignatureType,
          signatoryInputTypes: [],
        };
      }

      saveSignaturesSection({
        variables: {
          signaturesSection: data,
        },
      }).then(() => {
        setSuccessNotify('Sección actualizada con exito!');
      });
    }
  }, [selectedGlobalSignatureType])

  const copiarContacto = watch('copiarContacto');
  const contacto = watch('contacto');

  useEffect(() => {
    let shareholders = [];
    persons.shareholders.forEach((sh) => {
      if (sh.personType.name === 'Persona Física') {
        shareholders.push({
          id: sh.id,
          firstName: sh.shareholderPersonDetails.firstName ?? '',
          lastName: sh.shareholderPersonDetails.lastName ?? '',
          identificationType: sh.identificationType,
          identification: sh.identification,
          position: sh.shareholderPersonDetails.charge,
          isUnitedStatesCitizen:
            sh.shareholderPersonDetails.unitedStatesCitizen,
          isInDarkList: sh.isInDarkList,
          politicExposure: sh.shareholderPersonDetails.politicExposure,
          nationalityCountry: sh.country,
        });
      }
    });

    const legalRepresentatives = persons.legalRepresentatives.map((legal) => ({
      id: legal.id,
      firstName: legal.firstName,
      lastName: legal.lastName,
      email: legal.correspondenceEmail?.email,
      identificationType: legal.identificationType,
      identification: legal.identification,
      position: legal.position,
      nationalityCountry: legal.nationalityCountry,
      isUnitedStatesCitizen: legal.isUnitedStatesCitizen,
      isInDarkList: legal.isInDarkList,
      politicExposure: legal.politicExposure,
      address: comprexAddress(legal.address),
    }));

    const beneficiaries = persons.beneficiaries.map((beneficiary) => ({
      id: beneficiary.id,
      firstName: beneficiary.firstName,
      lastName: beneficiary.lastName,
      email: beneficiary.email,
      identificationType: beneficiary.identificationType,
      identification: beneficiary.identificationNumber,
      position: beneficiary.occupation,
      isUnitedStatesCitizen: beneficiary.isUnitedStatesCitizen,
      socialSecurityNumber: beneficiary.socialSecurityNumber,
      politicExposure: beneficiary.politicExposure,
      address: beneficiary.address,
    }));

    const counselMembers = persons.counselMembers.map((counselMember) => ({
      id: counselMember.id,
      firstName: counselMember.firstName,
      lastName: counselMember.lastName,
      identificationType: counselMember.identificationType,
      identification: counselMember.identification,
      position: counselMember.position,
      nationalityCountry: counselMember.nationalityCountry,
      isInDarkList: counselMember.isInDarkList,
      politicExposure: counselMember.politicExposure,
      contactFatca: counselMember.contactFatca,
    }));

    let shareholdersBeneficiaries = [];

    persons.shareholders.forEach((sh) => {
      sh.beneficiaries.forEach((beneficiary) =>
        shareholdersBeneficiaries.push({
          id: beneficiary.id,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          email: beneficiary.email,
          identificationType: beneficiary.identificationType,
          identification: beneficiary.identificationNumber,
          position: beneficiary.occupation,
          isUnitedStatesCitizen: beneficiary.isUnitedStatesCitizen,
          socialSecurityNumber: beneficiary.socialSecurityNumber,
          politicExposure: beneficiary.politicExposure,
          phoneNumber: beneficiary.phoneNumber,
          address: beneficiary.address,
        })
      );
    });

    let personsToCopy = [
      ...shareholders,
      ...legalRepresentatives,
      ...beneficiaries,
      ...shareholdersBeneficiaries,
      ...counselMembers,
    ];

    personsToCopy = mergeObjectsWithSameId(personsToCopy);

    const idsToRemove = new Set(
      persons.signatores.map((item) => item.identification)
    );
    personsToCopy = personsToCopy.filter(
      (item) => !idsToRemove.has(item.identification)
    );

    setContacts(personsToCopy);
  }, [persons]);

  useEffect(() => {
    if (contacto && contacto !== 'DEFAULT') {
      const personToCopy = contacts.find((contact) => contact.id === contacto);
      reset();
      setValue('contacto', contacto);
      setValue('copiarContacto', copiarContacto);
      setForm(personToCopy);
    }
  }, [contacto]);

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'signaturesSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3 signature-card-section">
          <div>
            <h3 className="formTitle">
              Tarjeta de Firmas (Firmantes Autorizados)
              <ModalInfo
                Icon={<FontAwesomeIcon icon={faCircleInfo} />}
                title={'Tarjeta de Firmas (Firmantes Autorizados)'}
                description={
                  "En el formulario 'Tarjeta de Firmas (Firmantes Autorizados)', se detallan las personas autorizadas para realizar transacciones y retiros en nombre de la empresa. Este espacio proporciona un registro completo de los individuos designados con la responsabilidad de firmar documentos y llevar a cabo operaciones financieras en representación de la entidad. La tarjeta de firmas es esencial para garantizar la seguridad y la legitimidad de las transacciones, asegurando que solo las personas debidamente autorizadas puedan ejercer control sobre las operaciones financieras de la empresa."
                }
              />
              {!!validationErrors?.length && (
                <span style={{ marginLeft: 30 }}>
                  <ModalInfo
                    color="white"
                    bgcolor="#B90E0A"
                    Icon={
                      <Error
                        style={{
                          color: '#B90E0A',
                          fontSize: 25,
                          marginTop: '-5px',
                        }}
                      />
                    }
                    description={
                      validationErrors.length === 1 &&
                      validationErrors[0] === 'signatories'
                        ? 'Es necesario que agregues por lo menos un firmante.'
                        : `Esta sección tiene campos incompletos.`
                    }
                  />
                </span>
              )}
            </h3>

            <h4 className="bff-sub-title" style={{ textAlign: 'left' }}>
              Si tienes más de una tarjeta de firmas, puedes agregarlas todas
              aquí.
            </h4>
          </div>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <>
              <div className="repository-status m-0 p-0 mt-2 mb-4">
                <p
                  className="m-0 p-0 fw-bold"
                  style={{ fontSize: 17, color: 'rgb(87, 87, 87)', textAlign: 'left' }}
                >
                  Tipo de firma *
                </p>
                <FormControl
                  style={{ width: 250, marginTop: 20 }}
                  disabled={isFormCompleted || isFormDisqualified}
                >
                  <Select
                    style={{ borderRadius: 10 }}
                    value={signatureTypes?.signatureTypes?.length ? selectedGlobalSignatureType : '0'}
                    onChange={(event) =>
                      setSelectedGlobalSignatureType(event.target.value)
                    }
                  >
                    {signatureTypes?.signatureTypes?.length ? (
                      signatureTypes?.signatureTypes.map((s) => (
                        <MenuItem key={s.id} value={s.id}>
                          {s.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={'0'}>Cargando...</MenuItem>
                    )}
                  </Select>
                </FormControl>
                {!signaturesSectionData?.data?.signaturesSection?.signatureType?.id &&
                  <div style={{ marginTop: 40, marginBottom: '-15px' }}>
                    <ErrorText hasError={hasError('signatureType')} />
                  </div>
                }
              </div>
              <div className="add-card-container">
                {signaturesSectionData?.data?.signaturesSection?.signaturesCard?.signatories?.map(
                  (signatureCard, index) => {
                    return (
                      <div
                        key={index}
                        data-bs-toggle={isUpdating ? 'modal' : ''}
                        id={signatureCard?.id}
                        data-bs-target={isUpdating ? '#exampleModal' : ''}
                      >
                        <CardItems
                          key={index}
                          nameElement={
                            signatureCard?.firstName +
                            ' ' +
                            signatureCard?.lastName
                          }
                          identityDocument={signatureCard?.identification}
                          idElement={signatureCard.id}
                          deleteElement={deleteCardElement}
                          handleEdit={handleEdit}
                          setIsUpdating={setIsUpdating}
                          showDeleteButton={!isFormCompleted && !isFormDisqualified}
                          detalle={
                            signatureCard?.isRepresentative ? 'Apoderado' : null
                          }
                        />
                      </div>
                    );
                  }
                )}
                {!isFormCompleted && !isFormDisqualified ? (
                  signaturesSectionData?.data?.signaturesSection?.signatureType?.id ? 
                  (<div
                    className="add-card"
                    onClick={() => {
                      setSelectedSignatoryId('');
                      reset();
                      setValue('tipoFirma', signaturesSectionData?.data?.signaturesSection?.signatureType?.id);
                    }}
                    data-bs-toggle={
                      signaturesSectionData?.data?.signaturesSection
                        ?.signaturesCard?.signatories?.length < 4 ||
                      signaturesSectionData?.data?.signaturesSection
                        ?.signaturesCard?.signatories === undefined
                        ? 'modal'
                        : ''
                    }
                    data-bs-target={
                      signaturesSectionData?.data?.signaturesSection
                        ?.signaturesCard?.signatories?.length < 4 ||
                      signaturesSectionData?.data?.signaturesSection
                        ?.signaturesCard?.signatories === undefined
                        ? '#exampleModal'
                        : ''
                    }
                  >
                    <div className="image-icon-container">
                      <FontAwesomeIcon icon={faAdd} className="image-icon" />
                    </div>
                    <h4 className="bk-card-title">Agregar Tarjeta</h4>
                  </div>) : (
                  <div className="add-card" style={{ pointerEvents: 'none' }}>
                    <div className="image-icon-container">
                      <FontAwesomeIcon
                        icon={faWarning}
                        className="image-icon"
                      />
                    </div>
                    <h4 className="bk-card-title">
                      {' '}
                      Completa los campos previos{' '}
                    </h4>
                  </div>)
                ) : null}
              </div>
            </>
          )}

          {showWarning === true ? (
            <div className="row text-center">
              <p className="text-error">
                Solo se pueden agregar 4 firmantes como máximo.
              </p>
            </div>
          ) : null}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addSignatureCard)}
                  className="fields-modal-container frm-main-share-holders"
                >
                  <div className="fields-modal-header mb-3">
                    <h4 className="modal-title" id="exampleModalLabel">
                      {!isFormCompleted && !isFormDisqualified
                        ? selectedSignatoryId
                          ? 'Editar'
                          : 'Agregar'
                        : 'Ver'}{' '}
                      Tarjeta
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      onClick={() => reset()}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="row fields-modal-content">
                    <div className="row">
                      {!isFormCompleted && !isFormDisqualified ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="copiar-contacto"
                            className="label-field"
                          >
                            ¿Copiar información de otro contacto?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="copiar-contacto"
                              defaultValue="No"
                              {...register('copiarContacto')}
                            >
                              <option value="No">No</option>
                              <option value="Si">Si</option>
                            </select>
                          </div>
                        </div>
                      ) : null}
                      {!isFormCompleted && !isFormDisqualified && copiarContacto === 'Si' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="contacto" className="label-field">
                            Contacto <span>*</span>
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="contacto"
                              defaultValue={'DEFAULT'}
                              {...register('contacto', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona
                              </option>
                              {contacts.map((contact) => {
                                return (
                                  <option key={contact?.id} value={contact?.id}>
                                    {contact?.firstName +
                                      ' ' +
                                      contact?.lastName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {errors.copiarContacto && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="field-form col-lg-6 col-sm-12">
                        <label htmlFor="nombre" className="label-field">
                          Nombre <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="nombre"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('nombre', {
                            required: true,
                          })}
                        />
                        {errors.nombre?.type === 'required' && (
                          <p className="text-danger">
                            El campo Nombre es requerido
                          </p>
                        )}
                        {selectedSignatoryId &&
                          !nombre &&
                          errors.nombre?.type !== 'required' && (
                            <ErrorText hasError={hasError('firstName')} />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12">
                        <label htmlFor="apellidos" className="label-field">
                          Apellidos <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="apellidos"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('apellidos', {
                            required: true,
                          })}
                        />
                        {errors.apellidos?.type === 'required' && (
                          <p className="text-danger">
                            El campo Apellidos es requerido
                          </p>
                        )}
                        {selectedSignatoryId &&
                          !apellidos &&
                          errors.apellidos?.type !== 'required' && (
                            <ErrorText hasError={hasError('lastName')} />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12">
                        <label
                          htmlFor="documento-identidad"
                          className="label-field"
                        >
                          Número de identificación <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          maxLength="13"
                          pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                          placeholder="000-0000000-0"
                          id="cedula"
                          disabled={isFormCompleted || isFormDisqualified}
                          onInput={() => {
                            validateInputCedula();
                          }}
                          {...register('documentoIdentidad', {
                            required: true,
                          })}
                        />
                        {errors.documentoIdentidad?.type === 'required' && (
                          <p className="text-danger">
                            El campo Número de identificación es requerido
                          </p>
                        )}
                        {selectedSignatoryId &&
                          !documentoIdentidad &&
                          errors.documentoIdentidad?.type !== 'required' && (
                            <ErrorText hasError={hasError('identification')} />
                          )}
                      </div>
                      {/* <div className='field-form col-lg-6 col-sm-12'>
                                    <div className='dr-same-direction-container'>
                                        <input type="checkbox" className='me-2' id="dr-same-direction" {...register('esRepresentante')} />
                                        <label className='dr-same-direction-text' htmlFor="dr-same-direction me-1">Marcar si la persona es apoderado</label>
                                    </div> 
                                    </div>  */}
                      <div className="field-form col-lg-6 col-sm-12">
                        <label htmlFor="email" className="label-field">
                          Correo electrónico <span>*</span>
                        </label>
                        <input
                          type="email"
                          className="input-form"
                          placeholder=""
                          id="email"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('email', {
                            required: true,
                          })}
                        />
                        {errors.email?.type === 'required' && (
                          <p className="text-danger">
                            El campo Correo electrónico es requerido
                          </p>
                        )}
                        {selectedSignatoryId &&
                          !email &&
                          errors.email?.type !== 'required' && (
                            <ErrorText hasError={hasError('email')} />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12">
                        <label htmlFor="cargo" className="label-field">
                          Cargo <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="cargo"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('cargo', {
                            required: true,
                          })}
                        />
                        {errors.cargo?.type === 'required' && (
                          <p className="text-danger">
                            El campo Cargo es requerido
                          </p>
                        )}
                        {selectedSignatoryId &&
                          !cargo &&
                          errors.cargo?.type !== 'required' && (
                            <ErrorText hasError={hasError('charge')} />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12">
                        <label htmlFor="tipoFirma" className="label-field">
                          Tipo de firma <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="tipoFirma"
                            defaultValue={'DEFAULT'}
                            disabled={true}
                            {...register('tipoFirma', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {signatureTypes?.signatureTypes.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.tipoFirma && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {selectedSignatoryId &&
                          (!tipoFirma || tipoFirma === 'DEFAULT') &&
                          errors.tipoFirma && (
                            <ErrorText hasError={hasError('signatureType')} />
                          )}
                      </div>
                    </div>
                  </div>

                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="row d-flex justify-content-center">
                      <button type="submit" className="mt-2 mb-4 btn-add">
                        Guardar
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default SignatureCardForm;
