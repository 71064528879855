import {
  currencyFormat,
  formatName,
  ram,
} from '../../../shared/utils/functions';
import { Container, Col, Row } from 'react-bootstrap';
import './product.css';
import { PRODUCTSELEMENTS } from '../../../shared/constants/gobal-constants';
import {
  AttachMoney,
  CableSharp,
  CurrencyExchange,
  Lock,
} from '@mui/icons-material';

export default function Product({ product }) {
  const getEmisor = (singleProduct) => {
    return singleProduct.data.find((p) => p.name === 'emisor').value;
  };

  const setMonto = (type, value) => {
    return type === 'monto' ? currencyFormat(Number(value), 2, false) : value;
  };

  const setIcons = (code) => {
    switch (code) {
      case 'EFE':
        return {
          icon: <AttachMoney />,
          color: '#FFFF00',
        };
      case 'RF':
        return {
          icon: <Lock />,
          color: '#9C5150',
        };
      case 'SBB':
        return {
          icon: <CurrencyExchange />,
          color: '#FF8000',
        };
      case 'RV':
        return {
          icon: <AttachMoney />,
          color: '#03D92D',
        };
      case 'MT':
        return {
          icon: 'M',
          color: '#00D3FF',
        };
      case 'CT':
        return {
          icon: <CableSharp />,
          color: '#950101',
        };
      case 'RP':
        return {
          icon: <CurrencyExchange />,
          color: '#252850',
        };
      default:
        return {
          icon: 'A',
          color: '#9B9B9B',
        };
    }
  };

  return (
    <Container className="product-detail-content" fluid>
      <div className="product-top-info">
        <div
          className="product-icon"
          style={{ backgroundColor: setIcons(product.cp.acronym).color }}
        >
          {setIcons(product.cp.acronym).icon}
        </div>
        <div className="ms-3 product-cp-info">
          <div
            className="product-cp-type d-flex align-items-center"
            style={{ marginBottom: -5 }}
          >
            <p className="p-0 m-0">{formatName(product.cp.product)}</p>
            <p className="p-0 m-0 ms-3 text-secondary">{product.cp.coin}</p>
          </div>
          <strong className="product-cp-amount fs-5">
            {product.cp.coin +
              '$ ' +
              currencyFormat(product.cp.amount, 2, false)}
          </strong>
        </div>
      </div>
      <Container fluid className="my-2">
        <Row>
          {product.product.map((p) => (
            <Col className="product-bottom-info" key={p.id ? p.id : 1 * ram()}>
              <div className="product-left">
                <div className="product-image">
                  {/* Mioentras tanto, no hay imagenes disponibles */}
                  <p className="p-0 m-0" style={{ fontSize: 11 }}>
                    Imagen no disponible
                  </p>
                </div>
                <p className="m-0 p-0 product-name mb-3">{getEmisor(p)}</p>
              </div>
              <div className="product-right">
                <Container fluid>
                  <Row className="ms-4 product-grid">
                    {p.data.map((d) =>
                      d.name === 'emisor' ? null : (
                        <div className="product-element" key={d.name}>
                          <p className="p-0 m-0 product-element-name">
                            {PRODUCTSELEMENTS[d.name].name}:
                          </p>
                          <p className="p-0 m-0 product-element-value">
                            {setMonto(PRODUCTSELEMENTS[d.name].type, d.value)}
                          </p>
                        </div>
                      )
                    )}
                  </Row>
                </Container>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}
