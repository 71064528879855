import { Navigate, Route, Routes } from 'react-router-dom';
import ClienteRealApp from '../../../pages/ClienteReal/Index/Index';
import { CLIENTEREALROUTES } from '../../../shared/constants/app-constants';
import ClienteRealDashboard from '../../../pages/ClienteReal/Dashboard/Dashboard';
import {
  azureUserFlowUri,
  COOKIENAMES,
} from '../../../shared/constants/gobal-constants';
import jwt_decode from 'jwt-decode';
import {
  getCookie,
  getRedirectURIOnboarding,
  handleClientePotencialLogOut,
  handleClienteRealLogOut,
} from '../../../shared/utils/functions';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export default function ClienteRoutes() {
  const token = getCookie(COOKIENAMES.user);
  const potencialConfig = getCookie('potencialCustomerConfig', false)
    ? jwt_decode(getCookie('potencialCustomerConfig', false))
    : null;
  const formResponse = JSON.parse(sessionStorage.getItem('selectedFormResponse'));
  const { accessToken } = useSelector((state) => state.clientePotencialSlice);

  return (
    <Routes>
      <Route
        path={CLIENTEREALROUTES.index}
        Component={() => {
          const out = () => {
            handleClientePotencialLogOut();
            handleClienteRealLogOut(true);
            window.location.replace(azureUserFlowUri());
          };
          if (token && token.token) {
            return <Navigate to={CLIENTEREALROUTES.father} />;
          } else if (accessToken && formResponse) {
            if (formResponse?.form?.personType?.name === 'Persona Jurídica') {
              return (
                <Navigate to="/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-general" />
              );
            } else {
              return (
                <Navigate to="/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/datos-generales" />
              );
            }
          } else if (potencialConfig) {
            const obj = getRedirectURIOnboarding(potencialConfig);
            // Reported issue 1466
            if (!obj) {
              out();
            } else {
              toast(obj.toast, {
                type: 'info',
                theme: 'colored',
              });
              return <Navigate to={obj.navigate} />;
            }
          } else {
            out();
          }
        }}
      />
      {/* <Route path="*" element={<Navigate to={CLIENTEREALROUTES.index} />} /> */}
      {/* <Route path="*" Component={() => {
                if(location.pathname !== '/redirect-actived-user-flow'){
                    // return <Navigate to={CLIENTEREALROUTES.index} />
                }
            }} /> */}
      <Route path={CLIENTEREALROUTES.father} element={<ClienteRealApp />}>
        {/* <Route path={CLIENTEREALROUTES.externalRoutes.login} Component={() => {
                        handleClientePotencialLogOut();
                        handleClienteRealLogOut(true);
                        window.location.replace(azureUserFlowUri().login);
                    }}/> */}
        <Route
          path={CLIENTEREALROUTES.protectedRoutes.dashboard.self}
          element={<ClienteRealDashboard />}
        >
          <Route
            index
            path={CLIENTEREALROUTES.protectedRoutes.dashboard.children.index}
          />
          <Route
            path={CLIENTEREALROUTES.protectedRoutes.dashboard.children.index}
          />
          <Route
            path={
              CLIENTEREALROUTES.protectedRoutes.dashboard.children.rateProducts
            }
          />
          {/* <Route path={CLIENTEREALROUTES.protectedRoutes.dashboard.self}/>
                    <Route path={CLIENTEREALROUTES.protectedRoutes.dashboard.self}/>
                    <Route path={CLIENTEREALROUTES.protectedRoutes.dashboard.self}/>
                    <Route path={CLIENTEREALROUTES.protectedRoutes.dashboard.self}/>
                    <Route path={CLIENTEREALROUTES.protectedRoutes.dashboard.self}/>  */}
          <Route
            path="*"
            element={
              <Navigate
                to={CLIENTEREALROUTES.protectedRoutes.dashboard.children.index}
              />
            }
          />
        </Route>
      </Route>
    </Routes>
  );
}
