import './TypeOfApplicantForm.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { GET_CLIENTYPES } from '../../../../../shared/graphQL/queries';
import { useQuery } from '@apollo/client';

const TypeOfApplicantForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [
    parvalMarketingSocialMediaOptions,
    setParvalMarketingSocialMediaOptions,
  ] = useState([]);
  const [preferredContacMethods, setPreferredContacMethods] = useState([]);
  const [marketingAboutParvalOptions, setMarketingAboutParvalOptions] =
    useState([]);
  const [nameClientTypeOption, setNameClientTypeOption] = useState('');
  const [leads, setLeads] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const { data: applicantTypes } = useQuery(GET_CLIENTYPES);
  const navigate = useNavigate();
  const formRef = useRef();

  const tipoSolicitante = watch('tipoSolicitante');
  const tipoCuenta = watch('tipoCuenta');

  const marketingAboutParval = watch('marketingAboutParval');
  const referidoPor = watch('referidoPor');
  const otros = watch('otros');

  useEffect(() => {
    if (applicantTypes) {
      // console.log("Data: ", applicantTypes?.applicantTypes);
      const selectedDefault = applicantTypes?.applicantTypes.find(
        (x) => x.name === 'Cliente Parval de Negocios'
      );
      // console.log("selectedDefault: ", selectedDefault.id);

      setNameClientTypeOption('Cliente Parval de Negocios');
      if (selectedDefault) {
        setValue('tipoSolicitante', selectedDefault.id);
        setValue('tipoCuenta', 'Individual');
      }
    }
  }, [applicantTypes]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveData = () => {
    // navigate('/formularios/persona-fisica/informacion-de-contacto');
  };

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="form-fileds-basic"
      ref={formRef}
    >
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12 columns">
          <h3 className="formTitle text-center">Tipo de Solicitante</h3>

          <div className="field-form">
            <label htmlFor="enviarDocumentos" className="label-field">
              Enviar documentos{' '}
            </label>
            <select
              defaultValue={'DEFAULT'}
              id="enviarDocumentos"
              {...register('enviarDocumentos', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
            {/* {errors.enviarDocumentos && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>

          <div className="field-form">
            <label htmlFor="tipo-solicitante" className="label-field">
              Tipo de Solicitante <span>*</span>
            </label>
            <select
              defaultValue={'DEFAULT'}
              id="tipo-solicitante"
              disabled
              {...register('tipoSolicitante', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {applicantTypes?.applicantTypes?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {errors.tipoSolicitante && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {tipoCuenta === 'Individual' ? (
            <>
              <div className="field-form">
                <label htmlFor="cliente-centralizado" className="label-field">
                  Cliente centralizado <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="cliente-centralizado"
                  {...register('clienteCentralizado', {
                    required: true,
                  })}
                />
                {errors.clienteCentralizado?.type === 'required' && (
                  <p className="text-danger">
                    El campo Cliente centralizado es requerido
                  </p>
                )}
              </div>
            </>
          ) : null}
        </div>

        <div className="col-md-5 col-sm-12 columns">
          <h3 className="formTitle" style={{ visibility: 'hidden' }}>
            Non visible
          </h3>

          {nameClientTypeOption === 'Cliente Parval de Negocios' ? (
            <div className="field-form">
              <label htmlFor="tipo-cuenta" className="label-field">
                Tipo de Cuenta <span>*</span>
              </label>
              <select
                defaultValue={'DEFAULT'}
                id="tipo-cuenta"
                {...register('tipoCuenta', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Individual">Individual</option>
                <option value="Mancomunado">Mancomunado</option>
              </select>
              {errors.tipoCuenta && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>
          ) : null}

          {tipoCuenta === 'Individual' ? (
            <>
              <div className="field-form">
                <label
                  htmlFor="cliente-potecnial-original"
                  className="label-field"
                >
                  Cliente potencial original <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="cliente-potecnial-original"
                  {...register('clienteCentralizado', {
                    required: true,
                  })}
                />
                {errors.clientePotecnialOriginal?.type === 'required' && (
                  <p className="text-danger">
                    El campo Cliente potencial original es requerido
                  </p>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default TypeOfApplicantForm;
