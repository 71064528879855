import { useLocation, useNavigate } from 'react-router-dom';
import './ErrorPage.css';
import { decode64 } from '../../../shared/utils/functions';

export default function ErrorPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const error = queryParams.get('type');

  const goBack = () => {
    navigate('/');
  };
  return (
    <div className="error-container-fluid">
      <div className="container">
        <div className="wrap">
          <div className="row">
            <div className="col-6" style={{ paddingRight: '5%' }}>
              <h2>{error ? decode64(error) : 'Error Inesperado'}</h2>
              <strong>
                Estamos aquí para solucionar cualquier problema y apreciamos tu
                paciencia.
              </strong>
              <p>
                {' '}
                Por favor, contáctanos a través de{' '}
                <strong>info@parval.com.do</strong> o{' '}
                <strong>809-560-0909</strong> para que podamos abordar este
                inconveniente juntos. ¡Gracias por tu comprensión y
                colaboración!
              </p>
              <p className="button" onClick={goBack}>
                Volver a Inicio
              </p>
            </div>
            <div className="col-6">
              <img
                className="imgError"
                src="images/image.svg"
                alt="ErrorImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
