import './PepForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';
import ModalInvestorProfile from '../../../../../shared/UIElements/ModalInvestorProfile/ModalInvestorProfile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const PepForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [pepOptions, setPepOptions] = useState([]);
  const [pepRelationOptions, setPepRelationOptions] = useState([]);
  const [pepPositions, setPepPositionsOptions] = useState([]);
  const [pepVinculationOptions, setPepVinculationOptions] = useState([]);
  const [pepCurrentPositionOptions, setPepCurrentPositionOptions] = useState(
    []
  );
  const [nombreRelacionPepSeleccionado, setNombreRelacionPepSeleccionado] =
    useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const esPep = watch('esPep');
  const estaVinculadoAPep = watch('estaVinculadoAPep');
  const ocupaCargoActualmente = watch('ocupaCargoActualmente');
  const cargoPep = watch('cargoPep');
  const relacionPep = watch('relacionPep');
  const personaOcupaCargo = watch('personaOcupaCargo');
  const institucion = watch('institucion');
  const fechaInicioCargo = watch('fechaInicioCargo');
  const ultimaFechaCargo = watch('ultimaFechaCargo');
  const ocupaCargoActualmenteVinculadpPep = watch(
    'ocupaCargoActualmenteVinculadpPep'
  );

  useEffect(() => {
    if (relacionPep) {
      const itemSearched = pepVinculationOptions.find(
        (x) => x?.id === Number(relacionPep)
      );
      setNombreRelacionPepSeleccionado(itemSearched?.name);
    }
  }, [relacionPep]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveDataInBD = () => {};

  return (
    <>
      {showSpinner ? <Spinner /> : null}

      <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
        <div className="row fields-container">
          <div className="col-md-5 col-sm-12">
            <h3 className="formTitle">
              Persona Políticamente Expuesta (PEP){' '}
              <ModalInvestorProfile
                title="Persona Políticamente Expuesta (PEP)"
                message="Cualquier individuo que desempeña o ha desempeñado, durante los últimos tres (3) años altas funciones públicas, por elección o nombramientos ejecutivos, en un país extranjero o en territorio nacional, incluyendo altos funcionarios de organizaciones internacionales. Incluye, pero no se limita a, jefes de estado o de gobierno, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales o funcionarios, así como aquellos que determine el Comité Nacional de Lavado de Activos previa consulta con el Ministerio de la Administración Pública. Los cargos considerados PEP serán todos aquellos funcionarios obligados a presentar declaración jurada de bienes. Se asimilan todas aquellas personas que hayan desempeñado o desempeñen estas funciones o su equivalente para gobiernos extranjeros."
              />
            </h3>

            <div className="field-form">
              <label htmlFor="es-pep" className="label-field">
                ¿Es usted una persona expuesta políticamente? <span>*</span>
              </label>
              <select
                id="es-pep"
                defaultValue={'DEFAULT'}
                {...register('esPep', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
              {errors.esPep && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            {esPep == 'Si' ? (
              <>
                <div className="field-form">
                  <label htmlFor="cargo-pep" className="label-field">
                    Cargo <span>*</span>
                  </label>
                  <select
                    id="cargo-pep"
                    defaultValue={'DEFAULT'}
                    {...register('cargoPep')}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {pepPositions.map((pepPosition) => (
                      <option key={pepPosition.id} value={pepPosition.id}>
                        {pepPosition.name}
                      </option>
                    ))}
                  </select>
                  {errors.cargoPep && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                {/* <div className='field-form'>
                            <label htmlFor="ultima-fecha-cargo" className='label-field'>Última fecha en el cargo <span>*</span></label>
                            <input type="date" className='input-form' placeholder="" id="ultima-fecha-cargo" {...register('ultimaFechaCargo', {
                              required: true
                            })} />
                            {errors.ultimaFechaCargo?.type === 'required' && <p className='text-danger'>El campo Última fecha en el cargo es requerido</p>}
                        </div> */}
              </>
            ) : null}

            <div className="field-form">
              <label htmlFor="esta-vinculado-a-pep" className="label-field">
                ¿Esta vinculado a una persona expuesta políticamente?{' '}
                <span>*</span>
              </label>
              <select
                id="esta-vinculado-a-pep"
                defaultValue={'DEFAULT'}
                {...register('estaVinculadoAPep', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
              {errors.estaVinculadoAPep && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            {estaVinculadoAPep === 'Si' ? (
              <>
                <div className="field-form">
                  <label htmlFor="relacion-pep" className="label-field">
                    Relación con el PEP
                  </label>
                  <select
                    id="relacion-pep"
                    defaultValue={'DEFAULT'}
                    {...register('relacionPep')}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {pepVinculationOptions.map((pepVinculationOption) => (
                      <option
                        key={pepVinculationOption.id}
                        value={pepVinculationOption.id}
                      >
                        {pepVinculationOption.name}
                      </option>
                    ))}
                  </select>
                  {errors.relacionPep && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>
              </>
            ) : null}

            {nombreRelacionPepSeleccionado &&
            nombreRelacionPepSeleccionado !== 'Quien suscribe' ? (
              <>
                <div className="field-form">
                  <label htmlFor="persona-ocupa-cargo" className="label-field">
                    Persona que ocupa el cargo
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="persona-ocupa-cargo"
                    {...register('personaOcupaCargo', {
                      required: true,
                      maxLength: 30,
                    })}
                  />
                  {errors.personaOcupaCargo?.type === 'required' && (
                    <p className="text-danger">Este campo es requerido</p>
                  )}
                  {errors.personaOcupaCargo?.type === 'maxLength' && (
                    <p className="text-danger">
                      Solo se permiten ingresar 30 caracteres como máximo
                    </p>
                  )}
                </div>

                <div className="field-form">
                  <label
                    htmlFor="institucionVinculadoPep"
                    className="label-field"
                  >
                    Institución <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="institucionVinculadoPep"
                    {...register('institucionVinculadoPep', {
                      required: true,
                      maxLength: 30,
                    })}
                  />
                  {errors.institucionVinculadoPep?.type === 'required' && (
                    <p className="text-danger">
                      El campo Institución es requerido
                    </p>
                  )}
                  {errors.institucionVinculadoPep?.type === 'maxLength' && (
                    <p className="text-danger">
                      Solo se permiten ingresar 30 caracteres como máximo
                    </p>
                  )}
                </div>

                <div className="field-form">
                  <label
                    htmlFor="ocupaCargoActualmenteVinculadpPep"
                    className="label-field"
                  >
                    ¿Ocupa el cargo actualmente? <span>*</span>
                  </label>
                  <select
                    id="ocupaCargoActualmenteVinculadpPep"
                    defaultValue={'DEFAULT'}
                    {...register('ocupaCargoActualmenteVinculadpPep', {
                      required: true,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {errors.ocupaCargoActualmenteVinculadpPep && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                {ocupaCargoActualmenteVinculadpPep === 'Si' ? (
                  <div className="field-form">
                    <label
                      htmlFor="fechaInicioCargoVinculadpPep"
                      className="label-field"
                    >
                      Fecha de inicio en el cargo <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="input-form"
                      placeholder=""
                      id="fechaInicioCargoVinculadpPep"
                      {...register('fechaInicioCargoVinculadpPep', {
                        required: true,
                      })}
                    />
                    {errors.fechaInicioCargoVinculadpPep?.type ===
                      'required' && (
                      <p className="text-danger">
                        El campo Fecha de inicio en el cargo es requerido
                      </p>
                    )}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>

          <div className="col-md-5 col-sm-12">
            <h3 className="formTitle" style={{ visibility: 'hidden' }}>
              Non visible
            </h3>
            {esPep == 'Si' ? (
              <>
                <div className="field-form">
                  <label htmlFor="institucion" className="label-field">
                    Institución <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="institucion"
                    {...register('institucion', {
                      required: true,
                      maxLength: 30,
                    })}
                  />
                  {errors.institucion?.type === 'required' && (
                    <p className="text-danger">
                      El campo Institución es requerido
                    </p>
                  )}
                  {errors.institucion?.type === 'maxLength' && (
                    <p className="text-danger">
                      Solo se permiten ingresar 30 caracteres como máximo
                    </p>
                  )}
                </div>

                <div className="field-form">
                  <label
                    htmlFor="ocupa-cargo-actualmente"
                    className="label-field"
                  >
                    ¿Ocupa el cargo actualmente? <span>*</span>
                  </label>
                  <select
                    id="ocupa-cargo-actualmente"
                    defaultValue={'DEFAULT'}
                    {...register('ocupaCargoActualmente', {
                      required: true,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                  {errors.ocupaCargoActualmente && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                {ocupaCargoActualmente === 'Si' ? (
                  <div className="field-form">
                    <label htmlFor="fecha-inicio-cargo" className="label-field">
                      Fecha de inicio en el cargo <span>*</span>
                    </label>
                    <input
                      type="date"
                      className="input-form"
                      placeholder=""
                      id="fecha-inicio-cargo"
                      {...register('fechaInicioCargo', {
                        required: true,
                      })}
                    />
                    {errors.fechaInicioCargo?.type === 'required' && (
                      <p className="text-danger">
                        El campo Fecha de inicio en el cargo es requerido
                      </p>
                    )}
                  </div>
                ) : null}

                {/* <div className='field-form'>
                            <label htmlFor="ultima-fecha-cargo" className='label-field'>Última fecha en el cargo <span>*</span></label>
                            <input type="date" className='input-form' placeholder="" id="ultima-fecha-cargo" {...register('ultimaFechaCargo', {
                              required: true
                            })} />
                            {errors.ultimaFechaCargo?.type === 'required' && <p className='text-danger'>El campo Última fecha en el cargo es requerido</p>}
                        </div> */}
              </>
            ) : null}
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="button"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            disabled={disabledButton}
            className={disabledButton ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default PepForm;
