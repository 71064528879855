import './CommercialReferencesForm.css';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_COMMERCIAL_REFERENCES_SECTION,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import {
  REMOVE_COMMERCIAL_REFERENCE,
  SAVE_COMMERCIAL_REFERENCES_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { setSelectedSection } from '../../../../../shared/store/slices/clientePotencial';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const CommercialReferencesForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [referenceCards, setReferenceCards] = useState([]);
  const [referenceCardId, setReferenceCardId] = useState('');
  const [formResponseId, setFormResponseId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState([]);
  const {
    isContrapart,
    hasBCRDAccount,
    isListedOnStock,
    isFormCompleted,
    isFormDisqualified,
    validation,
  } = useSelector((state) => state.clientePotencialSlice);

  const [saveCommercialReferencesSection] = useMutation(
    SAVE_COMMERCIAL_REFERENCES_SECTION,
    {
      refetchQueries: [
        {
          query: GET_COMMERCIAL_REFERENCES_SECTION,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [removeCommercialReference] = useMutation(REMOVE_COMMERCIAL_REFERENCE, {
    refetchQueries: [
      {
        query: GET_COMMERCIAL_REFERENCES_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [getCommercialReferencesSectionData, commercialReferencesSectionData] =
    useLazyQuery(GET_COMMERCIAL_REFERENCES_SECTION);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [loadingSection, setLoadingSection] = useState(true);

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);

    getCommercialReferencesSectionData({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (
      commercialReferencesSectionData?.data?.commercialReferencesSection
        ?.commercialReferences.length
    ) {
      setReferenceCards(
        commercialReferencesSectionData?.data?.commercialReferencesSection
          ?.commercialReferences
      );
    }

    if (commercialReferencesSectionData?.data) {
      setLoadingSection(false);
    }
  }, [commercialReferencesSectionData]);

  const nombreReferencia = watch('nombreReferencia');
  const correoReferencia = watch('correoReferencia');
  const telefonoReferencia = watch('telefonoReferencia');

  const handleBackNavigation = () => {
    if (hasBCRDAccount) {
      dispatch(setSelectedSection('Información De Mi Empresa'));
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
      );
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/cuentas-bancarias'
      );
    }
  };

  const navigateToNextPage = () => {
    if (isContrapart) {
      if (!isListedOnStock) {
        navigate(
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
        );
      } else {
        navigate(
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/miembros-del-consejo'
        );
      }
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
      );
    }
  };

  const addSignatureCard = () => {
    let data;

    if (
      !commercialReferencesSectionData?.data?.commercialReferencesSection?.id
    ) {
      if (referenceCardId) {
        data = {
          formResponseId: formResponseId,
          commercialReferenceInputTypes: {
            id: referenceCardId,
            email: correoReferencia,
            name: nombreReferencia,
            phoneNumber: telefonoReferencia,
          },
        };
      } else {
        data = {
          formResponseId: formResponseId,
          commercialReferenceInputTypes: {
            email: correoReferencia,
            name: nombreReferencia,
            phoneNumber: telefonoReferencia,
          },
        };
      }
    } else {
      if (referenceCardId) {
        data = {
          id: commercialReferencesSectionData?.data?.commercialReferencesSection
            ?.id,
          formResponseId: formResponseId,
          commercialReferenceInputTypes: {
            id: referenceCardId,
            email: correoReferencia,
            name: nombreReferencia,
            phoneNumber: telefonoReferencia,
          },
        };
      } else {
        data = {
          id: commercialReferencesSectionData?.data?.commercialReferencesSection
            ?.id,
          formResponseId: formResponseId,
          commercialReferenceInputTypes: {
            email: correoReferencia,
            name: nombreReferencia,
            phoneNumber: telefonoReferencia,
          },
        };
      }
    }

    saveCommercialReferencesSection({
      variables: {
        commercialReferencesSection: data,
      },
    });

    btnCloseModalRef.current.click();
    reset();
  };

  const editElement = (id) => {
    setReferenceCardId(id);
    const selectedCommercialReference =
      commercialReferencesSectionData?.data?.commercialReferencesSection?.commercialReferences?.find(
        (x) => x.id === id
      );

    if (id && selectedCommercialReference) {
      setValue('nombreReferencia', selectedCommercialReference?.name);
      setValue('correoReferencia', selectedCommercialReference?.email);
      setValue('telefonoReferencia', selectedCommercialReference?.phoneNumber);
    }
  };

  const deleteCardElement = (id) => {
    removeCommercialReference({ variables: { commercialReferenceId: id } });
  };

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(referenceCardId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'commercialReferencesSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Referencias Comerciales
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Referencias Comerciales'}
              description={
                'Complete este formulario con las referencias comerciales de su empresa. Proporcione información detallada sobre las empresas con las que ha trabajado anteriormente o actualmente. Estas referencias son importantes para evaluar su historial comercial y establecer relaciones sólidas con nuestros clientes y socios comerciales.'
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'commercialReferences'
                      ? 'Es necesario que agregues por lo menos una referencia comercial.'
                      : `Hay una o varias referencias comerciales con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {referenceCards.map((referenceCard, index) => {
                return (
                  <div
                    key={index}
                    data-bs-toggle={isUpdating ? 'modal' : ''}
                    id={referenceCard?.id}
                    data-bs-target={isUpdating ? '#exampleModal' : ''}
                  >
                    <CardItems
                      key={index}
                      nameElement={referenceCard.name}
                      identityDocument={referenceCard.email}
                      idElement={referenceCard.id}
                      deleteElement={deleteCardElement}
                      handleEdit={editElement}
                      setIsUpdating={setIsUpdating}
                      showDeleteButton={!isFormCompleted && !isFormDisqualified}
                    />
                  </div>
                );
              })}
              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    reset();
                    setReferenceCardId('');
                    setIsUpdating(false);
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">
                    Agregar Referencia Comercial
                  </h4>
                </div>
              ) : null}
            </div>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addSignatureCard)}
                  className="fields-modal-container frm-main-share-holders"
                >
                  <div className="fields-modal-header mb-3">
                    <h4 className="modal-title" id="exampleModalLabel">
                      {!isFormCompleted && !isFormDisqualified
                        ? referenceCardId
                          ? 'Editar'
                          : 'Agregar'
                        : 'Ver'}{' '}
                      Referencia
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      onClick={() => {
                        reset();
                        setReferenceCardId('');
                        setIsUpdating(false);
                      }}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="row fields-modal-content">
                    <div className="col-12">
                      <div className="field-form">
                        <label
                          htmlFor="nombre-referencia"
                          className="label-field"
                        >
                          Nombre Referencia<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="nombre-referencia"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('nombreReferencia', {
                            required: true,
                          })}
                        />
                        {errors.nombreReferencia?.type === 'required' && (
                          <p className="text-danger">
                            El campo Nombre Referencia es requerido
                          </p>
                        )}
                        {referenceCardId &&
                          !nombreReferencia &&
                          errors.nombreReferencia?.type !== 'required' && (
                            <ErrorText hasError={hasError('name')} />
                          )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="correo-referencia"
                          className="label-field"
                        >
                          Correo Referencia<span>*</span>
                        </label>
                        <input
                          type="email"
                          className="input-form"
                          placeholder=""
                          id="correo-referencia"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('correoReferencia', {
                            required: true,
                          })}
                        />
                        {errors.correoReferencia?.type === 'required' && (
                          <p className="text-danger">
                            El campo Correo Referencia es requerido
                          </p>
                        )}
                        {referenceCardId &&
                          !correoReferencia &&
                          errors.correoReferencia?.type !== 'required' && (
                            <ErrorText hasError={hasError('email')} />
                          )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="telefono-referencia"
                          className="label-field"
                        >
                          Teléfono Referencia <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="input-form"
                          placeholder=""
                          id="telefono-referencia"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('telefonoReferencia', {
                            required: true,
                          })}
                        />
                        {errors.telefonoReferencia?.type === 'required' && (
                          <p className="text-danger">
                            El campo Teléfono Referencia es requerido
                          </p>
                        )}
                        {referenceCardId &&
                          !telefonoReferencia &&
                          errors.telefonoReferencia?.type !== 'required' && (
                            <ErrorText hasError={hasError('phoneNumber')} />
                          )}
                      </div>
                    </div>
                  </div>

                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="row d-flex justify-content-center">
                      <button type="submit" className="mt-2 mb-4 btn-add">
                        Guardar
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            className="btn-continue"
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default CommercialReferencesForm;
