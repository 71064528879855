import { useEffect } from 'react';
import './SliderProgress.css';
import { useState } from 'react';
import { useRef } from 'react';

const SliderProgress = ({
  value,
  setProgress,
  remainingPercentage,
  valueProgress,
  changeAssignment,
}) => {
  const sliderRef = useRef();
  const sliderProgressBarRef = useRef();
  const sliderProgressBarRestRef = useRef();
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    setCurrentValue(value);
    sliderRef.current.value = value;
  }, [value]);

  useEffect(() => {
    if (changeAssignment === true) {
      sliderRef.current.value = valueProgress;
      setCurrentValue(valueProgress);
      setProgress(valueProgress);
    }
  }, [valueProgress]);

  useEffect(() => {
    sliderRef.current.value = 0;
    sliderProgressBarRestRef.current.style.width = remainingPercentage + '%';
  }, []);

  useEffect(() => {
    sliderProgressBarRestRef.current.style.width =
      parseInt(currentValue) + remainingPercentage + '%';
  }, [remainingPercentage]);

  useEffect(() => {
    sliderProgressBarRef.current.style.width = currentValue + '%';
  }, [currentValue]);

  const handleRangeChange = () => {
    if (changeAssignment === true) {
      sliderRef.current.value = valueProgress;
      setCurrentValue(valueProgress);
      setProgress(Number(sliderRef.current.value));
    } else {
      setCurrentValue(Number(sliderRef.current.value));
      setProgress(Number(sliderRef.current.value));
    }
  };

  return (
    <div className="slider-container">
      <input
        type="range"
        ref={sliderRef}
        onMouseMove={handleRangeChange}
        onMouseUp={() => {
          if (changeAssignment === true) {
            sliderRef.current.value = valueProgress;
            setCurrentValue(valueProgress);
            setProgress(valueProgress);
          }
        }}
        id="dal-slider"
      />

      <div className="slider-progress-bar-bg"></div>

      <div className="slider-progress-bar" ref={sliderProgressBarRef}></div>

      <div
        className="slider-progress-rest"
        ref={sliderProgressBarRestRef}
      ></div>
    </div>
  );
};

export default SliderProgress;
