import { useEffect, useState } from 'react';
import {
  getAuthorizationConfigCookie,
  formatName,
  setCookie,
  getCookie,
  handleClienteRealLogOut,
  notAuthorizedTokenClienteReal,
  gatewayError,
  envars,
} from '../../../shared/utils/functions';
import jwt_encode from 'jwt-encode';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import './dashboard.css';
import { Container, Col, Row, Nav } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { CLIENTEREALROUTES } from '../../../shared/constants/app-constants';
import {
  COOKIENAMES,
  CUSTOMERSERVICECONTACT,
  SOCIALNETWORKS,
} from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';
import { CLIENTEREALORIGINROUTE } from '../Index/Index';
import { useDispatch } from 'react-redux';
import {
  setCurrentAccount,
  setProfile,
} from '../../../shared/store/slices/clienteReal';
import { useSelector } from 'react-redux';
import LoadDescription from '../../../shared/UIElements/LoadDescription/LoadDescription';
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  ExpandMore,
  ExpandLess,
  HomeOutlined,
  LibraryBooksOutlined,
  TimelineOutlined,
} from '@mui/icons-material';
import {
  BookOutlined,
  DateRangeOutlined,
  ExitToAppOutlined,
  MailOutline,
  PersonOutlined,
} from '@material-ui/icons';

const CHILDRENROUTES = CLIENTEREALROUTES.protectedRoutes.dashboard.children;
const DASHBOARDROUTE = CLIENTEREALROUTES.protectedRoutes.dashboard.self;

export default function ClienteRealDashboard() {
  const [showSpinner, setShowSpinner] = useState(true);
  const [showAccountSelector, setShowAccountSelector] = useState(false);
  const [loadDescription, setLoadDescription] = useState(
    'Obteniendo información del dashboard...'
  );
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    session: { showSessionModal },
  } = useSelector((state) => state.clienteRealSlice);

  useEffect(() => {
    const config = getAuthorizationConfigCookie();
    const profile = getCookie(COOKIENAMES.profile, false);
    setLoadDescription('Obteniendo información del dashboard...');

    if (profile) {
      const profileDecoded = jwt_decode(profile);
      dispatch(setProfile(profileDecoded));
      configOptions(profileDecoded);
      setShowSpinner(false);
    } else {
      getProfile(config);
    }
  }, []);

  const getProfile = async (config) => {
    try {
      const res = await axios.get('/middleware/api/auth/profile', config);
      if (res.data instanceof Object) {
        const profileCoded = jwt_encode(res.data, envars('SECRETINNERJWT'));
        setCookie(COOKIENAMES.profile, profileCoded, {
          'max-age': 3600,
          path: '/',
        });
        configOptions(res.data);
        dispatch(setProfile(res.data));
        setShowSpinner(false);
      } else {
        toast('Hubo un error al obtener el dashboard', {
          type: 'error',
          theme: 'colored',
        });
        handleClienteRealLogOut();
      }
    } catch (error) {
      console.log(error);
      notAuthorizedTokenClienteReal(error, dispatch, showSessionModal);
      gatewayError(getProfile, error);
    }
  };

  const configOptions = (profile) => {
    let cleanedShared = [];
    if (profile && profile.sharedProfiles && profile.sharedProfiles.length) {
      cleanedShared = profile.sharedProfiles.reduce((acc, item) => {
        if (!acc.find((i) => i.idClient === item.idClient)) {
          acc.push(item);
        }
        return acc;
      }, []);
    }
    const principalProfile = {
      idClient: profile.idClient,
      lastName: profile.lastName,
      name: profile.name,
      rif: profile.rif,
      titular: true,
    };
    setOptions(cleanedShared);
    setSelectedOption(principalProfile);
    dispatch(setCurrentAccount(principalProfile));
  };

  const selectOption = (idOption) => {
    const preOption = selectedOption;
    const option = options.find((o) => o.idClient === idOption);
    dispatch(setCurrentAccount(option));
    setOptions([
      ...options.filter((op) => idOption !== op.idClient),
      preOption,
    ]);
    setSelectedOption(option);
  };

  const isRoute = (route) => {
    const base = CLIENTEREALORIGINROUTE + DASHBOARDROUTE + '/';
    return location.pathname.includes(base + route) ? 'active' : '';
  };

  return (
    <div className="client-container">
      {showSpinner ? (
        <LoadDescription description={loadDescription} />
      ) : (
        <>
          <Container className="mainContainer" fluid>
            <Row>
              <Col xs lg="2" className="p-0">
                <div className="client-sidebar skinny-scroll">
                  <img
                    className="sidebar-logoParval"
                    alt="logo"
                    src="/logo.png"
                  />
                  <div className="sidebar-social-media">
                    <Nav className="justify-content-center">
                      <Nav.Item>
                        <Nav.Link
                          className="px-2 media"
                          href={SOCIALNETWORKS.facebook}
                        >
                          <Facebook fontSize="small" />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="px-2 media"
                          href={SOCIALNETWORKS.instagram}
                        >
                          <Instagram fontSize="small" />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="px-2 media"
                          href={SOCIALNETWORKS.linkedin}
                        >
                          <LinkedIn fontSize="small" />
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className="px-2 media"
                          href={SOCIALNETWORKS.twitter}
                        >
                          <Twitter fontSize="small" />
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Container className="sidebar-account-container">
                    <Row>
                      <Col>
                        <div className="sidebar-account-selector">
                          <div
                            className="selector"
                            onClick={() =>
                              setShowAccountSelector(!showAccountSelector)
                            }
                          >
                            <div className="selector-info">
                              <p
                                className="p-0 m-0"
                                style={{ fontSize: 16, color: 'gray' }}
                              >
                                {formatName(
                                  selectedOption.name +
                                    ' ' +
                                    selectedOption.lastName
                                )}
                              </p>
                              <div className="account-selected">
                                <strong>
                                  {selectedOption.titular
                                    ? 'Titular'
                                    : 'Cotitular'}
                                </strong>
                                <div className="separator-point"></div>
                                <strong>{selectedOption.idClient}</strong>
                              </div>
                            </div>
                            {showAccountSelector ? (
                              <ExpandLess style={{ color: 'gray' }} />
                            ) : (
                              <ExpandMore style={{ color: 'gray' }} />
                            )}
                          </div>
                          {showAccountSelector && options.length ? (
                            <div className="selector-options skinny-scroll">
                              {options.map((op) => (
                                <div
                                  className="selector-info"
                                  key={op.idClient}
                                  onClick={() => {
                                    setShowAccountSelector(false);
                                    selectOption(op.idClient);
                                  }}
                                >
                                  <p
                                    className="p-0 m-0"
                                    style={{ fontSize: 16, color: 'gray' }}
                                  >
                                    {formatName(op.name + ' ' + op.lastName)}
                                  </p>
                                  <div className="account-selected">
                                    <strong>
                                      {op.titular ? 'Titular' : 'Cotitular'}
                                    </strong>
                                    <div className="separator-point"></div>
                                    <strong>{op.idClient}</strong>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <ul className="client-dashboard-menu">
                    <li>
                      <Link
                        to={CHILDRENROUTES.index}
                        className={isRoute(CHILDRENROUTES.index)}
                      >
                        <HomeOutlined /> <span>Inicio</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={CHILDRENROUTES.historyOp} className={isRoute()}>
                        <LibraryBooksOutlined />{' '}
                        <span>Histórico de Operaciones</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={CHILDRENROUTES.rateProducts}
                        className={isRoute(CHILDRENROUTES.rateProducts)}
                      >
                        <TimelineOutlined /> <span>Tasas y Productos</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={CHILDRENROUTES.makeAppointment}
                        className={isRoute()}
                      >
                        <DateRangeOutlined /> <span>Agenda tu cita</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={CHILDRENROUTES.requestCerts}
                        className={isRoute()}
                      >
                        <MailOutline />{' '}
                        <span>Solicitar Carta y Certificaciones</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={CHILDRENROUTES.guides} className={isRoute()}>
                        <BookOutlined /> <span>Guías e Instructivos</span>
                      </Link>
                    </li>
                  </ul>
                  <div className="sidebar-other-info mt-5">
                    <strong>Contacte Servicio al Cliente</strong>
                    <p className="p-0 m-0 mt-3">
                      {CUSTOMERSERVICECONTACT.telephone}
                    </p>
                    <p
                      className="p-0 m-0 mb-4"
                      style={{ color: '#ffa751' }}
                      onClick={() =>
                        window.open(`mailTo:${CUSTOMERSERVICECONTACT.email}`)
                      }
                    >
                      {CUSTOMERSERVICECONTACT.email}
                    </p>
                    <div className="sidebar-btn mb-4">
                      <Link
                        to="personal-info"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <PersonOutlined style={{ marginRight: 9 }} />
                        <p className="p-0 m-0">Información personal</p>
                      </Link>
                    </div>
                    <div
                      className="sidebar-btn"
                      onClick={() => {
                        handleClienteRealLogOut();
                      }}
                    >
                      <ExitToAppOutlined style={{ marginRight: 9 }} />{' '}
                      <p className="p-0 m-0">Cerrar Sesión</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs lg="10" className="p-0">
                <Container fluid className="p-0">
                  <div className="outlet-content skinny-scroll middle-size-scroll">
                    <Outlet />
                  </div>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
}
