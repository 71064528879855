import React, { useEffect, useState } from 'react';
import { validateNonSelectedOptionInDropdown } from '../../../shared/utils/validators';
import { useForm } from 'react-hook-form';
import './RegistrationDataRequest.css';

const RegistrationDataRequest = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm();
  const [branches, setBranches] = useState([]);
  const knowAnyExecutive = watch('knowAnyExecutive');
  const [agents, setAgents] = useState([]);
  const [leadSourceCodes, setLeadSourceCodes] = useState([]);

  const branch = watch('branch');
  const agent = watch('agent');
  const leadSourceCode = watch('leadSourceCode');

  const completeRegister = () => {};

  return (
    <div className="rdr-form-container">
      <div className="rdr-logo-login-container">
        <img className="logoParval" alt="logo" src="/logo.png" />
      </div>
      <h3 className="rdr-title">
        Complete la siguiente información para terminar su registro
      </h3>
      <form className="rdr-form" onSubmit={handleSubmit(completeRegister)}>
        <select
          {...register('branch', {
            validate: validateNonSelectedOptionInDropdown,
          })}
        >
          <option value="">Selecciona la sucursal de su preferencia</option>
          {Array.isArray(branches) &&
            branches.map((branch) => (
              <option key={branch.branchId} value={branch.branchId}>
                {branch.name}
              </option>
            ))}
        </select>
        {errors.branch && (
          <p className="text-danger">Debe seleccionar una opción</p>
        )}

        <select
          {...register('knowAnyExecutive', {
            validate: validateNonSelectedOptionInDropdown,
          })}
        >
          <option value="">¿Conoces algún ejecutivo de Parval?</option>
          <option value="Si">Si</option>
          <option value="No">No</option>
        </select>
        {errors.knowAnyExecutive && (
          <p className="text-danger">Debe seleccionar una opción</p>
        )}

        {knowAnyExecutive === 'Si' && branch !== '' ? (
          <>
            <select
              {...register('agent', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="">Selecciona quién quieres que te asista</option>
              {agents.map((agent) => (
                <option key={agent.agentId} value={agent.agentId}>
                  {agent.name}
                </option>
              ))}
            </select>
            {errors.agent && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </>
        ) : null}

        <select
          {...register('leadSourceCode', {
            validate: validateNonSelectedOptionInDropdown,
          })}
        >
          <option value="">¿Cómo se entero de Parval?</option>
          {leadSourceCodes.map((leadSourceCode) => (
            <option key={leadSourceCode.id} value={leadSourceCode.id}>
              {leadSourceCode.name}
            </option>
          ))}
        </select>
        {errors.leadSourceCode && (
          <p className="text-danger">Debe seleccionar una opción</p>
        )}

        <button
          type="submit"
          style={{ marginBottom: 0, width: '100%' }}
          className="btnSubmit"
        >
          Completar registro
        </button>
      </form>
    </div>
  );
};

export default RegistrationDataRequest;
