import './WaitingSiteAccountSummary.css';

const WaitingSiteAccountSummary = () => {
  return (
    <div className="waiting-site-account-summary">
      <div>
        <p style={{ fontWeight: '600', fontSize: '3rem' }}>
          Estamos creando tu perfil
        </p>
        {/* <img src="/images/verifyingProfile.JPG" alt="Estamos creando tu perfil" />  */}
      </div>
    </div>
  );
};

export default WaitingSiteAccountSummary;
