import './ResultForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  faUser,
  faCreditCard,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';

const ResultForm = ({ validateForm, setValidateForm, goToBack }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [fatcaProxyOptions, setFatcaProxyOptions] = useState([]);
  const [perfil, setPerfil] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [accept, setAccept] = useState([]);
  const [isloading, setIsloading] = useState(false);

  const acepto = watch('acepto');

  useEffect(() => {
    //alert(accept);
  }, [accept]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-fisica/origen-de-los-fondos');
    }
  }, [goToBack]);

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveDataInBD = () => {
    setIsloading(true);
  };

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      {isloading ? <Spinner /> : null}

      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <div className="result-text-container">
            <p>
              <FontAwesomeIcon icon={faUser} className="icon-text" />
            </p>
            <div style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '1.3rem' }}>
                Tu perfil de inversionista es:
              </p>
              <h2 className="mb-3">{perfil}</h2>
              <p style={{ textAlign: 'justify' }}>{descripcion}</p>
            </div>
            <input
              type="hidden"
              className="input-form"
              placeholder=""
              id="acepto"
              {...register('acepto', {
                required: true,
              })}
            />
          </div>

          <div className="result-btn-container">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setAccept(false);
                setValue('acepto', 'falso');
              }}
            >
              No acepto
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                setAccept(true);
                setValue('acepto', 'verdadero');
              }}
            >
              Acepto
            </button>
          </div>

          <div className="result-error-container">
            {errors.acepto?.type === 'required' && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>
        </div>
      </div>
      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default ResultForm;
