import './InvestorProfilePage.css';
import React, { useState } from 'react';
import ProgressBar from '../../../../../components/ClientePotencial/ProgressBar/ProgressBar';
import InvestorProfileForm from './../../../../../components/ClientePotencial/forms/Legal person/InvestorProfileForm/InvestorProfileForm';

const InvestorProfilePage = () => {
  const [validateForm, setValidateForm] = useState(false);
  const [goToBack, setGoToBack] = useState(false);

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  const handleFormValidation = () => {
    setValidateForm(true);
  };

  const handleBackNavigation = () => {
    setGoToBack(true);
  };

  return (
    <div className="page-container">
      <div className="logo-parval-container">
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="formTitle">Perfil del Inversionista</h3>
        <InvestorProfileForm
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          goToBack={goToBack}
        />
      </div>
      <ProgressBar
        handleClick={handleFormValidation}
        handleBackNavigation={handleBackNavigation}
        width={'68.75%'}
        percentage={'68.75%'}
      />
    </div>
  );
};

export default InvestorProfilePage;
