import { useEffect, useState } from 'react';
import './companyType.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProgressBar } from '../../../shared/store/slices/clientePotencial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import {
  getDecodedCookie,
  modifyCookie,
} from '../../../shared/utils/functions';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { GET_COMPANY_TYPES } from '../../../shared/graphQL/queries';
import {
  AZURE_SESSION_TIME_OUT,
  azureUserFlowUri,
} from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';

export default function CompanyType() {
  const [companyType, setCompanyType] = useState(null);
  const { loading, error, data } = useQuery(GET_COMPANY_TYPES);
  const [animationOut, setAnimationOut] = useState(false);
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectable, setSelectable] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgressBar({ show: true, value: '80%' }));
    const configDecoded = getDecodedCookie('potencialCustomerConfig');

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }

    if (!configDecoded?.assistUnit?.name?.toLowerCase().includes('negocios')) {
      setSelectable(true);
    }

    if (configDecoded && configDecoded.companyType) {
      setReload(true);
      setCompanyType(configDecoded.companyType);
      setValue({
        label: configDecoded.companyType.name,
        id: configDecoded.companyType.id,
      });
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener los tipos de empresa', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (companyType && !reload && !selectable) {
      goTo();
    }
  }, [companyType, reload]);

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    modifyCookie(
      'potencialCustomerConfig',
      'companyType',
      companyType,
      true,
      timeout
    );
    setTimeout(() => {
      navigate(`/onboarding-digital/select-business-representative`);
    }, 500);
    setAnimationOut(true);
  };

  const goBack = () => {
    const configDecoded = getDecodedCookie('potencialCustomerConfig');
    if (configDecoded?.assistUnit?.name?.toLowerCase().includes('negocios')) {
      navigate('/onboarding-digital/select-branch');
    } else {
      navigate('/onboarding-digital/sub-type');
    }
  };

  const filter = (types) => {
    const companies = types.filter(
      (type) => type.name === 'Privada' || type.name === 'Pública'
    );

    return companies;
  };

  useEffect(() => {
    if (value) {
      setCompanyType(data?.companyTypes.find((type) => type.id === value.id));
    } else {
      setCompanyType(null);
    }
  }, [value]);

  return (
    <div className="containerOnboarding">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="d-none d-md-block">
            <div className="banner">
              <img
                className="bg"
                src="/images/onboarding/bannerOnboarding_1.jpg"
                alt=""
              />
            </div>
          </Col>
          <Col className="contForm">
            <p className="linkBack" onClick={goBack}>
              <FontAwesomeIcon icon={faAngleLeft} /> Volver
            </p>
            <div className="vAlign animate__animated animate__fadeInUp">
              <strong className="title">Selecciona tu tipo de empresa</strong>
              <p className="subTitle">
                De acuerdo a su aporte al capital, su empresa es considerada:
              </p>
              <div className="cliente-potencial-register-form">
                <div className="mt-2 newForm">
                  {!loading &&
                    !error &&
                    data?.companyTypes &&
                    (!selectable ? (
                      <div
                        className="assist-unit cards-container"
                        style={{ marginBottom: 75 }}
                      >
                        {filter(data?.companyTypes).map((p) => (
                          <div
                            key={p.id}
                            className={`boxButton ${companyType && companyType.id === p.id ? 'selected' : ''}`}
                            onClick={() => {
                              setReload(false);
                              setCompanyType(p);
                            }}
                          >
                            <div>
                              <h5 className="rwf-card-title">{p.name}</h5>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <>
                        <div className="filter-select-company">
                          <Autocomplete
                            disablePortal
                            id="controllable-states-demo"
                            value={value}
                            placeholder="Busca y selecciona"
                            onChange={(event, newValue) => {
                              setValue(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            options={data?.companyTypes?.map((type) => ({
                              label: type.name,
                              id: type.id,
                            }))}
                            renderInput={(params) => (
                              <TextField {...params} label="Tipo de empresa" />
                            )}
                          />
                        </div>
                        <button
                          type="button"
                          disabled={!companyType}
                          className="company-continue linkYellow mt-4 d-flex align-items-center justify-content-center"
                          style={{
                            border: 'none',
                            background: 'none',
                            width: '100%',
                          }}
                          onClick={() => goTo()}
                        >
                          Continuar{' '}
                          <FontAwesomeIcon
                            className="ms-2"
                            style={{ marginTop: 3 }}
                            icon={faAngleRight}
                          />
                        </button>
                      </>
                    ))}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
