import React, { createContext, useState, useContext } from 'react';

const SessionContext = createContext();
const ChangeSessionContext = createContext();
const ExtendSessionContext = createContext();
const ChangeExtendSessionContext = createContext();
const SessionEpiredContext = createContext();
const ChangeSessionExpiredContext = createContext();

export function useSessionContext() {
  return useContext(SessionContext);
}

export function useChangeSessionContext() {
  return useContext(ChangeSessionContext);
}

export function useExtendSessionContext() {
  return useContext(ExtendSessionContext);
}

export function useChangeExtendSessionContext() {
  return useContext(ChangeExtendSessionContext);
}

export function useSessionExpiredContext() {
  return useContext(SessionEpiredContext);
}

export function useChangeSessionExpiredContext() {
  return useContext(ChangeSessionExpiredContext);
}

const SessionContextProvider = ({ children }) => {
  const [
    initializeSessionExpirationCount,
    setInitializeSessionExpirationCount,
  ] = useState(false);
  const [extendSession, setExtendSession] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  return (
    <SessionContext.Provider value={initializeSessionExpirationCount}>
      <ChangeSessionContext.Provider
        value={setInitializeSessionExpirationCount}
      >
        <ExtendSessionContext.Provider value={extendSession}>
          <ChangeExtendSessionContext.Provider value={setExtendSession}>
            <SessionEpiredContext.Provider value={sessionExpired}>
              <ChangeSessionExpiredContext.Provider value={setSessionExpired}>
                {children}
              </ChangeSessionExpiredContext.Provider>
            </SessionEpiredContext.Provider>
          </ChangeExtendSessionContext.Provider>
        </ExtendSessionContext.Provider>
      </ChangeSessionContext.Provider>
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
