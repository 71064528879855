export const CLIENTEREALROUTES = {
  index: '/',
  father: 'cliente-real',
  externalRoutes: {
    login: 'login',
    activeOldUser: 'active-old-user/:email',
  },
  protectedRoutes: {
    dashboard: {
      self: 'dashboard',
      children: {
        index: 'inicio',
        historyOp: 'historico-de-operaciones',
        rateProducts: 'tasas-y-productos',
        makeAppointment: 'agendar-cita',
        requestCerts: 'solicitar-carta-y-certificaciones',
        guides: 'guias-e-instructivos',
      },
    },
  },
};

export const CLIENTEPOTENCIALROUTES = {
  index: '/',
  father: 'onboarding-digital',
  externalRoutes: {
    login: '/onboarding-digital/login',
    register: '/register',
    selectBranchYourChoice: '/register/select-branch-your-choice',
    selectSalesRepresentative: '/register/select-sales-representative',
    passwordRecovery: '/password-recovery',
    passwordRecoveryRequestNewPassword:
      '/password-recovery-request-new-password',
    mailSentNotification: '/mail-sent-notification',
    passwordChangedNotification: '/password-changed-notification',
    errorPage: '/pagina-de-error',
    selectAccountType: '/select-account-type',
  },
  protectedRoutes: {
    dashboard: {
      self: 'dashboard',
      children: {
        index: 'formularios/persona-juridica/descripcion-del-negocio',
        accountOpening: {
          name: 'datos-de-la-cuenta',
          children: {
            // RUTAS DE PERSONA JURIDICA
            companyInformation: {
              name: 'Información De Mi Empresa',
              children: {
                generalInfo: 'formularios/persona-juridica/informacion-general',
                settlementInstruction: 'formularios/persona-juridica/instruccion-de-liquidacion',
                mainContactInformation:
                  'formularios/persona-juridica/informacion-contacto-principal',
                businessDescription:
                  'formularios/persona-juridica/descripcion-del-negocio',
                // mainOfficeAddress: 'formularios/persona-juridica/direccion-oficina-principal',
                mainOfficeAddress:
                  'formularios/persona-juridica/direcciones-de-oficinas',
                // OfficeAddressDomRep: 'formularios/persona-juridica/direccion-oficina-republica-dominicana',
                legalRepresentative:
                  'formularios/persona-juridica/representante-legal',
                obligatedSubject:
                  'formularios/persona-juridica/sujeto-obligado',
                shareholders:
                  'formularios/persona-juridica/principales-accionistas',
                affiliatedCompanies:
                  'formularios/persona-juridica/empresas-afiliadas',
                supplementaryData:
                  'formularios/persona-juridica/datos-complementarios',
              },
            },
            parvalCustomerInformation: {
              name: 'Información de Cliente Parval',
              children: {
                bankAccounts: 'formularios/persona-juridica/cuentas-bancarias',
                commercialReferences:
                  'formularios/persona-juridica/referencias-comerciales',
                declarationOfBeneficiaries:
                  'formularios/persona-juridica/declaracion-de-beneficiarios',
                signatureCard: 'formularios/persona-juridica/tarjeta-de-firmas',
                counselMembers: 'formularios/persona-juridica/miembros-del-consejo',
                correspondenceEmails: 'formularios/persona-juridica/correos-de-correspondencia',
              },
            },
            financialInformation: {
              name: 'Información Financiera e Inversiones',
              children: {
                general: 'formularios/persona-juridica/generales',
                objectivesRiskTolerance:
                  'formularios/persona-juridica/objetivos-tolerancia-al-riesgo',
                titleOperations:
                  'formularios/persona-juridica/operaciones-de-titulos',
                distributionLiquidAssets:
                  'formularios/persona-juridica/distribucion-de-activos-liquidos',
                financialInstruments:
                  'formularios/persona-juridica/instrumentos-financieros',
                // sourceOfFunds: 'formularios/persona-juridica/origen-de-los-fondos',
                professionalClient:
                  'formularios/persona-juridica/cliente-profesional',
              },
            },

            // COMMON
            documents: {
              name: 'formularios/{{type}}/documentos',
            },

            // RUTAS DE PERSONA FISICA
            customerIdentification: {
              name: 'Identificación del cliente',
              children: {
                generalData: 'formularios/persona-fisica/datos-generales',
                commercialReferences: 'formularios/referencias-comerciales',
                workingInformation:
                  'formularios/persona-fisica/informacion-laboral',
                otherIncome: 'formularios/persona-fisica/otros-ingresos',
                workAddress: 'formularios/persona-fisica/direccion-laboral',
                pep: 'formularios/persona-fisica/pep',
                marketing: 'formularios/persona-fisica/marketing-sobre-parval',
                // branch: "formularios/persona-fisica/informacion-de-sucursal",
                investmentStatus:
                  'formularios/persona-fisica/estado-de-inversion',
                residentialAddress:
                  'formularios/persona-fisica/direccion-residencial',
                // dataCore: "formularios/persona-fisica/datos-core",
                relatedPeople:
                  'formularios/persona-fisica/personas-relacionadas',
                bankAccounts: 'formularios/persona-fisica/cuentas-bancarias',
                typeOfApplicant:
                  'formularios/persona-fisica/tipo-de-solicitante',
              },
            },
            financialInformationPhysicalPerson: {
              name: 'Información Financiera e Inversiones',
              children: {
                investorProfile:
                  'formularios/persona-fisica/perfil-del-inversionista',
                distributionLiquidAssets:
                  'formularios/persona-fisica/distribucion-de-activos-liquidos',
                financialInstruments:
                  'formularios/persona-fisica/instrumentos-financieros',
                sourceOfFunds:
                  'formularios/persona-fisica/origen-de-los-fondos',
                titleOperations:
                  'formularios/persona-fisica/operaciones-de-titulos-valores-financieras',
                professionalClient:
                  'formularios/persona-fisica/cliente-profesional',
                currencyAndInvestment:
                  'formularios/persona-fisica/moneda-e-inversion',
              },
            },
            related: {
              name: 'Relacionados',
              children: {
                finalBeneficiary:
                  'formularios/persona-fisica/beneficiario-final',
              },
            },
          },
        },
        documentRepository: 'repositorio-de-documentos',
        rateProducts: 'tasas-y-productos',
        guides: 'guias-e-instructivos',
      },
    },
    requestWayToFillForm: '/request-way-to-fill-form',
    identityVerification: '/identity-verification/:accountType',
    historyOperations: '/historico-de-operaciones',
    profile: '/profile',
    basicFormFieldsPage: '/formularios/persona-fisica/informacion-basica',
    residenceInformationFormPage:
      '/formularios/persona-fisica/informacion-de-residencia',
    contactInformationFormPage:
      '/formularios/persona-fisica/informacion-de-contacto',
    academicLevelAndProfessionFormPage:
      '/forms/academic-level-and-profession-form',
    accountTypesPage: '/forms/account-types',
    bankAccountsPage: '/formularios/persona-fisica/cuentas-bancarias',
    registrationDataRequest: '/forms/registration-data-request',
    generalInformation: '/formularios/persona-juridica/informacion-general',
    mainOfficeAddress:
      '/formularios/persona-juridica/direccion-oficina-principal',
    officeAddressDominicanRepublic:
      '/formularios/persona-juridica/direccion-oficina-republica-dominicana',
    legalRepresentativePage:
      '/formularios/persona-juridica/representante-legal',
    businessDescriptionPage:
      '/formularios/persona-juridica/descripcion-del-negocio',
    mainShareholdersPage:
      '/formularios/persona-juridica/principales-accionistas',
    affiliatedCompaniesPage: '/formularios/persona-juridica/empresas-afiliadas',
    communicationsAndCorrespondencePage:
      '/formularios/persona-juridica/comunicaciones-y-correspondencia',
    commercialReferencesPage: '/formularios/referencias-comerciales',
    parvalMarketingPage: '/formularios/persona-juridica/marketing-sobre-parval',
    investorProfilePage:
      '/formularios/persona-juridica/perfil-del-inversionista',
    bankAccountDesignationPage:
      '/formularios/persona-juridica/designacion-cuenta-bancaria',
    fatcaRelatedPage: '/formularios/persona-juridica/relativo-a-fatca',
    declarationOfBeneficiariesPage:
      '/formularios/persona-juridica/declaracion-de-beneficiarios',
    signatureCardPage: '/formularios/persona-juridica/tarjeta-de-firmas',
    affidavitProvisionInformationPage:
      '/formularios/persona-juridica/declaracion-jurada-de-suministro-de-informacion',
  },
};
