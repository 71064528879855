import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import {
  GET_CORRESPONDENCE_EMAIL_SECTION,
  GET_CORRESPONDENCE_EMAIL_TYPE,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  SAVE_CORRESPONDENCE_EMAIL_SECTION,
  REMOVE_CORRESPONDENCE_EMAIL,
} from '../../../../../shared/graphQL/mutations';
import { useDispatch } from 'react-redux';
import {
  loadPersons,
  setSelectedSection,
} from '../../../../../shared/store/slices/clientePotencial';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const CorrespondenceEmailForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [formResponseId, setFormResponseId] = useState('');
  const { data: correspondenceEmailTypes } = useQuery(GET_CORRESPONDENCE_EMAIL_TYPE);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isContrapart, persons, hasInvestmentProfile, isLIstedOnStock, hasBCRDAccount, isFormCompleted, isForeign, isFormDisqualified, validation } =
    useSelector((state) => state.clientePotencialSlice);
  const [getLegalRepresentativesSectionData, legalRepresentativesSectionData] =
    useLazyQuery(GET_LEGAL_REPRESENTATIVES_SECTION_DATA, {
      fetchPolicy: 'network-only',
    });
  const [saveCorrespondenceEmailSection] = useMutation(SAVE_CORRESPONDENCE_EMAIL_SECTION, {
    refetchQueries: [
      {
        query: GET_CORRESPONDENCE_EMAIL_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [removeCorrespondenceEmail] = useMutation(REMOVE_CORRESPONDENCE_EMAIL, {
    refetchQueries: [
      {
        query: GET_CORRESPONDENCE_EMAIL_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [getCorrespondenceEmailSection, correspondenceEmailSection] = useLazyQuery(
    GET_CORRESPONDENCE_EMAIL_SECTION, { fetchPolicy: 'network-only' });
  const [correspondenceEmailSectionId, setCorrespondenceEmailSectionId] = useState('');
  const [selectedCorrespondenceEmailId, setSelectedCorrespondenceEmailId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();

  const email = watch('email');
  const tipoEmail = watch('tipoEmail');

  const [loadingSection, setLoadingSection] = useState(true);

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if(formResponseByIdentification?.data?.formResponses?.length){
      setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);
      const parameter = {
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      };

      getCorrespondenceEmailSection(parameter);
      getLegalRepresentativesSectionData(parameter);
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (correspondenceEmailSection?.data) {
      setCorrespondenceEmailSectionId(
        correspondenceEmailSection?.data?.correspondenceEmailSection?.id
      );
      setLoadingSection(false);
    }
  }, [correspondenceEmailSection]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedCorrespondenceEmailId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/miembros-del-consejo'
    );
  };

  const navigateToNextPage = () => {
    if (hasInvestmentProfile) {
      dispatch(setSelectedSection('Información Financiera e Inversiones'));
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/generales'
      );
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/documentos'
      );
    }
  };

  const addCorrespondenceEmail = async () => {
    const emailIsInUse =
      correspondenceEmailSection?.data?.correspondenceEmailSection?.correspondenceEmails?.find(
        (correspondenceEmail) =>
          correspondenceEmail.id !== selectedCorrespondenceEmailId &&
          email === correspondenceEmail.email
      );

    if (emailIsInUse) {
      toast('Ya existe este correo electrónico', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    let data = {};

    //Si counselMembersSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (correspondenceEmailSectionId) {
      // Si selectedCorrespondenceEmailId tiene valor entonces se edita en lugar de agregar
      if (selectedCorrespondenceEmailId) {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: correspondenceEmailSectionId,
          correspondenceEmailInputTypes: {
            id: selectedCorrespondenceEmailId,
            email: email,
            correspondenceEmailTypeId: tipoEmail,
          },
        };

        setSelectedCorrespondenceEmailId('');
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: correspondenceEmailSectionId,
          correspondenceEmailInputTypes: {
            email: email,
            correspondenceEmailTypeId: tipoEmail,
          },
        };
      }
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        correspondenceEmailInputTypes: {
          email: email,
          correspondenceEmailTypeId: tipoEmail,
        },
      };
    }

    btnCloseModalRef.current.click();
    reset();

    await saveCorrespondenceEmailSection({
      variables: {
        correspondenceEmailSection: data,
      },
    });

    loadPersons(dispatch, {
      legalRepresentatives: persons.legalRepresentatives?.map((legal) => ({
        ...legal,
        correspondenceEmail: selectedCorrespondenceEmailId === legal.correspondenceEmail?.id ?
        {
          ...legal.correspondenceEmail,
          email: email,
        } : legal.correspondenceEmail,
      })),
      shareholders: persons.shareholders,
      signatores: persons.signatores,
      beneficiaries: persons.beneficiaries,
      counselMembers: persons.counselMembers,
    });

    setIsUpdating(false);
  };

  const deleteCardElement = async (id) => {
    await removeCorrespondenceEmail({
      variables: {
        correspondenceEmailId: id,
      },
    });
  };

  const handleEdit = (id) => {
    setSelectedCorrespondenceEmailId(id);
    const selectedCorrespondenceEmail =
      correspondenceEmailSection?.data?.correspondenceEmailSection?.correspondenceEmails?.find(
        (x) => x.id === id
      );

    if (id && selectedCorrespondenceEmail) {
      selectedCorrespondenceEmail?.email &&
        setValue('email', selectedCorrespondenceEmail?.email);
      selectedCorrespondenceEmail?.correspondenceEmailType?.id &&
        setValue('tipoEmail', selectedCorrespondenceEmail?.correspondenceEmailType?.id);
    }
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'correspondenceEmailSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const checkLegalRepresentativesEmail = (id) => {
    return legalRepresentativesSectionData?.data?.legalRepresentativesSection
      ?.legalRepresentatives?.find((legal) => legal.correspondenceEmail?.id === id);
  }

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Correos Electrónicos de Correspondencia
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={
                'Correos Electrónicos de Correspondencia'
              }
              description={
                `En el formulario 'Correos Electrónicos de Correspondencia', se
                detallan los correos electrónicos de correspondencia de la
                empresa. Este espacio proporciona un registro completo de los
                correos electrónicos de correspondencia de la entidad. Los
                correos electrónicos de correspondencia son esenciales para
                garantizar la comunicación efectiva y eficiente con la
                empresa.`
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'correspondenceEmails'
                      ? 'Es necesario que agregues por lo menos un correo electrónico de correpondencia para facturas.'
                      : `Hay uno o varios correos electrónicos de correpondencia con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>

          <h4 className="bff-sub-title">
            Si tienes más de un correo electrónico de correpondencia, puedes agregarlos todos aquí.
          </h4>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {correspondenceEmailSection?.data?.correspondenceEmailSection?.correspondenceEmails?.map(
                (correspondenceEmail, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={correspondenceEmail?.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardItems
                        key={index}
                        nameElement={
                          correspondenceEmail?.email
                        }
                        idElement={correspondenceEmail?.id}
                        deleteElement={deleteCardElement}
                        handleEdit={handleEdit}
                        setIsUpdating={setIsUpdating}
                        showDeleteButton={
                          !isFormCompleted && !isFormDisqualified && 
                          !checkLegalRepresentativesEmail(correspondenceEmail?.id)
                        }
                        forceEdit={checkLegalRepresentativesEmail(correspondenceEmail?.id)}
                        detalle={
                          correspondenceEmail?.correspondenceEmailType?.name
                        }
                      />
                    </div>
                  );
                }
              )}
              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    reset();
                    setSelectedCorrespondenceEmailId('');
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">Agregar Correo Electrónico de Correspondencia</h4>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="button"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form
              onSubmit={handleSubmit(addCorrespondenceEmail)}
              className="fields-modal-container"
            >
              <div className="fields-modal-header">
                <h4 className="modal-title" id="exampleModalLabel">
                  {!isFormCompleted && !isFormDisqualified
                    ? selectedCorrespondenceEmailId
                      ? 'Editar'
                      : 'Agregar'
                    : 'Ver'}{' '}
                  Correo Electrónico de Correspondencia
                </h4>
                <button
                  type="button"
                  ref={btnCloseModalRef}
                  onClick={() => {
                    reset();
                    setSelectedCorrespondenceEmailId('');
                  }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="row fields-modal-content">
                <div className="row">
                  <div className="field-form col-lg-6 col-sm-12">
                    <label htmlFor="email" className="label-field">
                      Correo electrónico <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="email"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('email', {
                        required: true,
                      })}
                    />
                    {errors.email?.type === 'required' && (
                      <p className="text-danger">
                        El campo Correo Electrónico es requerido
                      </p>
                    )}
                    {selectedCorrespondenceEmailId &&
                      !email &&
                      errors.email?.type !== 'required' && (
                        <ErrorText hasError={hasError('email')} />
                      )}
                  </div>

                  <div className="field-form col-lg-6 col-sm-12">
                    <label htmlFor="tipo-documento" className="label-field">
                      Tipo de documento <span>*</span>
                    </label>
                    <div className="selectWrapper">
                      {' '}
                      <select
                        id="tipo-documento"
                        defaultValue={'DEFAULT'}
                        disabled={isFormCompleted || isFormDisqualified || checkLegalRepresentativesEmail(selectedCorrespondenceEmailId)}
                        {...register('tipoEmail', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {correspondenceEmailTypes?.correspondenceEmailTypes.map(
                          (correspondenceEmailType) => {
                            return (
                              <option
                                key={correspondenceEmailType.id}
                                value={correspondenceEmailType.id}
                              >
                                {correspondenceEmailType.name}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    {errors.tipoEmail && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                    {selectedCorrespondenceEmailId &&
                      (!tipoEmail || tipoEmail === 'DEFAULT') &&
                      errors.tipoEmail && (
                        <ErrorText hasError={hasError('correspondenceEmailType')} />
                      )}
                  </div>
                </div>
              </div>

              {!isFormCompleted && !isFormDisqualified ? (
                <div className="row d-flex justify-content-center">
                  <button type="submit" className="mt-5 mb-5 btn-add">
                    Guardar
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorrespondenceEmailForm;