import './BranchAccountSummary.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  envars,
  getDecodedCookie,
  modifyCookie,
} from '../../../../shared/utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  AZURE_SESSION_TIME_OUT,
  BRANCHTYPE,
  CLIENTTYPE,
  azureUserFlowUri,
} from '../../../../shared/constants/gobal-constants';
import { GET_BRANCHES } from '../../../../shared/graphQL/queries';

export default function BranchAccountSummary() {
  const navigate = useNavigate();
  const [branchSelected, setBranchSelected] = useState(null);
  const [reload, setReload] = useState(false);
  const [animationOut, setAnimationOut] = useState(false);
  const { loading, error, data } = useQuery(GET_BRANCHES);
  const dispatch = useDispatch();

  useEffect(() => {
    const configDecoded = getDecodedCookie('potencialCustomerConfigRC');
    if (configDecoded && configDecoded.branch) {
      setReload(true);
      setBranchSelected(configDecoded.branch);
    }

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener las sucursales', {
      type: 'error',
      theme: 'colored',
    });
  }

  const selectBranch = (branchId) => {
    setBranchSelected(branchId);
  };

  useEffect(() => {
    if (branchSelected && !reload) {
      setAnimationOut(true);
      setTimeout(() => {
        goTo();
      }, 500);
    }
  }, [branchSelected, reload]);

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    const configDecoded = getDecodedCookie('potencialCustomerConfigRC');
    if (
      configDecoded?.assistUnit &&
      CLIENTTYPE[
        configDecoded.assistUnit.name
          .split(' ')
          [configDecoded.assistUnit.name.split(' ').length - 1].toLowerCase()
      ].isBusiness
    ) {
      modifyCookie(
        'potencialCustomerConfigRC',
        'branch',
        branchSelected,
        true,
        timeout
      );
      navigate(
        `/onboarding-digital/dashboard/apertura-de-cuenta/tipo-de-empresa`
      );
    }
    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  };

  const goBack = () => {
    navigate(
      '/onboarding-digital/dashboard/apertura-de-cuenta/unidad-de-asistencia'
    );
  };

  const getIcon = (p) => {
    return BRANCHTYPE[
      p.name.split(' ')[p.name.split(' ').length - 1].toLowerCase()
    ].icon;
  };

  const filterByUnit = (branches) => {
    let filterBranches = [];
    if (branches?.length) {
      const configDecoded = getDecodedCookie('potencialCustomerConfigRC');
      if (configDecoded?.assistUnit?.name?.toLowerCase().includes('negocios')) {
        filterBranches = branches.filter(
          (branch) => !branch.name.includes('Alameda')
        );
      }
    }

    return filterBranches;
  };

  return (
    <div className="accountSumary">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className=" ">
            {/* <p className='linkBack' onClick={goBack}><FontAwesomeIcon icon={faAngleLeft} /> Volver</p> */}
            <div className="vAlign" style={{ padding: '2rem' }}>
              <strong className="formTitle">
                Elije tu sucursal de preferencia
              </strong>
              <p className="subTitle">Estamos ubicados en:</p>
              <div className="mt-2 newForm">
                <div className="cards-container-summary-account">
                  {!loading &&
                    !error &&
                    data &&
                    data.branches &&
                    filterByUnit(data.branches)?.map((branch) => (
                      <div
                        className={`boxButton card-element`}
                        onClick={() => {
                          setReload(false);
                          selectBranch(branch);
                        }}
                        key={branch?.id}
                      >
                        <div
                          className="flexLeft"
                          style={{ width: '100%', alignItems: 'flex-start' }}
                        >
                          <div className="iconContainer">
                            {/* <img src={getIcon(branch)} alt="" /> */}
                            <img src="/images/iconSantoDomingo.svg" alt="" />
                          </div>
                          <div>
                            <h4 className="rwf-card-title">{branch.name}</h4>
                            <p className="card-text">{branch.address}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                </div>
              </div>
              <p className="linkBack bottom mt-3" onClick={goBack}>
                <FontAwesomeIcon icon={faAngleLeft} /> Volver
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
