import React, { useRef, useState } from 'react';
import './DistributionLiquidAssetsLpFormNew.css';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import {
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA,
  GET_INVESTMENT_PROFILE_SECTION,
  GET_LIQUID_ASSETS_TYPES_LIST,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GENERATE_INVESTMENT_PROFILE,
  REMOVE_LIQUID_ASSETS_TYPE_DISTRIBUTION,
  SAVE_INVESTMENT_FINANCIAL_DETAILS_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useSelector } from 'react-redux';
import { Error } from '@mui/icons-material';

const DistributionLiquidAssetsLpFormNew = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { data: liquidAssetsTypes } = useQuery(GET_LIQUID_ASSETS_TYPES_LIST);
  const [formResponseId, setFormResponseId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [
    getInvestmentFinancialDetailsSectionData,
    investmentFinancialDetailsSectionData,
  ] = useLazyQuery(GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [generateInvestmentProfile] = useMutation(GENERATE_INVESTMENT_PROFILE, {
    refetchQueries: [
      {
        query: GET_INVESTMENT_PROFILE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [saveInvestmentFinancialDetailsSection] = useMutation(
    SAVE_INVESTMENT_FINANCIAL_DETAILS_SECTION,
    {
      refetchQueries: [
        {
          query: GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [removeLiquidAssetsTypeDistribution] = useMutation(
    REMOVE_LIQUID_ASSETS_TYPE_DISTRIBUTION,
    {
      refetchQueries: [
        {
          query: GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [loadingSection, setLoadingSection] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [liquidAssetId, setLiquidAssetId] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const btnCloseModalRef = useRef();

  const tipoActivoLiquido = watch('tipoActivoLiquido');
  const porcentaje = watch('porcentaje');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      getInvestmentFinancialDetailsSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/operaciones-de-titulos'
    );
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/instrumentos-financieros'
    );
  };

  const saveData = () => {
    let data = {};

    const sectionId =
      investmentFinancialDetailsSectionData?.data
        ?.investmentFinancialDetailsSection?.id;
    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (sectionId === undefined || sectionId === null) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        liquidAssetsTypeDistributionInputTypes: [
          {
            percentage: Number(porcentaje),
            liquidAssetsTypeId: tipoActivoLiquido,
          },
        ],
      };
    } else {
      if (liquidAssetId) {
        data = {
          id: sectionId,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          liquidAssetsTypeDistributionInputTypes:
            investmentFinancialDetailsSectionData?.data?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions?.map(
              (liquidAssetsTypeDistribution) => {
                if (liquidAssetsTypeDistribution.id === liquidAssetId) {
                  return {
                    id: liquidAssetId,
                    percentage: Number(porcentaje),
                    liquidAssetsTypeId: tipoActivoLiquido,
                  };
                } else {
                  return {
                    id: liquidAssetsTypeDistribution.id,
                    percentage: liquidAssetsTypeDistribution.percentage,
                    liquidAssetsTypeId:
                      liquidAssetsTypeDistribution.liquidAssetsType.id,
                  };
                }
              }
            ),
        };
      } else {
        data = {
          id: sectionId,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          liquidAssetsTypeDistributionInputTypes: [
            ...investmentFinancialDetailsSectionData?.data?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions?.map(
              (liquidAssetsTypeDistribution) => {
                return {
                  id: liquidAssetsTypeDistribution.id,
                  percentage: liquidAssetsTypeDistribution.percentage,
                  liquidAssetsTypeId:
                    liquidAssetsTypeDistribution.liquidAssetsType.id,
                };
              }
            ),
            {
              percentage: Number(porcentaje),
              liquidAssetsTypeId: tipoActivoLiquido,
            },
          ],
        };
      }
    }

    saveInvestmentFinancialDetailsSection({
      variables: {
        investmentFinancialDetailsSection: data,
      },
    });

    generateInvestmentProfile({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });

    btnCloseModalRef.current.click();
    reset();
  };

  const deleteCardElement = (id) => {
    removeLiquidAssetsTypeDistribution({
      variables: {
        liquidAssetsTypeDistributionId: id,
      },
    });
  };

  const handleEdit = (id) => {
    setLiquidAssetId(id);
    const liquidAsset =
      investmentFinancialDetailsSectionData?.data?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions?.find(
        (liquidAsset) => liquidAsset.id === id
      );
    if (id && liquidAsset) {
      setTimeout(() => {
        setValue('tipoActivoLiquido', liquidAsset?.liquidAssetsType?.id);
        setValue('porcentaje', liquidAsset?.percentage);
      }, 0);
    }
  };

  const percentageAvailable = (value = 100) => {
    if (
      investmentFinancialDetailsSectionData?.data
        ?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions
        ?.length
    ) {
      for (const element of investmentFinancialDetailsSectionData?.data
        ?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions) {
        value -= element?.percentage ?? 0;
      }
    }

    return Number(value.toFixed(2));
  };

  const getMaxPercentage = () => {
    let value = 100;

    if (liquidAssetId) {
      const distributionToEdit =
        investmentFinancialDetailsSectionData?.data?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions?.find(
          (existingDistribution) => existingDistribution.id === liquidAssetId
        );

      if (distributionToEdit) {
        value += parseFloat(distributionToEdit?.percentage?.toString() ?? '0');
      }
    }

    return percentageAvailable(value);
  };

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(liquidAssetId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const filterLiquidAssetsTypes = (asset) => {
    let inUseTypes =
      investmentFinancialDetailsSectionData?.data?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions?.map(
        (liquidAssetType) => liquidAssetType.liquidAssetsType
      );

    if (asset) {
      const distributionToEdit =
        investmentFinancialDetailsSectionData?.data?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions?.find(
          (existingDistribution) => existingDistribution.id === liquidAssetId
        );
      inUseTypes = inUseTypes?.filter(
        (type) => type.id !== distributionToEdit.liquidAssetsType.id
      );
    }

    const idsToRemove = new Set(
      inUseTypes?.length ? inUseTypes?.map((item) => item.id) : []
    );
    const typesToUse = liquidAssetsTypes?.liquidAssetsTypes?.filter(
      (item) => !idsToRemove.has(item.id)
    );

    return typesToUse;
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'investmentFinancialDetailsSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  return (
    <div className="page-container" id="page-container">
      <div className="principal-container3">
        <h3 className="formTitle" style={{ color: '#3d675e' }}>
          Distribución de Activos Líquidos
          <ModalInfo
            Icon={<FontAwesomeIcon icon={faCircleInfo} />}
            title={'Distribución de Activos Líquidos'}
            description={
              "El formulario 'Distribución de Activos Líquidos' se enfoca en recopilar información esencial sobre la asignación de activos líquidos del cliente. A través de selectores, se registran porcentajes correspondientes a diferentes categorías de activos, incluyendo Acciones del Exterior, Cuentas Bancarias, Cuentas por Cobrar, Depósitos a Plazo, Efectivo, Inventarios y Mercado de Valores. Esta distribución proporciona una visión detallada de cómo el cliente gestiona y diversifica sus recursos líquidos, permitiendo una comprensión precisa de la estructura de su cartera y estrategias de inversión."
            }
          />
          {!!validationErrors?.find(
            (violationError) =>
              violationError === 'liquidAssetsTypeDistributions'
          )?.length && (
            <span style={{ marginLeft: 30 }}>
              <ModalInfo
                color="white"
                bgcolor="#B90E0A"
                Icon={
                  <Error
                    style={{
                      color: '#B90E0A',
                      fontSize: 25,
                      marginTop: '-5px',
                    }}
                  />
                }
                description={
                  'Es necesario que completes el 100% de la distibrución de activos liquidos.'
                }
              />
            </span>
          )}
        </h3>

        {loadingSection ? (
          <div className="w-100 d-flex justify-content-center p-5">
            <CircularProgress color="warning" />
          </div>
        ) : (
          <>
            <div className="add-card-container">
              {investmentFinancialDetailsSectionData?.data?.investmentFinancialDetailsSection?.liquidAssetsTypeDistributions?.map(
                (asset, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={asset.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardItems
                        key={index}
                        nameElement={asset.liquidAssetsType.name}
                        idElement={asset.id}
                        identityDocument={asset.percentage + '%'}
                        deleteElement={deleteCardElement}
                        handleEdit={handleEdit}
                        setIsUpdating={setIsUpdating}
                        showDeleteButton={!isFormCompleted && !isFormDisqualified}
                      />
                    </div>
                  );
                }
              )}
              {!isFormCompleted && !isFormDisqualified && percentageAvailable() > 0 ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    reset();
                    setLiquidAssetId('');
                    setIsUpdating(false);
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">
                    Agregar Distribución de Activo Liquido
                  </h4>
                </div>
              ) : null}
            </div>

            <div className="row my-3">
              <div>
                <p className="text-center" style={{ fontSize: '0.8rem' }}>
                  Esta distribución proporciona una visión detallada de cómo el
                  cliente gestiona y diversifica sus recursos líquidos,
                  permitiendo una comprensión precisa de la estructura de su
                  cartera y estrategias de inversión.
                </p>
              </div>
            </div>
          </>
        )}

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <form
                onSubmit={handleSubmit(saveData)}
                className="fields-modal-container frm-main-share-holders"
              >
                <div className="fields-modal-header mb-3">
                  <h4 className="modal-title" id="exampleModalLabel">
                    {!isFormCompleted && !isFormDisqualified
                      ? liquidAssetId
                        ? 'Editar'
                        : 'Agregar'
                      : 'Ver'}{' '}
                    Distribución de Activo Liquido
                  </h4>
                  <button
                    type="button"
                    ref={btnCloseModalRef}
                    onClick={() => {
                      reset();
                      setLiquidAssetId('');
                      setIsUpdating(false);
                    }}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>

                <div className="row mt-5">
                  <div className="field-form col-md-7 col-sm-12 ps-4">
                    <label
                      htmlFor="tipo-activo-liquido"
                      className="label-field"
                    >
                      Tipo de Activo Liquido <span>*</span>
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="tipo-activo-liquido"
                        defaultValue={'DEFAULT'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('tipoActivoLiquido', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {filterLiquidAssetsTypes(liquidAssetId)?.map(
                          (liquidAssetType) => (
                            <option
                              key={liquidAssetType.id}
                              value={liquidAssetType.id}
                            >
                              {liquidAssetType.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {errors.tipoActivoLiquido && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>
                  <div
                    className="field-form col-md-4 col-sm-12 ps-4"
                    style={{ marginTop: '-13px' }}
                  >
                    <label htmlFor="porcentaje" className="label-field">
                      Porcentaje <span>*</span>
                    </label>
                    <input
                      type="number"
                      className="input-form"
                      placeholder=""
                      max={getMaxPercentage()}
                      id="porcentaje"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('porcentaje', {
                        required: true,
                        max: getMaxPercentage(),
                      })}
                    />
                    {!errors.porcentaje && (
                      <small
                        id="numero-accionesHelp"
                        style={{ marginTop: '-32px', fontSize: '13px' }}
                        className="text-muted d-block"
                      >
                        Porcentaje Disponible: {getMaxPercentage()}%
                      </small>
                    )}
                    {errors.porcentaje?.type === 'required' && (
                      <p className="text-danger">Este campo es requerido</p>
                    )}
                    {errors.porcentaje?.type === 'max' && (
                      <p className="text-danger">
                        El porcentaje máximo permitido es {getMaxPercentage()}%
                      </p>
                    )}
                  </div>
                </div>

                {!isFormCompleted && !isFormDisqualified ? (
                  <div className="row d-flex mt-5 justify-content-center">
                    <button type="submit" className="mt-2 mb-4 btn-add">
                      Guardar
                    </button>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="button"
            className="btn-continue"
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DistributionLiquidAssetsLpFormNew;
