import './EmploymentStatusForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';

const EmploymentStatusForm = ({ validateForm, setValidateForm, goToBack }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [jobStatuses, setJobStatuses] = useState([]);
  const [economicActivities, setEconomicActivities] = useState([]);
  const [incomeOptions, setIncomeOptions] = useState([]);

  const estadoLaboral = watch('estadoLaboral');
  const nombreCompania = watch('nombreCompania');
  const cargo = watch('cargo');

  const telefonoOficina = watch('telefonoOficina');
  const actividadEconomica = watch('actividadEconomica');
  const fechaIngreso = watch('fechaIngreso');
  const correoElectronicoLaboral = watch('correoElectronicoLaboral');
  const ingresosMensuales = watch('ingresosMensuales');

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-fisica/cuentas-bancarias');
    }
  }, [goToBack]);

  useEffect(() => {
    //getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveDataInBD = () => {
    navigate('/formularios/persona-fisica/otros-ingresos');
  };

  return (
    <>
      {showSpinner ? <Spinner /> : null}

      <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
        <div className="row fields-container">
          <div className="col-md-6 col-sm-12 pe-4 columns">
            <div className="field-form">
              <label htmlFor="estado-laboral" className="label-field">
                Indique su estado laboral <span>*</span>
              </label>
              <select
                id="estado-laboral"
                defaultValue={'DEFAULT'}
                {...register('estadoLaboral', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {jobStatuses.map((status) => (
                  <option key={status.id} value={status.id}>
                    {status.name}
                  </option>
                ))}
              </select>
              {errors.estadoLaboral && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="nombre-compania" className="label-field">
                Nombre de la compañía <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="nombre-compania"
                {...register('nombreCompania', {
                  required: true,
                })}
              />
              {errors.nombreCompania?.type === 'required' && (
                <p className="text-danger">
                  El campo Nombre de la compañía es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="cargo" className="label-field">
                Cargo <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="cargo"
                {...register('cargo', {
                  required: true,
                })}
              />
              {errors.cargo?.type === 'required' && (
                <p className="text-danger">El campo Cargo es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="telefono-oficina" className="label-field">
                Teléfono de la oficina <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="telefono-oficina"
                {...register('telefonoOficina', {
                  required: true,
                })}
              />
              {errors.telefonoOficina?.type === 'required' && (
                <p className="text-danger">
                  El campo Teléfono de la oficina es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="actividad-economica" className="label-field">
                Actividad económica <span>*</span>
              </label>
              <select
                id="actividad-economica"
                defaultValue={'DEFAULT'}
                {...register('actividadEconomica', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {economicActivities.map((activity) => (
                  <option key={activity.id} value={activity.id}>
                    {activity.name}
                  </option>
                ))}
              </select>
              {errors.actividadEconomica && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="fecha-ingreso" className="label-field">
                Fecha de ingreso <span>*</span>
              </label>
              <input
                type="date"
                className="input-form"
                placeholder=""
                id="fecha-ingreso"
                {...register('fechaIngreso', {
                  required: true,
                })}
              />
              {errors.fechaIngreso?.type === 'required' && (
                <p className="text-danger">
                  El campo Fecha de constitución es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="correo-electronico-laboral"
                className="label-field"
              >
                Correo eléctrónico laboral <span>*</span>
              </label>
              <input
                type="email"
                className="input-form"
                placeholder=""
                id="correo-electronico-laboral"
                {...register('correoElectronicoLaboral', {
                  required: true,
                })}
              />
              {errors.correoElectronicoLaboral?.type === 'required' && (
                <p className="text-danger">
                  El campo Correo eléctrónico laboral es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="ingresos-mensuales" className="label-field">
                Ingresos mensuales <span>*</span>
              </label>
              <select
                id="ingresos-mensuales"
                defaultValue={'DEFAULT'}
                {...register('ingresosMensuales', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {incomeOptions.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
              {errors.ingresosMensuales && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>
          </div>
        </div>
        <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
          submit
        </button>
      </form>
    </>
  );
};

export default EmploymentStatusForm;
