import './AccountTypes.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AccountTypes = ({ validateForm, setValidateForm, goToBack }) => {
  const [accountTypes, setAccountTypes] = useState([]);
  const [cardClicked, setCardClicked] = useState('');
  const [accountTypeSelected, setAccountTypeSelected] = useState(0);
  const navigate = useNavigate();

  const divStyleAccountSelected = {
    border: '2px solid #F3B90E',
    backgroundColor: 'white',
  };

  useEffect(() => {
    if (cardClicked === 'individual') {
      setAccountTypeSelected(accountTypes[0].id);
    } else if (cardClicked === 'mancomunada') {
      setAccountTypeSelected(accountTypes[1].id);
    }
  }, [cardClicked]);

  useEffect(() => {
    if (validateForm === true) {
      localStorage.setItem('accountType', accountTypeSelected);
      setValidateForm(false);

      // Navegar al siguiente formulario
      if (cardClicked !== '') {
        // TODO codigo para guardar el tipo de cuenta selecionada en la base de datos. Tambien se debe agregar el codigo para traer la opcion seleccionada
        navigate('/formularios/persona-fisica/cuentas-bancarias');
      }
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/forms/academic-level-and-profession-form');
    }
  }, [goToBack]);

  return (
    <div className="cards-container-account-types">
      <div
        className="fill-form-way-card margin-card"
        style={
          cardClicked === 'individual'
            ? divStyleAccountSelected
            : { border: '2px solid rgb(209, 202, 202)' }
        }
        onClick={() => setCardClicked('individual')}
      >
        <div className="image-icon-container">
          <img src="/images/individual.svg" alt="" className="image-icon" />
        </div>
        <h4 className="rwf-card-title">Individual</h4>
        <p>Esta es una cuenta que pertenece a una sola persona</p>
      </div>

      <div
        className="fill-form-way-card"
        style={
          cardClicked === 'mancomunada'
            ? divStyleAccountSelected
            : { border: '2px solid rgb(209, 202, 202)' }
        }
        onClick={() => setCardClicked('mancomunada')}
      >
        <div className="image-icon-container">
          <img src="/images/mancumunada.svg" alt="" className="image-icon" />
        </div>
        <h4 className="rwf-card-title">Mancomunada</h4>
        <p>Esta es una cuenta compartida entre dos o más personas</p>
      </div>
    </div>
  );
};

export default AccountTypes;
