export const fieldsByComplexSections = {
  Generales2: [
    'investmentTime',
    'reinvestmentBehavior',
    'capitalLossMargin',
    'riskTolerance',
    'desiredProfitability',
    'marketInformationSource',
    'initialInvestmentAmount',
    'annualInvestmentAmount',
    'highVolatilityBehavior',
    'operationTime',
    'investmentExperience',
    'annualRevenue',
    'liquidAssets',
    'totalPatrimony',
    'accountsPayable',
    'annualExpenses',
    'emergencyFunds',
  ],
  Generales: [
    'registeredNumber',
    'registeredNumberExpirationDate',
    'commercialName',
    'constitutionDate',
    'societyType',
    'country',
    'equityType',
    'companyOrigin',
    'custodian',
    'accountHeld',
    'clearingNumber',
  ],
  'Contacto Principal': [
    'linkageType',
    'parvalRelationship',
    'haveTaxIdentificationNumber',
    'haveGlobalIntermediaryIdentificationNumber',
    'taxIdentificationNumber',
    'globalIntermediaryIdentificationNumber',
    'email',
    'lastName',
    'firstName',
  ],
  'Descripción del Negocio': [
    'employeesNumber',
    'productsOrServices',
    'providers',
    'clientsAndCountries',
    'economicSector',
    'economicActivity',
    'annualSales',
    'isObligatedSubject',
    'obligatedSubjectType',
  ],
  'Datos Complementarios': [
    'leadManagementType',
    'invoiceEmail',
    'primaryPhone',
    'correspondenceDelivery',
    'referral',
    'socalNetworks',
    'marketingChannels',
    'referralInfo',
    'correspondenceConfirmations',
  ],
  'Marque las operaciones de títulos valores y financieras que desea realizar y comprende':
    ['financialOperations'],
  'Objetivos / Tolerancia al riesgo': ['investmentObjective'],
  'Distribución de Activos Líquidos': ['liquidAssetsTypeDistributions'],
  'Sujeto Obligado': ['dueDiligenceForm'],
};
