import './MailSentNotificationPage.css';

const MailSentNotificationPage = () => {
  return (
    <div className="msnp-container">
      <h3 className="msnp-text">
        Hemos enviado un mensaje de recuperación a su correo electrónico. Por
        favor revise su correo.
      </h3>
      <img src="/images/check-mark.png" alt="" className="msnp-check-image" />
    </div>
  );
};

export default MailSentNotificationPage;
