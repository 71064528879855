import './CommercialReferencesForm.css';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setSelectedSection } from '../../../../../shared/store/slices/clientePotencial';

const CommercialReferencesForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm();
  const [signatureCards, setSignatureCards] = useState([]);
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const dispatch = useDispatch();

  const nombreCompletoFirmante = watch('nombreCompletoFirmante');
  const documentoIdentidad = watch('documentoIdentidad');

  useEffect(() => {
    // getSignatureCards();
  }, []);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
    );
  };

  const navigateToNextPage = () => {
    dispatch(setSelectedSection('Información Financiera e Inversiones'));
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/generales'
    );
  };

  const addSignatureCard = () => {
    btnCloseModalRef.current.click();
    // setDisableButton(false);
    reset();
  };

  const deleteCardElement = (id) => {};

  return (
    <>
      <div className="page-container" id="page-container">
        {/* <div>
            <img src="/logo.png" className="logoParval2" alt="" />
        </div> */}

        <div className="principal-container3">
          <h3 className="formTitle">Referencias Personales y Comerciales </h3>

          <div className="add-card-container">
            <div
              className="add-card"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="image-icon-container">
                <FontAwesomeIcon icon={faAdd} className="image-icon" />
              </div>
              <h4 className="bk-card-title">Agregar Referencia</h4>
            </div>
          </div>

          <div className="cards-container-add-element">
            {signatureCards.map((signatureCard, index) => {
              return (
                <CardItems
                  key={index}
                  nameElement={signatureCard.fullName}
                  identityDocument={signatureCard.identificationDocument}
                  idElement={signatureCard.id}
                  deleteElement={deleteCardElement}
                />
              );
            })}
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addSignatureCard)}
                  className="fields-modal-container frm-main-share-holders"
                >
                  <div className="fields-modal-header mb-3">
                    <h4 className="modal-title" id="exampleModalLabel">
                      Agregar Referencia
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      onClick={() => reset()}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="row fields-modal-content">
                    <div className="col-12">
                      <div className="field-form">
                        <label
                          htmlFor="nombre-referencia"
                          className="label-field"
                        >
                          Nombre <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="nombre-referencia"
                          {...register('nombreReferencia', {
                            required: true,
                          })}
                        />
                        {errors.nombreReferencia?.type === 'required' && (
                          <p className="text-danger">
                            El campo Nombre es requerido
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label htmlFor="telefono" className="label-field">
                          Teléfono <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="input-form"
                          placeholder=""
                          id="telefono"
                          {...register('telefono', {
                            required: true,
                          })}
                        />
                        {errors.telefono?.type === 'required' && (
                          <p className="text-danger">
                            El campo Teléfono es requerido
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center">
                    <button type="submit" className="mt-2 mb-4 btn-add">
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default CommercialReferencesForm;
