import { AccountBalanceTwoTone, EmojiFlags, Public } from '@mui/icons-material';
import './CardBankAccounts.css';

const CardBankAccounts = ({
  nameElement,
  identityDocument,
  idElement,
  deleteElement,
  tipoCuenta = '',
  monedaCuenta,
  localAccount,
  logoBanco,
  nombreBanco,
  handleEdit,
  setIsUpdating,
  showDeleteButton,
}) => {
  const handleDeleteElement = () => {
    if (tipoCuenta === '') {
      deleteElement(idElement);
    } else {
      deleteElement(idElement, tipoCuenta);
    }
  };

  const handleEditElement = () => {
    handleEdit(idElement);
    setIsUpdating(true);
  };

  return (
    <div className="fill-form-way-card" style={{ paddingLeft: 15 }}>
      <div className="point-options-container">
        <span>...</span>
        <ul>
          <li onClick={handleEditElement}>{!showDeleteButton ? 'Detalles' : 'Editar'}</li>
          {showDeleteButton ? (
            <li onClick={handleDeleteElement}>Eliminar</li>
          ) : null}
        </ul>
      </div>

      <div className="bank-info d-flex" style={{ marginBottom: -15 }}>
        {logoBanco ? (
          <img
            src={`/middleware/${logoBanco.split('.net/')[1]}`}
            alt="Logo del banco"
            style={{
              objectFit: 'contain',
              position: 'unset',
              height: 25,
              width: 47,
            }}
          />
        ) : (
          <AccountBalanceTwoTone />
        )}
        <span className="ms-1 bank-account-text">{nombreBanco}</span>
      </div>

      {/* <h4 className='rwf-card-title' style={{ fontSize: "5px !important" }}>{nameElement}</h4> */}
      <div className="bank-account-number-container mt-4">
        <span>{identityDocument}</span>
      </div>
      <div className="bank-acount-text-container d-flex align-items-center">
        <span className="bank-account-text">{tipoCuenta}</span>
        {/* Esta parte agrega un espacio pra separar las palabras */}
        <span>&nbsp; | &nbsp;</span>
        <span className="bank-account-text">{monedaCuenta}</span>
        <span>&nbsp; | &nbsp;</span>
        {localAccount ? (
          <EmojiFlags style={{ fontSize: 18 }} titleAccess="Cuenta nacional" />
        ) : (
          <Public style={{ fontSize: 18 }} titleAccess="Cuenta internacional" />
        )}
      </div>

      <div className="bank-account-holder-container">
        <span className="bank-account-text">{nameElement}</span>
      </div>
    </div>
  );
};

export default CardBankAccounts;
