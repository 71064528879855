import * as bootstrap from 'bootstrap';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Toast = ({
  title,
  message,
  typeButton,
  textSelector,
  handleClick,
  selector,
  selectorClass,
  displayToast,
}) => {
  const showToast = () => {
    handleClick();

    let toastTrigger = document.getElementById('liveToastBtn');
    let toastLiveExample = document.getElementById('liveToast');
    if (toastTrigger) {
      var toast = new bootstrap.Toast(toastLiveExample);
      toast.show();
    }
  };

  useEffect(() => {
    if (displayToast === true) {
      showToast();
    }
  }, [displayToast]);

  return (
    <div>
      {selector === 'button' ? (
        <button
          type={typeButton}
          className="btn btn-primary mt-1"
          id="liveToastBtn"
          onClick={showToast}
        >
          {' '}
          {textSelector}{' '}
        </button>
      ) : (
        <Link
          to="#"
          className={selectorClass}
          onClick={showToast}
          id="liveToastBtn"
        >
          {' '}
          {textSelector}{' '}
        </Link>
      )}

      <div
        className="toast align-items-center text-white bg-danger border-0 position-fixed top-0 end-0 p-1"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        id="liveToast"
        data-bs-delay="3000"
      >
        <div className="d-flex">
          <div className="toast-body">
            <h6> {title} </h6>
            <p> {message} </p>
          </div>
          {/* <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button> */}
        </div>
      </div>
    </div>
  );
};

export default Toast;
