import React from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';

export default function Chart() {
  const options = {
    color: ['#fef1cf', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
    title: {
      text: ' ',
    },
    tooltip: {
      triggerOn: 'axis',
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'Line 1',
        type: 'line',
        stack: 'Total',
        symbolSize: 8,
        smooth: true,
        symbol: 'circle',
        itemStyle: {
          color: '#e3cc33',
        },
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgb(255, 255, 255)',
            },
            {
              offset: 1,
              color: 'rgb(254, 241, 207)',
            },
          ]),
        },
        emphasis: {
          focus: 'series',
        },
        data: [140, 232, 101, 264, 90, 340, 250],
      },
    ],
  };
  return <ReactECharts option={options} />;
}
