import { useNavigate } from 'react-router-dom';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CardSummaryAddAccount.css';

const CardSummaryAddAccount = ({ routeToNavigate }) => {
  const navigate = useNavigate();

  return (
    <div
      className="card-summary-account-add card-summary-account"
      onClick={() => {
        setCreatingAccount();
        navigate(routeToNavigate);
      }}
    >
      <div className="card-summary-add-btn-container">
        <div className="card-summary-image-icon-container">
          <div className="card-summary-add-circle">
            <FontAwesomeIcon icon={faAdd} className="image-icon" />
          </div>
        </div>
        <div className="card-summary-image-icon-text">
          <span>Agregar Cuenta</span>
          <p>
            Ahora puedes crear otra solicitud para otra empresa en la que eres
            el contacto principal. Dicha solicitud inicia un proceso de debida
            diligencia.
          </p>
        </div>
      </div>
    </div>
  );
};
const setCreatingAccount = () => {
  localStorage.setItem('creatingAccount', true);
};
export default CardSummaryAddAccount;
