import Navbar from '../../../components/ClientePotencial/Navbar/Navbar';
import Sidebar from '../../../components/ClientePotencial/Sidebar/Sidebar';
import './RealDashboard.css';
import ProfessionalClientPage from './../FormPages/Physical person/ProfessionalClientPage/ProfessionalClientPage';
import { Outlet, Route, Router, Routes } from 'react-router-dom';
import ProfessionalClientForm from './../../../components/ClientePotencial/forms/Physical person/ProfessionalClientForm/ProfessionalClientForm';
import Realistic from '../../../shared/UIElements/Confetti/confetti';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setConfetti } from '../../../shared/store/slices/clientePotencial';

const RealDashboard = () => {
  const { confetti } = useSelector((state) => state.clientePotencialSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    if (confetti) {
      setTimeout(() => {
        dispatch(setConfetti(false));
      }, 7000);
    }
  }, [confetti]);

  return (
    <div className="flex">
      {confetti && <Realistic />}
      <Sidebar />
      <div className="w-100">
        {/* <Navbar /> */}
        <Outlet />
      </div>
    </div>
  );
};

export default RealDashboard;
