/* eslint-disable no-useless-escape */

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_SETTLEMENT_INSTRUCTIONS,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  VALIDATE_FORM,
  GET_SETTLEMENT_INSTRUCTION_SECTION,
} from '../../../../../shared/graphQL/queries';
import {
  SAVE_SETTLEMENT_INSTRUCTION_SECTION,
} from '../../../../../shared/graphQL/mutations';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { useSelector } from 'react-redux';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';

const SettlementInstructionForm = () => {
    const {
      register,
      formState: { errors },
      watch,
      handleSubmit,
      setValue,
    } = useForm();
    const navigate = useNavigate();
    const [formResponseId, setFormResponseId] = useState('');
    const { data: settlementInstructions } = useQuery(GET_SETTLEMENT_INSTRUCTIONS);
    const [getSettlementInstructionSectionData, settlementInstructionSectionData] = useLazyQuery(
      GET_SETTLEMENT_INSTRUCTION_SECTION,
      {
        fetchPolicy: 'network-only',
      }
    );
    const [getFormResponseByIdentification, formResponseByIdentification] =
      useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
    const [saveSettlementInstructionSection] = useMutation(
      SAVE_SETTLEMENT_INSTRUCTION_SECTION,
      {
        refetchQueries: [
          {
            query: GET_SETTLEMENT_INSTRUCTION_SECTION,
            variables: {
              formResponseId: formResponseId,
            },
          },
          {
            query: VALIDATE_FORM,
            variables: {
              formResponseId: formResponseId,
            },
          },
        ],
      }
    );
    const [selectedFormResponse, setSelectedFormResponse] = useState({});
    const { isFormCompleted, isFormDisqualified, validation } = useSelector(
      (state) => state.clientePotencialSlice
    );
    const [loadingSection, setLoadingSection] = useState(true);
    const [validationErrors, setValidationErrors] = useState([]);
  
    const titularCuenta = watch('titularCuenta');
    const numeroCuenta = watch('numeroCuenta');
    const instruccionLiquidacion = watch('instruccionLiquidacion');
  
    useEffect(() => {
      setLoadingSection(true);
      setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
    }, []);
  
    useEffect(() => {
      if (selectedFormResponse?.lead?.identification) {
        if (
          Object.keys(selectedFormResponse).length > 0 &&
          selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
        ) {
          navigate(-1);
        }
  
        setValue('rnc', selectedFormResponse?.lead?.identification);
  
        getFormResponseByIdentification({
          variables: {
            pagination: {
              filter: selectedFormResponse?.lead?.identification,
            },
          },
        });
      }
    }, [selectedFormResponse]);
  
    useEffect(() => {
      if (formResponseByIdentification?.data?.formResponses?.length > 0) {
        setFormResponseId(
          formResponseByIdentification?.data?.formResponses[0]?.id
        );
  
        getSettlementInstructionSectionData({
          variables: {
            formResponseId:
              formResponseByIdentification?.data?.formResponses[0]?.id,
          },
        });
      }
    }, [formResponseByIdentification]);
  
    useEffect(() => {
      if (settlementInstructionSectionData?.data) {
        getFormInfo();
      }
    }, [settlementInstructionSectionData]);

    const getFormInfo = async () => {
      console.log(settlementInstructionSectionData?.data?.settlementInstructionSection)
      if (settlementInstructionSectionData?.data?.settlementInstructionSection) {
        setValue(
          'titularCuenta',
          settlementInstructionSectionData?.data?.settlementInstructionSection
            ?.accountHolder
        );
        setValue(
          'numeroCuenta',
          settlementInstructionSectionData?.data?.settlementInstructionSection
            ?.accountNumber
        );
        if (
          settlementInstructionSectionData?.data?.settlementInstructionSection?.settlementInstruction
            ?.id
        ) {
          setValue(
            'instruccionLiquidacion',
            settlementInstructionSectionData?.data?.settlementInstructionSection?.settlementInstruction
              ?.id
          );
        }
      }

      setLoadingSection(false);
    };
  
    const saveData = () => {
      if (isFormCompleted || isFormDisqualified) {
        navigate(
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/representante-legal'
        );
        return;
      }
  
      let data = {};
  
      // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
      if (!settlementInstructionSectionData?.data?.settlementInstructionSection?.id) {
        // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          accountHolder: titularCuenta,
          accountNumber: numeroCuenta,
          settlementInstructionId: instruccionLiquidacion,
        };
      } else {
        // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: settlementInstructionSectionData.data.settlementInstructionSection?.id,
          accountHolder: titularCuenta,
          accountNumber: numeroCuenta,
          settlementInstructionId: instruccionLiquidacion,
        };
      }
  
      saveSettlementInstructionSection({
        variables: {
          settlementInstructionSection: data,
        },
      });

      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/representante-legal'
      );
    };

    const handleBackNavigation = () => {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-general'
      );
    };

    useEffect(() => {
      const complexSectionsErrors = [];
      for (const key in validation) {
        if (key !== 'clientErrors' && key !== 'isValid' && key !== '__typename') {
          complexSectionsErrors.push(
            ...validation[key]?.map((validation) => validation.fieldId)
          );
        }
      }
      setValidationErrors(complexSectionsErrors);
    }, [validation]);
  
    const hasError = (field) => {
      return validationErrors?.includes(field);
    };
  
    const isSectionError = () => {
      return fieldsByComplexSections.Generales.some((flied) =>
        validationErrors?.includes(flied)
      );
    };
  
    return (
      <>
        <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
          <div
            className="row fields-container"
            style={{ height: loadingSection ? '85%' : 'auto' }}
          >
            <h3 className="formTitle">
              Instrucción de Liquidación
              <ModalInfo
                Icon={<FontAwesomeIcon icon={faCircleInfo} />}
                title={'Instrucción de Liquidación'}
                description={
                  "El formulario 'Instrucción de Liquidación' proporciona un espacio centralizado para capturar y gestionar las directrices necesarias para la liquidación de transacciones financieras. En esta sección, se recopilan datos cruciales como los detalles de la cuenta e instrucciones específicas de liquidación. El objetivo de este formulario es asegurar que todas las transacciones se procesen de manera precisa y eficiente, cumpliendo con los requisitos operativos y financieros de la empresa."
                }
              />
              {isSectionError() && (
                <span style={{ marginLeft: 30 }}>
                  <ModalInfo
                    color="white"
                    bgcolor="#B90E0A"
                    Icon={
                      <Error
                        style={{
                          color: '#B90E0A',
                          fontSize: 25,
                          marginTop: '-5px',
                        }}
                      />
                    }
                    description={'Hay campos incompletos en esta sección'}
                  />
                </span>
              )}
            </h3>
            {loadingSection ? (
              <div
                className="w-100 d-flex align-items-center justify-content-center p-5"
                style={{ height: '100%' }}
              >
                <CircularProgress color="warning" />
              </div>
            ) : (
              <div className="row">  
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="instruccion-liquidacion" className="label-field">
                  Instrucción de liquidación <span>*</span>
                  </label>
                  <div className="selectWrapper">
                    <select
                      id="instruccion-liquidacion"
                      disabled={isFormCompleted || isFormDisqualified}
                      defaultValue={'DEFAULT'}
                      {...register('instruccionLiquidacion', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {settlementInstructions?.settlementInstructionList?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.instruccionLiquidacion && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                  {(!instruccionLiquidacion || instruccionLiquidacion === 'DEFAULT') &&
                    !errors.instruccionLiquidacion && (
                      <ErrorText hasError={hasError('settlementInstruction')} />
                    )}
                </div>
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="titular-cuenta" className="label-field">
                    Titular de cuenta <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="titular-cuenta"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('titularCuenta', {
                      required: true,
                    })}
                  />
                  {errors.titularCuenta?.type === 'required' && (
                    <p className="text-danger">
                      El campo Titular de cuenta es requerido
                    </p>
                  )}
                  {!titularCuenta && errors.titularCuenta?.type !== 'required' && (
                    <ErrorText hasError={hasError('accountHolder')} />
                  )}
                </div>
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="numero-cuenta" className="label-field">
                    Número de cuenta <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="numero-cuenta"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('numeroCuenta', {
                      required: true,
                    })}
                  />
                  {errors.numeroCuenta?.type === 'required' && (
                    <p className="text-danger">
                      El campo Número de cuenta es requerido
                    </p>
                  )}
                  {!numeroCuenta && errors.numeroCuenta?.type !== 'required' && (
                    <ErrorText hasError={hasError('accountNumber')} />
                  )}
                </div>
              </div>
            )}
          </div>
  
          <div className="buttons-control-container">
            <button
              type="submit"
              className="btn-back"
              onClick={handleBackNavigation}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              &#160; Atrás
            </button>

            <button
              type="submit"
              disabled={loadingSection}
              className={loadingSection ? 'button-disabled' : 'btn-continue'}
            >
              Siguiente &#160;
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </form>
      </>
    );
  };
  
  export default SettlementInstructionForm;
  