import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import {
  deleteCookie,
  getCookie,
  handleClienteRealLogOut,
} from '../../../shared/utils/functions';
import ClienteRealDashboard from '../Dashboard/Dashboard';
import { useEffect } from 'react';
import ExpirationModal from '../../../components/ClienteReal/ExpirationModal/ExpirationModal';
import { CLIENTEREALROUTES } from '../../../shared/constants/app-constants';
import {
  azureUserFlowUri,
  COOKIENAMES,
} from '../../../shared/constants/gobal-constants';
import Inicio from '../Inicio/Inicio';
import TasaProductos from '../TasaProductos/TasaProductos';
import { useSelector } from 'react-redux';
import {
  setSessionAuth,
  setSessionModal,
} from '../../../shared/store/slices/clienteReal';
import { useDispatch } from 'react-redux';

const FATHERROUTE = CLIENTEREALROUTES.father;
const INDEX = CLIENTEREALROUTES.index;
export const CLIENTEREALORIGINROUTE = INDEX + FATHERROUTE + INDEX;

export default function ClienteRealIndex() {
  const navigate = useNavigate();
  const {
    session: { isAuth },
  } = useSelector((state) => state.clienteRealSlice);
  const dispatch = useDispatch();
  const redirectUri = azureUserFlowUri();

  useEffect(() => {
    const token = getCookie(COOKIENAMES.user);
    if (token && token.token) {
      setSessionVarables(
        true,
        CLIENTEREALROUTES.protectedRoutes.dashboard.self +
          INDEX +
          CLIENTEREALROUTES.protectedRoutes.dashboard.children.index
      );
    } else {
      if (!window.location.pathname.includes(redirectUri)) {
        setSessionVarables(false, redirectUri);
      }
    }

    setInterval(() => {
      const cookieToken = getCookie(COOKIENAMES.user);
      if (
        (!cookieToken || !cookieToken.token) &&
        !window.location.pathname.includes(redirectUri) &&
        !window.location.pathname.includes('cliente-potencial')
      ) {
        deleteCookie(COOKIENAMES.profile);
        dispatch(setSessionModal({ showSessionModal: true }));
      }
    }, 1000);
  }, []);

  const handleModalClick = () => {
    handleClienteRealLogOut();
  };

  const setSessionVarables = (auth, nav) => {
    dispatch(setSessionAuth(auth));
    if (auth) {
      navigate(nav);
    } else {
      window.location.replace(nav);
    }
  };

  return (
    <>
      <ExpirationModal event={handleModalClick} />
      <Routes>
        {!isAuth ? (
          <>
            <Route path={CLIENTEREALROUTES.index} />
          </>
        ) : (
          <>
            {/* These are the protected routes for cliente real*/}
            <Route
              path={CLIENTEREALROUTES.protectedRoutes.dashboard.self}
              element={<ClienteRealDashboard />}
            >
              <Route
                index
                path={
                  CLIENTEREALROUTES.protectedRoutes.dashboard.children.index
                }
                element={<Inicio />}
              />
              <Route
                path={
                  CLIENTEREALROUTES.protectedRoutes.dashboard.children.index
                }
                element={<Inicio />}
              />
              <Route
                path={
                  CLIENTEREALROUTES.protectedRoutes.dashboard.children
                    .rateProducts
                }
                element={<TasaProductos />}
              />
              <Route
                path="*"
                element={
                  <Navigate
                    to={
                      CLIENTEREALROUTES.protectedRoutes.dashboard.children.index
                    }
                  />
                }
              />
            </Route>
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    CLIENTEREALROUTES.protectedRoutes.dashboard.self +
                    '/' +
                    CLIENTEREALROUTES.protectedRoutes.dashboard.children.index
                  }
                />
              }
            />
          </>
        )}
      </Routes>
    </>
  );
}
