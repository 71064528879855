import './LegalRepresentative.css';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import {
  REMOVE_LEGAL_REPRESENTATIVE,
  SAVE_LEGAL_REPRESENTATIVES_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_CIVIL_STATUS,
  GET_COUNTRIES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_IDENTITYTYPES,
  GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
  GET_LEGAL_REPRESENTATIVES_TYPES,
  GET_POLITIC_EXPOSURE_TYPES,
  GET_PROVINCES_BY_COUNTRY,
  GET_SECTORS_BY_PROVINCE,
  GET_GENDER_TYPES,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import {
  CHECK_POLITIC_EXPOSURE,
  CHECK_LEXIS_NEXIS,
  SEND_LEGAL_REPRESENTATIVE_IDENTIFICATION,
} from './../../../../../shared/graphQL/mutations';
import {
  convertBooleanToInput,
  convertInputToBoolean,
  formatSimple,
  mergeObjectsWithSameId,
  parseSimple,
  preFormat,
  validateInputCedula,
  validateInputRnc,
} from '../../../../../shared/utils/functions';
import { useDispatch } from 'react-redux';
import { loadPersons } from '../../../../../shared/store/slices/clientePotencial';
import { toast } from 'react-toastify';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const LegalRepresentative = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const { data: identificationTypeList } = useQuery(GET_IDENTITYTYPES);
  const { data: civilStatusList } = useQuery(GET_CIVIL_STATUS);
  const { data: legalRepresentativeTypes } = useQuery(
    GET_LEGAL_REPRESENTATIVES_TYPES
  );
  const { data: politicExposureTypes } = useQuery(GET_POLITIC_EXPOSURE_TYPES);
  const { data: genderTypes } = useQuery(GET_GENDER_TYPES);
  const [checkLexisNexis] = useMutation(CHECK_LEXIS_NEXIS);
  const { data: countries } = useQuery(GET_COUNTRIES);
  const [getProvincesByCountry, provincesByCountry] = useLazyQuery(
    GET_PROVINCES_BY_COUNTRY
  );
  const [getSectorsByProvince, sectorsByProvince] = useLazyQuery(
    GET_SECTORS_BY_PROVINCE
  );
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [formResponseId, setFormResponseId] = useState('');
  const [notCheck, setNotCheck] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [saveLegalRepresentativesSection] = useMutation(
    SAVE_LEGAL_REPRESENTATIVES_SECTION,
    {
      refetchQueries: [
        {
          query: GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [sendVerification] = useMutation(
    SEND_LEGAL_REPRESENTATIVE_IDENTIFICATION,
    {
      refetchQueries: [
        {
          query: GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [removeLegalRepresentative] = useMutation(REMOVE_LEGAL_REPRESENTATIVE, {
    refetchQueries: [
      {
        query: GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [
    checkPoliticExposure,
    { data: checkPoliticExposureResponse, error: checkPoliticExposureError },
  ] = useMutation(CHECK_POLITIC_EXPOSURE);
  const [getLegalRepresentativesSectionData, legalRepresentativesSectionData] =
    useLazyQuery(GET_LEGAL_REPRESENTATIVES_SECTION_DATA, {
      fetchPolicy: 'network-only',
    });
  const [legalRepresentativesSectionId, setLegalRepresentativesSectionId] =
    useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [selectedRepresentativeId, setSelectedRepresentativeId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [politicExposureId, setPoliticExposureId] = useState('');
  const [fatcaId, setFatcaId] = useState('');
  const [addressId, setAddressId] = useState('');
  const [correspondenceEmailId, setCorrespondenceEmailId] = useState('');
  const [correspondenceEmailTypeId, setCorrespondenceEmailTypeId] = useState('');
  const [addressTypeId, setAddressTypeId] = useState('');
  const [existingLegalRepresentative, setExistingLegalRepresentative] =
    useState(false);
  const dispatch = useDispatch();
  const [validationErrors, setValidationErrors] = useState([]);
  const { persons, isFormCompleted, isFormDisqualified, validation, isContrapart, isForeign } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const [loadingSection, setLoadingSection] = useState(true);
  const [contacts, setContacts] = useState([]);

  const nombres = watch('nombres');
  const numeroDoc = watch('numeroDoc');
  const posicionEmpresa = watch('posicionEmpresa');
  const apellidos = watch('apellidos');
  const estadoCivil = watch('estadoCivil');
  const correoElectronico = watch('correoElectronico');
  const tipoDocumento = watch('tipoDocumento');
  const tipoRepresentante = watch('tipoRepresentante');
  const sexo = watch('sexo');
  const fechaNacimiento = watch('fechaNacimiento');
  const [paisNacionalidad, setPaisNacionalidad] = useState(null);
  const [inputPaisNacionalidad, setInputPaisNacionalidad] = useState('');

  // Direccion

  const [pais, setPais] = useState(null);
  const [inputPais, setInputPais] = useState('');
  const [provincia, setProvincia] = useState(null);
  const [inputProvincia, setInputProvincia] = useState('');
  const [sector, setSector] = useState(null);
  const [inputSector, setInputSector] = useState('');
  const calle = watch('calle');
  const residencial = watch('residencial');
  const numeroApartamento = watch('numeroApartamento');
  const casaNumero = watch('casaNumero');
  const zipCode = watch('zipCode');

  // PEP

  const personaPoliticamenteExpuesta = watch('personaPoliticamenteExpuesta');
  const cargoPep = watch('cargoPep');
  const institucion = watch('institucion');
  const fechaInicioCargo = watch('fechaInicioCargo');
  const ultimaFechaCargo = watch('ultimaFechaCargo');
  const tipoExposicionPolitica = watch('tipoExposicionPolitica');
  const nombreDelFamiliar = watch('nombreDelFamiliar');
  const tipoRelacionFamiliar = watch('tipoRelacionFamiliar');
  const pepActivo = watch('pepActivo');

  // FATCA

  const tieneNacionalidadEU = watch('tieneNacionalidadEU');
  const esCiudadanoEU = watch('esCiudadanoEU');
  const esResidenteEU = watch('esResidenteEU');
  const seguroSocial = watch('seguroSocial');
  const codigoPostalEU = watch('codigoPostalEU');
  const telefonoEU = watch('telefonoEU');
  const tieneRepresentanteEU = watch('tieneRepresentanteEU');
  const tieneTIN = watch('tieneTIN');
  const tin = watch('tin');
  const tarjetaVerde = watch('tarjetaVerde');
  const direccionEU = watch('direccionEU');

  useEffect(() => {
    if (
      typeof numeroDoc === 'string' &&
      getIdentificationTypeName(tipoDocumento) ===
      'Cedula de Identidad y Electoral' &&
      !notCheck
    ) {
      if (numeroDoc.length > 12) {
        verifiyIfIsPep();
      } else {
        setValue('personaPoliticamenteExpuesta', 'No');
      }
    }
  }, [numeroDoc, notCheck]);

  useEffect(() => {
    if (
      getIdentificationTypeName(tipoDocumento) !==
      'Cedula de Identidad y Electoral' &&
      personaPoliticamenteExpuesta === 'Si' &&
      !notCheck
    ) {
      setValue('personaPoliticamenteExpuesta', 'No');
      setValue('pepActivo', 'No');
      setValue('cargoPep', null);
      setValue('institucion', null);
      setValue('fechaInicioCargo', null);
      setValue('ultimaFechaCargo', null);
      setValue('tipoExposicionPolitica', 'DEFAULT');
      setValue('nombreDelFamiliar', null);
      setValue('tipoRelacionFamiliar', null);
    }
  }, [tipoDocumento, notCheck]);

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );
      getLegalRepresentativesSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (legalRepresentativesSectionData) {
      setLegalRepresentativesSectionId(
        legalRepresentativesSectionData?.data?.legalRepresentativesSection?.id
      );
    }
  }, [legalRepresentativesSectionData]);

  useEffect(() => {
    if (pais) {
      getProvincesByCountry({
        variables: {
          countryId: pais?.id,
        },
      });
    }
  }, [pais]);

  useEffect(() => {
    if (provincesByCountry?.data?.provincesByCountry.length > 0) {
      getSectorsByProvince({
        variables: {
          provinceId: provincia?.id,
        },
      });
    }
  }, [provincesByCountry, provincia]);

  useEffect(() => {
    if (checkPoliticExposureResponse) {
      setValue(
        'personaPoliticamenteExpuesta',
        convertBooleanToInput(
          checkPoliticExposureResponse.checkPoliticExposure.isPEP
        )
      );
      if (checkPoliticExposureResponse.checkPoliticExposure.isPEP === true) {
        setValue(
          'cargoPep',
          checkPoliticExposureResponse.checkPoliticExposure.charge
        );
        setValue(
          'tipoExposicionPolitica',
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .id
        );
        if (
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .name === 'Familiar'
        ) {
          setValue(
            'nombreDelFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure.relativeName
          );
          setValue(
            'tipoRelacionFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure
              .relativeRelationship
          );
        }
        toast('Esta es una Persona Politicamente Expuesta', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        toast('Esta no es una Persona Politicamente Expuesta', {
          type: 'success',
          theme: 'colored',
        });
      }
    }
  }, [checkPoliticExposureResponse]);

  useEffect(() => {
    if (checkPoliticExposureError?.message) {
      toast(checkPoliticExposureError.message, {
        type: 'error',
        theme: 'colored',
      });
    }
  }, [checkPoliticExposureError]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedRepresentativeId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const handleBackNavigation = () => {
    if (isContrapart && isForeign) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/instruccion-de-liquidacion'
      );
    }
    else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-general'
      );
    }
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-contacto-principal'
    );
  };

  const verifiyIfIsPep = () => {
    checkPoliticExposure({
      variables: {
        identification: numeroDoc,
      },
    });
  };

  const addLegalRepresentative = async () => {
    const emailIsInUse =
      legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives?.find(
        (representative) =>
          representative.id !== selectedRepresentativeId &&
          correoElectronico === representative?.correspondenceEmail?.email
      );

    if (emailIsInUse) {
      toast('Ya existe un representante legal con este correo', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    const identificationIsInUse =
      legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives?.find(
        (representative) =>
          representative.id !== selectedRepresentativeId &&
          numeroDoc === representative.identification &&
          representative.identificationType.id === tipoDocumento
      );

    if (identificationIsInUse) {
      toast('Ya existe un representante legal con esta identificación', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    const legalRepresentativeTypeSelected =
      legalRepresentativeTypes?.legalRepresentativeTypes?.find(
        (x) => x.id === tipoRepresentante
      );
    const legalRepresentativeSaved =
      legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives?.find(
        (x) => x.id === selectedRepresentativeId
      );
    const isUpdatingLegalRepresentative =
      legalRepresentativeTypeSelected?.id ===
      legalRepresentativeSaved?.legalRepresentativeType?.id;

    let alreadyPrincipalLegalRepresentative = false;

    if (
      legalRepresentativesSectionData?.data?.legalRepresentativesSection
        ?.legalRepresentatives
    ) {
      alreadyPrincipalLegalRepresentative =
        legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives?.some(
          (x) => x.legalRepresentativeType?.name === 'Principal'
        );
    }

    setSaveLoading(true);

    if (
      (legalRepresentativeTypeSelected?.name !== 'Principal' &&
        alreadyPrincipalLegalRepresentative === true) ||
      (legalRepresentativeTypeSelected?.name === 'Principal' &&
        alreadyPrincipalLegalRepresentative === false) ||
      (legalRepresentativeTypeSelected?.name !== 'Principal' &&
        alreadyPrincipalLegalRepresentative === false) ||
      isUpdatingLegalRepresentative
    ) {
      let data = {};

      //Si legalRepresentativesSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
      if (legalRepresentativesSectionId) {
        // Si selectedRepresentativeId tiene valor entonces se edita en lugar de agregar
        if (selectedRepresentativeId) {
          let response;
          try {
            response = await checkLexisNexis({
              variables: {
                lexisNexisVerifyInputType: {
                  shareholderId: '',
                  legalRepresentativeId: '',
                  document_id_number: numeroDoc,
                  address: '',
                  entity_type: 'INDIVIDUAL',
                  city: getProvinceName(provincia?.id),
                  last_name: apellidos,
                  first_name: nombres,
                },
              },
            });
          } catch (error) {
            console.error(error);
          }

          data = {
            formResponseId:
              formResponseByIdentification?.data?.formResponses[0]?.id,
            id: legalRepresentativesSectionId,
            legalRepresentativeInputTypes: {
              id: selectedRepresentativeId,
              civilStatusId: estadoCivil,
              correspondenceEmailInputType: {
                id: correspondenceEmailId,
                email: correoElectronico,
                correspondenceEmailTypeId: correspondenceEmailTypeId,
              },
              firstName: nombres,
              identification: numeroDoc,
              identificationTypeId: tipoDocumento,
              lastName: apellidos,
              nationalityCountryId: paisNacionalidad?.id,
              position: posicionEmpresa,
              isInDarkList: response?.data?.checkLexisNexis?.isInDarkList,
              legalRepresentativeTypeId: tipoRepresentante,
              genderId: sexo,
              birthDate: parseSimple(preFormat(fechaNacimiento)),
              politicExposureInputType:
                personaPoliticamenteExpuesta === 'Si'
                  ? {
                    id: politicExposureId ? politicExposureId : null,
                    isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                    active: convertInputToBoolean(pepActivo),
                    charge: cargoPep,
                    relativeName: nombreDelFamiliar,
                    relativeRelationship: tipoRelacionFamiliar,
                    politicExposureTypeId: tipoExposicionPolitica,
                    chargeStartDate: fechaInicioCargo
                      ? parseSimple(preFormat(fechaInicioCargo))
                      : null,
                    chargeEndDate: ultimaFechaCargo
                      ? parseSimple(preFormat(ultimaFechaCargo))
                      : null,
                    institution: institucion,
                  }
                  : {},
              addressInput: {
                addressTypeId: addressTypeId,
                countryId: pais?.id,
                provinceId: provincia?.id,
                sectorId: sector?.id,
                street: calle,
                houseNumber: casaNumero,
                building: residencial,
                apartmentNumber: numeroApartamento,
                zipCode: zipCode,
                id: addressId,
              },
              contactFatcaInputType: {
                id: fatcaId,
                haveUnitedStatesNationality:
                  convertInputToBoolean(tieneNacionalidadEU),
                isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                socialSecurityNumber: seguroSocial,
                zipInUnitedStates: codigoPostalEU,
                phoneInUnitedStates: telefonoEU,
                haveRepresentativeInUnitedStates:
                  convertInputToBoolean(tieneRepresentanteEU),
                haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                taxIdentificationNumber: tin,
                greenCard: tarjetaVerde,
                addressInUnitedStates: direccionEU,
              },
            },
          };

          setSelectedRepresentativeId('');
        } else {
          let response;
          try {
            response = await checkLexisNexis({
              variables: {
                lexisNexisVerifyInputType: {
                  shareholderId: '',
                  legalRepresentativeId: '',
                  document_id_number: numeroDoc,
                  address: '',
                  entity_type: 'INDIVIDUAL',
                  city: getProvinceName(provincia?.id),
                  last_name: apellidos,
                  first_name: nombres,
                },
              },
            });
          } catch (error) {
            console.error(error);
          }

          data = {
            formResponseId:
              formResponseByIdentification?.data?.formResponses[0]?.id,
            id: legalRepresentativesSectionId,
            legalRepresentativeInputTypes: {
              id: v4(),
              civilStatusId: estadoCivil,
              correspondenceEmailInputType: {
                id: v4(),
                email: correoElectronico,
              },
              firstName: nombres,
              identification: numeroDoc,
              identificationTypeId: tipoDocumento,
              lastName: apellidos,
              nationalityCountryId: paisNacionalidad?.id,
              position: posicionEmpresa,
              isInDarkList: response?.data?.checkLexisNexis?.isInDarkList,
              legalRepresentativeTypeId: tipoRepresentante,
              genderId: sexo,
              birthDate: parseSimple(preFormat(fechaNacimiento)),
              politicExposureInputType:
                personaPoliticamenteExpuesta === 'Si'
                  ? {
                    isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                    active: convertInputToBoolean(pepActivo),
                    charge: cargoPep,
                    relativeName: nombreDelFamiliar,
                    relativeRelationship: tipoRelacionFamiliar,
                    politicExposureTypeId: tipoExposicionPolitica,
                    chargeStartDate: fechaInicioCargo
                      ? parseSimple(preFormat(fechaInicioCargo))
                      : null,
                    chargeEndDate: ultimaFechaCargo
                      ? parseSimple(preFormat(ultimaFechaCargo))
                      : null,
                    institution: institucion,
                  }
                  : {},
              addressInput: {
                countryId: pais?.id,
                provinceId: provincia?.id,
                sectorId: sector?.id,
                street: calle,
                houseNumber: casaNumero,
                building: residencial,
                apartmentNumber: numeroApartamento,
                zipCode: zipCode,
              },
              contactFatcaInputType: {
                haveUnitedStatesNationality:
                  convertInputToBoolean(tieneNacionalidadEU),
                isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                socialSecurityNumber: seguroSocial,
                zipInUnitedStates: codigoPostalEU,
                phoneInUnitedStates: telefonoEU,
                haveRepresentativeInUnitedStates:
                  convertInputToBoolean(tieneRepresentanteEU),
                haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                taxIdentificationNumber: tin,
                greenCard: tarjetaVerde,
                addressInUnitedStates: direccionEU,
              },
            },
          };
        }
      } else {
        let response;
        try {
          response = await checkLexisNexis({
            variables: {
              lexisNexisVerifyInputType: {
                shareholderId: '',
                legalRepresentativeId: '',
                document_id_number: numeroDoc,
                address: '',
                entity_type: 'INDIVIDUAL',
                city: getProvinceName(provincia?.id),
                last_name: apellidos,
                first_name: nombres,
              },
            },
          });
        } catch (error) {
          console.error(error);
        }

        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          legalRepresentativeInputTypes: {
            id: v4(),
            civilStatusId: estadoCivil,
            correspondenceEmailInputType: {
              id: v4(),
              email: correoElectronico,
            },
            firstName: nombres,
            identification: numeroDoc,
            identificationTypeId: tipoDocumento,
            lastName: apellidos,
            nationalityCountryId: paisNacionalidad?.id,
            position: posicionEmpresa,
            isInDarkList: response?.data?.checkLexisNexis?.isInDarkList,
            legalRepresentativeTypeId: tipoRepresentante,
            genderId: sexo,
            birthDate: parseSimple(preFormat(fechaNacimiento)),
            politicExposureInputType:
              personaPoliticamenteExpuesta === 'Si'
                ? {
                  isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                  active: convertInputToBoolean(pepActivo),
                  charge: cargoPep,
                  relativeName: nombreDelFamiliar,
                  relativeRelationship: tipoRelacionFamiliar,
                  politicExposureTypeId: tipoExposicionPolitica,
                  chargeStartDate: fechaInicioCargo
                    ? parseSimple(preFormat(fechaInicioCargo))
                    : null,
                  chargeEndDate: ultimaFechaCargo
                    ? parseSimple(preFormat(ultimaFechaCargo))
                    : null,
                  institution: institucion,
                }
                : {},
            addressInput: {
              countryId: pais?.id,
              provinceId: provincia?.id,
              sectorId: sector?.id,
              street: calle,
              houseNumber: casaNumero,
              building: residencial,
              apartmentNumber: numeroApartamento,
              zipCode: zipCode,
            },
            contactFatcaInputType: {
              haveUnitedStatesNationality:
                convertInputToBoolean(tieneNacionalidadEU),
              isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
              isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
              socialSecurityNumber: seguroSocial,
              zipInUnitedStates: codigoPostalEU,
              phoneInUnitedStates: telefonoEU,
              haveRepresentativeInUnitedStates:
                convertInputToBoolean(tieneRepresentanteEU),
              haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
              taxIdentificationNumber: tin,
              greenCard: tarjetaVerde,
              addressInUnitedStates: direccionEU,
            },
          },
        };
      }

      const saveData = await saveLegalRepresentativesSection({
        variables: {
          legalRepresentativesSection: data,
        },
      });

      loadPersons(dispatch, {
        legalRepresentatives:
          saveData.data?.saveLegalRepresentativesSection
            ?.legalRepresentatives ?? [],
        shareholders: persons.shareholders,
        signatores: persons.signatores,
        beneficiaries: persons.beneficiaries,
        counselMembers: persons.counselMembers,
      });

      setIsUpdating(false);
    } else {
      toast('Solo se puede agregar un representante legal de tipo Principal.', {
        type: 'error',
        theme: 'colored',
      });
    }
    setIsUpdating(false);
    setSaveLoading(false);

    btnCloseModalRef.current.click();

    resetAllInputs();
  };

  const deleteCardElement = async (id) => {
    await removeLegalRepresentative({
      variables: {
        legalRepresentativeId: id,
      },
    });

    resetAllInputs();

    loadPersons(dispatch, {
      legalRepresentatives:
        persons.legalRepresentatives.filter((legal) => legal.id !== id) ?? [],
      shareholders: persons.shareholders,
      signatores: persons.signatores,
      beneficiaries: persons.beneficiaries,
      counselMembers: persons.counselMembers,
    });
  };

  const handleEdit = (id) => {
    setNotCheck(true);
    setSelectedRepresentativeId(id);
    const selectedRepresentative =
      legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives?.find(
        (x) => x?.id === id
      );

    if (id && selectedRepresentative) {
      setForm(selectedRepresentative);
    }
  };

  const setForm = (selectedRepresentative) => {
    setFatcaId(selectedRepresentative?.contactFatca?.id);
    setCorrespondenceEmailId(selectedRepresentative?.correspondenceEmail?.id);
    setCorrespondenceEmailTypeId(
      selectedRepresentative?.correspondenceEmail?.correspondenceEmailType?.id
    );
    selectedRepresentative?.firstName &&
      setValue('nombres', selectedRepresentative?.firstName);
    selectedRepresentative?.identification &&
      setValue('numeroDoc', selectedRepresentative?.identification);
    selectedRepresentative?.position &&
      setValue('posicionEmpresa', selectedRepresentative?.position);
    selectedRepresentative?.lastName &&
      setValue('apellidos', selectedRepresentative?.lastName);
    selectedRepresentative?.civilStatus?.id &&
      setValue('estadoCivil', selectedRepresentative?.civilStatus?.id);
    (selectedRepresentative?.correspondenceEmail?.email || selectedRepresentative?.email) &&
      setValue('correoElectronico', 
        selectedRepresentative?.correspondenceEmail?.email || selectedRepresentative?.email
      );
    if (selectedRepresentative?.nationalityCountry?.id) {
      setValue('paisNacionalidad', {
        label: selectedRepresentative?.nationalityCountry?.name,
        id: selectedRepresentative?.nationalityCountry?.id,
      });
      setPaisNacionalidad({
        label: selectedRepresentative?.nationalityCountry?.name,
        id: selectedRepresentative?.nationalityCountry?.id,
      });
    }

    selectedRepresentative?.identificationType?.id &&
      setValue('tipoDocumento', selectedRepresentative?.identificationType?.id);
    selectedRepresentative?.gender?.id &&
      setValue('sexo', selectedRepresentative?.gender?.id);
    selectedRepresentative?.birthDate &&
      setValue(
        'fechaNacimiento',
        selectedRepresentative?.birthDate
          ? formatSimple(new Date(selectedRepresentative?.birthDate))
          : null
      );
    selectedRepresentative?.legalRepresentativeType?.id &&
      setValue(
        'tipoRepresentante',
        selectedRepresentative?.legalRepresentativeType?.id
      );

    selectedRepresentative?.politicExposure?.id &&
      setValue(
        'personaPoliticamenteExpuesta',
        selectedRepresentative?.politicExposure?.isPep ? 'Si' : 'No'
      );
    selectedRepresentative?.politicExposure?.id &&
      setPoliticExposureId(selectedRepresentative?.politicExposure?.id);
    selectedRepresentative?.address?.id &&
      setAddressId(selectedRepresentative?.address?.id);
    selectedRepresentative?.address?.addressType?.id &&
      setAddressTypeId(selectedRepresentative?.address?.addressType?.id);

    if (selectedRepresentative?.address?.country?.id) {
      setValue('pais', {
        label: selectedRepresentative?.address?.country?.name,
        id: selectedRepresentative?.address?.country?.id,
      });
      setPais({
        label: selectedRepresentative?.address?.country?.name,
        id: selectedRepresentative?.address?.country?.id,
      });
    }
    if (selectedRepresentative?.address?.province?.id) {
      setValue('provincia', {
        label: selectedRepresentative?.address?.province?.name,
        id: selectedRepresentative?.address?.province?.id,
      });
      setProvincia({
        label: selectedRepresentative?.address?.province?.name,
        id: selectedRepresentative?.address?.province?.id,
      });
    }
    if (selectedRepresentative?.address?.sector?.id) {
      setValue('sector', {
        label: selectedRepresentative?.address?.sector?.name,
        id: selectedRepresentative?.address?.sector?.id,
      });
      setSector({
        label: selectedRepresentative?.address?.sector?.name,
        id: selectedRepresentative?.address?.sector?.id,
      });
    }
    selectedRepresentative?.address?.province?.id &&
      setValue('provincia', selectedRepresentative?.address?.province?.id);
    selectedRepresentative?.address?.sector?.id &&
      setValue('sector', selectedRepresentative?.address?.sector?.id);
    selectedRepresentative?.address?.street &&
      setValue('calle', selectedRepresentative?.address?.street);
    selectedRepresentative?.address?.building &&
      setValue('residencial', selectedRepresentative?.address?.building);
    selectedRepresentative?.address?.apartmentNumber &&
      setValue(
        'numeroApartamento',
        selectedRepresentative?.address?.apartmentNumber
      );
    selectedRepresentative?.address?.houseNumber &&
      setValue('casaNumero', selectedRepresentative?.address?.houseNumber);
    selectedRepresentative?.address?.zipCode &&
      setValue('zipCode', selectedRepresentative?.address?.zipCode);

    if (
      selectedRepresentative?.politicExposure?.id &&
      selectedRepresentative?.politicExposure?.isPep
    ) {
      setValue(
        'pepActivo',
        selectedRepresentative?.politicExposure?.politicExposureDetails?.active
          ? 'Si'
          : 'No'
      );
      setValue(
        'cargoPep',
        selectedRepresentative?.politicExposure?.politicExposureDetails?.charge
      );
      setValue(
        'institucion',
        selectedRepresentative?.politicExposure?.politicExposureDetails
          ?.institution
      );
      setValue(
        'fechaInicioCargo',
        selectedRepresentative?.politicExposure?.politicExposureDetails
          ?.chargeStartDate
          ? formatSimple(
            new Date(
              selectedRepresentative?.politicExposure?.politicExposureDetails?.chargeStartDate
            )
          )
          : null
      );
      setValue(
        'ultimaFechaCargo',
        selectedRepresentative?.politicExposure?.politicExposureDetails
          ?.chargeEndDate
          ? formatSimple(
            new Date(
              selectedRepresentative?.politicExposure?.politicExposureDetails?.chargeEndDate
            )
          )
          : null
      );
      setValue(
        'tipoExposicionPolitica',
        selectedRepresentative?.politicExposure?.politicExposureDetails
          ?.politicExposureType?.id
      );
      setValue(
        'nombreDelFamiliar',
        selectedRepresentative?.politicExposure?.politicExposureDetails
          ?.relativeName
      );
      setValue(
        'tipoRelacionFamiliar',
        selectedRepresentative?.politicExposure?.politicExposureDetails
          ?.relativeRelationship
      );
    }

    if (selectedRepresentative?.contactFatca) {
      if (
        selectedRepresentative?.contactFatca?.haveUnitedStatesNationality !==
        null &&
        selectedRepresentative?.contactFatca?.haveUnitedStatesNationality !==
        undefined
      ) {
        setValue(
          'tieneNacionalidadEU',
          convertBooleanToInput(
            selectedRepresentative?.contactFatca?.haveUnitedStatesNationality
          )
        );
      }
      if (
        selectedRepresentative?.contactFatca?.isUnitedStatesCitizen !== null &&
        selectedRepresentative?.contactFatca?.isUnitedStatesCitizen !==
        undefined
      ) {
        setValue(
          'esCiudadanoEU',
          convertBooleanToInput(
            selectedRepresentative?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      if (
        selectedRepresentative?.contactFatca?.isUnitedStatesResident !== null &&
        selectedRepresentative?.contactFatca?.isUnitedStatesResident !==
        undefined
      ) {
        setValue(
          'esResidenteEU',
          convertBooleanToInput(
            selectedRepresentative?.contactFatca?.isUnitedStatesResident
          )
        );
      }
      if (
        selectedRepresentative?.contactFatca
          ?.haveRepresentativeInUnitedStates !== null &&
        selectedRepresentative?.contactFatca
          ?.haveRepresentativeInUnitedStates !== undefined
      ) {
        setValue(
          'tieneRepresentanteEU',
          convertBooleanToInput(
            selectedRepresentative?.contactFatca
              ?.haveRepresentativeInUnitedStates
          )
        );
      }
      if (
        selectedRepresentative?.contactFatca?.haveTaxIdentificationNumber !==
        null &&
        selectedRepresentative?.contactFatca?.haveTaxIdentificationNumber !==
        undefined
      ) {
        setValue(
          'tieneTIN',
          convertBooleanToInput(
            selectedRepresentative?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      selectedRepresentative?.contactFatca?.socialSecurityNumber &&
        setValue(
          'seguroSocial',
          selectedRepresentative?.contactFatca?.socialSecurityNumber
        );
      selectedRepresentative?.contactFatca?.zipInUnitedStates &&
        setValue(
          'codigoPostalEU',
          selectedRepresentative?.contactFatca?.zipInUnitedStates
        );
      selectedRepresentative?.contactFatca?.phoneInUnitedStates &&
        setValue(
          'telefonoEU',
          selectedRepresentative?.contactFatca?.phoneInUnitedStates
        );
      selectedRepresentative?.contactFatca?.taxIdentificationNumber &&
        setValue(
          'tin',
          selectedRepresentative?.contactFatca?.taxIdentificationNumber
        );
      selectedRepresentative?.contactFatca?.greenCard &&
        setValue(
          'tarjetaVerde',
          selectedRepresentative?.contactFatca?.greenCard
        );
      selectedRepresentative?.contactFatca?.addressInUnitedStates &&
        setValue(
          'direccionEU',
          selectedRepresentative?.contactFatca?.addressInUnitedStates
        );
    }
  };

  const getIdentificationTypeName = (tipoDocumento) => {
    const identificationType =
      identificationTypeList?.identificationTypeList?.find(
        (x) => x.id === tipoDocumento
      );
    return identificationType?.name;
  };

  const getPoliticExposureTypeName = (politicExposureTypeId) => {
    const politicExposureType =
      politicExposureTypes?.politicExposureTypes?.find(
        (x) => x.id === politicExposureTypeId
      );
    return politicExposureType?.name;
  };

  const getProvinceName = (provinceId) => {
    const province = provincesByCountry?.data?.provincesByCountry?.find(
      (province) => province.id === provinceId
    );
    return province?.name;
  };

  const copiarContacto = watch('copiarContacto');
  const contacto = watch('contacto');

  useEffect(() => {
    let shareholders = [];
    persons.shareholders.forEach((sh) => {
      if (sh.personType.name === 'Persona Física') {
        shareholders.push({
          id: sh.id,
          firstName: sh.shareholderPersonDetails.firstName ?? '',
          lastName: sh.shareholderPersonDetails.lastName ?? '',
          identificationType: sh.identificationType,
          identification: sh.identification,
          position: sh.shareholderPersonDetails.charge,
          isInDarkList: sh.isInDarkList,
          politicExposure: sh.shareholderPersonDetails.politicExposure,
          nationalityCountry: sh.country,
          contactFatca: sh.contactFatca,
        });
      }
    });

    const counselMembers = persons.counselMembers.map((counselMember) => ({
      id: counselMember.id,
      firstName: counselMember.firstName,
      lastName: counselMember.lastName,
      identificationType: counselMember.identificationType,
      identification: counselMember.identification,
      position: counselMember.position,
      nationalityCountry: counselMember.nationalityCountry,
      isInDarkList: counselMember.isInDarkList,
      politicExposure: counselMember.politicExposure,
      contactFatca: counselMember.contactFatca,
    }));

    const signatores = persons.signatores.map((sig) => ({
      id: sig.id,
      firstName: sig.firstName,
      lastName: sig.lastName,
      identification: sig.identification,
      position: sig.charge,
      email: sig.email,
    }));

    const beneficiaries = persons.beneficiaries.map((beneficiary) => ({
      id: beneficiary.id,
      firstName: beneficiary.firstName,
      lastName: beneficiary.lastName,
      email: beneficiary.email,
      identificationType: beneficiary.identificationType,
      identification: beneficiary.identificationNumber,
      position: beneficiary.occupation,
      politicExposure: beneficiary.politicExposure,
      contactFatca: beneficiary.contactFatca,
      address: beneficiary.address,
    }));

    let shareholdersBeneficiaries = [];

    persons.shareholders.forEach((sh) => {
      sh.beneficiaries.forEach((beneficiary) =>
        shareholdersBeneficiaries.push({
          id: beneficiary.id,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          email: beneficiary.email,
          identificationType: beneficiary.identificationType,
          identification: beneficiary.identificationNumber,
          position: beneficiary.occupation,
          politicExposure: beneficiary.politicExposure,
          phoneNumber: beneficiary.phoneNumber,
          address: beneficiary.address,
          contactFatca: beneficiary.contactFatca,
        })
      );
    });

    let personsToCopy = [
      ...shareholders,
      ...signatores,
      ...beneficiaries,
      ...shareholdersBeneficiaries,
      ...counselMembers,
    ];

    personsToCopy = mergeObjectsWithSameId(personsToCopy);

    const idsToRemove = new Set(
      persons.legalRepresentatives.map((item) => item.identification)
    );
    personsToCopy = personsToCopy.filter(
      (item) => !idsToRemove.has(item.identification)
    );

    setContacts(personsToCopy);
  }, [persons]);

  useEffect(() => {
    if (contacto && contacto !== 'DEFAULT') {
      const personToCopy = contacts.find((contact) => contact.id === contacto);
      resetAllInputs();
      setValue('contacto', contacto);
      setValue('copiarContacto', copiarContacto);
      setForm(personToCopy);
    }
  }, [contacto]);

  const isEU = (type) => {
    return type?.label === 'ESTADOS UNIDOS';
  };

  useEffect(() => {
    if (paisNacionalidad && !fatcaId) {
      setValue(
        'tieneNacionalidadEU',
        convertBooleanToInput(!!isEU(paisNacionalidad))
      );
    }
  }, [paisNacionalidad]);

  useEffect(() => {
    if (pais && !fatcaId) {
      setValue('esResidenteEU', convertBooleanToInput(!!isEU(pais)));
    }
  }, [pais]);

  useEffect(() => {
    if (isEU(pais) && !fatcaId) {
      const addressEU =
        (calle ?? '') +
        ' ' +
        (casaNumero ? '#' + casaNumero + ' ' : '') +
        (residencial ? residencial + ' ' : '') +
        (numeroApartamento ? numeroApartamento + ' ' : '') +
        (sector?.label ? sector?.label + ', ' : '') +
        (provincia?.label ? provincia?.label + ' ' : '') +
        (zipCode ? zipCode + ', ' : '') +
        (pais?.label ?? '');

      setValue('direccionEU', addressEU);
    }
  }, [
    pais,
    provincia,
    sector,
    casaNumero,
    calle,
    residencial,
    numeroApartamento,
    zipCode,
  ]);

  useEffect(() => {
    if (isEU(pais) && !fatcaId) {
      setValue('codigoPostalEU', zipCode);
    }
  }, [zipCode]);

  const resetAllInputs = () => {
    reset();
    setInputPaisNacionalidad('');
    setPaisNacionalidad('');
    setInputPais('');
    setPais('');
    setInputProvincia('');
    setProvincia('');
    setInputSector('');
    setSector('');
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'legalRepresentativesSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const canShowVerificationButton = (
    legalRepresentative,
  ) => {
    return (
      legalRepresentative.id &&
      // eslint-disable-next-line no-useless-escape
      legalRepresentative.email.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/)
    );
  };

  const handleVerificationClick = async (id) => {
    const legalRepresentative = legalRepresentativesSectionData?.data
      ?.legalRepresentativesSection?.legalRepresentatives?.find((legal) => legal.id === id);

    if (canShowVerificationButton(legalRepresentative)) {
      try {
        await sendVerification({
          variables: {
            legalRepresentativeId: id,
          },
        });
        toast('Solicitud de Verificación enviada', {
          theme: 'colored',
          type: 'success',
        });
      } catch (e) {
        toast('No se pudo enviar la solicitud de Verificación', {
          theme: 'colored',
          type: 'error',
        });
      }
    }
  }

  const filterLegalRepresentativeType = () => {
    if (true) {
      return legalRepresentativeTypes?.legalRepresentativeTypes
        ?.filter((type) => type.name !== 'Apoderado o Tutor');
    }
  }

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Representante legal
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Representante legal'}
              description={
                'En esta sección se da cabida a la incorporación de figuras fundamentales que actúan como voceros y agentes autorizados en nombre de la entidad. Un Representante Legal es aquella persona física identificada y reconocida por la ley para ejercer funciones clave en representación de la empresa. Este formulario permite la inclusión de dichos representantes, ofreciendo un espacio dedicado para capturar información pertinente. Al añadir a los representantes legales, se establece una conexión jurídica vital entre la empresa y aquellos individuos autorizados a tomar decisiones y actuar en su representación. Explorar esta sección proporcionará una visión clara de las personas designadas para desempeñar roles cruciales en el marco legal y operativo de la empresa.'
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                      validationErrors[0] === 'legalRepresentatives'
                      ? 'Es necesario que agregues por lo menos un representante legal.'
                      : `Hay uno o varios representantes legales con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>

          <h4 className="bff-sub-title">
            Si tienes más de un representante legal, puedes agregarlos todos
            aquí.
          </h4>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {legalRepresentativesSectionData?.data?.legalRepresentativesSection?.legalRepresentatives.map(
                (legalRepresentative, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={legalRepresentative?.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardItems
                        key={index}
                        nameElement={
                          legalRepresentative.firstName +
                          ' ' +
                          legalRepresentative.lastName
                        }
                        identityDocument={legalRepresentative.identification}
                        idElement={legalRepresentative?.id}
                        deleteElement={deleteCardElement}
                        handleEdit={handleEdit}
                        setIsUpdating={setIsUpdating}
                        showDeleteButton={!isFormCompleted && !isFormDisqualified}
                        detalle={
                          legalRepresentative?.politicExposure?.isPep
                            ? 'Persona politicamente expuesta'
                            : null
                        }
                        extraButton={{
                          show: legalRepresentative.legalRepresentativeType.name === 'Principal'
                            && !isFormCompleted && !isFormDisqualified && legalRepresentative.verificationStatus !== 'VERIFIED',
                          text: 'Verificar',
                          click: handleVerificationClick,
                        }}
                      />
                    </div>
                  );
                }
              )}

              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    resetAllInputs();
                    setIsUpdating(false);
                    setExistingLegalRepresentative(false);
                    setSelectedRepresentativeId('');
                    setCorrespondenceEmailTypeId('');
                    setFatcaId('');
                    setPoliticExposureId('');
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">Agregar Representante</h4>
                </div>
              ) : null}
            </div>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addLegalRepresentative)}
                  className="fields-modal-container"
                >
                  <div className="fields-modal-header">
                    <h4 className="modal-title" id="exampleModalLabel">
                      {!isFormCompleted && !isFormDisqualified
                        ? selectedRepresentativeId
                          ? 'Editar'
                          : 'Agregar'
                        : 'Ver'}{' '}
                      Representante
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      onClick={() => {
                        resetAllInputs();
                        setIsUpdating(false);
                        setExistingLegalRepresentative(false);
                        setSelectedRepresentativeId('');
                        setCorrespondenceEmailTypeId('');
                        setFatcaId('');
                        setPoliticExposureId('');
                      }}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="row fields-modal-content">
                    <div className="row">
                      {!isFormCompleted && !isFormDisqualified ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="copiar-contacto"
                            className="label-field"
                          >
                            ¿Copiar información de otro contacto?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="copiar-contacto"
                              defaultValue="No"
                              {...register('copiarContacto')}
                            >
                              <option value="No">No</option>
                              <option value="Si">Si</option>
                            </select>
                          </div>
                        </div>
                      ) : null}
                      {!isFormCompleted && !isFormDisqualified && copiarContacto === 'Si' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="contacto" className="label-field">
                            Contacto <span>*</span>
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="contacto"
                              defaultValue={'DEFAULT'}
                              {...register('contacto', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona
                              </option>
                              {contacts.map((contact) => {
                                return (
                                  <option key={contact?.id} value={contact?.id}>
                                    {contact?.firstName +
                                      ' ' +
                                      contact?.lastName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {errors.copiarContacto && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="nombres-representante-legal"
                          className="label-field"
                        >
                          Nombres <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="nombres-representante-legal"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('nombres', {
                            required: true,
                          })}
                        />
                        {errors.nombres?.type === 'required' && (
                          <p className="text-danger">
                            El campo Nombres es requerido
                          </p>
                        )}
                        {selectedRepresentativeId &&
                          !nombres &&
                          errors.nombres?.type !== 'required' && (
                            <ErrorText hasError={hasError('firstName')} />
                          )}
                      </div>
                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="apellidos-representante-legal"
                          className="label-field"
                        >
                          Apellidos <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="apellidos-representante-legal"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('apellidos', {
                            required: true,
                          })}
                        />
                        {errors.apellidos?.type === 'required' && (
                          <p className="text-danger">
                            El campo Apellidos es requerido
                          </p>
                        )}
                        {selectedRepresentativeId &&
                          !apellidos &&
                          errors.apellidos?.type !== 'required' && (
                            <ErrorText hasError={hasError('lastName')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="correo-electronico"
                          className="label-field"
                        >
                          Correo electrónico <span>*</span>
                        </label>
                        <input
                          type="email"
                          className="input-form"
                          placeholder=""
                          id="correo-electronico"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('correoElectronico', {
                            required: true,
                          })}
                        />
                        {errors.correoElectronico?.type === 'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                        {selectedRepresentativeId &&
                          !correoElectronico &&
                          errors.correoElectronico?.type !== 'required' && (
                            <ErrorText hasError={hasError('email')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="tipo-documento-representante-legal"
                          className="label-field"
                        >
                          Tipo de documento <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tipo-documento-representante-legal"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoDocumento', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {identificationTypeList?.identificationTypeList.map(
                              (identificationType) => {
                                return (
                                  <option
                                    key={identificationType.id}
                                    value={identificationType?.id}
                                  >
                                    {identificationType?.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                        {errors.tipoDocumento && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {selectedRepresentativeId &&
                          (!tipoDocumento || tipoDocumento === 'DEFAULT') &&
                          errors.tipoDocumento && (
                            <ErrorText
                              hasError={hasError('identificationType')}
                            />
                          )}
                      </div>
                      {tipoDocumento &&
                        identificationTypeList?.identificationTypeList &&
                        identificationTypeList?.identificationTypeList
                          ?.find((i) => i.id === tipoDocumento)
                          ?.name.toLowerCase()
                          .includes('cedula') ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="cedula" className="label-field">
                            Número de documento de identidad <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            maxLength="13"
                            pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                            placeholder="000-0000000-0"
                            id="cedula"
                            disabled={isFormCompleted || isFormDisqualified}
                            onInput={() => {
                              setNotCheck(false);
                              validateInputCedula();
                            }}
                            {...register('numeroDoc', {
                              required: true,
                            })}
                          />
                          {errors.numeroDoc?.type === 'required' && (
                            <p className="text-danger">
                              El campo Número de documento es requerido
                            </p>
                          )}
                          {existingLegalRepresentative ? (
                            <p className="text-danger">
                              Ya existe un representante legal registrado con
                              ese número de documento.
                            </p>
                          ) : null}
                          {selectedRepresentativeId &&
                            !numeroDoc &&
                            errors.numeroDoc?.type !== 'required' && (
                              <ErrorText
                                hasError={hasError('identification')}
                              />
                            )}
                        </div>
                      ) : tipoDocumento &&
                        identificationTypeList?.identificationTypeList &&
                        identificationTypeList?.identificationTypeList
                          ?.find((i) => i.id === tipoDocumento)
                          ?.name.toLowerCase()
                          .includes('rnc') ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="rnc" className="label-field">
                            Número de documento de identidad <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            maxLength="11"
                            pattern="[0-9]{3}-?[0-9]{5}-?[0-9]{1}"
                            placeholder="000-00000-0"
                            disabled={isFormCompleted || isFormDisqualified}
                            onInput={validateInputRnc}
                            id="rnc"
                            {...register('numeroDoc', {
                              required: true,
                            })}
                          />
                          {errors.numeroDoc?.type === 'required' && (
                            <p className="text-danger">
                              El campo Número de documento es requerido
                            </p>
                          )}
                          {existingLegalRepresentative ? (
                            <p className="text-danger">
                              Ya existe un representante legal registrado con
                              ese número de documento.
                            </p>
                          ) : null}
                          {selectedRepresentativeId &&
                            !numeroDoc &&
                            errors.numeroDoc?.type !== 'required' && (
                              <ErrorText
                                hasError={hasError('identification')}
                              />
                            )}
                        </div>
                      ) : (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="numero-doc" className="label-field">
                            Número de documento de identidad <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="numero-doc"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('numeroDoc', {
                              required: true,
                            })}
                          />
                          {errors.numeroDoc?.type === 'required' && (
                            <p className="text-danger">
                              El campo Número de documento es requerido
                            </p>
                          )}
                          {existingLegalRepresentative ? (
                            <p className="text-danger">
                              Ya existe un representante legal registrado con
                              ese número de documento.
                            </p>
                          ) : null}
                          {selectedRepresentativeId &&
                            !numeroDoc &&
                            errors.numeroDoc?.type !== 'required' && (
                              <ErrorText
                                hasError={hasError('identification')}
                              />
                            )}
                        </div>
                      )}
                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="tipoRepresentante"
                          className="label-field"
                        >
                          Tipo de representante legal <span>*</span>
                        </label>

                        <div className="selectWrapper">
                          <select
                            id="tipoRepresentante"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoRepresentante', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {filterLegalRepresentativeType()?.map(
                              (option) => (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.tipoRepresentante && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {selectedRepresentativeId &&
                          (!tipoRepresentante ||
                            tipoRepresentante === 'DEFAULT') &&
                          errors.tipoRepresentante && (
                            <ErrorText
                              hasError={hasError('legalRepresentativeType')}
                            />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="posicion-empresa-representante-legal"
                          className="label-field"
                        >
                          Posición en la empresa<span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="posicion-empresa-representante-legal"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('posicionEmpresa', {
                            required: true,
                          })}
                        />
                        {errors.posicionEmpresa?.type === 'required' && (
                          <p className="text-danger">
                            El campo Posición en la empresa es requerido
                          </p>
                        )}
                        {selectedRepresentativeId &&
                          !posicionEmpresa &&
                          errors.posicionEmpresa?.type !== 'required' && (
                            <ErrorText hasError={hasError('position')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="estado-civil-representante-legal"
                          className="label-field"
                        >
                          Estado civil{' '}
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="estado-civil-representante-legal"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('estadoCivil')}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {civilStatusList?.civilStatusList.map((option) => (
                              <option key={option?.id} value={option?.id}>
                                {option?.name}
                              </option>
                            ))}
                            {selectedRepresentativeId &&
                              (!tipoRepresentante ||
                                tipoRepresentante === 'DEFAULT') &&
                              errors.tipoRepresentante && (
                                <ErrorText hasError={hasError('civilStatus')} />
                              )}
                          </select>
                        </div>
                      </div>

                      <div className="field-form col-md-4 col-sm-12 mt-1 special-autocomplete">
                        <label
                          htmlFor="pais-nacionalidad-representante-legal"
                          className="label-field"
                        >
                          País de nacionalidad <span>*</span>
                        </label>
                        <Autocomplete
                          disablePortal
                          id="controllable-states-demo"
                          value={paisNacionalidad}
                          disabled={isFormCompleted || isFormDisqualified}
                          onChange={(event, newValue) => {
                            setPaisNacionalidad(newValue);
                          }}
                          inputValue={inputPaisNacionalidad}
                          onInputChange={(event, newInputValue) => {
                            setInputPaisNacionalidad(newInputValue);
                          }}
                          options={
                            countries?.countries?.length
                              ? countries?.countries?.map((type) => ({
                                label: type.name,
                                id: type.id,
                              }))
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              placeholder={
                                countries?.countries?.length
                                  ? 'Busca y selecciona'
                                  : 'Cargando...'
                              }
                              {...params}
                              {...register('paisNacionalidad', {
                                required: true,
                              })}
                            />
                          )}
                        />
                        <p
                          className="text-danger"
                          style={{
                            marginTop: 8,
                            visibility: errors.paisNacionalidad
                              ? 'visible'
                              : 'hidden',
                          }}
                        >
                          Debe seleccionar una opción
                        </p>
                        {selectedRepresentativeId &&
                          !paisNacionalidad &&
                          !errors.paisNacionalidad && (
                            <ErrorText
                              hasError={hasError('nationalityCountry')}
                            />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="sexo-representante-legal"
                          className="label-field"
                        >
                          Sexo <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="sexo-representante-legal"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('sexo', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {genderTypes?.genderList.map((option) => (
                              <option key={option?.id} value={option?.id}>
                                {option?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.sexo && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {selectedRepresentativeId &&
                          (!sexo || sexo === 'DEFAULT') &&
                          errors.sexo && (
                            <ErrorText hasError={hasError('gender')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="fecha-nacimiento"
                          className="label-field"
                        >
                          Fecha de nacimiento <span>*</span>
                        </label>
                        <input
                          type="date"
                          className="input-form"
                          placeholder=""
                          id="fecha-nacimiento"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('fechaNacimiento', {
                            required: true,
                          })}
                        />
                        {errors.fechaNacimiento?.type === 'required' && (
                          <p className="text-danger">
                            El campo Fecha de nacimiento es requerido
                          </p>
                        )}
                        {selectedRepresentativeId &&
                          !fechaNacimiento &&
                          errors.fechaNacimiento?.type !== 'required' && (
                            <ErrorText hasError={hasError('birthDate')} />
                          )}
                      </div>
                    </div>

                    <div className="row fields-modal-content">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Dirección
                        </h4>
                      </div>
                      <div className="row">
                        <div className="field-form col-md-4 col-sm-12 mt-1 special-autocomplete">
                          <label
                            htmlFor="pais-representante-legal"
                            className="label-field"
                          >
                            País <span>*</span>
                          </label>
                          <Autocomplete
                            disablePortal
                            id="controllable-states-demo"
                            value={pais}
                            disabled={isFormCompleted || isFormDisqualified}
                            onChange={(event, newValue) => {
                              setPais(newValue);
                            }}
                            inputValue={inputPais}
                            onInputChange={(event, newInputValue) => {
                              setInputPais(newInputValue);
                            }}
                            options={
                              countries?.countries?.length
                                ? countries?.countries?.map((type) => ({
                                  label: type.name,
                                  id: type.id,
                                }))
                                : []
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder={
                                  countries?.countries?.length
                                    ? 'Busca y selecciona'
                                    : 'Cargando...'
                                }
                                {...params}
                                {...register('pais', { required: true })}
                              />
                            )}
                          />
                          <p
                            className="text-danger"
                            style={{
                              marginTop: 8,
                              visibility: errors.pais ? 'visible' : 'hidden',
                            }}
                          >
                            Debe seleccionar una opción
                          </p>
                          {selectedRepresentativeId &&
                            !pais &&
                            !errors.pais && (
                              <ErrorText hasError={hasError('country')} />
                            )}
                        </div>

                        <div className="field-form col-md-4 col-sm-12 mt-1 special-autocomplete">
                          <label htmlFor="provincia" className="label-field">
                            Provincia <span>*</span>
                          </label>
                          <Autocomplete
                            disablePortal
                            id="controllable-states-demo"
                            value={provincia}
                            disabled={isFormCompleted || isFormDisqualified}
                            onChange={(event, newValue) => {
                              setProvincia(newValue);
                            }}
                            inputValue={inputProvincia}
                            onInputChange={(event, newInputValue) => {
                              setInputProvincia(newInputValue);
                            }}
                            options={
                              provincesByCountry?.data?.provincesByCountry
                                ?.length
                                ? provincesByCountry?.data?.provincesByCountry?.map(
                                  (type) => ({
                                    label: type.name,
                                    id: type.id,
                                  })
                                )
                                : []
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder="Busca y selecciona"
                                {...params}
                                {...register('provincia', { required: true })}
                              />
                            )}
                          />
                          <p
                            className="text-danger"
                            style={{
                              marginTop: 8,
                              visibility: errors.provincia
                                ? 'visible'
                                : 'hidden',
                            }}
                          >
                            Debe seleccionar una opción
                          </p>
                          {!pais && !errors.provincia && (
                            <p className="text-danger text-stook">
                              Seleccione primero un país
                            </p>
                          )}
                          {selectedRepresentativeId &&
                            !provincia &&
                            !errors.provincia && (
                              <div className="mt-5">
                                <ErrorText hasError={hasError('province')} />
                              </div>
                            )}
                        </div>

                        <div className="field-form col-md-4 col-sm-12 mt-1 special-autocomplete">
                          <label htmlFor="sector" className="label-field">
                            Municipio o sector <span>*</span>
                          </label>
                          <Autocomplete
                            disablePortal
                            id="controllable-states-demo"
                            value={sector}
                            disabled={isFormCompleted || isFormDisqualified}
                            onChange={(event, newValue) => {
                              setSector(newValue);
                            }}
                            inputValue={inputSector}
                            onInputChange={(event, newInputValue) => {
                              setInputSector(newInputValue);
                            }}
                            options={
                              sectorsByProvince?.data?.sectorsByProvince?.length
                                ? sectorsByProvince?.data?.sectorsByProvince?.map(
                                  (type) => ({
                                    label: type.name,
                                    id: type.id,
                                  })
                                )
                                : []
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder="Busca y selecciona"
                                {...params}
                                {...register('sector', { required: true })}
                              />
                            )}
                          />
                          <p
                            className="text-danger"
                            style={{
                              marginTop: 8,
                              visibility: errors.sector ? 'visible' : 'hidden',
                            }}
                          >
                            Debe seleccionar una opción
                          </p>
                          {!provincia && !errors.sector && (
                            <p className="text-danger text-stook">
                              Seleccione primero una provincia
                            </p>
                          )}

                          {selectedRepresentativeId &&
                            !sector &&
                            !errors.sector && (
                              <div className="mt-5">
                                <ErrorText hasError={hasError('sector')} />
                              </div>
                            )}
                        </div>

                        <div className="field-form col-md-4 col-sm-12">
                          <label htmlFor="calle" className="label-field">
                            Calle <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="calle"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('calle', {
                              required: true,
                            })}
                          />
                          {errors.calle?.type === 'required' && (
                            <p className="text-danger">
                              El campo Calle es requerido
                            </p>
                          )}

                          {selectedRepresentativeId &&
                            !calle &&
                            errors.calle?.type !== 'required' && (
                              <ErrorText hasError={hasError('street')} />
                            )}
                        </div>

                        <div className="field-form col-md-4 col-sm-12">
                          <label htmlFor="casaNumero" className="label-field">
                            Número de Casa <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="casaNumero"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('casaNumero', {
                              required: true,
                            })}
                          />
                          {errors.casaNumero?.type === 'required' && (
                            <p className="text-danger">
                              El campo Número de Casa es requerido
                            </p>
                          )}
                          {selectedRepresentativeId &&
                            !casaNumero &&
                            errors.casaNumero?.type !== 'required' && (
                              <ErrorText hasError={hasError('houseNumber')} />
                            )}
                        </div>

                        <div className="field-form col-md-4 col-sm-12">
                          <label htmlFor="residencial" className="label-field">
                            Nombre Edificio{' '}
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="residencial"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('residencial')}
                          />
                        </div>

                        <div className="field-form col-md-4 col-sm-12">
                          <label
                            htmlFor="numeroApartamento"
                            className="label-field"
                          >
                            Apartamento{' '}
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="numeroApartamento"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('numeroApartamento')}
                          />
                        </div>

                        <div className="field-form col-md-4 col-sm-12">
                          <label htmlFor="zipCode" className="label-field">
                            Código Postal <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="zipCode"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('zipCode', { required: true })}
                          />
                          {errors.zipCode?.type === 'required' && (
                            <p className="text-danger">
                              El campo Código Postal requerido
                            </p>
                          )}
                          {selectedRepresentativeId &&
                            !zipCode &&
                            errors.zipCode?.type !== 'required' && (
                              <ErrorText hasError={hasError('zipCode')} />
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="row fields-modal-content">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Persona políticamente expuesta
                        </h4>
                      </div>
                      <div className="row">
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="persona-politicamente-expuesta"
                            className="label-field"
                          >
                            ¿Es una persona políticamente expuesta?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="persona-politicamente-expuesta"
                              defaultValue={'No'}
                              disabled={
                                (isFormCompleted || isFormDisqualified) ||
                                ((getIdentificationTypeName(tipoDocumento) ===
                                  'Cedula de Identidad y Electoral' &&
                                  !checkPoliticExposureError?.message) ||
                                  getIdentificationTypeName(tipoDocumento) ===
                                  undefined
                                  ? true
                                  : false)
                              }
                              {...register('personaPoliticamenteExpuesta', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        {personaPoliticamenteExpuesta === 'Si' ? (
                          <>
                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tipoExposicionPolitica"
                                className="label-field"
                              >
                                Tipo de exposición política de su representante
                                legal <span>*</span>
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tipoExposicionPolitica"
                                  defaultValue={'DEFAULT'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tipoExposicionPolitica', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="DEFAULT" disabled>
                                    Selecciona
                                  </option>
                                  {politicExposureTypes?.politicExposureTypes?.map(
                                    (option) => (
                                      <option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              {errors.tipoExposicionPolitica && (
                                <p className="text-danger">
                                  Debe seleccionar una opción
                                </p>
                              )}
                              {selectedRepresentativeId &&
                                (!tipoExposicionPolitica ||
                                  tipoExposicionPolitica === 'DEFAULT') &&
                                errors.tipoExposicionPolitica && (
                                  <ErrorText
                                    hasError={hasError('politicExposureType')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-12 col-sm-12">
                              <label
                                htmlFor="cargo-pep"
                                className="label-field"
                              >
                                Cargo <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="cargo-pep"
                                disabled={isFormCompleted || isFormDisqualified}
                                readOnly={
                                  checkPoliticExposureResponse &&
                                  (getIdentificationTypeName(tipoDocumento) ===
                                    'Cedula de Identidad y Electoral' ||
                                    getIdentificationTypeName(tipoDocumento) ===
                                    undefined
                                    ? true
                                    : false)
                                }
                                {...register('cargoPep', {
                                  required: true,
                                })}
                              />
                              {errors.cargoPep?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Cargo es requerido
                                </p>
                              )}
                              {selectedRepresentativeId &&
                                !cargoPep &&
                                errors.cargoPep?.type !== 'required' && (
                                  <ErrorText hasError={hasError('charge')} />
                                )}
                            </div>

                            {getPoliticExposureTypeName(
                              tipoExposicionPolitica
                            ) === 'Familiar' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label
                                  htmlFor="tipoRelacionFamiliar"
                                  className="label-field"
                                >
                                  Indique su parentesco con ese familiar{' '}
                                  <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="tipoRelacionFamiliar"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tipoRelacionFamiliar', {
                                    required: true,
                                    maxLength: 100,
                                  })}
                                />
                                {errors.tipoRelacionFamiliar?.type ===
                                  'required' && (
                                    <p className="text-danger">
                                      Este campo es requerido
                                    </p>
                                  )}
                                {errors.tipoRelacionFamiliar?.type ===
                                  'maxLength' && (
                                    <p className="text-danger">
                                      Este campo solo puede tener 100 caracteres
                                      como máximo
                                    </p>
                                  )}
                                {selectedRepresentativeId &&
                                  !tipoRelacionFamiliar &&
                                  errors.tipoRelacionFamiliar?.type !==
                                  'required' && (
                                    <ErrorText
                                      hasError={hasError(
                                        'relativeRelationship'
                                      )}
                                    />
                                  )}
                              </div>
                            ) : null}

                            {getPoliticExposureTypeName(
                              tipoExposicionPolitica
                            ) === 'Familiar' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label
                                  htmlFor="nombreDelFamiliar"
                                  className="label-field"
                                >
                                  Nombre completo del familiar <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="nombreDelFamiliar"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('nombreDelFamiliar', {
                                    required: true,
                                    maxLength: 100,
                                  })}
                                />
                                {errors.nombreDelFamiliar?.type ===
                                  'required' && (
                                    <p className="text-danger">
                                      Este campo es requerido
                                    </p>
                                  )}
                                {errors.nombreDelFamiliar?.type ===
                                  'maxLength' && (
                                    <p className="text-danger">
                                      Este campo solo puede tener 100 caracteres
                                      como máximo
                                    </p>
                                  )}
                                {selectedRepresentativeId &&
                                  !nombreDelFamiliar &&
                                  errors.nombreDelFamiliar?.type !==
                                  'required' && (
                                    <ErrorText
                                      hasError={hasError('relativeName')}
                                    />
                                  )}
                              </div>
                            ) : null}

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="pep-activo"
                                className="label-field"
                              >
                                ¿Está activo en el cargo?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="pep-activo"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('pepActivo', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="fecha-inicio-cargo"
                                className="label-field"
                              >
                                Fecha de inicio en el cargo <span>*</span>
                              </label>
                              <input
                                type="date"
                                className="input-form"
                                placeholder=""
                                id="fecha-inicio-cargo"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('fechaInicioCargo', {
                                  required: true,
                                })}
                              />
                              {errors.fechaInicioCargo?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Fecha de inicio en el cargo es
                                  requerido
                                </p>
                              )}

                              {selectedRepresentativeId &&
                                !fechaInicioCargo &&
                                errors.fechaInicioCargo?.type !==
                                'required' && (
                                  <ErrorText
                                    hasError={hasError('chargeStartDate')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="ultima-fecha-cargo"
                                className="label-field"
                              >
                                Última fecha en el cargo{' '}
                                <span>{pepActivo === 'No' ? '*' : ''}</span>
                              </label>
                              <input
                                type="date"
                                disabled={isFormCompleted || pepActivo === 'Si'}
                                className="input-form"
                                placeholder=""
                                id="ultima-fecha-cargo"
                                {...register('ultimaFechaCargo', {
                                  required: pepActivo === 'No',
                                })}
                              />
                              {errors.ultimaFechaCargo?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Última fecha en el cargo es requerido
                                </p>
                              )}

                              {selectedRepresentativeId &&
                                !ultimaFechaCargo &&
                                errors.ultimaFechaCargo?.type !==
                                'required' && (
                                  <ErrorText
                                    hasError={hasError('chargeEndDate')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="institucion"
                                className="label-field"
                              >
                                Institución <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="institucion"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('institucion', {
                                  required: true,
                                })}
                              />
                              {errors.institucion?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Institución es requerido
                                </p>
                              )}

                              {selectedRepresentativeId &&
                                !institucion &&
                                errors.institucion?.type !== 'required' && (
                                  <ErrorText
                                    hasError={hasError('institution')}
                                  />
                                )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>

                    <div className="row fields-modal-content">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Foreign Account Tax Compliance Act
                        </h4>
                      </div>
                      <div className="row">
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="tieneNacionalidadEU"
                            className="label-field"
                          >
                            ¿Tiene nacionalidad de EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="tieneNacionalidadEU"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('tieneNacionalidadEU', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="esCiudadanoEU"
                            className="label-field"
                          >
                            ¿Es ciudadano de los EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="esCiudadanoEU"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('esCiudadanoEU', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="esResidenteEU"
                            className="label-field"
                          >
                            ¿Es residente de los EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="esResidenteEU"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('esResidenteEU', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        {tieneNacionalidadEU === 'Si' ||
                          esCiudadanoEU === 'Si' ||
                          esResidenteEU === 'Si' ? (
                          <>
                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="codigoPostalEU"
                                className="label-field"
                              >
                                Código Postal en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="codigoPostalEU"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('codigoPostalEU')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="telefonoEU"
                                className="label-field"
                              >
                                Télefono en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="telefonoEU"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('telefonoEU')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tieneRepresentanteEU"
                                className="label-field"
                              >
                                ¿Tiene un apoderado en los EE.UU.?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tieneRepresentanteEU"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tieneRepresentanteEU', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label htmlFor="tieneTIN" className="label-field">
                                ¿Tiene identidad para el pago de impuestos
                                (TIN)?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tieneTIN"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tieneTIN', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            {tieneTIN === 'Si' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label htmlFor="tin" className="label-field">
                                  Número de Identificación Tributaria de los
                                  EE.UU. (TIN) <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="tin"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tin', {
                                    required: true,
                                  })}
                                />
                                {errors.tin?.type === 'required' && (
                                  <p className="text-danger">
                                    El campo Número de Identificación Tributaria
                                    de los EE.UU. (TIN) es requerido
                                  </p>
                                )}
                                {selectedRepresentativeId &&
                                  !tin &&
                                  errors.tin?.type !== 'required' && (
                                    <ErrorText
                                      hasError={hasError(
                                        'taxIdentificationNumber'
                                      )}
                                    />
                                  )}
                              </div>
                            ) : null}

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tarjetaVerde"
                                className="label-field"
                              >
                                Tarjeta de Identidad de los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="tarjetaVerde"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('tarjetaVerde')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="direccionEU"
                                className="label-field"
                              >
                                Dirección en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="direccionEU"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('direccionEU')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="seguroSocial"
                                className="label-field"
                              >
                                Número de Seguro Social EE.UU. <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="seguroSocial"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('seguroSocial', {
                                  required: true,
                                })}
                              />
                              {errors.seguroSocial?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Número de Seguro Social EE.UU. es
                                  requerido
                                </p>
                              )}
                              {selectedRepresentativeId &&
                                !seguroSocial &&
                                errors.seguroSocial?.type !== 'required' && (
                                  <ErrorText
                                    hasError={hasError('socialSecurityNumber')}
                                  />
                                )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="row d-flex justify-content-center mt-5 mb-5 ">
                      {saveLoading ? (
                        <div>
                          <CircularProgress style={{ color: '#f4c43c' }} />
                        </div>
                      ) : (
                        <button type="submit" className="btn-add">
                          Guardar
                        </button>
                      )}
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            className="btn-continue"
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default LegalRepresentative;
