import './BankAccountsPage.css';
import { useState } from 'react';
import ProgressBar from '../../../../../components/ClientePotencial/ProgressBar/ProgressBar';
import { useNavigate } from 'react-router-dom';
import BankAccounts from './../../../../../components/ClientePotencial/forms/Physical person/BankAccounts/BankAccounts';

const BankAccountsPage = () => {
  const [goToBack, setGoToBack] = useState(false);
  const navigate = useNavigate();
  const [disableButton, setDisableButton] = useState(true);

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  const handleBackNavigation = () => {
    setGoToBack(true);
  };

  const navigateToNextPage = () => {
    navigate('/formularios/persona-fisica/estado-laboral');
  };

  return (
    <div className="page-container" id="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container3">
        <h3 className="bff-title">
          En este paso nos gustaría conocer un poco más sobre tus cuentas
          bancarias
        </h3>
        <h4>Si tienes más de una cuenta, puedes agregarlas todas aquí</h4>

        <BankAccounts goToBack={goToBack} setDisableButton={setDisableButton} />

        <ProgressBar
          handleClick={navigateToNextPage}
          handleBackNavigation={handleBackNavigation}
          width={'25%'}
          percentage={'25%'}
          disableButton={disableButton}
        />
      </div>
    </div>
  );
};

export default BankAccountsPage;
