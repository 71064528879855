import { configureStore } from '@reduxjs/toolkit';
import clienteRealSlice from './slices/clienteReal';
import clientePotencialSlice from './slices/clientePotencial';

export const store = configureStore({
  reducer: {
    clienteRealSlice,
    clientePotencialSlice,
  },
});
