import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  session: {
    isAuth: false,
    showSessionModal: false,
    sessionModal: {
      title: '',
      subtitle: '',
      button: '',
    },
  },
  profile: null,
  currentAccount: null,
};

export const clienteRealSlice = createSlice({
  name: 'clienteReal',
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
    },
    resetState: (state) => {
      state = initialState;
    },
    setSessionAuth: (state, action) => {
      state.session.isAuth = action.payload;
    },
    setSessionModal: (state, action) => {
      state.session.showSessionModal = action.payload.showSessionModal;
      state.session.sessionModal = action.payload.sessionModal
        ? action.payload.sessionModal
        : state.session.sessionModal;
    },
  },
});

export const {
  setProfile,
  setCurrentAccount,
  resetState,
  setSessionAuth,
  setSessionModal,
} = clienteRealSlice.actions;

export default clienteRealSlice.reducer;
