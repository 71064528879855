import { Routes, Route, Navigate } from 'react-router-dom';
import './index.css';
import PersonType from './PersonType/PersonType';
import AssistanceUnit from './AssistanceUnit/AssistanceUnit';
import Branch from './Branch/Branch';
import BusinessRepresentative from './BusinessRepresentative/BusinessRepresentative';
import IdentityVerification from './IdentityVerification/IdentityVerification';
import WaitingSide from './WaitingSide/WaitingSide';
import { envars, getCookie } from '../../shared/utils/functions';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setPrimaryContact } from '../../shared/store/slices/clientePotencial';
import CompanyType from './CompanyType/CompanyType';
import Subtype from './Subtype/Subtype';

export default function ClientePotencialIndex() {
  const { primaryContact } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const config = getCookie('potencialCustomerConfig', false);
    if (config) {
      const configDecoded = jwt_decode(config);
      configDecoded && configDecoded.primaryContact
        ? dispatch(setPrimaryContact(true))
        : dispatch(setPrimaryContact(false));
    }
  }, []);

  useEffect(() => {
    const config = getCookie('potencialCustomerConfig', false);
    if (config) {
      const configDecoded = jwt_decode(config);
      configDecoded && configDecoded.primaryContact
        ? dispatch(setPrimaryContact(true))
        : dispatch(setPrimaryContact(false));
    }
  }, [primaryContact]);

  return (
    <>
      <Routes>
        {primaryContact ? (
          <>
            <Route path={'person-type'} element={<PersonType />} />
            <Route path={'assistance-unit'} element={<AssistanceUnit />} />
            <Route path={'sub-type'} element={<Subtype />} />
            <Route path={'select-branch'} element={<Branch />} />
            <Route path={'company-type'} element={<CompanyType />} />
            <Route
              path={'select-business-representative'}
              element={<BusinessRepresentative />}
            />
            <Route
              path={'identity-verification'}
              element={<IdentityVerification />}
            />
            <Route path={'waiting-side'} element={<WaitingSide />} />
          </>
        ) : (
          <Route
            path="*"
            Component={() => {
              return <Navigate to="/" />;
            }}
          />
        )}
      </Routes>
    </>
  );
}
