import './CommunicationsAndCorrespondenceForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';

const CommunicationsAndCorrespondenceForm = ({
  validateForm,
  setValidateForm,
  goToBack,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const correspondencia = watch('correspondencia');
  const correoElectronicoCorrespondencia = watch(
    'correoElectronicoCorrespondencia'
  );
  const confirmacionesInstrucciones = watch('confirmacionesInstrucciones');
  const numeroFax = watch('numeroFax');

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-juridica/empresas-afiliadas');
    }
  }, [goToBack]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveData = () => {
    // TODO cambiar el endpoint al que se enviara la data.
  };

  return (
    <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12 pe-4 columns">
          <div className="field-form">
            <label htmlFor="correspondencia" className="label-field">
              Entrega correspondencia y estados de cuenta <span>*</span>
            </label>
            <div className="selectWrapper">
              <select
                id="correspondencia"
                defaultValue={'DEFAULT'}
                {...register('correspondencia', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Residencia">Residencia</option>
                <option value="Oficina">Oficina</option>
                <option value="Correo electrónico">Correo electrónico</option>
                {/* {civilStatus.map(civilStatus => <option key={civilStatus.id} value={civilStatus.id}>{civilStatus.name}</option>)} */}
              </select>
            </div>
            {errors.correspondencia && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {correspondencia === 'Correo electrónico' ||
          confirmacionesInstrucciones === 'Correo electrónico' ? (
            <div className="field-form">
              <label
                htmlFor="correo-electronico-correspondencia"
                className="label-field"
              >
                Correo electrónico para correspondencia <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="correo-electronico-correspondencia"
                {...register('correoElectronicoCorrespondencia', {
                  required: true,
                })}
              />
              {errors.correoElectronicoCorrespondencia?.type === 'required' && (
                <p className="text-danger">
                  El campo Correo electrónico para correspondencia es requerido
                </p>
              )}
            </div>
          ) : null}
        </div>

        <div className="col-md-5 col-sm-12 ps-4 columns">
          <div className="field-form">
            <label
              htmlFor="confirmaciones-instrucciones"
              className="label-field"
            >
              Recepción de confirmaciones y envío de instrucciones{' '}
              <span>*</span>
            </label>
            <div className="selectWrapper">
              <select
                id="confirmaciones-instrucciones"
                defaultValue={'DEFAULT'}
                {...register('confirmacionesInstrucciones', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Correo electrónico">Correo electrónico</option>
                <option value="Fax">Fax</option>
                <option value="Internet">Internet</option>
                {/* {economicActivities.map(economicActivity => <option key={economicActivity.id} value={economicActivity.id}>{economicActivity.name}</option>)} */}
              </select>
            </div>
            {errors.confirmacionesInstrucciones && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {confirmacionesInstrucciones === 'Fax' ? (
            <div className="field-form">
              <label htmlFor="numero-fax" className="label-field">
                Número de fax <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="numero-fax"
                {...register('numeroFax', {
                  required: true,
                })}
              />
              {errors.numeroFax?.type === 'required' && (
                <p className="text-danger">
                  El campo Número de fax es requerido
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default CommunicationsAndCorrespondenceForm;
