import './AffidavitProvisionInformationPage.css';
import ProgressBar from '../../../../../components/ClientePotencial/ProgressBar/ProgressBar';
import React, { useState } from 'react';
import ModalInvestorProfile from '../../../../../shared/UIElements/ModalInvestorProfile/ModalInvestorProfile';
import AffidavitProvisionInformationForm from './../../../../../components/ClientePotencial/forms/Legal person/AffidavitProvisionInformationForm/AffidavitProvisionInformationForm';

const AffidavitProvisionInformationPage = () => {
  const [validateForm, setValidateForm] = useState(false);
  const [goToBack, setGoToBack] = useState(false);

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  const handleFormValidation = () => {
    setValidateForm(true);
  };

  const handleBackNavigation = () => {
    setGoToBack(true);
  };

  return (
    <div className="page-container" id="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="bff-title">
          Declaración Jurada de Suministro de Información
          <ModalInvestorProfile
            title={'Declaración Jurada de Suministro de Información'}
            message={
              'Por medio del presente documento declaro (declaramos) bajo fe de juramento que todos los dineros, capitales, haberes, valores o títulos utilizados en los negocios realizados con o a través de Parallax Valores Puesto de Bolsa, S. A., no tienen relación alguna con actividades producto del delito de legitimación de capitales, y por lo tanto no guardan vinculación con actividades ilícitas, así como tampoco proceden de ningún delito previsto en cualquier ley penal vigente. Además afirmo (afirmamos) que todas las informaciones declaradas en este formulario son ciertas y autorizo (autorizamos) a Parallax Valores Puesto de Bolsa, S. A. a comprobar, por cualquier medio, la veracidad de las mismas. \nEl (los) firmante (s) (“el Cliente”) declara haber leído, comprendido y expresamente acepta los términos y condiciones generales de contratación de Parallax Valores Puesto de Bolsa, S. A. El Cliente además ratifica su intención de someter los contratos y operaciones efectuadas con o a través de Parallax Valores Puesto de Bolsa, S. A., a arbitraje institucional de conformidad con las disposiciones establecidas en la Ley 50-87 de fecha 4 de junio de 1987 sobre Cámara de Comercio y Producción y en el Reglamento del Consejo de Conciliación y Arbitraje de la Cámara de Comercio y Producción de Santo Domingo, vigente a la fecha de suscripción del presente documento. \nEl Cliente autoriza a Parallax Valores Puesto de Bolsa, S. A.  a suministrar y solicitar a los Buró de Información Crediticia instituidos conforme a las disposiciones de la Ley 288-05, la información crediticia disponible.'
            }
          />
        </h3>

        <AffidavitProvisionInformationForm
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          goToBack={goToBack}
        />

        <ProgressBar
          handleClick={handleFormValidation}
          handleBackNavigation={handleBackNavigation}
          width={'100%'}
          percentage={'100%'}
          forwardButtonText={'Finalizar'}
        />
      </div>
    </div>
  );
};

export default AffidavitProvisionInformationPage;
