import './ProxiesOrGuardiansForm.css';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CardItems from '../../../CardItems/CardItems';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';

const ProxiesOrGuardiansForm = ({ goToBack, setDisableButton }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [proxiesOrGuardians, setProxiesOrGuardians] = useState([]);
  const [civilStatus, setCivilStatus] = useState([]);
  const [identificationTypes, setIdentificationTypes] = useState([]);
  const [genders, setGenders] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [proxyOptions, setProxyOptions] = useState([]);
  const [proxyForeignOptions, setProxyForeignOptions] = useState([]);
  const [proxyPepOptions, setProxyPepOptions] = useState([]);

  const apoderadoOTutor = watch('apoderadoOTutor');
  const nombreYApellidos = watch('nombreYApellidos');
  const sexo = watch('sexo');
  const nacionalidad = watch('nacionalidad');
  const correoElectronico = watch('correoElectronico');
  const estadoCivil = watch('estadoCivil');
  const tipoDocumento = watch('tipoDocumento');
  const numeroDoc = watch('numeroDoc');
  const esCiudadanoEstadoUnidense = watch('esCiudadanoEstadoUnidense');
  const numeroSeguridadSocial = watch('numeroSeguridadSocial');
  const pasaporte = watch('pasaporte');
  const personaPoliticamenteExpuesta = watch('personaPoliticamenteExpuesta');
  const institucion = watch('institucion');
  const fechaInicioCargo = watch('fechaInicioCargo');
  const ultimaFechaCargo = watch('ultimaFechaCargo');
  const direccion = watch('direccion');

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/referencias-comerciales');
    }
  }, [goToBack]);

  useEffect(() => {
    getProxiesOrGuardians();
  }, []);

  const getProxiesOrGuardians = (i) => {};

  const addProxiesOrGuardians = () => {
    btnCloseModalRef.current.click();
    setDisableButton(false);
    reset();
  };

  const deleteCardElement = (id) => {};

  return (
    <>
      <div className="cards-container-add-element">
        {proxiesOrGuardians.map((comercialReference, index) => {
          return (
            <CardItems
              key={index}
              nameElement={comercialReference.referenceName}
              identityDocument={comercialReference.telephoneContact}
              idElement={comercialReference.id}
              deleteElement={deleteCardElement}
            />
          );
        })}

        <div
          className="fill-form-way-card"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          <div className="image-icon-container">
            <FontAwesomeIcon icon={faAdd} className="image-icon" />
          </div>
          <h4 className="bk-card-title">Agregar</h4>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form
              onSubmit={handleSubmit(addProxiesOrGuardians)}
              className="fields-modal-container"
            >
              <div className="fields-modal-header mb-3">
                <h4 className="modal-title" id="exampleModalLabel">
                  Agregar
                </h4>
                <button
                  type="button"
                  ref={btnCloseModalRef}
                  onClick={() => reset()}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="row fields-modal-content">
                <div className="col-md-6 col-sm-12">
                  <div className="field-form">
                    <label htmlFor="apoderado-o-tutor" className="label-field">
                      Apoderado o tutor <span>*</span>
                    </label>
                    <select
                      id="apoderado-o-tutor"
                      defaultValue={'DEFAULT'}
                      {...register('apoderadoOTutor', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {proxyOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {errors.apoderadoOTutor && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  <div className="field-form">
                    <label htmlFor="nombre-y-apellidos" className="label-field">
                      Nombre y apellidos
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="nombre-y-apellidos"
                      {...register('nombreYApellidos', {
                        required: true,
                      })}
                    />
                    {errors.nombreYApellidos?.type === 'required' && (
                      <p className="text-danger">
                        El campo Nombre y apellidos es requerido
                      </p>
                    )}
                  </div>

                  <div className="field-form">
                    <label htmlFor="sexo" className="label-field">
                      Sexo <span>*</span>
                    </label>
                    <select
                      id="sexo"
                      defaultValue={'DEFAULT'}
                      {...register('sexo', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {genders.map((gender) => (
                        <option key={gender.id} value={gender.id}>
                          {gender.name}
                        </option>
                      ))}
                    </select>
                    {errors.sexo && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  <div className="field-form">
                    <label htmlFor="nacionalidad" className="label-field">
                      Nacionalidad <span>*</span>
                    </label>
                    <select
                      id="nacionalidad"
                      defaultValue={'DEFAULT'}
                      {...register('nacionalidad', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {nationalities.map((nationality) => (
                        <option key={nationality.id} value={nationality.id}>
                          {nationality.name}
                        </option>
                      ))}
                    </select>
                    {errors.nacionalidad && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  <div className="field-form">
                    <label htmlFor="correo-electronico" className="label-field">
                      Correo electrónico
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="correo-electronico"
                      {...register('correoElectronico', {
                        required: true,
                      })}
                    />
                    {errors.correoElectronico?.type === 'required' && (
                      <p className="text-danger">
                        El campo Correo electrónico es requerido
                      </p>
                    )}
                  </div>

                  <div className="field-form">
                    <label
                      htmlFor="estado-civil-representante-legal"
                      className="label-field"
                    >
                      Estado civil <span>*</span>
                    </label>
                    <select
                      id="estado-civil-representante-legal"
                      defaultValue={'DEFAULT'}
                      {...register('estadoCivil', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {civilStatus.map((civilStatus) => (
                        <option key={civilStatus.id} value={civilStatus.id}>
                          {civilStatus.name}
                        </option>
                      ))}
                    </select>
                    {errors.estadoCivil && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="field-form">
                    <label
                      htmlFor="tipo-documento-representante-legal"
                      className="label-field"
                    >
                      Tipo de identificación <span>*</span>
                    </label>
                    <select
                      id="tipo-documento-representante-legal"
                      defaultValue={'DEFAULT'}
                      {...register('tipoDocumento', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {/* <option value="Cédula">Cédula</option>
                                    <option value="Pasaporte">Pasaporte</option> */}
                      {identificationTypes.map((identificationType) => (
                        <option
                          key={identificationType.id}
                          value={identificationType.id}
                        >
                          {identificationType.name}
                        </option>
                      ))}
                    </select>
                    {errors.tipoDocumento && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  <div className="field-form">
                    <label htmlFor="numero-doc" className="label-field">
                      Número de identificación <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="numero-doc"
                      {...register('numeroDoc', {
                        required: true,
                      })}
                    />
                    {errors.numeroDoc?.type === 'required' && (
                      <p className="text-danger">
                        El campo Número de identificación es requerido
                      </p>
                    )}
                  </div>

                  <div className="field-form">
                    <label
                      htmlFor="es-ciudadano-estadoUnidense"
                      className="label-field"
                    >
                      ¿Es ciudadano residente en Estados Unidos? <span>*</span>
                    </label>
                    <select
                      id="es-ciudadano-estadoUnidense"
                      defaultValue={'DEFAULT'}
                      {...register('esCiudadanoEstadoUnidense', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      {proxyForeignOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {errors.esCiudadanoEstadoUnidense && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  {esCiudadanoEstadoUnidense === '2' ? (
                    <>
                      <div className="field-form">
                        <label
                          htmlFor="numero-seguridad-social"
                          className="label-field"
                        >
                          Número de seguridad social <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="numero-seguridad-social"
                          {...register('numeroSeguridadSocial', {
                            required: true,
                          })}
                        />
                        {errors.numeroSeguridadSocial?.type === 'required' && (
                          <p className="text-danger">
                            El campo Número de seguridad social es requerido
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label htmlFor="pasaporte" className="label-field">
                          Pasaporte <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="pasaporte"
                          {...register('pasaporte', {
                            required: true,
                          })}
                        />
                        {errors.pasaporte?.type === 'required' && (
                          <p className="text-danger">
                            El campo Pasaporte es requerido
                          </p>
                        )}
                      </div>
                    </>
                  ) : null}

                  <div className="field-form">
                    <label
                      htmlFor="persona-politicamente-expuesta"
                      className="label-field"
                    >
                      ¿Es mi apoderado o tutor una persona políticamente
                      expuesta? <span>*</span>
                    </label>
                    <select
                      id="persona-politicamente-expuesta"
                      defaultValue={'DEFAULT'}
                      {...register('personaPoliticamenteExpuesta', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {proxyPepOptions.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                    {errors.personaPoliticamenteExpuesta && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  {personaPoliticamenteExpuesta === '2' ? (
                    <>
                      <div className="field-form">
                        <label htmlFor="cargo" className="label-field">
                          Cargo <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="cargo"
                          {...register('cargo')}
                        />
                        {/* {errors.cargo?.type === 'required' && <p className='text-danger'>El campo Cargo es requerido</p>} */}
                      </div>

                      <div className="field-form">
                        <label htmlFor="institucion" className="label-field">
                          Institución
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="institucion"
                          {...register('institucion')}
                        />
                        {/* {errors.institucion?.type === 'required' && <p className='text-danger'>El campo Institución es requerido</p>} */}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="fecha-inicio-cargo"
                          className="label-field"
                        >
                          Fecha de inicio en el cargo <span>*</span>
                        </label>
                        <input
                          type="date"
                          className="input-form"
                          placeholder=""
                          id="fecha-inicio-cargo"
                          {...register('fechaInicioCargo', {
                            required: true,
                          })}
                        />
                        {errors.fechaInicioCargo?.type === 'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="ultima-fecha-cargo"
                          className="label-field"
                        >
                          Última fecha en el cargo <span>*</span>
                        </label>
                        <input
                          type="date"
                          className="input-form"
                          placeholder=""
                          id="ultima-fecha-cargo"
                          {...register('ultimaFechaCargo', {
                            required: true,
                          })}
                        />
                        {errors.ultimaFechaCargo?.type === 'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                      </div>
                    </>
                  ) : null}

                  <div className="field-form">
                    <label htmlFor="direccion" className="label-field">
                      Dirección
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="direccion"
                      {...register('direccion', {
                        required: true,
                      })}
                    />
                    {errors.direccion?.type === 'required' && (
                      <p className="text-danger">
                        El campo Dirección es requerido
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <button type="submit" className="mt-2 mb-4 btn-continue">
                  Agregar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProxiesOrGuardiansForm;
