import './PepPage.css';
import { useState } from 'react';
import ProgressBar from '../../../../../components/ClientePotencial/ProgressBar/ProgressBar';
import PepForm from '../../../../../components/ClientePotencial/forms/Physical person/PepForm/PepForm';
import ModalInvestorProfile from '../../../../../shared/UIElements/ModalInvestorProfile/ModalInvestorProfile';

const PepPage = () => {
  const [validateForm, setValidateForm] = useState(false);
  const [goToBack, setGoToBack] = useState(false);

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  const handleFormValidation = () => {
    setValidateForm(true);
  };

  const handleBackNavigation = () => {
    setGoToBack(true);
  };

  return (
    <div className="page-container" id="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="bff-title" style={{ fontWeight: '800' }}>
          Siguiente paso, declaración jurada de persona expuesta políticamente
          (PEP){' '}
          <ModalInvestorProfile
            title={'Definición de PEP'}
            message={
              'Cualquier individuo que desempeña o ha desempeñado, durante los últimos (3) años altas funciones públicas, por elección o nombramientos ejecutivos, en un país extranjero o en territorio nacional, incluyendo altos funcionarios de organizaciones internacionales. Incluye, pero no se limita a, jefes de estado o de gobierno, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales o funcionarios, así como aquellos que determine el Comité Nacional de Lavado de Activos previa consulta con el Ministerio de Administración pública. Los cargos considerados PEP serán todos aquellos funcionarios obligados a presentar declaración jurada de bienes. Se asimilan todas aquellas personas que hallan desempeñado o desempeñen estas funciones o su equivalente para gobiernos extranjeros.'
            }
          />
        </h3>
        <h4 className="bff-title">
          Esta informacion es importante para cumplir con las reglaciones
          internacionales contra el lavado de dinero y la financiación del
          terrorismo
        </h4>

        {/* <BasicFormFields validateForm={validateForm} setValidateForm={setValidateForm} /> */}

        <PepForm
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          goToBack={goToBack}
        />

        <ProgressBar
          handleClick={handleFormValidation}
          handleBackNavigation={handleBackNavigation}
          width={'10%'}
          percentage={'10%'}
        />
      </div>
    </div>
  );
};

export default PepPage;
