/* eslint-disable no-useless-escape */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import {
  setConfetti,
  setHasInvestmentProfile,
  setIslogged,
  setPrimaryContact,
  setProgressBar,
  setSelectedSection,
  setVerified,
} from '../../../shared/store/slices/clientePotencial';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../shared/utils/validators';
import {
  envars,
  getDecodedCookie,
  modifyCookie,
  validateInputCedula,
  validateInputRnc,
} from '../../../shared/utils/functions';
import {
  AZURE_SESSION_TIME_OUT,
  PERSONTYPE,
  azureUserFlowUri,
} from '../../../shared/constants/gobal-constants';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  GET_BUSINESS_REPRESENTATIVES_BY_FILTER,
  GET_FORM_RESPONSE_STATUS_LIST,
  GET_FORM_TYPES,
  GET_IDENTITYTYPES,
  GET_INVESTMENT_PROFILES,
  GET_INVESTMENT_PROFILE_TYPES,
  GET_LEAD_BY_IDENTIFICATION,
} from '../../../shared/graphQL/queries';
import { toast } from 'react-toastify';
import {
  INSERT_INVESTMENT_PROFILE_SECTION,
  SAVE_COMPANY_ECONOMIC_DETAILS_SECTION,
  SAVE_FORM_RESPONSE,
  SAVE_LEAD,
  SEND_NEW_REGISTER_NOTIFICATION,
} from '../../../shared/graphQL/mutations';
import WaitingSide from '../WaitingSide/WaitingSide';

export default function IdentityVerification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const { data: formTypes } = useQuery(GET_FORM_TYPES);
  const { data: investmentProfiles } = useQuery(GET_INVESTMENT_PROFILES);
  const { data: investmentProfileTypes } = useQuery(
    GET_INVESTMENT_PROFILE_TYPES
  );
  const [formSelected, setFormSelected] = useState('');
  const identityDocumentType = watch('identityDocumentType');
  const identityDocumentNumber = watch('identityDocumentNumber');
  const [cedulaErrorMessage, setCedulaErrorMessage] = useState('');
  const [creatingLoad, setCreatingLoad] = useState(false);
  const { loading, error, data } = useQuery(GET_IDENTITYTYPES);
  const [saveLead] = useMutation(SAVE_LEAD);
  const [saveCompanyEconomicDetailsSection] = useMutation(
    SAVE_COMPANY_ECONOMIC_DETAILS_SECTION
  );
  const [getFormResponseStatusList] = useLazyQuery(GET_FORM_RESPONSE_STATUS_LIST);
  const [insertInvestmentProfileSection] = useMutation(
    INSERT_INVESTMENT_PROFILE_SECTION
  );
  const [animationOut, setAnimationOut] = useState(false);
  const [saveFormResponse] = useMutation(SAVE_FORM_RESPONSE);
  const [sendNewRegisterNotification] = useMutation(
    SEND_NEW_REGISTER_NOTIFICATION
  );
  const [getRepresentatives] = useLazyQuery(GET_BUSINESS_REPRESENTATIVES_BY_FILTER);
  const [getLead] = useLazyQuery(GET_LEAD_BY_IDENTIFICATION);
  const [canNavigate, setCanNavigate] = useState(false);
  const [showWaittingSite, setShowWaittingSite] = useState(false);
  const configDecoded = getDecodedCookie('potencialCustomerConfig');
  const buttonRef = useRef(null);
  const btnSubmitRef = useRef();

  // setup callbacks
  const handleLoaded = ({ detail }) => {
    // console.log('loaded payload', detail);
  };

  const handleUserStartedSdk = ({ detail }) => {
    // console.log('started payload', detail);
  };

  const handleUserFinishedSdk = ({ detail }) => {
    // console.log('finished payload', detail);

    if (detail?.identityId && detail?.verificationId) {
      btnSubmitRef?.current?.click();
      // saveDataAndNavigate();
    }
  };

  const handleExitedSdk = ({ detail }) => {
    // console.log('exited payload', detail);
  };

  const handleClick = () => {
    // console.log('Botón clickeado');
    // Realiza las acciones deseadas cuando se hace clic en el botón (Probando)
  };

  useEffect(() => {
    if (formTypes) {
      if (configDecoded?.accountType.name === 'Persona Jurídica') {
        const formTypeToLegalPerson = formTypes.forms.find(
          (x) => x.name === 'Persona Jurídica'
        );
        setFormSelected(formTypeToLegalPerson?.id);
      } else {
        const formTypeToPhysicalPerson = formTypes.forms.find(
          (x) => x.name === 'Persona Física'
        );
        setFormSelected(formTypeToPhysicalPerson?.id);
      }
    }
  }, [formTypes]);

  useEffect(() => {
    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
    dispatch(setProgressBar({ show: true, value: '100%' }));
    const buttonElement = buttonRef?.current;
    buttonElement?.addEventListener('click', handleClick);
    buttonElement?.addEventListener('metamap:loaded', handleLoaded);
    buttonElement?.addEventListener(
      'metamap:userStartedSdk',
      handleUserStartedSdk
    );
    buttonElement?.addEventListener(
      'metamap:userFinishedSdk',
      handleUserFinishedSdk
    );
    buttonElement?.addEventListener('metamap:exitedSdk', handleExitedSdk);
  }, []);

  useEffect(() => {
    if (data) {
      if (configDecoded && configDecoded.accountType) {
        if (!configDecoded.identityVerification) {

          PERSONTYPE[configDecoded.accountType.name].isLegal &&
            setValue(
              'identityDocumentType',
              data.identificationTypeList.find((i) =>
                i.name.toLowerCase().includes('rnc')
              )?.id
            );

          !PERSONTYPE[configDecoded.accountType.name].isLegal &&
            setValue(
              'identityDocumentType',
              data.identificationTypeList.find((i) =>
                i.name.toLowerCase().includes('cedula')
              )?.id
            );
        } else {
          setValue(
            'identityDocumentType',
            configDecoded.identityVerification.identificationTypeId
          );
          setValue(
            'identityDocumentNumber',
            configDecoded.identityVerification.identification
          );
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (canNavigate) {
      setTimeout(() => {
        dispatch(setIslogged(true));

        modifyCookie(
          'potencialCustomerConfig',
          'isVerified',
          false,
          true,
          45 * 60 * 1000
        );
        modifyCookie(
          'potencialCustomerConfig',
          'primaryContact',
          false,
          true,
          45 * 60 * 1000
        );
        dispatch(setVerified(false));
        dispatch(setPrimaryContact(true));
        toast('Felicidades, tu cuenta Parval ha sido creada', {
          type: 'success',
          theme: 'colored',
        });
        dispatch(setProgressBar({ show: false, value: '' }));
        dispatch(setConfetti(true));

        if (configDecoded.assistUnit.name.includes('Negocio')) {
          sessionStorage.setItem('hasInvestmentProfile', true);
          dispatch(setHasInvestmentProfile(true));
        } else {
          sessionStorage.setItem('hasInvestmentProfile', false);
          dispatch(setHasInvestmentProfile(false));
        }

        if (configDecoded.accountType.name === 'Persona Jurídica') {
          dispatch(setSelectedSection('Información De Mi Empresa'));
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-general'
          );
          setShowWaittingSite(false);
        } else {
          dispatch(setSelectedSection('Identificación del cliente'));
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/datos-generales'
          );
          setShowWaittingSite(false);
        }
      }, [500]);
    } else {
      setCanNavigate(false);
    }
  }, [canNavigate]);

  if (error) {
    toast('Hubo un problema al obtener los tipos de verificación', {
      type: 'error',
      theme: 'colored',
    });
  }

  const clickMetaMapBtn = () => {
    if (identityDocumentType !== 'DEFAULT' && identityDocumentNumber !== '') {
      const buttonElement = buttonRef.current;
      buttonElement.click();
    } else {
      if (identityDocumentType === 'DEFAULT') {
        toast('Debe seleccionar un tipo de documento.', {
          type: 'error',
          theme: 'colored',
        });
      }

      if (identityDocumentNumber === '') {
        toast('Debe ingresar el número de su documento de identidad.', {
          type: 'error',
          theme: 'colored',
        });
      }
    }
  };

  const saveDataAndNavigate = async () => {
    setCreatingLoad(true);
    const lead = await getLead({
      variables: {
        identification: identityDocumentNumber?.replace(/\-/g, ''),
      },
    });

    if (
      lead?.data?.lead?.identificationType?.id
    ) {
      toast(
        'Ya existe un cliente registrado con ese número de documento de identidad!',
        {
          type: 'error',
          theme: 'colored',
        }
      );
    } else {
      const identityVerification = {
        identification: identityDocumentNumber?.replace(/\-/g, ''),
        identificationTypeId: identityDocumentType,
      };
  
      const timeout = AZURE_SESSION_TIME_OUT;
      modifyCookie(
        'potencialCustomerConfig',
        'identityVerification',
        identityVerification,
        true,
        timeout
      );
  
      let newLead;

      let representative;

      if (configDecoded.businessRepresentative === 'auto') {
        const representatives = await getRepresentatives({
          variables: {
            filters: {
              branchIds: [configDecoded?.branch?.id], 
              personTypeId: configDecoded?.accountType?.id,
            }
          },
        });

        const randomIndex = Math.floor(
          Math.random() *
            representatives?.data?.accountRepresentatives?.length
        );

        representative =
          representatives?.data?.accountRepresentatives[randomIndex]?.id;
      } else {
        representative = configDecoded.businessRepresentative;
      }

      if (PERSONTYPE[configDecoded.accountType.name].isLegal) {
        newLead = {
          accountRepresentativeId: representative,
          identification: identityDocumentNumber?.replace(/\-/g, ''),
          identificationTypeId: identityDocumentType,
          applicantTypeId: configDecoded.assistUnit?.id,
          subApplicantTypeId: configDecoded.subtype?.id,
          personTypeId: configDecoded.accountType?.id,
          primaryContactEmail: configDecoded.email,
          branchId: configDecoded?.branch?.id,
        };
      } else {
        newLead = {
          accountRepresentativeId: representative,
          identification: identityDocumentNumber?.replace(/\-/g, ''),
          identificationTypeId: identityDocumentType,
          applicantTypeId: configDecoded.assistUnit?.id,
          personTypeId: configDecoded.accountType?.id,
          primaryContactEmail: configDecoded?.email,
          branchId: configDecoded?.branch?.id,
        };
      }

      const savedLead = await saveLead({ variables: { lead: newLead } });

      if (savedLead?.data?.saveLead) {
        const statusId = (await getFormResponseStatusList()).data?.formResponseStatusList
          ?.find((status) => status.name === 'Nuevo')?.id;
        const formResponseCreated = await saveFormResponse({
          variables: {
            formResponse: {
              formId: formSelected,
              leadIdentification: identityDocumentNumber?.replace(/\-/g, ''),
              channelId: 'WEB',
              formResponseStatusId: statusId,
            },
          },
        });

        if(formResponseCreated?.data?.saveFormResponse){
          const data = {
            formResponseId: formResponseCreated?.data?.saveFormResponse?.id,
            economicActivityId: null,
            economicSectorId: null,
            clientsAndCountries: null,
            providers: null,
            annualSalesId: null,
            employeesNumber: null,
            productsOrServices: null,
            companyTypeId: configDecoded.companyType.id,
          };
          await saveCompanyEconomicDetailsSection({
            variables: {
              companyEconomicDetailsSection: data,
            },
          });
  
          const isContrapart = configDecoded?.subtype.name === 'Contraparte';
  
          if (isContrapart) {
            const professionalProfile =
              investmentProfiles?.investmentProfiles?.find(
                (profile) => profile?.name === 'Inversionista Profesional'
              );
  
            const agresiveType =
              investmentProfileTypes?.investmentProfileTypes?.find(
                (type) => type.name === 'Agresivo'
              );
  
            await insertInvestmentProfileSection({
              variables: {
                investmentProfileSectionInputType: {
                  formResponseId: formResponseCreated?.data?.saveFormResponse?.id,
                  investmentProfileId: professionalProfile?.id || '',
                  investmentProfileTypeId: agresiveType?.id || '',
                  profileCompletionPercentage: 100,
                  profileScore: 100,
                  profileTypeScore: 100,
                },
              },
            });
          }

          await sendNewRegisterNotification({
            variables: {
              registerNotification: {
                formResponseId: formResponseCreated?.data?.saveFormResponse?.id,
                leadIdentification: identityDocumentNumber?.replace(/\-/g, ''),
              },
            },
          });
          sessionStorage.setItem('selectedFormResponse', JSON.stringify(formResponseCreated?.data?.saveFormResponse));
  
          setCanNavigate(true);
        }
        else{
          console.error('Error creando el form reponse: ' + formResponseCreated?.errors[0].message);
        }
      }
    }
    setCreatingLoad(false);
  };

  const goBack = () => {
    navigate('/onboarding-digital/select-business-representative');
  };

  const navigatePersonTypePage = () => {
    navigate('/onboarding-digital/person-type');
  };

  const filterDocumentTypes = (types) => {
    return configDecoded?.accountType.name === 'Persona Jurídica'
      ? types?.filter(
          (type) =>
            type.name === 'Numero Unico de Identidad' || type.name === 'RNC'
        )
      : types?.filter(
          (type) =>
            type.name === 'Cedula de Identidad y Electoral' ||
            type.name === 'Pasaporte' ||
            type.name === 'Numero Unico de Identidad'
        );
  };

  return (
    <>
      {showWaittingSite &&
      configDecoded?.accountType?.name !== 'Persona Jurídica' ? (
        <WaitingSide />
      ) : (
        <div className="containerOnboarding">
          <div
            className={
              'contBox ' +
              (animationOut
                ? 'animate__animated animate__fadeOutLeft'
                : 'animate__animated animate__fadeInRight')
            }
          >
            <Row className="p-0 m-0">
              <Col className="d-none d-md-block">
                <div className="banner">
                  <img
                    className="bg"
                    src="/images/onboarding/bannerOnboarding_4.jpg"
                    alt=""
                  />
                </div>
              </Col>
              <Col className="contForm">
                <p className="linkBack" onClick={goBack}>
                  <FontAwesomeIcon icon={faAngleLeft} /> Volver
                </p>
                <div className="vAlign animate__animated animate__fadeInUp">
                  {configDecoded?.accountType?.name === 'Persona Jurídica' ? (
                    <>
                      <strong className="title">
                        ¿Eres una Persona Jurídica?
                      </strong>
                      <p className="subTitle">
                        Nos has indicado con anterioridad que eres una persona
                        jurídica.
                      </p>
                      <p className="subTitle">
                        Ayúdanos a verificar si la identificación de tu empresa
                        ya fue asociada a otro (Usuario / Representante Legal).
                      </p>
                    </>
                  ) : (
                    <>
                      <strong className="title">
                        ¿Eres una Persona Física?
                      </strong>
                      <p className="subTitle">
                        Nos has indicado con anterioridad que eres una Persona
                        Física.
                      </p>
                      <p className="subTitle">
                        Ayúdanos a verificar tu identidad.
                      </p>
                    </>
                  )}
                  <div className="cliente-potencial-register-form">
                    <div className="mt-5 newForm">
                      <form
                        onSubmit={handleSubmit(saveDataAndNavigate)}
                        className="iv-form"
                        id="form"
                        style={{ width: '100%' }}
                      >
                        {!loading && (
                          <>
                            <div className="contField">
                              <label
                                htmlFor="identityDocumentType"
                                className="iv-field-title"
                              >
                                Tipo de documento
                              </label>
                              <div
                                className="field-form mb-3"
                                style={{ height: '10px !important' }}
                              >
                                <select
                                  className="form-select iv-form-field"
                                  defaultValue={'DEFAULT'}
                                  {...register('identityDocumentType', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="DEFAULT" disabled>
                                    Selecciona
                                  </option>
                                  {data &&
                                    filterDocumentTypes(
                                      data.identificationTypeList
                                    ).map((identificationType) => (
                                      <option
                                        key={identificationType.id}
                                        value={identificationType.id}
                                      >
                                        {identificationType.name}
                                      </option>
                                    ))}
                                </select>
                              </div>
                              {errors.identityDocumentType && (
                                <p className="text-danger">
                                  Debe seleccionar el tipo de documento de
                                  identidad
                                </p>
                              )}
                            </div>

                            <div className="contField">
                              <label
                                htmlFor="identityDocumentNumber"
                                className="iv-field-title"
                              >
                                Documento de identidad
                              </label>
                              <div
                                className="input-group flex-nowrap"
                                style={{ height: '10px !important' }}
                              >
                                {identityDocumentType &&
                                data &&
                                data.identificationTypeList
                                  .find((i) => i.id === identityDocumentType)
                                  ?.name.toLowerCase()
                                  .includes('cedula') ? (
                                  <input
                                    id="cedula"
                                    className="iv-form-field"
                                    type="text"
                                    maxLength="13"
                                    pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                                    placeholder="000-0000000-0"
                                    onInput={validateInputCedula}
                                    {...register('identityDocumentNumber', {
                                      required: true,
                                    })}
                                  />
                                ) : identityDocumentType &&
                                  data &&
                                  data.identificationTypeList
                                    .find((i) => i.id === identityDocumentType)
                                    ?.name.toLowerCase()
                                    .includes('rnc') ? (
                                  <input
                                    id="rnc"
                                    className="iv-form-field"
                                    type="text"
                                    maxLength="11"
                                    pattern="[0-9]{3}-?[0-9]{5}-?[0-9]{1}"
                                    placeholder="000-00000-0"
                                    onInput={validateInputRnc}
                                    {...register('identityDocumentNumber', {
                                      required: true,
                                    })}
                                  />
                                ) : (
                                  <input
                                    type="text"
                                    className="form-control iv-form-field"
                                    aria-label="Username"
                                    aria-describedby="addon-wrapping"
                                    {...register('identityDocumentNumber', {
                                      required: true,
                                    })}
                                  />
                                )}
                              </div>
                              {errors.identityDocumentNumber?.type ===
                                'required' && (
                                <p className="text-danger">
                                  Debe ingresar el número de su documento de
                                  identidad
                                </p>
                              )}
                              {cedulaErrorMessage !== '' ? (
                                <p className="text-danger">
                                  {cedulaErrorMessage}
                                </p>
                              ) : null}
                            </div>
                          </>
                        )}
                        {(loading || creatingLoad) ? (
                          <div className="w-100 d-flex justify-content-center p-5">
                            <CircularProgress color="warning" />
                          </div>
                        ) : configDecoded?.accountType?.name ===
                        'Persona Jurídica' ? (
                          <>
                            <button
                              type="submit"
                              className="btnSubmit"
                              id="btnContinuar"
                            >
                              Continuar
                            </button>
                            <button
                              type="button"
                              className="linkGreen"
                              onClick={navigatePersonTypePage}
                              style={{
                                border: 'none',
                                background: 'none',
                                width: '100%',
                              }}
                            >
                              Soy Persona Física
                            </button>
                          </>
                        ) : (
                          <>
                            <button
                              type="button"
                              className="btnSubmit"
                              onClick={clickMetaMapBtn}
                            >
                              Verificación de identidad biométrica
                            </button>
                            <button
                              type="submit"
                              className="linkGreen"
                              style={{
                                border: 'none',
                                background: 'none',
                                width: '100%',
                              }}
                              ref={btnSubmitRef}
                            >
                              Deseo realizar la verificación tradicional
                            </button>
                            <button
                              type="button"
                              className="linkGreen mt-3"
                              onClick={navigatePersonTypePage}
                              style={{
                                border: 'none',
                                background: 'none',
                                width: '100%',
                              }}
                            >
                              Soy Persona Jurídica
                            </button>
                          </>
                        )}

                        <metamap-button
                          ref={buttonRef}
                          id="metaBtn"
                          clientId={envars('METAMAP_CLIENTID')} // from your Metamap dashboard
                          flowId={envars('METAMAP_FLOWID')} // from your Metamap dashboard
                          color="#505DED" // to setup main color of buttons in your metamap
                          textcolor="#FFFFFF" // to setup text color of buttons in your metamap
                          metadata={`{"user_id":"1234778", "fixedLanguage":"es", "email": "${sessionStorage.getItem('email')}"}`}
                          style={{ display: 'none' }}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
}
