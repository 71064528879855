import React, { useEffect, useState } from 'react';
import './subtype.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProgressBar } from '../../../shared/store/slices/clientePotencial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import {
  getDecodedCookie,
  modifyCookie,
} from '../../../shared/utils/functions';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { GET_SUB_APPLICANT_TYPE } from '../../../shared/graphQL/queries';
import {
  AZURE_SESSION_TIME_OUT,
  azureUserFlowUri,
} from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';

export default function Subtype() {
  const [subtype, setSubtype] = useState(null);
  const { loading, error, data } = useQuery(GET_SUB_APPLICANT_TYPE);
  const [animationOut, setAnimationOut] = useState(false);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgressBar({ show: true, value: '68.5%' }));
    const configDecoded = getDecodedCookie('potencialCustomerConfig');

    if (configDecoded && configDecoded.subtype) {
      setReload(true);
      setSubtype(configDecoded.subtype);
    }

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener los tipos de solicitantes', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (subtype && !reload) {
      goTo();
    }
  }, [subtype, reload]);

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    modifyCookie('potencialCustomerConfig', 'subtype', subtype, true, timeout);
    setTimeout(() => {
      navigate(`/onboarding-digital/company-type`);
    }, 500);
    setAnimationOut(true);
  };

  const goBack = () => {
    navigate('/onboarding-digital/assistance-unit');
  };

  return (
    <div className="containerOnboarding">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="d-none d-md-block">
            <div className="banner">
              <img
                className="bg"
                src="/images/onboarding/bannerOnboarding_1.jpg"
                alt=""
              />
            </div>
          </Col>
          <Col className="contForm">
            <p className="linkBack" onClick={goBack}>
              <FontAwesomeIcon icon={faAngleLeft} /> Volver
            </p>
            <div className="vAlign animate__animated animate__fadeInUp">
              <strong className="title">¿Qué tipo de solicitante eres?</strong>
              <div className="cliente-potencial-register-form">
                <div className="mt-2 newForm">
                  {!loading && !error && data && (
                    <div
                      className="assist-unit cards-container"
                      style={{ marginBottom: 75 }}
                    >
                      {data?.subApplicantTypes.map((p) => (
                        <div
                          key={p.id}
                          className={`boxButton ${subtype && subtype.id === p.id ? 'selected' : ''}`}
                          onClick={() => {
                            setReload(false);
                            setSubtype(p);
                          }}
                        >
                          <div>
                            <h5 className="rwf-card-title">{p.name}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
