import './RelatedPeopleForm.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import {
  GET_LINKAGE_TYPES,
  GET_PARVAL_RELATIONSHIPS,
} from '../../../../../shared/graphQL/queries';

const RelatedPeopleForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [
    parvalMarketingSocialMediaOptions,
    setParvalMarketingSocialMediaOptions,
  ] = useState([]);
  const [preferredContacMethods, setPreferredContacMethods] = useState([]);
  const [marketingAboutParvalOptions, setMarketingAboutParvalOptions] =
    useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const { data: parvalRelationships } = useQuery(GET_PARVAL_RELATIONSHIPS);
  const { data: linkageTypes } = useQuery(GET_LINKAGE_TYPES);

  const navigate = useNavigate();
  const formRef = useRef();

  const tieneRelacionParval = watch('tieneRelacionParval');
  const referidoPor = watch('referidoPor');
  const otros = watch('otros');

  useEffect(() => {
    getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveData = () => {
    // navigate('/formularios/persona-fisica/informacion-de-contacto');
  };

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="form-fileds-basic"
      ref={formRef}
    >
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle text-center">Personas Relacionadas</h3>

          <div className="field-form">
            <label htmlFor="tieneRelacionParval" className="label-field">
              ¿Tiene relación con Parval?
            </label>
            <select
              id="tieneRelacionParval"
              defaultValue={'DEFAULT'}
              {...register('tieneRelacionParval', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
            {errors.tieneRelacionParval && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          <div className="field-form">
            <label htmlFor="esEmpleadoParval" className="label-field">
              ¿Es usted empleado Parval?
            </label>
            <select
              id="esEmpleadoParval"
              defaultValue={'DEFAULT'}
              {...register('esEmpleadoParval', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
            {/* {errors.esEmpleadoParval && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
        </div>

        {tieneRelacionParval === 'Si' ? (
          <>
            <div className="col-md-5 col-sm-12">
              <h3 className="formTitle" style={{ visibility: 'hidden' }}>
                Non visible
              </h3>

              <div className="field-form">
                <label htmlFor="detalle-relacion" className="label-field">
                  Detalle de relación
                </label>
                <select
                  id="detalle-relacion"
                  defaultValue={'DEFAULT'}
                  {...register('detalleRelacion', {
                    // validate: validateNonSelectedOptionInDropdown
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {parvalRelationships?.parvalRelationships.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {/* {errors.detalleRelacion && <p className='text-danger'>Debe seleccionar una opción</p>} */}
              </div>

              <div className="field-form">
                <label htmlFor="grupo-vinculacion" className="label-field">
                  Grupo de vinculación
                </label>
                <select
                  id="grupo-vinculacion"
                  defaultValue={'DEFAULT'}
                  {...register('grupoVinculacion', {
                    // validate: validateNonSelectedOptionInDropdown
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {linkageTypes?.linkageTypes.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {/* {errors.grupoVinculacion && <p className='text-danger'>Debe seleccionar una opción</p>} */}
              </div>
            </div>
          </>
        ) : null}
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default RelatedPeopleForm;
