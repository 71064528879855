import './DistributionLiquidAssetsForm.css';
import SliderProgress from '../../../../../shared/UIElements/SliderProgress/SliderProgress';
import { useEffect, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

let data = [
  {
    name: 'Valor restante',
    color: '#D9D9D9',
    percentage: 100,
  },
  {
    name: 'Acciones del Exterior',
    color: '#04CD9A',
    percentage: 0,
  },
  {
    name: 'Cuentas Bancarias',
    color: '#F3BA0D',
    percentage: 0,
  },
  {
    name: 'Cuentas por Cobrar',
    color: '#EE5C50',
    percentage: 0,
  },
  {
    name: 'Depósitos a Plazo',
    color: '#69D2FF',
    percentage: 0,
  },
  {
    name: 'Efectivo',
    color: '#326267',
    percentage: 0,
  },
  {
    name: 'Inventarios',
    color: '#9748FF',
    percentage: 0,
  },
  {
    name: 'Mercado de Valores',
    color: '#6CBF4E',
    percentage: 0,
  },
];

// const data2 = [
//     {name: "Group A", value: 2400},
//     {name: "Group B", value: 4567},
//     {name: "Group C", value: 1398},
//     {name: "Group D", value: 9800},
//     {name: "Group E", value: 3908},
//     {name: "Group F", value: 4800},
// ];

const COLORS = [
  '#D9D9D9',
  '#04CD9A',
  '#F3BA0D',
  '#EE5C50',
  '#69D2FF',
  '#326267',
  '#9748FF',
  '#6CBF4E',
];

const DistributionLiquidAssetsFormPhysicalPerson = () => {
  const [initialPercentage, setInitialPercentage] = useState(0);
  const [remainingPercentage, setRemainingPercentage] = useState(100);
  const [sum, setSum] = useState(0);
  const [foreignActionsProgress, setForeignActionsProgress] = useState(0);
  const [bankAccountsProgress, setBankAccountsProgress] = useState(0);
  const [accountsReceivableProgress, setAccountsReceivableProgress] =
    useState(0);
  const [termDepositsProgress, setTermDepositsProgress] = useState(0);
  const [cashProgress, setCashProgress] = useState(0);
  const [inventoriesProgress, setInventoriesProgress] = useState(0);
  const [stockMarketProgress, setStockMarketProgress] = useState(0);
  const navigate = useNavigate();
  const [changeAssignment, setChangeAssignment] = useState(false);
  const [changeAssignmentValue, setChangeAssignmentValue] = useState(0);
  const [disabledButton, setDisabledButton] = useState(true);

  useEffect(() => {
    let sumatoria =
      foreignActionsProgress +
      bankAccountsProgress +
      accountsReceivableProgress +
      termDepositsProgress +
      cashProgress +
      inventoriesProgress +
      stockMarketProgress;
    if (sumatoria > 100) {
      //sumatoria = 100;
      setChangeAssignment(true);
      // setChangeAssignmentValue(Math.round(100 / 7));
      setChangeAssignmentValue(Math.round(0));
      sumatoria =
        foreignActionsProgress +
        bankAccountsProgress +
        accountsReceivableProgress +
        termDepositsProgress +
        cashProgress +
        inventoriesProgress +
        stockMarketProgress;
    } else {
      setChangeAssignment(false);
      setChangeAssignmentValue(0);
    }

    setSum(sumatoria);

    if (remainingPercentage > 0) {
      setRemainingPercentage(
        100 -
          foreignActionsProgress -
          bankAccountsProgress -
          accountsReceivableProgress -
          termDepositsProgress -
          cashProgress -
          inventoriesProgress -
          stockMarketProgress
      );
    } else {
      setRemainingPercentage(100 - sumatoria);
    }

    data = [
      {
        name: 'Valor restante',
        color: '#D9D9D9',
        percentage: remainingPercentage,
      },
      {
        name: 'Acciones del Exterior',
        color: '#04CD9A',
        percentage: foreignActionsProgress,
      },
      {
        name: 'Cuentas Bancarias',
        color: '#F3BA0D',
        percentage: bankAccountsProgress,
      },
      {
        name: 'Cuentas por Cobrar',
        color: '#EE5C50',
        percentage: accountsReceivableProgress,
      },
      {
        name: 'Depósitos a Plazo',
        color: '#69D2FF',
        percentage: termDepositsProgress,
      },
      {
        name: 'Efectivo',
        color: '#326267',
        percentage: cashProgress,
      },
      {
        name: 'Inventarios',
        color: '#9748FF',
        percentage: inventoriesProgress,
      },
      {
        name: 'Mercado de Valores',
        color: '#6CBF4E',
        percentage: stockMarketProgress,
      },
    ];
  }, [
    foreignActionsProgress,
    bankAccountsProgress,
    accountsReceivableProgress,
    termDepositsProgress,
    cashProgress,
    inventoriesProgress,
    stockMarketProgress,
    remainingPercentage,
  ]);

  useEffect(() => {
    // console.log("sum: " + sum);
    if (sum === 100) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [sum]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/operaciones-de-titulos'
    );
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/instrumentos-financieros'
    );
  };

  return (
    <div className="w-100 bg-white">
      <div className="container">
        <div className="row" style={{ margin: '0 0.9rem' }}>
          <div className="col-12">
            <h3 className="formTitle" style={{ color: '#3d675e' }}>
              Distribución de Activos Líquidos
            </h3>
          </div>
        </div>
        <div
          className="dal-sections-container row"
          style={{ margin: '0 0.9rem' }}
        >
          <div className="col-md-3 col-sm-12">
            {data.map((item) => (
              <>
                {item.name !== 'Valor restante' ? (
                  <div className="mb-1">
                    <div
                      className="dla-circle"
                      style={{ backgroundColor: item.color }}
                    ></div>
                    <p className="dal-name">{item.name}</p>
                  </div>
                ) : null}
              </>
            ))}
          </div>
          <div className="col-md-5 col-sm-12">
            <div className="mb-1">
              <SliderProgress
                setProgress={setForeignActionsProgress}
                sum={sum}
                remainingPercentage={remainingPercentage}
                valueProgress={changeAssignmentValue}
                changeAssignment={changeAssignment}
              />

              <span className="dal-progress-percentage">
                {foreignActionsProgress}%
              </span>
            </div>

            <div className="mb-1">
              <SliderProgress
                setProgress={setBankAccountsProgress}
                sum={sum}
                remainingPercentage={remainingPercentage}
                valueProgress={changeAssignmentValue}
                changeAssignment={changeAssignment}
              />

              <span className="dal-progress-percentage">
                {bankAccountsProgress}%
              </span>
            </div>

            <div className="mb-1">
              <SliderProgress
                setProgress={setAccountsReceivableProgress}
                sum={sum}
                remainingPercentage={remainingPercentage}
                valueProgress={changeAssignmentValue}
                changeAssignment={changeAssignment}
              />

              <span className="dal-progress-percentage">
                {accountsReceivableProgress}%
              </span>
            </div>

            <div className="mb-1">
              <SliderProgress
                setProgress={setTermDepositsProgress}
                sum={sum}
                remainingPercentage={remainingPercentage}
                valueProgress={changeAssignmentValue}
                changeAssignment={changeAssignment}
              />

              <span className="dal-progress-percentage">
                {termDepositsProgress}%
              </span>
            </div>

            <div className="mb-1">
              <SliderProgress
                setProgress={setCashProgress}
                sum={sum}
                remainingPercentage={remainingPercentage}
                valueProgress={changeAssignmentValue}
                changeAssignment={changeAssignment}
              />

              <span className="dal-progress-percentage">{cashProgress}%</span>
            </div>

            <div className="mb-1">
              <SliderProgress
                setProgress={setInventoriesProgress}
                sum={sum}
                remainingPercentage={remainingPercentage}
                valueProgress={changeAssignmentValue}
                changeAssignment={changeAssignment}
              />

              <span className="dal-progress-percentage">
                {inventoriesProgress}%
              </span>
            </div>

            <div className="mb-1">
              <SliderProgress
                setProgress={setStockMarketProgress}
                sum={sum}
                remainingPercentage={remainingPercentage}
                valueProgress={changeAssignmentValue}
                changeAssignment={changeAssignment}
              />

              <span className="dal-progress-percentage">
                {stockMarketProgress}%
              </span>
            </div>

            {/* <div>
                        <span>Porcentaje inicial: { initialPercentage }</span>
                        <br />
                        <span>Resto: {remainingPercentage}</span>
                    </div>  */}

            {/* {data.map(item => 
                        <div className='mb-1'>
                            <SliderProgress setProgress={setForeignActionsProgress} />

                            <span className="dal-progress-percentage">10%</span>
                        </div>
                    )} */}
          </div>

          <div className="dal-chart-text-container col-md-4 col-sm-12">
            <div
              className="dal-chart-container"
              style={{
                width: '100%',
                marginTop: '0',
                height: 200,
              }}
            >
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    dataKey="percentage"
                    data={data}
                    innerRadius={60}
                    outerRadius={85}
                    fill="#D9D9D9"
                  >
                    {data.map((item, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="dal-text-sum-container row">
            <div className="dal-text-sum-content">
              <h2>{sum}%</h2>
              <p>Distribuido</p>
            </div>
          </div>

          <div className="row my-3">
            <div>
              <p className="text-center" style={{ fontSize: '0.8rem' }}>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Dignissimos doloribus, omnis beatae iusto eligendi tempora
                assumenda, dolore laboriosam nihil debitis consequatur pariatur
                consequuntur ipsam. A incidunt dolore corrupti soluta suscipit?
              </p>
            </div>
          </div>

          <div className="buttons-control-container">
            <button
              type="submit"
              className="btn-back"
              onClick={handleBackNavigation}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
              &#160; Atrás
            </button>

            <button
              type="submit"
              disabled={disabledButton}
              className={disabledButton ? 'button-disabled' : 'btn-continue'}
              onClick={navigateToNextPage}
            >
              Siguiente &#160;
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistributionLiquidAssetsFormPhysicalPerson;
