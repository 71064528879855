import { useState } from 'react';
import './PasswordRecovery.css';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../shared/UIElements/Spinner/Spinner';
import { validateEmail } from '../../../shared/utils/functions';

const PasswordRecovery = () => {
  const [email, setEmail] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const navigate = useNavigate();
  const [emailErrors, setEmailErrors] = useState([]);

  const recoverPassword = (event) => {
    event.preventDefault();

    const validEmail = validateEmail(email);

    if (email === '') {
      setEmailErrors((prevState) => [
        ...prevState,
        'Debe ingresar un correo electrónico',
      ]);
    }

    if (!validEmail) {
      setEmailErrors((prevState) => [
        ...prevState,
        'El formato del correo electrónico ingresado no es válido',
      ]);
    }

    if (email !== '' && validEmail) {
      setShowSpinner(true);
    }
  };

  return (
    <div className="row">
      {showSpinner ? <Spinner /> : null}

      <div
        className="d-flex justify-content-center align-items-center w-100 flex-column"
        style={{ height: '100vh' }}
      >
        <img className="logoParval" alt="logo" src="/logo.png" />
        <form onSubmit={recoverPassword} className="pr-form">
          <h1 className="pr-title">Recuperación de contraseña</h1>
          <p>Ingrese su correo electrónico</p>
          <input
            type="email"
            style={{ display: 'block' }}
            value={email}
            className="form-control mb-3"
            onChange={(event) => setEmail(event.target.value)}
          />
          {emailErrors.map((error, index) => (
            <p className="text-danger" key={index}>
              {' '}
              {error}{' '}
            </p>
          ))}

          <button type="submit" className="btn btn-info reset-password-btn">
            Recuperar contraseña
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordRecovery;
