import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmationDialog({
  title = 'Confirmación',
  text,
  openModal,
  closeModal,
  color,
}) {
  return (
    <BootstrapDialog
      onClose={() => closeModal(false)}
      aria-labelledby="customized-dialog-title"
      open={openModal}
    >
      <DialogTitle
        sx={{ m: 0, p: 2, backgroundColor: color, color: 'white' }}
        id="customized-dialog-title"
      >
        {title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => closeModal(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography gutterBottom className="p-3">
          {text}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => closeModal(true)}
          style={{ color: color }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
