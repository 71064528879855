import './InvestmentStatusForm.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const InvestmentStatusForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [
    parvalMarketingSocialMediaOptions,
    setParvalMarketingSocialMediaOptions,
  ] = useState([]);
  const [preferredContacMethods, setPreferredContacMethods] = useState([]);
  const [marketingAboutParvalOptions, setMarketingAboutParvalOptions] =
    useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [currencies, setCurrencies] = useState([]);

  const navigate = useNavigate();
  const formRef = useRef();

  const clienteHaInvertido = watch('clienteHaInvertido');
  const referidoPor = watch('referidoPor');
  const otros = watch('otros');

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveData = () => {
    // navigate('/formularios/persona-fisica/informacion-de-contacto');
  };

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="form-fileds-basic"
      ref={formRef}
    >
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle text-center">Estado de inversión</h3>

          <div className="field-form">
            <label htmlFor="clienteHaInvertido" className="label-field">
              ¿Cliente ha invertido?{' '}
            </label>
            <select
              id="clienteHaInvertido"
              defaultValue={'DEFAULT'}
              {...register('clienteHaInvertido', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
              {/* {parvalMarketingSocialMediaOptions.map(socialMedia => <option key={socialMedia.id} value={socialMedia.id}>{socialMedia.name}</option>)} */}
            </select>
            {/* {errors.clienteHaInvertido && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>

          {clienteHaInvertido === 'Si' ? (
            <>
              <div className="field-form">
                <label htmlFor="divisas" className="label-field">
                  Divisa <span>*</span>
                </label>
                <select
                  id="divisas"
                  defaultValue={'DEFAULT'}
                  {...register('divisas', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {currencies.map((currency) => (
                    <option key={currency.id} value={currency.id}>
                      {currency.name}
                    </option>
                  ))}
                </select>
                {errors.divisas && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="monto" className="label-field">
                  Monto <span>*</span>
                </label>
                <select
                  id="monto"
                  defaultValue={'DEFAULT'}
                  {...register('monto', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  <option value="Opción 1" disabled>
                    Opción 1
                  </option>
                  <option value="Opción 2" disabled>
                    Opción 2
                  </option>
                  {/* {currencies.map(currency => <option key={currency.id} value={currency.id}>{currency.name}</option>)} */}
                </select>
                {errors.monto && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="fecha" className="label-field">
                  Fecha <span>*</span>
                </label>
                <input
                  type="date"
                  className="input-form"
                  placeholder=""
                  id="fecha"
                  {...register('fecha', {
                    required: true,
                  })}
                />
                {errors.fecha?.type === 'required' && (
                  <p className="text-danger">El campo Fecha es requerido</p>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default InvestmentStatusForm;
