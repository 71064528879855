import './FinancialInstrumentsForm';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { SAVE_FINANCIAL_INSTRUMENTS_SECTION } from '../../../../../shared/graphQL/mutations';
import {
  GET_FINANCIAL_INSTRUMENTS_SECTION_DATA,
  GET_FINANCIAL_INSTRUMENTS_TYPES,
  GET_FINANCIAL_INSTRUMENT_ANNUAL_OPERATIONS_LIST,
  GET_FINANCIAL_INSTRUMENT_EXPIRIENCE_LIST,
  GET_FINANCIAL_INSTRUMENT_KNOWLEDGELEVELS,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

const FinancialInstrumentsForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [formResponseId, setFormResponseId] = useState('');
  const [financialInstrumentSectionId, setFinancialInstrumentSectionId] =
    useState('');
  const [selectedIntrumentId, setSelectedIntrumentId] = useState('');
  const [instrumentsWithoutAdd, setInstrumentsWithoutAdd] = useState([
    '',
    '',
    '',
  ]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [saveFinancialInstrumentsSection] = useMutation(
    SAVE_FINANCIAL_INSTRUMENTS_SECTION,
    {
      refetchQueries: [
        {
          query: GET_FINANCIAL_INSTRUMENTS_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [getFinancialInstrumentsSectionData, financialInstrumentsSectionData] =
    useLazyQuery(GET_FINANCIAL_INSTRUMENTS_SECTION_DATA);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const { data: financialInstrumentTypes } = useQuery(
    GET_FINANCIAL_INSTRUMENTS_TYPES
  );
  const { data: financialInstrumentExperienceList } = useQuery(
    GET_FINANCIAL_INSTRUMENT_EXPIRIENCE_LIST
  );
  const { data: financialInstrumentAnnualOperationsList } = useQuery(
    GET_FINANCIAL_INSTRUMENT_ANNUAL_OPERATIONS_LIST
  );
  const { data: financialInstrumentKnowledgeLevels } = useQuery(
    GET_FINANCIAL_INSTRUMENT_KNOWLEDGELEVELS
  );

  const instrumento = watch('instrumento');
  const tiempoExpInstrumentos = watch('tiempoExpInstrumentos');
  const cantidadOperacionesAnio = watch('cantidadOperacionesAnio');
  const nivelConocimientoInstrumentos = watch('nivelConocimientoInstrumentos');

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if(formResponseByIdentification?.data){
      setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);
      getFinancialInstrumentsSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    //alert("id: " + companyOfficesSectionData?.data?.companyOfficesSection?.id);
    if (financialInstrumentsSectionData) {
      setFinancialInstrumentSectionId(
        financialInstrumentsSectionData?.data?.financialInstrumentsSection?.id
      );

      // alert(JSON.stringify(financialInstrumentsSectionData?.data?.financialInstrumentsSection?.financialInstruments));
      if (
        financialInstrumentsSectionData?.data?.financialInstrumentsSection
          ?.financialInstruments?.length > 0
      ) {
        setDisabledButton(false);

        if (
          financialInstrumentsSectionData?.data?.financialInstrumentsSection
            ?.financialInstruments?.length === 1
        ) {
          setInstrumentsWithoutAdd(['', '']);
        } else if (
          financialInstrumentsSectionData?.data?.financialInstrumentsSection
            ?.financialInstruments?.length === 2
        ) {
          setInstrumentsWithoutAdd(['']);
        } else if (
          financialInstrumentsSectionData?.data?.financialInstrumentsSection
            ?.financialInstruments?.length === 3
        ) {
          setInstrumentsWithoutAdd([]);
        }
      } else {
        setDisabledButton(true);
      }
    }
  }, [financialInstrumentsSectionData]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/distribucion-de-activos-liquidos'
    );
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/origen-de-los-fondos'
    );
  };

  const addOrEditInstrument = () => {
    let data = {};

    //Si financialInstrumentSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (financialInstrumentSectionId) {
      // Si selectedIntrumentId no tiene valor hay que agregar n nuevo instrmento, si tiene valor hay que editar ese instrumneto a partir de su id
      if (selectedIntrumentId !== '') {
        // alert("id en save: " + selectedIntrumentId);
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: financialInstrumentSectionId,
          financialInstrumentInputTypes: {
            id: selectedIntrumentId,
            financialInstrumentAnnualOperationsId: cantidadOperacionesAnio,
            financialInstrumentExperienceId: tiempoExpInstrumentos,
            financialInstrumentKnowledgeLevelId: nivelConocimientoInstrumentos,
            financialInstrumentTypeId: instrumento,
          },
        };
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: financialInstrumentSectionId,
          financialInstrumentInputTypes: {
            financialInstrumentAnnualOperationsId: cantidadOperacionesAnio,
            financialInstrumentExperienceId: tiempoExpInstrumentos,
            financialInstrumentKnowledgeLevelId: nivelConocimientoInstrumentos,
            financialInstrumentTypeId: instrumento,
          },
        };
      }
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        financialInstrumentInputTypes: {
          financialInstrumentAnnualOperationsId: cantidadOperacionesAnio,
          financialInstrumentExperienceId: tiempoExpInstrumentos,
          financialInstrumentKnowledgeLevelId: nivelConocimientoInstrumentos,
          financialInstrumentTypeId: instrumento,
        },
      };
    }

    saveFinancialInstrumentsSection({
      variables: {
        financialInstrumentsSection: data,
      },
    });

    btnCloseModalRef.current.click();
    reset();
  };

  const handleEdit = (id) => {
    //alert("id: " + id);
    setSelectedIntrumentId(id);
    const selectedInstrument =
      financialInstrumentsSectionData?.data?.financialInstrumentsSection?.financialInstruments?.find(
        (x) => x.id === id
      );
    if (id && selectedInstrument) {
      setValue('instrumento', selectedInstrument?.financialInstrumentType?.id);
      setValue(
        'tiempoExpInstrumentos',
        selectedInstrument?.financialInstrumentExperience?.id
      );
      setValue(
        'cantidadOperacionesAnio',
        selectedInstrument?.financialInstrumentAnnualOperations?.id
      );
      setValue(
        'nivelConocimientoInstrumentos',
        selectedInstrument?.financialInstrumentKnowledgeLevel?.id
      );
    }
  };

  return (
    <div style={{ backgroundColor: 'white', paddingTop: 15 }}>
      <div
        className="w-100 d-flex justify-content-start px-5"
        style={{ height: 40 }}
      >
        <h3 className="formTitle" style={{ color: '#3d675e' }}>
          Instrumentos financieros negociados previamente
        </h3>
      </div>

      <div className="cards-container-add-element px-5">
        {/* <div className='fill-form-way-card' data-bs-toggle="modal" data-bs-target="#exampleModal">
                <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className='image-icon' />
                </div> 
                <h4 className='bk-card-title'>Agregar instrumento</h4>
            </div> */}

        <TableContainer className="mt-1">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ border: 'none' }}>
                <TableCell
                  className="financial-instrument-row-cell"
                  align="left"
                >
                  INSTRUMENTOS
                </TableCell>
                <TableCell
                  className="financial-instrument-row-cell"
                  align="left"
                >
                  TIEMPO EXPERIENCIA
                </TableCell>
                <TableCell
                  className="financial-instrument-row-cell"
                  align="left"
                >
                  CANTIDAD OPERACIONES
                </TableCell>
                <TableCell
                  className="financial-instrument-row-cell"
                  align="left"
                >
                  NIVEL CONOCIMIENTOS
                </TableCell>
                <TableCell
                  className="financial-instrument-row-cell"
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {financialInstrumentsSectionData?.data
                ?.financialInstrumentsSection?.financialInstruments?.length >
              0 ? (
                <>
                  {/* Renderiza dinamicamente los instrumentos que han sido agregados */}

                  {financialInstrumentsSectionData?.data?.financialInstrumentsSection?.financialInstruments.map(
                    (instrument, index) => (
                      <TableRow
                        key={index}
                        className="financial-instrument-table-row"
                      >
                        <TableCell
                          style={{
                            border: 'none',
                            color: 'rgb(82, 82, 82)',
                            backgroundColor: '#fff',
                          }}
                          align="left"
                        >
                          {instrument?.financialInstrumentType?.name}
                        </TableCell>
                        <TableCell
                          style={{
                            border: 'none',
                            color: 'rgb(82, 82, 82)',
                            backgroundColor: '#fff',
                          }}
                          align="left"
                        >
                          {instrument?.financialInstrumentExperience?.name}
                        </TableCell>
                        <TableCell
                          style={{
                            border: 'none',
                            color: 'rgb(82, 82, 82)',
                            backgroundColor: '#fff',
                          }}
                          align="left"
                        >
                          {
                            instrument?.financialInstrumentAnnualOperations
                              ?.name
                          }
                        </TableCell>
                        <TableCell
                          style={{
                            border: 'none',
                            color: 'rgb(82, 82, 82)',
                            backgroundColor: '#fff',
                          }}
                          align="left"
                        >
                          {instrument?.financialInstrumentKnowledgeLevel?.name}
                        </TableCell>
                        <TableCell
                          style={{
                            border: 'none',
                            color: 'rgb(82, 82, 82)',
                            backgroundColor: '#fff',
                          }}
                          align="center"
                        >
                          <EditOutlined
                            data-bs-toggle="modal"
                            style={{
                              color: 'rgb(170, 170, 170)',
                              cursor: 'pointer',
                            }}
                            data-bs-target="#exampleModal"
                            onClick={() => handleEdit(instrument.id)}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}

                  {/* Renderiza los instrumentos que faltan para crear mostrar 3 instrmentos */}
                  {instrumentsWithoutAdd.length > 0
                    ? instrumentsWithoutAdd.map((item, index) => (
                        <TableRow
                          key={index}
                          className="financial-instrument-table-row"
                        >
                          <TableCell
                            style={{
                              border: 'none',
                              color: 'rgb(82, 82, 82)',
                              backgroundColor: '#fff',
                            }}
                            align="left"
                          >
                            Ninguno
                          </TableCell>
                          <TableCell
                            style={{
                              border: 'none',
                              color: 'rgb(82, 82, 82)',
                              backgroundColor: '#fff',
                            }}
                            align="left"
                          >
                            Ninguno
                          </TableCell>
                          <TableCell
                            style={{
                              border: 'none',
                              color: 'rgb(82, 82, 82)',
                              backgroundColor: '#fff',
                            }}
                            align="left"
                          >
                            Ninguna
                          </TableCell>
                          <TableCell
                            style={{
                              border: 'none',
                              color: 'rgb(82, 82, 82)',
                              backgroundColor: '#fff',
                            }}
                            align="left"
                          >
                            Ninguno
                          </TableCell>
                          <TableCell
                            style={{
                              border: 'none',
                              color: 'rgb(82, 82, 82)',
                              backgroundColor: '#fff',
                            }}
                            align="center"
                          >
                            <EditOutlined
                              data-bs-toggle="modal"
                              style={{
                                color: 'rgb(170, 170, 170)',
                                cursor: 'pointer',
                              }}
                              data-bs-target="#exampleModal"
                              onClick={() => handleEdit('')}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </>
              ) : (
                <>
                  {instrumentsWithoutAdd.map((item, index) => (
                    <TableRow
                      key={index}
                      className="financial-instrument-table-row"
                    >
                      <TableCell
                        style={{
                          border: 'none',
                          color: 'rgb(82, 82, 82)',
                          backgroundColor: '#fff',
                        }}
                        align="left"
                      >
                        Ninguno
                      </TableCell>
                      <TableCell
                        style={{
                          border: 'none',
                          color: 'rgb(82, 82, 82)',
                          backgroundColor: '#fff',
                        }}
                        align="left"
                      >
                        Ninguno
                      </TableCell>
                      <TableCell
                        style={{
                          border: 'none',
                          color: 'rgb(82, 82, 82)',
                          backgroundColor: '#fff',
                        }}
                        align="left"
                      >
                        Ninguna
                      </TableCell>
                      <TableCell
                        style={{
                          border: 'none',
                          color: 'rgb(82, 82, 82)',
                          backgroundColor: '#fff',
                        }}
                        align="left"
                      >
                        Ninguno
                      </TableCell>
                      <TableCell
                        style={{
                          border: 'none',
                          color: 'rgb(82, 82, 82)',
                          backgroundColor: '#fff',
                        }}
                        align="center"
                      >
                        <EditOutlined
                          data-bs-toggle="modal"
                          style={{
                            color: 'rgb(170, 170, 170)',
                            cursor: 'pointer',
                          }}
                          data-bs-target="#exampleModal"
                          onClick={() => handleEdit('')}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="button"
            disabled={disabledButton}
            className={disabledButton ? 'button-disabled' : 'btn-continue'}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>

      <>
        <div
          className="modal fade financial-instrument"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            style={{ height: '90%', display: 'flex', alignItems: 'center' }}
          >
            <div className="modal-content" style={{ padding: 15 }}>
              <form
                onSubmit={handleSubmit(addOrEditInstrument)}
                className="fields-modal-container"
              >
                <div className="fields-modal-header">
                  <h4 className="modal-title fs-5" id="exampleModalLabel">
                    Editar instrumento financiero
                  </h4>
                  <button
                    type="button"
                    ref={btnCloseModalRef}
                    className="btn-close"
                    onClick={() => reset()}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="row fields-modal-content">
                  <div className="col-sm-12">
                    <div className="field-form">
                      <label htmlFor="instrumento" className="label-field">
                        Instrumento <span>*</span>
                      </label>
                      <select
                        id="instrumento"
                        defaultValue={'DEFAULT'}
                        {...register('instrumento', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {financialInstrumentTypes?.financialInstrumentTypes.map(
                          (option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        )}
                      </select>
                      {errors.instrumento && (
                        <p className="text-danger">
                          Debe seleccionar una opción
                        </p>
                      )}
                    </div>

                    <div className="field-form">
                      <label
                        htmlFor="tiempo-exp-instrmentos"
                        className="label-field"
                      >
                        Tiempo de experiencia en instrumentos
                      </label>
                      <select
                        id="tiempo-exp-instrmentos"
                        defaultValue={'DEFAULT'}
                        {...register('tiempoExpInstrumentos', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {financialInstrumentExperienceList?.financialInstrumentExperienceList.map(
                          (option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        )}
                      </select>
                      {errors.tiempoExpInstrumentos && (
                        <p className="text-danger">
                          Debe seleccionar una opción
                        </p>
                      )}
                    </div>

                    <div className="field-form">
                      <label
                        htmlFor="cantidad-operaciones-anio"
                        className="label-field"
                      >
                        Cantidad de operaciones por año <span>*</span>
                      </label>
                      <select
                        id="cantidad-operaciones-anio"
                        defaultValue={'DEFAULT'}
                        {...register('cantidadOperacionesAnio', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {financialInstrumentAnnualOperationsList?.financialInstrumentAnnualOperationsList.map(
                          (option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        )}
                      </select>
                      {errors.cantidadOperacionesAnio && (
                        <p className="text-danger">
                          Debe seleccionar una opción
                        </p>
                      )}
                    </div>

                    <div className="field-form">
                      <label
                        htmlFor="nivel-conocimiento-instrumentos"
                        className="label-field"
                      >
                        Nivel de conocimiento instrumentos de riesgos asociados{' '}
                        <span>*</span>
                      </label>
                      <select
                        id="nivel-conocimiento-instrumentos"
                        defaultValue={'DEFAULT'}
                        {...register('nivelConocimientoInstrumentos', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {financialInstrumentKnowledgeLevels?.financialInstrumentKnowledgeLevels.map(
                          (option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        )}
                      </select>
                      {errors.nivelConocimientoInstrumentos && (
                        <p className="text-danger">
                          Debe seleccionar una opción
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row d-flex justify-content-center">
                  <button type="submit" className="mt-3 mb-4 new-btn-fi">
                    Agregar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default FinancialInstrumentsForm;
