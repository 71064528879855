import './CurrencyAndInvestmentForm.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const CurrencyAndInvestmentForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [
    parvalMarketingSocialMediaOptions,
    setParvalMarketingSocialMediaOptions,
  ] = useState([]);
  const [preferredContacMethods, setPreferredContacMethods] = useState([]);
  const [marketingAboutParvalOptions, setMarketingAboutParvalOptions] =
    useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  const navigate = useNavigate();
  const formRef = useRef();

  const marketingAboutParval = watch('marketingAboutParval');
  const referidoPor = watch('referidoPor');
  const otros = watch('otros');

  useEffect(() => {
    getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveData = () => {};

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="form-fileds-basic"
      ref={formRef}
    >
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle text-center">Moneda e Inversión</h3>

          <div className="field-form">
            <label htmlFor="monedaEInversionInicial" className="label-field">
              Moneda e Inversión <span>*</span>
            </label>
            <select
              id="monedaEInversionInicial"
              defaultValue={'DEFAULT'}
              {...register('monedaEInversionInicial', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Opción 1" disabled>
                Opción 1
              </option>
              <option value="Opción 2" disabled>
                Opción 2
              </option>
              {/* {parvalMarketingSocialMediaOptions.map(socialMedia => <option key={socialMedia.id} value={socialMedia.id}>{socialMedia.name}</option>)} */}
            </select>
            {errors.monedaEInversionInicial && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          <div className="field-form">
            <label htmlFor="montoAproxInvInicial" className="label-field">
              Monto apróx de inversión inicial <span>*</span>
            </label>
            <select
              id="montoAproxInvInicial"
              defaultValue={'DEFAULT'}
              {...register('montoAproxInvInicial', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Opción 1" disabled>
                Opción 1
              </option>
              <option value="Opción 2" disabled>
                Opción 2
              </option>
              {/* {preferredContacMethods.map(preferredContacMethod => <option key={preferredContacMethod.id} value={preferredContacMethod.id}>{preferredContacMethod.name}</option>)} */}
            </select>
            {errors.montoAproxInvInicial && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          <div className="field-form">
            <label htmlFor="montoestInvAnuales" className="label-field">
              Monto estimado de inversiones anuales
            </label>
            <select
              id="montoestInvAnuales"
              defaultValue={'DEFAULT'}
              {...register('montoestInvAnuales', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Opción 1" disabled>
                Opción 1
              </option>
              <option value="Opción 2" disabled>
                Opción 2
              </option>
              {/* {marketingAboutParvalOptions.map(marketingAboutParvalOption => <option key={marketingAboutParvalOption.id} value={marketingAboutParvalOption.id}>{marketingAboutParvalOption.name}</option>)} */}
            </select>
            {/* {errors.montoestInvAnuales && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default CurrencyAndInvestmentForm;
