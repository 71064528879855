import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { azureUserFlowUri } from '../../../shared/constants/gobal-constants';

const ReturnToLoginFlow = () => {
  window.location.replace(azureUserFlowUri());
};

const ProtectedRoute = ({ children }) => {
  const { accessToken } = useSelector((state) => state.clientePotencialSlice);

  // Para permitir el acceso luego del onboarding

  if (!accessToken) {
    return <ReturnToLoginFlow />;
  }

  // Outlet se usa para retornar múltiples rutas que estaran protegidas por la misma lógica
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
