import './BankAccounts.css';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import { getAuthorizationConfig } from './../../../../../shared/utils/functions';
import { setSelectedSection } from '../../../../../shared/store/slices/clientePotencial';
import { useDispatch } from 'react-redux';
import {
  REMOVE_BANK_ACCOUNT,
  SAVE_BANK_ACCOUNT_SECTION,
} from '../../../../../shared/graphQL/mutations';
import {
  GET_BANKS,
  GET_BANK_ACCOUNTS_SECTION,
  GET_BANK_ACCOUNTS_TYPES,
  GET_CURRENCIES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_IDENTITYTYPES,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

const BankAccounts = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [formResponseId, setFormResponseId] = useState('');
  // const [loading, setLoading] = seState(false);
  const btnCloseModalRef = useRef();
  const navigate = useNavigate();
  const config = getAuthorizationConfig();
  const dispatch = useDispatch();
  const { data: currencies } = useQuery(GET_CURRENCIES);
  const { data: identificationTypeList } = useQuery(GET_IDENTITYTYPES);
  const { data: banks } = useQuery(GET_BANKS);
  const { data: bankAccountTypes } = useQuery(GET_BANK_ACCOUNTS_TYPES);
  const [currencySelected, setCurrencySelected] = useState('');
  const [saveBankAccountSection] = useMutation(SAVE_BANK_ACCOUNT_SECTION, {
    refetchQueries: [
      {
        query: GET_BANK_ACCOUNTS_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [removeBankAccount] = useMutation(REMOVE_BANK_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_BANK_ACCOUNTS_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [getBankAccountsSectionData, bankAccountsSectionData] = useLazyQuery(
    GET_BANK_ACCOUNTS_SECTION
  );
  const [bankAccountSectionId, setBankAccountSectionId] = useState('');
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);

  const titularPrincipalIgualTitularCuenta = watch(
    'titularPrincipalIgualTitularCuenta'
  );
  const tieneCuentaEnDolares = watch('tieneCuentaEnDolares');
  const tipoBanco = watch('tipoBanco');

  const nombreTitular = watch('nombreTitular');
  const tipoDocumento = watch('tipoDocumento');
  const numeroDoc = watch('numeroDoc');

  const institucion = watch('institucion');
  const numeroCuentaBancaria = watch('numeroCuentaBancaria');
  const tipoCuentaBancaria = watch('tipoCuentaBancaria');
  const abaOSwift = watch('abaOSwift');

  // useEffect(() => {
  //     if(monedasCuentasBancarias === "Pesos (RD$)") {
  //         const currencySearched = currencies?.currencies?.find(x => x.name === "DOP");
  //         setCurrencySelected(currencySearched.id);
  //     }
  //     else if(monedasCuentasBancarias === "Dólares (US$)") {
  //         const currencySearched = currencies?.currencies?.find(x => x.name === "USD");
  //         setCurrencySelected(currencySearched.id);
  //     }

  // }, [monedasCuentasBancarias]);

  useEffect(() => {
    setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);

    getBankAccountsSectionData({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });
    // alert("Id: " + formResponseByIdentification?.data?.formResponses[0]?.id);
    // alert(JSON.stringify(formResponseByIdentification?.data?.formResponses));
  }, [formResponseByIdentification]);

  useEffect(() => {
    //alert("id: " + companyOfficesSectionData?.data?.companyOfficesSection?.id);
    if (bankAccountsSectionData) {
      // alert("Data: " + JSON.stringify(bankAccountsSectionData?.data));
      setBankAccountSectionId(
        bankAccountsSectionData?.data?.bankAccountSection?.id
      );

      if (
        bankAccountsSectionData?.data?.bankAccountSection?.bankAccounts?.ength >
        0
      ) {
        setDisabledButton(false);
      } else {
        setDisabledButton(true);
      }
    }
  }, [bankAccountsSectionData]);

  const handleBackNavigation = () => {
    dispatch(setSelectedSection('Información De Mi Empresa'));
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
    );
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
    );
  };

  const addBankAccount = () => {
    // btnCloseModalRef.current.click();
    // reset();
    // let data = {};
    // //Si bankAccountSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    // if(bankAccountSectionId) {
    //     if(monedasCuentasBancarias === "Pesos (RD$)") {
    //         // alert("Entro aqui");
    //         data = {
    //             "formResponseId": formResponseByIdentification?.data?.formResponses[0]?.id,
    //             "id": bankAccountSectionId,
    //             "bankAccountInputTypes": {
    //                 "accountHolder": titularCuenta,
    //                 "accountNumber": numeroCuentaBancaria,
    //                 "bankAccountTypeId": tipoCuentaBancaria,
    //                 "bankId": institucion,
    //                 "currencyId": currencySelected
    //             }
    //         }
    //     }
    //     else if(monedasCuentasBancarias === "Dólares (US$)") {
    //         // TODO falta colocar el campo abaOSwift para realizar su guardado
    //         data = {
    //             "formResponseId": formResponseByIdentification?.data?.formResponses[0]?.id,
    //             "id": bankAccountSectionId,
    //             "bankAccountInputTypes": {
    //                 "accountHolder": titularCuenta,
    //                 "accountNumber": numeroCuentaBancaria,
    //                 "bankAccountTypeId": tipoCuentaBancaria,
    //                 "bankId": institucion,
    //                 "currencyId": currencySelected
    //             }
    //         }
    //     }
    // }
    // else {
    //     if(monedasCuentasBancarias === "Pesos (RD$)") {
    //         data = {
    //             "formResponseId": formResponseByIdentification?.data?.formResponses[0]?.id,
    //             "bankAccountInputTypes": {
    //                 "accountHolder": titularCuenta,
    //                 "accountNumber": numeroCuentaBancaria,
    //                 "bankAccountTypeId": tipoCuentaBancaria,
    //                 "bankId": institucion,
    //                 "currencyId": currencySelected
    //             }
    //         }
    //     }
    //     else if(monedasCuentasBancarias === "Dólares (US$)") {
    //         // TODO falta colocar el campo abaOSwift para realizar su guardado
    //         data = {
    //             "formResponseId": formResponseByIdentification?.data?.formResponses[0]?.id,
    //             "bankAccountInputTypes": {
    //                 "accountHolder": titularCuenta,
    //                 "accountNumber": numeroCuentaBancaria,
    //                 "bankAccountTypeId": tipoCuentaBancaria,
    //                 "bankId": institucion,
    //                 "currencyId": currencySelected
    //             }
    //         }
    //     }
    // }
    // saveBankAccountSection({ variables: {
    //     bankAccountSection: data
    // }});
  };

  const deleteCardElement = (id) => {
    removeBankAccount({
      variables: {
        bankAccountId: id,
      },
    });
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="bff-title">Cuentas Bancarias</h3>

          <div className="add-card-container">
            <div
              className="add-card"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="image-icon-container">
                <FontAwesomeIcon icon={faAdd} className="image-icon" />
              </div>
              <h4 className="bk-card-title">Agregar cuenta</h4>
            </div>
          </div>

          <div className="cards-container-add-element">
            {bankAccountsSectionData?.data?.bankAccountSection?.bankAccounts?.map(
              (account, index) => {
                // console.log("account: ", account);
                return (
                  <CardItems
                    key={index}
                    nameElement={account?.accountHolder}
                    identityDocument={account?.accountNumber}
                    idElement={account?.id}
                    deleteElement={deleteCardElement}
                    tipoCuenta={
                      'Cuenta en ' + account?.currency?.name === 'DOP'
                        ? 'pesos'
                        : 'dolares'
                    }
                  />
                );
              }
            )}
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="button"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form
              onSubmit={handleSubmit(addBankAccount)}
              className="fields-modal-container"
            >
              <div className="fields-modal-header">
                <h4 className="modal-title" id="exampleModalLabel">
                  Agregar Cuenta Bancaria
                </h4>
                <button
                  type="button"
                  ref={btnCloseModalRef}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    reset();
                    setNotificationMessage('');
                  }}
                ></button>
              </div>

              <div className="row fields-container">
                <div className="col-md-6 col-sm-12 pe-4 columns">
                  <div className="field-form">
                    <label
                      htmlFor="titularPrincipalIgualTitularCuenta"
                      className="label-field"
                    >
                      ¿Titular principal es igual al titular de Cuenta bancaria?
                    </label>
                    <select
                      id="titularPrincipalIgualTitularCuenta"
                      defaultValue={'DEFAULT'}
                      {...register('titularPrincipalIgualTitularCuenta', {
                        // validate: validateNonSelectedOptionInDropdown
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                    {/* {errors.titularPrincipalIgualTitularCuenta && <p className='text-danger'>Debe seleccionar una opción</p>} */}
                  </div>

                  <div className="field-form">
                    <label htmlFor="nombreTitular" className="label-field">
                      Nombre Titular de la cuenta <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="nombreTitular"
                      {...register('nombreTitular', {
                        required: true,
                      })}
                    />
                    {errors.nombreTitular?.type === 'required' && (
                      <p className="text-danger">Este campo es requerido</p>
                    )}
                  </div>

                  <div className="field-form">
                    <label htmlFor="tipo-documento" className="label-field">
                      Tipo de identificación <span>*</span>
                    </label>
                    <select
                      id="tipo-documento"
                      defaultValue={'DEFAULT'}
                      {...register('tipoDocumento', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {/* {identificationTypeList?.identificationTypeList.map((identificationType) => {
                                            if(identificationType.name !== "RNC" && identificationType.name !== "Numero Unico de Identidad") {
                                                return <option key={identificationType.id} value={identificationType.id}>{ identificationType.name }</option>
                                            }
                                        })} */}

                      {identificationTypeList?.identificationTypeList.map(
                        (identificationType) => (
                          <option
                            key={identificationType.id}
                            value={identificationType.id}
                          >
                            {identificationType.name}
                          </option>
                        )
                      )}
                    </select>
                    {errors.tipoDocumento && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  <div className="field-form">
                    <label htmlFor="numero-doc" className="label-field">
                      Número de identificación <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="numero-doc"
                      {...register('numeroDoc', {
                        required: true,
                      })}
                    />
                    {errors.numeroDoc?.type === 'required' && (
                      <p className="text-danger">
                        El campo Número de documento es requerido
                      </p>
                    )}
                  </div>

                  <div className="field-form">
                    <label
                      htmlFor="tieneCuentaEnDolares"
                      className="label-field"
                    >
                      ¿Tiene Cuenta bancaria en dólares EE.UU.?
                    </label>
                    <select
                      id="tieneCuentaEnDolares"
                      defaultValue={'DEFAULT'}
                      {...register('tieneCuentaEnDolares', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                    {errors.tieneCuentaEnDolares && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                  </div>

                  {tieneCuentaEnDolares === 'Si' ? (
                    <>
                      <div className="field-form">
                        <label htmlFor="tipoBanco" className="label-field">
                          Tipo de Banco <span>*</span>
                        </label>
                        <select
                          id="tipoBanco"
                          defaultValue={'DEFAULT'}
                          {...register('tipoBanco', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          <option value="Local">Local</option>
                          <option value="Internacional">Internacional</option>
                        </select>
                        {errors.tipoBanco && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>

                <div className="col-md-6 col-sm-12 pe-4 columns">
                  {tipoBanco === 'Local' ? (
                    <>
                      <div className="field-form">
                        <label
                          htmlFor="entidadBancaria"
                          className="label-field"
                        >
                          Entidad Bancaria <span>*</span>
                        </label>
                        <select
                          id="entidadBancaria"
                          defaultValue={'DEFAULT'}
                          {...register('entidadBancaria', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {banks?.banks?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        {errors.entidadBancaria && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="tipo-cuenta-bancaria"
                          className="label-field"
                        >
                          Tipo de cuenta <span>*</span>
                        </label>
                        <select
                          id="tipo-cuenta-bancaria"
                          defaultValue={'DEFAULT'}
                          {...register('tipoCuentaBancaria', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {bankAccountTypes?.bankAccountTypes?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        {errors.tipoCuentaBancaria && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="numero-cuenta-bancaria"
                          className="label-field"
                        >
                          Número de cuenta bancaria <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="input-form"
                          placeholder=""
                          id="numero-cuenta-bancaria"
                          {...register('numeroCuentaBancaria', {
                            required: true,
                          })}
                        />
                        {errors.numeroCuentaBancaria?.type === 'required' && (
                          <p className="text-danger">
                            El campo Número de cuenta bancaria es requerido
                          </p>
                        )}
                      </div>
                    </>
                  ) : null}

                  {tipoBanco === 'Internacional' ? (
                    <>
                      <div className="field-form">
                        <label
                          htmlFor="entidadBancaria"
                          className="label-field"
                        >
                          Entidad Bancaria USD (Internacional) <span>*</span>
                        </label>
                        <select
                          id="entidadBancaria"
                          defaultValue={'DEFAULT'}
                          {...register('entidadBancaria', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {banks?.banks?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        {errors.entidadBancaria && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="tipo-cuenta-bancaria"
                          className="label-field"
                        >
                          Tipo de cuenta <span>*</span>
                        </label>
                        <select
                          id="tipo-cuenta-bancaria"
                          defaultValue={'DEFAULT'}
                          {...register('tipoCuentaBancaria', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {bankAccountTypes?.bankAccountTypes?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                        {errors.tipoCuentaBancaria && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="numero-cuenta-bancaria"
                          className="label-field"
                        >
                          Número de cuenta bancaria <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="input-form"
                          placeholder=""
                          id="numero-cuenta-bancaria"
                          {...register('numeroCuentaBancaria', {
                            required: true,
                          })}
                        />
                        {errors.numeroCuentaBancaria?.type === 'required' && (
                          <p className="text-danger">
                            El campo Número de cuenta bancaria es requerido
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label htmlFor="codigoAba" className="label-field">
                          Código ABA <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="codigoAba"
                          {...register('codigoAba', {
                            required: true,
                          })}
                        />
                        {errors.codigoAba?.type === 'required' && (
                          <p className="text-danger">
                            El campo Código ABA es requerido
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label htmlFor="codigoSwift" className="label-field">
                          Código Swift <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="codigoSwift"
                          {...register('codigoSwift', {
                            required: true,
                          })}
                        />
                        {errors.codigoSwift?.type === 'required' && (
                          <p className="text-danger">
                            El campo Código Swift es requerido
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="direccionBancoIntermediario"
                          className="label-field"
                        >
                          Dirección del Banco Intermediario <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="direccionBancoIntermediario"
                          {...register('direccionBancoIntermediario', {
                            required: true,
                          })}
                        />
                        {errors.direccionBancoIntermediario?.type ===
                          'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="bancoIntermediario"
                          className="label-field"
                        >
                          Banco Intermediario <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="bancoIntermediario"
                          {...register('bancoIntermediario', {
                            required: true,
                          })}
                        />
                        {errors.bancoIntermediario?.type === 'required' && (
                          <p className="text-danger">Este campo es requerido</p>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="row d-flex justify-content-center">
                <button type="submit" className="mt-3 mb-5 btn-add">
                  Agregar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccounts;
