import { CircularProgress } from '@mui/material';
import './loadDescription.css';

export default function LoadDescription({ description = 'Cargando...' }) {
  return (
    <div className="load-description-container">
      <img className="load-logoParval" alt="logo" src="/logo.png" />
      <div className="load-description">
        <div className="load-local-spinner">
          <CircularProgress color="warning" />
        </div>
        <strong>{description}</strong>
      </div>
    </div>
  );
}
