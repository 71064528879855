import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconSantoDomingo from '../../../../shared/icons/iconSantoDomingo.svg';
import IconSantiago from '../../../../shared/icons/iconSantiago.svg';
import IconMacoris from '../../../../shared/icons/iconMacoris.svg';
import { CircularProgress } from '@mui/material';

const SelectBranchYourChoice = () => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);
  const [branchSelected, setBranchSelected] = useState('');
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const branchAddresses = {
    '3c0d1ae2-2049-eb11-a813-000d3a579ca6':
      'Ave. Abraham Lincoln 1057, Edificio Mil57, 2do Piso, Serralles, Santo Domingo 10129',
    '20f1fb3c-2149-eb11-a813-000d3a579ca6':
      'Av. Presidente Antonio Guzmán Fernandez Esq. El Tejar No. 2 Local 1B, San Francisco de Macorís, Prov. Duarte',
    'deb9231d-bc47-ec11-8c62-00224829fa13':
      'Ave. Juan Pablo Duarte No. 172 Santiago de los Caballeros',
  };

  const branchNames = {
    '3c0d1ae2-2049-eb11-a813-000d3a579ca6': 'Oficina Abraham Lincoln',
    '20f1fb3c-2149-eb11-a813-000d3a579ca6': 'Oficina San Francisco de Macorís',
    'deb9231d-bc47-ec11-8c62-00224829fa13':
      'Oficina Santiago de los caballeros',
  };

  const branchIcons = {
    '3c0d1ae2-2049-eb11-a813-000d3a579ca6': IconSantoDomingo,
    '20f1fb3c-2149-eb11-a813-000d3a579ca6': IconSantiago,
    'deb9231d-bc47-ec11-8c62-00224829fa13': IconMacoris,
  };

  const selectBranch = (index, branchId) => {
    setSelectedCardIndex(index);
    setBranchSelected(branchId);
  };

  const continueNextPage = () => {
    if (branchSelected !== '') {
      localStorage.setItem('branch', branchSelected);
      navigate('/register/select-sales-representative');
    } else {
      // Codigo en caso de que no se halla seleccionado ninguna sucursal y se le haga click al boton continuar
    }
  };

  return (
    <div className="page-container">
      <div className="logo-parval-container">
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="formTitle">¡Hola! Bienvenido a Parval</h3>
        <p className="formSubTitle">
          Para continuar tu solicitud selecciona la sucursal de tu preferencia.
        </p>
        <div className="cards-container">
          {isLoading ? (
            <div className="w-100 d-flex justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            branches.map((branch, index) => {
              const isSelected = selectedCardIndex === index;

              return (
                // This is to not show the main branch as an option
                branch.branchId !== 'b3803a10-0d9d-ea11-a813-000d3a19b041' ? (
                  <div
                    className={`boxButton ${branchSelected === branch.branchId ? 'selected' : ''}`}
                    onClick={() => selectBranch(index, branch.branchId)}
                    key={branch.branchId}
                  >
                    <div>
                      <div className="iconContainer">
                        <img src={branchIcons[branch.branchId]} alt="" />
                      </div>
                      <h4 className="rwf-card-title">
                        {branchNames[branch.branchId]}
                      </h4>
                      <p className="card-text">
                        {branchAddresses[branch.branchId]}
                      </p>
                    </div>
                  </div>
                ) : null
              );
            })
          )}

          {/* <div className='fill-form-way-card me-4' style={divStyleSelectedAbrahanLincol} onClick={() => setCardClicked("AbrahamLincoln")}>
                      <FontAwesomeIcon icon={faBuilding} className='card-icon' />
                      <h4 className='rwf-card-title'>Oficina Abraham Lincoln</h4>
                      <p className='card-text'>Ave. Abraham Lincoln 1057, Edificio Mil57, 2do Piso, Serralles, Santo Domingo 10129</p>
                  </div>
  
                  <div className='fill-form-way-card me-4' style={divStyleSelectedProlongacion27} onClick={() => setCardClicked("Prolongacion27")}>
                      <FontAwesomeIcon icon={faBuilding} className='card-icon' />
                      <h4 className='rwf-card-title'>Oficina Prolongación 27</h4>
                      <p className='card-text'>Prolongacion Av, 27 de Febrero 1762 Alameda, Santo Domingo 10902 República Dominicana</p>
                  </div>
  
                  <div className='fill-form-way-card me-4' style={divStyleSelectedSanFrancisco} onClick={() => setCardClicked("SanFrancisco")}>
                      <FontAwesomeIcon icon={faBuilding} className='card-icon' />
                      <h4 className='rwf-card-title'>Oficina San Francisco de Macorís</h4>
                      <p className='card-text'>Av. Presidente Antonio Guzmán Fernandez Esq. El Tejar No. 2 Local 1B, San Francisco de Macorís, Prov. Duarte</p>
                  </div>

                  <div className='fill-form-way-card' style={divStyleSelectedSantiago} onClick={() => setCardClicked("Santiago")}>
                      <FontAwesomeIcon icon={faBuilding} className='card-icon' />
                      <h4 className='rwf-card-title'>Oficina Santiago de los caballeros</h4>
                      <p className='card-text'>Ave. Juan Pablo Duarte No. 172 Santiago de los Caballeros</p>
                  </div> */}
        </div>

        <button
          type="submit"
          className={`mt-5 btn-continue ${branchSelected != '' ? 'active' : ''}`}
          onClick={continueNextPage}
        >
          Continuar &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
};

export default SelectBranchYourChoice;
