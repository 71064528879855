import React from 'react';
import { Container, Col, Row, Stack } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { ThemeProvider, createTheme } from '@mui/material';
import Chart from '../../../components/Chart';
import Layout from '../../../layout/index.js';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
export default function HistoryOperations() {
  const mytheme = createTheme({});
  return (
    <Layout>
      <Container fluid>
        <Row>
          <Col>
            <div className="boxActive">
              <p className="amountCard_p_active">Posición Consolidada DOP$</p>
              <strong className="amountCard_strong_active">$220,700</strong>
            </div>
          </Col>
          <Col>
            <div className="amountCard box">
              <p className="amountCard_p">Posición Consolidada USD$</p>
              <strong className="amountCard_strong">$220,700</strong>
            </div>
          </Col>
          <Col>
            <div className="amountCard box">
              <p className="amountCard_p">Tipo de Cambio</p>
              <Row>
                <Col>
                  <p className="amountCard_minip">DOP</p>
                  <strong className="amountCard_mini_strong">1.0000</strong>
                </Col>
                <Col>
                  <p className="amountCard_minip">USD</p>
                  <strong className="amountCard_mini_strong">56.4723</strong>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="box">
              <h3>Resumen de Operaciones</h3>
              <Container style={{ padding: 0 }}>
                <Row>
                  <Col>
                    <Stack direction="horizontal" gap={3}>
                      <div className="iconCont green">
                        <ArrowUpwardIcon
                          fontSize="small"
                          style={{ color: '#5C5D5F' }}
                        />
                      </div>
                      <span className="iconContText">
                        <strong>$ 100,500</strong>
                        <p>Capital sumado en el año</p>
                      </span>
                    </Stack>
                  </Col>
                  <Col xs={6}>
                    <Stack direction="horizontal" gap={3}>
                      <div className="iconCont orange">
                        <ArrowDownwardIcon
                          fontSize="small"
                          style={{ color: '#5C5D5F' }}
                        />
                      </div>
                      <span className="iconContText">
                        <strong>$ 100,500</strong>
                        <p>Capital sumado en el año</p>
                      </span>
                    </Stack>
                  </Col>
                  <Col>
                    <strong>Próximo Vencimiento</strong>
                    <p>30 - 05 - 2023</p>
                  </Col>
                </Row>
              </Container>
              <Chart />
              <br />
              <br />
              <h3>Historial de Operaciones</h3>
              <ThemeProvider theme={mytheme}>
                <MaterialTable
                  columns={[
                    { title: 'Titulo', field: 'title' },
                    { title: 'Producto', field: 'product' },
                    { title: 'Moneda', field: 'currency' },
                    { title: 'Inicio', field: 'start' },
                    { title: 'Vencimiento', field: 'expiration' },
                    { title: 'Monto Inicial', field: 'initialAmount' },
                    { title: 'Rendimiento', field: 'performance' },
                    { title: 'Monto Final', field: 'finalAmount' },
                  ]}
                  data={[
                    {
                      title: 'Le0623',
                      product: 'Mutuo',
                      currency: 'DOP',
                      start: '16/01/2023',
                      expiration: '16/01/2023',
                      initialAmount: '33,333.33',
                      performance: '12.00%',
                      finalAmount: '476,789.99',
                    },
                    {
                      title: 'Le0623',
                      product: 'Mutuo',
                      currency: 'DOP',
                      start: '16/01/2023',
                      expiration: '16/01/2023',
                      initialAmount: '33,333.33',
                      performance: '12.00%',
                      finalAmount: '476,789.99',
                    },
                  ]}
                  options={{
                    toolbar: false,
                    search: false,
                    paging: false,
                  }}
                />
              </ThemeProvider>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
