/* eslint-disable no-unreachable */
import './MainShareholdersForm.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faWarning,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import {
  GET_COMPANY_SHAREHOLDER_SECTION,
  GET_COUNTRIES,
  GET_ECONOMIC_ACTIVITIES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_IDENTITYTYPES,
  GET_PARVAL_RELATIONSHIPS,
  GET_PERSONTYPES,
  GET_POLITIC_EXPOSURE_TYPES,
  GET_SOCIETY_TYPES,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  REMOVE_SHAREHOLDER,
  SAVE_COMPANY_SHAREHOLDER_SECTION,
  CHECK_LEXIS_NEXIS,
  CHECK_POLITIC_EXPOSURE,
  SAVE_BENEFICIARY,
  REMOVE_BENEFICIARY,
} from '../../../../../shared/graphQL/mutations';
import { toast } from 'react-toastify';
import {
  comprexAddress,
  convertBooleanToInput,
  convertInputToBoolean,
  formatSimple,
  mergeObjectsWithSameId,
  parseSimple,
  preFormat,
  validateInputCedula,
  validateInputRnc,
} from '../../../../../shared/utils/functions';
import { useDispatch } from 'react-redux';
import { loadPersons } from '../../../../../shared/store/slices/clientePotencial';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { Error } from '@mui/icons-material';

const MainShareholdersForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const {
    register: registerShares,
    formState: { errors: errorsShares },
    watch: watchShare,
    handleSubmit: handleSubmitShare,
    setValue: setValueShare,
  } = useForm();
  const {
    register: registerBeneficiary,
    formState: { errors: errorsBeneficiary },
    watch: watchBeneficiary,
    handleSubmit: handleSubmitBeneficiary,
    reset: resetBeneficiary,
    setValue: setValueBeneficiary,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const { data: personTypeList } = useQuery(GET_PERSONTYPES, {
    fetchPolicy: 'no-cache',
  });
  const { data: countries } = useQuery(GET_COUNTRIES);
  const { data: identificationTypeList } = useQuery(GET_IDENTITYTYPES);
  const { data: societyTypeList } = useQuery(GET_SOCIETY_TYPES);
  const { data: economicActivities } = useQuery(GET_ECONOMIC_ACTIVITIES);
  const { data: parvalRelationships } = useQuery(GET_PARVAL_RELATIONSHIPS);
  const [formResponseId, setFormResponseId] = useState('');
  const [nombreTipoPersona, setNombreTipoPersona] = useState('');

  const [
    saveCompanyShareholdersSection,
    { loading: loadingShareSave },
  ] = useMutation(SAVE_COMPANY_SHAREHOLDER_SECTION, {
    refetchQueries: [
      {
        query: GET_COMPANY_SHAREHOLDER_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });

  const [checkLexisNexis, { data: lexisNexisData }] =
    useMutation(CHECK_LEXIS_NEXIS);
  const [removeShareholder, { loading: loadingShareRemove }] = useMutation(
    REMOVE_SHAREHOLDER,
    {
      refetchQueries: [
        {
          query: GET_COMPANY_SHAREHOLDER_SECTION,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [
    getCompanyShareholdersSectionData,
    { data: companyShareholdersSectionData, loading: loadingShareGet },
  ] = useLazyQuery(GET_COMPANY_SHAREHOLDER_SECTION);
  const [companyShareholdersSectionId, setCompanyShareholdersSectionId] =
    useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedShareholderId, setSelectedShareholderId] = useState('');
  const [companyOrPersionDetailsId, setCompanyOrPersionDetailsId] =
    useState('');
  const dispatch = useDispatch();
  const { persons, typeObligatedSubject, isFormCompleted, isFormDisqualified, validation } =
    useSelector((state) => state.clientePotencialSlice);
  const [fatcaId, setFatcaId] = useState('');
  const [politicExposureId, setPoliticExposureId] = useState('');
  const [notCheck, setNotCheck] = useState(false);
  const [existingShareholder, setExistingShareholder] = useState(false);
  const { data: politicExposureTypes } = useQuery(GET_POLITIC_EXPOSURE_TYPES);
  const [
    checkPoliticExposure,
    { data: checkPoliticExposureResponse, error: checkPoliticExposureError },
  ] = useMutation(CHECK_POLITIC_EXPOSURE);
  const [
    checkPoliticExposureBeneficiary,
    {
      data: checkPoliticExposureResponseBeneficiary,
      error: checkPoliticExposureErrorBeneficiary,
    },
  ] = useMutation(CHECK_POLITIC_EXPOSURE);

  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isUpdatingBeneficiary, setIsUpdatingBeneficiary] = useState(false);
  const [existingBeneficiary, setExistingBeneficiary] = useState(false);
  const [politicExposureBeneficiaryId, setPoliticExposureBeneficiaryId] =
    useState('');
  const [fatcaBeneficiaryId, setFatcaBeneficiaryId] = useState('');
  const [notCheckBeneficiary, setNotCheckBeneficiary] = useState(false);
  const [loadingSection, setLoadingSection] = useState(true);
  const [contacts, setContacts] = useState([]);

  const numeroAccionesEmitidas = watchShare('numeroAccionesEmitidas');
  const numeroAccionesDistribuidas = watchShare('numeroAccionesDistribuidas');

  const tipoPersona = watch('tipoPersona');

  //Persona fisica
  const nombre = watch('nombre');
  const apellido = watch('apellido');
  const tipoDocumento = watch('tipoDocumento');
  const numeroDoc = watch('numeroDoc');
  const [nacionalidad, setNacionalidad] = useState(null);
  const [inputNacionalidad, setInputNacionalidad] = useState('');
  const cargo = watch('cargo');
  const numeroAcciones = watch('numeroAcciones');
  const porcentajeParticipacion = watch('porcentajeParticipacion');

  //persona juridica (tambien incluye numeroAcciones y porcentajeParticipacion)
  const tipoSociedad = watch('tipoSociedad');
  const denominacionSocial = watch('denominacionSocial');
  const relacion = watch('relacion');
  const accionistasMayoritarios = watch('accionistasMayoritarios');
  const poseeAccionesPortador = watch('poseeAccionesPortador');
  const [actividadEconomica, setActividadEconomica] = useState(null);
  const [inputActividadEconomica, setInputActividadEconomica] = useState('');

  const personaPoliticamenteExpuesta = watch('personaPoliticamenteExpuesta');
  const cargoPep = watch('cargoPep');
  const institucion = watch('institucion');
  const fechaInicioCargo = watch('fechaInicioCargo');
  const ultimaFechaCargo = watch('ultimaFechaCargo');
  const tipoExposicionPolitica = watch('tipoExposicionPolitica');
  const nombreDelFamiliar = watch('nombreDelFamiliar');
  const tipoRelacionFamiliar = watch('tipoRelacionFamiliar');
  const pepActivo = watch('pepActivo');

  const tieneNacionalidadEU = watch('tieneNacionalidadEU');
  const esCiudadanoEU = watch('esCiudadanoEU');
  const esResidenteEU = watch('esResidenteEU');
  const seguroSocial = watch('seguroSocial');
  const codigoPostalEU = watch('codigoPostalEU');
  const telefonoEU = watch('telefonoEU');
  const tieneRepresentanteEU = watch('tieneRepresentanteEU');
  const tieneTIN = watch('tieneTIN');
  const tin = watch('tin');
  const tarjetaVerde = watch('tarjetaVerde');
  const direccionEU = watch('direccionEU');

  useEffect(() => {
    if (
      typeof numeroDoc === 'string' &&
      getIdentificationTypeName(tipoDocumento) ===
        'Cedula de Identidad y Electoral' &&
      !notCheck
    ) {
      if (numeroDoc.length > 12) {
        verifiyIfIsPep();
      } else {
        setValue('personaPoliticamenteExpuesta', 'No');
      }
    }
  }, [numeroDoc, notCheck]);

  useEffect(() => {
    if (
      getIdentificationTypeName(tipoDocumento) !==
        'Cedula de Identidad y Electoral' &&
      personaPoliticamenteExpuesta === 'Si' &&
      !notCheck
    ) {
      setValue('personaPoliticamenteExpuesta', 'No');
      setValue('pepActivo', 'No');
      setValue('cargoPep', null);
      setValue('institucion', null);
      setValue('fechaInicioCargo', null);
      setValue('ultimaFechaCargo', null);
      setValue('tipoExposicionPolitica', 'DEFAULT');
      setValue('nombreDelFamiliar', null);
      setValue('tipoRelacionFamiliar', null);
    }
  }, [tipoDocumento]);

  const getIdentificationTypeName = (tipoDocumento) => {
    const identificationType =
      identificationTypeList?.identificationTypeList?.find(
        (x) => x.id === tipoDocumento
      );
    return identificationType?.name;
  };

  const getPoliticExposureTypeName = (politicExposureTypeId) => {
    const politicExposureType =
      politicExposureTypes?.politicExposureTypes?.find(
        (x) => x.id === politicExposureTypeId
      );
    return politicExposureType?.name;
  };

  const verifiyIfIsPep = () => {
    checkPoliticExposure({
      variables: {
        identification: numeroDoc,
      },
    });
  };

  const verifiyIfIsPepBeneficiary = () => {
    checkPoliticExposureBeneficiary({
      variables: {
        identification: numeroDocBeneficiario,
      },
    });
  };

  const getAvailableOwnershipPercentage = () => {
    const shareholders =
      companyShareholdersSectionData?.companyShareholdersSection
        ?.shareholders ?? [];
    const filteredShareholders = shareholders.filter(
      (filteredShareholder) => filteredShareholder.id !== selectedShareholderId
    );

    const total = filteredShareholders.reduce((acc, shareholder) => {
      return acc + Number(shareholder.ownershipPercentage ?? 0);
    }, 0);

    const availableOwnershipPercentage = 100 - total;

    return trimToTwoDecimals(availableOwnershipPercentage);
  };

  const getAvailablePercentage = () => {
    const selected =
      companyShareholdersSectionData?.companyShareholdersSection?.shareholders?.find(
        (shareholders) => shareholders.id === selectedShareholderId
      );
    return trimToTwoDecimals(
      getAvailableOwnershipPercentage() -
        Number(selected?.ownershipPercentage ?? 0)
    );
  };

  const calculateSharesNumber = () => {
    const totalDistributedShares =
      companyShareholdersSectionData?.companyShareholdersSection
        ?.totalDistributedShares;
    const availableOwnershipPercentage = getAvailableOwnershipPercentage();
    let percentage = porcentajeParticipacion;

    if (percentage > availableOwnershipPercentage) {
      percentage = availableOwnershipPercentage;
      setValue('porcentajeParticipacion', availableOwnershipPercentage);
    }

    if (percentage && percentage < 0) {
      setValue('porcentajeParticipacion', 0);
    }

    if (!percentage) {
      setValue('numeroAcciones', undefined);
    }

    if (totalDistributedShares) {
      const shares = trimToTwoDecimals(
        totalDistributedShares * (percentage / 100)
      );

      setValue('numeroAcciones', shares);
    }
  };

  const calculateOwnershipPercentage = () => {
    const totalDistributedShares = Number(numeroAccionesDistribuidas);
    const availableOwnershipPercentage = Number(
      getAvailableOwnershipPercentage()
    );
    const availableShares =
      totalDistributedShares * (availableOwnershipPercentage / 100);
    const sharesToUse = selectedShareholderId
      ? numeroAcciones + availableShares
      : totalDistributedShares * (availableOwnershipPercentage / 100);
    let shares = numeroAcciones;

    if (shares > sharesToUse) {
      shares = sharesToUse;
      setValue('numeroAcciones', sharesToUse);
    }

    if (shares && shares < 0) {
      setValue('numeroAcciones', 0);
    }

    if (!shares) {
      setValue('porcentajeParticipacion', undefined);
    }

    if (shares && sharesToUse) {
      const ownershipPercentage = trimToTwoDecimals(
        (shares / totalDistributedShares) * 100
      );

      setValue('porcentajeParticipacion', ownershipPercentage);
    }
  };

  const trimToTwoDecimals = (value) => {
    return Number(value.toFixed(2));
  };

  useEffect(() => {
    if (checkPoliticExposureResponse) {
      setValue(
        'personaPoliticamenteExpuesta',
        convertBooleanToInput(
          checkPoliticExposureResponse.checkPoliticExposure.isPEP
        )
      );
      if (checkPoliticExposureResponse.checkPoliticExposure.isPEP === true) {
        setValue(
          'cargoPep',
          checkPoliticExposureResponse.checkPoliticExposure.charge
        );
        setValue(
          'tipoExposicionPolitica',
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .id
        );
        if (
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .name === 'Familiar'
        ) {
          setValue(
            'nombreDelFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure.relativeName
          );
          setValue(
            'tipoRelacionFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure
              .relativeRelationship
          );
        }
        toast('Esta es una Persona Politicamente Expuesta', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        toast('Esta no es una Persona Politicamente Expuesta', {
          type: 'success',
          theme: 'colored',
        });
      }
    }
  }, [checkPoliticExposureResponse]);

  useEffect(() => {
    const option = personTypeList?.personTypeList.find(
      (x) => x?.id === tipoPersona
    );
    setNombreTipoPersona(option?.name);
  }, [tipoPersona]);

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );
      getCompanyShareholdersSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (companyShareholdersSectionData) {
      setCompanyShareholdersSectionId(
        companyShareholdersSectionData?.companyShareholdersSection?.id
      );

      setValueShare(
        'numeroAccionesEmitidas',
        companyShareholdersSectionData?.companyShareholdersSection
          ?.totalSharesIssued ?? 0
      );
      setValueShare(
        'numeroAccionesDistribuidas',
        companyShareholdersSectionData?.companyShareholdersSection
          ?.totalDistributedShares ?? 0
      );
      setLoadingSection(false);
    }
  }, [companyShareholdersSectionData]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedShareholderId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const handleBackNavigation = () => {
    if (sessionStorage.getItem('obligatedSubject') || typeObligatedSubject) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/sujeto-obligado'
      );
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/direcciones-de-oficinas'
      );
    }
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/empresas-afiliadas'
    );
  };

  const addMainShareholders = async () => {
    if (!numeroAccionesDistribuidas || !numeroAccionesEmitidas) {
      toast(
        'Es necesario que la entidad cuente con acciones emitidas y distribuidas.',
        {
          type: 'error',
          theme: 'colored',
        }
      );
      btnCloseModalRef.current.click();
      resetAllInputs();
      return;
    }

    if (nombreTipoPersona === 'Persona Jurídica' && !beneficiaries.length) {
      toast(
        'Debes agregar por lo menos un beneficiario para guardar un accionista',
        {
          type: 'error',
          theme: 'colored',
        }
      );
      return;
    }

    const identificationIsInUse =
      companyShareholdersSectionData?.companyShareholdersSection?.shareholders?.find(
        (shareholder) =>
          shareholder.id !== selectedShareholderId &&
          numeroDoc === shareholder.identification &&
          shareholder.identificationType.id === tipoDocumento
      );

    if (identificationIsInUse) {
      toast('Ya existe un accionista con esta identificación', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    btnCloseModalRef.current.click();
    resetAllInputs();

    let data = {};

    // Si no hay accionistas agregados o si no esta agregando o editando
    if (nombre === undefined && denominacionSocial === undefined) {
      if (
        companyShareholdersSectionId !== null &&
        companyShareholdersSectionId !== undefined
      ) {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: companyShareholdersSectionId,
          totalSharesIssued: numeroAccionesEmitidas
            ? Number(numeroAccionesEmitidas)
            : 0,
          totalDistributedShares: numeroAccionesDistribuidas
            ? Number(numeroAccionesDistribuidas)
            : 0,
          shareholderInputTypes:
            companyShareholdersSectionData?.companyShareholdersSection?.shareholders.map(
              (shareholder) => {
                return {
                  id: shareholder?.id ?? null,
                  personTypeId: shareholder.personType?.id ?? null,
                  isInDarkList: shareholder.isInDarkList,
                  shareholderPersonDetailsInputType:
                    shareholder.shareholderPersonDetails
                      ? {
                          id: shareholder.shareholderPersonDetails?.id ?? null,
                          firstName:
                            shareholder.shareholderPersonDetails?.firstName ??
                            null,
                          lastName:
                            shareholder.shareholderPersonDetails?.lastName ??
                            null,
                          charge:
                            shareholder.shareholderPersonDetails?.charge ??
                            null,
                          politicExposureInputType:
                            personaPoliticamenteExpuesta === 'Si'
                              ? {
                                  id: politicExposureId
                                    ? politicExposureId
                                    : null,
                                  isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                                  active: convertInputToBoolean(pepActivo),
                                  charge: cargoPep,
                                  relativeName: nombreDelFamiliar,
                                  relativeRelationship: tipoRelacionFamiliar,
                                  politicExposureTypeId: tipoExposicionPolitica,
                                  chargeStartDate: fechaInicioCargo
                                    ? parseSimple(preFormat(fechaInicioCargo))
                                    : null,
                                  chargeEndDate: ultimaFechaCargo
                                    ? parseSimple(preFormat(ultimaFechaCargo))
                                    : null,
                                  institution: institucion,
                                }
                              : {},
                        }
                      : null,
                  shareholderCompanyDetailsInputType:
                    shareholder.shareholderCompanyDetails
                      ? {
                          id: shareholder.shareholderCompanyDetails?.id ?? null,
                          businessName:
                            shareholder.shareholderCompanyDetails
                              ?.businessName ?? null,
                          societyTypeId:
                            shareholder.shareholderCompanyDetails?.societyType
                              ?.id ?? null,
                          economicActivityId:
                            shareholder.shareholderCompanyDetails
                              ?.economicActivity?.id ?? null,
                          parvalRelationshipId:
                            shareholder.shareholderCompanyDetails
                              ?.parvalRelationship?.id ?? null,
                          hasBearerShares:
                            shareholder.shareholderCompanyDetails
                              ?.hasBearerShares ?? null,
                          controllingShareholders:
                            shareholder.shareholderCompanyDetails
                              ?.controllingShareholders,
                        }
                      : null,
                  identificationTypeId:
                    shareholder.identificationType?.id ?? null,
                  identification: shareholder.identification,
                  countryId: shareholder.country?.id ?? null,
                  sharesNumber: shareholder.sharesNumber,
                  ownershipPercentage: shareholder.ownershipPercentage,
                  beneficiaryIds: shareholder.beneficiaries.map(
                    (x) => x?.id ?? null
                  ),
                  contactFatcaInputType: shareholder.contactFatca
                    ? {
                        id: shareholder.contactFatca?.id ?? null,
                        haveUnitedStatesNationality:
                          shareholder.contactFatca
                            ?.haveUnitedStatesNationality ?? null,
                        isUnitedStatesCitizen:
                          shareholder.contactFatca?.isUnitedStatesCitizen ??
                          null,
                        isUnitedStatesResident:
                          shareholder.contactFatca?.isUnitedStatesResident ??
                          null,
                        socialSecurityNumber:
                          shareholder.contactFatca?.socialSecurityNumber ??
                          null,
                        zipInUnitedStates:
                          shareholder.contactFatca?.zipInUnitedStates ?? null,
                        phoneInUnitedStates:
                          shareholder.contactFatca?.phoneInUnitedStates ?? null,
                        haveRepresentativeInUnitedStates:
                          shareholder.contactFatca
                            ?.haveRepresentativeInUnitedStates ?? null,
                        haveTaxIdentificationNumber:
                          shareholder.contactFatca
                            ?.haveTaxIdentificationNumber ?? null,
                        taxIdentificationNumber:
                          shareholder.contactFatca?.taxIdentificationNumber ??
                          null,
                        greenCard: shareholder.contactFatca?.greenCard ?? null,
                        addressInUnitedStates:
                          shareholder.contactFatca?.addressInUnitedStates ??
                          null,
                      }
                    : null,
                };
              }
            ),
        };
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          totalSharesIssued: numeroAccionesEmitidas
            ? Number(numeroAccionesEmitidas)
            : 0,
          totalDistributedShares: numeroAccionesDistribuidas
            ? Number(numeroAccionesDistribuidas)
            : 0,
          shareholderInputTypes:
            companyShareholdersSectionData?.companyShareholdersSection?.shareholders.map(
              (shareholder) => {
                return {
                  id: shareholder?.id ?? null,
                  personTypeId: shareholder.personType?.id ?? null,
                  isInDarkList: shareholder.isInDarkList,
                  shareholderPersonDetailsInputType:
                    shareholder.shareholderPersonDetails
                      ? {
                          id: shareholder.shareholderPersonDetails?.id ?? null,
                          firstName:
                            shareholder.shareholderPersonDetails?.firstName ??
                            null,
                          lastName:
                            shareholder.shareholderPersonDetails?.lastName ??
                            null,
                          charge:
                            shareholder.shareholderPersonDetails?.charge ??
                            null,
                          politicExposureInputType:
                            personaPoliticamenteExpuesta === 'Si'
                              ? {
                                  id: politicExposureId
                                    ? politicExposureId
                                    : null,
                                  isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                                  active: convertInputToBoolean(pepActivo),
                                  charge: cargoPep,
                                  relativeName: nombreDelFamiliar,
                                  relativeRelationship: tipoRelacionFamiliar,
                                  politicExposureTypeId: tipoExposicionPolitica,
                                  chargeStartDate: fechaInicioCargo
                                    ? parseSimple(preFormat(fechaInicioCargo))
                                    : null,
                                  chargeEndDate: ultimaFechaCargo
                                    ? parseSimple(preFormat(ultimaFechaCargo))
                                    : null,
                                  institution: institucion,
                                }
                              : {},
                        }
                      : null,
                  shareholderCompanyDetailsInputType:
                    shareholder.shareholderCompanyDetails
                      ? {
                          id: shareholder.shareholderCompanyDetails?.id ?? null,
                          businessName:
                            shareholder.shareholderCompanyDetails
                              ?.businessName ?? null,
                          societyTypeId:
                            shareholder.shareholderCompanyDetails?.societyType
                              ?.id ?? null,
                          economicActivityId:
                            shareholder.shareholderCompanyDetails
                              ?.economicActivity?.id ?? null,
                          parvalRelationshipId:
                            shareholder.shareholderCompanyDetails
                              ?.parvalRelationship?.id ?? null,
                          hasBearerShares:
                            shareholder.shareholderCompanyDetails
                              ?.hasBearerShares ?? null,
                          controllingShareholders:
                            shareholder.shareholderCompanyDetails
                              ?.controllingShareholders,
                        }
                      : null,
                  identificationTypeId:
                    shareholder.identificationType?.id ?? null,
                  identification: shareholder.identification,
                  countryId: shareholder.country?.id ?? null,
                  sharesNumber: shareholder.sharesNumber,
                  ownershipPercentage: shareholder.ownershipPercentage,
                  beneficiaryIds: shareholder.beneficiaries.map(
                    (x) => x?.id ?? null
                  ),
                  contactFatcaInputType: shareholder.contactFatca
                    ? {
                        id: shareholder.contactFatca?.id ?? null,
                        haveUnitedStatesNationality:
                          shareholder.contactFatca
                            ?.haveUnitedStatesNationality ?? null,
                        isUnitedStatesCitizen:
                          shareholder.contactFatca?.isUnitedStatesCitizen ??
                          null,
                        isUnitedStatesResident:
                          shareholder.contactFatca?.isUnitedStatesResident ??
                          null,
                        socialSecurityNumber:
                          shareholder.contactFatca?.socialSecurityNumber ??
                          null,
                        zipInUnitedStates:
                          shareholder.contactFatca?.zipInUnitedStates ?? null,
                        phoneInUnitedStates:
                          shareholder.contactFatca?.phoneInUnitedStates ?? null,
                        haveRepresentativeInUnitedStates:
                          shareholder.contactFatca
                            ?.haveRepresentativeInUnitedStates ?? null,
                        haveTaxIdentificationNumber:
                          shareholder.contactFatca
                            ?.haveTaxIdentificationNumber ?? null,
                        taxIdentificationNumber:
                          shareholder.contactFatca?.taxIdentificationNumber ??
                          null,
                        greenCard: shareholder.contactFatca?.greenCard ?? null,
                        addressInUnitedStates:
                          shareholder.contactFatca?.addressInUnitedStates ??
                          null,
                      }
                    : null,
                };
              }
            ),
        };
      }
    } else {
      //Si companyOfficesSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
      if (
        companyShareholdersSectionId !== null &&
        companyShareholdersSectionId !== undefined
      ) {
        // Si el tipo de persona es persona juridica
        if (nombreTipoPersona === 'Persona Física') {
          if (selectedShareholderId && selectedShareholderId !== '') {
            checkLexisNexis({
              variables: {
                lexisNexisVerifyInputType: {
                  shareholderId: '',
                  legalRepresentativeId: '',
                  document_id_number: numeroDoc ?? '',
                  address: '',
                  entity_type:
                    tipoPersona === 'Persona Jurídica'
                      ? 'BUSINESS'
                      : 'INDIVIDUAL',
                  city: '',
                  last_name: apellido ?? '',
                  first_name: nombre ?? '',
                },
              },
            });
            data = {
              formResponseId:
                formResponseByIdentification?.data?.formResponses[0]?.id,
              id: companyShareholdersSectionId,
              totalSharesIssued: numeroAccionesEmitidas
                ? Number(numeroAccionesEmitidas)
                : 0,
              totalDistributedShares: numeroAccionesDistribuidas
                ? Number(numeroAccionesDistribuidas)
                : 0,
              shareholderInputTypes: {
                id: selectedShareholderId,
                isInDarkList: lexisNexisData?.checkLexisNexis?.isInDarkList
                  ? lexisNexisData?.checkLexisNexis?.isInDarkList
                  : false,
                personTypeId: tipoPersona,
                shareholderPersonDetailsInputType: {
                  id: companyOrPersionDetailsId,
                  firstName: nombre,
                  lastName: apellido,
                  charge: cargo,
                  politicExposureInputType:
                    personaPoliticamenteExpuesta === 'Si'
                      ? {
                          id: politicExposureId ? politicExposureId : null,
                          isPep: convertInputToBoolean(personaPoliticamenteExpuesta), 
                          active: convertInputToBoolean(pepActivo),
                          charge: cargoPep,
                          relativeName: nombreDelFamiliar,
                          relativeRelationship: tipoRelacionFamiliar,
                          politicExposureTypeId: tipoExposicionPolitica,
                          chargeStartDate: fechaInicioCargo
                            ? parseSimple(preFormat(fechaInicioCargo))
                            : null,
                          chargeEndDate: ultimaFechaCargo
                            ? parseSimple(preFormat(ultimaFechaCargo))
                            : null,
                          institution: institucion,
                        }
                      : {},
                },
                identificationTypeId: tipoDocumento,
                identification: numeroDoc,
                countryId: nacionalidad?.id,
                sharesNumber: Number(numeroAcciones),
                ownershipPercentage: Number(porcentajeParticipacion),
                beneficiaryIds: beneficiaries?.length
                  ? beneficiaries?.map((b) => b.id)
                  : [],
                contactFatcaInputType: {
                  id: fatcaId ? fatcaId : null,
                  haveUnitedStatesNationality:
                    convertInputToBoolean(tieneNacionalidadEU),
                  isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                  isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                  socialSecurityNumber: seguroSocial,
                  zipInUnitedStates: codigoPostalEU,
                  phoneInUnitedStates: telefonoEU,
                  haveRepresentativeInUnitedStates:
                    convertInputToBoolean(tieneRepresentanteEU),
                  haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                  taxIdentificationNumber: tin,
                  greenCard: tarjetaVerde,
                  addressInUnitedStates: direccionEU,
                },
              },
            };

            setSelectedShareholderId('');
          } else {
            checkLexisNexis({
              variables: {
                lexisNexisVerifyInputType: {
                  shareholderId: '',
                  legalRepresentativeId: '',
                  document_id_number: numeroDoc ?? '',
                  address: '',
                  entity_type:
                    tipoPersona === 'Persona Jurídica'
                      ? 'BUSINESS'
                      : 'INDIVIDUAL',
                  city: '',
                  last_name: apellido ?? '',
                  first_name: nombre ?? '',
                },
              },
            });
            data = {
              formResponseId:
                formResponseByIdentification?.data?.formResponses[0]?.id,
              id: companyShareholdersSectionId,
              totalSharesIssued: numeroAccionesEmitidas
                ? Number(numeroAccionesEmitidas)
                : 0,
              totalDistributedShares: numeroAccionesDistribuidas
                ? Number(numeroAccionesDistribuidas)
                : 0,
              shareholderInputTypes: {
                personTypeId: tipoPersona,
                isInDarkList: lexisNexisData?.checkLexisNexis?.isInDarkList
                  ? lexisNexisData?.checkLexisNexis?.isInDarkList
                  : false,
                shareholderPersonDetailsInputType: {
                  firstName: nombre,
                  lastName: apellido,
                  charge: cargo,
                  politicExposureInputType:
                    personaPoliticamenteExpuesta === 'Si'
                      ? {
                          isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                          active: convertInputToBoolean(pepActivo),
                          charge: cargoPep,
                          relativeName: nombreDelFamiliar,
                          relativeRelationship: tipoRelacionFamiliar,
                          politicExposureTypeId: tipoExposicionPolitica,
                          chargeStartDate: fechaInicioCargo
                            ? parseSimple(preFormat(fechaInicioCargo))
                            : null,
                          chargeEndDate: ultimaFechaCargo
                            ? parseSimple(preFormat(ultimaFechaCargo))
                            : null,
                          institution: institucion,
                        }
                      : {},
                },
                identificationTypeId: tipoDocumento,
                identification: numeroDoc,
                countryId: nacionalidad?.id,
                sharesNumber: Number(numeroAcciones),
                ownershipPercentage: Number(porcentajeParticipacion),
                beneficiaryIds: beneficiaries?.length
                  ? beneficiaries?.map((b) => b.id)
                  : [],
                contactFatcaInputType: {
                  haveUnitedStatesNationality:
                    convertInputToBoolean(tieneNacionalidadEU),
                  isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                  isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                  socialSecurityNumber: seguroSocial,
                  zipInUnitedStates: codigoPostalEU,
                  phoneInUnitedStates: telefonoEU,
                  haveRepresentativeInUnitedStates:
                    convertInputToBoolean(tieneRepresentanteEU),
                  haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                  taxIdentificationNumber: tin,
                  greenCard: tarjetaVerde,
                  addressInUnitedStates: direccionEU,
                },
              },
            };
          }
        }
        // Si el tipo de persona es persona fisica
        else if (nombreTipoPersona === 'Persona Jurídica') {
          if (selectedShareholderId && selectedShareholderId !== '') {
            data = {
              formResponseId:
                formResponseByIdentification?.data?.formResponses[0]?.id,
              id: companyShareholdersSectionId,
              totalSharesIssued: numeroAccionesEmitidas
                ? Number(numeroAccionesEmitidas)
                : 0,
              totalDistributedShares: numeroAccionesDistribuidas
                ? Number(numeroAccionesDistribuidas)
                : 0,
              shareholderInputTypes: {
                id: selectedShareholderId,
                personTypeId: tipoPersona,
                isInDarkList: false,
                shareholderCompanyDetailsInputType: {
                  id: companyOrPersionDetailsId,
                  businessName: denominacionSocial,
                  societyTypeId: tipoSociedad,
                  economicActivityId: actividadEconomica?.id,
                  parvalRelationshipId: relacion,
                  hasBearerShares: convertInputToBoolean(poseeAccionesPortador),
                  controllingShareholders: accionistasMayoritarios,
                },
                countryId: nacionalidad?.id,
                identificationTypeId: tipoDocumento,
                identification: numeroDoc,
                sharesNumber: Number(numeroAcciones),
                ownershipPercentage: Number(porcentajeParticipacion),
                beneficiaryIds: beneficiaries?.length
                  ? beneficiaries?.map((b) => b.id)
                  : [],
                contactFatcaInputType: {
                  id: fatcaId,
                  haveUnitedStatesNationality:
                    convertInputToBoolean(tieneNacionalidadEU),
                  isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                  isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                  socialSecurityNumber: seguroSocial,
                  zipInUnitedStates: codigoPostalEU,
                  phoneInUnitedStates: telefonoEU,
                  haveRepresentativeInUnitedStates:
                    convertInputToBoolean(tieneRepresentanteEU),
                  haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                  taxIdentificationNumber: tin,
                  greenCard: tarjetaVerde,
                  addressInUnitedStates: direccionEU,
                },
              },
            };

            setSelectedShareholderId('');
          } else {
            data = {
              formResponseId:
                formResponseByIdentification?.data?.formResponses[0]?.id,
              id: companyShareholdersSectionId,
              totalSharesIssued: numeroAccionesEmitidas
                ? Number(numeroAccionesEmitidas)
                : 0,
              totalDistributedShares: numeroAccionesDistribuidas
                ? Number(numeroAccionesDistribuidas)
                : 0,
              shareholderInputTypes: {
                personTypeId: tipoPersona,
                shareholderCompanyDetailsInputType: {
                  businessName: denominacionSocial,
                  societyTypeId: tipoSociedad,
                  economicActivityId: actividadEconomica?.id,
                  parvalRelationshipId: relacion,
                  hasBearerShares: convertInputToBoolean(poseeAccionesPortador),
                  controllingShareholders: accionistasMayoritarios,
                },
                countryId: nacionalidad?.id,
                identificationTypeId: tipoDocumento,
                identification: numeroDoc,
                sharesNumber: Number(numeroAcciones),
                isInDarkList: false,
                ownershipPercentage: Number(porcentajeParticipacion),
                beneficiaryIds: beneficiaries?.length
                  ? beneficiaries?.map((b) => b.id)
                  : [],
                contactFatcaInputType: {
                  haveUnitedStatesNationality:
                    convertInputToBoolean(tieneNacionalidadEU),
                  isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                  isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                  socialSecurityNumber: seguroSocial,
                  zipInUnitedStates: codigoPostalEU,
                  phoneInUnitedStates: telefonoEU,
                  haveRepresentativeInUnitedStates:
                    convertInputToBoolean(tieneRepresentanteEU),
                  haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                  taxIdentificationNumber: tin,
                  greenCard: tarjetaVerde,
                  addressInUnitedStates: direccionEU,
                },
              },
            };
          }
        }
      } else {
        if (nombreTipoPersona === 'Persona Física') {
          checkLexisNexis({
            variables: {
              lexisNexisVerifyInputType: {
                shareholderId: '',
                legalRepresentativeId: '',
                document_id_number: numeroDoc ?? '',
                address: '',
                entity_type:
                  tipoPersona === 'Persona Jurídica'
                    ? 'BUSINESS'
                    : 'INDIVIDUAL',
                city: '',
                last_name: apellido ?? '',
                first_name: nombre ?? '',
              },
            },
          });
          data = {
            formResponseId:
              formResponseByIdentification?.data?.formResponses[0]?.id,
            totalSharesIssued: numeroAccionesEmitidas
              ? Number(numeroAccionesEmitidas)
              : 0,
            totalDistributedShares: numeroAccionesDistribuidas
              ? Number(numeroAccionesDistribuidas)
              : 0,
            shareholderInputTypes: {
              personTypeId: tipoPersona,
              shareholderPersonDetailsInputType: {
                firstName: nombre,
                lastName: apellido,
                charge: cargo,
                politicExposureInputType:
                  personaPoliticamenteExpuesta === 'Si'
                    ? {
                        isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                        active: convertInputToBoolean(pepActivo),
                        charge: cargoPep,
                        relativeName: nombreDelFamiliar,
                        relativeRelationship: tipoRelacionFamiliar,
                        politicExposureTypeId: tipoExposicionPolitica,
                        chargeStartDate: fechaInicioCargo
                          ? parseSimple(preFormat(fechaInicioCargo))
                          : null,
                        chargeEndDate: ultimaFechaCargo
                          ? parseSimple(preFormat(ultimaFechaCargo))
                          : null,
                        institution: institucion,
                      }
                    : {},
              },
              isInDarkList: lexisNexisData?.checkLexisNexis?.isInDarkList
                ? lexisNexisData?.checkLexisNexis?.isInDarkList
                : false,
              identificationTypeId: tipoDocumento,
              identification: numeroDoc,
              countryId: nacionalidad?.id,
              sharesNumber: Number(numeroAcciones),
              ownershipPercentage: Number(porcentajeParticipacion),
              beneficiaryIds: beneficiaries?.length
                ? beneficiaries?.map((b) => b.id)
                : [],
              contactFatcaInputType: {
                haveUnitedStatesNationality:
                  convertInputToBoolean(tieneNacionalidadEU),
                isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                socialSecurityNumber: seguroSocial,
                zipInUnitedStates: codigoPostalEU,
                phoneInUnitedStates: telefonoEU,
                haveRepresentativeInUnitedStates:
                  convertInputToBoolean(tieneRepresentanteEU),
                haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                taxIdentificationNumber: tin,
                greenCard: tarjetaVerde,
                addressInUnitedStates: direccionEU,
              },
            },
          };
        } else if (nombreTipoPersona === 'Persona Jurídica') {
          data = {
            formResponseId:
              formResponseByIdentification?.data?.formResponses[0]?.id,
            totalSharesIssued: numeroAccionesEmitidas
              ? Number(numeroAccionesEmitidas)
              : 0,
            totalDistributedShares: numeroAccionesDistribuidas
              ? Number(numeroAccionesDistribuidas)
              : 0,
            shareholderInputTypes: {
              isInDarkList: false,
              personTypeId: tipoPersona,
              shareholderCompanyDetailsInputType: {
                businessName: denominacionSocial ?? '',
                societyTypeId: tipoSociedad,
                economicActivityId: actividadEconomica?.id,
                parvalRelationshipId: relacion,
                hasBearerShares: convertInputToBoolean(poseeAccionesPortador),
                controllingShareholders: accionistasMayoritarios,
              },
              countryId: nacionalidad?.id,
              identification: numeroDoc,
              identificationTypeId: tipoDocumento,
              sharesNumber: Number(numeroAcciones),
              ownershipPercentage: Number(porcentajeParticipacion),
              beneficiaryIds: beneficiaries?.length
                ? beneficiaries?.map((b) => b.id)
                : [],
              contactFatcaInputType: {
                haveUnitedStatesNationality:
                  convertInputToBoolean(tieneNacionalidadEU),
                isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
                isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
                socialSecurityNumber: seguroSocial,
                zipInUnitedStates: codigoPostalEU,
                phoneInUnitedStates: telefonoEU,
                haveRepresentativeInUnitedStates:
                  convertInputToBoolean(tieneRepresentanteEU),
                haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
                taxIdentificationNumber: tin,
                greenCard: tarjetaVerde,
                addressInUnitedStates: direccionEU,
              },
            },
          };
        }
      }
    }
    setIsUpdating(false);

    const saveData = await saveCompanyShareholdersSection({
      variables: {
        companyShareholdersSection: data,
      },
    });

    loadPersons(dispatch, {
      shareholders:
        saveData.data?.saveCompanyShareholdersSection?.shareholders ?? [],
      legalRepresentatives: persons.legalRepresentatives,
      signatores: persons.signatores,
      beneficiaries: persons.beneficiaries,
      counselMembers: persons.counselMembers,
    });
  };

  const deleteCardElement = async (id) => {
    await removeShareholder({
      variables: {
        shareholderId: id,
      },
    });

    loadPersons(dispatch, {
      legalRepresentatives: persons.legalRepresentatives,
      shareholders:
        persons.shareholders.filter((shareholder) => shareholder.id !== id) ??
        [],
      signatores: persons.signatores,
      beneficiaries: persons.beneficiaries,
      counselMembers: persons.counselMembers,
    });
  };

  useEffect(() => {
    if (nacionalidad && !fatcaId) {
      const isEU = nacionalidad?.label === 'ESTADOS UNIDOS';
      setValue('tieneNacionalidadEU', convertBooleanToInput(!!isEU));
    }
  }, [nacionalidad]);

  const handleEdit = (id) => {
    setNotCheck(true);
    setSelectedShareholderId(id);
    const selectedShareholder =
      companyShareholdersSectionData?.companyShareholdersSection?.shareholders?.find(
        (x) => x.id === id
      );
    setFatcaId(selectedShareholder?.contactFatca?.id);
    if (id && selectedShareholder) {
      setValue('tipoPersona', selectedShareholder?.personType?.id);
      setValue('tipoDocumento', selectedShareholder?.identificationType?.id);
      setValue('numeroDoc', selectedShareholder?.identification);
      setValue('numeroAcciones', selectedShareholder?.sharesNumber);
      setValue(
        'porcentajeParticipacion',
        selectedShareholder?.ownershipPercentage
      );

      if (selectedShareholder?.country?.id) {
        setValue('nacionalidad', {
          label: selectedShareholder?.country?.name,
          id: selectedShareholder?.country?.id,
        });
        setNacionalidad({
          label: selectedShareholder?.country?.name,
          id: selectedShareholder?.country?.id,
        });
      }

      // Si esto se cumple es persona juridica, de lo contrario es persona fisica
      if (selectedShareholder?.personType?.name === 'Persona Jurídica') {
        setCompanyOrPersionDetailsId(
          selectedShareholder?.shareholderCompanyDetails?.id
        );
        setValue(
          'denominacionSocial',
          selectedShareholder?.shareholderCompanyDetails?.businessName
        );
        setValue(
          'tipoSociedad',
          selectedShareholder?.shareholderCompanyDetails?.societyType?.id
        );
        setValue(
          'relacion',
          selectedShareholder?.shareholderCompanyDetails?.parvalRelationship?.id
        );
        setValue(
          'poseeAccionesPortador',
          convertBooleanToInput(
            selectedShareholder?.shareholderCompanyDetails?.hasBearerShares
          )
        );
        setValue(
          'accionistasMayoritarios',
          selectedShareholder?.shareholderCompanyDetails
            ?.controllingShareholders
        );

        if (
          selectedShareholder?.shareholderCompanyDetails?.economicActivity?.id
        ) {
          setActividadEconomica({
            label:
              selectedShareholder?.shareholderCompanyDetails?.economicActivity
                ?.name,
            id: selectedShareholder?.shareholderCompanyDetails?.economicActivity
              ?.id,
          });

          setValue('actividadEconomica', {
            label:
              selectedShareholder?.shareholderCompanyDetails?.economicActivity
                ?.name,
            id: selectedShareholder?.shareholderCompanyDetails?.economicActivity
              ?.id,
          });
        }
      } else {
        setCompanyOrPersionDetailsId(
          selectedShareholder?.shareholderPersonDetails?.id
        );
        setValue(
          'nombre',
          selectedShareholder?.shareholderPersonDetails?.firstName
        );
        setValue(
          'apellido',
          selectedShareholder?.shareholderPersonDetails?.lastName
        );
        setValue(
          'cargo',
          selectedShareholder?.shareholderPersonDetails?.charge
        );

        setValue(
          'personaPoliticamenteExpuesta',
          selectedShareholder?.shareholderPersonDetails?.politicExposure?.isPep
            ? 'Si'
            : 'No'
        );
        setPoliticExposureId(
          selectedShareholder?.shareholderPersonDetails?.politicExposure?.id
        );

        if (
          selectedShareholder?.shareholderPersonDetails?.politicExposure?.id &&
          selectedShareholder?.shareholderPersonDetails?.politicExposure?.isPep
        ) {
          setValue(
            'pepActivo',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.active
              ? 'Si'
              : 'No'
          );
          setValue(
            'cargoPep',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.charge
          );
          setValue(
            'institucion',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.institution
          );
          setValue(
            'fechaInicioCargo',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.chargeStartDate
              ? formatSimple(
                  new Date(
                    selectedShareholder?.shareholderPersonDetails?.politicExposure?.politicExposureDetails?.chargeStartDate
                  )
                )
              : null
          );
          setValue(
            'ultimaFechaCargo',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.chargeEndDate
              ? formatSimple(
                  new Date(
                    selectedShareholder?.shareholderPersonDetails?.politicExposure?.politicExposureDetails?.chargeEndDate
                  )
                )
              : null
          );
          setValue(
            'tipoExposicionPolitica',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.politicExposureType?.id
          );
          setValue(
            'nombreDelFamiliar',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.relativeName
          );
          setValue(
            'tipoRelacionFamiliar',
            selectedShareholder?.shareholderPersonDetails?.politicExposure
              ?.politicExposureDetails?.relativeRelationship
          );
        }
      }

      if (selectedShareholder?.contactFatca) {
        if (
          selectedShareholder?.contactFatca?.haveUnitedStatesNationality !==
            null &&
          selectedShareholder?.contactFatca?.haveUnitedStatesNationality !==
            undefined
        ) {
          setValue(
            'tieneNacionalidadEU',
            convertBooleanToInput(
              selectedShareholder?.contactFatca?.haveUnitedStatesNationality
            )
          );
        }
        if (
          selectedShareholder?.contactFatca?.isUnitedStatesCitizen !== null &&
          selectedShareholder?.contactFatca?.isUnitedStatesCitizen !== undefined
        ) {
          setValue(
            'esCiudadanoEU',
            convertBooleanToInput(
              selectedShareholder?.contactFatca?.isUnitedStatesCitizen
            )
          );
        }
        if (
          selectedShareholder?.contactFatca?.isUnitedStatesResident !== null &&
          selectedShareholder?.contactFatca?.isUnitedStatesResident !==
            undefined
        ) {
          setValue(
            'esResidenteEU',
            convertBooleanToInput(
              selectedShareholder?.contactFatca?.isUnitedStatesResident
            )
          );
        }
        if (
          selectedShareholder?.contactFatca
            ?.haveRepresentativeInUnitedStates !== null &&
          selectedShareholder?.contactFatca
            ?.haveRepresentativeInUnitedStates !== undefined
        ) {
          setValue(
            'tieneRepresentanteEU',
            convertBooleanToInput(
              selectedShareholder?.contactFatca
                ?.haveRepresentativeInUnitedStates
            )
          );
        }
        if (
          selectedShareholder?.contactFatca?.haveTaxIdentificationNumber !==
            null &&
          selectedShareholder?.contactFatca?.haveTaxIdentificationNumber !==
            undefined
        ) {
          setValue(
            'tieneTIN',
            convertBooleanToInput(
              selectedShareholder?.contactFatca?.isUnitedStatesCitizen
            )
          );
        }
        selectedShareholder?.contactFatca?.socialSecurityNumber &&
          setValue(
            'seguroSocial',
            selectedShareholder?.contactFatca?.socialSecurityNumber
          );
        selectedShareholder?.contactFatca?.zipInUnitedStates &&
          setValue(
            'codigoPostalEU',
            selectedShareholder?.contactFatca?.zipInUnitedStates
          );
        selectedShareholder?.contactFatca?.phoneInUnitedStates &&
          setValue(
            'telefonoEU',
            selectedShareholder?.contactFatca?.phoneInUnitedStates
          );
        selectedShareholder?.contactFatca?.taxIdentificationNumber &&
          setValue(
            'tin',
            selectedShareholder?.contactFatca?.taxIdentificationNumber
          );
        selectedShareholder?.contactFatca?.greenCard &&
          setValue(
            'tarjetaVerde',
            selectedShareholder?.contactFatca?.greenCard
          );
        selectedShareholder?.contactFatca?.addressInUnitedStates &&
          setValue(
            'direccionEU',
            selectedShareholder?.contactFatca?.addressInUnitedStates
          );
      }

      setBeneficiaries(selectedShareholder?.beneficiaries);
    }
  };

  const handleClick = () => {
    if (!isFormCompleted && !isFormDisqualified) {
      addMainShareholders();
    }
    navigateToNextPage();
  };

  const [saveBeneficiary] = useMutation(SAVE_BENEFICIARY);
  const [removeBeneficiary] = useMutation(REMOVE_BENEFICIARY);

  const deleteCardElementBeneficiary = async (id) => {
    await removeBeneficiary({
      variables: {
        beneficiaryId: id,
      },
    });

    setBeneficiaries(beneficiaries.filter((b) => b.id !== id));
  };

  const nombresBeneficiario = watchBeneficiary('nombresBeneficiario');
  const apellidosBeneficiario = watchBeneficiary('apellidosBeneficiario');
  const tipoDocumentoBeneficiario = watchBeneficiary(
    'tipoDocumentoBeneficiario'
  );
  const numeroDocBeneficiario = watchBeneficiary('numeroDocBeneficiario');
  const ocupacionBeneficiario = watchBeneficiary('ocupacionBeneficiario');
  const telefonoContactoBeneficiario = watchBeneficiary(
    'telefonoContactoBeneficiario'
  );
  const direccionBeneficiario = watchBeneficiary('direccionBeneficiario');
  const correoElectronicoBeneficiario = watchBeneficiary(
    'correoElectronicoBeneficiario'
  );

  const personaPoliticamenteExpuestaBeneficiario = watchBeneficiary(
    'personaPoliticamenteExpuestaBeneficiario'
  );
  const cargoPepBeneficiario = watchBeneficiary('cargoPepBeneficiario');
  const institucionBeneficiario = watchBeneficiary('institucionBeneficiario');
  const fechaInicioCargoBeneficiario = watchBeneficiary(
    'fechaInicioCargoBeneficiario'
  );
  const ultimaFechaCargoBeneficiario = watchBeneficiary(
    'ultimaFechaCargoBeneficiario'
  );
  const tipoExposicionPoliticaBeneficiario = watchBeneficiary(
    'tipoExposicionPoliticaBeneficiario'
  );
  const nombreDelFamiliarBeneficiario = watchBeneficiary(
    'nombreDelFamiliarBeneficiario'
  );
  const tipoRelacionFamiliarBeneficiario = watchBeneficiary(
    'tipoRelacionFamiliarBeneficiario'
  );
  const pepActivoBeneficiario = watchBeneficiary('pepActivoBeneficiario');

  const tieneNacionalidadEUBeneficiario = watchBeneficiary(
    'tieneNacionalidadEUBeneficiario'
  );
  const esCiudadanoEUBeneficiario = watchBeneficiary(
    'esCiudadanoEUBeneficiario'
  );
  const esResidenteEUBeneficiario = watchBeneficiary(
    'esResidenteEUBeneficiario'
  );
  const seguroSocialBeneficiario = watchBeneficiary('seguroSocialBeneficiario');
  const codigoPostalEUBeneficiario = watchBeneficiary(
    'codigoPostalEUBeneficiario'
  );
  const telefonoEUBeneficiario = watchBeneficiary('telefonoEUBeneficiario');
  const tieneRepresentanteEUBeneficiario = watchBeneficiary(
    'tieneRepresentanteEUBeneficiario'
  );
  const tieneTINBeneficiario = watchBeneficiary('tieneTINBeneficiario');
  const tinBeneficiario = watchBeneficiary('tinBeneficiario');
  const tarjetaVerdeBeneficiario = watchBeneficiary('tarjetaVerdeBeneficiario');
  const direccionEUBeneficiario = watchBeneficiary('direccionEUBeneficiario');

  const addBeneficiary = async () => {
    const emailIsInUse = beneficiaries?.find(
      (beneficiary) =>
        beneficiary.id !== selectedBeneficiaryId &&
        correoElectronicoBeneficiario === beneficiary.email
    );

    if (emailIsInUse) {
      toast('Ya existe un beneficiario con este correo', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    const identificationIsInUse = beneficiaries?.find(
      (beneficiary) =>
        beneficiary.id !== selectedBeneficiaryId &&
        numeroDocBeneficiario === beneficiary.identificationNumber &&
        beneficiary.identificationType.id === tipoDocumentoBeneficiario
    );

    if (identificationIsInUse) {
      toast('Ya existe un beneficiario con esta identificación', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    setOpenBeneficiaryModal(false);
    resetBeneficiary();

    let data = {};

    if (selectedBeneficiaryId && selectedBeneficiaryId !== '') {
      data = {
        id: selectedBeneficiaryId,
        address: direccionBeneficiario,
        firstName: nombresBeneficiario,
        identificationNumber: numeroDocBeneficiario,
        identificationTypeId: tipoDocumentoBeneficiario,
        lastName: apellidosBeneficiario,
        occupation: ocupacionBeneficiario,
        phoneNumber: telefonoContactoBeneficiario,
        email: correoElectronicoBeneficiario,
        socialSecurityNumber: seguroSocialBeneficiario,
        politicExposureInputType:
          personaPoliticamenteExpuestaBeneficiario === 'Si'
            ? {
                id: politicExposureBeneficiaryId
                  ? politicExposureBeneficiaryId
                  : null,
                isPep: convertInputToBoolean(personaPoliticamenteExpuestaBeneficiario),
                active: convertInputToBoolean(pepActivoBeneficiario),
                charge: cargoPepBeneficiario,
                relativeName: nombreDelFamiliarBeneficiario,
                relativeRelationship: tipoRelacionFamiliarBeneficiario,
                politicExposureTypeId: tipoExposicionPoliticaBeneficiario,
                chargeStartDate: fechaInicioCargoBeneficiario
                  ? parseSimple(preFormat(fechaInicioCargoBeneficiario))
                  : null,
                chargeEndDate: ultimaFechaCargoBeneficiario
                  ? parseSimple(preFormat(ultimaFechaCargoBeneficiario))
                  : null,
                institution: institucionBeneficiario,
              }
            : {},
        contactFatcaInputType: {
          id: fatcaBeneficiaryId,
          haveUnitedStatesNationality: convertInputToBoolean(
            tieneNacionalidadEUBeneficiario
          ),
          isUnitedStatesCitizen: convertInputToBoolean(
            esCiudadanoEUBeneficiario
          ),
          isUnitedStatesResident: convertInputToBoolean(
            esResidenteEUBeneficiario
          ),
          socialSecurityNumber: seguroSocialBeneficiario,
          zipInUnitedStates: codigoPostalEUBeneficiario,
          phoneInUnitedStates: telefonoEUBeneficiario,
          haveRepresentativeInUnitedStates: convertInputToBoolean(
            tieneRepresentanteEUBeneficiario
          ),
          haveTaxIdentificationNumber:
            convertInputToBoolean(tieneTINBeneficiario),
          taxIdentificationNumber: tinBeneficiario,
          greenCard: tarjetaVerdeBeneficiario,
          addressInUnitedStates: direccionEUBeneficiario,
        },
      };

      setSelectedBeneficiaryId('');
    } else {
      data = {
        id: v4(),
        address: direccionBeneficiario,
        firstName: nombresBeneficiario,
        identificationNumber: numeroDocBeneficiario,
        identificationTypeId: tipoDocumentoBeneficiario,
        lastName: apellidosBeneficiario,
        occupation: ocupacionBeneficiario,
        phoneNumber: telefonoContactoBeneficiario,
        email: correoElectronicoBeneficiario,
        politicExposureInputType:
          personaPoliticamenteExpuestaBeneficiario === 'Si'
            ? {
                isPep: convertInputToBoolean(personaPoliticamenteExpuestaBeneficiario),
                active: convertInputToBoolean(pepActivoBeneficiario),
                charge: cargoPepBeneficiario,
                relativeName: nombreDelFamiliarBeneficiario,
                relativeRelationship: tipoRelacionFamiliarBeneficiario,
                politicExposureTypeId: tipoExposicionPoliticaBeneficiario,
                chargeStartDate: fechaInicioCargoBeneficiario
                  ? parseSimple(preFormat(fechaInicioCargoBeneficiario))
                  : null,
                chargeEndDate: ultimaFechaCargoBeneficiario
                  ? parseSimple(preFormat(ultimaFechaCargoBeneficiario))
                  : null,
                institution: institucionBeneficiario,
              }
            : {},
        contactFatcaInputType: {
          haveUnitedStatesNationality: convertInputToBoolean(
            tieneNacionalidadEUBeneficiario
          ),
          isUnitedStatesCitizen: convertInputToBoolean(
            esCiudadanoEUBeneficiario
          ),
          isUnitedStatesResident: convertInputToBoolean(
            esResidenteEUBeneficiario
          ),
          socialSecurityNumber: seguroSocialBeneficiario,
          zipInUnitedStates: codigoPostalEUBeneficiario,
          phoneInUnitedStates: telefonoEUBeneficiario,
          haveRepresentativeInUnitedStates: convertInputToBoolean(
            tieneRepresentanteEUBeneficiario
          ),
          haveTaxIdentificationNumber:
            convertInputToBoolean(tieneTINBeneficiario),
          taxIdentificationNumber: tinBeneficiario,
          greenCard: tarjetaVerdeBeneficiario,
          addressInUnitedStates: direccionEUBeneficiario,
        },
      };
    }

    const response = await saveBeneficiary({
      variables: {
        beneficiary: data,
      },
    });

    if (isUpdatingBeneficiary) {
      setBeneficiaries(
        beneficiaries.map((b) => {
          if (b.id === response.data.saveBeneficiary.id) {
            return response.data.saveBeneficiary;
          } else {
            return b;
          }
        })
      );
    } else {
      setBeneficiaries([...beneficiaries, response.data?.saveBeneficiary]);
    }

    document.getElementById('exampleModal').style.display = 'block';
  };

  const setBeneficiaryForm = (selectedBeneficiary) => {
    setFatcaBeneficiaryId(selectedBeneficiary?.contactFatca?.id);
    selectedBeneficiary?.firstName &&
      setValueBeneficiary(
        'nombresBeneficiario',
        selectedBeneficiary?.firstName
      );
    selectedBeneficiary?.lastName &&
      setValueBeneficiary(
        'apellidosBeneficiario',
        selectedBeneficiary?.lastName
      );
    if (selectedBeneficiary?.identificationType?.id) {
      setValueBeneficiary(
        'tipoDocumentoBeneficiario',
        selectedBeneficiary?.identificationType?.id
      );
    }
    if (
      selectedBeneficiary?.identificationNumber ||
      selectedBeneficiary?.identification
    ) {
      setValueBeneficiary(
        'numeroDocBeneficiario',
        selectedBeneficiary?.identificationNumber ||
          selectedBeneficiary?.identification
      );
    }
    if (selectedBeneficiary?.occupation || selectedBeneficiary?.position) {
      setValueBeneficiary(
        'ocupacionBeneficiario',
        selectedBeneficiary?.occupation || selectedBeneficiary?.position
      );
    }
    selectedBeneficiary?.phoneNumber &&
      setValueBeneficiary(
        'telefonoContactoBeneficiario',
        selectedBeneficiary?.phoneNumber
      );
    selectedBeneficiary?.address &&
      setValueBeneficiary(
        'direccionBeneficiario',
        selectedBeneficiary?.address
      );
    selectedBeneficiary?.email &&
      setValueBeneficiary(
        'correoElectronicoBeneficiario',
        selectedBeneficiary?.email
      );
    selectedBeneficiary?.politicExposure?.id &&
      setValueBeneficiary(
        'personaPoliticamenteExpuestaBeneficiario',
        selectedBeneficiary?.politicExposure?.isPep ? 'Si' : 'No'
      );
    selectedBeneficiary?.politicExposure?.id &&
      setPoliticExposureBeneficiaryId(selectedBeneficiary?.politicExposure?.id);

    if (
      selectedBeneficiary?.politicExposure?.id &&
      selectedBeneficiary?.politicExposure?.isPep
    ) {
      setValueBeneficiary(
        'pepActivoBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails?.active
          ? 'Si'
          : 'No'
      );
      setValueBeneficiary(
        'cargoPepBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails?.charge
      );
      setValueBeneficiary(
        'institucionBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.institution
      );
      setValueBeneficiary(
        'fechaInicioCargoBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.chargeStartDate
          ? formatSimple(
              new Date(
                selectedBeneficiary?.politicExposure?.politicExposureDetails?.chargeStartDate
              )
            )
          : null
      );
      setValueBeneficiary(
        'ultimaFechaCargoBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.chargeEndDate
          ? formatSimple(
              new Date(
                selectedBeneficiary?.politicExposure?.politicExposureDetails?.chargeEndDate
              )
            )
          : null
      );
      setValueBeneficiary(
        'tipoExposicionPoliticaBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.politicExposureType?.id
      );
      setValueBeneficiary(
        'nombreDelFamiliarBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.relativeName
      );
      setValueBeneficiary(
        'tipoRelacionFamiliarBeneficiario',
        selectedBeneficiary?.politicExposure?.politicExposureDetails
          ?.relativeRelationship
      );
    }

    if (selectedBeneficiary?.contactFatca) {
      if (
        selectedBeneficiary?.contactFatca?.haveUnitedStatesNationality !==
          null &&
        selectedBeneficiary?.contactFatca?.haveUnitedStatesNationality !==
          undefined
      ) {
        setValueBeneficiary(
          'tieneNacionalidadEUBeneficiario',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.haveUnitedStatesNationality
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen !== null &&
        selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen !== undefined
      ) {
        setValueBeneficiary(
          'esCiudadanoEUBeneficiario',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.isUnitedStatesResident !== null &&
        selectedBeneficiary?.contactFatca?.isUnitedStatesResident !== undefined
      ) {
        setValueBeneficiary(
          'esResidenteEUBeneficiario',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.isUnitedStatesResident
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.haveRepresentativeInUnitedStates !==
          null &&
        selectedBeneficiary?.contactFatca?.haveRepresentativeInUnitedStates !==
          undefined
      ) {
        setValueBeneficiary(
          'tieneRepresentanteEUBeneficiario',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.haveRepresentativeInUnitedStates
          )
        );
      }
      if (
        selectedBeneficiary?.contactFatca?.haveTaxIdentificationNumber !==
          null &&
        selectedBeneficiary?.contactFatca?.haveTaxIdentificationNumber !==
          undefined
      ) {
        setValueBeneficiary(
          'tieneTINBeneficiario',
          convertBooleanToInput(
            selectedBeneficiary?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      selectedBeneficiary?.contactFatca?.socialSecurityNumber &&
        setValueBeneficiary(
          'seguroSocialBeneficiario',
          selectedBeneficiary?.contactFatca?.socialSecurityNumber
        );
      selectedBeneficiary?.contactFatca?.zipInUnitedStates &&
        setValueBeneficiary(
          'codigoPostalEUBeneficiario',
          selectedBeneficiary?.contactFatca?.zipInUnitedStates
        );
      selectedBeneficiary?.contactFatca?.phoneInUnitedStates &&
        setValueBeneficiary(
          'telefonoEUBeneficiario',
          selectedBeneficiary?.contactFatca?.phoneInUnitedStates
        );
      selectedBeneficiary?.contactFatca?.taxIdentificationNumber &&
        setValueBeneficiary(
          'tinBeneficiario',
          selectedBeneficiary?.contactFatca?.taxIdentificationNumber
        );
      selectedBeneficiary?.contactFatca?.greenCard &&
        setValueBeneficiary(
          'tarjetaVerdeBeneficiario',
          selectedBeneficiary?.contactFatca?.greenCard
        );
      selectedBeneficiary?.contactFatca?.addressInUnitedStates &&
        setValueBeneficiary(
          'direccionEUBeneficiario',
          selectedBeneficiary?.contactFatca?.addressInUnitedStates
        );
    }
  };

  const handleEditBeneficiary = (id) => {
    document.getElementById('exampleModal').style.display = 'none';
    setOpenBeneficiaryModal(true);
    setNotCheckBeneficiary(true);
    setSelectedBeneficiaryId(id);
    setExistingBeneficiary(false);
    const selectedBeneficiary = beneficiaries?.find((x) => x.id === id);

    if (id && selectedBeneficiary) {
      setBeneficiaryForm(selectedBeneficiary);
    }
  };

  useEffect(() => {
    if (
      typeof numeroDocBeneficiario === 'string' &&
      getIdentificationTypeName(tipoDocumentoBeneficiario) ===
        'Cedula de Identidad y Electoral' &&
      !notCheckBeneficiary
    ) {
      if (numeroDocBeneficiario.length > 12) {
        verifiyIfIsPepBeneficiary();
      } else {
        setValueBeneficiary('personaPoliticamenteExpuestaBeneficiario', 'No');
      }
    }
  }, [numeroDocBeneficiario, notCheckBeneficiary]);

  useEffect(() => {
    if (checkPoliticExposureResponseBeneficiary) {
      setValueBeneficiary(
        'personaPoliticamenteExpuestaBeneficiario',
        convertBooleanToInput(
          checkPoliticExposureResponseBeneficiary.checkPoliticExposure.isPEP
        )
      );
      if (
        checkPoliticExposureResponseBeneficiary.checkPoliticExposure.isPEP ===
        true
      ) {
        setValueBeneficiary(
          'cargoPepBeneficiario',
          checkPoliticExposureResponseBeneficiary.checkPoliticExposure.charge
        );
        setValueBeneficiary(
          'tipoExposicionPoliticaBeneficiario',
          checkPoliticExposureResponseBeneficiary.checkPoliticExposure
            .politicExposureType.id
        );
        if (
          checkPoliticExposureResponseBeneficiary.checkPoliticExposure
            .politicExposureType.name === 'Familiar'
        ) {
          setValueBeneficiary(
            'nombreDelFamiliarBeneficiario',
            checkPoliticExposureResponseBeneficiary.checkPoliticExposure
              .relativeName
          );
          setValueBeneficiary(
            'tipoRelacionFamiliarBeneficiario',
            checkPoliticExposureResponseBeneficiary.checkPoliticExposure
              .relativeRelationship
          );
        }
        toast('Esta es una Persona Politicamente Expuesta', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        toast('Esta no es una Persona Politicamente Expuesta', {
          type: 'success',
          theme: 'colored',
        });
      }
    }
  }, [checkPoliticExposureResponseBeneficiary]);

  const [openBeneficiaryModal, setOpenBeneficiaryModal] = useState(false);

  const copiarContacto = watch('copiarContacto');
  const contacto = watch('contacto');

  useEffect(() => {
    const legalRepresentatives = persons.legalRepresentatives.map((legal) => ({
      id: legal.id,
      firstName: legal.firstName,
      lastName: legal.lastName,
      email: legal.correspondenceEmail?.email,
      identificationType: legal.identificationType,
      identification: legal.identification,
      position: legal.position,
      nationalityCountry: legal.nationalityCountry,
      isInDarkList: legal.isInDarkList,
      politicExposure: legal.politicExposure,
      contactFatca: legal.contactFatca,
      address: comprexAddress(legal.address),
    }));

    const counselMembers = persons.counselMembers.map((counselMember) => ({
      id: counselMember.id,
      firstName: counselMember.firstName,
      lastName: counselMember.lastName,
      identificationType: counselMember.identificationType,
      identification: counselMember.identification,
      position: counselMember.position,
      nationalityCountry: counselMember.nationalityCountry,
      isInDarkList: counselMember.isInDarkList,
      politicExposure: counselMember.politicExposure,
      contactFatca: counselMember.contactFatca,
    }));

    const signatores = persons.signatores.map((sig) => ({
      id: sig.id,
      firstName: sig.firstName,
      lastName: sig.lastName,
      identification: sig.identification,
      position: sig.charge,
      email: sig.email,
    }));

    const beneficiaries = persons.beneficiaries.map((beneficiary) => ({
      id: beneficiary.id,
      firstName: beneficiary.firstName,
      lastName: beneficiary.lastName,
      email: beneficiary.email,
      identificationType: beneficiary.identificationType,
      identification: beneficiary.identificationNumber,
      position: beneficiary.occupation,
      politicExposure: beneficiary.politicExposure,
      contactFatca: beneficiary.contactFatca,
      phoneNumber: beneficiary.phoneNumber,
      address: beneficiary.address,
    }));

    let shareholdersBeneficiaries = [];

    persons.shareholders.forEach((sh) => {
      sh.beneficiaries.forEach((beneficiary) =>
        shareholdersBeneficiaries.push({
          id: beneficiary.id,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          email: beneficiary.email,
          identificationType: beneficiary.identificationType,
          identification: beneficiary.identificationNumber,
          position: beneficiary.occupation,
          politicExposure: beneficiary.politicExposure,
          phoneNumber: beneficiary.phoneNumber,
          address: beneficiary.address,
          contactFatca: beneficiary.contactFatca,
        })
      );
    });

    let personsToCopy = [
      ...legalRepresentatives,
      ...signatores,
      ...beneficiaries,
      ...shareholdersBeneficiaries,
      ...counselMembers,
    ];

    personsToCopy = mergeObjectsWithSameId(personsToCopy);

    const idsToRemove = new Set(
      persons.shareholders.map((item) => item.identification)
    );
    personsToCopy = personsToCopy.filter(
      (item) => !idsToRemove.has(item.identification)
    );

    setContacts(personsToCopy);
  }, [persons]);

  useEffect(() => {
    if (contacto && contacto !== 'DEFAULT') {
      const personToCopy = contacts.find((contact) => contact.id === contacto);
      resetAllInputs();
      setValue('contacto', contacto);
      setValue('copiarContacto', copiarContacto);
      setValue(
        'tipoPersona',
        personTypeList?.personTypeList?.find(
          (type) => type.name === 'Persona Física'
        )?.id
      );
      personToCopy?.firstName && setValue('nombre', personToCopy?.firstName);
      personToCopy?.identification &&
        setValue('numeroDoc', personToCopy?.identification);
      personToCopy?.position && setValue('cargo', personToCopy?.position);
      personToCopy?.lastName && setValue('apellido', personToCopy?.lastName);
      if (personToCopy?.nationalityCountry?.id) {
        setValue('nacionalidad', {
          label: personToCopy?.nationalityCountry?.name,
          id: personToCopy?.nationalityCountry?.id,
        });
        setNacionalidad({
          label: personToCopy?.nationalityCountry?.name,
          id: personToCopy?.nationalityCountry?.id,
        });
      }
      personToCopy?.identificationType?.id &&
        setValue('tipoDocumento', personToCopy?.identificationType?.id);

      personToCopy?.politicExposure?.id &&
        setValue(
          'personaPoliticamenteExpuesta',
          personToCopy?.politicExposure?.isPep ? 'Si' : 'No'
        );
      if (
        personToCopy?.politicExposure?.id &&
        personToCopy?.politicExposure?.isPep
      ) {
        setValue(
          'pepActivo',
          personToCopy?.politicExposure?.politicExposureDetails?.active
            ? 'Si'
            : 'No'
        );
        setValue(
          'cargoPep',
          personToCopy?.politicExposure?.politicExposureDetails?.charge
        );
        setValue(
          'institucion',
          personToCopy?.politicExposure?.politicExposureDetails?.institution
        );
        setValue(
          'fechaInicioCargo',
          personToCopy?.politicExposure?.politicExposureDetails?.chargeStartDate
            ? formatSimple(
                new Date(
                  personToCopy?.politicExposure?.politicExposureDetails?.chargeStartDate
                )
              )
            : null
        );
        setValue(
          'ultimaFechaCargo',
          personToCopy?.politicExposure?.politicExposureDetails?.chargeEndDate
            ? formatSimple(
                new Date(
                  personToCopy?.politicExposure?.politicExposureDetails?.chargeEndDate
                )
              )
            : null
        );
        setValue(
          'tipoExposicionPolitica',
          personToCopy?.politicExposure?.politicExposureDetails
            ?.politicExposureType?.id
        );
        setValue(
          'nombreDelFamiliar',
          personToCopy?.politicExposure?.politicExposureDetails?.relativeName
        );
        setValue(
          'tipoRelacionFamiliar',
          personToCopy?.politicExposure?.politicExposureDetails
            ?.relativeRelationship
        );
      }

      if (personToCopy?.contactFatca) {
        if (
          personToCopy?.contactFatca?.haveUnitedStatesNationality !== null &&
          personToCopy?.contactFatca?.haveUnitedStatesNationality !== undefined
        ) {
          setValue(
            'tieneNacionalidadEU',
            convertBooleanToInput(
              personToCopy?.contactFatca?.haveUnitedStatesNationality
            )
          );
        }
        if (
          personToCopy?.contactFatca?.isUnitedStatesCitizen !== null &&
          personToCopy?.contactFatca?.isUnitedStatesCitizen !== undefined
        ) {
          setValue(
            'esCiudadanoEU',
            convertBooleanToInput(
              personToCopy?.contactFatca?.isUnitedStatesCitizen
            )
          );
        }
        if (
          personToCopy?.contactFatca?.isUnitedStatesResident !== null &&
          personToCopy?.contactFatca?.isUnitedStatesResident !== undefined
        ) {
          setValue(
            'esResidenteEU',
            convertBooleanToInput(
              personToCopy?.contactFatca?.isUnitedStatesResident
            )
          );
        }
        if (
          personToCopy?.contactFatca?.haveRepresentativeInUnitedStates !==
            null &&
          personToCopy?.contactFatca?.haveRepresentativeInUnitedStates !==
            undefined
        ) {
          setValue(
            'tieneRepresentanteEU',
            convertBooleanToInput(
              personToCopy?.contactFatca?.haveRepresentativeInUnitedStates
            )
          );
        }
        if (
          personToCopy?.contactFatca?.haveTaxIdentificationNumber !== null &&
          personToCopy?.contactFatca?.haveTaxIdentificationNumber !== undefined
        ) {
          setValue(
            'tieneTIN',
            convertBooleanToInput(
              personToCopy?.contactFatca?.isUnitedStatesCitizen
            )
          );
        }
        personToCopy?.contactFatca?.socialSecurityNumber &&
          setValue(
            'seguroSocial',
            personToCopy?.contactFatca?.socialSecurityNumber
          );
        personToCopy?.contactFatca?.zipInUnitedStates &&
          setValue(
            'codigoPostalEU',
            personToCopy?.contactFatca?.zipInUnitedStates
          );
        personToCopy?.contactFatca?.phoneInUnitedStates &&
          setValue(
            'telefonoEU',
            personToCopy?.contactFatca?.phoneInUnitedStates
          );
        personToCopy?.contactFatca?.taxIdentificationNumber &&
          setValue('tin', personToCopy?.contactFatca?.taxIdentificationNumber);
        personToCopy?.contactFatca?.greenCard &&
          setValue('tarjetaVerde', personToCopy?.contactFatca?.greenCard);
        personToCopy?.contactFatca?.addressInUnitedStates &&
          setValue(
            'direccionEU',
            personToCopy?.contactFatca?.addressInUnitedStates
          );
      }
    }
  }, [contacto]);

  const [beneficiaryContacts, setBeneficiaryContacts] = useState([]);
  const copiarContactoBeneficiario = watchBeneficiary(
    'copiarContactoBeneficiario'
  );
  const contactoBeneficiario = watchBeneficiary('contactoBeneficiario');

  useEffect(() => {
    let shareholders = [];
    persons.shareholders.forEach((sh) => {
      if (sh.personType.name === 'Persona Física') {
        shareholders.push({
          id: sh.id,
          firstName: sh.shareholderPersonDetails.firstName ?? '',
          lastName: sh.shareholderPersonDetails.lastName ?? '',
          identificationType: sh.identificationType,
          identification: sh.identification,
          position: sh.shareholderPersonDetails.charge,
          isInDarkList: sh.isInDarkList,
          politicExposure: sh.shareholderPersonDetails.politicExposure,
          nationalityCountry: sh.country,
          contactFatca: sh.contactFatca,
        });
      }
    });

    const legalRepresentatives = persons.legalRepresentatives.map((legal) => ({
      id: legal.id,
      firstName: legal.firstName,
      lastName: legal.lastName,
      email: legal.correspondenceEmail?.email,
      identificationType: legal.identificationType,
      identification: legal.identification,
      position: legal.position,
      nationalityCountry: legal.nationalityCountry,
      isInDarkList: legal.isInDarkList,
      politicExposure: legal.politicExposure,
      contactFatca: legal.contactFatca,
      address: comprexAddress(legal.address),
    }));

    const signatores = persons.signatores.map((sig) => ({
      id: sig.id,
      firstName: sig.firstName,
      lastName: sig.lastName,
      identification: sig.identification,
      position: sig.charge,
      email: sig.email,
    }));

    const counselMembers = persons.counselMembers.map((counselMember) => ({
      id: counselMember.id,
      firstName: counselMember.firstName,
      lastName: counselMember.lastName,
      identificationType: counselMember.identificationType,
      identification: counselMember.identification,
      position: counselMember.position,
      nationalityCountry: counselMember.nationalityCountry,
      isInDarkList: counselMember.isInDarkList,
      politicExposure: counselMember.politicExposure,
      contactFatca: counselMember.contactFatca,
    }));

    const beneficiaries = persons.beneficiaries.map((beneficiary) => ({
      id: beneficiary.id,
      firstName: beneficiary.firstName,
      lastName: beneficiary.lastName,
      email: beneficiary.email,
      identificationType: beneficiary.identificationType,
      identification: beneficiary.identificationNumber,
      position: beneficiary.occupation,
      politicExposure: beneficiary.politicExposure,
      phoneNumber: beneficiary.phoneNumber,
      address: beneficiary.address,
      contactFatca: beneficiary.contactFatca,
    }));

    let shareholdersBeneficiaries = [];

    persons.shareholders.forEach((sh) => {
      sh.beneficiaries.forEach((beneficiary) =>
        shareholdersBeneficiaries.push({
          id: beneficiary.id,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          email: beneficiary.email,
          identificationType: beneficiary.identificationType,
          identification: beneficiary.identificationNumber,
          position: beneficiary.occupation,
          politicExposure: beneficiary.politicExposure,
          phoneNumber: beneficiary.phoneNumber,
          address: beneficiary.address,
          contactFatca: beneficiary.contactFatca,
        })
      );
    });

    let personsToCopy = [
      ...shareholders,
      ...legalRepresentatives,
      ...signatores,
      ...beneficiaries,
      ...shareholdersBeneficiaries,
      ...counselMembers,
    ];

    personsToCopy = mergeObjectsWithSameId(personsToCopy);

    setBeneficiaryContacts(personsToCopy);
  }, [persons]);

  useEffect(() => {
    if (contactoBeneficiario && contactoBeneficiario !== 'DEFAULT') {
      const personToCopy = beneficiaryContacts.find(
        (contact) => contact.id === contactoBeneficiario
      );
      resetBeneficiary();
      setValueBeneficiary('contactoBeneficiario', contactoBeneficiario);
      setValueBeneficiary(
        'copiarContactoBeneficiario',
        copiarContactoBeneficiario
      );
      setBeneficiaryForm(personToCopy);
    }
  }, [contactoBeneficiario]);

  const removeBeneficiariesFromContacts = (beneficariesContacts) => {
    const idsToRemove = new Set(
      beneficiaries?.map(
        (item) => item.identification || item.identificationNumber
      )
    );
    return beneficariesContacts?.filter(
      (item) => !idsToRemove.has(item.identification)
    );
  };

  const resetAllInputs = () => {
    reset();
    setInputNacionalidad('');
    setNacionalidad('');
    setInputActividadEconomica('');
    setActividadEconomica('');
  };

  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'companyShareholdersSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container position-relative" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Principales Accionistas (que tengan 5% o más de las acciones)
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Principales Accionistas'}
              description={
                "Este formulario, dedicado a los 'Principales Accionistas con una participación del 5% o más', se enfoca en identificar y detallar aquellos inversionistas clave que poseen una significativa influencia en la empresa. Aquí se capturan datos relevantes sobre los accionistas con una participación sustancial del 5% o superior en la propiedad de la empresa. El objetivo es ofrecer una visión detallada de aquellos individuos o entidades que desempeñan un papel destacado en la toma de decisiones y en la dirección estratégica de la compañía."
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'shareholders'
                      ? 'Es necesario que agregues por lo menos un accionista.'
                      : `Existen campos incompletos en esta sección`
                  }
                />
              </span>
            )}
          </h3>

          <h4 className="bff-sub-title">
            Si tienes más de un accionista, puedes agregarlos todos aquí.
          </h4>
          {loadingSection ||
          loadingShareGet ||
          loadingShareRemove ||
          loadingShareSave ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <>
              <div className="row w-75">
                <div className="row align-items-start">
                  <div className="field-form col-md-6 col-sm-12">
                    <label
                      htmlFor="numero-acciones-emitidas"
                      className="label-field"
                    >
                      Total de Acciones Emitidas <span>*</span>
                    </label>
                    <input
                      type="number"
                      className="input-form"
                      placeholder=""
                      id="numero-acciones-emitidas"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...registerShares('numeroAccionesEmitidas', {
                        required: true,
                      })}
                    />
                    {errorsShares.numeroAccionesEmitidas && (
                      <p className="text-danger">Este campo es requerido</p>
                    )}
                    {!numeroAccionesEmitidas &&
                      errorsShares.numeroAccionesEmitidas?.type !==
                        'required' && (
                        <ErrorText hasError={hasError('totalSharesIssued')} />
                      )}
                  </div>
                  <div className="field-form col-md-6 col-sm-12">
                    <label
                      htmlFor="numeroAccionesDistribuidas"
                      className="label-field"
                    >
                      Total de Acciones Distribuidas <span>*</span>
                    </label>
                    <input
                      type="number"
                      className="input-form"
                      placeholder=""
                      id="numeroAccionesDistribuidas"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...registerShares('numeroAccionesDistribuidas', {
                        required: true,
                        max: numeroAccionesEmitidas ?? 0,
                      })}
                    />
                    {errorsShares.numeroAccionesDistribuidas?.type ===
                      'required' && (
                      <p className="text-danger">Este campo es requerido</p>
                    )}
                    {errorsShares.numeroAccionesDistribuidas?.type ===
                      'max' && (
                      <p className="text-danger">
                        Debe ser igual o menor al total de acciones emitidas
                      </p>
                    )}
                    {!numeroAccionesDistribuidas &&
                      errorsShares.numeroAccionesDistribuidas?.type !==
                        'required' && (
                        <ErrorText
                          hasError={hasError('totalDistributedShares')}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="add-card-container">
                {companyShareholdersSectionData?.companyShareholdersSection?.shareholders.map(
                  (mainShareholder, index) => {
                    return (
                      <div
                        key={index}
                        data-bs-toggle={isUpdating ? 'modal' : ''}
                        id={mainShareholder?.id}
                        data-bs-target={isUpdating ? '#exampleModal' : ''}
                      >
                        <CardItems
                          key={index}
                          nameElement={
                            mainShareholder?.shareholderCompanyDetails
                              ? mainShareholder?.shareholderCompanyDetails
                                  ?.businessName
                              : mainShareholder?.shareholderPersonDetails
                                  ?.firstName +
                                ' ' +
                                mainShareholder?.shareholderPersonDetails
                                  ?.lastName
                          }
                          identityDocument={mainShareholder?.identification}
                          idElement={mainShareholder?.id}
                          deleteElement={deleteCardElement}
                          handleEdit={handleEdit}
                          setIsUpdating={setIsUpdating}
                          showDeleteButton={!isFormCompleted && !isFormDisqualified}
                          detalle={
                            mainShareholder?.shareholderPersonDetails
                              ?.politicExposure?.isPep
                              ? 'Persona politicamente expuesta'
                              : null
                          }
                        />
                      </div>
                    );
                  }
                )}
                {!isFormCompleted && !isFormDisqualified ? (
                  numeroAccionesDistribuidas && numeroAccionesEmitidas ? (
                    getAvailablePercentage() ? (
                      <div
                        className="add-card"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={() => {
                          resetAllInputs();
                          setSelectedShareholderId('');
                          setBeneficiaries([]);
                          setIsUpdating(false);
                          setExistingShareholder(false);
                          setFatcaId('');
                        }}
                      >
                        <div className="image-icon-container">
                          <FontAwesomeIcon
                            icon={faAdd}
                            className="image-icon"
                          />
                        </div>
                        <h4 className="bk-card-title">Agregar Accionista</h4>
                      </div>
                    ) : null
                  ) : (
                    <div className="add-card" style={{ pointerEvents: 'none' }}>
                      <div className="image-icon-container">
                        <FontAwesomeIcon
                          icon={faWarning}
                          className="image-icon"
                        />
                      </div>
                      <h4 className="bk-card-title">
                        {' '}
                        Completa los campos previos{' '}
                      </h4>
                    </div>
                  )
                ) : null}
              </div>
            </>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addMainShareholders)}
                  className="fields-modal-container frm-main-share-holders"
                >
                  <div className="fields-modal-header mb-3">
                    <h4 className="modal-title" id="exampleModalLabel">
                      {!isFormCompleted && !isFormDisqualified
                        ? selectedShareholderId
                          ? 'Editar'
                          : 'Agregar'
                        : 'Ver'}{' '}
                      Accionista
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      onClick={() => {
                        resetAllInputs();
                        setSelectedShareholderId('');
                        setBeneficiaries([]);
                        setIsUpdating(false);
                        setExistingShareholder(false);
                        setFatcaId('');
                      }}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="row fields-modal-content">
                    <div className="row">
                      {!isFormCompleted && !isFormDisqualified ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="copiar-contacto"
                            className="label-field"
                          >
                            ¿Copiar información de otro contacto?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="copiar-contacto"
                              defaultValue="No"
                              {...register('copiarContacto')}
                            >
                              <option value="No">No</option>
                              <option value="Si">Si</option>
                            </select>
                          </div>
                        </div>
                      ) : null}
                      {!isFormCompleted && !isFormDisqualified && copiarContacto === 'Si' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="contacto" className="label-field">
                            Contacto <span>*</span>
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="contacto"
                              defaultValue={'DEFAULT'}
                              {...register('contacto', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona
                              </option>
                              {contacts.map((contact) => {
                                return (
                                  <option key={contact?.id} value={contact?.id}>
                                    {contact?.firstName +
                                      ' ' +
                                      contact?.lastName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          {errors.copiarContacto && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="field-form col-md-4 col-sm-12">
                        <label htmlFor="tipo-persona" className="label-field">
                          Tipo de Persona <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tipo-persona"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoPersona', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {personTypeList?.personTypeList.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.tipoPersona && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {selectedShareholderId &&
                          (!tipoPersona || tipoPersona === 'DEFAULT') &&
                          errors.tipoPersona && (
                            <ErrorText hasError={hasError('personType')} />
                          )}
                      </div>
                    </div>
                  </div>

                  {tipoPersona && tipoPersona !== 'DEFAULT' ? (
                    <div className="row fields-modal-content">
                      <div className="row align-items-start">
                        <div className="field-form col-md-4 col-sm-12">
                          <label
                            htmlFor="tipo-documento"
                            className="label-field"
                          >
                            Tipo de documento <span>*</span>
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="tipo-documento"
                              defaultValue={'DEFAULT'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('tipoDocumento', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona
                              </option>
                              {identificationTypeList?.identificationTypeList.map(
                                (option) => {
                                  if (
                                    nombreTipoPersona === 'Persona Física' &&
                                    !option.name.includes('RNC')
                                  ) {
                                    return (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    );
                                  }

                                  if (
                                    nombreTipoPersona === 'Persona Jurídica' &&
                                    !option.name.includes('Cedula')
                                  ) {
                                    return (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    );
                                  }

                                  return null;
                                }
                              )}
                            </select>
                          </div>
                          {errors.tipoDocumento && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )}
                          {selectedShareholderId &&
                            (!tipoDocumento || tipoDocumento === 'DEFAULT') &&
                            errors.tipoDocumento && (
                              <ErrorText
                                hasError={hasError('identificationType')}
                              />
                            )}
                        </div>

                        {tipoDocumento &&
                        identificationTypeList?.identificationTypeList &&
                        identificationTypeList?.identificationTypeList
                          ?.find((i) => i.id === tipoDocumento)
                          ?.name.toLowerCase()
                          .includes('cedula') ? (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label htmlFor="cedula" className="label-field">
                              Número de documento de identidad <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="input-form"
                              maxLength="13"
                              pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                              placeholder="000-0000000-0"
                              id="cedula"
                              disabled={isFormCompleted || isFormDisqualified}
                              onInput={() => {
                                setNotCheck(false);
                                validateInputCedula();
                              }}
                              {...register('numeroDoc', {
                                required: true,
                              })}
                            />
                            {errors.numeroDoc?.type === 'required' && (
                              <p className="text-danger">
                                El campo Número de documento es requerido
                              </p>
                            )}
                            {existingShareholder ? (
                              <p className="text-danger">
                                Ya existe un accionista registrado con ese
                                número de documento.
                              </p>
                            ) : null}
                            {selectedShareholderId &&
                              !numeroDoc &&
                              errors.numeroDoc?.type !== 'required' && (
                                <ErrorText
                                  hasError={hasError('identification')}
                                />
                              )}
                          </div>
                        ) : tipoDocumento &&
                          identificationTypeList?.identificationTypeList &&
                          identificationTypeList?.identificationTypeList
                            ?.find((i) => i.id === tipoDocumento)
                            ?.name.toLowerCase()
                            .includes('rnc') ? (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label htmlFor="rnc" className="label-field">
                              Número de documento de identidad <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="input-form"
                              maxLength="11"
                              pattern="[0-9]{3}-?[0-9]{5}-?[0-9]{1}"
                              placeholder="000-00000-0"
                              disabled={isFormCompleted || isFormDisqualified}
                              onInput={() => validateInputRnc()}
                              id="rnc"
                              {...register('numeroDoc', {
                                required: true,
                              })}
                            />
                            {errors.numeroDoc?.type === 'required' && (
                              <p className="text-danger">
                                El campo Número de documento es requerido
                              </p>
                            )}
                            {existingShareholder ? (
                              <p className="text-danger">
                                Ya existe un accionista registrado con ese
                                número de documento.
                              </p>
                            ) : null}
                            {selectedShareholderId &&
                              !numeroDoc &&
                              errors.numeroDoc?.type !== 'required' && (
                                <ErrorText
                                  hasError={hasError('identification')}
                                />
                              )}
                          </div>
                        ) : (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label htmlFor="numero-doc" className="label-field">
                              Número de documento de identidad <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="input-form"
                              placeholder=""
                              id="numero-doc"
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('numeroDoc', {
                                required: true,
                              })}
                            />
                            {errors.numeroDoc?.type === 'required' && (
                              <p className="text-danger">
                                El campo Número de documento es requerido
                              </p>
                            )}
                            {existingShareholder ? (
                              <p className="text-danger">
                                Ya existe un accionista registrado con ese
                                número de documento.
                              </p>
                            ) : null}
                            {selectedShareholderId &&
                              !numeroDoc &&
                              errors.numeroDoc?.type !== 'required' && (
                                <ErrorText
                                  hasError={hasError('identification')}
                                />
                              )}
                          </div>
                        )}

                        {nombreTipoPersona === 'Persona Física' ? (
                          <>
                            <div className="field-form col-md-4 col-sm-12">
                              <label htmlFor="nombre" className="label-field">
                                Nombre <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="nombre"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('nombre', {
                                  required: true,
                                })}
                              />
                              {errors.nombre?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Nombre completo es requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !nombre &&
                                errors.nombre?.type !== 'required' && (
                                  <ErrorText hasError={hasError('firstName')} />
                                )}
                            </div>

                            <div className="field-form col-md-4 col-sm-12">
                              <label htmlFor="apellido" className="label-field">
                                Apellido <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="apellido"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('apellido', {
                                  required: true,
                                })}
                              />
                              {errors.apellido?.type === 'required' && (
                                <p className="text-danger">
                                  El Apellido es requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !apellido &&
                                errors.apellido?.type !== 'required' && (
                                  <ErrorText hasError={hasError('lastName')} />
                                )}
                            </div>

                            <div className="field-form col-md-4 col-sm-12">
                              <label htmlFor="cargo" className="label-field">
                                Cargo{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="cargo"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('cargo')}
                              />
                            </div>
                          </>
                        ) : null}

                        {nombreTipoPersona === 'Persona Jurídica' ? (
                          <>
                            <div className="field-form col-md-4 col-sm-12">
                              <label
                                htmlFor="denominacion-social"
                                className="label-field"
                              >
                                Denominación Social (Nombre) <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="denominacion-social"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('denominacionSocial', {
                                  required: true,
                                })}
                              />
                              {errors.denominacionSocial?.type ===
                                'required' && (
                                <p className="text-danger">
                                  El campo Nombre completo es requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !denominacionSocial &&
                                errors.denominacionSocial?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError('businessName')}
                                  />
                                )}
                            </div>

                            <div className="field-form col-md-4 col-sm-12">
                              <label
                                htmlFor="tipo-sociedad"
                                className="label-field"
                              >
                                Tipo de sociedad
                              </label>
                              <div className="selectWrapper">
                                {' '}
                                <select
                                  id="tipo-sociedad"
                                  defaultValue="DEFAULT"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tipoSociedad')}
                                >
                                  <option value="DEFAULT" disabled>
                                    Selecciona
                                  </option>
                                  {societyTypeList?.societyTypeList?.map(
                                    (option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              {errors.tipoSociedad && (
                                <p className="text-danger">
                                  Debe seleccionar una opción
                                </p>
                              )}
                            </div>

                            <div className="field-form col-md-4 col-sm-12 special-autocomplete">
                              <label
                                htmlFor="actividad-economica"
                                className="label-field"
                              >
                                Actividad económica <span>*</span>
                              </label>
                              <Autocomplete
                                disablePortal
                                id="controllable-states-demo"
                                value={actividadEconomica}
                                disabled={isFormCompleted || isFormDisqualified}
                                onChange={(event, newValue) => {
                                  setActividadEconomica(newValue);
                                }}
                                inputValue={inputActividadEconomica}
                                onInputChange={(event, newInputValue) => {
                                  setInputActividadEconomica(newInputValue);
                                }}
                                options={
                                  economicActivities?.economicActivities?.length
                                    ? economicActivities?.economicActivities?.map(
                                        (type) => ({
                                          label: type.name,
                                          id: type.id,
                                        })
                                      )
                                    : []
                                }
                                renderInput={(params) => (
                                  <TextField
                                    placeholder={
                                      economicActivities?.economicActivities
                                        ?.length
                                        ? 'Busca y selecciona'
                                        : 'Cargando...'
                                    }
                                    {...params}
                                    {...register('actividadEconomica', {
                                      required: true,
                                    })}
                                  />
                                )}
                              />
                              <p
                                className="text-danger"
                                style={{
                                  marginTop: 8,
                                  visibility: errors.actividadEconomica
                                    ? 'visible'
                                    : 'hidden',
                                }}
                              >
                                Debe seleccionar una opción
                              </p>
                              {selectedShareholderId &&
                                !actividadEconomica &&
                                errors.actividadEconomica && (
                                  <ErrorText
                                    hasError={hasError('businessName')}
                                  />
                                )}
                            </div>

                            <div className="field-form col-md-4 col-sm-12">
                              <label
                                htmlFor="posee-acciones-portador"
                                className="label-field"
                              >
                                ¿Posee acciones al portador?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="posee-acciones-portador"
                                  defaultValue={'DEFAULT'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('poseeAccionesPortador')}
                                >
                                  <option value="DEFAULT" disabled>
                                    Selecciona
                                  </option>
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                              {errors.poseeAccionesPortador && (
                                <p className="text-danger">
                                  Debe seleccionar una opción
                                </p>
                              )}
                            </div>
                            <div className="field-form col-md-4 col-sm-12 mb-4">
                              <label
                                htmlFor="accionistas-mayoritarios"
                                className="label-field"
                              >
                                Accionistas Mayoritarios <span>*</span>
                              </label>
                              <textarea
                                rows="5"
                                className="form-control"
                                placeholder=""
                                id="accionistas-mayoritarios"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('accionistasMayoritarios', {
                                  required: true,
                                })}
                              ></textarea>
                              {errors.accionistasMayoritarios?.type ===
                                'required' && (
                                <p className="text-danger mt-2">
                                  Este campo es requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !accionistasMayoritarios &&
                                errors.accionistasMayoritarios?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError(
                                      'controllingShareholders'
                                    )}
                                  />
                                )}
                            </div>
                            <div className="field-form col-md-4 col-sm-12">
                              <label htmlFor="relacion" className="label-field">
                                Relación con PARVAL <span>*</span>
                              </label>
                              <div className="selectWrapper">
                                {' '}
                                <select
                                  id="relacion"
                                  defaultValue="DEFAULT"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('relacion', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="DEFAULT" disabled>
                                    Selecciona
                                  </option>
                                  {parvalRelationships?.parvalRelationships?.map(
                                    (option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              {errors.relacion && (
                                <p className="text-danger">
                                  Debe seleccionar una opción
                                </p>
                              )}
                              {selectedShareholderId &&
                                (!relacion || relacion === 'DEFAULT') &&
                                errors.relacion && (
                                  <ErrorText
                                    hasError={hasError('parvalRelationship')}
                                  />
                                )}
                            </div>
                          </>
                        ) : null}

                        <div className="field-form col-md-4 col-sm-12">
                          <label
                            htmlFor="numero-acciones"
                            className="label-field"
                          >
                            Número de Acciones<span>*</span>
                          </label>
                          <input
                            type="number"
                            className="input-form"
                            placeholder=""
                            id="numero-acciones"
                            disabled={isFormCompleted || isFormDisqualified}
                            onKeyUp={calculateOwnershipPercentage}
                            aria-describedby="numero-accionesHelp"
                            {...register('numeroAcciones', {
                              required: true,
                            })}
                          />
                          {errors.numeroAcciones?.type === 'required' && (
                            <p className="text-danger">
                              El campo Número de Acciones es requerido
                            </p>
                          )}
                          {selectedShareholderId &&
                            !numeroAcciones &&
                            errors.numeroAcciones?.type !== 'required' && (
                              <ErrorText hasError={hasError('sharesNumber')} />
                            )}
                        </div>

                        <div className="field-form col-md-4 col-sm-12">
                          <label
                            htmlFor="porcentaje-participacion"
                            className="label-field"
                          >
                            Porcentaje de Participación <span>*</span>
                          </label>
                          <input
                            type="number"
                            className="input-form"
                            placeholder=""
                            disabled={isFormCompleted || isFormDisqualified}
                            max={getAvailableOwnershipPercentage()}
                            onKeyUp={calculateSharesNumber}
                            id="porcentaje-participacion"
                            {...register('porcentajeParticipacion', {
                              required: true,
                              max: getAvailableOwnershipPercentage(),
                            })}
                          />
                          {!errors.porcentajeParticipacion && (
                            <small
                              id="numero-accionesHelp"
                              style={{ marginTop: '-32px', fontSize: '13px' }}
                              className="text-muted d-block"
                            >
                              Porcentaje Disponible:{' '}
                              {getAvailableOwnershipPercentage()}%
                            </small>
                          )}
                          {errors.porcentajeParticipacion?.type ===
                            'required' && (
                            <p className="text-danger">
                              Este campo es requerido
                            </p>
                          )}
                          {errors.porcentajeParticipacion?.type === 'max' && (
                            <p className="text-danger">
                              No mayor a {getAvailableOwnershipPercentage()}%
                            </p>
                          )}
                          {selectedShareholderId &&
                            !porcentajeParticipacion &&
                            errors.porcentajeParticipacion?.type !==
                              'required' && (
                              <ErrorText
                                hasError={hasError('ownershipPercentage')}
                              />
                            )}
                        </div>

                        <div className="field-form col-md-4 col-sm-12 special-autocomplete">
                          <label htmlFor="nacionalidad" className="label-field">
                            Nacionalidad <span>*</span>
                          </label>
                          <Autocomplete
                            disablePortal
                            id="controllable-states-demo"
                            value={nacionalidad}
                            disabled={isFormCompleted || isFormDisqualified}
                            onChange={(event, newValue) => {
                              setNacionalidad(newValue);
                            }}
                            inputValue={inputNacionalidad}
                            onInputChange={(event, newInputValue) => {
                              setInputNacionalidad(newInputValue);
                            }}
                            options={
                              countries?.countries?.length
                                ? countries?.countries?.map((type) => ({
                                    label: type.name,
                                    id: type.id,
                                  }))
                                : []
                            }
                            renderInput={(params) => (
                              <TextField
                                placeholder={
                                  countries?.countries?.length
                                    ? 'Busca y selecciona'
                                    : 'Cargando...'
                                }
                                {...params}
                                {...register('nacionalidad', {
                                  required: true,
                                })}
                              />
                            )}
                          />
                          <p
                            className="text-danger"
                            style={{
                              marginTop: 8,
                              visibility: errors.nacionalidad
                                ? 'visible'
                                : 'hidden',
                            }}
                          >
                            Debe seleccionar una opción
                          </p>
                          {selectedShareholderId &&
                            !nacionalidad &&
                            !errors.nacionalidad && (
                              <ErrorText hasError={hasError('country')} />
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {nombreTipoPersona === 'Persona Física' ? (
                    <div className="row fields-modal-content mt-3">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Persona políticamente expuesta
                        </h4>
                      </div>
                      <div className="row">
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="persona-politicamente-expuesta"
                            className="label-field"
                          >
                            ¿Es una persona políticamente expuesta?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="persona-politicamente-expuesta"
                              defaultValue={'No'}
                              disabled={
                                (isFormCompleted || isFormDisqualified) ||
                                ((getIdentificationTypeName(tipoDocumento) ===
                                  'Cedula de Identidad y Electoral' &&
                                  !checkPoliticExposureError?.message) ||
                                getIdentificationTypeName(tipoDocumento) ===
                                  undefined
                                  ? true
                                  : false)
                              }
                              {...register('personaPoliticamenteExpuesta', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        {personaPoliticamenteExpuesta === 'Si' ? (
                          <>
                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tipoExposicionPolitica"
                                className="label-field"
                              >
                                Tipo de exposición política <span>*</span>
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tipoExposicionPolitica"
                                  defaultValue={'DEFAULT'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tipoExposicionPolitica', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="DEFAULT" disabled>
                                    Selecciona
                                  </option>
                                  {politicExposureTypes?.politicExposureTypes?.map(
                                    (option) => (
                                      <option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              {errors.tipoExposicionPolitica && (
                                <p className="text-danger">
                                  Debe seleccionar una opción
                                </p>
                              )}
                              {selectedShareholderId &&
                                (!tipoExposicionPolitica ||
                                  tipoExposicionPolitica === 'DEFAULT') &&
                                errors.tipoExposicionPolitica && (
                                  <ErrorText
                                    hasError={hasError('politicExposureType')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-12 col-sm-12">
                              <label
                                htmlFor="cargo-pep"
                                className="label-field"
                              >
                                Cargo <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="cargo-pep"
                                disabled={isFormCompleted || isFormDisqualified}
                                readOnly={
                                  checkPoliticExposureResponse &&
                                  (getIdentificationTypeName(tipoDocumento) ===
                                    'Cedula de Identidad y Electoral' ||
                                  getIdentificationTypeName(tipoDocumento) ===
                                    undefined
                                    ? true
                                    : false)
                                }
                                {...register('cargoPep', {
                                  required: true,
                                })}
                              />
                              {errors.cargoPep?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Cargo es requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !cargoPep &&
                                errors.cargoPep?.type !== 'required' && (
                                  <ErrorText hasError={hasError('charge')} />
                                )}
                            </div>

                            {getPoliticExposureTypeName(
                              tipoExposicionPolitica
                            ) === 'Familiar' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label
                                  htmlFor="tipoRelacionFamiliar"
                                  className="label-field"
                                >
                                  Indique su parentesco con ese familiar{' '}
                                  <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="tipoRelacionFamiliar"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tipoRelacionFamiliar', {
                                    required: true,
                                    maxLength: 100,
                                  })}
                                />
                                {errors.tipoRelacionFamiliar?.type ===
                                  'required' && (
                                  <p className="text-danger">
                                    Este campo es requerido
                                  </p>
                                )}
                                {errors.tipoRelacionFamiliar?.type ===
                                  'maxLength' && (
                                  <p className="text-danger">
                                    Este campo solo puede tener 100 caracteres
                                    como máximo
                                  </p>
                                )}
                                {selectedShareholderId &&
                                  !tipoRelacionFamiliar &&
                                  errors.tipoRelacionFamiliar?.type !==
                                    'required' && (
                                    <ErrorText
                                      hasError={hasError(
                                        'relativeRelationship'
                                      )}
                                    />
                                  )}
                              </div>
                            ) : null}

                            {getPoliticExposureTypeName(
                              tipoExposicionPolitica
                            ) === 'Familiar' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label
                                  htmlFor="nombreDelFamiliar"
                                  className="label-field"
                                >
                                  Nombre completo del familiar <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="nombreDelFamiliar"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('nombreDelFamiliar', {
                                    required: true,
                                    maxLength: 100,
                                  })}
                                />
                                {errors.nombreDelFamiliar?.type ===
                                  'required' && (
                                  <p className="text-danger">
                                    Este campo es requerido
                                  </p>
                                )}
                                {errors.nombreDelFamiliar?.type ===
                                  'maxLength' && (
                                  <p className="text-danger">
                                    Este campo solo puede tener 100 caracteres
                                    como máximo
                                  </p>
                                )}
                                {selectedShareholderId &&
                                  !nombreDelFamiliar &&
                                  errors.nombreDelFamiliar?.type !==
                                    'required' && (
                                    <ErrorText
                                      hasError={hasError('relativeName')}
                                    />
                                  )}
                              </div>
                            ) : null}

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="pep-activo"
                                className="label-field"
                              >
                                ¿Está activo en el cargo?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="pep-activo"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('pepActivo', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="fecha-inicio-cargo"
                                className="label-field"
                              >
                                Fecha de inicio en el cargo <span>*</span>
                              </label>
                              <input
                                type="date"
                                className="input-form"
                                placeholder=""
                                id="fecha-inicio-cargo"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('fechaInicioCargo', {
                                  required: true,
                                })}
                              />
                              {errors.fechaInicioCargo?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Fecha de inicio en el cargo es
                                  requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !fechaInicioCargo &&
                                errors.fechaInicioCargo?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError('chargeStartDate')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="ultima-fecha-cargo"
                                className="label-field"
                              >
                                Última fecha en el cargo{' '}
                                <span>{pepActivo === 'No' ? '*' : ''}</span>
                              </label>
                              <input
                                type="date"
                                disabled={isFormCompleted || pepActivo === 'Si'}
                                className="input-form"
                                placeholder=""
                                id="ultima-fecha-cargo"
                                {...register('ultimaFechaCargo', {
                                  required: pepActivo === 'No',
                                })}
                              />
                              {errors.ultimaFechaCargo?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Última fecha en el cargo es requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !ultimaFechaCargo &&
                                errors.ultimaFechaCargo?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError('chargeEndDate')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="institucion"
                                className="label-field"
                              >
                                Institución <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="institucion"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('institucion', {
                                  required: true,
                                })}
                              />
                              {errors.institucion?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Institución es requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !institucion &&
                                errors.institucion?.type !== 'required' && (
                                  <ErrorText
                                    hasError={hasError('institution')}
                                  />
                                )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {nombreTipoPersona && nombreTipoPersona !== null ? (
                    <div className="row mt-4 fields-modal-content">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Foreign Account Tax Compliance Act
                        </h4>
                      </div>
                      <div className="row">
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="tieneNacionalidadEU"
                            className="label-field"
                          >
                            ¿Tiene nacionalidad de EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="tieneNacionalidadEU"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('tieneNacionalidadEU', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="esCiudadanoEU"
                            className="label-field"
                          >
                            ¿Es ciudadano de los EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="esCiudadanoEU"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('esCiudadanoEU', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="esResidenteEU"
                            className="label-field"
                          >
                            ¿Es residente de los EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="esResidenteEU"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('esResidenteEU', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        {tieneNacionalidadEU === 'Si' ||
                        esCiudadanoEU === 'Si' ||
                        esResidenteEU === 'Si' ? (
                          <>
                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="codigoPostalEU"
                                className="label-field"
                              >
                                Código Postal en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="codigoPostalEU"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('codigoPostalEU')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="telefonoEU"
                                className="label-field"
                              >
                                Télefono en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="telefonoEU"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('telefonoEU')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tieneRepresentanteEU"
                                className="label-field"
                              >
                                ¿Tiene un apoderado en los EE.UU.?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tieneRepresentanteEU"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tieneRepresentanteEU', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label htmlFor="tieneTIN" className="label-field">
                                ¿Tiene identidad para el pago de impuestos
                                (TIN)?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tieneTIN"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tieneTIN', {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  })}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            {tieneTIN === 'Si' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label htmlFor="tin" className="label-field">
                                  Número de Identificación Tributaria de los
                                  EE.UU. (TIN) <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="tin"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...register('tin', {
                                    required: true,
                                  })}
                                />
                                {errors.tin?.type === 'required' && (
                                  <p className="text-danger">
                                    El campo Número de Identificación Tributaria
                                    de los EE.UU. (TIN) es requerido
                                  </p>
                                )}
                                {selectedShareholderId &&
                                  !tin &&
                                  errors.tin?.type !== 'required' && (
                                    <ErrorText
                                      hasError={hasError(
                                        'taxIdentificationNumber'
                                      )}
                                    />
                                  )}
                              </div>
                            ) : null}

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tarjetaVerde"
                                className="label-field"
                              >
                                Tarjeta de Identidad de los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="tarjetaVerde"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('tarjetaVerde')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="direccionEU"
                                className="label-field"
                              >
                                Dirección en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="direccionEU"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('direccionEU')}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="seguroSocial"
                                className="label-field"
                              >
                                Número de Seguro Social EE.UU. <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="seguroSocial"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...register('seguroSocial', {
                                  required: true,
                                })}
                              />
                              {errors.seguroSocial?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Número de Seguro Social EE.UU. es
                                  requerido
                                </p>
                              )}
                              {selectedShareholderId &&
                                !seguroSocial &&
                                errors.seguroSocial?.type !== 'required' && (
                                  <ErrorText
                                    hasError={hasError('socialSecurityNumber')}
                                  />
                                )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {nombreTipoPersona === 'Persona Jurídica' ? (
                    <div className="row fields-modal-content">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Beneficiarios finales
                        </h4>
                      </div>
                      <div className="row">
                        <div className="add-card-container">
                          {beneficiaries?.map((beneficiary, index) => {
                            return (
                              <div key={index}>
                                <CardItems
                                  key={index}
                                  nameElement={
                                    beneficiary?.firstName +
                                    ' ' +
                                    beneficiary?.lastName
                                  }
                                  identityDocument={
                                    beneficiary?.identificationNumber
                                  }
                                  idElement={beneficiary?.id}
                                  deleteElement={deleteCardElementBeneficiary}
                                  handleEdit={handleEditBeneficiary}
                                  setIsUpdating={setIsUpdatingBeneficiary}
                                  showDeleteButton={!isFormCompleted && !isFormDisqualified}
                                  detalle={
                                    beneficiary?.politicExposure?.isPep
                                      ? 'Persona politicamente expuesta'
                                      : null
                                  }
                                />
                              </div>
                            );
                          })}
                          {!isFormCompleted && !isFormDisqualified ? (
                            <div
                              className="add-card"
                              onClick={() => {
                                resetBeneficiary();
                                setExistingBeneficiary(false);
                                setSelectedBeneficiaryId('');
                                setOpenBeneficiaryModal(true);
                                setIsUpdatingBeneficiary(false);
                                setFatcaBeneficiaryId('');
                                setPoliticExposureBeneficiaryId('');
                                document.getElementById(
                                  'exampleModal'
                                ).style.display = 'none';
                              }}
                            >
                              <div className="image-icon-container">
                                <FontAwesomeIcon
                                  icon={faAdd}
                                  className="image-icon"
                                />
                              </div>
                              <h4 className="bk-card-title">
                                Agregar Beneficiario
                              </h4>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="row d-flex justify-content-center">
                      <button type="submit" className="mt-5 mb-5 btn-add">
                        Guardar
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>

          {openBeneficiaryModal ? (
            <div className="complex-modal">
              <div className="complex-lg">
                <div className="complex-content">
                  <form
                    onSubmit={handleSubmitBeneficiary(addBeneficiary)}
                    className="fields-modal-container"
                  >
                    <div className="fields-modal-header">
                      <h4 className="modal-title">
                        {!isFormCompleted && !isFormDisqualified
                          ? selectedBeneficiaryId
                            ? 'Editar'
                            : 'Agregar'
                          : 'Ver'}{' '}
                        Beneficiario
                      </h4>
                      <button
                        type="button"
                        onClick={() => {
                          resetBeneficiary();
                          setExistingBeneficiary(false);
                          setSelectedBeneficiaryId('');
                          setOpenBeneficiaryModal(false);
                          setFatcaBeneficiaryId('');
                          setPoliticExposureBeneficiaryId('');
                          document.getElementById(
                            'exampleModal'
                          ).style.display = 'block';
                        }}
                        className="btn-close"
                      ></button>
                    </div>

                    <div className="row fields-modal-content">
                      <div className="row">
                        {!isFormCompleted && !isFormDisqualified ? (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label
                              htmlFor="copiar-contacto-beneficiario"
                              className="label-field"
                            >
                              ¿Copiar información de otro contacto?{' '}
                            </label>
                            <div className="selectWrapper">
                              <select
                                id="copiar-contacto-beneficiario"
                                defaultValue="No"
                                {...registerBeneficiary(
                                  'copiarContactoBeneficiario'
                                )}
                              >
                                <option value="No">No</option>
                                <option value="Si">Si</option>
                              </select>
                            </div>
                          </div>
                        ) : null}
                        {!isFormCompleted && !isFormDisqualified &&
                        copiarContactoBeneficiario === 'Si' ? (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label
                              htmlFor="contacto-beneficiario"
                              className="label-field"
                            >
                              Contacto <span>*</span>
                            </label>
                            <div className="selectWrapper">
                              <select
                                id="contacto-beneficiario"
                                defaultValue={'DEFAULT'}
                                {...registerBeneficiary(
                                  'contactoBeneficiario',
                                  {
                                    validate:
                                      validateNonSelectedOptionInDropdown,
                                  }
                                )}
                              >
                                <option value="DEFAULT" disabled>
                                  Selecciona
                                </option>
                                {removeBeneficiariesFromContacts(
                                  beneficiaryContacts
                                ).map((contact) => {
                                  return (
                                    <option
                                      key={contact?.id}
                                      value={contact?.id}
                                    >
                                      {contact?.firstName +
                                        ' ' +
                                        contact?.lastName}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errorsBeneficiary.copiarContacto && (
                              <p className="text-danger">
                                Debe seleccionar una opción
                              </p>
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="row">
                        <div className="field-form col-lg-4 col-sm-12">
                          <label
                            htmlFor="nombresBeneficiario"
                            className="label-field"
                          >
                            Nombres <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="nombresBeneficiario"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...registerBeneficiary('nombresBeneficiario', {
                              required: true,
                            })}
                          />
                          {errorsBeneficiary.nombresBeneficiario?.type ===
                            'required' && (
                            <p className="text-danger">
                              El campo Nombres es requerido
                            </p>
                          )}
                          {selectedBeneficiaryId &&
                            !nombresBeneficiario &&
                            errorsBeneficiary.nombresBeneficiario?.type !==
                              'required' && (
                              <ErrorText hasError={hasError('firstName')} />
                            )}
                        </div>

                        <div className="field-form col-lg-4 col-sm-12">
                          <label
                            htmlFor="apellidosBeneficiario"
                            className="label-field"
                          >
                            Apellidos <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="apellidosBeneficiario"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...registerBeneficiary('apellidosBeneficiario', {
                              required: true,
                            })}
                          />
                          {errorsBeneficiary.apellidosBeneficiario?.type ===
                            'required' && (
                            <p className="text-danger">
                              El campo Apellidos es requerido
                            </p>
                          )}
                          {selectedBeneficiaryId &&
                            !apellidosBeneficiario &&
                            errors.apellidosBeneficiario?.type !==
                              'required' && (
                              <ErrorText hasError={hasError('lastName')} />
                            )}
                        </div>

                        <div className="field-form col-lg-4 col-sm-12">
                          <label
                            htmlFor="tipo-documentoBeneficiario"
                            className="label-field"
                          >
                            Tipo de documento <span>*</span>
                          </label>
                          <div className="selectWrapper">
                            {' '}
                            <select
                              id="tipo-documentoBeneficiario"
                              defaultValue={'DEFAULT'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...registerBeneficiary(
                                'tipoDocumentoBeneficiario',
                                {
                                  validate: validateNonSelectedOptionInDropdown,
                                }
                              )}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona
                              </option>
                              {identificationTypeList?.identificationTypeList.map(
                                (identificationType) => {
                                  return (
                                    <option
                                      key={identificationType.id}
                                      value={identificationType.id}
                                    >
                                      {identificationType.name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          {errorsBeneficiary.tipoDocumentoBeneficiario && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )}
                          {selectedBeneficiaryId &&
                            (!tipoDocumentoBeneficiario ||
                              tipoDocumentoBeneficiario === 'DEFAULT') &&
                            errors.tipoDocumentoBeneficiario && (
                              <ErrorText
                                hasError={hasError('identificationType')}
                              />
                            )}
                        </div>

                        {tipoDocumentoBeneficiario &&
                        identificationTypeList?.identificationTypeList &&
                        identificationTypeList?.identificationTypeList
                          ?.find((i) => i.id === tipoDocumentoBeneficiario)
                          ?.name.toLowerCase()
                          .includes('cedula') ? (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label
                              htmlFor="cedulaBeneficiario"
                              className="label-field"
                            >
                              Número de documento de identidad <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="input-form"
                              maxLength="13"
                              pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                              placeholder="000-0000000-0"
                              id="cedulaBeneficiario"
                              disabled={isFormCompleted || isFormDisqualified}
                              onInput={() => {
                                setNotCheckBeneficiary(false);
                                validateInputCedula('cedulaBeneficiario');
                              }}
                              {...registerBeneficiary('numeroDocBeneficiario', {
                                required: true,
                              })}
                            />
                            {errorsBeneficiary.numeroDocBeneficiario?.type ===
                              'required' && (
                              <p className="text-danger">
                                El campo Número de documento es requerido
                              </p>
                            )}
                            {existingBeneficiary ? (
                              <p className="text-danger">
                                Ya existe un beneficiario registrado con ese
                                número de documento.
                              </p>
                            ) : null}
                            {selectedBeneficiaryId &&
                              !numeroDocBeneficiario &&
                              errors.numeroDocBeneficiario?.type !==
                                'required' && (
                                <ErrorText
                                  hasError={hasError('identificationNumber')}
                                />
                              )}
                          </div>
                        ) : tipoDocumentoBeneficiario &&
                          identificationTypeList?.identificationTypeList &&
                          identificationTypeList?.identificationTypeList
                            ?.find((i) => i.id === tipoDocumentoBeneficiario)
                            ?.name.toLowerCase()
                            .includes('rnc') ? (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label
                              htmlFor="rncBeneficiario"
                              className="label-field"
                            >
                              Número de documento de identidad <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="input-form"
                              maxLength="11"
                              pattern="[0-9]{3}-?[0-9]{5}-?[0-9]{1}"
                              placeholder="000-00000-0"
                              disabled={isFormCompleted || isFormDisqualified}
                              onInput={() =>
                                validateInputRnc('rncBeneficiario')
                              }
                              id="rncBeneficiario"
                              {...registerBeneficiary('numeroDocBeneficiario', {
                                required: true,
                              })}
                            />
                            {errorsBeneficiary.numeroDocBeneficiario?.type ===
                              'required' && (
                              <p className="text-danger">
                                El campo Número de documento es requerido
                              </p>
                            )}
                            {existingBeneficiary ? (
                              <p className="text-danger">
                                Ya existe un beneficiario registrado con ese
                                número de documento.
                              </p>
                            ) : null}
                            {selectedBeneficiaryId &&
                              !numeroDocBeneficiario &&
                              errors.numeroDocBeneficiario?.type !==
                                'required' && (
                                <ErrorText
                                  hasError={hasError('identificationNumber')}
                                />
                              )}
                          </div>
                        ) : (
                          <div className="field-form  col-md-4 col-sm-12">
                            <label
                              htmlFor="numero-docBeneficiario"
                              className="label-field"
                            >
                              Número de documento de identidad <span>*</span>
                            </label>
                            <input
                              type="text"
                              className="input-form"
                              placeholder=""
                              id="numero-docBeneficiario"
                              disabled={isFormCompleted || isFormDisqualified}
                              {...registerBeneficiary('numeroDocBeneficiario', {
                                required: true,
                              })}
                            />
                            {errorsBeneficiary.numeroDocBeneficiario?.type ===
                              'required' && (
                              <p className="text-danger">
                                El campo Número de documento es requerido
                              </p>
                            )}
                            {existingBeneficiary ? (
                              <p className="text-danger">
                                Ya existe un beneficiario registrado con ese
                                número de documento.
                              </p>
                            ) : null}
                            {selectedBeneficiaryId &&
                              !numeroDocBeneficiario &&
                              errors.numeroDocBeneficiario?.type !==
                                'required' && (
                                <ErrorText
                                  hasError={hasError('identificationNumber')}
                                />
                              )}
                          </div>
                        )}

                        <div className="field-form col-lg-4 col-sm-12">
                          <label
                            htmlFor="ocupacionBeneficiario"
                            className="label-field"
                          >
                            Ocupación{' '}
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="ocupacionBeneficiario"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...registerBeneficiary('ocupacionBeneficiario')}
                          />
                        </div>

                        <div className="field-form col-lg-4 col-sm-12">
                          <label
                            htmlFor="telefono-contactoBeneficiario"
                            className="label-field"
                          >
                            Teléfono contacto{' '}
                          </label>
                          <input
                            type="number"
                            className="input-form"
                            placeholder=""
                            id="telefono-contactoBeneficiario"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...registerBeneficiary(
                              'telefonoContactoBeneficiario'
                            )}
                          />
                        </div>

                        <div className="field-form col-lg-4 col-sm-12">
                          <label
                            htmlFor="direccionBeneficiario"
                            className="label-field"
                          >
                            Dirección{' '}
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="direccionBeneficiario"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...registerBeneficiary('direccionBeneficiario')}
                          />
                        </div>

                        <div className="field-form col-lg-4 col-sm-12">
                          <label
                            htmlFor="correo-electronicoBeneficiario"
                            className="label-field"
                          >
                            Correo electrónico <span>*</span>
                          </label>
                          <input
                            type="email"
                            className="input-form"
                            placeholder=""
                            id="correo-electronicoBeneficiario"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...registerBeneficiary(
                              'correoElectronicoBeneficiario',
                              {
                                required: true,
                              }
                            )}
                          />
                          {errorsBeneficiary.correoElectronicoBeneficiario
                            ?.type === 'required' && (
                            <p className="text-danger">
                              Este campo es requerido
                            </p>
                          )}
                          {selectedBeneficiaryId &&
                            !correoElectronicoBeneficiario &&
                            errors.correoElectronicoBeneficiario?.type !==
                              'required' && (
                              <ErrorText hasError={hasError('email')} />
                            )}
                        </div>
                      </div>
                    </div>

                    <div className="row fields-modal-content">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Persona políticamente expuesta
                        </h4>
                      </div>
                      <div className="row">
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="persona-politicamente-expuestaBeneficiario"
                            className="label-field"
                          >
                            ¿Es una persona políticamente expuesta?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="persona-politicamente-expuestaBeneficiario"
                              defaultValue={'No'}
                              disabled={
                                (isFormCompleted || isFormDisqualified) ||
                                ((getIdentificationTypeName(
                                  tipoDocumentoBeneficiario
                                ) === 'Cedula de Identidad y Electoral' &&
                                  !checkPoliticExposureErrorBeneficiary?.message) ||
                                getIdentificationTypeName(
                                  tipoDocumentoBeneficiario
                                ) === undefined
                                  ? true
                                  : false)
                              }
                              {...registerBeneficiary(
                                'personaPoliticamenteExpuestaBeneficiario',
                                {
                                  validate: validateNonSelectedOptionInDropdown,
                                }
                              )}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        {personaPoliticamenteExpuestaBeneficiario === 'Si' ? (
                          <>
                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tipoExposicionPoliticaBeneficiario"
                                className="label-field"
                              >
                                Tipo de exposición política <span>*</span>
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tipoExposicionPoliticaBeneficiario"
                                  defaultValue={'DEFAULT'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...registerBeneficiary(
                                    'tipoExposicionPoliticaBeneficiario',
                                    {
                                      validate:
                                        validateNonSelectedOptionInDropdown,
                                    }
                                  )}
                                >
                                  <option value="DEFAULT" disabled>
                                    Selecciona
                                  </option>
                                  {politicExposureTypes?.politicExposureTypes?.map(
                                    (option) => (
                                      <option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              {errorsBeneficiary.tipoExposicionPoliticaBeneficiario && (
                                <p className="text-danger">
                                  Debe seleccionar una opción
                                </p>
                              )}
                              {selectedBeneficiaryId &&
                                (!tipoExposicionPoliticaBeneficiario ||
                                  tipoExposicionPoliticaBeneficiario ===
                                    'DEFAULT') &&
                                errors.tipoExposicionPoliticaBeneficiario && (
                                  <ErrorText
                                    hasError={hasError('politicExposureType')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-12 col-sm-12">
                              <label
                                htmlFor="cargo-pepBeneficiario"
                                className="label-field"
                              >
                                Cargo <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="cargo-pepBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                readOnly={
                                  checkPoliticExposureResponseBeneficiary &&
                                  (getIdentificationTypeName(
                                    tipoDocumentoBeneficiario
                                  ) === 'Cedula de Identidad y Electoral' ||
                                  getIdentificationTypeName(
                                    tipoDocumentoBeneficiario
                                  ) === undefined
                                    ? true
                                    : false)
                                }
                                {...registerBeneficiary(
                                  'cargoPepBeneficiario',
                                  {
                                    required: true,
                                  }
                                )}
                              />
                              {errorsBeneficiary.cargoPepBeneficiario?.type ===
                                'required' && (
                                <p className="text-danger">
                                  El campo Cargo es requerido
                                </p>
                              )}
                              {selectedBeneficiaryId &&
                                !cargoPepBeneficiario &&
                                errors.cargoPepBeneficiario?.type !==
                                  'required' && (
                                  <ErrorText hasError={hasError('charge')} />
                                )}
                            </div>

                            {getPoliticExposureTypeName(
                              tipoExposicionPoliticaBeneficiario
                            ) === 'Familiar' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label
                                  htmlFor="tipoRelacionFamiliarBeneficiario"
                                  className="label-field"
                                >
                                  Indique su parentesco con ese familiar{' '}
                                  <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="tipoRelacionFamiliarBeneficiario"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...registerBeneficiary(
                                    'tipoRelacionFamiliarBeneficiario',
                                    {
                                      required: true,
                                      maxLength: 100,
                                    }
                                  )}
                                />
                                {errorsBeneficiary
                                  .tipoRelacionFamiliarBeneficiario?.type ===
                                  'required' && (
                                  <p className="text-danger">
                                    Este campo es requerido
                                  </p>
                                )}
                                {errorsBeneficiary
                                  .tipoRelacionFamiliarBeneficiario?.type ===
                                  'maxLength' && (
                                  <p className="text-danger">
                                    Este campo solo puede tener 100 caracteres
                                    como máximo
                                  </p>
                                )}
                                {selectedBeneficiaryId &&
                                  !tipoRelacionFamiliarBeneficiario &&
                                  errors.tipoRelacionFamiliarBeneficiario
                                    ?.type !== 'required' && (
                                    <ErrorText
                                      hasError={hasError(
                                        'relativeRelationship'
                                      )}
                                    />
                                  )}
                              </div>
                            ) : null}

                            {getPoliticExposureTypeName(
                              tipoExposicionPoliticaBeneficiario
                            ) === 'Familiar' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label
                                  htmlFor="nombreDelFamiliarBeneficiario"
                                  className="label-field"
                                >
                                  Nombre completo del familiar <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="nombreDelFamiliarBeneficiario"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...registerBeneficiary(
                                    'nombreDelFamiliarBeneficiario',
                                    {
                                      required: true,
                                      maxLength: 100,
                                    }
                                  )}
                                />
                                {errorsBeneficiary.nombreDelFamiliarBeneficiario
                                  ?.type === 'required' && (
                                  <p className="text-danger">
                                    Este campo es requerido
                                  </p>
                                )}
                                {errorsBeneficiary.nombreDelFamiliarBeneficiario
                                  ?.type === 'maxLength' && (
                                  <p className="text-danger">
                                    Este campo solo puede tener 100 caracteres
                                    como máximo
                                  </p>
                                )}
                                {selectedBeneficiaryId &&
                                  !nombreDelFamiliarBeneficiario &&
                                  errors.nombreDelFamiliarBeneficiario?.type !==
                                    'required' && (
                                    <ErrorText
                                      hasError={hasError('relativeName')}
                                    />
                                  )}
                              </div>
                            ) : null}

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="pep-activoBeneficiario"
                                className="label-field"
                              >
                                ¿Está activo en el cargo?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="pep-activoBeneficiario"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...registerBeneficiary(
                                    'pepActivoBeneficiario',
                                    {
                                      validate:
                                        validateNonSelectedOptionInDropdown,
                                    }
                                  )}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="fecha-inicio-cargoBeneficiario"
                                className="label-field"
                              >
                                Fecha de inicio en el cargo <span>*</span>
                              </label>
                              <input
                                type="date"
                                className="input-form"
                                placeholder=""
                                id="fecha-inicio-cargoBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...registerBeneficiary(
                                  'fechaInicioCargoBeneficiario',
                                  {
                                    required: true,
                                  }
                                )}
                              />
                              {errorsBeneficiary.fechaInicioCargoBeneficiario
                                ?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Fecha de inicio en el cargo es
                                  requerido
                                </p>
                              )}
                              {selectedBeneficiaryId &&
                                !fechaInicioCargoBeneficiario &&
                                errors.fechaInicioCargoBeneficiario?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError('chargeStartDate')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="ultima-fecha-cargoBeneficiario"
                                className="label-field"
                              >
                                Última fecha en el cargo{' '}
                                <span>
                                  {pepActivoBeneficiario === 'No' ? '*' : ''}
                                </span>
                              </label>
                              <input
                                type="date"
                                disabled={
                                  (isFormCompleted || isFormDisqualified) ||
                                  pepActivoBeneficiario === 'Si'
                                }
                                className="input-form"
                                placeholder=""
                                id="ultima-fecha-cargoBeneficiario"
                                {...registerBeneficiary(
                                  'ultimaFechaCargoBeneficiario',
                                  {
                                    required: pepActivoBeneficiario === 'No',
                                  }
                                )}
                              />
                              {errorsBeneficiary.ultimaFechaCargoBeneficiario
                                ?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Última fecha en el cargo es requerido
                                </p>
                              )}
                              {selectedBeneficiaryId &&
                                !ultimaFechaCargoBeneficiario &&
                                errors.ultimaFechaCargoBeneficiario?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError('chargeEndDate')}
                                  />
                                )}
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="institucionBeneficiario"
                                className="label-field"
                              >
                                Institución <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="institucionBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...registerBeneficiary(
                                  'institucionBeneficiario',
                                  {
                                    required: true,
                                  }
                                )}
                              />
                              {errorsBeneficiary.institucionBeneficiario
                                ?.type === 'required' && (
                                <p className="text-danger">
                                  El campo Institución es requerido
                                </p>
                              )}
                              {selectedBeneficiaryId &&
                                !institucionBeneficiario &&
                                errors.institucionBeneficiario?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError('institution')}
                                  />
                                )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>

                    <div className="row mt-4 fields-modal-content">
                      <div className="fields-modal-header">
                        <h4 className="modal-title" id="exampleModalLabel">
                          Foreign Account Tax Compliance Act
                        </h4>
                      </div>
                      <div className="row">
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="tieneNacionalidadEUBeneficiario"
                            className="label-field"
                          >
                            ¿Tiene nacionalidad de EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="tieneNacionalidadEUBeneficiario"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...registerBeneficiary(
                                'tieneNacionalidadEUBeneficiario',
                                {
                                  validate: validateNonSelectedOptionInDropdown,
                                }
                              )}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="esCiudadanoEUBeneficiario"
                            className="label-field"
                          >
                            ¿Es ciudadano de los EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="esCiudadanoEUBeneficiario"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...registerBeneficiary(
                                'esCiudadanoEUBeneficiario',
                                {
                                  validate: validateNonSelectedOptionInDropdown,
                                }
                              )}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="esResidenteEUBeneficiario"
                            className="label-field"
                          >
                            ¿Es residente de los EE.UU.?{' '}
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="esResidenteEUBeneficiario"
                              defaultValue={'No'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...registerBeneficiary(
                                'esResidenteEUBeneficiario',
                                {
                                  validate: validateNonSelectedOptionInDropdown,
                                }
                              )}
                            >
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>

                        {tieneNacionalidadEUBeneficiario === 'Si' ||
                        esCiudadanoEUBeneficiario === 'Si' ||
                        esResidenteEUBeneficiario === 'Si' ? (
                          <>
                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="codigoPostalEUBeneficiario"
                                className="label-field"
                              >
                                Código Postal en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="codigoPostalEUBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...registerBeneficiary(
                                  'codigoPostalEUBeneficiario'
                                )}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="telefonoEUBeneficiario"
                                className="label-field"
                              >
                                Télefono en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="telefonoEUBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...registerBeneficiary(
                                  'telefonoEUBeneficiario'
                                )}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tieneRepresentanteEUBeneficiario"
                                className="label-field"
                              >
                                ¿Tiene un apoderado en los EE.UU.?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tieneRepresentanteEUBeneficiario"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...registerBeneficiary(
                                    'tieneRepresentanteEUBeneficiario',
                                    {
                                      validate:
                                        validateNonSelectedOptionInDropdown,
                                    }
                                  )}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tieneTINBeneficiario"
                                className="label-field"
                              >
                                ¿Tiene identidad para el pago de impuestos
                                (TIN)?{' '}
                              </label>
                              <div className="selectWrapper">
                                <select
                                  id="tieneTINBeneficiario"
                                  defaultValue={'No'}
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...registerBeneficiary(
                                    'tieneTINBeneficiario',
                                    {
                                      validate:
                                        validateNonSelectedOptionInDropdown,
                                    }
                                  )}
                                >
                                  <option value="Si">Si</option>
                                  <option value="No">No</option>
                                </select>
                              </div>
                            </div>

                            {tieneTINBeneficiario === 'Si' ? (
                              <div className="field-form  col-md-4 col-sm-12">
                                <label
                                  htmlFor="tinBeneficiario"
                                  className="label-field"
                                >
                                  Número de Identificación Tributaria de los
                                  EE.UU. (TIN) <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="input-form"
                                  placeholder=""
                                  id="tinBeneficiario"
                                  disabled={isFormCompleted || isFormDisqualified}
                                  {...registerBeneficiary('tinBeneficiario', {
                                    required: true,
                                  })}
                                />
                                {errors.tinBeneficiario?.type ===
                                  'required' && (
                                  <p className="text-danger">
                                    El campo Número de Identificación Tributaria
                                    de los EE.UU. (TIN) es requerido
                                  </p>
                                )}
                                {selectedBeneficiaryId &&
                                  !tinBeneficiario &&
                                  errors.tinBeneficiario?.type !==
                                    'required' && (
                                    <ErrorText
                                      hasError={hasError(
                                        'taxIdentificationNumber'
                                      )}
                                    />
                                  )}
                              </div>
                            ) : null}

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="tarjetaVerdeBeneficiario"
                                className="label-field"
                              >
                                Tarjeta de Identidad de los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="tarjetaVerdeBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...registerBeneficiary(
                                  'tarjetaVerdeBeneficiario'
                                )}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="direccionEUBeneficiario"
                                className="label-field"
                              >
                                Dirección en los EE.UU.{' '}
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="direccionEUBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...registerBeneficiary(
                                  'direccionEUBeneficiario'
                                )}
                              />
                            </div>

                            <div className="field-form  col-md-4 col-sm-12">
                              <label
                                htmlFor="seguroSocialBeneficiario"
                                className="label-field"
                              >
                                Número de Seguro Social EE.UU. <span>*</span>
                              </label>
                              <input
                                type="text"
                                className="input-form"
                                placeholder=""
                                id="seguroSocialBeneficiario"
                                disabled={isFormCompleted || isFormDisqualified}
                                {...registerBeneficiary(
                                  'seguroSocialBeneficiario',
                                  {
                                    required: true,
                                  }
                                )}
                              />
                              {errors.seguroSocialBeneficiario?.type ===
                                'required' && (
                                <p className="text-danger">
                                  El campo Número de Seguro Social EE.UU. es
                                  requerido
                                </p>
                              )}
                              {selectedBeneficiaryId &&
                                !seguroSocialBeneficiario &&
                                errors.seguroSocialBeneficiario?.type !==
                                  'required' && (
                                  <ErrorText
                                    hasError={hasError('socialSecurityNumber')}
                                  />
                                )}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>

                    {!isFormCompleted && !isFormDisqualified ? (
                      <div className="row d-flex justify-content-center">
                        <button type="submit" className="mt-5 mb-5 btn-add">
                          Guardar
                        </button>
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="buttons-control-container">
          <button
            type="button"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="button"
            onClick={handleSubmitShare(handleClick)}
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default MainShareholdersForm;
