import React, { createContext, useState, useContext } from 'react';

const ErrorContext = createContext();
const ChangeErrorContext = createContext();

export function useErrorContext() {
  return useContext(ErrorContext);
}

export function useChangeErrorContext() {
  return useContext(ChangeErrorContext);
}

const ErrorContextProvider = ({ children }) => {
  const [errors, setErrors] = useState([]);

  return (
    <ErrorContext.Provider value={errors}>
      <ChangeErrorContext.Provider value={setErrors}>
        {children}
      </ChangeErrorContext.Provider>
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
