import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ProgressBar.css';
import { useEffect, useState } from 'react';

const ProgressBar = ({
  handleClick,
  handleBackNavigation,
  width,
  percentage,
  disableButton = false,
  forwardButtonText = 'Continuar',
}) => {
  const [marginLeftToPercentajeMeter, setMarginLeftToPercentajeMeter] =
    useState(0);

  useEffect(() => {
    calculateMarginLeftOfPercentajeMeter();
  }, []);

  const calculateMarginLeftOfPercentajeMeter = () => {
    let numberWithoutPercentaje = parseFloat(percentage.split('%')[0]);

    if (percentage.includes('.')) {
      const decimalPart = percentage.split('.')[1];
      const wholePart = percentage.split('.')[0];

      if (wholePart.length === 1 && decimalPart.length - 1 === 2) {
        numberWithoutPercentaje = numberWithoutPercentaje - 2.7;
      } else if (wholePart.length === 1 && decimalPart.length - 1 === 1) {
        numberWithoutPercentaje = numberWithoutPercentaje - 3;
      } else if (wholePart.length === 2 && decimalPart.length - 1 === 2) {
        numberWithoutPercentaje = numberWithoutPercentaje - 3.27;
      } else if (wholePart.length === 2 && decimalPart.length - 1 === 1) {
        numberWithoutPercentaje = numberWithoutPercentaje - 2.7;
      }
    } else {
      if (numberWithoutPercentaje <= 99) {
        numberWithoutPercentaje = numberWithoutPercentaje - 1.95;
      } else {
        numberWithoutPercentaje = numberWithoutPercentaje - 3.25;
      }
    }

    const marginToAllocate = numberWithoutPercentaje.toString() + '%';
    setMarginLeftToPercentajeMeter(marginToAllocate);
  };

  const buttonDisabledStyle = {
    backgroundColor: '#e6e6e9',
  };

  return (
    <>
      <div className="percentage-meter-container">
        <span
          className="percentage-meter "
          style={{ marginLeft: marginLeftToPercentajeMeter }}
        >
          {percentage}
        </span>
      </div>

      <div className="progress-bar-container">
        <div className="progress" style={{ height: '4px' }}>
          <div
            className="progress-bar bg-warning"
            role="progressbar"
            style={{ width: width }}
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="submit"
            className={`btn-continue ${disableButton ? '' : 'active'}`}
            onClick={handleClick}
            disabled={disableButton}
          >
            {forwardButtonText} &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
