import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import SessionContextProvider from './context/SessionContextProvider';
import ErrorContextProvider from './context/ErrorContextProvider';
import { Provider } from 'react-redux';
import { store } from './shared/store/config';

import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { envars, getCookie } from './shared/utils/functions';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const httpLink = createHttpLink({
  uri: '/middleware/crm/',
});

const authLink = setContext((_, { headers }) => {
  const token = getCookie('CRM_AUTH', false);

  return {
    headers: {
      ...headers,
      authorization: token ? token : '',
    },
  };
});
const wsLink = new GraphQLWsLink(
  createClient({
    url: envars('CRM_WS_URL'),
    connectionParams: () => ({
      headers: {
        authorization: getCookie('CRM_AUTH', false),
      },
    }),
  })
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <SessionContextProvider>
          <ErrorContextProvider>
            <App />
          </ErrorContextProvider>
        </SessionContextProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
