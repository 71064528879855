import './SelectSalesRepresentative.css';
import React, { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { useChangeErrorContext } from '../../../../context/ErrorContextProvider';
import { CircularProgress } from '@mui/material';
import Spinner from '../../../../shared/UIElements/Spinner/Spinner';

const SelectSalesRepresentative = () => {
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [agentSelected, setAgentSelected] = useState('');
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const setErrorMessage = useChangeErrorContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompletePageLoading, setIsCompletePageLoading] = useState(false);

  const selectAgent = (index, agentId) => {
    setSelectedCardIndex(index);
    setAgentSelected(agentId);
  };

  const registerUser = () => {};

  const login = () => {};

  const continueNextPage = () => {
    if (agentSelected !== '') {
      //localStorage.setItem("agent", agentSelected);
      registerUser();
    } else {
      // Codigo en caso de que no se halla seleccionado ningun agente de ventas y se le haga click al boton continuar
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="page-container">
      {isCompletePageLoading ? <Spinner /> : null}

      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="sbyc-title">Selecciona tu representante de ventas</h3>
        {isLoading ? (
          <div className="w-100 d-flex justify-content-center p-5">
            <CircularProgress color="warning" />
          </div>
        ) : (
          <Slider {...settings}>
            {agents.map((agent, index) => {
              //const isSelected = selectedCardIndex === index;

              return (
                <div
                  className={`boxButtonPhoto ${agentSelected === agent.agentId ? 'selected' : ''}`}
                  onClick={() => selectAgent(index, agent.agentId)}
                  key={agent.agentId}
                >
                  <img
                    src={
                      agent.imageURL !== null
                        ? `/middleware/crm/${agent.imageURL}`
                        : '/images/profile-picture.png'
                    }
                    alt=""
                    className="sr-card-img"
                  />
                  <p className="sr-card-text">{agent.name}</p>
                </div>
              );
            })}
          </Slider>
        )}

        <button
          type="submit"
          className={`mt-5 btn-continue ${agentSelected != '' ? 'active' : ''}`}
          onClick={continueNextPage}
        >
          Continuar &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </div>
  );
};

export default SelectSalesRepresentative;
