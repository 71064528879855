import { useEffect } from 'react';
import './CardStockBroker.css';
import { useState } from 'react';

const CardStockBroker = ({ imageUrl }) => {
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [businessPhone, setBusinessPhone] = useState('');
  const [phoneExtention, setPhoneExtention] = useState('');

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse) {
      if (selectedFormResponse?.lead?.accountRepresentative?.businessPhones) {
        const businessPhonesArray =
          selectedFormResponse?.lead?.accountRepresentative?.businessPhones?.split(',');
        const separatePhoneAndExtentionArray =
          businessPhonesArray[0]?.split('E');
        setBusinessPhone(separatePhoneAndExtentionArray[0]);
        setPhoneExtention(separatePhoneAndExtentionArray[1]);
      }
    }
  }, [selectedFormResponse]);

  return (
    <div className="card-stock-broker">
      <div className="card-stock-broker-image">
        <img
          src={
            imageUrl
              ? `/middleware/image-crm${imageUrl}`
              : '/images/profile-picture.png'
          }
          alt="stock brokers"
        />
      </div>
      <div className="card-stock-broker-text">
        <strong>Su corredor de valores:</strong>
        <p>
          {selectedFormResponse?.lead?.accountRepresentative?.firstName +
            ' ' +
            selectedFormResponse?.lead?.accountRepresentative?.lastName}
        </p>
        {businessPhone ? (
          <p>
            {businessPhone} <strong>E{phoneExtention}</strong>
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default CardStockBroker;
