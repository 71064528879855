import './ParvalMarketingFormPhysicalPerson.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const ParvalMarketingFormPhysicalPerson = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [
    parvalMarketingSocialMediaOptions,
    setParvalMarketingSocialMediaOptions,
  ] = useState([]);
  const [preferredContacMethods, setPreferredContacMethods] = useState([]);
  const [marketingAboutParvalOptions, setMarketingAboutParvalOptions] =
    useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  const navigate = useNavigate();
  const formRef = useRef();

  const marketingAboutParval = watch('marketingAboutParval');
  const referidoPor = watch('referidoPor');
  const otros = watch('otros');

  useEffect(() => {
    getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveData = () => {
    // navigate('/formularios/persona-fisica/informacion-de-contacto');
  };

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="form-fileds-basic"
      ref={formRef}
    >
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle text-center">Marketing</h3>

          <div className="field-form">
            <label htmlFor="marketing-social-media" className="label-field">
              ¿Por cuál medio nos sigues? <span>*</span>
            </label>
            <select
              id="marketing-social-media"
              defaultValue={'DEFAULT'}
              {...register('marketingSocialMedia', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {parvalMarketingSocialMediaOptions.map((socialMedia) => (
                <option key={socialMedia.id} value={socialMedia.id}>
                  {socialMedia.name}
                </option>
              ))}
            </select>
            {errors.marketingSocialMedia && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          <div className="field-form">
            <label htmlFor="metodo-preferido-contacto" className="label-field">
              Elije tu método preferido de contacto <span>*</span>
            </label>
            <select
              id="metodo-preferido-contacto"
              defaultValue={'DEFAULT'}
              {...register('metodoPreferidoContacto', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {preferredContacMethods.map((preferredContacMethod) => (
                <option
                  key={preferredContacMethod.id}
                  value={preferredContacMethod.id}
                >
                  {preferredContacMethod.name}
                </option>
              ))}
            </select>
            {errors.metodoPreferidoContacto && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          <div className="field-form">
            <label htmlFor="marketing-about-parval" className="label-field">
              ¿Como se enteró de Parval? <span>*</span>
            </label>
            <select
              id="marketing-about-parval"
              defaultValue={'DEFAULT'}
              {...register('marketingAboutParval', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {marketingAboutParvalOptions.map((marketingAboutParvalOption) => (
                <option
                  key={marketingAboutParvalOption.id}
                  value={marketingAboutParvalOption.id}
                >
                  {marketingAboutParvalOption.name}
                </option>
              ))}
            </select>
            {errors.marketingAboutParval && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {/* {
                    marketingAboutParval === "2" ?
                        <div className='field-form'>
                            <label htmlFor="referido-por" className='label-field'>Nombre(s) y Apellido(s) del referidor <span>*</span></label>
                            <input type="text" className='input-form' placeholder="" id="referido-por" {...register('referidoPor', {
                                required: true
                            })} />
                            {errors.referidoPor?.type === 'required' && <p className='text-danger'>El campo Correo electrónico es requerido</p>}
                        </div>
                    : null
                } */}
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default ParvalMarketingFormPhysicalPerson;
