import './AssistanceUnitAccountSummary.css';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Help } from '@mui/icons-material';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import {
  envars,
  getDecodedCookie,
  modifyCookie,
  setCookie,
} from '../../../../shared/utils/functions';
import {
  GET_BRANCHES,
  GET_CLIENTYPES,
  GET_PERSONTYPES,
  GET_SUB_APPLICANT_TYPE,
} from '../../../../shared/graphQL/queries';
import {
  AZURE_SESSION_TIME_OUT,
  CLIENTTYPE,
  azureUserFlowUri,
} from '../../../../shared/constants/gobal-constants';
import { NewPotencialCookieConfig } from '../../../../components/NewClientePotencial/cookieConfig';
import jwt_encode from 'jwt-encode';

export default function AssistanceUnitAccountSummary() {
  const [assistUnit, setAssistUnit] = useState(null);
  const { loading, error, data } = useQuery(GET_CLIENTYPES);
  const { data: subapplicanTypes } = useQuery(GET_SUB_APPLICANT_TYPE);
  const { data: branches } = useQuery(GET_BRANCHES);
  const { data: personTypes } = useQuery(GET_PERSONTYPES);
  const [animationOut, setAnimationOut] = useState(false);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const email = sessionStorage.getItem('email');

    if (email) {
      // Creo la cookie donde almacenare la info
      const config = new NewPotencialCookieConfig(email, false);
      const configCoded = jwt_encode(config, envars('SECRETINNERJWT'));
      const timeout = AZURE_SESSION_TIME_OUT;
      setCookie('potencialCustomerConfigRC', configCoded, {
        'max-age': timeout,
        path: '/',
      });
      // setCookie('potencialCustomerConfigRC', configCoded, { 'max-age': 45 * 60 * 1000, path: '/' });
    }

    const configDecoded = getDecodedCookie('potencialCustomerConfigRC');

    if (configDecoded && configDecoded.assistUnit) {
      setReload(true);
      configDecoded.assistUnit.knowid
        ? setAssistUnit({ knowid: '0' })
        : setAssistUnit(configDecoded.assistUnit);
    }

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener las unidades de asistencia', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (assistUnit && !reload) {
      goTo();
    }
  }, [assistUnit, reload]);

  const goTo = async () => {
    let unit =
      assistUnit.knowid === '0'
        ? data?.applicantTypes.find((p) =>
            p.name.toLowerCase().includes('negocios')
          )
        : assistUnit;
    assistUnit.knowid === '0' && (unit = { ...unit, knowid: '0' });
    const timeout = AZURE_SESSION_TIME_OUT;
    const personType = personTypes?.personTypeList?.find(
      (person) => person.name === 'Persona Jurídica'
    ); // REMOVER DESPUES
    modifyCookie(
      'potencialCustomerConfigRC',
      'accountType',
      personType,
      true,
      timeout
    );
    modifyCookie(
      'potencialCustomerConfigRC',
      'assistUnit',
      unit,
      true,
      timeout
    );
    setAnimationOut(true);
    if (unit.name.toLowerCase().includes('tesorería')) {
      const alamedaBranch = branches.branches.find((branch) =>
        branch.name.toLowerCase().includes('alameda')
      );
      modifyCookie(
        'potencialCustomerConfigRC',
        'branch',
        alamedaBranch,
        true,
        timeout
      );
      setTimeout(() => {
        navigate(
          `/onboarding-digital/dashboard/apertura-de-cuenta/tipo-solicitante`
        );
      }, 500);
    } else {
      const clienteParval = subapplicanTypes.subApplicantTypes.find(
        (subApplicantType) => subApplicantType.name === 'Cliente PARVAL'
      );
      modifyCookie(
        'potencialCustomerConfigRC',
        'subtype',
        clienteParval,
        true,
        timeout
      );
      setTimeout(() => {
        navigate(`/onboarding-digital/dashboard/apertura-de-cuenta/sucursal`);
      }, 500);
    }
  };

  const goBack = () => {
    setAnimationOut(true);
    setTimeout(() => {
      navigate(
        '/onboarding-digital/dashboard/apertura-de-cuenta/tipo-de-persona'
      );
    }, 500);
  };

  const getConfigUnit = (p) => {
    return CLIENTTYPE[
      p.name.split(' ')[p.name.split(' ').length - 1].toLowerCase()
    ];
  };

  return (
    <div className="accountSumary">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="">
            <div className="vAlign" style={{ padding: '2rem' }}>
              <h3 className="formTitle">
                ¿Sabes si tu caso es atendido por alguna de estas unidades?
              </h3>
              <div className="cliente-potencial-register-form">
                <div className="mt-2 newForm">
                  {!loading && !error && data && (
                    <div className="assist-unit cards-container-summary-account">
                      {data?.applicantTypes.map((p) => (
                        <div
                          key={p.id}
                          className={`boxButton card-element`}
                          onClick={() => {
                            setReload(false);
                            setAssistUnit(p);
                          }}
                        >
                          <div>
                            <h5 className="rwf-card-title">
                              {getConfigUnit(p).name}
                            </h5>
                          </div>
                        </div>
                      ))}

                      <div
                        className={`boxButton card-element`}
                        onClick={() => setAssistUnit({ knowid: '0' })}
                      >
                        <div>
                          <h5 className="rwf-card-title">Aún no lo sé</h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                </div>
              </div>
              {/* <p className='linkBack bottom' onClick={goBack}><FontAwesomeIcon icon={faAngleLeft} /> Volver</p> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
