import './SourceOfFundsForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const SourceOfFundsForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [fatcaProxyOptions, setFatcaProxyOptions] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);

  const ahorroSalario = watch('ahorroSalario');
  const inversionesAnteriores = watch('inversionesAnteriores');
  const negocioPropio = watch('negocioPropio');
  const herencia = watch('herencia');
  const salariosOingresosActuales = watch('salariosOingresosActuales');
  const fondoPension = watch('fondoPension');
  const ventasActivos = watch('ventasActivos');

  useEffect(() => {
    if (
      ahorroSalario ||
      inversionesAnteriores ||
      negocioPropio ||
      herencia ||
      salariosOingresosActuales ||
      fondoPension ||
      ventasActivos
    ) {
      setDisabledButton(false);
    } else {
      setDisabledButton(true);
    }
  }, [
    ahorroSalario,
    inversionesAnteriores,
    negocioPropio,
    herencia,
    salariosOingresosActuales,
    fondoPension,
    ventasActivos,
  ]);

  useEffect(() => {
    //getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveDataInBD = () => {
    navigate('/formularios/persona-fisica/resultado');
  };

  const addRemoveTitleOperations = () => {};

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div
        className="w-100 d-flex justify-content-start px-5 mb-5"
        style={{ height: 40 }}
      >
        <h3 className="formTitle" style={{ color: '#3d675e' }}>
          Origen de fondos
        </h3>
      </div>

      <div className="row fields-container">
        <div className="col-md-4 col-sm-12">
          <div className="sof-options-container">
            <label htmlFor="ahorro-salario" className="label-field me-1">
              <input
                type="checkbox"
                id="ahorro-salario"
                {...register('ahorroSalario')}
              />{' '}
              Ahorro de Salario
            </label>

            <label
              htmlFor="inversiones-anteriores"
              className="label-field me-1"
            >
              <input
                type="checkbox"
                id="inversiones-anteriores"
                {...register('inversionesAnteriores')}
              />{' '}
              Inversiones Anteriores
            </label>

            <label htmlFor="negocio-propio" className="label-field me-1">
              <input
                type="checkbox"
                id="negocio-propio"
                {...register('negocioPropio')}
              />{' '}
              Negocio Propio
            </label>

            <label htmlFor="herencia" className="label-field me-1">
              <input type="checkbox" id="herencia" {...register('herencia')} />{' '}
              Herencia
            </label>
          </div>
        </div>

        <div className="col-md-4 col-sm-12">
          <div className="sof-options-container">
            <label
              htmlFor="salarios-o-ingresos-actuales"
              className="label-field me-1"
            >
              <input
                type="checkbox"
                id="salarios-o-ingresos-actuales"
                {...register('salariosOingresosActuales')}
              />{' '}
              Salarios o ingresos actuales
            </label>

            <label htmlFor="fondo-pension" className="label-field me-1">
              <input
                type="checkbox"
                id="fondo-pension"
                {...register('fondoPension')}
              />{' '}
              Retiro/Fondo de Pensión
            </label>

            <label htmlFor="ventas-activos" className="label-field me-1">
              <input
                type="checkbox"
                id="ventas-activos"
                {...register('ventasActivos')}
              />{' '}
              Ventas de activos
            </label>
          </div>
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="submit"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default SourceOfFundsForm;
