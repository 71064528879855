export class NewPotencialCookieConfig {
  constructor(
    email,
    isVerified = false,
    firstName = '',
    lastName = '',
    primaryContact = false,
    accountType = null,
    assistUnit = '',
    branch = null,
    subtype = null,
    companyType = null,
    businessRepresentative = '',
    identityVerification = null
  ) {
    this.email = email;
    this.isVerified = isVerified;
    this.firstName = firstName;
    this.lastName = lastName;
    this.accountType = accountType;
    this.assistUnit = assistUnit;
    this.subtype = subtype;
    this.branch = branch;
    this.companyType = companyType;
    this.businessRepresentative = businessRepresentative;
    this.identityVerification = identityVerification;
    this.primaryContact = primaryContact;
  }
}
