import './PasswordChangedNotificationPage.css';

const PasswordChangedNotificationPage = () => {
  return (
    <div className="msnp-container">
      <h3 className="msnp-text">
        Su contraseña ha sido cambiada exitosamente.
      </h3>
      <img src="/images/check-mark.png" alt="" className="msnp-check-image" />
    </div>
  );
};

export default PasswordChangedNotificationPage;
