import { gql } from '@apollo/client';

export const GET_PERSONTYPES = gql`
  query PersonTypeList {
    personTypeList {
      id
      name
    }
  }
`;

export const GET_CLIENTYPES = gql`
  query ApplicantTypes {
    applicantTypes {
      id
      name
    }
  }
`;

export const GET_BRANCHES = gql`
  query Branches {
    branches {
      id
      address
      name
    }
  }
`;

export const GET_BUSINESS_REPRESENTATIVES = gql`
  query AccountRepresentativesByBranch($branchId: String!) {
    accountRepresentativesByBranch(branchId: $branchId) {
      id
      firstName
      lastName
      imageURL
      branches {
        id
        name
      }
      email
      outlookId
      businessPhones
    }
  }
`;

export const GET_BUSINESS_REPRESENTATIVES_BY_FILTER = gql`
  query AccountRepresentatives($filters: FilterAccountRepresentativeInputType) {
    accountRepresentatives(filters: $filters) {
      id
      creationDate
      modificationDate
      firstName
      lastName
      SIVLicense
      email
      outlookId
      imageURL
      branches {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        shortName
        address
      }
      accountEnabled
      businessPhones
      personTypes {
        id
        creationDate
        modificationDate
        name
        parameterCode
        coreParameterCode
      }
    }
  }
`;

export const GET_IDENTITYTYPES = gql`
  query IdentificationTypeList {
    identificationTypeList {
      id
      name
    }
  }
`;

export const GET_COMPANY_ORIGINS = gql`
  query CompanyOrigins {
    companyOrigins {
      id
      name
    }
  }
`;

export const GET_COMPANY_RNC_INFO = gql`
  query CompanyRncInfoByRnc($companyRnc: String!) {
    companyRncInfoByRnc(companyRnc: $companyRnc) {
      id
      rnc
      status
      businessName
    }
  }
`;

export const GET_COUNTRIES = gql`
  query Countries {
    countries {
      id
      name
    }
  }
`;

export const GET_SOCIETY_TYPES = gql`
  query SocietyTypeList {
    societyTypeList {
      id
      name
    }
  }
`;

export const GET_CIVIL_STATUS = gql`
  query CivilStatusList {
    civilStatusList {
      id
      name
    }
  }
`;

export const GET_EQUITY_TYPES = gql`
  query EquityTypes {
    equityTypes {
      id
      name
    }
  }
`;

export const GET_GENERAL_INFORMATION = gql`
  query CompanyInformationSection($formResponseId: String!) {
    companyInformationSection(formResponseId: $formResponseId) {
      id
      commercialName
      registeredNumber
      constitutionDate
      registeredNumberExpirationDate
      societyType {
        id
        name
      }
      country {
        id
        name
      }
      equityType {
        id
        name
      }
      companyOrigin {
        id
        name
      }
      primaryPhone
      secondaryPhone
      websiteUrl
      bearerShares
      hasBCRDAccount
      custodian
      clearingNumber
      accountHeld
      isListedOnStock
    }
  }
`;

export const GET_ECONOMIC_ACTIVITIES = gql`
  query EconomicActivities {
    economicActivities {
      id
      name
      economicSector {
        id
        name
      }
    }
  }
`;

export const GET_ECONOMIC_SECTORS = gql`
  query EconomicSectors {
    economicSectors {
      id
      name
    }
  }
`;

export const GET_COMPANY_TYPES = gql`
  query CompanyTypes {
    companyTypes {
      id
      name
      impact
      probability
      riskLevel {
        id
        name
      }
      hasInvestmentProfile
    }
  }
`;

export const GET_OBLIGATED_SUBJECTS_TYPES = gql`
  query ObligatedSubjectTypes($applicantTypeId: String!) {
    obligatedSubjectTypes(applicantTypeId: $applicantTypeId) {
      id
      name
    }
  }
`;

export const GET_IDENTIFICATION = gql`
  query LeadsByPrimaryContact($primaryContactEmail: String!) {
    leadsByPrimaryContact(primaryContactEmail: $primaryContactEmail) {
      identification
    }
  }
`;

export const GET_ANNUAL_SALES_LIST = gql`
  query AnnualSalesList {
    annualSalesList {
      id
      name
    }
  }
`;

export const GET_CURRENCIES = gql`
  query Currencies {
    currencies {
      id
      name
    }
  }
`;

export const GET_BANKS = gql`
  query Banks {
    banks {
      id
      businessName
      name
      isInternational
    }
  }
`;

export const GET_BANK_ACCOUNTS_TYPES = gql`
  query BankAccountTypes {
    bankAccountTypes {
      id
      name
    }
  }
`;

export const GET_BANK_ACCOUNTS_ORIGIN_TYPES = gql`
  query BankAccountOriginTypes {
    bankAccountOriginTypes {
      id
      name
    }
  }
`;

export const GET_BANK_ACCOUNTS_PERSON_TYPES = gql`
  query BankAccountPersonTypes {
    bankAccountPersonTypes {
      id
      name
      parameterCode
    }
  }
`;

export const GET_INVESTMENT_EXPERIENCE_LIST = gql`
  query InvestmentExperienceList {
    investmentExperienceList {
      id
      name
    }
  }
`;

export const GET_ACCOUNTS_PAYABLE_LIST = gql`
query AccountsPayableList($personTypeId: String) {
  accountsPayableList(personTypeId: $personTypeId) {
    id
    name
  }
}
`;

export const GET_ANNUAL_EXPENSES_LIST = gql`
  query AnnualExpensesList {
    annualExpensesList {
      id
      name
    }
  }
`;

export const GET_ANNUAL_REVENUE_LIST = gql`
  query AnnualRevenueList {
    annualRevenueList {
      id
      name
    }
  }
`;

export const GET_EMERGENCY_FUNDS_LIST = gql`
  query EmergencyFundsList {
    emergencyFundsList {
      id
      name
    }
  }
`;

export const GET_TOTAL_PATRIMONY_LIST = gql`
  query TotalPatrimonyList($personTypeId: String) {
    totalPatrimonyList(personTypeId: $personTypeId) {
      id
      name
    }
  }
`;

export const GET_OPERATION_TIMES_LIST = gql`
  query OperationTimes {
    operationTimes {
      id
      name
    }
  }
`;

export const GET_lIQUID_ASSETS_LIST = gql`
  query LiquidAssetsList($personTypeId: String) {
    liquidAssetsList(personTypeId: $personTypeId) {
      id
      name
    }
  }
`;

export const GET_RISK_TOLERANCE_LIST = gql`
  query RiskToleranceList {
    riskToleranceList {
      id
      name
    }
  }
`;

export const GET_DESIRED_PROFITABILITY_LIST = gql`
  query DesiredProfitabilityList {
    desiredProfitabilityList {
      id
      name
    }
  }
`;

export const GET_SOCIAL_NETWORKS_LIST = gql`
  query SocialNetworks {
    socialNetworks {
      id
      name
    }
  }
`;

export const GET_CAPITAL_LOST_MARGIN_LIST = gql`
  query CapitalLossMarginList {
    capitalLossMarginList {
      id
      name
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENTS_KNOWLEDGELEVELS_LIST = gql`
  query FinancialInstrumentKnowledgeLevels {
    financialInstrumentKnowledgeLevels {
      id
      name
    }
  }
`;

export const GET_MARKET_INFORMATION_SOURCES_LIST = gql`
  query MarketInformationSources {
    marketInformationSources {
      id
      name
    }
  }
`;

export const GET_REINVESTMENT_BEHAVIOR_LIST = gql`
  query ReinvestmentBehaviorList {
    reinvestmentBehaviorList {
      id
      name
    }
  }
`;

export const GET_INVESTMENT_TIME_LIST = gql`
  query InvestmentTimes {
    investmentTimes {
      id
      name
    }
  }
`;

export const GET_FINANCIAL_OPERATIONS_LIST = gql`
  query FinancialOperations {
    financialOperations {
      id
      name
    }
  }
`;

export const GET_LIQUID_ASSETS_TYPES_LIST = gql`
  query LiquidAssetsTypes {
    liquidAssetsTypes {
      id
      name
    }
  }
`;

export const GET_CORRESPONDENCE_CONFIRMATIONS_OPTIONS = gql`
  query CorrespondenceConfirmationOptions {
    correspondenceConfirmationOptions {
      id
      name
    }
  }
`;

export const GET_CORRESPONDENCE_DELIVERY_OPTIONS = gql`
  query CorrespondenceDeliveryOptions($personTypeId: String!) {
    correspondenceDeliveryOptions(personTypeId: $personTypeId) {
      id
      name
    }
  }
`;

export const GET_REFERRAL_LIST = gql`
  query ReferralList {
    referralList {
      id
      name
    }
  }
`;

export const GET_LEAD_MANAGEMENT_TYPE_LIST = gql`
  query LeadManagementTypes {
    leadManagementTypes {
      id
      name
    }
  }
`;

export const GET_LEADS = gql`
  query LeadsByPrimaryContact($primaryContactEmail: String!) {
    leadsByPrimaryContact(primaryContactEmail: $primaryContactEmail) {
      identification
      companyLeadDetails {
        id
        applicantType {
          id
          name
        }
        subApplicantType {
          id
          name
        }
      }
      identificationType {
        id
        name
      }
      primaryContact {
        email
        firstName
        lastName
        primaryContactDetails {
          id
          phoneNumber
          secondaryPhoneNumber
          primaryContactEmail
        }
      }
      leadManagementType {
        id
        name
      }
      personType {
        id
        name
      }
      accountRepresentative {
        id
        firstName
        lastName
        branches {
          id
          name
          address
        }
      }
    }
  }
`;

export const GET_LEAD_BY_IDENTIFICATION = gql`
  query Lead($identification: String!) {
    lead(identification: $identification) {
      identification
      identificationType {
        id
        name
      }
    }
  }
`;

export const GET_FORM_RESPONSE_ID = gql`
  query FormResponse($leadIdentification: String!, $formId: String!) {
    formResponse(leadIdentification: $leadIdentification, formId: $formId) {
      id
      formattedFormResponseId
    }
  }
`;

export const GET_SIGNATURE_TYPES = gql`
  query SignatureTypes {
    signatureTypes {
      id
      name
    }
  }
`;

export const GET_COMPANY_OFFICES_SECTION_DATA = gql`
  query CompanyOfficesSection($formResponseId: String!) {
    companyOfficesSection(formResponseId: $formResponseId) {
      id
      companyOffices {
        id
        isPrincipal
        state
        city
        street
        buildingNumber
        buildingName
        apartment
        sector
        postalCode
        phoneNumber
        phoneNumberAlternative
        fax
        webPageUrl
        country {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_SHAREHOLDER_SECTION = gql`
  query CompanyShareholdersSection($formResponseId: String) {
    companyShareholdersSection(formResponseId: $formResponseId) {
      id
      shareholders {
        country {
          id
          name
        }
        id
        identification
        identificationType {
          id
          name
        }
        personType {
          id
          name
        }
        isInDarkList
        ownershipPercentage
        sharesNumber
        shareholderCompanyDetails {
          businessName
          controllingShareholders
          economicActivity {
            id
            name
          }
          hasBearerShares
          id
          parvalRelationship {
            id
            name
          }
          societyType {
            id
            name
          }
        }
        shareholderPersonDetails {
          charge
          firstName
          id
          lastName
          politicExposure {
            id
            isPep
            politicExposureDetails {
              id
              charge
              relativeName
              relativeRelationship
              institution
              active
              chargeStartDate
              chargeEndDate
              politicExposureType {
                id
                name
              }
            }
          }
        }
        beneficiaries {
          address
          email
          firstName
          id
          identificationNumber
          identificationType {
            id
            name
          }
          contactFatca {
            id
            haveUnitedStatesNationality
            isUnitedStatesCitizen
            isUnitedStatesResident
            socialSecurityNumber
            zipInUnitedStates
            phoneInUnitedStates
            haveRepresentativeInUnitedStates
            haveTaxIdentificationNumber
            taxIdentificationNumber
            greenCard
            addressInUnitedStates
          }
          lastName
          occupation
          phoneNumber
        }
        contactFatca {
          id
          haveUnitedStatesNationality
          isUnitedStatesCitizen
          isUnitedStatesResident
          socialSecurityNumber
          zipInUnitedStates
          phoneInUnitedStates
          haveRepresentativeInUnitedStates
          haveTaxIdentificationNumber
          taxIdentificationNumber
          greenCard
          addressInUnitedStates
        }
      }
      totalDistributedShares
      totalSharesIssued
    }
  }
`;

export const GET_FORM_RESPONSE_BY_IDENTIFICATION = gql`
  query FormResponses($pagination: FormResponsePagination) {
    formResponses(pagination: $pagination) {
      id
      creationDate
      modificationDate
      form {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        order
        personType {
          id
          creationDate
          modificationDate
          name
          parameterCode
          coreParameterCode
        }
      }
      client {
        id
        creationDate
        modificationDate
        codeLA
        codeRIF
        rnt
        cevaldomAccountNumber
      }
      riskLevel {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        altCoreParameterCode
      }
      previousPhase {
        id
        creationDate
        modificationDate
        name
      }
      phase {
        id
        creationDate
        modificationDate
        name
      }
      phaseChangeDate
      formattedFormResponseId
      formResponseStatus {
        id
        name
      }
      lead {
        identification
        identificationType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        accountRepresentative {
          id
          creationDate
          modificationDate
          firstName
          lastName
          SIVLicense
          email
          outlookId
          imageURL
          branches {
            id
            creationDate
            modificationDate
            name
            coreParameterCode
            shortName
            address
          }
          accountEnabled
          businessPhones
          personTypes {
            id
            creationDate
            modificationDate
            name
            parameterCode
            coreParameterCode
          }
        }
        primaryContact {
          email
          firstName
          lastName
          primaryContactDetails {
            id
            creationDate
            modificationDate
            phoneNumber
            secondaryPhoneNumber
            primaryContactEmail
          }
          creationDate
          modificationDate
        }
        companyLeadDetails {
          id
          creationDate
          modificationDate
          applicantType {
            id
            creationDate
            modificationDate
            name
          }
          subApplicantType {
            id
            creationDate
            modificationDate
            name
          }
        }
        creationDate
        modificationDate
        isRelatedToParval
        parvalRelationship {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
        linkageType {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
        leadManagementType {
          id
          creationDate
          modificationDate
          name
        }
        branch {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
          shortName
          address
          publicNotary {
            id
            creationDate
            modificationDate
            firstName
            lastName
            email
            collegeNumber
            zone
          }
        }
      }
    }
  }
`;

export const GET_LEGAL_REPRESENTATIVES_SECTION_DATA = gql`
  query LegalRepresentativesSection($formResponseId: String!) {
    legalRepresentativesSection(formResponseId: $formResponseId) {
      id
      legalRepresentatives {
        correspondenceEmail {
          id
          email
          correspondenceEmailType {
            id 
            name
          }
        }
        firstName
        id
        identification
        lastName
        position
        isInDarkList
        verificationStatus
        verificationId
        gender {
          id
          name
        }
        birthDate
        lexisNexisResponse {
          id
          response
          quantity
          isInDarkList
        }
        civilStatus {
          id
          name
        }
        identificationType {
          id
          name
        }
        nationalityCountry {
          id
          name
        }
        address {
          id
          addressType {
            id
            name
          }
          country {
            id
            name
          }
          province {
            id
            name
          }
          sector {
            id
            name
          }
          zipCode
          street
          building
          houseNumber
          apartmentNumber
        }
        politicExposure {
          id
          isPep
          politicExposureDetails {
            id
            charge
            relativeName
            relativeRelationship
            institution
            active
            chargeStartDate
            chargeEndDate
            politicExposureType {
              id
              name
            }
          }
        }
        contactFatca {
          id
          haveUnitedStatesNationality
          isUnitedStatesCitizen
          isUnitedStatesResident
          socialSecurityNumber
          zipInUnitedStates
          phoneInUnitedStates
          haveRepresentativeInUnitedStates
          haveTaxIdentificationNumber
          taxIdentificationNumber
          greenCard
          addressInUnitedStates
        }
        legalRepresentativeType {
          id
          name
        }
      }
    }
  }
`;

export const GET_RELATED_COMPANIES_SECTION_DATA = gql`
  query RelatedCompaniesSection($formResponseId: String!) {
    relatedCompaniesSection(formResponseId: $formResponseId) {
      id
      relatedCompanies {
        id
        businessName
        identification
        ownerFirstName
        ownerEmail
        ownerLastName
        ownerPhoneNumber
        identificationType {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_ECONOMIC_DETAILS_SECTION = gql`
  query CompanyEconomicDetailsSection($formResponseId: String!) {
    companyEconomicDetailsSection(formResponseId: $formResponseId) {
      id
      clientsAndCountries
      productsOrServices
      providers
      employeesNumber
      economicActivity {
        id
        name
        economicSector {
          id
          name
        }
      }
      economicSector {
        id
        name
      }
      annualSales {
        id
        name
      }
      companyType {
        id
        impact
        name
        probability
        riskLevel {
          id
          name
        }
        hasInvestmentProfile
      }
    }
  }
`;

export const GET_FATCA_SECTION = gql`
  query FatcaSection($formResponseId: String!) {
    fatcaSection(formResponseId: $formResponseId) {
      id
      globalIntermediaryIdentificationNumber
      haveGlobalIntermediaryIdentificationNumber
      haveTaxIdentificationNumber
      taxIdentificationNumber
    }
  }
`;

export const GET_PARVAL_RELATIONSHIPS = gql`
  query ParvalRelationships {
    parvalRelationships {
      id
      name
    }
  }
`;

export const GET_LINKAGE_TYPES = gql`
  query LinkageTypes {
    linkageTypes {
      id
      name
    }
  }
`;

export const GET_FORM_TYPES = gql`
  query Forms {
    forms {
      id
      name
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENTS_SECTION_DATA = gql`
  query FinancialInstrumentsSection($formResponseId: String!) {
    financialInstrumentsSection(formResponseId: $formResponseId) {
      id
      financialInstruments {
        id
        financialInstrumentAnnualOperations {
          id
          name
        }
        financialInstrumentExperience {
          id
          name
        }
        financialInstrumentKnowledgeLevel {
          id
          name
        }
        financialInstrumentType {
          id
          name
        }
      }
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENTS_TYPES = gql`
  query FinancialInstrumentTypes {
    financialInstrumentTypes {
      id
      name
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENT_EXPIRIENCE_LIST = gql`
  query FinancialInstrumentExperienceList {
    financialInstrumentExperienceList {
      id
      name
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENT_ANNUAL_OPERATIONS_LIST = gql`
  query FinancialInstrumentAnnualOperationsList {
    financialInstrumentAnnualOperationsList {
      id
      name
    }
  }
`;

export const GET_FINANCIAL_INSTRUMENT_KNOWLEDGELEVELS = gql`
  query FinancialInstrumentKnowledgeLevels {
    financialInstrumentKnowledgeLevels {
      id
      name
    }
  }
`;

export const GET_POLITIC_EXPOSURE_TYPES = gql`
  query PoliticExposureTypes {
    politicExposureTypes {
      id
      name
    }
  }
`;

export const GET_BENEFICIARIES_SECTION = gql`
  query BeneficiariesSection($formResponseId: String!) {
    beneficiariesSection(formResponseId: $formResponseId) {
      id
      beneficiaries {
        id
        firstName
        lastName
        identificationType {
          id
          name
        }
        identificationNumber
        occupation
        phoneNumber
        address
        email
        contactFatca {
          id
          haveUnitedStatesNationality
          isUnitedStatesCitizen
          isUnitedStatesResident
          socialSecurityNumber
          zipInUnitedStates
          phoneInUnitedStates
          haveRepresentativeInUnitedStates
          haveTaxIdentificationNumber
          taxIdentificationNumber
          greenCard
          addressInUnitedStates
        }
        politicExposure {
          id
          isPep
          politicExposureDetails {
            id
            charge
            relativeName
            relativeRelationship
            institution
            active
            chargeStartDate
            chargeEndDate
            politicExposureType {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const GET_BANK_ACCOUNTS_SECTION = gql`
  query BankAccountSection($formResponseId: String!) {
    bankAccountSection(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      bankAccounts {
        id
        creationDate
        modificationDate
        accountHolder
        accountNumber
        hasIntermediaryBank
        intermediaryBankAddress
        abaOrSwift
        intermediaryBank {
          id
          creationDate
          modificationDate
          name
          parameterCode
          businessName
          logoUrl
          isInternational
        }
        bank {
          id
          creationDate
          modificationDate
          name
          parameterCode
          businessName
          logoUrl
          isInternational
        }
        bankAccountType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        currency {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        bankAccountOriginType {
          id
          creationDate
          modificationDate
          name
        }
        bankAccountPersonType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
      }
    }
  }
`;

export const GET_DUE_DILIGENCE_SECTION = gql`
  query DueDiligenceSection($formResponseId: String!) {
    dueDiligenceSection(formResponseId: $formResponseId) {
      id
      isObligatedSubject
      dueDiligenceSectionDetails {
        dueDiligenceAnswers {
          id
          dueDiligenceQuestion {
            id
            order
            question
          }
          answer
        }
        dueDiligenceForm {
          applicantType {
            id
            name
          }
          id
        }
        id
        noMoneyLaunderingOfficialName
        noMoneyLaunderingOfficialPosition
        noMoneyLaunderingOfficialReason
        obligatedSubjectType {
          id
          name
        }
      }
    }
  }
`;

export const GET_INVESTMENT_OBJECTIVES = gql`
  query InvestmentObjectives {
    investmentObjectives {
      id
      name
    }
  }
`;

export const GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA = gql`
  query InvestmentProfileDescriptionSection($formResponseId: String!) {
    investmentProfileDescriptionSection(formResponseId: $formResponseId) {
      capitalLossMargin {
        id
        name
      }
      desiredProfitability {
        id
        name
      }
      financialOperations {
        id
        name
      }
      id
      investmentObjective {
        id
        name
      }
      investmentTime {
        id
        name
      }
      marketInformationSource {
        id
        name
      }
      reinvestmentBehavior {
        id
        name
      }
      riskTolerance {
        id
        name
      }
      desiredProfitability {
        id
        name
      }
      initialInvestmentAmount {
        id
        name
      }
      annualInvestmentAmount {
        id
        name
      }
    }
  }
`;

export const GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA = gql`
  query InvestmentFinancialDetailsSection($formResponseId: String!) {
    investmentFinancialDetailsSection(formResponseId: $formResponseId) {
      accountsPayable {
        id
        name
      }
      annualExpenses {
        id
        name
      }
      annualRevenue {
        id
        name
      }
      emergencyFunds {
        id
        name
      }
      id
      totalPatrimony {
        id
        name
      }
      liquidAssets {
        id
        name
      }
      liquidAssetsTypeDistributions {
        id
        liquidAssetsType {
          id
          name
        }
        percentage
      }
    }
  }
`;

export const GET_INVESTMENT_EXPERIENCE_SECTION_DATA = gql`
  query InvestmentExperienceSection($formResponseId: String!) {
    investmentExperienceSection(formResponseId: $formResponseId) {
      id
      investmentExperience {
        id
        name
      }
      operationTime {
        id
        name
      }
    }
  }
`;

export const GET_INVESTMENT_EXPERIENCE_CHECKLIST = gql`
  query InvestmentExperienceCheckList {
    investmentExperienceCheckList {
      id
      name
    }
  }
`;

export const GET_PATRIMONIAL_CAPACITY_CHECKLIST = gql`
  query PatrimonialCapacityCheckList {
    patrimonialCapacityCheckList {
      id
      name
    }
  }
`;

export const GET_INVESTMENT_KNOWLEDGE_CHECKLIST = gql`
  query InvestmentKnowledgeCheckList {
    investmentKnowledgeCheckList {
      id
      name
    }
  }
`;

export const GET_LEGAL_REPRESENTATIVES_TYPES = gql`
  query LegalRepresentativeTypes {
    legalRepresentativeTypes {
      id
      name
    }
  }
`;

export const GET_ADDRESS_TYPES = gql`
  query AddressTypes($personTypeId: String!) {
    addressTypes(personTypeId: $personTypeId) {
      id
      name
    }
  }
`;

export const GET_PROVINCES_BY_COUNTRY = gql`
  query ProvincesByCountry($countryId: String!) {
    provincesByCountry(countryId: $countryId) {
      id
      name
    }
  }
`;

export const GET_SECTORS_BY_PROVINCE = gql`
  query SectorsByProvince($provinceId: String!) {
    sectorsByProvince(provinceId: $provinceId) {
      id
      name
    }
  }
`;

export const GET_INVESTOR_CLASIFICATION_SECTION = gql`
  query InvestorClassificationSection($formResponseId: String!) {
    investorClassificationSection(formResponseId: $formResponseId) {
      id
      investmentExperienceChecks {
        id
        name
      }
      investmentKnowledgeChecks {
        id
        name
      }
      patrimonialCapacityChecks {
        id
        name
      }
    }
  }
`;

export const GET_INVESTMENT_PROFILE_SECTION = gql`
  query InvestmentProfileSection($formResponseId: String!) {
    investmentProfileSection(formResponseId: $formResponseId) {
      id
      investmentProfile {
        id
        name
        description
      }
      investmentProfileType {
        id
        name
        description
      }
      profileScore
      profileTypeScore
      profileCompletionPercentage
    }
  }
`;

export const GET_COMMUNICATIONS_SECTION = gql`
  query CommunicationsSection($formResponseId: String!) {
    communicationsSection(formResponseId: $formResponseId) {
      correspondenceConfirmations {
        id
        name
      }
      correspondenceDelivery {
        id
        name
      }
      id
    }
  }
`;

export const GET_MARKETING_SECTION = gql`
  query MarketingSection($formResponseId: String!) {
    marketingSection(formResponseId: $formResponseId) {
      id
      marketingChannels {
        id
        name
      }
      referral {
        id
        name
      }
      referralInfo
      socialNetworks {
        id
        name
      }
    }
  }
`;

export const GET_MARKETING_CHANNELS = gql`
  query MarketingChannels {
    marketingChannels {
      id
      name
    }
  }
`;

export const GET_LEAD_By_PRIMARYCONTACT_AND_PERSONTYPE = gql`
  query LeadByPrimaryContactEmailAndPersonType(
    $personTypeId: String!
    $primaryContactEmail: String!
  ) {
    leadByPrimaryContactEmailAndPersonType(
      personTypeId: $personTypeId
      primaryContactEmail: $primaryContactEmail
    ) {
      leadManagementType {
        id
        name
      }
      identification
      identificationType {
        id
        name
      }
      personType {
        id
        name
      }
      primaryContact {
        email
        firstName
        lastName
      }
      accountRepresentative {
        id
        firstName
        lastName
      }
      companyLeadDetails {
        parvalRelationship {
          id
          name
        }
        linkageType {
          id
          name
        }
      }
    }
  }
`;

export const GET_PRIMARY_CONTACT = gql`
  query PrimaryContact($primaryContactEmail: String!) {
    primaryContact(primaryContactEmail: $primaryContactEmail) {
      email
      firstName
      lastName
      primaryContactDetails {
        id
        phoneNumber
        secondaryPhoneNumber
        primaryContactEmail
      }
    }
  }
`;

export const GET_SIGNATURES_SECTION = gql`
  query SignaturesSection($formResponseId: String!) {
    signaturesSection(formResponseId: $formResponseId) {
      id
      signatureType {
        id
        name
      }
      signaturesCard {
        id
        signatories {
          charge
          email
          firstName
          id
          identification
          isRepresentative
          lastName
          orderNumber
          signatureType {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ADDRESSES_SECTION = gql`
  query AddressesSection($formResponseId: String!) {
    addressesSection(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      addresses {
        id
        creationDate
        modificationDate
        addressType {
          id
          creationDate
          modificationDate
          name
          personType {
            id
            creationDate
            modificationDate
            name
            parameterCode
          }
        }
        country {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        province {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        sector {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        street
        zipCode
        building
        houseNumber
        apartmentNumber
      }
    }
  }
`;

export const GET_SIGNATURE_CARDS_PDF_BY_FORM_RESPONSES = gql`
  query SignaturesCardPDFDataByFormResponse($formResponseId: String!) {
    signaturesCardPDFDataByFormResponse(formResponseId: $formResponseId) {
      clientCode
      commercialName
      identification
      identificationType {
        id
        name
      }
      isIncludingNewSignature
      isNewAccount
      isSupersedingPreviousSignatureCard
      legalRepresentative {
        correspondenceEmail {
          id
          email
          correspondenceEmailType {
            id 
            name
          }
        }
        firstName
        identification
        identificationType {
          id
          name
        }
        lastName
        position
        id
      }
      signatories {
        charge
        email
        firstName
        identification
        isRepresentative
        lastName
        signatureType {
          id
          name
        }
        orderNumber
        id
      }
    }
  }
`;

export const GET_FORM_RESPONSES_BY_PRIMARY_CONTACT = gql`
  query FormResponsesByPrimaryContact($primaryContactEmail: String!) {
    formResponsesByPrimaryContact(primaryContactEmail: $primaryContactEmail) {
      id
      creationDate
      modificationDate
      form {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        order
        personType {
          id
          creationDate
          modificationDate
          name
          parameterCode
          coreParameterCode
        }
      }
      client {
        id
        creationDate
        modificationDate
        codeLA
        codeRIF
        rnt
        cevaldomAccountNumber
      }
      riskLevel {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        altCoreParameterCode
      }
      previousPhase {
        id
        creationDate
        modificationDate
        name
      }
      phase {
        id
        creationDate
        modificationDate
        name
      }
      phaseChangeDate
      formattedFormResponseId
      formResponseStatus {
        id
        name
      }
      lead {
        identification
        identificationType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        accountRepresentative {
          id
          creationDate
          modificationDate
          firstName
          lastName
          SIVLicense
          email
          outlookId
          imageURL
          branches {
            id
            creationDate
            modificationDate
            name
            coreParameterCode
            shortName
            address
          }
          accountEnabled
          businessPhones
          personTypes {
            id
            creationDate
            modificationDate
            name
            parameterCode
            coreParameterCode
          }
        }
        primaryContact {
          email
          firstName
          lastName
          primaryContactDetails {
            id
            creationDate
            modificationDate
            phoneNumber
            secondaryPhoneNumber
            primaryContactEmail
          }
          creationDate
          modificationDate
        }
        companyLeadDetails {
          id
          creationDate
          modificationDate
          applicantType {
            id
            creationDate
            modificationDate
            name
          }
          subApplicantType {
            id
            creationDate
            modificationDate
            name
          }
        }
        creationDate
        modificationDate
        isRelatedToParval
        parvalRelationship {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
        linkageType {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
        leadManagementType {
          id
          creationDate
          modificationDate
          name
        }
        branch {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
          shortName
          address
          publicNotary {
            id
            creationDate
            modificationDate
            firstName
            lastName
            email
            collegeNumber
            zone
          }
        }
      }
    }
  }
`;

export const GET_DOCUMENT_TYPES = gql`
  query DocumentTypes {
    documentTypes {
      id
      creationDate
      modificationDate
      name
      fileManagerDocumentTypeId
      requireSignature
      isSelfGenerated
      isVisible
    }
  }
`;

export const GET_FILE_MANAGER_CLIENT = gql`
  query FileManagerClient($accessToken: String!, $email: String!) {
    fileManagerClient(accessToken: $accessToken, email: $email) {
      clientId
      clientAccounts {
        id
        client
        identification
        name
        email
        risk
        typeIdentification
      }
      clientPersonTypeName
      clientAccountTypeName
      executiveInfo {
        id
        firstName
        lastName
      }
      executiveOffice {
        id
        name
        address
      }
    }
  }
`;

export const GET_DOCUMENT_SECTION = gql`
  query DocumentSection($formResponseId: String!) {
    documentSection(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      documentsCompletionOption {
        id
        name
      }
      documents {
        id
        creationDate
        modificationDate
        fileName
        fileManagerFileId
        modifiedBy
        lastActivity
        documentType {
          id
          creationDate
          modificationDate
          name
          fileManagerDocumentTypeId
          requireSignature
          isSelfGenerated
          isVisible
        }
        status {
          id
          creationDate
          modificationDate
          name
        }
        verificationMechanism {
          id
          creationDate
          modificationDate
          name
        }
      }
    }
  }
`;

export const GET_DOCUMENT_STATUS = gql`
  query DocumentStatus {
    documentStatus {
      id
      creationDate
      modificationDate
      name
    }
  }
`;

export const GET_NOTES_By_ENTITYID = gql`
  query Notes($entityId: String!) {
    notes(entityId: $entityId) {
      id
      creationDate
      modificationDate
      title
      text
      author {
        id
        creationDate
        modificationDate
        firstName
        lastName
        displayName
        email
      }
      messageType {
        id
        creationDate
        modificationDate
        name
      }
    }
  }
`;
export const GET_VERIFICATION_MECHANISM_LIST = gql`
  query VerificationMechanismList {
    verificationMechanismList {
      id
      creationDate
      modificationDate
      name
    }
  }
`;

export const GET_SIGNATURE_PROCESS_LIST_BY_DOCUMENT_SECTION = gql`
  query SignatureProcessListByDocumentSection($documentSectionId: String!) {
    signatureProcessListByDocumentSection(
      documentSectionId: $documentSectionId
    ) {
      id
      creationDate
      modificationDate
      documentId
      code
      sha
      signedID
      error
      status
      recipients {
        id
        creationDate
        modificationDate
        key
        messageCode
        status
        mail
        name
      }
      messages {
        id
        creationDate
        modificationDate
        code
        status
        commentReject
        auditTrailPage
        policies {
          id
          creationDate
          modificationDate
          evidences {
            id
            creationDate
            modificationDate
            type
            enabled
            visible
            code
            status
            recipientKey
            imageTextSize
            legalText
          }
          signatures {
            id
            creationDate
            modificationDate
            type
            code
            status
            helpText
          }
        }
      }
    }
  }
`;

export const GET_SIGNATURE_PROCESS_BY_DOCUMENT = gql`
  query SignatureProcessByDocumentId($documentId: String!) {
    signatureProcessByDocumentId(documentId: $documentId) {
      id
      creationDate
      modificationDate
      documentId
      code
      sha
      signedID
      error
      status
      recipients {
        id
        creationDate
        modificationDate
        key
        messageCode
        status
        mail
        name
      }
      messages {
        id
        creationDate
        modificationDate
        code
        status
        commentReject
        auditTrailPage
        policies {
          id
          creationDate
          modificationDate
          evidences {
            id
            creationDate
            modificationDate
            type
            enabled
            visible
            code
            status
            recipientKey
            imageTextSize
            legalText
          }
          signatures {
            id
            creationDate
            modificationDate
            type
            code
            status
            helpText
          }
        }
      }
    }
  }
`;

export const GENERATED_CEVALDOOM_CONTRACT_PDF = gql`
  query KnowYourClientPDF($formResponseId: String!) {
    cevaldomContractPDF(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      fileName
      fileManagerFileId
      modifiedBy
      lastActivity
      verificationMechanism {
        id
        creationDate
        modificationDate
        name
      }
      documentType {
        id
        creationDate
        modificationDate
        name
        fileManagerDocumentTypeId
        requireSignature
        isSelfGenerated
        isVisible
      }
      status {
        id
        creationDate
        modificationDate
        name
      }
    }
  }
`;

export const GENERATED_BROKERAGE_CONTRACT_PDF = gql`
  query BrokerageContractPDF($formResponseId: String!) {
    brokerageContractPDF(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      fileName
      fileManagerFileId
      modifiedBy
      lastActivity
      verificationMechanism {
        id
        creationDate
        modificationDate
        name
      }
      documentType {
        id
        creationDate
        modificationDate
        name
        fileManagerDocumentTypeId
        requireSignature
        isSelfGenerated
        isVisible
      }
      status {
        id
        creationDate
        modificationDate
        name
      }
    }
  }
`;

export const GENERATED_KNOW_YOUR_CLIENT_PDF = gql`
  query KnowYourClientPDF($formResponseId: String!) {
    knowYourClientPDF(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      fileName
      fileManagerFileId
      modifiedBy
      lastActivity
      verificationMechanism {
        id
        creationDate
        modificationDate
        name
      }
      documentType {
        id
        creationDate
        modificationDate
        name
        fileManagerDocumentTypeId
        requireSignature
        isSelfGenerated
        isVisible
      }
      status {
        id
        creationDate
        modificationDate
        name
      }
    }
  }
`;

export const GET_COMMERCIAL_REFERENCES_SECTION = gql`
  query CommercialReferencesSection($formResponseId: String!) {
    commercialReferencesSection(formResponseId: $formResponseId) {
      id
      commercialReferences {
        id
        name
        email
        phoneNumber
      }
    }
  }
`;

export const GET_SUB_APPLICANT_TYPE = gql`
  query SubApplicantTypes {
    subApplicantTypes {
      id
      name
    }
  }
`;

export const GET_DUE_DILIGENCE_SECTION_FORM = gql`
  query DueDiligenceForm(
    $subApplicantTypeId: String!
    $applicantTypeId: String!
  ) {
    dueDiligenceForm(
      subApplicantTypeId: $subApplicantTypeId
      applicantTypeId: $applicantTypeId
    ) {
      id
      applicantType {
        id
        creationDate
        modificationDate
        name
      }
      subApplicantType {
        id
        creationDate
        modificationDate
        name
      }
      questions {
        id
        creationDate
        modificationDate
        question
        order
      }
      constructionCompaniesQuestions {
        id
        creationDate
        modificationDate
        question
        order
      }
    }
  }
`;

export const REQUIRED_DOCUMENTS = gql`
  query RequiredDocuments($formResponseId: String!) {
    requiredDocuments(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      name
      fileManagerDocumentTypeId
      requireSignature
      isSelfGenerated
      isVisible
    }
  }
`;

export const GET_GENDER_TYPES = gql`
  query GenderList {
    genderList {
      id
      name
    }
  }
`;

export const GET_INVESTMENT_PROFILES = gql`
  query InvestmentProfiles {
    investmentProfiles {
      id
      name
      description
    }
  }
`;

export const GET_INVESTMENT_PROFILE_TYPES = gql`
  query InvestmentProfileTypes {
    investmentProfileTypes {
      id
      name
      description
    }
  }
`;

export const VALIDATE_FORM = gql`
  query ValidateForm($formResponseId: String!) {
    validateForm(formResponseId: $formResponseId) {
      isValid
      addressSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      leadErrors {
        fieldId
        errorMessageId
        entityId
      }
      bankAccountSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      beneficiariesSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      commercialReferencesSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      communicationsSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      companyEconomicDetailsSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      clientErrors {
        fieldId
        errorMessageId
        entityId
      }
      companyInformationSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      companyShareholdersSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      dueDiligenceSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      fatcaSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      financialInstrumentsSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      investmentExperienceSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      investmentFinancialDetailsSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      investmentProfileDescriptionSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      investmentProfileSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      investorClassificationSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      legalRepresentativesSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      relatedCompaniesSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      marketingSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      signaturesSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      settlementInstructionSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      counselMembersSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
      correspondenceEmailSectionErrors {
        fieldId
        errorMessageId
        entityId
      }
    }
  }
`;

export const GET_INITIAL_INVESTMENTS = gql`
  query InitialInvestments($personTypeId: String) {
    initialInvestments(personTypeId: $personTypeId) {
      id
      name
    }
  }
`;

export const GET_RISK_LEVEL_LIST = gql`
  query RiskLevelList {
    riskLevelList {
      id
      creationDate
      modificationDate
      name
      coreParameterCode
      altCoreParameterCode
    }
  }
`;

export const GET_RISK_ENGINE_PARAMETERS = gql`
  query RiskEngineParameters($formResponseId: String!) {
    riskEngineParameters(formResponseId: $formResponseId) {
      economicActivityName
      countryName
      companyTypeName
      leadManagementTypeName
      branchName
      initialInvestmentAmountName
      provinceName
      financialOperationName
      isTherePEP
    }
  }
`;

export const GET_SETTLEMENT_INSTRUCTIONS = gql`
  query SettlementInstructionList {
    settlementInstructionList {
      id
      creationDate
      modificationDate
      name
    }
  }
`;

export const GET_SETTLEMENT_INSTRUCTION_SECTION = gql`
  query SettlementInstructionSection($formResponseId: String!) {
    settlementInstructionSection(formResponseId: $formResponseId) {
      id
      settlementInstruction {
        id
        name
      }
      accountNumber
      accountHolder
    }
  }
`;

export const GET_FORM_RESPONSE_STATUS_LIST = gql`
  query FormResponseStatusList {
    formResponseStatusList {
      id
      name
    }
  }
`;

export const GET_DOCUMENT_COMPLETION_OPTIONS = gql`
  query DocumentsCompletionOptions {
    documentsCompletionOptions {
      id
      name
    }
  }
`;

export const GET_COUNSEL_MEMBERS_SECTION = gql`
  query CounselMembersSection($formResponseId: String!) {
    counselMembersSection(formResponseId: $formResponseId) {
      id
      counselMembers {
        id
        creationDate
        modificationDate
        firstName
        lastName
        identificationType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        identification
        position
        politicExposure {
          id
          creationDate
          modificationDate
          isPep
          politicExposureDetails {
            id
            creationDate
            modificationDate
            politicExposureType {
              id
              creationDate
              modificationDate
              name
            }
            charge
            relativeName
            relativeRelationship
            institution
            active
            chargeStartDate
            chargeEndDate
          }
        }
        nationalityCountry {
          id
          creationDate
          modificationDate
          name
          parameterCode
          coreParameterCode
        }
        contactFatca {
          id
          creationDate
          modificationDate
          haveUnitedStatesNationality
          isUnitedStatesCitizen
          isUnitedStatesResident
          socialSecurityNumber
          zipInUnitedStates
          phoneInUnitedStates
          haveRepresentativeInUnitedStates
          haveTaxIdentificationNumber
          taxIdentificationNumber
          greenCard
          addressInUnitedStates
        }
      }
    }
  }
`;

export const GET_CORRESPONDENCE_EMAIL_TYPE = gql`
  query CorrespondenceEmailTypes {
    correspondenceEmailTypes {
      id
      name
    }
  }
`;

export const GET_CORRESPONDENCE_EMAIL_SECTION = gql`
  query CorrespondenceEmailSection($formResponseId: String!) {
    correspondenceEmailSection(formResponseId: $formResponseId) {
      id
      creationDate
      modificationDate
      correspondenceEmails {
        id
        creationDate
        modificationDate
        email
        correspondenceEmailType {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
      }
    }
  }
`;

export const GET_RISK_EVALUATION = gql`
  query RiskEngineEvaluation($formResponseId: String!) {
    riskEngineEvaluation(formResponseId: $formResponseId) {
      riskLevelSearched {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        altCoreParameterCode
      }
      riskLevelResponse {
        globalContribution
        globalContributionHighRisk
        highRisk
        nextEvaluation
        outcome
        rubrics {
          id
          contribution
          highRisk
          name
          options {
            id
            externalId
            rubricOptionId
            impact
            lastModifiedBy
            lastModifiedDate
            name
            probability
          }
          risk
          riskLegend
          weighing
          weight
        }
      }
      error
    }
  }
`;