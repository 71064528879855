import './ProfessionalClientForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import ModalInvestorProfile from '../../../../../shared/UIElements/ModalInvestorProfile/ModalInvestorProfile';

const ProfessionalClientForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [fatcaProxyOptions, setFatcaProxyOptions] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  // TODO algunos de esos campos llevan N/A como valor por defecto
  const haRealizadoOperacionesMercado = watch('haRealizadoOperacionesMercado');
  const haTenidoCargoDireccion = watch('haTenidoCargoDireccion');
  const haSidoMiembroConsejo = watch('haSidoMiembroConsejo');
  const haLaboradoCargosDireccion = watch('haLaboradoCargosDireccion');
  const haSidoFuncionarioSuperintencia = watch(
    'haSidoFuncionarioSuperintencia'
  );
  const tieneCertificacionCorredor = watch('tieneCertificacionCorredor');

  useEffect(() => {
    //getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const saveDataInBD = () => {
    navigate('/formularios/persona-fisica/perfil-del-inversionista');
  };

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div className="row fields-container">
        <h3 className="formTitle text-center">
          Cliente Profesional
          <ModalInvestorProfile
            title={'Cliente Profesional'}
            message={
              'Se considerarán Clientes Profesionales aquellos a quienes se presuma la experiencia, conocimientos y calificación necesarios para tomar sus propias decisiones de inversión y valorar correctamente los riesgos, de conformidad a lo establecido en el Reglamento.'
            }
          />
        </h3>

        <div className="row">
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="ha-realizado-operaciones-mercado"
              className="label-field text-justify"
            >
              ¿Ha realizado operaciones en el mercado de valores nacional o
              extranjero? <span>*</span>
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="ha-realizado-operaciones-mercado"
                defaultValue={'DEFAULT'}
                {...register('haRealizadoOperacionesMercado', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {errors.haRealizadoOperacionesMercado && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>
          {haRealizadoOperacionesMercado === 'Si' ? (
            <>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label
                  htmlFor="volumenMayorA3mm"
                  className="label-field text-justify"
                >
                  ¿El volumen transado en dichas Opereraciones ha sido igual o
                  mayor a DOP 3MM o su equivalente en otra moneda/Año?{' '}
                  <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="volumenMayorA3mm"
                    defaultValue={'DEFAULT'}
                    {...register('volumenMayorA3mm', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
                {errors.volumenMayorA3mm && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>
            </>
          ) : null}
          {haRealizadoOperacionesMercado === 'Si' ? (
            <>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label
                  htmlFor="frecuenciaOperacionesMayorA3Anio"
                  className="label-field text-justify"
                >
                  ¿Las transacciones realizadas han tenido una frecuencia media
                  de tres Opereraciones por trimestre en el año? <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="frecuenciaOperacionesMayorA3Anio"
                    defaultValue={'DEFAULT'}
                    {...register('frecuenciaOperacionesMayorA3Anio', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
                {errors.frecuenciaOperacionesMayorA3Anio && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>
            </>
          ) : null}
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="poseeActivosEfectivoMayorA25M"
              className="label-field text-justify"
            >
              ¿Posee activos en efectivo o inversiones en instrumentos
              financieros igual o mayor a DOP 25,000,000.00 o su equivalente a
              cualquier otra moneda, depositados en una entidad de
              intermediación financiera o inversiones locales o extranjeras?{' '}
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="poseeActivosEfectivoMayorA25M"
                defaultValue={'DEFAULT'}
                {...register('poseeActivosEfectivoMayorA25M', {
                  // validate: validateNonSelectedOptionInDropdown
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.poseeActivosEfectivoMayorA25M && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="haSidoMiembroConsejoAdministracionEntCal"
              className="label-field text-justify"
            >
              ¿Ha sido miembro del consejo de administración de una entidad
              calificada en el mercado de valores?{' '}
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="haSidoMiembroConsejoAdministracionEntCal"
                defaultValue={'DEFAULT'}
                {...register('haSidoMiembroConsejoAdministracionEntCal', {
                  // validate: validateNonSelectedOptionInDropdown
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.haSidoMiembroConsejoAdministracionEntCal && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="haLaboradoCargosDireccionAreaNegocios"
              className="label-field text-justify"
            >
              ¿Ha laborado Cargos de Dirección en área de negocios o afines de
              entidad que opere Sistemas Financieros por 2 años?{' '}
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="haLaboradoCargosDireccionAreaNegocios"
                defaultValue={'DEFAULT'}
                {...register('haLaboradoCargosDireccionAreaNegocios', {
                  // validate: validateNonSelectedOptionInDropdown
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.haLaboradoCargosDireccionAreaNegocios && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="haLaboradoCargosDireccionEntFinExt"
              className="label-field text-justify"
            >
              ¿Ha laborado en cargos de dirección o gerencia entidades
              financieras extranjeras/org multilaterales de la RD?{' '}
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="haLaboradoCargosDireccionEntFinExt"
                defaultValue={'DEFAULT'}
                {...register('haLaboradoCargosDireccionEntFinExt', {
                  // validate: validateNonSelectedOptionInDropdown
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.haLaboradoCargosDireccionEntFinExt && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="haSidoMiembroConsejoAdministracionSisFin"
              className="label-field text-justify"
            >
              ¿Ha sido Miembro del consejo de administración de entidad que
              opere en el Sistema Financiero por mínimo 2 años?{' '}
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="haSidoMiembroConsejoAdministracionSisFin"
                defaultValue={'DEFAULT'}
                {...register('haSidoMiembroConsejoAdministracionSisFin', {
                  // validate: validateNonSelectedOptionInDropdown
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.haSidoMiembroConsejoAdministracionSisFin && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="haSidoFuncionarioDeLaSimvBancos"
              className="label-field text-justify"
            >
              ¿Ha sido funcionario de la SIMV, Bancos, Seguros o de Pensiones
              del Banco Central RD o de Junta Monetaria?{' '}
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="haSidoFuncionarioDeLaSimvBancos"
                defaultValue={'DEFAULT'}
                {...register('haSidoFuncionarioDeLaSimvBancos', {
                  // validate: validateNonSelectedOptionInDropdown
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.haSidoFuncionarioDeLaSimvBancos && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="haTenidoCargoDireccionGerencial"
              className="label-field text-justify"
            >
              ¿Ha tenido un cargo de dir gerencial/tesorería, inv, riesgos,
              finanzas o contraloría x al menos 2 año?{' '}
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="haTenidoCargoDireccionGerencial"
                defaultValue={'DEFAULT'}
                {...register('haTenidoCargoDireccionGerencial', {
                  // validate: validateNonSelectedOptionInDropdown
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.haTenidoCargoDireccionGerencial && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>{' '}
          <div className="field-form col-lg-6 col-sm-12 ps-4">
            <label
              htmlFor="tiene-certificacion-corredor"
              className="label-field text-justify"
            >
              ¿Tiene una certificación de corredor de valores o su equivalente
              de otro país? <span>*</span>
            </label>
            <div className="selectWrapper">
              {' '}
              <select
                id="tiene-certificacion-corredor"
                defaultValue={'DEFAULT'}
                {...register('tieneCertificacionCorredor', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </select>
            </div>
            {/* {errors.tieneCertificacionCorredor && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default ProfessionalClientForm;
