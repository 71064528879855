import React, { useState } from 'react';

const Context = React.createContext({});

export function DataContext({ children }) {
  const [selectedPage, setSelectedPage] = useState('home');

  return (
    <Context.Provider value={{ selectedPage, setSelectedPage }}>
      {children}
    </Context.Provider>
  );
}
export default Context;
