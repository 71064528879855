import './ObjectivesRiskToleranceForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import {
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_INVESTMENT_OBJECTIVES,
  GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA,
  GET_INVESTMENT_PROFILE_SECTION,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GENERATE_INVESTMENT_PROFILE,
  SAVE_INVESTMENT_PROFILE_DESCRIPTION_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { useSelector } from 'react-redux';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';
import { Error } from '@mui/icons-material';

const ObjectivesRiskToleranceForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const { data: investmentObjetives } = useQuery(GET_INVESTMENT_OBJECTIVES);
  const [formResponseId, setFormResponseId] = useState('');
  const [saveInvestmentProfileDescriptionSection] = useMutation(
    SAVE_INVESTMENT_PROFILE_DESCRIPTION_SECTION,
    {
      refetchQueries: [
        {
          query: GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [
    getInvestmentProfileDescriptionSectionData,
    investmentProfileDescriptionSectionData,
  ] = useLazyQuery(GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [generateInvestmentProfile] = useMutation(GENERATE_INVESTMENT_PROFILE, {
    refetchQueries: [
      {
        query: GET_INVESTMENT_PROFILE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [sectionId, setSectionId] = useState('');
  const [loadingSection, setLoadingSection] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );

  const objetivosToleranciaRiesgo = watch('objetivosToleranciaRiesgo');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      getInvestmentProfileDescriptionSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (investmentProfileDescriptionSectionData) {
      setSectionId(
        investmentProfileDescriptionSectionData.data
          ?.investmentProfileDescriptionSection?.id
      );
      getFormInfo();
    }
  }, [investmentProfileDescriptionSectionData]);

  const getFormInfo = () => {
    if (
      investmentProfileDescriptionSectionData?.data
        ?.investmentProfileDescriptionSection
    ) {
      const { investmentObjective } =
        investmentProfileDescriptionSectionData.data
          ?.investmentProfileDescriptionSection;
      setValue('objetivosToleranciaRiesgo', investmentObjective?.id);
    }
  };

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/generales'
    );
  };

  const saveData = () => {
    if (isFormCompleted || isFormDisqualified) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/operaciones-de-titulos'
      );
      return;
    }

    let data = {};

    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (sectionId === undefined || sectionId === null) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        investmentObjectiveId: objetivosToleranciaRiesgo,
      };
    } else {
      data = {
        id: sectionId,
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        investmentObjectiveId: objetivosToleranciaRiesgo,
      };
    }

    saveInvestmentProfileDescriptionSection({
      variables: {
        investmentProfileDescriptionSection: data,
      },
    });

    generateInvestmentProfile({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });

    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/operaciones-de-titulos'
    );
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key !== 'clientErrors' && key !== 'isValid' && key !== '__typename') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const isSectionError = () => {
    return fieldsByComplexSections['Objetivos / Tolerancia al riesgo'].some(
      (flied) => validationErrors?.includes(flied)
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div className="row fields-container">
          <div className="col-11">
            <div className="field-form">
              <h3 className="formTitle">
                Objetivos / Tolerancia al riesgo
                <ModalInfo
                  Icon={<FontAwesomeIcon icon={faCircleInfo} />}
                  title={'Objetivos / Tolerancia al riesgo '}
                  description={
                    "El formulario 'Objetivos / Tolerancia al Riesgo' se centra en evaluar y comprender los objetivos financieros y la disposición hacia el riesgo de la entidad. La información capturada incluye criterios que reflejan las preferencias y metas financieras, desde la conservación del capital con la menor volatilidad y riesgo posibles, hasta la formación de un patrimonio a largo plazo con una selección agresiva de instrumentos financieros para mayores rendimientos. La respuesta a esta pregunta específica guía la definición de estrategias de inversión que se alineen de manera efectiva con las metas financieras y la tolerancia al riesgo de la institución."
                  }
                />
                {isSectionError() && (
                  <span style={{ marginLeft: 30 }}>
                    <ModalInfo
                      color="white"
                      bgcolor="#B90E0A"
                      Icon={
                        <Error
                          style={{
                            color: '#B90E0A',
                            fontSize: 25,
                            marginTop: '-5px',
                          }}
                        />
                      }
                      description={'Hay campos incompletos en esta sección'}
                    />
                  </span>
                )}
              </h3>
              {loadingSection ? (
                <div className="w-100 d-flex justify-content-center p-5">
                  <CircularProgress color="warning" />
                </div>
              ) : (
                <>
                  <label
                    htmlFor="objetivos-tolerancia-riesgo"
                    className="label-field mt-3 mb-4"
                  >
                    ¿Cuál de estos criterios se ajusta mejor a sus objetivos de inversión, rentabilidad y riesgo? <span>*</span>
                  </label>

                  <div id="objetivos-tolerancia-riesgo">
                    {investmentObjetives?.investmentObjectives?.map(
                      (option, index) => (
                        <div key={index} aria-disabled>
                          <input
                            type="radio"
                            id={`objetivo${index + 1}`}
                            name={`objetivo${index + 1}`}
                            value={option.id}
                            className="radio-options me-2"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('objetivosToleranciaRiesgo', {
                              required: !(isFormCompleted || isFormDisqualified) ,
                            })}
                          />
                          <label htmlFor={`objetivo${index + 1}`}>
                            {option.name}
                          </label>
                        </div>
                      )
                    )}
                    {errors.objetivosToleranciaRiesgo?.type === 'required' && (
                      <p className="text-danger mt-2">
                        Debe elegir una opción.
                      </p>
                    )}
                    {!objetivosToleranciaRiesgo &&
                      errors.objetivosToleranciaRiesgo?.type !== 'required' && (
                        <div className="mt-5">
                          <ErrorText
                            hasError={hasError('investmentObjective')}
                          />
                        </div>
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="submit"
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default ObjectivesRiskToleranceForm;
