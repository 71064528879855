import './ModalInfo.css';

import React from 'react';

const ModalInfo = ({
  description,
  Icon,
  bgcolor = '#fff',
  color = '#9a9a9a',
}) => {
  return (
    <>
      <span className="iconInformation">
        {Icon}
        <div
          className={
            'tooltipInfo animate__animated animate__fadeIn' +
            (bgcolor !== '#fff' ? ' error' : ' normal')
          }
          style={{ backgroundColor: bgcolor }}
        >
          <p style={{ color }}>{description}</p>
        </div>
      </span>
    </>
  );
};

export default ModalInfo;
