import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './businessRepresentative.css';
import { useDispatch } from 'react-redux';
import { setProgressBar } from '../../../shared/store/slices/clientePotencial';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { AccountCircleOutlined } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import {
  getDecodedCookie,
  modifyCookie,
} from '../../../shared/utils/functions';
import { GET_BUSINESS_REPRESENTATIVES_BY_FILTER } from '../../../shared/graphQL/queries';
import { useQuery } from '@apollo/client';
import {
  AZURE_SESSION_TIME_OUT,
  azureUserFlowUri,
} from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';

export default function BusinessRepresentative() {
  const navigate = useNavigate();
  const [agentSelected, setAgentSelected] = useState(null);
  const [animationOut, setAnimationOut] = useState(false);
  const [reload, setReload] = useState(false);

  const getOptionsSelected = () => {
    const configDecoded = getDecodedCookie('potencialCustomerConfig');
    return {
      branch: configDecoded?.branch?.id,
      personType: configDecoded?.accountType?.id
    }
  };

  const { loading, error, data } = useQuery(GET_BUSINESS_REPRESENTATIVES_BY_FILTER, {
    variables: { 
      filters: {
        branchIds: [getOptionsSelected()?.branch], 
        personTypeId: getOptionsSelected()?.personType 
      }
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgressBar({ show: true, value: '90%' }));
    const configDecoded = getDecodedCookie('potencialCustomerConfig');

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }

    if (!configDecoded?.assistUnit) {
      navigate(-1);
    }

    if (configDecoded && configDecoded.businessRepresentative) {
      setReload(true);
      setAgentSelected(configDecoded.businessRepresentative);
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener los representantes de venta', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (agentSelected && !reload) {
      goTo();
    }
  }, [agentSelected, reload]);

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    modifyCookie(
      'potencialCustomerConfig',
      'businessRepresentative',
      agentSelected,
      true,
      timeout
    );
    setAnimationOut(true);
    setTimeout(() => {
      navigate('/onboarding-digital/identity-verification');
    }, 500);
  };

  const goBack = () => {
    navigate('/onboarding-digital/company-type');
  };

  return (
    <div className="containerOnboarding">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="d-none d-md-block">
            <div className="banner">
              <img
                className="bg"
                src="/images/onboarding/bannerOnboarding_3.jpg"
                alt=""
              />
            </div>
          </Col>
          <Col className="contForm">
            <p className="linkBack" onClick={goBack}>
              <FontAwesomeIcon icon={faAngleLeft} /> Volver
            </p>
            <div className="vAlign animate__animated animate__fadeInUp">
              <strong className="title">
                Elige tu Representante de Negocio
              </strong>
              <p className="subTitle">
                En esta etapa, tendrá la oportunidad de seleccionar un
                representante de negocios que estará a su disposición para
                brindarle apoyo y colaboración.
              </p>
              <div className="mt-3 newForm">
                <div className="cards-container">
                  {!loading &&
                  !error &&
                  data &&
                  data?.accountRepresentatives?.length ? (
                    <>
                      {data?.accountRepresentatives?.length > 1 ? <div
                        className={`boxButton ${agentSelected === 'auto' ? 'selected' : ''}`}
                        onClick={() => {
                          setReload(false);
                          setAgentSelected('auto');
                        }}
                      >
                        <div className="flexLeft">
                          <div>
                            <AccountCircleOutlined
                              color="gray"
                              style={{ color: '#cbcbcb', fontSize: 60 }}
                            />
                          </div>
                          <p className="sr-card-text" style={{ color: 'gray' }}>
                            Asígname un representante
                          </p>
                        </div>
                      </div> : null}
                      {data?.accountRepresentatives.map((agent) => {
                        return (
                          <div
                            className={`boxButton ${agentSelected === agent.id ? 'selected' : ''}`}
                            onClick={() => {
                              setReload(false);
                              setAgentSelected(agent.id);
                            }}
                            key={agent.id}
                          >
                            <div className="flexLeft">
                              <div>
                                <img
                                  src={
                                    agent.firstName.includes('Arturo')
                                      ? 'https://res.cloudinary.com/dkkvblucf/image/upload/v1681393445/h9vdym3rqe1vplu5vusk.png'
                                      : agent.imageURL
                                        ? `/middleware/image-crm${agent.imageURL}`
                                        : '/images/profile-picture.png'
                                  }
                                  alt=""
                                  className="sr-card-img"
                                />
                              </div>
                              <p className="sr-card-text">
                                {agent.firstName + ' ' + agent.lastName}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                  {!loading &&
                  !error &&
                  !data?.accountRepresentatives?.length ? (
                    <p className="subTitle mt-3 fs-5 text-dark">
                      Esta sucursal no posee representantes de negocios todavía.
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
