import './GeneralForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import {
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { setSelectedSection } from '../../../../../shared/store/slices/clientePotencial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  SAVE_INVESTMENT_FINANCIAL_DETAILS_SECTION,
  SAVE_INVESTMENT_PROFILE_DESCRIPTION_SECTION,
  SAVE_INVESTMENT_EXPERIENCE_SECTION,
  GENERATE_INVESTMENT_PROFILE,
} from '../../../../../shared/graphQL/mutations';
import {
  GET_ACCOUNTS_PAYABLE_LIST,
  GET_ANNUAL_EXPENSES_LIST,
  GET_ANNUAL_REVENUE_LIST,
  GET_CAPITAL_LOST_MARGIN_LIST,
  GET_DESIRED_PROFITABILITY_LIST,
  GET_EMERGENCY_FUNDS_LIST,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_INITIAL_INVESTMENTS,
  GET_INVESTMENT_EXPERIENCE_LIST,
  GET_INVESTMENT_EXPERIENCE_SECTION_DATA,
  GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA,
  GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA,
  GET_INVESTMENT_PROFILE_SECTION,
  GET_INVESTMENT_TIME_LIST,
  GET_MARKET_INFORMATION_SOURCES_LIST,
  GET_OPERATION_TIMES_LIST,
  GET_REINVESTMENT_BEHAVIOR_LIST,
  GET_RISK_TOLERANCE_LIST,
  GET_TOTAL_PATRIMONY_LIST,
  GET_lIQUID_ASSETS_LIST,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';
import { Error } from '@mui/icons-material';

const GeneralForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formResponseId, setFormResponseId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [saveInvestmentFinancialDetailsSection] = useMutation(
    SAVE_INVESTMENT_FINANCIAL_DETAILS_SECTION,
    {
      refetchQueries: [
        {
          query: GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [saveInvestmentProfileDescriptionSection] = useMutation(
    SAVE_INVESTMENT_PROFILE_DESCRIPTION_SECTION,
    {
      refetchQueries: [
        {
          query: GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [SaveInvestmentExperienceSection] = useMutation(
    SAVE_INVESTMENT_EXPERIENCE_SECTION,
    {
      refetchQueries: [
        {
          query: GET_INVESTMENT_EXPERIENCE_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [
    getInvestmentProfileDescriptionSectionData,
    investmentProfileDescriptionSectionData,
  ] = useLazyQuery(GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA);
  const [
    getInvestmentFinancialDetailsSectionData,
    investmentFinancialDetailsSectionData,
  ] = useLazyQuery(GET_INVESTMENT_FINANCIAL_DETAILS_SECTION_DATA);
  const [getInvestmentExperienceSectionData, investmentExperienceSectionData] =
    useLazyQuery(GET_INVESTMENT_EXPERIENCE_SECTION_DATA);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [generateInvestmentProfile] = useMutation(GENERATE_INVESTMENT_PROFILE, {
    refetchQueries: [
      {
        query: GET_INVESTMENT_PROFILE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const { data: investmentExperienceList } = useQuery(
    GET_INVESTMENT_EXPERIENCE_LIST
  );
  const [getAccountsPayableList, { data: accountsPayableList }] = useLazyQuery(GET_ACCOUNTS_PAYABLE_LIST);
  const [getInitialInvestments, { data: initialInvestments }] = useLazyQuery(GET_INITIAL_INVESTMENTS);
  const [getLiquidAssetsList, { data: liquidAssetsList }] = useLazyQuery(GET_lIQUID_ASSETS_LIST);
  const [getTotalPatrimonyList, { data: totalPatrimonyList }] = useLazyQuery(GET_TOTAL_PATRIMONY_LIST);
  const { data: annualExpensesList } = useQuery(GET_ANNUAL_EXPENSES_LIST);
  const { data: annualRevenueList } = useQuery(GET_ANNUAL_REVENUE_LIST);
  const { data: emergencyFundsList } = useQuery(GET_EMERGENCY_FUNDS_LIST);
  const { data: operationTimes } = useQuery(GET_OPERATION_TIMES_LIST);
  const { data: riskToleranceList } = useQuery(GET_RISK_TOLERANCE_LIST);
  const { data: investmentTimes } = useQuery(GET_INVESTMENT_TIME_LIST);
  const { data: desiredProfitabilityList } = useQuery(
    GET_DESIRED_PROFITABILITY_LIST
  );
  const { data: marketInformationSources } = useQuery(
    GET_MARKET_INFORMATION_SOURCES_LIST
  );
  const { data: capitalLossMarginList } = useQuery(
    GET_CAPITAL_LOST_MARGIN_LIST
  );
  const { data: reinvestmentBehaviorList } = useQuery(
    GET_REINVESTMENT_BEHAVIOR_LIST
  );
  const [loadingSection, setLoadingSection] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );

  const tiempoOperacion = watch('tiempoOperacion');
  const ingresosAnuales = watch('ingresosAnuales');
  const totalActivosLiquidos = watch('totalActivosLiquidos');
  const cuentasPorPagar = watch('cuentasPorPagar');
  const gastosAnuales = watch('gastosAnuales');
  const destinoIntereses = watch('destinoIntereses');
  const patrimonioTotal = watch('patrimonioTotal');
  const horizonteInversion = watch('horizonteInversion');
  const mantieneFondoEmergencia = watch('mantieneFondoEmergencia');
  const capacidadAbsorberPerdidas = watch('capacidadAbsorberPerdidas');
  const toleranciaRiesgo = watch('toleranciaRiesgo');
  const rentabilidadDeseada = watch('rentabilidadDeseada');
  const mediosInformacionUtilizados = watch('mediosInformacionUtilizados');
  const nivelExperienciaInvesionista = watch('nivelExperienciaInvesionista');
  const inversionAnual = watch('inversionAnual');
  const inversionInicial = watch('inversionInicial');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
    const identityDocumentNumber = localStorage.getItem(
      'identityDocumentNumber'
    );
    setValue('rnc', identityDocumentNumber);
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data?.formResponses) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      const sectionParameter = {
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      };

      const optionsParameter = {
        variables: {
          personTypeId: formResponseByIdentification?.data
            ?.formResponses[0]?.form?.personType?.id
        },
      };

      getInitialInvestments(optionsParameter);
      getAccountsPayableList(optionsParameter);
      getLiquidAssetsList(optionsParameter);
      getTotalPatrimonyList(optionsParameter);

      getInvestmentFinancialDetailsSectionData(sectionParameter);
      getInvestmentProfileDescriptionSectionData(sectionParameter);
      getInvestmentExperienceSectionData(sectionParameter);
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (
      investmentFinancialDetailsSectionData.data &&
      investmentProfileDescriptionSectionData.data &&
      investmentExperienceSectionData.data
    ) {
      getFormInfo();
    }
  }, [
    investmentFinancialDetailsSectionData,
    investmentProfileDescriptionSectionData,
    investmentExperienceSectionData,
  ]);

  const handleBackNavigation = () => {
    dispatch(setSelectedSection('Información de Cliente Parval'));
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/correos-de-correspondencia'
    );
  };

  const getFormInfo = () => {
    if (
      investmentFinancialDetailsSectionData?.data
        ?.investmentFinancialDetailsSection?.id
    ) {
      setValue(
        'cuentasPorPagar',
        investmentFinancialDetailsSectionData?.data
          ?.investmentFinancialDetailsSection?.accountsPayable?.id
      );
      setValue(
        'gastosAnuales',
        investmentFinancialDetailsSectionData?.data
          ?.investmentFinancialDetailsSection?.annualExpenses?.id
      );
      setValue(
        'ingresosAnuales',
        investmentFinancialDetailsSectionData?.data
          ?.investmentFinancialDetailsSection?.annualRevenue?.id
      );
      setValue(
        'mantieneFondoEmergencia',
        investmentFinancialDetailsSectionData?.data
          ?.investmentFinancialDetailsSection?.emergencyFunds?.id
      );
      setValue(
        'patrimonioTotal',
        investmentFinancialDetailsSectionData?.data
          ?.investmentFinancialDetailsSection?.totalPatrimony?.id
      );
      setValue(
        'totalActivosLiquidos',
        investmentFinancialDetailsSectionData?.data
          ?.investmentFinancialDetailsSection?.liquidAssets?.id
      );
    }

    if (
      investmentProfileDescriptionSectionData?.data
        ?.investmentProfileDescriptionSection
    ) {
      const {
        capitalLossMargin,
        investmentTime,
        marketInformationSource,
        reinvestmentBehavior,
        riskTolerance,
        desiredProfitability,
        initialInvestmentAmount,
        annualInvestmentAmount,
      } =
        investmentProfileDescriptionSectionData.data
          ?.investmentProfileDescriptionSection;
      if (capitalLossMargin?.id) {
        setValue('capacidadAbsorberPerdidas', capitalLossMargin?.id);
      }
      if (investmentTime?.id) {
        setValue('horizonteInversion', investmentTime?.id);
      }
      if (marketInformationSource?.id) {
        setValue('mediosInformacionUtilizados', marketInformationSource?.id);
      }
      if (reinvestmentBehavior?.id) {
        setValue('destinoIntereses', reinvestmentBehavior?.id);
      }
      if (riskTolerance?.id) {
        setValue('toleranciaRiesgo', riskTolerance?.id);
      }
      if (desiredProfitability?.id) {
        setValue('rentabilidadDeseada', desiredProfitability?.id);
      }
      if (initialInvestmentAmount?.id) {
        setValue('inversionInicial', initialInvestmentAmount?.id);
      }
      if (annualInvestmentAmount?.id) {
        setValue('inversionAnual', annualInvestmentAmount?.id);
      }
    }

    if (investmentExperienceSectionData?.data?.investmentExperienceSection) {
      const { investmentExperience, operationTime } =
        investmentExperienceSectionData?.data?.investmentExperienceSection;
      setValue('nivelExperienciaInvesionista', investmentExperience?.id);
      setValue('tiempoOperacion', operationTime?.id);
    }

    setLoadingSection(false);
  };

  const saveData = () => {
    if (isFormCompleted || isFormDisqualified) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/objetivos-tolerancia-al-riesgo'
      );
      return;
    }

    let data = {};
    // // Primer servicio
    const sectionId =
      investmentFinancialDetailsSectionData?.data
        ?.investmentFinancialDetailsSection?.id;
    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (sectionId === undefined || sectionId === null) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        accountsPayableId: cuentasPorPagar,
        annualExpensesId: gastosAnuales,
        annualRevenueId: ingresosAnuales,
        emergencyFundsId: mantieneFondoEmergencia,
        totalPatrimonyId: patrimonioTotal,
        liquidAssetsId: totalActivosLiquidos,
      };
    } else {
      data = {
        id: sectionId,
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        accountsPayableId: cuentasPorPagar,
        annualExpensesId: gastosAnuales,
        annualRevenueId: ingresosAnuales,
        emergencyFundsId: mantieneFondoEmergencia,
        totalPatrimonyId: patrimonioTotal,
        liquidAssetsId: totalActivosLiquidos,
      };
    }

    saveInvestmentFinancialDetailsSection({
      variables: {
        investmentFinancialDetailsSection: data,
      },
    });

    // Segundo servicio
    const sectionId2 =
      investmentProfileDescriptionSectionData.data
        ?.investmentProfileDescriptionSection?.id;

    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (sectionId2 === undefined || sectionId2 === null) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        capitalLossMarginId: capacidadAbsorberPerdidas,
        investmentTimeId: horizonteInversion,
        marketInformationSourceId: mediosInformacionUtilizados,
        reinvestmentBehaviorId: destinoIntereses,
        riskToleranceId: toleranciaRiesgo,
        desiredProfitabilityId: rentabilidadDeseada,
        annualInvestmentAmountId: inversionAnual,
        initialInvestmentAmountId: inversionInicial,
      };
    } else {
      data = {
        id: sectionId2,
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        capitalLossMarginId: capacidadAbsorberPerdidas,
        investmentTimeId: horizonteInversion,
        marketInformationSourceId: mediosInformacionUtilizados,
        reinvestmentBehaviorId: destinoIntereses,
        riskToleranceId: toleranciaRiesgo,
        desiredProfitabilityId: rentabilidadDeseada,
        annualInvestmentAmountId: inversionAnual,
        initialInvestmentAmountId: inversionInicial,
      };
    }

    saveInvestmentProfileDescriptionSection({
      variables: {
        investmentProfileDescriptionSection: data,
      },
    });

    // Tercer servicio
    const sectionId3 =
      investmentExperienceSectionData.data?.investmentExperienceSection?.id;

    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (sectionId3 === undefined || sectionId3 === null) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        investmentExperienceId: nivelExperienciaInvesionista,
        operationTimeId: tiempoOperacion,
      };
    } else {
      data = {
        id: sectionId3,
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        investmentExperienceId: nivelExperienciaInvesionista,
        operationTimeId: tiempoOperacion,
      };
    }

    SaveInvestmentExperienceSection({
      variables: {
        investmentExperienceSection: data,
      },
    });

    generateInvestmentProfile({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });

    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/objetivos-tolerancia-al-riesgo'
    );
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key !== 'clientErrors' && key !== 'isValid' && key !== '__typename') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const isSectionError = () => {
    return fieldsByComplexSections.Generales2.some((flied) =>
      validationErrors?.includes(flied)
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div
          className="row fields-container"
          style={{ height: loadingSection ? '80%' : 'auto' }}
        >
          <h3 className="formTitle">
            Generales
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Generales'}
              description={
                "El formulario 'Generales' abarca una visión integral de la información financiera clave de la institución. Aquí se recopilan datos esenciales que proporcionan una instantánea de la posición financiera general. Este espacio contempla aspectos como el tipo de operación, ingresos anuales, activos líquidos, cuentas por pagar, gastos anuales, destino de los intereses, patrimonio total, horizonte de inversión, fondos de emergencia, capacidad de absorber pérdidas, tolerancia al riesgo, rentabilidad deseada, y el nivel de experiencia y conocimiento como inversionista. La captura de estos datos busca construir un perfil financiero comprensivo que sirva como base para la toma de decisiones estratégicas y la gestión eficiente de los recursos de la institución."
              }
            />
            {isSectionError() && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={'Hay campos incompletos en esta sección'}
                />
              </span>
            )}
          </h3>

          {loadingSection ? (
            <div className="w-100 d-flex justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="row">
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="tipo-operacion" className="label-field">
                  Tiempo de Operación <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="tipo-operacion"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('tiempoOperacion', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {operationTimes?.operationTimes.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.tiempoOperacion && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!tiempoOperacion || tiempoOperacion === 'DEFAULT') &&
                  !errors.tiempoOperacion && (
                    <ErrorText hasError={hasError('operationTime')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label
                  htmlFor="mantiene-fondo-inversion"
                  className="label-field"
                >
                  ¿Mantiene un fondo de emergencia para cubrir gastos
                  imprevistos? <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="mantiene-fondo-inversion"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('mantieneFondoEmergencia', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {emergencyFundsList?.emergencyFundsList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.mantieneFondoEmergencia && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!mantieneFondoEmergencia ||
                  mantieneFondoEmergencia === 'DEFAULT') &&
                  !errors.mantieneFondoEmergencia && (
                    <ErrorText hasError={hasError('emergencyFunds')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label
                  htmlFor="capacidad-absorber-perdidas"
                  className="label-field"
                >
                  Capacidad de absorber pérdidas de capital <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="gastos-anuales"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('capacidadAbsorberPerdidas', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {capitalLossMarginList?.capitalLossMarginList.map(
                      (option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>{' '}
                </div>
                {errors.capacidadAbsorberPerdidas && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!capacidadAbsorberPerdidas ||
                  capacidadAbsorberPerdidas === 'DEFAULT') &&
                  !errors.capacidadAbsorberPerdidas && (
                    <ErrorText hasError={hasError('capitalLossMargin')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="ingresos-nuales" className="label-field">
                  Ingresos Anuales <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="ingresos-nuales"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('ingresosAnuales', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {annualRevenueList?.annualRevenueList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.ingresosAnuales && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!ingresosAnuales || ingresosAnuales === 'DEFAULT') &&
                  !errors.ingresosAnuales && (
                    <ErrorText hasError={hasError('annualRevenue')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="tolerancia-riesgo" className="label-field">
                  Tolerancia al riesgo <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="tolerancia-riesgo"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('toleranciaRiesgo', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {riskToleranceList?.riskToleranceList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.toleranciaRiesgo && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!toleranciaRiesgo || toleranciaRiesgo === 'DEFAULT') &&
                  !errors.toleranciaRiesgo && (
                    <ErrorText hasError={hasError('riskTolerance')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="inversion-anual" className="label-field">
                  Monto Estimado Inversión Anual <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="inversion-anual"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('inversionAnual', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {initialInvestments?.initialInvestments.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.inversionAnual && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!inversionAnual || inversionAnual === 'DEFAULT') &&
                  !errors.inversionAnual && (
                    <ErrorText hasError={hasError('annualInvestmentAmount')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="inversion-inicial" className="label-field">
                  Monto Estimado Inversión Inicial <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="inversion-inicial"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('inversionInicial', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {initialInvestments?.initialInvestments.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.inversionInicial && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!inversionInicial || inversionInicial === 'DEFAULT') &&
                  !errors.inversionInicial && (
                    <ErrorText hasError={hasError('initialInvestmentAmount')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="total-activos-liquidos" className="label-field">
                  Total activos líquidos <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="total-activos-liquidos"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('totalActivosLiquidos', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {liquidAssetsList?.liquidAssetsList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.totalActivosLiquidos && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!totalActivosLiquidos ||
                  totalActivosLiquidos === 'DEFAULT') &&
                  !errors.totalActivosLiquidos && (
                    <ErrorText hasError={hasError('liquidAssets')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="rentabilidad-deseada" className="label-field">
                  Rentabilidad Deseada <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="rentabilidad-deseada"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('rentabilidadDeseada', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {desiredProfitabilityList?.desiredProfitabilityList.map(
                      (option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>{' '}
                </div>
                {errors.rentabilidadDeseada && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!rentabilidadDeseada || rentabilidadDeseada === 'DEFAULT') &&
                  !errors.rentabilidadDeseada && (
                    <ErrorText hasError={hasError('desiredProfitability')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="cuentas-por-pagar" className="label-field">
                  Cuentas Por Pagar RD$ <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="cuentas-por-pagar"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('cuentasPorPagar', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {accountsPayableList?.accountsPayableList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.cuentasPorPagar && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!cuentasPorPagar || cuentasPorPagar === 'DEFAULT') &&
                  !errors.cuentasPorPagar && (
                    <ErrorText hasError={hasError('accountsPayable')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label
                  htmlFor="medios-informacion-utilizados"
                  className="label-field"
                >
                  Medios de información utilizados <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="medios-informacion-utilizados"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('mediosInformacionUtilizados', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {marketInformationSources?.marketInformationSources.map(
                      (option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>{' '}
                </div>
                {errors.mediosInformacionUtilizados && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!mediosInformacionUtilizados ||
                  mediosInformacionUtilizados === 'DEFAULT') &&
                  !errors.mediosInformacionUtilizados && (
                    <ErrorText hasError={hasError('marketInformationSource')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="gastos-anuales" className="label-field">
                  Gastos Anuales RD$ <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="gastos-anuales"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('gastosAnuales', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {annualExpensesList?.annualExpensesList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.gastosAnuales && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!gastosAnuales || gastosAnuales === 'DEFAULT') &&
                  !errors.gastosAnuales && (
                    <ErrorText hasError={hasError('annualExpenses')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label
                  htmlFor="nivel-experiencia-invesionista"
                  className="label-field"
                >
                  Nivel de experiencia y conocimiento como inversionista{' '}
                  <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="nivel-experiencia-invesionista"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('nivelExperienciaInvesionista', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {investmentExperienceList?.investmentExperienceList.map(
                      (option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>{' '}
                </div>
                {errors.nivelExperienciaInvesionista && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!nivelExperienciaInvesionista ||
                  nivelExperienciaInvesionista === 'DEFAULT') &&
                  !errors.nivelExperienciaInvesionista && (
                    <ErrorText hasError={hasError('investmentExperience')} />
                  )}
              </div>
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="destino-intereses" className="label-field">
                  Destino de los intereses <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="destino-intereses"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('destinoIntereses', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {reinvestmentBehaviorList?.reinvestmentBehaviorList.map(
                      (option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>{' '}
                </div>
                {errors.destinoIntereses && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!destinoIntereses || destinoIntereses === 'DEFAULT') &&
                  !errors.destinoIntereses && (
                    <ErrorText hasError={hasError('reinvestmentBehavior')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="patrimonio-total" className="label-field">
                  Patrimonio total <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="patrimonio-total"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('patrimonioTotal', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {totalPatrimonyList?.totalPatrimonyList.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.patrimonioTotal && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!patrimonioTotal || patrimonioTotal === 'DEFAULT') &&
                  !errors.patrimonioTotal && (
                    <ErrorText hasError={hasError('totalPatrimony')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="horizonte-inversion" className="label-field">
                  En términos de tiempo, el horizonte de inversión del cliente
                  es: <span>*</span>
                </label>
                <div className="selectWrapper">
                  {' '}
                  <select
                    id="horizonte-inversion"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('horizonteInversion', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {investmentTimes?.investmentTimes.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>{' '}
                </div>
                {errors.horizonteInversion && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!horizonteInversion || horizonteInversion === 'DEFAULT') &&
                  !errors.horizonteInversion && (
                    <ErrorText hasError={hasError('investmentTime')} />
                  )}
              </div>
            </div>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="submit"
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default GeneralForm;
