import { useEffect, useState } from 'react';
import {
  getAuthorizationConfigCookie,
  currencyFormat,
  formatName,
  notAuthorizedTokenClienteReal,
  gatewayError,
  requestCanceled,
  ram,
} from '../../../shared/utils/functions';
import { Skeleton } from '@mui/material';
import { Container, Col, Row, Breadcrumb } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './inicio.css';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ConsolidatedPosition from '../../../components/ClienteReal/ConsolidatedPosition/ConsolidatedPosition';
import Product from '../../../components/ClienteReal/Product/Product';
import { useDispatch } from 'react-redux';
import Top from '../../../components/ClienteReal/Top/Top';

export default function Inicio() {
  const [positionsLoading, setPositionsLoading] = useState(true);
  const [datesLoading, setDatesLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);

  const [consolidateResumes, setConsolidateResumes] = useState([]);
  const [changeRate, setChangeRate] = useState(0);
  const [dates, setDates] = useState([]);
  const [products, setProducts] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const [showDateSelector, setShowDateSelector] = useState(false);
  const [prevAbortController, setPrevAbortController] = useState(null);

  const selector = useSelector((state) => state.clienteRealSlice);
  const dispatch = useDispatch();

  const {
    profile,
    currentAccount,
    session: { showSessionModal },
  } = selector;

  // Function to cancel the previous request
  const cancelPreviousRequest = () => {
    if (prevAbortController) {
      prevAbortController.abort();
    }
  };

  useEffect(() => {
    if (dates && dates.length) {
      setSelectedDate(dates[0]);
    }
  }, [currentAccount]);

  useEffect(() => {
    init();
  }, [currentAccount, selectedDate]);

  const init = async () => {
    if (!dates || !dates.length) {
      await getCutOffDates();
    }
    setChangeRate(54.663);
    if (selectedDate) {
      await getConsolidatedPositions();
    }
  };

  const getCutOffDates = async () => {
    setDatesLoading(true);
    const config = getAuthorizationConfigCookie();
    try {
      if (!dates.length) {
        const res = await axios.get(
          '/middleware/api/ubibroker/cutoff-dates',
          config
        );
        const dates = res.data.map((d) => d.date);
        setDates(dates);
        setSelectedDate(dates[0]);
      }
    } catch (error) {
      console.log(error);
      notAuthorizedTokenClienteReal(error, dispatch, showSessionModal);
      gatewayError(getCutOffDates, error);
    }
    setDatesLoading(false);
  };

  const formatDate = (date) => {
    if (date) {
      const newDate = date.split('T')[0];
      const elements = newDate.split('-');

      return `${elements[2]}/${elements[1]}/${elements[0]}`;
    }
  };

  const getConsolidatedPositions = async () => {
    cancelPreviousRequest();

    // Create a new AbortController instance
    const abortController = new AbortController();

    setPrevAbortController(abortController);
    setPositionsLoading(true);
    setProductsLoading(true);
    setProducts([]);
    setConsolidateResumes(null);
    const config = getAuthorizationConfigCookie();
    try {
      config.params = {
        cutoffDate: selectedDate,
        rif: currentAccount.rif,
      };
      config.signal = abortController.signal;
      const res = await axios.get(
        '/middleware/api/ubibroker/consolidated-position',
        config
      );
      if (res.data) {
        const cps = res.data;
        cps.positions = cps.positions.map((cp, index) => {
          cp.id = index + 1 * ram();
          return cp;
        });
        setConsolidateResumes(setCPResumes(cps));
        setPositionsLoading(false);

        if (res.data.positions && res.data.positions.length) {
          await getProducts(cps.positions, abortController);
        }
        setProductsLoading(false);
        setPositionsLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (!requestCanceled(error)) {
        notAuthorizedTokenClienteReal(error, dispatch, showSessionModal);
        await gatewayError(getConsolidatedPositions, error);
        setProductsLoading(false);
        setPositionsLoading(false);
      }
    }
  };

  const setCPResumes = (cps) => {
    const availableCoins = ['DOP', 'USD'];

    const resume = (coin) => {
      let positions = cps.positions.filter((cp) => cp.coin === coin);
      let counter = 0;
      positions.forEach((p) => (counter += p.amount));
      return {
        amount: counter,
        coin,
      };
    };

    let results = [];

    availableCoins.forEach((coin, index) => {
      const resumes = resume(coin);
      resumes.id = index;
      resumes.amount !== 0 && results.push(resumes);
    });

    return results;
  };

  const getProducts = async (cps, abortController) => {
    const config = getAuthorizationConfigCookie();
    const newProducts = [];
    for (const [i, cp] of cps.entries()) {
      const product = await getProduct(cp, config, i, abortController);
      product && newProducts.push(product);
    }
    setProducts(newProducts);
  };

  const getProduct = async (cp, config, id, abortController) => {
    try {
      config.params = {
        date: selectedDate,
        rif: currentAccount.rif,
        product: cp.acronym,
        currency: cp.coin,
      };
      config.signal = abortController.signal;
      const res = await axios.get(
        '/middleware/api/ubibroker/product-details',
        config
      );
      if (res.data) {
        return {
          id: id * ram(),
          cp,
          product: res.data,
        };
      }
    } catch (error) {
      console.log(error);
      if (!requestCanceled(error)) {
        notAuthorizedTokenClienteReal(error, dispatch, showSessionModal);
        await gatewayError(getProduct, error);
      }
    }
  };

  return (
    <div className="cliente-real-dashboard-inicio">
      <Top
        profile={profile}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              {currentAccount.titular ? 'Titular' : 'Cotitular'}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{currentAccount.idClient}</Breadcrumb.Item>
            <Breadcrumb.Item active>
              <strong>Inicio</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title={
          <div className="greetings">
            <p className="p-0 m-0">Bienvenido(a),</p>
            <p className="p-0 m-0" style={{ fontWeight: 600 }}>
              {formatName(currentAccount.name + ' ' + currentAccount.lastName)}
            </p>
          </div>
        }
      />
      <div className="content">
        <Container className="position-container" fluid>
          <div className="position-content">
            <Row>
              {!positionsLoading ? (
                consolidateResumes && consolidateResumes.length ? (
                  consolidateResumes.map((cr) => (
                    <Col key={cr.id}>
                      <ConsolidatedPosition consolidatedPosition={cr} />
                    </Col>
                  ))
                ) : (
                  <Col>
                    <div className="no-positions box">
                      <strong>
                        No se encontraron posiciones consolidadas.
                      </strong>
                    </div>
                  </Col>
                )
              ) : (
                <>
                  <Col>
                    <Skeleton
                      variant="rounded"
                      height={148}
                      animation="wave"
                      style={{ backgroundColor: '#dddddd', borderRadius: 10 }}
                    />
                  </Col>
                  <Col>
                    <Skeleton
                      variant="rounded"
                      height={148}
                      animation="wave"
                      style={{ backgroundColor: '#dddddd', borderRadius: 10 }}
                    />
                  </Col>
                </>
              )}
              <Col>
                <div className="consolidated-position rate-container">
                  <p className="consolidated-position-title p-0 mb-2">
                    Tipo de Cambio
                  </p>
                  <div className="rates">
                    <div className="rate">
                      <p
                        className="p-0 m-0 consolidated-position-title"
                        style={{ fontWeight: 400 }}
                      >
                        DOP
                      </p>
                      <strong className="rate-amount">
                        {currencyFormat(1.0)}
                      </strong>
                    </div>
                    <div className="rate">
                      <p
                        className="p-0 m-0 consolidated-position-title"
                        style={{ fontWeight: 400 }}
                      >
                        USD
                      </p>
                      <strong className="rate-amount">
                        {currencyFormat(changeRate, 3)}
                      </strong>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="inicio-content">
          <div className="position-dates-container">
            <strong className="dates-title">Inversiones</strong>
            {datesLoading ? (
              <Skeleton
                variant="rounded"
                height={30}
                animation="wave"
                style={{ backgroundColor: '#dddddd', borderRadius: 20 }}
              />
            ) : dates && dates.length ? (
              <div className="dates-content">
                <div
                  className="dates-container"
                  onClick={() => setShowDateSelector(!showDateSelector)}
                >
                  <div className="date-selected">
                    {formatDate(selectedDate)}
                  </div>
                  {showDateSelector ? (
                    <ExpandLess style={{ color: 'gray', fontSize: 30 }} />
                  ) : (
                    <ExpandMore style={{ color: 'gray', fontSize: 30 }} />
                  )}
                </div>
                {showDateSelector ? (
                  <div className="dates-selector">
                    {dates.map((d) => (
                      <p
                        className="p-0 m-0 dates-selector-option"
                        key={d}
                        onClick={() => {
                          setSelectedDate(d);
                          setShowDateSelector(false);
                        }}
                      >
                        {formatDate(d)}
                      </p>
                    ))}
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="dates-container">No hay fechas que mostrar</div>
            )}
          </div>
          <div className="product-details mt-4">
            <Container className="products-container" fluid>
              {!productsLoading ? (
                products && products.length ? (
                  products.map((product) =>
                    product ? (
                      <Row key={product.id} className="mb-4">
                        <Product product={product} />
                      </Row>
                    ) : null
                  )
                ) : (
                  <Row>
                    <Col>
                      <div className="no-positions box" style={{ height: 400 }}>
                        <strong>No se encontraron productos.</strong>
                      </div>
                    </Col>
                  </Row>
                )
              ) : (
                <>
                  <Row className="mb-4">
                    <Col>
                      <Skeleton
                        variant="rounded"
                        height={300}
                        animation="wave"
                        style={{ backgroundColor: '#dddddd', borderRadius: 10 }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Skeleton
                        variant="rounded"
                        height={300}
                        animation="wave"
                        style={{ backgroundColor: '#dddddd', borderRadius: 10 }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          </div>
        </div>
      </div>
    </div>
  );
}
