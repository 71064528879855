import { useEffect } from 'react';
import { getDomain } from '../../../shared/utils/functions';
import './CardSummaryOfAccounts.css';
import { useLazyQuery } from '@apollo/client';
import { GET_DUE_DILIGENCE_SECTION } from '../../../shared/graphQL/queries';

const CardSummaryOfAccounts = ({
  name,
  personType,
  formattedFormResponseId,
  formResponse,
}) => {
  const [getDueDiligenceSectionData] = useLazyQuery(GET_DUE_DILIGENCE_SECTION);

  const changeFormResponse = async () => {
    sessionStorage.setItem('selectedFormResponse', JSON.stringify(formResponse));

    const currentDomain = getDomain();

    const dueDiligence = await getDueDiligenceSectionData({
      variables: { formResponseId: formResponse.id },
    });

    if (dueDiligence?.data?.dueDiligenceSection?.isObligatedSubject) {
      sessionStorage.setItem(
        'obligatedSubject',
        JSON.stringify(
          dueDiligence?.data?.dueDiligenceSection?.dueDiligenceSectionDetails
            ?.obligatedSubjectType
        )
      );
    } else {
      sessionStorage.removeItem('obligatedSubject');
    }

    if (
      window.location.href.includes('datos-de-la-cuenta/formularios') ||
      window.location.href.includes('resumen-de-cuentas')
    ) {
      if (
        formResponse?.lead?.companyLeadDetails?.applicantType.name.includes(
          'Negocio'
        )
      ) {
        sessionStorage.setItem('hasInvestmentProfile', true);
      } else {
        sessionStorage.setItem('hasInvestmentProfile', false);
      }
      if (formResponse?.form?.personType?.name === 'Persona Jurídica') {
        window.location.href =
          currentDomain +
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-general';
      } else {
        window.location.href =
          currentDomain +
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/datos-generales';
      }
    } else {
      window.location.reload();
    }
  };

  return (
    <div className="card-summary-account" onClick={changeFormResponse}>
      <div className="card-summary-account-header">
        <h2>{name}</h2>
        <p>{formattedFormResponseId}</p>
      </div>
      <div className="card-summary-account-body">
        <div className="type-person-text-card-summary">
          <p className="centered-text">{personType}</p>
        </div>
        <div className="status-account-text">
          <span>Status de la cuenta: </span>
          {formResponse?.formResponseStatus?.name === 'Nuevo' ? (
            <p>En proceso</p>
          ) : (
            <p>{formResponse?.formResponseStatus?.name?.includes('Completado')
              ? 'Completado' : formResponse?.formResponseStatus?.name }</p>
          )}
        </div>
      </div>
      <div className="card-summary-account-footer">
        {formResponse?.hasInvestmentProfile === true ? (
          <div className="investor-profile-text-progress">
            <span>Perfil del inversionista: </span>
            <div>
              <p>
                {formResponse?.profileCompletionPercentaje < 100 || !formResponse?.profileCompletionPercentaje
                  ? 'En Progreso'
                  : 'Completado'}{' '}
                ({formResponse?.profileCompletionPercentaje ?? 0}%)
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardSummaryOfAccounts;
