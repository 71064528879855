// If this function returns true then the ID is valid, if it returns false then the ID is not valid
export function validarCedulaDominicana(cedula) {
  // Cédula debe tener 11 dígitos
  if (cedula.length !== 11) {
    return false;
  }
  // Obtener el primer dígito
  const primerDigito = parseInt(cedula.substring(0, 1));
  // Validar que el primer dígito sea 0, 1 o 2
  if (primerDigito < 0 || primerDigito > 2) {
    return false;
  }
  // Obtener los siguientes 8 dígitos
  const siguienteOchoDigitos = parseInt(cedula.substring(1, 9));
  // Validar que los siguientes 8 dígitos sean mayores a 0
  if (siguienteOchoDigitos <= 0) {
    return false;
  }
  // Obtener el último dígito
  const ultimoDigito = parseInt(cedula.substring(10, 11));
  // Validar que el último dígito sea igual al resultado de un cálculo
  const factor = [1, 2, 1, 2, 1, 2, 1, 2, 1];
  let suma = 0;
  for (let i = 0; i < factor.length; i++) {
    const producto = factor[i] * parseInt(cedula.charAt(i));
    suma += producto >= 10 ? producto - 9 : producto;
  }
  const resultado = 10 - (suma % 10);
  return ultimoDigito === resultado;
}

export const validateEmail = (email) => {
  if (email === '') {
    console.log('The email can not be empty');
  }

  // Define our regular expression.
  const validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

  // Using test we can check if the text match the pattern
  if (!validEmail.test(email) && email.length > 0) {
    console.log('Debe ingresar un correo electrónico válido');
  }
};

export const validateNonSelectedOptionInDropdown = (value) => {
  return value !== 'DEFAULT' && value !== '' && value !== 'Selecciona';
};
