import './ContactInformationForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';

const ContactInformationForm = ({
  validateForm,
  setValidateForm,
  goToBack,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [preferredMethodsContact, setPreferredMethodsContact] = useState([]);

  const telefonoResidencial = watch('telefonoResidencial');
  const telefonoCelular = watch('telefonoCelular');
  const telefonoAdicional = watch('telefonoAdicional');
  const metodoPreferidoContacto = watch('metodoPreferidoContacto');

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-fisica/marketing-sobre-parval');
    }
  }, [goToBack]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveDataInBD = () => {};

  return (
    <>
      {showSpinner ? <Spinner /> : null}

      <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
        <div className="row fields-container">
          <div className="col-md-5 col-sm-12 pe-4 columns">
            <div className="field-form">
              <label htmlFor="telefono-residencial" className="label-field">
                Teléfono residencial
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="telefono-residencial"
                {...register('telefonoResidencial', {
                  required: true,
                })}
              />
              {errors.telefonoResidencial?.type === 'required' && (
                <p className="text-danger">
                  El campo Teléfono residencial es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="telefono-celular" className="label-field">
                Teléfono celular
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="telefono-celular"
                {...register('telefonoCelular', {
                  required: true,
                })}
              />
              {errors.telefonoCelular?.type === 'required' && (
                <p className="text-danger">
                  El campo Teléfono celular es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="telefono-adicional" className="label-field">
                Teléfono adicional
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="telefono-adicional"
                {...register('telefonoAdicional', {
                  required: true,
                })}
              />
              {errors.telefonoAdicional?.type === 'required' && (
                <p className="text-danger">
                  El campo Teléfono adicional es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="metodo-preferido-contacto"
                className="label-field"
              >
                Elije tu método preferido de contacto
              </label>
              <select
                id="metodo-preferido-contacto"
                defaultValue={'DEFAULT'}
                {...register('metodoPreferidoContacto', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {preferredMethodsContact.map((preferredMethodContact) => (
                  <option
                    key={preferredMethodContact.id}
                    value={preferredMethodContact.name}
                  >
                    {preferredMethodContact.name}
                  </option>
                ))}
              </select>
              {errors.metodoPreferidoContacto && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>
          </div>
        </div>
        <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
          submit
        </button>
      </form>
    </>
  );
};

export default ContactInformationForm;
