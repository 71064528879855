import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './assistanceUnit.css';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProgressBar } from '../../../shared/store/slices/clientePotencial';
import { Help } from '@mui/icons-material';
import {
  deleteCookie,
  envars,
  getDecodedCookie,
  modifyCookie,
} from '../../../shared/utils/functions';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_BRANCHES,
  GET_CLIENTYPES,
  GET_SUB_APPLICANT_TYPE,
} from '../../../shared/graphQL/queries';
import { CircularProgress } from '@mui/material';
import {
  AZURE_SESSION_TIME_OUT,
  CLIENTTYPE,
  PERSONTYPE,
  azureUserFlowUri,
} from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';

export default function AssistanceUnit() {
  const [assistUnit, setAssistUnit] = useState(null);
  const { loading, error, data } = useQuery(GET_CLIENTYPES);
  const { data: subapplicanTypes } = useQuery(GET_SUB_APPLICANT_TYPE);
  const { data: branches } = useQuery(GET_BRANCHES);
  const [animationOut, setAnimationOut] = useState(false);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgressBar({ show: true, value: '62.5%' }));
    const configDecoded = getDecodedCookie('potencialCustomerConfig');
    if (configDecoded && configDecoded.assistUnit) {
      setReload(true);
      configDecoded.assistUnit.knowid
        ? setAssistUnit({ knowid: '0' })
        : setAssistUnit(configDecoded.assistUnit);
    }

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener las unidades de asistencia', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (assistUnit && !reload) {
      goTo();
    }
  }, [assistUnit, reload]);

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    let unit =
      assistUnit.knowid === '0'
        ? data?.applicantTypes.find((p) =>
            p.name.toLowerCase().includes('negocios')
          )
        : assistUnit;
    assistUnit.knowid === '0' && (unit = { ...unit, knowid: '0' });
    modifyCookie('potencialCustomerConfig', 'assistUnit', unit, true, timeout);
    if (unit.name.toLowerCase().includes('tesorería')) {
      const alamedaBranch = branches.branches.find((branch) =>
        branch.name.toLowerCase().includes('alameda')
      );
      modifyCookie(
        'potencialCustomerConfig',
        'branch',
        alamedaBranch,
        true,
        timeout
      );
      setTimeout(() => {
        navigate(`/onboarding-digital/sub-type`);
      }, 500);
    } else {
      const clienteParval = subapplicanTypes.subApplicantTypes.find(
        (subApplicantType) => subApplicantType.name === 'Cliente PARVAL'
      );
      modifyCookie(
        'potencialCustomerConfig',
        'subtype',
        clienteParval,
        true,
        timeout
      );
      setTimeout(() => {
        navigate(`/onboarding-digital/select-branch`);
      }, 500);
    }
    setAnimationOut(true);
  };

  // const goBack = () => {
  //     navigate('/onboarding-digital/person-type');
  // }

  const goBack = () => {
    // REMOVER DESPUES
    deleteCookie('potencialCustomerConfig');
    window.location.replace(azureUserFlowUri());
  };

  const getConfigUnit = (p) => {
    return CLIENTTYPE[
      p.name.split(' ')[p.name.split(' ').length - 1].toLowerCase()
    ];
  };

  return (
    <div className="containerOnboarding">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="d-none d-md-block">
            <div className="banner">
              <img
                className="bg"
                src="/images/onboarding/bannerOnboarding_1.jpg"
                alt=""
              />
            </div>
          </Col>
          <Col className="contForm">
            <p className="linkBack" onClick={goBack}>
              <FontAwesomeIcon icon={faAngleLeft} /> Volver
            </p>
            <div className="vAlign animate__animated animate__fadeInUp">
              <strong className="title">
                ¿Sabes si tu caso es atendido por alguna de estas unidades?
              </strong>
              <div className="cliente-potencial-register-form">
                <div className="mt-2 newForm">
                  {!loading && !error && data && (
                    <div
                      className="assist-unit cards-container"
                      style={{ marginBottom: 75 }}
                    >
                      {data?.applicantTypes.map((p) => (
                        <div
                          key={p.id}
                          className={`boxButton ${assistUnit && assistUnit.id === p.id ? 'selected' : ''}`}
                          onClick={() => {
                            setReload(false);
                            setAssistUnit(p);
                          }}
                        >
                          <div>
                            <h5 className="rwf-card-title">
                              {getConfigUnit(p).name}
                            </h5>
                          </div>
                        </div>
                      ))}

                      <div
                        className={`boxButton ${assistUnit && assistUnit.knowid === '0' ? 'selected' : ''}`}
                        onClick={() => {
                          setReload(false);
                          setAssistUnit({ knowid: '0' });
                        }}
                      >
                        <div>
                          <h5 className="rwf-card-title">Aún no lo sé</h5>
                        </div>
                      </div>
                    </div>
                  )}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
