import './MainOfficeAdressFormNew.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import {
  REMOVE_ADDRESSES,
  SAVE_ADDRESS,
  SAVE_ADDRESSES_SECTION,
} from '../../../../../shared/graphQL/mutations';
import {
  GET_ADDRESSES_SECTION,
  GET_ADDRESS_TYPES,
  GET_COUNTRIES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_PROVINCES_BY_COUNTRY,
  GET_SECTORS_BY_PROVINCE,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const MainOfficeAdressFormNew = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [formResponseId, setFormResponseId] = useState('');
  const [selectedAddressId, setSelectedAddressId] = useState('');
  const { data: countries } = useQuery(GET_COUNTRIES);
  const [getAddressTypes, addressTypes] = useLazyQuery(GET_ADDRESS_TYPES);
  const [getProvincesByCountry, provincesByCountry] = useLazyQuery(
    GET_PROVINCES_BY_COUNTRY
  );
  const [getSectorsByProvince, sectorsByProvince] = useLazyQuery(
    GET_SECTORS_BY_PROVINCE
  );
  const [isDomRepOffice, setIsDomRepOffice] = useState(false);
  const [saveAddress, { data: saveAddressResponse }] = useMutation(
    SAVE_ADDRESS,
    {
      refetchQueries: [
        {
          query: GET_ADDRESSES_SECTION,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [saveAddressesSection] = useMutation(SAVE_ADDRESSES_SECTION, {
    refetchQueries: [
      {
        query: GET_ADDRESSES_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });

  const [removeAddress] = useMutation(REMOVE_ADDRESSES, {
    refetchQueries: [
      {
        query: GET_ADDRESSES_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });

  const [getAddressesSectionData, addressesSectionData] = useLazyQuery(
    GET_ADDRESSES_SECTION
  );
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [addressesSectionId, setAddressesSectionId] = useState('');
  const [alreadyMainOffice, setAlreadyMainOffice] = useState(false);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [nombrePais, setNombrePais] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const { typeObligatedSubject, isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const [loadingSection, setLoadingSection] = useState(true);

  const [pais, setPais] = useState(null);
  const [inputPais, setInputPais] = useState('');
  const [provincia, setProvincia] = useState(null);
  const [inputProvincia, setInputProvincia] = useState('');
  const [sector, setSector] = useState(null);
  const [inputSector, setInputSector] = useState('');
  const calle = watch('calle');
  const residencial = watch('residencial');
  const numeroApartamento = watch('numeroApartamento');
  const casaNumero = watch('casaNumero');
  const tipoDireccion = watch('tipoDireccion');
  const zipCode = watch('zipCode');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });

      getAddressTypes({
        variables: {
          personTypeId: selectedFormResponse?.form?.personType?.id,
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (pais) {
      const option = countries?.countries.find((x) => x.id === pais);
      setNombrePais(option?.name);

      getProvincesByCountry({
        variables: {
          countryId: pais?.id,
        },
      });
    }
  }, [pais]);

  useEffect(() => {
    if (provincesByCountry?.data?.provincesByCountry.length > 0) {
      getSectorsByProvince({
        variables: {
          provinceId: provincia?.id,
        },
      });
    }
  }, [provincesByCountry, provincia]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );
      getAddressesSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (addressesSectionData?.data?.addressesSection) {
      verifyMainOfficceExists();
      verifyDomRepOfficceExists();
      setAddressesSectionId(addressesSectionData?.data?.addressesSection?.id);
    }
  }, [addressesSectionData]);

  useEffect(() => {
    if (saveAddressResponse?.saveAddress) {
      const addressId = saveAddressResponse?.saveAddress?.id;
      const addressIds = [];
      const addresses = addressesSectionData?.data?.addressesSection?.addresses;
      let data = {};

      if (addresses !== null && addresses !== undefined) {
        for (let address of addresses) {
          addressIds.push(address.id);
        }

        addressIds.push(addressId);
      } else {
        addressIds.push(addressId);
      }

      if (addressesSectionId && addressesSectionId !== '') {
        data = {
          addressIds: addressIds,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: addressesSectionId,
        };
      } else {
        data = {
          addressIds: addressIds,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        };
      }
      saveAddressesSection({
        variables: {
          addressesSection: data,
        },
      });
    }
  }, [saveAddressResponse]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedAddressId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const verifyMainOfficceExists = () => {
    const mainOffice =
      addressesSectionData?.data?.addressesSection?.addresses?.some(
        (elem) => elem?.addressType?.name === 'Oficina Principal'
      );
    if (mainOffice === true) {
      setAlreadyMainOffice(true);
    } else {
      setAlreadyMainOffice(false);
    }
  };

  const verifyDomRepOfficceExists = () => {
    const domRepOffice =
      addressesSectionData?.data?.addressesSection?.addresses?.some((elem) =>
        elem.country.name.toLowerCase().includes('dominicana')
      );

    setIsDomRepOffice(domRepOffice);
  };

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/descripcion-del-negocio'
    );
  };

  const navigateToNextPage = () => {
    if (sessionStorage.getItem('obligatedSubject') || typeObligatedSubject) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/sujeto-obligado'
      );
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/principales-accionistas'
      );
    }
  };

  const addAddress = () => {
    const addressTypeSelected = addressTypes?.data?.addressTypes.find(
      (x) => x.id === tipoDireccion
    );
    const addressSaved =
      addressesSectionData?.data?.addressesSection?.addresses?.find(
        (x) => x.id === selectedAddressId
      );

    const isUpdatingMainOffice =
      addressTypeSelected?.id === addressSaved?.addressType?.id;

    if (
      (addressTypeSelected?.name !== 'Oficina Principal' &&
        alreadyMainOffice === true) ||
      (addressTypeSelected?.name === 'Oficina Principal' &&
        alreadyMainOffice === false) ||
      (addressTypeSelected?.name !== 'Oficina Principal' &&
        alreadyMainOffice === false) ||
      isUpdatingMainOffice
    ) {
      btnCloseModalRef.current.click();

      let data = {};

      //Si addressesSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
      if (selectedAddressId && selectedAddressId !== '') {
        if (
          provincesByCountry?.data?.provincesByCountry.length > 0 &&
          sectorsByProvince?.data?.sectorsByProvince?.length > 0
        ) {
          data = {
            id: selectedAddressId,
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        } else if (provincesByCountry?.data?.provincesByCountry.length > 0) {
          data = {
            id: selectedAddressId,
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        } else if (sectorsByProvince?.data?.sectorsByProvince?.length > 0) {
          data = {
            id: selectedAddressId,
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        } else {
          data = {
            id: selectedAddressId,
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        }

        setSelectedAddressId('');
      } else {
        if (
          provincesByCountry?.data?.provincesByCountry.length > 0 &&
          sectorsByProvince?.data?.sectorsByProvince?.length > 0
        ) {
          data = {
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        } else if (provincesByCountry?.data?.provincesByCountry.length > 0) {
          data = {
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        } else if (sectorsByProvince?.data?.sectorsByProvince?.length > 0) {
          data = {
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        } else {
          data = {
            apartmentNumber: numeroApartamento,
            building: residencial,
            houseNumber: casaNumero,
            provinceId: provincia?.id,
            sectorId: sector?.id,
            street: calle,
            countryId: pais?.id,
            addressTypeId: tipoDireccion,
            zipCode: zipCode,
          };
        }
      }

      saveAddress({
        variables: {
          address: data,
        },
      });

      if (nombrePais === 'República Dominicana') {
        setIsDomRepOffice(true);
      }
      resetAllInputs();
    } else {
      toast('Solo se puede agregar una dirección de tipo Oficina Principal.', {
        type: 'error',
        theme: 'colored',
      });
    }
  };

  const deleteCardElement = (id) => {
    removeAddress({
      variables: {
        addressId: id,
      },
    });

    resetAllInputs();
  };

  const handleEdit = (id) => {
    setSelectedAddressId(id);
    const selectedAddress =
      addressesSectionData?.data?.addressesSection?.addresses?.find(
        (x) => x.id === id
      );
    if (id && selectedAddress) {
      setValue('numeroApartamento', selectedAddress?.apartmentNumber);
      if (selectedAddress.country?.id) {
        setValue('pais', {
          label: selectedAddress.country?.name,
          id: selectedAddress.country?.id,
        });
        setPais({
          label: selectedAddress.country?.name,
          id: selectedAddress.country?.id,
        });
      }
      if (selectedAddress?.province?.id) {
        setValue('provincia', {
          label: selectedAddress?.province?.name,
          id: selectedAddress?.province?.id,
        });
        setProvincia({
          label: selectedAddress?.province?.name,
          id: selectedAddress?.province?.id,
        });
      }
      if (selectedAddress?.sector?.id) {
        setValue('sector', {
          label: selectedAddress?.sector?.name,
          id: selectedAddress?.sector?.id,
        });
        setSector({
          label: selectedAddress?.sector?.name,
          id: selectedAddress?.sector?.id,
        });
      }
      setValue('calle', selectedAddress?.street);
      setValue('residencial', selectedAddress?.building);
      setValue('casaNumero', selectedAddress?.houseNumber);
      setValue('tipoDireccion', selectedAddress?.addressType.id);
      setValue('zipCode', selectedAddress?.zipCode);
    }
  };

  const currentAdress = () => {
    return addressesSectionData?.data?.addressesSection?.addresses?.find(
      (add) => add.id === selectedAddressId
    );
  };

  const representativeAddress = () => {
    const current = currentAdress();
    if (current?.addressType?.name === 'Dirección del representante') {
      return [current?.addressType, ...addressTypes?.data?.addressTypes];
    } else {
      return addressTypes?.data?.addressTypes;
    }
  };

  const resetAllInputs = () => {
    reset();
    setInputPais('');
    setPais('');
    setInputProvincia('');
    setProvincia('');
    setInputSector('');
    setSector('');
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'addressSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Direcciones
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Direcciones'}
              description={
                "El formulario 'Direcciones' sirve como un espacio centralizado para agregar y gestionar las distintas ubicaciones asociadas a la empresa. Esta sección permite la inclusión de múltiples direcciones, proporcionando una visión completa de la presencia geográfica de la empresa. El objetivo es registrar de manera organizada las diversas localidades vinculadas a la entidad. Así, se facilita la gestión eficiente de información crucial relacionada con la distribución geográfica de la empresa en distintas ubicaciones."
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'addresses'
                      ? 'Es necesario que agregues por lo menos una dirección, preferiblemente una principal.'
                      : `Hay una o varias direcciones con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <>
              <div className="add-card-container">
                {addressesSectionData?.data?.addressesSection?.addresses?.map(
                  (address) => {
                    return (
                      <div
                        key={address?.id}
                        data-bs-toggle={isUpdating ? 'modal' : ''}
                        id={address?.id}
                        data-bs-target={isUpdating ? '#exampleModal' : ''}
                      >
                        <CardItems
                          nameElement={address?.country?.name}
                          identityDocument={address?.province?.name}
                          idElement={address?.id}
                          deleteElement={deleteCardElement}
                          handleEdit={handleEdit}
                          setIsUpdating={setIsUpdating}
                          showDeleteButton={!isFormCompleted && !isFormDisqualified}
                          detalle={
                            address?.addressType?.name?.includes('Principal') ||
                            address?.addressType?.name ===
                              'Dirección del representante'
                              ? address?.addressType?.name
                              : null
                          }
                        />
                      </div>
                    );
                  }
                )}
                {!isFormCompleted && !isFormDisqualified ? (
                  <div
                    className="add-card"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={() => {
                      resetAllInputs();
                      setSelectedAddressId('');
                    }}
                  >
                    <div className="image-icon-container">
                      <FontAwesomeIcon icon={faAdd} className="image-icon" />
                    </div>
                    <h4 className="bk-card-title">Agregar Dirección</h4>
                  </div>
                ) : null}
              </div>

              {alreadyMainOffice === false ? (
                <div className="w-100 d-flex  mt-3 p-0 -mb-0">
                  <p className="bff-sub-title">
                    * Debe ingresar la dirección de la oficina principal
                  </p>
                </div>
              ) : null}

              {isDomRepOffice === false ? (
                <div className="w-100 d-flex">
                  <p className="bff-sub-title">
                    * Debe ingresar la dirección de la oficina en la República
                    Dominicana
                  </p>
                </div>
              ) : null}
            </>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="button"
            className="btn-continue"
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form
              onSubmit={handleSubmit(addAddress)}
              className="fields-modal-container"
            >
              <div className="fields-modal-header">
                <h4 className="modal-title" id="exampleModalLabel">
                  {!isFormCompleted && !isFormDisqualified
                    ? selectedAddressId
                      ? 'Editar'
                      : 'Agregar'
                    : 'Ver'}{' '}
                  Dirección
                </h4>
                <button
                  type="button"
                  ref={btnCloseModalRef}
                  onClick={() => {
                    resetAllInputs();
                    setSelectedAddressId('');
                  }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="row fields-modal-content">
                <div className="row">
                  <div className="field-form col-md-6 col-sm-12">
                    <label htmlFor="tipoDireccion" className="label-field">
                      Tipo de Dirección <span>*</span>
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="tipoDireccion"
                        disabled={
                          (isFormCompleted || isFormDisqualified) ||
                          currentAdress()?.addressType?.name ===
                            'Dirección del representante'
                        }
                        defaultValue={'DEFAULT'}
                        {...register('tipoDireccion', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {representativeAddress()?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.tipoDireccion && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                    {selectedAddressId &&
                      (!tipoDireccion ||
                        !errors.tipoDireccion === 'DEFAULT') && (
                        <ErrorText hasError={hasError('addressType')} />
                      )}
                  </div>

                  <div className="field-form col-md-6 col-sm-12 mt-1 special-autocomplete">
                    <label htmlFor="pais" className="label-field">
                      País <span>*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="controllable-states-demo"
                      value={pais}
                      disabled={isFormCompleted || isFormDisqualified}
                      onChange={(event, newValue) => {
                        setPais(newValue);
                      }}
                      inputValue={inputPais}
                      onInputChange={(event, newInputValue) => {
                        setInputPais(newInputValue);
                      }}
                      options={
                        countries?.countries?.length
                          ? countries?.countries?.map((type) => ({
                              label: type.name,
                              id: type.id,
                            }))
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          placeholder={
                            countries?.countries?.length
                              ? 'Busca y selecciona'
                              : 'Cargando...'
                          }
                          {...params}
                          {...register('pais', { required: true })}
                        />
                      )}
                    />
                    <p
                      className="text-danger"
                      style={{
                        marginTop: 8,
                        visibility: errors.pais ? 'visible' : 'hidden',
                      }}
                    >
                      Debe seleccionar una opción
                    </p>
                    {selectedAddressId && !pais && !errors.pais && (
                      <ErrorText hasError={hasError('country')} />
                    )}
                  </div>

                  <div className="field-form col-md-6 col-sm-12 special-autocomplete">
                    <label htmlFor="provincia" className="label-field">
                      Provincia <span>*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="controllable-states-demo"
                      value={provincia}
                      disabled={isFormCompleted || isFormDisqualified}
                      onChange={(event, newValue) => {
                        setProvincia(newValue);
                      }}
                      inputValue={inputProvincia}
                      onInputChange={(event, newInputValue) => {
                        setInputProvincia(newInputValue);
                      }}
                      options={
                        provincesByCountry?.data?.provincesByCountry?.length
                          ? provincesByCountry?.data?.provincesByCountry?.map(
                              (type) => ({ label: type.name, id: type.id })
                            )
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          placeholder="Busca y selecciona"
                          {...params}
                          {...register('provincia', { required: true })}
                        />
                      )}
                    />
                    <p
                      className="text-danger"
                      style={{
                        marginTop: 8,
                        visibility: errors.provincia ? 'visible' : 'hidden',
                      }}
                    >
                      Debe seleccionar una opción
                    </p>
                    {!pais && !errors.provincia && (
                      <p className="text-danger text-stook">
                        Seleccione primero un país
                      </p>
                    )}
                    {selectedAddressId && !provincia && !errors.provincia && (
                      <div className="mt-5">
                        <ErrorText hasError={hasError('province')} />
                      </div>
                    )}
                  </div>

                  <div className="field-form col-md-6 col-sm-12 special-autocomplete">
                    <label htmlFor="sector" className="label-field">
                      Municipio o sector <span>*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="controllable-states-demo"
                      value={sector}
                      disabled={isFormCompleted || isFormDisqualified}
                      onChange={(event, newValue) => {
                        setSector(newValue);
                      }}
                      inputValue={inputSector}
                      onInputChange={(event, newInputValue) => {
                        setInputSector(newInputValue);
                      }}
                      options={
                        sectorsByProvince?.data?.sectorsByProvince?.length
                          ? sectorsByProvince?.data?.sectorsByProvince?.map(
                              (type) => ({ label: type.name, id: type.id })
                            )
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          placeholder="Busca y selecciona"
                          {...params}
                          {...register('sector', { required: true })}
                        />
                      )}
                    />
                    <p
                      className="text-danger"
                      style={{
                        marginTop: 8,
                        visibility: errors.sector ? 'visible' : 'hidden',
                      }}
                    >
                      Debe seleccionar una opción
                    </p>
                    {!provincia && !errors.sector && (
                      <p className="text-danger text-stook">
                        Seleccione primero una provincia
                      </p>
                    )}
                    {selectedAddressId && !sector && !errors.sector && (
                      <div className="mt-5">
                        <ErrorText hasError={hasError('sector')} />
                      </div>
                    )}
                  </div>

                  <div className="field-form col-md-6 col-sm-12">
                    <label htmlFor="calle" className="label-field">
                      Calle <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="calle"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('calle', {
                        required: true,
                      })}
                    />
                    {errors.calle?.type === 'required' && (
                      <p className="text-danger">El campo Calle es requerido</p>
                    )}
                    {selectedAddressId &&
                      !calle &&
                      errors.calle?.type !== 'required' && (
                        <ErrorText hasError={hasError('street')} />
                      )}
                  </div>

                  <div className="field-form col-md-6 col-sm-12">
                    <label htmlFor="casaNumero" className="label-field">
                      Número de Casa <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="casaNumero"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('casaNumero', {
                        required: true,
                      })}
                    />
                    {errors.casaNumero?.type === 'required' && (
                      <p className="text-danger">
                        El campo Número de Casa es requerido
                      </p>
                    )}
                    {selectedAddressId &&
                      !casaNumero &&
                      errors.casaNumero?.type !== 'required' && (
                        <ErrorText hasError={hasError('houseNumber')} />
                      )}
                  </div>

                  <div className="field-form col-md-6 col-sm-12">
                    <label htmlFor="residencial" className="label-field">
                      Nombre Edificio{' '}
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="residencial"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('residencial')}
                    />
                  </div>

                  <div className="field-form col-md-6 col-sm-12">
                    <label htmlFor="numeroApartamento" className="label-field">
                      Apartamento{' '}
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="numeroApartamento"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('numeroApartamento')}
                    />
                  </div>

                  <div className="field-form col-md-6 col-sm-12">
                    <label htmlFor="zipCode" className="label-field">
                      Código Postal <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="zipCode"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('zipCode', { required: true })}
                    />
                    {errors.zipCode?.type === 'required' && (
                      <p className="text-danger">
                        El campo Código Postal requerido
                      </p>
                    )}
                    {selectedAddressId &&
                      !zipCode &&
                      errors.zipCode?.type !== 'required' && (
                        <ErrorText hasError={hasError('zipCode')} />
                      )}
                  </div>
                </div>
              </div>

              {!isFormCompleted && !isFormDisqualified ? (
                <div className="row d-flex justify-content-center">
                  <button type="submit" className="mt-5 mb-5 btn-add">
                    Guardar
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainOfficeAdressFormNew;
