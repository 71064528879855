import React, { useEffect, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { GET_NOTE_CREATED } from '../../../shared/graphQL/subscriptions';
import { Notifications } from '@mui/icons-material';
import { useNotification } from '../../../context/NotificationContext';
import { useNavigate } from 'react-router-dom';
import './NotificationIcon.css';

const NotificationIcon = () => {
  const navigate = useNavigate();
  const { resetRedDot, showRedDot } = useNotification();

  const handleClick = () => {
    resetRedDot();
    navigate('/onboarding-digital/dashboard/notificaciones');
  };

  return (
    <div
      className={`am-campaign${showRedDot ? ' has-red-dot' : ''}`}
      onClick={handleClick}
    >
      <Notifications />
      {showRedDot && <span className="am-campaign__red-dot" />}
    </div>
  );
};

export default NotificationIcon;
