import './OtherIncomeForm.css';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
} from '@fortawesome/free-solid-svg-icons';
import { GET_ECONOMIC_ACTIVITIES } from '../../../../../shared/graphQL/queries';
import { useQuery } from '@apollo/client';

const OtherIncomeForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [incomes, setIncomes] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [disabledButton, setDisabledButton] = useState(false);
  const { data: economicActivities } = useQuery(GET_ECONOMIC_ACTIVITIES);

  const concepto = watch('concepto');
  const monto = watch('monto');
  const divisas = watch('divisas');

  useEffect(() => {
    //getIncomes();
  }, []);

  const getIncomes = () => {};

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const navigateToNextPage = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/origen-de-los-fondos");
  };

  const addIncome = () => {
    btnCloseModalRef.current.click();
    reset();
  };

  const deleteCardElement = (id) => {};

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="bff-title">Otros Ingresos</h3>

          <div className="add-card-container">
            <div
              className="add-card"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <div className="image-icon-container">
                <FontAwesomeIcon icon={faAdd} className="image-icon" />
              </div>
              <h4 className="bk-card-title">Agregar ingreso</h4>
            </div>
          </div>

          <div className="cards-container-add-element">
            {incomes.map((bankAccount, index) => {
              return (
                <CardItems
                  key={index}
                  nameElement={bankAccount.name}
                  identityDocument={bankAccount.accountNumber}
                  idElement={bankAccount.id}
                  deleteElement={deleteCardElement}
                />
              );
            })}
          </div>

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addIncome)}
                  className="fields-modal-container"
                >
                  <div className="fields-modal-header">
                    <h4 className="modal-title fs-5" id="exampleModalLabel">
                      Agregar ingreso
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      className="btn-close"
                      onClick={() => reset()}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="row fields-modal-content">
                    <div className="col-sm-12">
                      <div className="field-form">
                        <label htmlFor="concepto" className="label-field">
                          Concepto <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="concepto"
                          {...register('concepto', {
                            required: true,
                          })}
                        />
                        {errors.concepto?.type === 'required' && (
                          <p className="text-danger">
                            El campo Concepto es requerido
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label htmlFor="monto" className="label-field">
                          Monto <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="input-form"
                          placeholder=""
                          id="monto"
                          {...register('monto', {
                            required: true,
                          })}
                        />
                        {errors.monto?.type === 'required' && (
                          <p className="text-danger">
                            El campo Monto es requerido
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label htmlFor="divisas" className="label-field">
                          Divisa <span>*</span>
                        </label>
                        <select
                          id="divisas"
                          defaultValue={'DEFAULT'}
                          {...register('divisas', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {currencies.map((currency) => (
                            <option key={currency.id} value={currency.id}>
                              {currency.name}
                            </option>
                          ))}
                        </select>
                        {errors.divisas && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="actividad-economica"
                          className="label-field"
                        >
                          Actividad económica <span>*</span>
                        </label>
                        <select
                          id="actividad-economica"
                          defaultValue={'DEFAULT'}
                          {...register('actividadEconomica', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {economicActivities?.economicActivities?.map(
                            (economicActivity) => (
                              <option
                                key={economicActivity.id}
                                value={economicActivity.id}
                              >
                                {economicActivity.name}
                              </option>
                            )
                          )}
                        </select>
                        {errors.actividadEconomica && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex justify-content-center">
                    <button type="submit" className="mt-5 mb-5 btn-add">
                      Guardar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            disabled={disabledButton}
            className={disabledButton ? 'button-disabled' : 'btn-continue'}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default OtherIncomeForm;
