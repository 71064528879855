import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  progressBar: {
    show: false,
    value: '0%',
  },
  configPassword: null,
  load: null,
  verified: false,
  primaryContact: false,
  confetti: false,
  isMainOffice: false,
  typeObligatedSubject: null,
  selectedSection: '',
  isLogged: false,
  emailInOnboarding: '',
  passwordInOnboarding: '',
  perfilObtenido: '',
  accessToken: null,
  hasInvestmentProfile: false,
  isContrapart: false,
  isTreasury: false,
  isListedOnStock: false,
  isForeign: false,
  hasBCRDAccount: false,
  companyName: null,
  persons: {
    legalRepresentatives: [],
    shareholders: [],
    signatores: [],
    beneficiaries: [],
    counselMembers: [],
  },
  isFormCompleted: false,
  isFormDisqualified: false,
  phase: {
    id: null,
    name: null,
  },
  formResponse: null,
  validation: null,
  requestFormComplete: false,
  requestFormCompletionLoading: false,
};

export const clientePotencialSlice = createSlice({
  name: 'clientePotencial',
  initialState,
  reducers: {
    setProgressBar: (state, action) => {
      state.progressBar.show = action.payload.show;
      state.progressBar.value = action.payload.value;
    },
    setConfigPassword: (state, action) => {
      state.configPassword = action.payload;
    },
    setVerified: (state, action) => {
      state.verified = action.payload;
    },
    setConfetti: (state, action) => {
      state.confetti = action.payload;
    },
    setPrimaryContact: (state, action) => {
      state.primaryContact = action.payload;
    },
    setIsMainOffice: (state, action) => {
      state.isMainOffice = action.payload;
    },
    setTypeObligatedSubject: (state, action) => {
      state.typeObligatedSubject = action.payload;
    },
    setSelectedSection: (state, action) => {
      state.selectedSection = action.payload;
    },
    setIslogged: (state, action) => {
      state.isLogged = action.payload;
    },
    setEmailInOnboarding: (state, action) => {
      state.emailInOnboarding = action.payload;
    },
    setPasswordInOnboarding: (state, action) => {
      state.passwordInOnboarding = action.payload;
    },
    setPerfilObtenido: (state, action) => {
      state.perfilObtenido = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setHasInvestmentProfile: (state, action) => {
      state.hasInvestmentProfile = action.payload;
    },
    setIsTreasury: (state, action) => {
      state.isTreasury = action.payload;
    },
    setIsContrapart: (state, action) => {
      state.isContrapart = action.payload;
    },
    setIsListedOnStock: (state, action) => {
      state.isListedOnStock = action.payload;
    },
    setIsForeign: (state, action) => {
      state.isForeign = action.payload;
    },
    setHasBCRDAccount: (state, action) => {
      state.hasBCRDAccount = action.payload;
    },
    setPersons: (state, action) => {
      state.persons = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setIsFormCompleted: (state, action) => {
      state.isFormCompleted = action.payload;
    },
    setPhase: (state, action) => {
      state.phase = action.payload;
    },
    setFormResponse: (state, action) => {
      state.formResponse = action.payload;
    },
    setValidation: (state, action) => {
      state.validation = action.payload;
    },
    setRequestFormComplete: (state, action) => {
      state.requestFormComplete = action.payload;
    },
    setIsFormDisqualified: (state, action) => {
      state.isFormDisqualified = action.payload;
    },
    setRequestFormCompletionLoading: (state, action) => {
      state.requestFormCompletionLoading = action.payload;
    }
  },
});

export const {
  setProgressBar,
  setConfigPassword,
  setVerified,
  setConfetti,
  setPrimaryContact,
  setIsMainOffice,
  setTypeObligatedSubject,
  setSelectedSection,
  setIslogged,
  setEmailInOnboarding,
  setPasswordInOnboarding,
  setPerfilObtenido,
  setAccessToken,
  setHasInvestmentProfile,
  setIsTreasury,
  setIsContrapart,
  setIsListedOnStock,
  setIsForeign,
  setHasBCRDAccount,
  setPersons,
  setCompanyName,
  setIsFormCompleted,
  setPhase,
  setFormResponse,
  setValidation,
  setRequestFormComplete,
  setIsFormDisqualified,
  setRequestFormCompletionLoading,
} = clientePotencialSlice.actions;

export default clientePotencialSlice.reducer;

export const loadPersons = (
  dispatch,
  { legalRepresentatives, shareholders, signatores, beneficiaries, counselMembers, }
) => {
  dispatch(
    setPersons({
      legalRepresentatives,
      shareholders,
      signatores,
      beneficiaries,
      counselMembers,
    })
  );
};
