import { Link, useLocation } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import './Sidebar.css';
import { CLIENTEPOTENCIALROUTES } from '../../../shared/constants/app-constants';
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  TimelineOutlined,
  BookOutlined,
  DateRangeOutlined,
  ExitToAppOutlined,
  MailOutline,
  FileCopyOutlined,
  NoteAltOutlined,
} from '@mui/icons-material';
import { CLIENTEPOTENCIALORIGINROUTE } from '../../../pages/ClientePotencial/Index/Index';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import AccountManager from '../AccountManager/AccountManager';
import {
  envars,
  handleClientePotencialLogOut,
} from '../../../shared/utils/functions';
import { useSubscription } from '@apollo/client';
import { GET_NOTE_CREATED } from '../../../shared/graphQL/subscriptions';
import { useNotification } from '../../../context/NotificationContext';
import {
  azureUserFlowUri,
  CUSTOMERSERVICECONTACT,
  SOCIALNETWORKS,
} from '../../../shared/constants/gobal-constants';

const CHILDRENROUTES =
  CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.children;
const DASHBOARDROUTE = CLIENTEPOTENCIALROUTES.protectedRoutes.dashboard.self;

const Sidebar = () => {
  const [minimizeSidebar, setMinimizeSidebar] = useState(true);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const location = useLocation();

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  const isRoute = (route) => {
    const base = CLIENTEPOTENCIALORIGINROUTE + DASHBOARDROUTE + '/';
    return location.pathname.includes(base + route)
      ? 'active-potencial-client'
      : '';
  };

  const handleClientePLogOut = () => {
    handleClientePotencialLogOut();
    window.location.replace(azureUserFlowUri());
  };
  const { notifyInfo } = useNotification();

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  const { data, loading } = useSubscription(GET_NOTE_CREATED, {
    variables: { entityId: selectedFormResponse?.lead?.identification },
  });

  useEffect(() => {
    if (!loading && data && data.noteCreated) {
      const newNote = data.noteCreated;
      notifyInfo(
        `Tienes una nueva nota de parte de ${
          newNote.author.firstName + ' ' + newNote.author.lastName
        }`
      );
    }
  }, [data, loading]);

  return (
    <div className={'sidebar' + (minimizeSidebar ? ' close' : '')}>
      <div className="minimize-sidebar-btn">
        <div
          className="sub-minimize-sidebar-btn cursor-pointer"
          onClick={() => setMinimizeSidebar(!minimizeSidebar)}
        >
          <span>
            {minimizeSidebar === true ? (
              <FontAwesomeIcon icon={faAngleRight} />
            ) : (
              <FontAwesomeIcon icon={faAngleLeft} />
            )}
          </span>
        </div>
      </div>

      {minimizeSidebar === false ? (
        <>
          <Link
            to="/onboarding-digital/dashboard/resumen-de-cuentas"
            style={{ width: 'fit-content' }}
          >
            <img
              className="potencial-sidebar-logoParval"
              alt="logo"
              src="/logo.png"
            />
          </Link>

          <div className="potencial-client-dashboard-menu-container">
            <AccountManager />

            <ul className="potencial-client-dashboard-menu">
              <li>
                <Link
                  to={
                    CHILDRENROUTES.accountOpening.name +
                    `${selectedFormResponse?.form?.personType?.name === 'Persona Jurídica' ? '/formularios/persona-juridica/informacion-general' : '/formularios/persona-fisica/datos-generales'}`
                  }
                  className={isRoute(CHILDRENROUTES.accountOpening.name)}
                >
                  <NoteAltOutlined /> <span>Datos de Cuenta</span>
                </Link>
              </li>
              <li>
                <Link
                  to={CHILDRENROUTES.rateProducts}
                  className={isRoute(CHILDRENROUTES.rateProducts)}
                >
                  <TimelineOutlined /> <span>Tasas y Productos</span>
                </Link>
              </li>
              <li>
                <Link to={CHILDRENROUTES.guides} className={isRoute()}>
                  <BookOutlined /> <span>Guías e Instructivos</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="potencial-sidebar-contact">
            <p>Contacte servicio al cliente</p>
            <p>{CUSTOMERSERVICECONTACT.telephone}</p>
            <p
              className="cursor-pointer"
              title={`Correo: ${CUSTOMERSERVICECONTACT.email}`}
              onClick={() =>
                window.open(`mailTo:${CUSTOMERSERVICECONTACT.email}`)
              }
            >
              {CUSTOMERSERVICECONTACT.email}
            </p>
            <div className="potencial-sidebar-social-media">
              <Nav className="justify-content-center">
                <Nav.Item>
                  <Nav.Link
                    className="px-2 media"
                    href={SOCIALNETWORKS.facebook}
                  >
                    <Facebook fontSize="small" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="px-2 media"
                    href={SOCIALNETWORKS.instagram}
                  >
                    <Instagram fontSize="small" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="px-2 media"
                    href={SOCIALNETWORKS.linkedin}
                  >
                    <LinkedIn fontSize="small" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="px-2 media"
                    href={SOCIALNETWORKS.twitter}
                  >
                    <Twitter fontSize="small" />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>

          <div
            className="cliente-potencial-logout-container"
            onClick={() => {
              handleClientePLogOut();
            }}
          >
            <div className="cliente-potencial-logout">
              <ExitToAppOutlined />
              <span className="ms-2">Cerrar Sesión</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <Link to="/onboarding-digital/dashboard/resumen-de-cuentas">
            <img
              className="minimize-potencial-sidebar-logoParval"
              alt="logo"
              src="/images/logoIcon.png"
            />
          </Link>

          <ul className="minimize-potencial-client-dashboard-menu">
            <li>
              <Link
                to={
                  CHILDRENROUTES.accountOpening.name +
                  `${selectedFormResponse?.form?.personType?.name === 'Persona Jurídica' ? '/formularios/persona-juridica/informacion-general' : '/formularios/persona-fisica/datos-generales'}`
                }
                className={isRoute(CHILDRENROUTES.accountOpening.name)}
              >
                <NoteAltOutlined style={{ fontSize: 25 }} />
              </Link>
            </li>
            {/* <li>
              <Link
                to={CHILDRENROUTES.documentRepository}
                className={isRoute(CHILDRENROUTES.documentRepository)}
              >
                <FileCopyOutlined style={{ fontSize: 25 }} />
              </Link>
            </li>
            <li>
              <Link to={CHILDRENROUTES.makeAppointment} className={isRoute()}>
                <DateRangeOutlined style={{ fontSize: 25 }} />
              </Link>
            </li>
            <li>
              <Link to={CHILDRENROUTES.requestCerts} className={isRoute()}>
                <MailOutline style={{ fontSize: 25 }} />
              </Link>
            </li> */}
            <li>
              <Link
                to={CHILDRENROUTES.rateProducts}
                className={isRoute(CHILDRENROUTES.rateProducts)}
              >
                <TimelineOutlined style={{ fontSize: 25 }} />
              </Link>
            </li>
            <li>
              <Link to={CHILDRENROUTES.guides} className={isRoute()}>
                <BookOutlined style={{ fontSize: 25 }} />
              </Link>
            </li>
          </ul>

          <div style={{ position: 'fixed', bottom: 20, width: 'fit-content' }}
            className="cliente-potencial-logout-container"
            onClick={() => {
              handleClientePLogOut();
            }}
          >
            <div className="cliente-potencial-logout">
              <ExitToAppOutlined style={{ fontSize: 30 }} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
