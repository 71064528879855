import { Route, Routes } from 'react-router-dom';
import HistoryOperations from '../HistoryOperations';
import Registration from '../Registration/ResistrationPage/Registration';
import Profile from '../Profile';
import PasswordRecovery from '../PasswordRecovery/PasswordRecovery';
import IdentityVerification from '../IdentityVerification/IdentityVerification.jsx';
import SelectAccountType from '../SelectAccountType/SelectAccountType';
import RequestWayToFillForm from '../RequestWayToFillForm/RequestWayToFillForm';
import BasicFormFieldsPage from '../FormPages/Physical person/BasicFormFieldsPage/BasicFormFieldsPage';
import ResidenceInformationFormPage from '../FormPages/Physical person/ResidenceInformationFormPage/ResidenceInformationFormPage';
import ContactInformationFormPage from '../FormPages/Physical person/ContactInformationFormPage/ContactInformationFormPage';
import AccountTypesPage from '../FormPages/Reusable pages/AccountTypesPage/AccountTypesPage';
import BankAccountsPage from '../FormPages/Physical person/BankAccountsPage/BankAccountsPage';
import RegistrationDataRequest from '../RegistrationDataRequest/RegistrationDataRequest';
import {
  getAccessToken,
  isAuthenticated,
} from '../../../shared/utils/functions';
import MailSentNotificationPage from '../MailSentNotificationPage/MailSentNotificationPage';
import SelectBranchYourChoice from '../Registration/SelectBranchYourChoice/SelectBranchYourChoice';
import PasswordRecoveryRequestNewPassword from '../PasswordRecoveryRequestNewPassword/PasswordRecoveryRequestNewPassword';
import PasswordChangedNotificationPage from '../PasswordChangedNotificationPage/PasswordChangedNotificationPage';
import SelectSalesRepresentative from '../Registration/SelectSalesRepresentative/SelectSalesRepresentative';
import CommunicationsAndCorrespondencePage from '../FormPages/Legal person/CommunicationsAndCorrespondencePage/CommunicationsAndCorrespondencePage';
import ParvalMarketingPage from '../FormPages/Legal person/ParvalMarketingPage/ParvalMarketingPage';
import InvestorProfilePage from '../FormPages/Legal person/InvestorProfilePage/InvestorProfilePage';
import BankAccountDesignationPage from '../FormPages/Legal person/BankAccountDesignationPage/BankAccountDesignationPage';
import FatcaRelatedPage from '../FormPages/Legal person/FatcaRelatedPage/FatcaRelatedPage';
import AffidavitProvisionInformationPage from '../FormPages/Legal person/AffidavitProvisionInformationPage/AffidavitProvisionInformationPage';
import ErrorPage from '../ErrorPage/ErrorPage';
import ProtectedRoute from '../../../components/ClientePotencial/ProtectedRoute/ProtectedRoute';
import ParvalMarketingPagePhysicalPerson from '../FormPages/Physical person/ParvalMarketingPagePhysicalPerson/ParvalMarketingPagePhysicalPerson';
import NationalityInformationPage from '../FormPages/Physical person/NationalityInformationPage/NationalityInformationPage';
import PepPage from '../FormPages/Physical person/PepPage/PepPage';
import EmploymentStatusPage from '../FormPages/Physical person/EmploymentStatusPage/EmploymentStatusPage';
import OtherIncomePage from '../FormPages/Physical person/OtherIncomePage/OtherIncomePage';
import WorkAddressPage from '../FormPages/Physical person/WorkAddressPage/WorkAddressPage';
import ProxiesOrGuardiansPage from '../FormPages/Physical person/ProxiesOrGuardiansPage/ProxiesOrGuardiansPage';
import CommercialReferencesPage from '../FormPages/Reusable pages/CommercialReferencesPage/CommercialReferencesPage';
import FinalBeneficiaryPage from '../FormPages/Physical person/FinalBeneficiaryPage/FinalBeneficiaryPage';
import FatcaPage from '../FormPages/Physical person/FatcaPage/FatcaPage';
import ProfessionalClientPage from '../FormPages/Physical person/ProfessionalClientPage/ProfessionalClientPage';
import InvestorProfilePhysicalPersonPage from './../FormPages/Physical person/InvestorProfilePage/InvestorProfilePage';
import DistributionLiquidAssetsPage from '../FormPages/Physical person/DistributionLiquidAssetsPage/DistributionLiquidAssetsPage';
import FinancialInstrumentsPage from '../FormPages/Physical person/FinancialInstrumentsPage/FinancialInstrumentsPage';
import SourceOfFundsPage from '../FormPages/Physical person/SourceOfFundsPage/SourceOfFundsPage';
import ResultPage from '../FormPages/Physical person/ResultPage/ResultPage';
import RealDashboard from '../RealDashboard/RealDashboard';
import SubDashboard from '../SubDashboard/SubDashboard';
import GeneralInformationForm from '../../../components/ClientePotencial/forms/Legal person/GeneralInformationForm/GeneralInformationForm';
import BusinessDescriptionForm from '../../../components/ClientePotencial/forms/Legal person/BusinessDescriptionForm/BusinessDescriptionForm';
import SupplementaryDataForm from '../../../components/ClientePotencial/forms/Legal person/SupplementaryDataForm/SupplementaryDataForm';
import FinancialInstrumentsLpFForm from '../../../components/ClientePotencial/forms/Legal person/FinancialInstrumentsLpForm/FinancialInstrumentsLpForm';
import ProfessionalClientLpForm from '../../../components/ClientePotencial/forms/Legal person/ProfessionalClientLpForm/ProfessionalClientLpForm';
import BankAccountDesignationForm from '../../../components/ClientePotencial/forms/Legal person/BankAccountDesignationForm/BankAccountDesignationForm';
import DeclarationOfBeneficiariesForm from './../../../components/ClientePotencial/forms/Legal person/DeclarationOfBeneficiariesForm/DeclarationOfBeneficiariesForm';
import SignatureCardForm from '../../../components/ClientePotencial/forms/Legal person/SignatureCardForm/SignatureCardForm';
import MainShareholdersForm from '../../../components/ClientePotencial/forms/Legal person/MainShareholdersForm/MainShareholdersForm';
import AffiliatedCompaniesForm from '../../../components/ClientePotencial/forms/Legal person/AffiliatedCompaniesForm/AffiliatedCompaniesForm';
import LegalRepresentative from '../../../components/ClientePotencial/forms/Reusable forms/LegalRepresentative/LegalRepresentative';
import GeneralForm from '../../../components/ClientePotencial/forms/Legal person/GeneralForm/GeneralForm';
import MainContactInformationForm from '../../../components/ClientePotencial/forms/Legal person/MainContactInformationForm/MainContactInformationForm';
import ObjectivesRiskToleranceForm from '../../../components/ClientePotencial/forms/Legal person/ObjectivesRiskToleranceForm/ObjectivesRiskToleranceForm';
import TitleOperationsForm from '../../../components/ClientePotencial/forms/Legal person/TitleOperationsForm/TitleOperationsForm';
import OfficeAddressDominicanRepublicForm from './../../../components/ClientePotencial/forms/Legal person/OfficeAddressDominicanRepublicForm/OfficeAddressDominicanRepublicForm';
import DistributionLiquidAssetsLpFormNew from '../../../components/ClientePotencial/forms/Legal person/DistributionLiquidAssetsLpFormNew/DistributionLiquidAssetsLpFormNew';
import MainOfficeAdressFormNew from './../../../components/ClientePotencial/forms/Legal person/MainOfficeAdressFormNew/MainOfficeAdressFormNew';
import FinancialInstrumentsForm from '../../../components/ClientePotencial/forms/Physical person/FinancialInstrumentsForm/FinancialInstrumentsForm';
import SourceOfFundsForm from '../../../components/ClientePotencial/forms/Physical person/SourceOfFundsForm/SourceOfFundsForm';
import DistributionLiquidAssetsFormPhysicalPerson from '../../../components/ClientePotencial/forms/Physical person/DistributionLiquidAssetsForm/DistributionLiquidAssetsForm';
import InvestorProfilePhysicalPersonForm from '../../../components/ClientePotencial/forms/Physical person/InvestorProfileForm/InvestorProfileForm';
import GeneralDataPhysicalPerson from '../../../components/ClientePotencial/forms/Physical person/GeneralData/GeneralData';
import WorkingInformationForm from './../../../components/ClientePotencial/forms/Physical person/WorkingInformationForm/WorkingInformationForm';
import OtherIncomeForm from '../../../components/ClientePotencial/forms/Physical person/OtherIncomeForm/OtherIncomeForm';
import WorkAddressForm from '../../../components/ClientePotencial/forms/Physical person/WorkAddressForm/WorkAddressForm';
import PepForm from '../../../components/ClientePotencial/forms/Physical person/PepForm/PepForm';
import ParvalMarketingFormPhysicalPerson from '../../../components/ClientePotencial/forms/Physical person/ParvalMarketingFormPhysicalPerson/ParvalMarketingFormPhysicalPerson';
import InvestmentStatusForm from '../../../components/ClientePotencial/forms/Physical person/InvestmentStatusForm/InvestmentStatusForm';
import ResidentialAddressForm from './../../../components/ClientePotencial/forms/Physical person/ResidentialAddressForm/ResidentialAddressForm';
import RelatedPeopleForm from '../../../components/ClientePotencial/forms/Physical person/RelatedPeopleForm/RelatedPeopleForm';
import CommercialReferencesForm from '../../../components/ClientePotencial/forms/Reusable forms/CommercialReferencesForm/CommercialReferencesForm';
import LegalCommercialReferencesForm from '../../../components/ClientePotencial/forms/Legal person/CommercialReferencesForm/CommercialReferencesForm';
import BankAccounts from './../../../components/ClientePotencial/forms/Physical person/BankAccounts/BankAccounts';
import TypeOfApplicantForm from '../../../components/ClientePotencial/forms/Physical person/TypeOfApplicantForm/TypeOfApplicantForm';
import ProfessionalClientForm from '../../../components/ClientePotencial/forms/Physical person/ProfessionalClientForm/ProfessionalClientForm';
import TitleOperationsFpForm from '../../../components/ClientePotencial/forms/Physical person/TitleOperationsFpForm/TitleOperationsFpForm';
import CurrencyAndInvestmentForm from '../../../components/ClientePotencial/forms/Physical person/CurrencyAndInvestmentForm/CurrencyAndInvestmentForm';
import SubDasboardSummaryOfAccounts from '../SubDasboardSummaryOfAccounts/SubDasboardSummaryOfAccounts';
import Notes from '../Notes/Notes';
import PersonTypeAccountSummary from '../AccountSummaryPages/PersonTypeAccountSummary/PersonTypeAccountSummary';
import AssistanceUnitAccountSummary from '../AccountSummaryPages/AssistanceUnitAccountSummary/AssistanceUnitAccountSummary';
import BranchAccountSummary from '../AccountSummaryPages/BranchAccountSummary/BranchAccountSummary';
import BussinesRepresentativeAccountSummary from '../AccountSummaryPages/BussinesRepresentativeAccountSummary/BussinesRepresentativeAccountSummary';
import IdentityVerificationAccountSummary from '../AccountSummaryPages/IdentityVerificationAccountSummary/IdentityVerificationAccountSummary';
import SubDashboardDocumentsRepository from '../SubDashboardDocumentsRepository/SubDashboardDocumentsRepository.jsx';
import { CLIENTEPOTENCIALROUTES } from '../../../shared/constants/app-constants.js';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAccessToken } from '../../../shared/store/slices/clientePotencial.js';
import CompanyTypeAccountSummary from '../AccountSummaryPages/CompanyTypeAccountSummary/CompanyTypeAccountSummary.jsx';
import ObligatedSubjectForm from '../../../components/ClientePotencial/forms/Legal person/ObligatedSubjectForm/ObligatedSubjectForm.jsx';
import SubtypeAccountSummary from '../AccountSummaryPages/SubtypeAccountSummary/SubtypeAccountSummary.jsx';
import TasaProductos from '../RatesProducts/RatesProducts.jsx';
import SettlementInstructionForm from '../../../components/ClientePotencial/forms/Legal person/SettlementInstructionForm/SettlementInstructionForm.jsx';
import CounselMembersForm from '../../../components/ClientePotencial/forms/Legal person/CounselMembersForm/CounselMembersForm.jsx';
import CorrespondenceEmailForm from '../../../components/ClientePotencial/forms/Legal person/CorrespondenceEmailForm/CorrespondenceEmailForm.jsx';

const FATHERROUTE = CLIENTEPOTENCIALROUTES.father;
const INDEX = CLIENTEPOTENCIALROUTES.index;
export const CLIENTEPOTENCIALORIGINROUTE = INDEX + FATHERROUTE + INDEX;

const ClientePotencialApp = () => {
  const selector = useSelector((state) => state.clienteRealSlice);
  const dispatch = useDispatch();
  const { accessToken } = selector;

  if (!accessToken && getAccessToken()) {
    dispatch(setAccessToken(getAccessToken()));
  }

  return (
    <>
      <Routes>
        <Route path="/register" element={<Registration />} />
        <Route
          path="/register/select-branch-your-choice"
          element={<SelectBranchYourChoice />}
        />
        <Route
          path="/register/select-sales-representative"
          element={<SelectSalesRepresentative />}
        />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route
          path="/password-recovery-request-new-password"
          element={<PasswordRecoveryRequestNewPassword />}
        />
        <Route
          path="/mail-sent-notification"
          element={<MailSentNotificationPage />}
        />
        <Route
          path="/password-changed-notification"
          element={<PasswordChangedNotificationPage />}
        />

        <Route path="/pagina-de-error" element={<ErrorPage />} />
        <Route path="/select-account-type" element={<SelectAccountType />} />
        {/* These are the protected routes */}

        {/* <Route path="/onboarding-digital/dashboard/datos-de-la-cuenta/bienvenida" element={<SubDashboard />} /> */}

        <Route element={<ProtectedRoute />}>
          <Route
            path="/request-way-to-fill-form"
            element={<RequestWayToFillForm />}
          />
          <Route
            path="/identity-verification/:accountType"
            element={<IdentityVerification />}
          />

          <Route
            path="/onboarding-digital/dashboard/"
            element={<RealDashboard />}
          >
            <Route
              path="apertura-de-cuenta/"
              element={<SubDashboard creatingAccount={true} />}
            >
              {/* ============== RUTAS PARA LA CREACION DE NUEVAS CUENTAS ============== */}
              <Route
                path="tipo-de-persona"
                element={<PersonTypeAccountSummary />}
              />
              <Route
                path="unidad-de-asistencia"
                element={<AssistanceUnitAccountSummary />}
              />
              <Route
                path="tipo-solicitante"
                element={<SubtypeAccountSummary />}
              />
              <Route
                path="tipo-de-empresa"
                element={<CompanyTypeAccountSummary />}
              />
              <Route path="sucursal" element={<BranchAccountSummary />} />
              <Route
                path="representante-de-negocio"
                element={<BussinesRepresentativeAccountSummary />}
              />
              <Route
                path="verificacion-de-identidad"
                element={<IdentityVerificationAccountSummary />}
              />
            </Route>
            <Route
              path="datos-de-la-cuenta/"
              element={<SubDashboard creatingAccount={false} />}
            >
              {/* RUTAS DE PERSONA JURISICA */}
              {/* Routes of Information of my Company */}
              <Route
                path="formularios/persona-juridica/informacion-general"
                element={<GeneralInformationForm />}
              />
              <Route
                path="formularios/persona-juridica/instruccion-de-liquidacion"
                element={<SettlementInstructionForm />}
              />
              <Route
                path="formularios/persona-juridica/informacion-contacto-principal"
                element={<MainContactInformationForm />}
              />
              <Route
                path="formularios/persona-juridica/descripcion-del-negocio"
                element={<BusinessDescriptionForm />}
              />
              <Route
                path="formularios/persona-juridica/sujeto-obligado"
                element={<ObligatedSubjectForm />}
              />
              {/* <Route path="formularios/persona-juridica/direccion-oficina-principal" element={<MainOfficeAdressFormNew />}/> */}
              <Route
                path="formularios/persona-juridica/direcciones-de-oficinas"
                element={<MainOfficeAdressFormNew />}
              />
              {/* <Route path="formularios/persona-juridica/direccion-oficina-republica-dominicana" element={<OfficeAddressDominicanRepublicForm />}/> */}
              <Route
                path="formularios/persona-juridica/representante-legal"
                element={<LegalRepresentative />}
              />
              <Route
                path="formularios/persona-juridica/principales-accionistas"
                element={<MainShareholdersForm />}
              />
              <Route
                path="formularios/persona-juridica/empresas-afiliadas"
                element={<AffiliatedCompaniesForm />}
              />
              <Route
                path="formularios/persona-juridica/datos-complementarios"
                element={<SupplementaryDataForm />}
              />
              {/* Parval Client Information Routes */}
              <Route
                path="formularios/persona-juridica/cuentas-bancarias"
                element={<BankAccountDesignationForm />}
              />
              <Route
                path="formularios/persona-juridica/referencias-comerciales"
                element={<LegalCommercialReferencesForm />}
              />
              <Route
                path="formularios/persona-juridica/declaracion-de-beneficiarios"
                element={<DeclarationOfBeneficiariesForm />}
              />
              <Route
                path="formularios/persona-juridica/tarjeta-de-firmas"
                element={<SignatureCardForm />}
              />
              <Route
                path="formularios/persona-juridica/miembros-del-consejo"
                element={<CounselMembersForm />}
              />
              <Route
                path="formularios/persona-juridica/correos-de-correspondencia"
                element={<CorrespondenceEmailForm />}
              />
              {/* Routes of Financial Information and Investments */}
              <Route
                path="formularios/persona-juridica/generales"
                element={<GeneralForm />}
              />
              <Route
                path="formularios/persona-juridica/objetivos-tolerancia-al-riesgo"
                element={<ObjectivesRiskToleranceForm />}
              />
              <Route
                path="formularios/persona-juridica/operaciones-de-titulos"
                element={<TitleOperationsForm />}
              />
              <Route
                path="formularios/persona-juridica/distribucion-de-activos-liquidos"
                element={<DistributionLiquidAssetsLpFormNew />}
              />
              <Route
                path="formularios/persona-juridica/instrumentos-financieros"
                element={<FinancialInstrumentsLpFForm />}
              />
              {/* <Route path="formularios/persona-juridica/origen-de-los-fondos" element={<SourceOfFundsLpForm />}/> */}
              <Route
                path="formularios/persona-juridica/cliente-profesional"
                element={<ProfessionalClientLpForm />}
              />
              <Route
                path="formularios/persona-juridica/documentos"
                element={<SubDashboardDocumentsRepository />}
              />

              {/* RUTAS DE PERSONA FISICA */}

              {/* Customer identification Routes */}
              <Route
                path="formularios/persona-fisica/datos-generales"
                element={<GeneralDataPhysicalPerson />}
              />
              <Route
                path="formularios/referencias-comerciales"
                element={<CommercialReferencesForm />}
              />
              <Route
                path="formularios/persona-fisica/informacion-laboral"
                element={<WorkingInformationForm />}
              />
              <Route
                path="formularios/persona-fisica/otros-ingresos"
                element={<OtherIncomeForm />}
              />
              <Route
                path="formularios/persona-fisica/direccion-laboral"
                element={<WorkAddressForm />}
              />
              <Route
                path="formularios/persona-fisica/pep"
                element={<PepForm />}
              />
              <Route
                path="formularios/persona-fisica/marketing-sobre-parval"
                element={<ParvalMarketingFormPhysicalPerson />}
              />
              {/* <Route path="formularios/persona-fisica/informacion-de-sucursal" element={<BranchForm />}/> */}
              <Route
                path="formularios/persona-fisica/estado-de-inversion"
                element={<InvestmentStatusForm />}
              />
              <Route
                path="formularios/persona-fisica/direccion-residencial"
                element={<ResidentialAddressForm />}
              />
              {/* <Route path="formularios/persona-fisica/datos-core" element={<DataCore />}/> */}
              <Route
                path="formularios/persona-fisica/personas-relacionadas"
                element={<RelatedPeopleForm />}
              />
              <Route
                path="formularios/persona-fisica/cuentas-bancarias"
                element={<BankAccounts />}
              />
              <Route
                path="formularios/persona-fisica/tipo-de-solicitante"
                element={<TypeOfApplicantForm />}
              />

              {/* Routes of Financial Information and Investments */}
              <Route
                path="formularios/persona-fisica/perfil-del-inversionista"
                element={<InvestorProfilePhysicalPersonForm />}
              />
              <Route
                path="formularios/persona-fisica/distribucion-de-activos-liquidos"
                element={<DistributionLiquidAssetsFormPhysicalPerson />}
              />
              <Route
                path="formularios/persona-fisica/instrumentos-financieros"
                element={<FinancialInstrumentsForm />}
              />
              <Route
                path="formularios/persona-fisica/origen-de-los-fondos"
                element={<SourceOfFundsForm />}
              />
              <Route
                path="formularios/persona-fisica/operaciones-de-titulos-valores-financieras"
                element={<TitleOperationsFpForm />}
              />
              <Route
                path="formularios/persona-fisica/cliente-profesional"
                element={<ProfessionalClientForm />}
              />
              <Route
                path="formularios/persona-fisica/moneda-e-inversion"
                element={<CurrencyAndInvestmentForm />}
              />
              <Route
                path="formularios/persona-fisica/documentos"
                element={<SubDashboardDocumentsRepository />}
              />
            </Route>
            <Route
              path="resumen-de-cuentas"
              element={<SubDasboardSummaryOfAccounts />}
            />
            <Route path="notificaciones" element={<Notes />} />
            <Route path="tasas-y-productos" element={<TasaProductos />} />
          </Route>

          <Route
            path="/historico-de-operaciones"
            element={<HistoryOperations />}
          />
          <Route path="/profile" element={<Profile />} />
          <Route
            path="/formularios/persona-fisica/informacion-basica"
            element={<BasicFormFieldsPage />}
          />
          <Route
            path="/formularios/persona-fisica/marketing-sobre-parval"
            element={<ParvalMarketingPagePhysicalPerson />}
          />
          <Route
            path="/formularios/persona-fisica/informacion-de-contacto"
            element={<ContactInformationFormPage />}
          />
          <Route
            path="/formularios/persona-fisica/informacion-de-nacionalidad"
            element={<NationalityInformationPage />}
          />
          <Route
            path="/formularios/persona-fisica/informacion-de-residencia"
            element={<ResidenceInformationFormPage />}
          />
          <Route
            path="/formularios/persona-fisica/cuentas-bancarias"
            element={<BankAccountsPage />}
          />
          <Route
            path="/formularios/persona-fisica/estado-laboral"
            element={<EmploymentStatusPage />}
          />
          <Route
            path="/formularios/persona-fisica/otros-ingresos"
            element={<OtherIncomePage />}
          />
          <Route
            path="/formularios/persona-fisica/direccion-laboral"
            element={<WorkAddressPage />}
          />
          <Route path="/formularios/persona-fisica/pep" element={<PepPage />} />
          <Route
            path="/formularios/persona-fisica/apoderados-o-tutores"
            element={<ProxiesOrGuardiansPage />}
          />
          <Route
            path="/formularios/persona-fisica/beneficiario-final"
            element={<FinalBeneficiaryPage />}
          />
          <Route
            path="/formularios/persona-fisica/fatca"
            element={<FatcaPage />}
          />
          <Route
            path="/formularios/persona-fisica/cliente-profesional"
            element={<ProfessionalClientPage />}
          />
          <Route
            path="/formularios/persona-fisica/perfil-del-inversionista"
            element={<InvestorProfilePhysicalPersonPage />}
          />
          <Route
            path="/formularios/persona-fisica/distribucion-de-activos-liquidos"
            element={<DistributionLiquidAssetsPage />}
          />
          <Route
            path="/formularios/persona-fisica/instrumentos-financieros"
            element={<FinancialInstrumentsPage />}
          />
          <Route
            path="/formularios/persona-fisica/origen-de-los-fondos"
            element={<SourceOfFundsPage />}
          />
          <Route
            path="/formularios/persona-fisica/resultado"
            element={<ResultPage />}
          />
          {/* <Route path="/forms/academic-level-and-profession-form" element={<AcademicLevelAndProfessionFormPage />}/> */}
          <Route path="/forms/account-types" element={<AccountTypesPage />} />
          <Route
            path="/forms/registration-data-request"
            element={<RegistrationDataRequest />}
          />
          <Route
            path="/formularios/persona-juridica/direccion-oficina-republica-dominicana"
            element={<OfficeAddressDominicanRepublicForm />}
          />
          <Route
            path="/formularios/persona-juridica/comunicaciones-y-correspondencia"
            element={<CommunicationsAndCorrespondencePage />}
          />
          <Route
            path="/formularios/referencias-comerciales"
            element={<CommercialReferencesPage />}
          />
          <Route
            path="/formularios/persona-juridica/marketing-sobre-parval"
            element={<ParvalMarketingPage />}
          />
          <Route
            path="/formularios/persona-juridica/perfil-del-inversionista"
            element={<InvestorProfilePage />}
          />
          <Route
            path="/formularios/persona-juridica/designacion-cuenta-bancaria"
            element={<BankAccountDesignationPage />}
          />
          <Route
            path="/formularios/persona-juridica/relativo-a-fatca"
            element={<FatcaRelatedPage />}
          />
          <Route
            path="/formularios/persona-juridica/declaracion-jurada-de-suministro-de-informacion"
            element={<AffidavitProvisionInformationPage />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default ClientePotencialApp;
