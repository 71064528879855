import './SubDashboard.css';
import { Outlet } from 'react-router-dom';
import SubSectionCard from '../../../components/ClientePotencial/SubSectionCard/SubSectionCard';
import React, { useEffect, useState } from 'react';
import ProgressMeter from '../../../shared/UIElements/ProgressMeter/ProgressMeter';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  loadPersons,
  setCompanyName,
  setConfetti,
  setFormResponse,
  setHasBCRDAccount,
  setHasInvestmentProfile,
  setIsContrapart,
  setIsForeign,
  setIsFormCompleted,
  setIsFormDisqualified,
  setIsListedOnStock,
  setIsTreasury,
  setPhase,
} from '../../../shared/store/slices/clientePotencial';
import { useLazyQuery } from '@apollo/client';
import {
  GET_BENEFICIARIES_SECTION,
  GET_COMPANY_SHAREHOLDER_SECTION,
  GET_COUNSEL_MEMBERS_SECTION,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_GENERAL_INFORMATION,
  GET_LEGAL_REPRESENTATIVES_SECTION_DATA,
  GET_SIGNATURES_SECTION,
} from '../../../shared/graphQL/queries';
import { toast } from 'react-toastify';

const SubDashboard = ({ creatingAccount }) => {
  const [SUBSECTIONS, setSUBSECTIONS] = useState({});
  const {
    hasInvestmentProfile,
    isContrapart,
    isTreasury,
    hasBCRDAccount,
    isListedOnStock,
    validation,
    isForeign,
  } = useSelector((state) => state.clientePotencialSlice);
  const [lead, setLead] = useState(false);
  const [getGeneralInformationData, generalInformationData] = useLazyQuery(
    GET_GENERAL_INFORMATION,
    {
      fetchPolicy: 'network-only',
    }
  );
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);

  const [getLegalRepresentativesSectionData] = useLazyQuery(
    GET_LEGAL_REPRESENTATIVES_SECTION_DATA
  );
  const [getCompanyShareholdersSectionData] = useLazyQuery(
    GET_COMPANY_SHAREHOLDER_SECTION
  );
  const [getBeneficiariesSectionData] = useLazyQuery(GET_BENEFICIARIES_SECTION);
  const [getSignaturesSectionData] = useLazyQuery(GET_SIGNATURES_SECTION);
  const [getCounselMembersSectionData] = useLazyQuery(GET_COUNSEL_MEMBERS_SECTION);
  const [principalSubsectionQuantity, setPrincipalSubsectionQuantity] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      sessionStorage.getItem('hasInvestmentProfile') === 'true' ||
      hasInvestmentProfile
    ) {
      dispatch(
        setHasInvestmentProfile(sessionStorage.getItem('hasInvestmentProfile'))
      );
    } else {
      dispatch(
        setHasInvestmentProfile(sessionStorage.getItem('hasInvestmentProfile'))
      );
    }
  }, [hasInvestmentProfile]);

  useEffect(() => {
    if (sessionStorage.getItem('selectedFormResponse')) {
      setLead(JSON.parse(sessionStorage.getItem('selectedFormResponse'))?.lead);
    }
    if(sessionStorage.getItem('isNewAccount')){
      dispatch(setConfetti(true));
      toast('Felicidades, tu cuenta Parval ha sido creada', {
        type: 'success',
        theme: 'colored',
      });
      sessionStorage.removeItem('isNewAccount');
    }
  }, []);

  useEffect(() => {
    setPrincipalSubsectionQuantity(
      document.getElementsByClassName('sub-sidebar-content')[0]
      .getElementsByClassName('sub-section-card').length - 1
    );
  });

  useEffect(() => {
    if (lead) {
      dispatch(
        setIsContrapart(
          lead?.companyLeadDetails?.subApplicantType?.name === 'Contraparte'
        )
      );
      dispatch(
        setIsTreasury(
          lead?.companyLeadDetails?.applicantType?.name.includes('Tesorería')
        )
      );
      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: lead?.identification,
          },
        },
      });
    }
  }, [lead]);

  const setPersonsData = async () => {
    const paratemer = {
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    };
    const promises = [
      getLegalRepresentativesSectionData(paratemer),
      getCompanyShareholdersSectionData(paratemer),
      getBeneficiariesSectionData(paratemer),
      getSignaturesSectionData(paratemer),
      getCounselMembersSectionData(paratemer),
    ];

    const response = await Promise.all(promises);

    loadPersons(dispatch, {
      legalRepresentatives:
        response[0].data?.legalRepresentativesSection?.legalRepresentatives ??
        [],
      shareholders:
        response[1].data?.companyShareholdersSection?.shareholders ?? [],
      beneficiaries:
        response[2].data?.beneficiariesSection?.beneficiaries ?? [],
      signatores:
        response[3].data?.signaturesSection?.signaturesCard?.signatories ?? [],
      counselMembers:
      response[4].data?.counselMembersSection?.counselMembers ?? [],
    });
  };

  useEffect(() => {
    if (formResponseByIdentification?.data?.formResponses?.length) {
      if (formResponseByIdentification?.data?.formResponses[0]?.form?.personType?.name === 'Persona Jurídica') {
        if (hasInvestmentProfile === 'true') {
          setSUBSECTIONS({
            //SECCIONES DE PERSONA JURIDICA
            companyInformation: {
              name: 'Información De Mi Empresa',
              children: {
                generalInfo: 'Generales',
                settlementInstruction: 'Instrucción de Liquidación',
                legalRepresentative: 'Representantes Legales',
                mainContactInformation: 'Contacto Principal',
                businessDescription: 'Descripción del Negocio',
                mainOfficeAddress: 'Direcciones',
                obligatedSubject: 'Sujeto Obligado',
                shareholders: 'Accionistas',
                affiliatedCompanies: 'Empresas Afiliadas',
                supplementaryData: 'Datos Complementarios',
              },
            },
            parvalCustomerInformation: {
              name: 'Información de Cliente Parval',
              children: {
                bankAccounts: 'Cuentas Bancarias',
                commercialReferences: 'Referencias Comerciales',
                declarationOfBeneficiaries:
                  'Declaración de Beneficiarios Directos / Indirectos /Relacionados',
                signatureCard: 'Tarjeta de Firmas',
                counselMembers: 'Miembros del Consejo',
                correspondenceEmails: 'Correos Electrónicos de Correspondencia',
              },
            },
            financialInformation: {
              name: 'Información Financiera e Inversiones',
              children: {
                general: 'Generales',
                objectivesRiskTolerance: 'Objetivos / Tolerancia al riesgo',
                titleOperations:
                  'Marque las operaciones de títulos valores y financieras que desea realizar y comprende',
                distributionLiquidAssets: 'Distribución de Activos Líquidos',
                financialInstruments:
                  'Instrumentos financieros negociados previamente',
                professionalClient: 'Cliente Profesional',
              },
            },
          });
        } else {
          setSUBSECTIONS({
            //SECCIONES DE PERSONA JURIDICA
            companyInformation: {
              name: 'Información De Mi Empresa',
              children: {
                generalInfo: 'Generales',
                settlementInstruction: 'Instrucción de Liquidación',
                legalRepresentative: 'Representantes Legales',
                mainContactInformation: 'Contacto Principal',
                businessDescription: 'Descripción del Negocio',
                mainOfficeAddress: 'Direcciones',
                obligatedSubject: 'Sujeto Obligado',
                shareholders: 'Accionistas',
                affiliatedCompanies: 'Empresas Afiliadas',
                supplementaryData: 'Datos Complementarios',
              },
            },
            parvalCustomerInformation: {
              name: 'Información de Cliente Parval',
              children: {
                bankAccounts: 'Cuentas Bancarias',
                commercialReferences: 'Referencias Comerciales',
                declarationOfBeneficiaries:
                  'Declaración de Beneficiarios Directos / Indirectos /Relacionados',
                signatureCard: 'Tarjeta de Firmas',
                counselMembers: 'Miembros del Consejo',
                correspondenceEmails: 'Correos Electrónicos de Correspondencia',
              },
            },
          });
        }
      } else {
        setSUBSECTIONS({
          // SECCIONES DE PERSONA FISICA
          customerIdentification: {
            name: 'Identificación del cliente',
            children: {
              generalData: 'Datos Generales',
              commercialReferences: 'Referencias Personales y Comerciales',
              workingInformation: 'Información Laboral',
              otherIncome: 'Otros Ingresos',
              workAddress: 'Dirección Laboral',
              pep: 'PEP',
              marketing: 'Marketing',
              investmentStatus: 'Estado de inversión',
              residentialAddress: 'Dirección Residencial',
              relatedPeople: 'Personas Relacionadas',
              bankAccounts: 'Cuentas bancarias',
              typeOfApplicant: 'Tipo de Solicitante',
            },
          },
          financialInformationPhysicalPerson: {
            name: 'Información Financiera e Inversiones',
            children: {
              investorProfile: 'Perfil del inversionista',
              distributionLiquidAssets: 'Distribución de Activos Líquidos',
              financialInstruments:
                'Instrumentos financieros negociados previamente',
              sourceOfFunds: 'Origen de fondos',
              titleOperations: 'Operaciones de títulos valores y financieros',
              professionalClient: 'Cliente Profesional',
              currencyAndInvestment: 'Moneda e Inversión',
            },
          },
          related: {
            name: 'Relacionados',
            children: {
              finalBeneficiary: 'Beneficiario Final',
            },
          },
        });
      }
      dispatch(
        setFormResponse(formResponseByIdentification?.data?.formResponses[0])
      );
      dispatch(
        setIsFormCompleted(
          formResponseByIdentification?.data?.formResponses[0]
            ?.formResponseStatus?.name !== 'Nuevo'
        )
      );
      dispatch(
        setIsFormDisqualified(
          formResponseByIdentification?.data?.formResponses[0]
            ?.formResponseStatus?.name === 'Descalificado'
        )
      );
      dispatch(
        setPhase(formResponseByIdentification?.data?.formResponses[0]?.phase)
      );
      getGeneralInformationData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setPersonsData();
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (generalInformationData?.data?.companyInformationSection?.id) {
      dispatch(
        setIsListedOnStock(
          !!generalInformationData?.data?.companyInformationSection
            ?.isListedOnStock
        )
      );
      dispatch(
        setIsForeign(
          generalInformationData?.data?.companyInformationSection?.companyOrigin
            ? generalInformationData?.data?.companyInformationSection?.companyOrigin?.name?.includes(
                'Extranjera'
              )
            : false
        )
      );
      dispatch(
        setHasBCRDAccount(
          !!generalInformationData?.data?.companyInformationSection
            ?.hasBCRDAccount
        )
      );
      dispatch(
        setCompanyName(
          generalInformationData?.data?.companyInformationSection
            ?.commercialName
        )
      );
    }
  }, [generalInformationData]);

  const sectionValidateObject = {
    'Información De Mi Empresa': [
      'addressSectionErrors',
      'legalRepresentativesSectionErrors',
      'communicationsSectionErrors',
      'companyEconomicDetailsSectionErrors',
      'companyInformationSectionErrors',
      'companyShareholdersSectionErrors',
      'dueDiligenceSectionErrors',
      'fatcaSectionErrors',
      'marketingSectionErrors',
      'relatedCompaniesSectionErrors',
      'leadErrors',
      'settlementInstructionSectionErrors',
    ],
    'Información de Cliente Parval': [
      'bankAccountSectionErrors',
      'beneficiariesSectionErrors',
      'commercialReferencesSectionErrors',
      'signaturesSectionErrors',
      'counselMembersSectionErrors',
      'correspondenceEmailSectionErrors'
    ],
    'Información Financiera e Inversiones': [
      'financialInstrumentsSectionErrors',
      'investmentExperienceSectionErrors',
      'investmentFinancialDetailsSectionErrors',
      'investmentProfileDescriptionSectionErrors',
      'investmentProfileSectionErrors',
      'investorClassificationSectionErrors',
    ],
  };

  const manageValidation = (subsection) => {
    let error = false;
    const errors = [];
    const sections = sectionValidateObject[subsection];
    const errorSections = validation;

    if (errorSections) {
      sections.forEach((section) => {
        if (errorSections[section]?.length) {
          error = true;
          errors.push(section);
        }
      });
    }

    return {
      error,
      errors,
    };
  };

  return (
    <div className="sub-dashboard">
      <div className="sub-dashboard-header">
        <h3>Datos de la Cuenta</h3>
      </div>
      <div className="justifyContent flex">
        <div className="sub-sidebar">
          <div className="sub-sidebar-content">
            <p className="subtitle">
              <span style={{ fontWeight: '600' }}>
                ¡Gracias por elegir{' '}
                <span style={{ color: '#f7c42c' }}>PARVAL</span> para abrir tu
                cuenta!
              </span>
            </p>

            <ProgressMeter isInAccount={!creatingAccount} />

            {creatingAccount ? (
              <div>
                <div
                  style={{ height: '276px' }}
                  className="skeletonSidebar"
                ></div>
                <div className="skeletonSidebar"></div>
                <div className="skeletonSidebar"></div>
              </div>
            ) : (
              <>
                {Object.keys(SUBSECTIONS).map((key, index) => {
                  if (
                    (!isContrapart ||
                      !(isTreasury && !hasInvestmentProfile) ||
                      SUBSECTIONS[key].name !==
                        'Información Financiera e Inversiones')
                  ) {
                    return (
                      <SubSectionCard
                        error={manageValidation(SUBSECTIONS[key].name)?.error}
                        errors={manageValidation(SUBSECTIONS[key].name)?.errors}
                        key={index}
                        name={SUBSECTIONS[key].name}
                        index={index}
                        child={SUBSECTIONS[key].children}
                      />
                    );
                  }
                  else{ return null }
                })}

                <SubSectionCard name="Documentos" index={principalSubsectionQuantity} />
              </>
            )}
          </div>
        </div>
        <div
          className="content sub-dashboard-content"
          style={{ overflow: 'hidden', borderRadius: '15px !important' }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SubDashboard;
