import './BasicFormFields.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  convertInputToBoolean,
  validateInputCedula,
} from '../../../../../shared/utils/functions';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const BasicFormFields = () => {
  const [genders, setGenders] = useState([]);
  const [civilStatus, setCivilStatus] = useState([]);
  const [areYouLinkedToParvalOptions, setAreYouLinkedToParvalOptions] =
    useState([]);
  const [parvalEmployeeOptions, setParvalEmployeeOptions] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [countries, setCountries] = useState([]);
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const accountType = localStorage.getItem('accountTypeToStartRegister');
  const [
    identificationTypesForPhisicalPerson,
    setIdentificationTypesForPhisicalPerson,
  ] = useState([]);
  const [academicLevels, setAcademicLevels] = useState([]);
  const [ocupations, setOcupations] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [parvalTypesRelationsOptions, setParvalTypesRelationsOptions] =
    useState([]);
  const [parvalGroupRelationsOptions, setParvalGroupRelationsOptions] =
    useState([]);
  const [isCompletePageLoading, setIsCompletePageLoading] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);

  const identityDocumentType = watch('identityDocumentType');
  const identityDocumentNumber = watch('identityDocumentNumber');

  const nombre = watch('nombre');
  const apellido = watch('apellido');
  const sexo = watch('sexo');
  const estadoCivil = watch('estadoCivil');
  const vinculadoParval = watch('vinculadoParval');
  const empleadoParval = watch('empleadoParval');
  const fechaNacimiento = watch('fechaNacimiento');
  const lugarNacimiento = watch('lugarNacimiento');
  const paisResidencia = watch('paisResidencia');
  const tieneSegundaNacionalidad = watch('tieneSegundaNacionalidad');
  const segundaNacionalidad = watch('segundaNacionalidad');
  const pasaporteSegundaNacionalidad = watch('pasaporteSegundaNacionalidad');
  const genderForTraditionalVerification = {
    1: 'M',
    0: 'F',
  };

  const sendData = () => {};

  const saveDataInLocalStorage = () => {
    setIsCompletePageLoading(true);
    // localStorage.setItem("name", nombre);
    // localStorage.setItem("lastName", apellido);
    // localStorage.setItem("gender", sexo);
    // localStorage.setItem("civilState", estadoCivil);
    // localStorage.setItem("parvalRelated", vinculadoParval);
    // localStorage.setItem("parvalEmployee", empleadoParval);
    // localStorage.setItem("birthDate", fechaNacimiento);
    // localStorage.setItem("placeBirth", lugarNacimiento);
    // localStorage.setItem("countryOfResidence", paisResidencia);
    // localStorage.setItem("hasSecondNationality", tieneSegundaNacionalidad);
    // localStorage.setItem("secondNationality", segundaNacionalidad);
    // localStorage.setItem("passportSecondNationality", pasaporteSegundaNacionalidad);
  };

  return (
    <>
      {isCompletePageLoading ? <Spinner /> : null}

      <form
        onSubmit={handleSubmit(saveDataInLocalStorage)}
        className="form-fileds-basic"
      >
        <div className="row fields-container">
          <div className="col-md-5 col-sm-12 columns">
            <h3 className="formTitle">Datos Generales</h3>

            <div className="field-form">
              <label htmlFor="nombre" className="label-field">
                Nombre <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="nombre"
                {...register('nombre', {
                  required: true,
                })}
              />
              {errors.nombre?.type === 'required' && (
                <p className="text-danger">El campo nombre es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="apellido" className="label-field">
                Apellido <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="apellido"
                {...register('apellido', {
                  required: true,
                })}
              />
              {errors.apellido?.type === 'required' && (
                <p className="text-danger">El campo apellido es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="sexo" className="label-field">
                Sexo <span>*</span>
              </label>
              <select
                id="sexo"
                defaultValue={'DEFAULT'}
                {...register('sexo', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {genders.map((gender) => (
                  <option key={gender.id} value={gender.id}>
                    {gender.name}
                  </option>
                ))}
              </select>
              {errors.sexo && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="identityDocumentType" className="label-field">
                Tipo de documento <span>*</span>
              </label>
              <select
                defaultValue={'DEFAULT'}
                {...register('identityDocumentType', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {identificationTypesForPhisicalPerson.map(
                  (identificationType) => (
                    <option
                      key={identificationType.id}
                      value={identificationType.id}
                    >
                      {identificationType.name}
                    </option>
                  )
                )}
              </select>
              {errors.identityDocumentType && (
                <p className="text-danger">
                  Debe seleccionar el tipo de documento de identidad
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="identityDocumentNumber" className="label-field">
                Documento de identidad <span>*</span>
              </label>
              <div
                className="input-group flex-nowrap"
                style={{ height: '10px !important' }}
              >
                {identityDocumentType === '100000001' ? (
                  <input
                    type="text"
                    id="cedula"
                    className="input-form"
                    maxLength="13"
                    pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                    placeholder="000-0000000-0"
                    onInput={validateInputCedula}
                    {...register('identityDocumentNumber', {
                      required: true,
                    })}
                  />
                ) : (
                  <input
                    type="text"
                    className="input-form"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    {...register('identityDocumentNumber', {
                      required: true,
                    })}
                  />
                )}
              </div>
              {errors.identityDocumentNumber?.type === 'required' && (
                <p className="text-danger">
                  Debe ingresar el número de su documento de identidad
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="estado-civil" className="label-field">
                Estado Civil <span>*</span>
              </label>
              <select
                id="estado-civil"
                defaultValue={'DEFAULT'}
                {...register('estadoCivil', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {civilStatus.map((civilStatus) => (
                  <option key={civilStatus.id} value={civilStatus.id}>
                    {civilStatus.name}
                  </option>
                ))}
              </select>
              {errors.estadoCivil && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>
          </div>

          <div className="col-md-5 col-sm-12 columns">
            <h3 className="formTitle" style={{ visibility: 'hidden' }}>
              Non visible
            </h3>

            <div className="field-form">
              <label htmlFor="nivel-academico" className="label-field">
                Nivel académico <span>*</span>
              </label>
              <select
                id="nivel-academico"
                defaultValue={'DEFAULT'}
                {...register('nivelAcademico', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {academicLevels.map((academicLevel) => (
                  <option key={academicLevel.id} value={academicLevel.id}>
                    {academicLevel.name}
                  </option>
                ))}
              </select>
              {errors.nivelAcademico && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="ocupacion" className="label-field">
                Ocupación <span>*</span>
              </label>
              <select
                id="ocupacion"
                defaultValue={'DEFAULT'}
                {...register('ocupacion', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {ocupations.map((ocupation) => (
                  <option key={ocupation.id} value={ocupation.id}>
                    {ocupation.name}
                  </option>
                ))}
              </select>
              {errors.ocupacion && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="profesion" className="label-field">
                Profesión <span>*</span>
              </label>
              <select
                id="profesion"
                defaultValue={'DEFAULT'}
                {...register('profesion', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {professions.map((profession) => (
                  <option key={profession.id} value={profession.id}>
                    {profession.name}
                  </option>
                ))}
              </select>
              {errors.profesion && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="tipo-cuenta" className="label-field">
                Tipo de cuenta <span>*</span>
              </label>
              <select
                id="tipo-cuenta"
                defaultValue={'DEFAULT'}
                {...register('tipoCuenta', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {accountTypes.map((accountType) => (
                  <option key={accountType.id} value={accountType.id}>
                    {accountType.name}
                  </option>
                ))}
              </select>
              {errors.tipoCuenta && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="vinculado-parval" className="label-field">
                ¿Estás vinculado a Parval? <span>*</span>
              </label>
              <select
                id="vinculado-parval"
                defaultValue={'DEFAULT'}
                {...register('vinculadoParval', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {areYouLinkedToParvalOptions.map((linkedToParvalOption) => (
                  <option
                    key={linkedToParvalOption.id}
                    value={linkedToParvalOption.id}
                  >
                    {linkedToParvalOption.name}
                  </option>
                ))}
              </select>
              {errors.vinculadoParval && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            {vinculadoParval === '1' ? (
              <>
                <div className="field-form">
                  <label
                    htmlFor="tipo-vinculacion-parval"
                    className="label-field"
                  >
                    Tipo de vinculación <span>*</span>
                  </label>
                  <select
                    id="tipo-vinculacion-parval"
                    defaultValue={'DEFAULT'}
                    {...register('tipoVinculacionParval', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {parvalTypesRelationsOptions.map((relationsOption) => (
                      <option
                        key={relationsOption.id}
                        value={relationsOption.id}
                      >
                        {relationsOption.name}
                      </option>
                    ))}
                  </select>
                  {errors.tipoVinculacionParval && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label
                    htmlFor="grupo-vinculacion-parval"
                    className="label-field"
                  >
                    Grupo de vinculación <span>*</span>
                  </label>
                  <select
                    id="grupo-vinculacion-parval"
                    defaultValue={'DEFAULT'}
                    {...register('grupoVinculacionParval', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {parvalGroupRelationsOptions.map((relationsOption) => (
                      <option
                        key={relationsOption.id}
                        value={relationsOption.id}
                      >
                        {relationsOption.name}
                      </option>
                    ))}
                  </select>
                  {errors.grupoVinculacionParval && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>
              </>
            ) : null}

            <div className="field-form">
              <label htmlFor="empleado-parval" className="label-field">
                ¿Eres empleado de Parval? <span>*</span>
              </label>
              <select
                id="empleado-parval"
                defaultValue={'DEFAULT'}
                {...register('empleadoParval', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {parvalEmployeeOptions.map((parvalEmployeeOption) => (
                  <option
                    key={parvalEmployeeOption.id}
                    value={parvalEmployeeOption.name}
                  >
                    {parvalEmployeeOption.name}
                  </option>
                ))}
              </select>
              {errors.empleadoParval && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            {/* <div className='field-form'>
                        <label htmlFor="fecha-nacimiento" className='label-field'>Fecha de nacimiento</label>
                        <input type="date" className='input-form' placeholder="" id="fecha-nacimiento" {...register('fechaNacimiento', {
                            required: true
                        })} />
                        {errors.fechaNacimiento?.type === 'required' && <p className='text-danger'>El campo Fecha de Nacimiento es requerido</p>}
                    </div>

                    <div className='field-form'>
                        <label htmlFor="lugar-nacimiento" className='label-field'>Lugar de Nacimiento</label>
                        <input type="text" className='input-form' placeholder="" id="lugar-nacimiento" {...register('lugarNacimiento', {
                            required: true
                        })} />
                        {errors.lugarNacimiento?.type === 'required' && <p className='text-danger'>El campo Lugar de Nacimiento es requerido</p>}
                    </div>

                    <div className='field-form'>
                        <label htmlFor="pais-residencia" className='label-field'>País de residencia</label>
                        <select  id='pais-residencia' defaultValue={'DEFAULT'} {...register('paisResidencia', {
                            validate: validateNonSelectedOptionInDropdown
                        })}>
                            <option value="DEFAULT" disabled>Selecciona</option>
                            {countries.map(country => <option key={country.id} value={country.id}>{country.name}</option>)}
                        </select>
                        {errors.paisResidencia && <p className='text-danger'>Debe seleccionar una opción</p>}
                    </div>

                    <div className='field-form'>
                        <label htmlFor="tiene-segunda-nacionalidad" className='label-field'>¿Posee una segunda nacionalidad?</label>
                        <select  id='tiene-segunda-nacionalidad' defaultValue={'DEFAULT'} {...register('tieneSegundaNacionalidad', {
                            validate: validateNonSelectedOptionInDropdown
                        })}>
                            <option value="DEFAULT" disabled>Selecciona</option>
                            <option value={"Si"}>Si</option>
                            <option value={"No"}>No</option>
                        </select>
                        {errors.tieneSegundaNacionalidad && <p className='text-danger'>Debe seleccionar una opción</p>}
                    </div>

                    <div className='field-form'>
                        <label htmlFor="segunda-nacionalidad" className='label-field'>Segunda nacionalidad</label>
                        <select  id='segunda-nacionalidad' defaultValue={'DEFAULT'} {...register('segundaNacionalidad')}>
                            <option value="DEFAULT" disabled>Selecciona</option>
                            {nationalities.map(nationality => <option key={nationality.id} value={nationality.id}>{nationality.name}</option>)}
                        </select>
                        {errors.segundaNacionalidad && <p className='text-danger'>Debe seleccionar una opción</p>}
                    </div>

                    <div className='field-form'>
                        <label htmlFor="pasaporte-segunda-nacionalidad" className='label-field'>Pasaporte segunda nacionalidad</label>
                        <input type="text" className='input-form' placeholder="" id="pasaporte-segunda-nacionalidad" {...register('pasaporteSegundaNacionalidad')} />
                        {errors.pasaporteSegundaNacionalidad?.type === 'required' && <p className='text-danger'>Este campo es requerido</p>}
                    </div> */}
          </div>
        </div>

        <div className="single-button-control-container">
          {/* <button type='submit' className='btn-back' onClick={handleBackNavigation}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                    &#160; Atrás 
                </button> */}
          <button
            type="submit"
            disabled={disabledButton}
            className={disabledButton ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default BasicFormFields;
