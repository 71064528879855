import './AffiliatedCompaniesForm.css';

import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_IDENTITYTYPES,
  GET_RELATED_COMPANIES_SECTION_DATA,
} from '../../../../../shared/graphQL/queries';
import {
  REMOVE_RELATED_COMPANY,
  SAVE_RELATED_COMPANIES_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const AffiliatedCompaniesForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const { data: identificationTypeList } = useQuery(GET_IDENTITYTYPES);
  const [formResponseId, setFormResponseId] = useState('');
  const [relatedCompaniesSectionId, setRelatedCompaniesSectionId] = useState();
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [saveRelatedCompaniesSection] = useMutation(
    SAVE_RELATED_COMPANIES_SECTION,
    {
      refetchQueries: [
        {
          query: GET_RELATED_COMPANIES_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [removeRelatedCompany] = useMutation(REMOVE_RELATED_COMPANY, {
    refetchQueries: [
      {
        query: GET_RELATED_COMPANIES_SECTION_DATA,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [getRelatedCompaniesSectionData, relatedCompaniesSectionData] =
    useLazyQuery(GET_RELATED_COMPANIES_SECTION_DATA);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isFormCompleted, validation, isFormDisqualified } = useSelector(
    (state) => state.clientePotencialSlice
  );

  const [loadingSection, setLoadingSection] = useState(true);

  const nombreRazonSocial = watch('nombreRazonSocial');
  const tipoIdentificacion = watch('tipoIdentificacion');
  const rncOEquivalente = watch('rncOEquivalente');
  const nombrePropietario = watch('nombrePropietario');
  const apellidoPropietario = watch('apellidoPropietario');
  const correoElectronicoPropietario = watch('correoElectronicoPropietario');
  const telefonoPropietario = watch('telefonoPropietario');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);
    getRelatedCompaniesSectionData({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (relatedCompaniesSectionData?.data) {
      setRelatedCompaniesSectionId(
        relatedCompaniesSectionData?.data?.relatedCompaniesSection?.id
      );
      setLoadingSection(false);
    }
  }, [relatedCompaniesSectionData]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedCompanyId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/principales-accionistas'
    );
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
    );
  };

  const addAffiliatedCompany = () => {
    const emailIsInUse =
      relatedCompaniesSectionData?.data?.relatedCompaniesSection?.relatedCompanies?.find(
        (company) =>
          company.id !== selectedCompanyId &&
          correoElectronicoPropietario === company.ownerEmail
      );

    if (emailIsInUse) {
      toast('Ya existe una empresa con este correo', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    const identificationIsInUse =
      relatedCompaniesSectionData?.data?.relatedCompaniesSection?.relatedCompanies?.find(
        (company) =>
          company.id !== selectedCompanyId &&
          rncOEquivalente === company.identification &&
          company.identificationType.id === tipoIdentificacion
      );

    if (identificationIsInUse) {
      toast('Ya existe una empresa con esta identificación', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    btnCloseModalRef.current.click();
    reset();

    let data = {};

    //Si companyOfficesSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (relatedCompaniesSectionId) {
      // En caso de que selectedCompanyId renga valor se debe editar, en caso contrario se debe agregar
      if (selectedCompanyId && selectedCompanyId !== '') {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: relatedCompaniesSectionId,
          relatedCompanyInputTypes: {
            id: selectedCompanyId,
            businessName: nombreRazonSocial,
            identificationTypeId: tipoIdentificacion,
            identification: rncOEquivalente,
            ownerFirstName: nombrePropietario,
            ownerLastName: apellidoPropietario,
            ownerEmail: correoElectronicoPropietario,
            ownerPhoneNumber: telefonoPropietario,
          },
        };

        setSelectedCompanyId('');
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: relatedCompaniesSectionId,
          relatedCompanyInputTypes: {
            businessName: nombreRazonSocial,
            identificationTypeId: tipoIdentificacion,
            identification: rncOEquivalente,
            ownerFirstName: nombrePropietario,
            ownerLastName: apellidoPropietario,
            ownerEmail: correoElectronicoPropietario,
            ownerPhoneNumber: telefonoPropietario,
          },
        };
      }
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        relatedCompanyInputTypes: {
          businessName: nombreRazonSocial,
          identificationTypeId: tipoIdentificacion,
          identification: rncOEquivalente,
          ownerFirstName: nombrePropietario,
          ownerLastName: apellidoPropietario,
          ownerEmail: correoElectronicoPropietario,
          ownerPhoneNumber: telefonoPropietario,
        },
      };
    }

    saveRelatedCompaniesSection({
      variables: {
        relatedCompaniesSection: data,
      },
    });
  };

  const deleteCardElement = (id) => {
    removeRelatedCompany({
      variables: {
        relatedCompanyId: id,
      },
    });

    reset();
  };

  const handleEdit = (id) => {
    setSelectedCompanyId(id);
    const selectedCompany =
      relatedCompaniesSectionData?.data?.relatedCompaniesSection?.relatedCompanies?.find(
        (x) => x.id === id
      );
    if (id && selectedCompany) {
      setValue('nombreRazonSocial', selectedCompany?.businessName);
      setValue('tipoIdentificacion', selectedCompany?.identificationType?.id);
      setValue('rncOEquivalente', selectedCompany?.identification);
      setValue('nombrePropietario', selectedCompany?.ownerFirstName);
      setValue('apellidoPropietario', selectedCompany?.ownerLastName);
      setValue('correoElectronicoPropietario', selectedCompany?.ownerEmail);
      setValue('telefonoPropietario', selectedCompany?.ownerPhoneNumber);
    }
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'relatedCompaniesSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Empresas Afiliadas, Subsidiarias y Compañías Relacionadas
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={
                'Empresas Afiliadas, Subsidiarias y Compañías Relacionadas'
              }
              description={
                "Este formulario, destinado a 'Empresas Afiliadas, Subsidiarias y Compañías Relacionadas', proporciona un panorama integral de las conexiones empresariales vinculadas a su organización. Aquí se detallan y registran todas las empresas afiliadas, subsidiarias y compañías que tienen una relación directa con su entidad. El propósito es ofrecer una visión completa y organizada de la red empresarial que rodea y colabora con su empresa, destacando las conexiones estratégicas que pueden influir en la operación y dirección del negocio."
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'relatedCompanies'
                      ? 'Es necesario que agregues por lo menos una empresa relacionada.'
                      : `Hay una o varias empresas relacionadas con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>
          <h4 className="bff-sub-title">
            Si tienes más de una empresa, puedes agregarlas todas aquí.
          </h4>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {relatedCompaniesSectionData?.data?.relatedCompaniesSection?.relatedCompanies.map(
                (affiliatedCompany, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={affiliatedCompany.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardItems
                        key={index}
                        nameElement={affiliatedCompany.businessName}
                        identityDocument={affiliatedCompany.identification}
                        idElement={affiliatedCompany.id}
                        deleteElement={deleteCardElement}
                        handleEdit={handleEdit}
                        setIsUpdating={setIsUpdating}
                        showDeleteButton={!isFormCompleted && !isFormDisqualified}
                      />
                    </div>
                  );
                }
              )}
              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    reset();
                    setIsUpdating(false);
                    setSelectedCompanyId('');
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">Agregar Empresa</h4>
                </div>
              ) : null}
              { (isFormCompleted || isFormDisqualified) && !relatedCompaniesSectionData?.data?.relatedCompaniesSection?.relatedCompanies?.length ?
                <div className="add-card" style={{ pointerEvents: 'none' }}>
                  <div className="image-icon-container">
                    <FontAwesomeIcon
                      icon={faWarning}
                      className="image-icon"
                    />
                  </div>
                  <h4 className="bk-card-title">
                    {' '}
                    No hay empresas que mostrar{' '}
                  </h4>
                </div> : null
              }
            </div>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addAffiliatedCompany)}
                  className="fields-modal-container frm-main-share-holders"
                >
                  <div className="fields-modal-header mb-3">
                    <h4 className="modal-title" id="exampleModalLabel">
                      {!isFormCompleted && !isFormDisqualified
                        ? selectedCompanyId
                          ? 'Editar'
                          : 'Agregar'
                        : 'Ver'}{' '}
                      Empresa
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      onClick={() => {
                        reset();
                        setIsUpdating(false);
                        setSelectedCompanyId('');
                      }}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="row">
                    <div className="field-form col-lg-12 col-sm-12">
                      <label
                        htmlFor="nombre-razon-social"
                        className="label-field"
                      >
                        Nombre / Razón Social <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        placeholder=""
                        id="nombre-razon-social"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('nombreRazonSocial', {
                          required: true,
                        })}
                      />
                      {errors.nombreRazonSocial?.type === 'required' && (
                        <p className="text-danger">
                          El campo Nombre / Razón Social es requerido
                        </p>
                      )}
                      {selectedCompanyId &&
                        !nombreRazonSocial &&
                        errors.nombreRazonSocial?.type !== 'required' && (
                          <ErrorText hasError={hasError('businessName')} />
                        )}
                    </div>

                    <div className="field-form col-lg-6 col-sm-12">
                      <label
                        htmlFor="nombre-propietario"
                        className="label-field"
                      >
                        Nombre del propietario <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        placeholder=""
                        id="nombre-propietario"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('nombrePropietario', {
                          required: true,
                        })}
                      />
                      {errors.nombrePropietario?.type === 'required' && (
                        <p className="text-danger">
                          El campo Nombre del propietario es requerido
                        </p>
                      )}
                      {selectedCompanyId &&
                        !nombrePropietario &&
                        errors.nombrePropietario?.type !== 'required' && (
                          <ErrorText hasError={hasError('ownerFirstName')} />
                        )}
                    </div>

                    <div className="field-form col-lg-6 col-sm-12">
                      <label
                        htmlFor="apellido-propietario"
                        className="label-field"
                      >
                        Apellido del propietario <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        placeholder=""
                        id="apellido-propietario"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('apellidoPropietario', {
                          required: true,
                        })}
                      />
                      {errors.apellidoPropietario?.type === 'required' && (
                        <p className="text-danger">
                          El campo Apellido del propietario es requerido
                        </p>
                      )}
                      {selectedCompanyId &&
                        !apellidoPropietario &&
                        errors.apellidoPropietario?.type !== 'required' && (
                          <ErrorText hasError={hasError('ownerLastName')} />
                        )}
                    </div>

                    <div className="field-form col-lg-6 col-sm-12">
                      <label
                        htmlFor="tipo-identificacion"
                        className="label-field"
                      >
                        Tipo de identificación <span>*</span>
                      </label>
                      <div className="selectWrapper">
                        <select
                          id="tipo-identificacion"
                          defaultValue={'DEFAULT'}
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('tipoIdentificacion', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {identificationTypeList?.identificationTypeList.map(
                            (identificationType) => {
                              if (
                                identificationType.name !==
                                'Cedula de Identidad y Electoral'
                              ) {
                                return (
                                  <option
                                    key={identificationType.id}
                                    value={identificationType.id}
                                  >
                                    {identificationType.name}
                                  </option>
                                );
                              }
                              return null;
                            }
                          )}
                        </select>
                      </div>
                      {errors.tipoIdentificacion && (
                        <p className="text-danger">
                          Debe seleccionar una opción
                        </p>
                      )}
                      {selectedCompanyId &&
                        (!tipoIdentificacion ||
                          tipoIdentificacion === 'DEFAULT') &&
                        errors.tipoIdentificacion && (
                          <ErrorText
                            hasError={hasError('identificationType')}
                          />
                        )}
                    </div>

                    <div className="field-form col-lg-6 col-sm-12">
                      <label htmlFor="rnc-equivalente" className="label-field">
                        Identificación <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        placeholder=""
                        id="rnc-equivalente"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('rncOEquivalente', {
                          required: true,
                        })}
                      />
                      {errors.rncOEquivalente?.type === 'required' && (
                        <p className="text-danger">
                          El campo Identificación es requerido
                        </p>
                      )}
                      {selectedCompanyId &&
                        !rncOEquivalente &&
                        errors.rncOEquivalente?.type !== 'required' && (
                          <ErrorText hasError={hasError('identification')} />
                        )}
                    </div>

                    <div className="field-form col-lg-6 col-sm-12">
                      <label
                        htmlFor="telefono-propietario"
                        className="label-field"
                      >
                        Teléfono del propietario
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        placeholder=""
                        id="telefono-propietario"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('telefonoPropietario')}
                      />
                    </div>

                    <div className="field-form col-lg-6 col-sm-12">
                      <label
                        htmlFor="correo-electronico-propietario"
                        className="label-field"
                      >
                        Correo electrónico del propietario <span>*</span>
                      </label>
                      <input
                        type="email"
                        className="input-form"
                        placeholder=""
                        id="correo-electronico-propietario"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('correoElectronicoPropietario', {
                          required: true,
                        })}
                      />
                      {errors.correoElectronicoPropietario?.type ===
                        'required' && (
                        <p className="text-danger">Eeste campo es requerido</p>
                      )}
                      {selectedCompanyId &&
                        !correoElectronicoPropietario &&
                        errors.correoElectronicoPropietario?.type !==
                          'required' && (
                          <ErrorText hasError={hasError('ownerEmail')} />
                        )}
                    </div>
                  </div>

                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="row d-flex justify-content-center">
                      <button type="submit" className="mt-2 mb-4 btn-add">
                        Guardar
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            className="btn-continue"
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default AffiliatedCompaniesForm;
