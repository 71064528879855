import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setProgressBar } from '../../../shared/store/slices/clientePotencial';
import {
  envars,
  getDecodedCookie,
  modifyCookie,
} from '../../../shared/utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { GET_BRANCHES } from '../../../shared/graphQL/queries';
import {
  AZURE_SESSION_TIME_OUT,
  BRANCHTYPE,
  CLIENTTYPE,
  azureUserFlowUri,
} from '../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';

export default function Branch() {
  const navigate = useNavigate();
  const [branchSelected, setBranchSelected] = useState(null);
  const [reload, setReload] = useState(false);
  const [animationOut, setAnimationOut] = useState(false);
  const { loading, error, data } = useQuery(GET_BRANCHES);
  const configDecoded = getDecodedCookie('potencialCustomerConfig');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setProgressBar({ show: true, value: '72%' }));
    if (
      configDecoded &&
      configDecoded.branch &&
      !configDecoded.branch.name.toLowerCase().includes('alameda')
    ) {
      setReload(true);
      setBranchSelected(configDecoded.branch);
    }

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener las sucursales', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (branchSelected && !reload) {
      setAnimationOut(true);
      setTimeout(() => {
        goTo();
      }, 500);
    }
  }, [branchSelected, reload]);

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    modifyCookie(
      'potencialCustomerConfig',
      'branch',
      branchSelected,
      true,
      timeout
    );
    if (
      configDecoded?.assistUnit &&
      CLIENTTYPE[
        configDecoded.assistUnit.name
          .split(' ')
          [configDecoded.assistUnit.name.split(' ').length - 1].toLowerCase()
      ].isBusiness
    ) {
      navigate('/onboarding-digital/company-type');
    } else {
      navigate('/onboarding-digital/identity-verification');
    }
  };

  const goBack = () => {
    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    } else if (configDecoded.assistUnit) {
      navigate('/onboarding-digital/assistance-unit');
    } else {
      navigate('/onboarding-digital/person-type');
    }
  };

  const getIcon = (p) => {
    return BRANCHTYPE[
      p.name.split(' ')[p.name.split(' ').length - 1].toLowerCase()
    ].icon;
  };

  const filterByUnit = (branches) => {
    let filterBranches = [];
    if (branches?.length) {
      if (configDecoded?.assistUnit?.name?.toLowerCase().includes('negocios')) {
        filterBranches = branches.filter(
          (branch) => !branch.name.includes('Alameda')
        );
      }
    }

    return filterBranches;
  };

  return (
    <div className="containerOnboarding">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="d-none d-md-block">
            <div className="banner">
              <img
                className="bg"
                src="/images/onboarding/bannerOnboarding_2.jpg"
                alt=""
              />
            </div>
          </Col>
          <Col className="contForm">
            <p className="linkBack" onClick={goBack}>
              <FontAwesomeIcon icon={faAngleLeft} /> Volver
            </p>
            <div className="vAlign animate__animated animate__fadeInUp">
              <strong className="title">
                Elije tu sucursal de preferencia
              </strong>
              <p className="subTitle">Estamos ubicados en:</p>
              <div className="mt-3 newForm">
                <div className="cards-container">
                  {!loading &&
                    !error &&
                    data &&
                    data.branches &&
                    filterByUnit(data.branches)?.map((branch) => (
                      <div
                        className={`boxButton ${branchSelected?.id === branch.id ? 'selected' : ''}`}
                        onClick={() => {
                          setReload(false);
                          setBranchSelected(branch);
                        }}
                        key={branch.id}
                      >
                        <div className="flexLeft">
                          <div className="iconContainer">
                            {/* <img src={getIcon(branch)} alt="" /> */}
                            <img src="/images/iconSantoDomingo.svg" alt="" />
                          </div>
                          <div>
                            <h4 className="rwf-card-title">{branch.name}</h4>
                            <p className="card-text">{branch.address}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
