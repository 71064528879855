import { Routes, Route } from 'react-router-dom';
import RedirectActivedUserFlow from './RedirectActivedUserFlow/RedirectActiveUserFlow';
import ErrorPage from './ErrorPage/ErrorPage';

export default function CommonRoutes() {
  return (
    <Routes>
      <Route
        path={'/redirect-actived-user-flow'}
        element={<RedirectActivedUserFlow />}
      />
      <Route path={'/error'} element={<ErrorPage />} />
    </Routes>
  );
}
