import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {
  GET_COUNSEL_MEMBERS_SECTION,
  GET_COUNTRIES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_IDENTITYTYPES,
  GET_POLITIC_EXPOSURE_TYPES,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CHECK_POLITIC_EXPOSURE,
  SAVE_COUNSEL_MEMBERS_SECTION,
  REMOVE_COUNSEL_MEMBER,
} from '../../../../../shared/graphQL/mutations';
import { useDispatch } from 'react-redux';
import {
  loadPersons,
  setSelectedSection,
} from '../../../../../shared/store/slices/clientePotencial';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  comprexAddress,
  convertBooleanToInput,
  convertInputToBoolean,
  formatSimple,
  mergeObjectsWithSameId,
  parseSimple,
  preFormat,
  validateInputCedula,
  validateInputRnc,
} from '../../../../../shared/utils/functions';
import { v4 } from 'uuid';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const CounselMembersForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [formResponseId, setFormResponseId] = useState('');
  const { data: identificationTypeList } = useQuery(GET_IDENTITYTYPES);
  const { data: countries } = useQuery(GET_COUNTRIES);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isContrapart, persons, isLIstedOnStock, hasBCRDAccount, isFormCompleted, isForeign, isFormDisqualified, validation } =
    useSelector((state) => state.clientePotencialSlice);
  const [saveCounselMembersSection] = useMutation(SAVE_COUNSEL_MEMBERS_SECTION, {
    refetchQueries: [
      {
        query: GET_COUNSEL_MEMBERS_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [removeCounselMember] = useMutation(REMOVE_COUNSEL_MEMBER, {
    refetchQueries: [
      {
        query: GET_COUNSEL_MEMBERS_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [getCounselMembersSectionData, counselMembersSectionData] = useLazyQuery(
    GET_COUNSEL_MEMBERS_SECTION
  );
  const [counselMembersSectionId, setCounselMembersSectionId] = useState('');
  const [selectedCounselMemberId, setSelectedCounselMemberId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const [politicExposureId, setPoliticExposureId] = useState('');
  const [fatcaId, setFatcaId] = useState('');
  const [notCheck, setNotCheck] = useState(false);
  const [existingCounselMember, setExistingCounselMember] = useState(false);
  const { data: politicExposureTypes } = useQuery(GET_POLITIC_EXPOSURE_TYPES);
  const [
    checkPoliticExposure,
    { data: checkPoliticExposureResponse, error: checkPoliticExposureError },
  ] = useMutation(CHECK_POLITIC_EXPOSURE);

  const nombres = watch('nombres');
  const apellidos = watch('apellidos');
  const tipoDocumento = watch('tipoDocumento');
  const numeroDoc = watch('numeroDoc');
  const posicion = watch('posicion');
  const [paisNacionalidad, setPaisNacionalidad] = useState(null);
  const [inputPaisNacionalidad, setInputPaisNacionalidad] = useState('');

  const personaPoliticamenteExpuesta = watch('personaPoliticamenteExpuesta');
  const cargoPep = watch('cargoPep');
  const institucion = watch('institucion');
  const fechaInicioCargo = watch('fechaInicioCargo');
  const ultimaFechaCargo = watch('ultimaFechaCargo');
  const tipoExposicionPolitica = watch('tipoExposicionPolitica');
  const nombreDelFamiliar = watch('nombreDelFamiliar');
  const tipoRelacionFamiliar = watch('tipoRelacionFamiliar');
  const pepActivo = watch('pepActivo');

  const tieneNacionalidadEU = watch('tieneNacionalidadEU');
  const esCiudadanoEU = watch('esCiudadanoEU');
  const esResidenteEU = watch('esResidenteEU');
  const seguroSocial = watch('seguroSocial');
  const codigoPostalEU = watch('codigoPostalEU');
  const telefonoEU = watch('telefonoEU');
  const tieneRepresentanteEU = watch('tieneRepresentanteEU');
  const tieneTIN = watch('tieneTIN');
  const tin = watch('tin');
  const tarjetaVerde = watch('tarjetaVerde');
  const direccionEU = watch('direccionEU');

  const [loadingSection, setLoadingSection] = useState(true);
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (
      typeof numeroDoc === 'string' &&
      getIdentificationTypeName(tipoDocumento) ===
        'Cedula de Identidad y Electoral' &&
      !notCheck
    ) {
      if (numeroDoc.length > 12) {
        verifiyIfIsPep();
      } else {
        setValue('personaPoliticamenteExpuesta', 'No');
      }
    }
  }, [numeroDoc, notCheck]);

  useEffect(() => {
    if (
      getIdentificationTypeName(tipoDocumento) !==
        'Cedula de Identidad y Electoral' &&
      personaPoliticamenteExpuesta === 'Si' &&
      !notCheck
    ) {
      setValue('personaPoliticamenteExpuesta', 'No');
      setValue('pepActivo', 'No');
      setValue('cargoPep', null);
      setValue('institucion', null);
      setValue('fechaInicioCargo', null);
      setValue('ultimaFechaCargo', null);
      setValue('tipoExposicionPolitica', 'DEFAULT');
      setValue('nombreDelFamiliar', null);
      setValue('tipoRelacionFamiliar', null);
    }
  }, [tipoDocumento, notCheck]);

  const getIdentificationTypeName = (tipoDocumento) => {
    const identificationType =
      identificationTypeList?.identificationTypeList?.find(
        (x) => x.id === tipoDocumento
      );
    return identificationType?.name;
  };

  const getPoliticExposureTypeName = (politicExposureTypeId) => {
    const politicExposureType =
      politicExposureTypes?.politicExposureTypes?.find(
        (x) => x.id === politicExposureTypeId
      );
    return politicExposureType?.name;
  };

  const verifiyIfIsPep = () => {
    checkPoliticExposure({
      variables: {
        identification: numeroDoc,
      },
    });
  };

  useEffect(() => {
    if (checkPoliticExposureResponse) {
      setValue(
        'personaPoliticamenteExpuesta',
        convertBooleanToInput(
          checkPoliticExposureResponse.checkPoliticExposure.isPEP
        )
      );
      if (checkPoliticExposureResponse.checkPoliticExposure.isPEP === true) {
        setValue(
          'cargoPep',
          checkPoliticExposureResponse.checkPoliticExposure.charge
        );
        setValue(
          'tipoExposicionPolitica',
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .id
        );
        if (
          checkPoliticExposureResponse.checkPoliticExposure.politicExposureType
            .name === 'Familiar'
        ) {
          setValue(
            'nombreDelFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure.relativeName
          );
          setValue(
            'tipoRelacionFamiliar',
            checkPoliticExposureResponse.checkPoliticExposure
              .relativeRelationship
          );
        }
        toast('Esta es una Persona Politicamente Expuesta', {
          type: 'warning',
          theme: 'colored',
        });
      } else {
        toast('Esta no es una Persona Politicamente Expuesta', {
          type: 'success',
          theme: 'colored',
        });
      }
    }
  }, [checkPoliticExposureResponse]);

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if(formResponseByIdentification?.data?.formResponses?.length){
      setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);

      getCounselMembersSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (counselMembersSectionData?.data) {
      setCounselMembersSectionId(
        counselMembersSectionData?.data?.counselMembersSection?.id
      );
      setLoadingSection(false);
    }
  }, [counselMembersSectionData]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedCounselMemberId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const handleBackNavigation = () => {
    if(isContrapart){
      if(!isLIstedOnStock){
        navigate(
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
        );
      }
      else{
        if(hasBCRDAccount){
          dispatch(setSelectedSection('Información De Mi Empresa'));
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
          );
        }
        else if(!isForeign) { 
          dispatch(setSelectedSection('Información De Mi Empresa'));
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
          );
        }
        else{
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/cuentas-bancarias'
          );
        }
      }
    }
    else{
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/tarjeta-de-firmas'
      );
    }
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/correos-de-correspondencia'
    );
  };

  const addCounselMember = async () => {
    const identificationIsInUse =
      counselMembersSectionData?.data?.counselMembersSection?.counselMembers?.find(
        (counselMember) =>
          counselMember.id !== selectedCounselMemberId &&
          numeroDoc === counselMember.identification &&
          counselMember.identificationType.id === tipoDocumento
      );

    if (identificationIsInUse) {
      toast('Ya existe un miembro del consejo con esta identificación', {
        type: 'error',
        theme: 'colored',
      });
      return;
    }

    let data = {};

    //Si counselMembersSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (counselMembersSectionId) {
      // Si selectedCounselMemberId tiene valor entonces se edita en lugar de agregar
      if (selectedCounselMemberId) {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: counselMembersSectionId,
          counselMembersInputTypes: {
            id: selectedCounselMemberId,
            firstName: nombres,
            identification: numeroDoc,
            identificationTypeId: tipoDocumento,
            lastName: apellidos,
            nationalityCountryId: paisNacionalidad?.id,
            position: posicion,
            politicExposureInputType:
              personaPoliticamenteExpuesta === 'Si'
                ? {
                  id: politicExposureId ? politicExposureId : null,
                  isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                  active: convertInputToBoolean(pepActivo),
                  charge: cargoPep,
                  relativeName: nombreDelFamiliar,
                  relativeRelationship: tipoRelacionFamiliar,
                  politicExposureTypeId: tipoExposicionPolitica,
                  chargeStartDate: fechaInicioCargo
                    ? parseSimple(preFormat(fechaInicioCargo))
                    : null,
                  chargeEndDate: ultimaFechaCargo
                    ? parseSimple(preFormat(ultimaFechaCargo))
                    : null,
                  institution: institucion,
                }
                : {},
            contactFatcaInputType: {
              id: fatcaId,
              haveUnitedStatesNationality:
                convertInputToBoolean(tieneNacionalidadEU),
              isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
              isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
              socialSecurityNumber: seguroSocial,
              zipInUnitedStates: codigoPostalEU,
              phoneInUnitedStates: telefonoEU,
              haveRepresentativeInUnitedStates:
                convertInputToBoolean(tieneRepresentanteEU),
              haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
              taxIdentificationNumber: tin,
              greenCard: tarjetaVerde,
              addressInUnitedStates: direccionEU,
            },
          },
        };

        setSelectedCounselMemberId('');
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: counselMembersSectionId,
          counselMembersInputTypes: {
            id: v4(),
            firstName: nombres,
            identification: numeroDoc,
            identificationTypeId: tipoDocumento,
            lastName: apellidos,
            nationalityCountryId: paisNacionalidad?.id,
            position: posicion,
            politicExposureInputType:
              personaPoliticamenteExpuesta === 'Si'
                ? {
                  isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                  active: convertInputToBoolean(pepActivo),
                  charge: cargoPep,
                  relativeName: nombreDelFamiliar,
                  relativeRelationship: tipoRelacionFamiliar,
                  politicExposureTypeId: tipoExposicionPolitica,
                  chargeStartDate: fechaInicioCargo
                    ? parseSimple(preFormat(fechaInicioCargo))
                    : null,
                  chargeEndDate: ultimaFechaCargo
                    ? parseSimple(preFormat(ultimaFechaCargo))
                    : null,
                  institution: institucion,
                }
                : {},
            contactFatcaInputType: {
              haveUnitedStatesNationality:
                convertInputToBoolean(tieneNacionalidadEU),
              isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
              isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
              socialSecurityNumber: seguroSocial,
              zipInUnitedStates: codigoPostalEU,
              phoneInUnitedStates: telefonoEU,
              haveRepresentativeInUnitedStates:
                convertInputToBoolean(tieneRepresentanteEU),
              haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
              taxIdentificationNumber: tin,
              greenCard: tarjetaVerde,
              addressInUnitedStates: direccionEU,
            },
          },
        };
      }
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        counselMembersInputTypes: {
          id: v4(),
          firstName: nombres,
          identification: numeroDoc,
          identificationTypeId: tipoDocumento,
          lastName: apellidos,
          nationalityCountryId: paisNacionalidad?.id,
          position: posicion,
          politicExposureInputType:
            personaPoliticamenteExpuesta === 'Si'
              ? {
                isPep: convertInputToBoolean(personaPoliticamenteExpuesta),
                active: convertInputToBoolean(pepActivo),
                charge: cargoPep,
                relativeName: nombreDelFamiliar,
                relativeRelationship: tipoRelacionFamiliar,
                politicExposureTypeId: tipoExposicionPolitica,
                chargeStartDate: fechaInicioCargo
                  ? parseSimple(preFormat(fechaInicioCargo))
                  : null,
                chargeEndDate: ultimaFechaCargo
                  ? parseSimple(preFormat(ultimaFechaCargo))
                  : null,
                institution: institucion,
              }
              : {},
          contactFatcaInputType: {
            haveUnitedStatesNationality:
              convertInputToBoolean(tieneNacionalidadEU),
            isUnitedStatesCitizen: convertInputToBoolean(esCiudadanoEU),
            isUnitedStatesResident: convertInputToBoolean(esResidenteEU),
            socialSecurityNumber: seguroSocial,
            zipInUnitedStates: codigoPostalEU,
            phoneInUnitedStates: telefonoEU,
            haveRepresentativeInUnitedStates:
              convertInputToBoolean(tieneRepresentanteEU),
            haveTaxIdentificationNumber: convertInputToBoolean(tieneTIN),
            taxIdentificationNumber: tin,
            greenCard: tarjetaVerde,
            addressInUnitedStates: direccionEU,
          },
        },
      };
    }

    btnCloseModalRef.current.click();
    resetAllInputs();

    const saveData = await saveCounselMembersSection({
      variables: {
        counselMembersSection: data,
      },
    });

    loadPersons(dispatch, {
      legalRepresentatives: persons.legalRepresentatives,
      shareholders: persons.shareholders,
      signatores: persons.signatores,
      beneficiaries: persons.beneficiaries,
      counselMembers: saveData.data?.saveCounselMembersSection
        ?.counselMembers ?? [],
    });

    setIsUpdating(false);
  };

  const deleteCardElement = async (id) => {
    await removeCounselMember({
      variables: {
        counselMemberId: id,
      },
    });

    loadPersons(dispatch, {
      legalRepresentatives: persons.legalRepresentatives,
      shareholders: persons.shareholders,
      signatores: persons.signatores,
      beneficiaries: persons.beneficiaries,
      counselMembers: persons.counselMembers
        .filter((counselMember) => counselMember.id !== id) ?? [],
    });
  };

  const handleEdit = (id) => {
    setNotCheck(true);
    setSelectedCounselMemberId(id);
    const selectedCounselMember =
      counselMembersSectionData?.data?.counselMembersSection?.counselMembers?.find(
        (x) => x.id === id
      );

    if (id && selectedCounselMember) {
      setForm(selectedCounselMember);
    }
  };

  const setForm = (selectedCounselMember) => {
    selectedCounselMember?.firstName &&
      setValue('nombres', selectedCounselMember?.firstName);
    selectedCounselMember?.lastName &&
      setValue('apellidos', selectedCounselMember?.lastName);
    if (selectedCounselMember?.identificationType?.id) {
      setValue('tipoDocumento', selectedCounselMember?.identificationType.id);
    }
    if (
      selectedCounselMember?.identificationNumber ||
      selectedCounselMember?.identification
    ) {
      setValue(
        'numeroDoc',
        selectedCounselMember?.identificationNumber ||
          selectedCounselMember?.identification
      );
    }
    if (selectedCounselMember?.occupation || selectedCounselMember?.position) {
      setValue(
        'posicion',
        selectedCounselMember?.occupation || selectedCounselMember?.position
      );
    }
    if (selectedCounselMember?.nationalityCountry?.id) {
      setValue('paisNacionalidad', {
        label: selectedCounselMember?.nationalityCountry?.name,
        id: selectedCounselMember?.nationalityCountry?.id,
      });
      setPaisNacionalidad({
        label: selectedCounselMember?.nationalityCountry?.name,
        id: selectedCounselMember?.nationalityCountry?.id,
      });
    }
    selectedCounselMember?.politicExposure?.id &&
      setValue(
        'personaPoliticamenteExpuesta',
        selectedCounselMember?.politicExposure?.isPep ? 'Si' : 'No'
      );
    selectedCounselMember?.politicExposure?.id &&
      setPoliticExposureId(selectedCounselMember?.politicExposure?.id);

    if (
      selectedCounselMember?.politicExposure?.id &&
      selectedCounselMember?.politicExposure?.isPep
    ) {
      setValue(
        'pepActivo',
        selectedCounselMember?.politicExposure?.politicExposureDetails?.active
          ? 'Si'
          : 'No'
      );
      setValue(
        'cargoPep',
        selectedCounselMember?.politicExposure?.politicExposureDetails?.charge
      );
      setValue(
        'institucion',
        selectedCounselMember?.politicExposure?.politicExposureDetails
          ?.institution
      );
      setValue(
        'fechaInicioCargo',
        selectedCounselMember?.politicExposure?.politicExposureDetails
          ?.chargeStartDate
          ? formatSimple(
              new Date(
                selectedCounselMember?.politicExposure?.politicExposureDetails?.chargeStartDate
              )
            )
          : null
      );
      setValue(
        'ultimaFechaCargo',
        selectedCounselMember?.politicExposure?.politicExposureDetails
          ?.chargeEndDate
          ? formatSimple(
              new Date(
                selectedCounselMember?.politicExposure?.politicExposureDetails?.chargeEndDate
              )
            )
          : null
      );
      setValue(
        'tipoExposicionPolitica',
        selectedCounselMember?.politicExposure?.politicExposureDetails
          ?.politicExposureType?.id
      );
      setValue(
        'nombreDelFamiliar',
        selectedCounselMember?.politicExposure?.politicExposureDetails
          ?.relativeName
      );
      setValue(
        'tipoRelacionFamiliar',
        selectedCounselMember?.politicExposure?.politicExposureDetails
          ?.relativeRelationship
      );
    }

    if (selectedCounselMember?.contactFatca) {
      setFatcaId(selectedCounselMember?.contactFatca?.id);
      if (
        selectedCounselMember?.contactFatca?.haveUnitedStatesNationality !==
          null &&
        selectedCounselMember?.contactFatca?.haveUnitedStatesNationality !==
          undefined
      ) {
        setValue(
          'tieneNacionalidadEU',
          convertBooleanToInput(
            selectedCounselMember?.contactFatca?.haveUnitedStatesNationality
          )
        );
      }
      if (
        selectedCounselMember?.contactFatca?.isUnitedStatesCitizen !== null &&
        selectedCounselMember?.contactFatca?.isUnitedStatesCitizen !== undefined
      ) {
        setValue(
          'esCiudadanoEU',
          convertBooleanToInput(
            selectedCounselMember?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      if (
        selectedCounselMember?.contactFatca?.isUnitedStatesResident !== null &&
        selectedCounselMember?.contactFatca?.isUnitedStatesResident !== undefined
      ) {
        setValue(
          'esResidenteEU',
          convertBooleanToInput(
            selectedCounselMember?.contactFatca?.isUnitedStatesResident
          )
        );
      }
      if (
        selectedCounselMember?.contactFatca?.haveRepresentativeInUnitedStates !==
          null &&
        selectedCounselMember?.contactFatca?.haveRepresentativeInUnitedStates !==
          undefined
      ) {
        setValue(
          'tieneRepresentanteEU',
          convertBooleanToInput(
            selectedCounselMember?.contactFatca?.haveRepresentativeInUnitedStates
          )
        );
      }
      if (
        selectedCounselMember?.contactFatca?.haveTaxIdentificationNumber !==
          null &&
        selectedCounselMember?.contactFatca?.haveTaxIdentificationNumber !==
          undefined
      ) {
        setValue(
          'tieneTIN',
          convertBooleanToInput(
            selectedCounselMember?.contactFatca?.isUnitedStatesCitizen
          )
        );
      }
      selectedCounselMember?.contactFatca?.socialSecurityNumber &&
        setValue(
          'seguroSocial',
          selectedCounselMember?.contactFatca?.socialSecurityNumber
        );
      selectedCounselMember?.contactFatca?.zipInUnitedStates &&
        setValue(
          'codigoPostalEU',
          selectedCounselMember?.contactFatca?.zipInUnitedStates
        );
      selectedCounselMember?.contactFatca?.phoneInUnitedStates &&
        setValue(
          'telefonoEU',
          selectedCounselMember?.contactFatca?.phoneInUnitedStates
        );
      selectedCounselMember?.contactFatca?.taxIdentificationNumber &&
        setValue(
          'tin',
          selectedCounselMember?.contactFatca?.taxIdentificationNumber
        );
      selectedCounselMember?.contactFatca?.greenCard &&
        setValue('tarjetaVerde', selectedCounselMember?.contactFatca?.greenCard);
      selectedCounselMember?.contactFatca?.addressInUnitedStates &&
        setValue(
          'direccionEU',
          selectedCounselMember?.contactFatca?.addressInUnitedStates
        );
    }
  };

  const copiarContacto = watch('copiarContacto');
  const contacto = watch('contacto');

  useEffect(() => {
    let shareholders = [];
    persons.shareholders.forEach((sh) => {
      if (sh.personType.name === 'Persona Física') {
        shareholders.push({
          id: sh.id,
          firstName: sh.shareholderPersonDetails.firstName ?? '',
          lastName: sh.shareholderPersonDetails.lastName ?? '',
          identificationType: sh.identificationType,
          identification: sh.identification,
          position: sh.shareholderPersonDetails.charge,
          isInDarkList: sh.isInDarkList,
          politicExposure: sh.shareholderPersonDetails.politicExposure,
          nationalityCountry: sh.country,
          contactFatca: sh.contactFatca,
        });
      }
    });

    const signatores = persons.signatores.map((sig) => ({
      id: sig.id,
      firstName: sig.firstName,
      lastName: sig.lastName,
      identification: sig.identification,
      position: sig.charge,
      email: sig.email,
    }));

    const legalRepresentatives = persons.legalRepresentatives.map((legal) => ({
      id: legal.id,
      firstName: legal.firstName,
      lastName: legal.lastName,
      email: legal.correspondenceEmail?.email,
      identificationType: legal.identificationType,
      identification: legal.identification,
      position: legal.position,
      nationalityCountry: legal.nationalityCountry,
      isInDarkList: legal.isInDarkList,
      politicExposure: legal.politicExposure,
      contactFatca: legal.contactFatca,
      address: comprexAddress(legal.address),
    }));

    let shareholdersBeneficiaries = [];

    persons.shareholders.forEach((sh) => {
      sh.beneficiaries.forEach((beneficiary) =>
        shareholdersBeneficiaries.push({
          id: beneficiary.id,
          firstName: beneficiary.firstName,
          lastName: beneficiary.lastName,
          email: beneficiary.email,
          identificationType: beneficiary.identificationType,
          identification: beneficiary.identificationNumber,
          position: beneficiary.occupation,
          politicExposure: beneficiary.politicExposure,
          phoneNumber: beneficiary.phoneNumber,
          address: beneficiary.address,
          contactFatca: beneficiary.contactFatca,
        })
      );
    });

    const beneficiaries = persons.beneficiaries.map((beneficiary) => ({
      id: beneficiary.id,
      firstName: beneficiary.firstName,
      lastName: beneficiary.lastName,
      email: beneficiary.email,
      identificationType: beneficiary.identificationType,
      identification: beneficiary.identificationNumber,
      position: beneficiary.occupation,
      politicExposure: beneficiary.politicExposure,
      contactFatca: beneficiary.contactFatca,
      address: beneficiary.address,
    }));

    let personsToCopy = [
      ...shareholders,
      ...signatores,
      ...legalRepresentatives,
      ...shareholdersBeneficiaries,
      ...beneficiaries,
    ];

    personsToCopy = mergeObjectsWithSameId(personsToCopy);

    const idsToRemove = new Set(
      persons.counselMembers.map((item) => item.identification)
    );
    personsToCopy = personsToCopy.filter(
      (item) => !idsToRemove.has(item.identification)
    );

    setContacts(personsToCopy);
  }, [persons]);

  useEffect(() => {
    if (contacto && contacto !== 'DEFAULT') {
      const personToCopy = contacts.find((contact) => contact.id === contacto);
      resetAllInputs();
      setValue('contacto', contacto);
      setValue('copiarContacto', copiarContacto);
      setForm(personToCopy);
    }
  }, [contacto]);

  
  const resetAllInputs = () => {
    reset();
    setInputPaisNacionalidad('');
    setPaisNacionalidad('');
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'counselMembersSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Miembros del Consejo
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={
                'Miembros del Consejo'
              }
              description={
                ""
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'counselMembers'
                      ? 'Es necesario que agregues por lo menos un miembro del consejo.'
                      : `Hay uno o varios miembros del consejo con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>

          <h4 className="bff-sub-title">
            Si tienes más de un miembro del consejo, puedes agregarlos todos aquí.
          </h4>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {counselMembersSectionData?.data?.counselMembersSection?.counselMembers?.map(
                (counselMember, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={counselMember?.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardItems
                        key={index}
                        nameElement={
                          counselMember?.firstName + ' ' + counselMember?.lastName
                        }
                        identityDocument={counselMember?.identification}
                        idElement={counselMember?.id}
                        deleteElement={deleteCardElement}
                        handleEdit={handleEdit}
                        setIsUpdating={setIsUpdating}
                        showDeleteButton={!isFormCompleted && !isFormDisqualified}
                        detalle={
                          counselMember?.politicExposure?.isPep
                            ? 'Persona politicamente expuesta'
                            : null
                        }
                      />
                    </div>
                  );
                }
              )}
              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    resetAllInputs();
                    setExistingCounselMember(false);
                    setSelectedCounselMemberId('');
                    setFatcaId('');
                    setPoliticExposureId('');
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">Agregar Miembro del Consejo</h4>
                </div>
              ) : null}
              { (isFormCompleted || isFormDisqualified) && !counselMembersSectionData?.data?.counselMembersSection?.counselMembers?.length ?
                <div className="add-card" style={{ pointerEvents: 'none' }}>
                  <div className="image-icon-container">
                    <FontAwesomeIcon
                      icon={faWarning}
                      className="image-icon"
                    />
                  </div>
                  <h4 className="bk-card-title">
                    {' '}
                    No hay miembros del consejo que mostrar{' '}
                  </h4>
                </div> : null
              }
            </div>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="button"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <form
              onSubmit={handleSubmit(addCounselMember)}
              className="fields-modal-container"
            >
              <div className="fields-modal-header">
                <h4 className="modal-title" id="exampleModalLabel">
                  {!isFormCompleted && !isFormDisqualified
                    ? selectedCounselMemberId
                      ? 'Editar'
                      : 'Agregar'
                    : 'Ver'}{' '}
                  Miembro del Consejo
                </h4>
                <button
                  type="button"
                  ref={btnCloseModalRef}
                  onClick={() => {
                    resetAllInputs();
                    setExistingCounselMember(false);
                    setSelectedCounselMemberId('');
                    setFatcaId('');
                    setPoliticExposureId('');
                  }}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="row fields-modal-content">
                <div className="row">
                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="field-form col-md-4 col-sm-12">
                      <label htmlFor="copiar-contacto" className="label-field">
                        ¿Copiar información de otro contacto?{' '}
                      </label>
                      <div className="selectWrapper">
                        <select
                          id="copiar-contacto"
                          defaultValue="No"
                          {...register('copiarContacto')}
                        >
                          <option value="No">No</option>
                          <option value="Si">Si</option>
                        </select>
                      </div>
                    </div>
                  ) : null}
                  {!isFormCompleted && !isFormDisqualified && copiarContacto === 'Si' ? (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="contacto" className="label-field">
                        Contacto <span>*</span>
                      </label>
                      <div className="selectWrapper">
                        <select
                          id="contacto"
                          defaultValue={'DEFAULT'}
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('contacto', {
                            validate: validateNonSelectedOptionInDropdown,
                          })}
                        >
                          <option value="DEFAULT" disabled>
                            Selecciona
                          </option>
                          {contacts.map((contact) => {
                            return (
                              <option key={contact?.id} value={contact?.id}>
                                {contact?.firstName + ' ' + contact?.lastName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {errors.copiarContacto && (
                        <p className="text-danger">
                          Debe seleccionar una opción
                        </p>
                      )}
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="nombres" className="label-field">
                      Nombres <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="nombres"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('nombres', {
                        required: true,
                      })}
                    />
                    {errors.nombres?.type === 'required' && (
                      <p className="text-danger">
                        El campo Nombres es requerido
                      </p>
                    )}
                    {selectedCounselMemberId &&
                      !nombres &&
                      errors.nombres?.type !== 'required' && (
                        <ErrorText hasError={hasError('firstName')} />
                      )}
                  </div>

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="apellidos" className="label-field">
                      Apellidos <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="apellidos"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('apellidos', {
                        required: true,
                      })}
                    />
                    {errors.apellidos?.type === 'required' && (
                      <p className="text-danger">
                        El campo Apellidos es requerido
                      </p>
                    )}
                    {selectedCounselMemberId &&
                      !apellidos &&
                      errors.apellidos?.type !== 'required' && (
                        <ErrorText hasError={hasError('lastName')} />
                      )}
                  </div>

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="tipo-documento" className="label-field">
                      Tipo de documento <span>*</span>
                    </label>
                    <div className="selectWrapper">
                      {' '}
                      <select
                        id="tipo-documento"
                        defaultValue={'DEFAULT'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('tipoDocumento', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {identificationTypeList?.identificationTypeList.map(
                          (identificationType) => {
                            return (
                              <option
                                key={identificationType.id}
                                value={identificationType.id}
                              >
                                {identificationType.name}
                              </option>
                            );
                          }
                        )}
                      </select>
                    </div>
                    {errors.tipoDocumento && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                    {selectedCounselMemberId &&
                      (!tipoDocumento || tipoDocumento === 'DEFAULT') &&
                      errors.tipoDocumento && (
                        <ErrorText hasError={hasError('identificationType')} />
                      )}
                  </div>

                  {tipoDocumento &&
                  identificationTypeList?.identificationTypeList &&
                  identificationTypeList?.identificationTypeList
                    ?.find((i) => i.id === tipoDocumento)
                    ?.name.toLowerCase()
                    .includes('cedula') ? (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="cedula" className="label-field">
                        Número de documento de identidad <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        maxLength="13"
                        pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                        placeholder="000-0000000-0"
                        id="cedula"
                        disabled={isFormCompleted || isFormDisqualified}
                        onInput={() => {
                          setNotCheck(false);
                          validateInputCedula();
                        }}
                        {...register('numeroDoc', {
                          required: true,
                        })}
                      />
                      {errors.numeroDoc?.type === 'required' && (
                        <p className="text-danger">
                          El campo Número de documento es requerido
                        </p>
                      )}
                      {existingCounselMember ? (
                        <p className="text-danger">
                          Ya existe un beneficiario registrado con ese número de
                          documento.
                        </p>
                      ) : null}
                      {selectedCounselMemberId &&
                        !numeroDoc &&
                        errors.numeroDoc?.type !== 'required' && (
                          <ErrorText
                            hasError={hasError('identification')}
                          />
                        )}
                    </div>
                  ) : tipoDocumento &&
                    identificationTypeList?.identificationTypeList &&
                    identificationTypeList?.identificationTypeList
                      ?.find((i) => i.id === tipoDocumento)
                      ?.name.toLowerCase()
                      .includes('rnc') ? (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="rnc" className="label-field">
                        Número de documento de identidad <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        maxLength="11"
                        pattern="[0-9]{3}-?[0-9]{5}-?[0-9]{1}"
                        placeholder="000-00000-0"
                        disabled={isFormCompleted || isFormDisqualified}
                        onInput={validateInputRnc}
                        id="rnc"
                        {...register('numeroDoc', {
                          required: true,
                        })}
                      />
                      {errors.numeroDoc?.type === 'required' && (
                        <p className="text-danger">
                          El campo Número de documento es requerido
                        </p>
                      )}
                      {existingCounselMember ? (
                        <p className="text-danger">
                          Ya existe un beneficiario registrado con ese número de
                          documento.
                        </p>
                      ) : null}
                      {selectedCounselMemberId &&
                        !numeroDoc &&
                        errors.numeroDoc?.type !== 'required' && (
                          <ErrorText
                            hasError={hasError('identification')}
                          />
                        )}
                    </div>
                  ) : (
                    <div className="field-form  col-md-4 col-sm-12">
                      <label htmlFor="numero-doc" className="label-field">
                        Número de documento de identidad <span>*</span>
                      </label>
                      <input
                        type="text"
                        className="input-form"
                        placeholder=""
                        id="numero-doc"
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('numeroDoc', {
                          required: true,
                        })}
                      />
                      {errors.numeroDoc?.type === 'required' && (
                        <p className="text-danger">
                          El campo Número de documento es requerido
                        </p>
                      )}
                      {existingCounselMember ? (
                        <p className="text-danger">
                          Ya existe un beneficiario registrado con ese número de
                          documento.
                        </p>
                      ) : null}
                      {selectedCounselMemberId &&
                        !numeroDoc &&
                        errors.numeroDoc?.type !== 'required' && (
                          <ErrorText
                            hasError={hasError('identification')}
                          />
                        )}
                    </div>
                  )}

                  <div className="field-form col-lg-4 col-sm-12">
                    <label htmlFor="posicion" className="label-field">
                      Posicion en la empresa{' '}
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="posicion"
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('posicion')}
                    />
                  </div>

                  <div className="field-form col-md-4 col-sm-12 mt-1 special-autocomplete">
                    <label
                      htmlFor="pais-nacionalidad-representante-legal"
                      className="label-field"
                    >
                      País de nacionalidad <span>*</span>
                    </label>
                    <Autocomplete
                      disablePortal
                      id="controllable-states-demo"
                      value={paisNacionalidad}
                      disabled={isFormCompleted || isFormDisqualified}
                      onChange={(event, newValue) => {
                        setPaisNacionalidad(newValue);
                      }}
                      inputValue={inputPaisNacionalidad}
                      onInputChange={(event, newInputValue) => {
                        setInputPaisNacionalidad(newInputValue);
                      }}
                      options={
                        countries?.countries?.length
                          ? countries?.countries?.map((type) => ({
                            label: type.name,
                            id: type.id,
                          }))
                          : []
                      }
                      renderInput={(params) => (
                        <TextField
                          placeholder={
                            countries?.countries?.length
                              ? 'Busca y selecciona'
                              : 'Cargando...'
                          }
                          {...params}
                          {...register('paisNacionalidad', {
                            required: true,
                          })}
                        />
                      )}
                    />
                    <p
                      className="text-danger"
                      style={{
                        marginTop: 8,
                        visibility: errors.paisNacionalidad
                          ? 'visible'
                          : 'hidden',
                      }}
                    >
                      Debe seleccionar una opción
                    </p>
                    {selectedCounselMemberId &&
                      !paisNacionalidad &&
                      !errors.paisNacionalidad && (
                        <ErrorText
                          hasError={hasError('nationalityCountry')}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="row fields-modal-content">
                <div className="fields-modal-header">
                  <h4 className="modal-title" id="exampleModalLabel">
                    Persona políticamente expuesta
                  </h4>
                </div>
                <div className="row">
                  <div className="field-form  col-md-4 col-sm-12">
                    <label
                      htmlFor="persona-politicamente-expuesta"
                      className="label-field"
                    >
                      ¿Es una persona políticamente expuesta?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="persona-politicamente-expuesta"
                        defaultValue={'No'}
                        disabled={
                          (isFormCompleted || isFormDisqualified) ||
                          ((getIdentificationTypeName(tipoDocumento) ===
                            'Cedula de Identidad y Electoral' &&
                            !checkPoliticExposureError?.message) ||
                          getIdentificationTypeName(tipoDocumento) === undefined
                            ? true
                            : false)
                        }
                        {...register('personaPoliticamenteExpuesta', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  {personaPoliticamenteExpuesta === 'Si' ? (
                    <>
                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="tipoExposicionPolitica"
                          className="label-field"
                        >
                          Tipo de exposición política <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tipoExposicionPolitica"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoExposicionPolitica', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {politicExposureTypes?.politicExposureTypes?.map(
                              (option) => (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.tipoExposicionPolitica && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {selectedCounselMemberId &&
                          (!tipoExposicionPolitica ||
                            tipoExposicionPolitica === 'DEFAULT') &&
                          errors.tipoExposicionPolitica && (
                            <ErrorText
                              hasError={hasError('politicExposureType')}
                            />
                          )}
                      </div>

                      <div className="field-form  col-md-12 col-sm-12">
                        <label htmlFor="cargo-pep" className="label-field">
                          Cargo <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="cargo-pep"
                          disabled={isFormCompleted || isFormDisqualified}
                          readOnly={
                            checkPoliticExposureResponse &&
                            (getIdentificationTypeName(tipoDocumento) ===
                              'Cedula de Identidad y Electoral' ||
                            getIdentificationTypeName(tipoDocumento) ===
                              undefined
                              ? true
                              : false)
                          }
                          {...register('cargoPep', {
                            required: true,
                          })}
                        />
                        {errors.cargoPep?.type === 'required' && (
                          <p className="text-danger">
                            El campo Cargo es requerido
                          </p>
                        )}
                        {selectedCounselMemberId &&
                          !cargoPep &&
                          errors.cargoPep?.type !== 'required' && (
                            <ErrorText hasError={hasError('charge')} />
                          )}
                      </div>

                      {getPoliticExposureTypeName(tipoExposicionPolitica) ===
                      'Familiar' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="tipoRelacionFamiliar"
                            className="label-field"
                          >
                            Indique su parentesco con ese familiar{' '}
                            <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="tipoRelacionFamiliar"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoRelacionFamiliar', {
                              required: true,
                              maxLength: 100,
                            })}
                          />
                          {errors.tipoRelacionFamiliar?.type === 'required' && (
                            <p className="text-danger">
                              Este campo es requerido
                            </p>
                          )}
                          {errors.tipoRelacionFamiliar?.type ===
                            'maxLength' && (
                            <p className="text-danger">
                              Este campo solo puede tener 100 caracteres como
                              máximo
                            </p>
                          )}
                          {selectedCounselMemberId &&
                            !tipoRelacionFamiliar &&
                            errors.tipoRelacionFamiliar?.type !==
                              'required' && (
                              <ErrorText
                                hasError={hasError('relativeRelationship')}
                              />
                            )}
                        </div>
                      ) : null}

                      {getPoliticExposureTypeName(tipoExposicionPolitica) ===
                      'Familiar' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label
                            htmlFor="nombreDelFamiliar"
                            className="label-field"
                          >
                            Nombre completo del familiar <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="nombreDelFamiliar"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('nombreDelFamiliar', {
                              required: true,
                              maxLength: 100,
                            })}
                          />
                          {errors.nombreDelFamiliar?.type === 'required' && (
                            <p className="text-danger">
                              Este campo es requerido
                            </p>
                          )}
                          {errors.nombreDelFamiliar?.type === 'maxLength' && (
                            <p className="text-danger">
                              Este campo solo puede tener 100 caracteres como
                              máximo
                            </p>
                          )}
                          {selectedCounselMemberId &&
                            !nombreDelFamiliar &&
                            errors.nombreDelFamiliar?.type !== 'required' && (
                              <ErrorText hasError={hasError('relativeName')} />
                            )}
                        </div>
                      ) : null}

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="pep-activo" className="label-field">
                          ¿Está activo en el cargo?{' '}
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="pep-activo"
                            defaultValue={'No'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('pepActivo', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="fecha-inicio-cargo"
                          className="label-field"
                        >
                          Fecha de inicio en el cargo <span>*</span>
                        </label>
                        <input
                          type="date"
                          className="input-form"
                          placeholder=""
                          id="fecha-inicio-cargo"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('fechaInicioCargo', {
                            required: true,
                          })}
                        />
                        {errors.fechaInicioCargo?.type === 'required' && (
                          <p className="text-danger">
                            El campo Fecha de inicio en el cargo es requerido
                          </p>
                        )}
                        {selectedCounselMemberId &&
                          !fechaInicioCargo &&
                          errors.fechaInicioCargo?.type !== 'required' && (
                            <ErrorText hasError={hasError('chargeStartDate')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="ultima-fecha-cargo"
                          className="label-field"
                        >
                          Última fecha en el cargo{' '}
                          <span>{pepActivo === 'No' ? '*' : ''}</span>
                        </label>
                        <input
                          type="date"
                          disabled={(isFormCompleted || isFormDisqualified) || pepActivo === 'Si'}
                          className="input-form"
                          placeholder=""
                          id="ultima-fecha-cargo"
                          {...register('ultimaFechaCargo', {
                            required: pepActivo === 'No',
                          })}
                        />
                        {errors.ultimaFechaCargo?.type === 'required' && (
                          <p className="text-danger">
                            El campo Última fecha en el cargo es requerido
                          </p>
                        )}
                        {selectedCounselMemberId &&
                          !ultimaFechaCargo &&
                          errors.ultimaFechaCargo?.type !== 'required' && (
                            <ErrorText hasError={hasError('chargeEndDate')} />
                          )}
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="institucion" className="label-field">
                          Institución <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="institucion"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('institucion', {
                            required: true,
                          })}
                        />
                        {errors.institucion?.type === 'required' && (
                          <p className="text-danger">
                            El campo Institución es requerido
                          </p>
                        )}
                        {selectedCounselMemberId &&
                          !institucion &&
                          errors.institucion?.type !== 'required' && (
                            <ErrorText hasError={hasError('institution')} />
                          )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              <div className="row fields-modal-content">
                <div className="fields-modal-header">
                  <h4 className="modal-title" id="exampleModalLabel">
                    Foreign Account Tax Compliance Act
                  </h4>
                </div>
                <div className="row">
                  <div className="field-form  col-md-4 col-sm-12">
                    <label
                      htmlFor="tieneNacionalidadEU"
                      className="label-field"
                    >
                      ¿Tiene nacionalidad de EE.UU.?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="tieneNacionalidadEU"
                        defaultValue={'No'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('tieneNacionalidadEU', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="field-form  col-md-4 col-sm-12">
                    <label htmlFor="esCiudadanoEU" className="label-field">
                      ¿Es ciudadano de los EE.UU.?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="esCiudadanoEU"
                        defaultValue={'No'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('esCiudadanoEU', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                  <div className="field-form  col-md-4 col-sm-12">
                    <label htmlFor="esResidenteEU" className="label-field">
                      ¿Es residente de los EE.UU.?{' '}
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="esResidenteEU"
                        defaultValue={'No'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('esResidenteEU', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="Si">Si</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>

                  {tieneNacionalidadEU === 'Si' ||
                  esCiudadanoEU === 'Si' ||
                  esResidenteEU === 'Si' ? (
                    <>
                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="codigoPostalEU" className="label-field">
                          Código Postal en los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="codigoPostalEU"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('codigoPostalEU')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="telefonoEU" className="label-field">
                          Télefono en los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="telefonoEU"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('telefonoEU')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label
                          htmlFor="tieneRepresentanteEU"
                          className="label-field"
                        >
                          ¿Tiene un apoderado en los EE.UU.?{' '}
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tieneRepresentanteEU"
                            defaultValue={'No'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tieneRepresentanteEU', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="tieneTIN" className="label-field">
                          ¿Tiene identidad para el pago de impuestos (TIN)?{' '}
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="tieneTIN"
                            defaultValue={'No'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tieneTIN', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      {tieneTIN === 'Si' ? (
                        <div className="field-form  col-md-4 col-sm-12">
                          <label htmlFor="tin" className="label-field">
                            Número de Identificación Tributaria de los EE.UU.
                            (TIN) <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="tin"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tin', {
                              required: true,
                            })}
                          />
                          {errors.tin?.type === 'required' && (
                            <p className="text-danger">
                              El campo Número de Identificación Tributaria de
                              los EE.UU. (TIN) es requerido
                            </p>
                          )}
                          {selectedCounselMemberId &&
                            !tin &&
                            errors.tin?.type !== 'required' && (
                              <ErrorText
                                hasError={hasError('taxIdentificationNumber')}
                              />
                            )}
                        </div>
                      ) : null}

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="tarjetaVerde" className="label-field">
                          Tarjeta de Identidad de los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="tarjetaVerde"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('tarjetaVerde')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="direccionEU" className="label-field">
                          Dirección en los EE.UU.{' '}
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="direccionEU"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('direccionEU')}
                        />
                      </div>

                      <div className="field-form  col-md-4 col-sm-12">
                        <label htmlFor="seguroSocial" className="label-field">
                          Número de Seguro Social EE.UU. <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="seguroSocial"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('seguroSocial', {
                            required: true,
                          })}
                        />
                        {errors.seguroSocial?.type === 'required' && (
                          <p className="text-danger">
                            El campo Número de Seguro Social EE.UU. es requerido
                          </p>
                        )}
                        {selectedCounselMemberId &&
                          !seguroSocial &&
                          errors.seguroSocial?.type !== 'required' && (
                            <ErrorText
                              hasError={hasError('socialSecurityNumber')}
                            />
                          )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {!isFormCompleted && !isFormDisqualified ? (
                <div className="row d-flex justify-content-center">
                  <button type="submit" className="mt-5 mb-5 btn-add">
                    Guardar
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CounselMembersForm;