import './SelectAccountType.css';
import { Link, useNavigate } from 'react-router-dom';
import {
  faUserTie,
  faBuilding,
  faAngleRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Toast from '../../../shared/UIElements/Toast/Toast';
import IconPersonaFisica from '../../../shared/icons/iconPersonaFisica.svg';
import IconPersonaJuridica from '../../../shared/icons/iconPersonaJuridica.svg';

const SelectAccountType = () => {
  const [accountType, setAccountType] = useState('');
  const [displayToast, setDisplayToast] = useState(false);
  const navigate = useNavigate();

  const clickedCardStyles = {
    borderColor: '#f0bf2b',
  };

  const continueNextPage = () => {
    if (accountType !== '') {
      localStorage.setItem('accountTypeToStartRegister', accountType);

      if (accountType === 'physical-person') {
        navigate(`/identity-verification/${accountType}`);
      } else if (accountType === 'legal-person') {
        navigate(`/identity-verification/${accountType}`);
      }
    } else {
      setDisplayToast(!displayToast);
    }
  };

  const changeToastValue = () => {
    setDisplayToast(false);
  };

  return (
    <div className="page-container">
      <div className="logo-parval-container">
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="formTitle">
          Selecciona el tipo de cuenta que más se adapte a tus necesidades de
          inversión
        </h3>
        <div className="cards-container">
          <div
            className={`boxButton ${accountType === 'physical-person' ? 'selected' : ''}`}
            onClick={() => setAccountType('physical-person')}
          >
            <div>
              <div className="iconContainer">
                <img src={IconPersonaFisica} alt="" />
                {/* <button type='button' className='account-btn' onClick={navigateToIdentityVerificationForPhysicalPerson}>Abre tu cuenta como persona física</button> */}
              </div>
              <h4 className="rwf-card-title">Persona Física</h4>
              <p className="card-text">
                Es una cuenta para profesionales independientes (contadores,
                abogados, ingenieros, entre otros) o personas con un oficio, que
                realizan actividades generadoras de obligaciones. Se identifican
                con su nombre y apellido.
              </p>

              <Link
                target="_blank"
                to="/docs/persona-fisica.pdf"
                className="pdf-link"
              >
                Ver Requisitos
              </Link>
            </div>
          </div>

          <div
            className={`boxButton ${accountType === 'legal-person' ? 'selected' : ''}`}
            onClick={() => setAccountType('legal-person')}
          >
            <div>
              <div className="iconContainer">
                <img src={IconPersonaJuridica} alt="" />
                {/* <button type='button' className='account-btn' onClick={navigateToIdentityVerificationForPhysicalPerson}>Abre tu cuenta como persona física</button> */}
              </div>
              <h4 className="rwf-card-title">Persona Jurídica</h4>
              <p className="card-text">
                Son las entidades identificadas con una denominación social que
                se constituyen de acuerdo a las disposiciones de la ley sobre
                Sociedades Comerciales y Empresas Individuales de
                Responsabilidad Limitada establecidas en la ley No.479-08.
              </p>

              <Link
                target="_blank"
                to="/docs/persona-juridica.pdf"
                className="pdf-link"
              >
                Ver Requisitos
              </Link>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className={`mt-5 btn-continue ${accountType != '' ? 'active' : ''}`}
          onClick={continueNextPage}
        >
          Continuar &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>

        <Toast
          handleClick={changeToastValue}
          title={'Selección incompleta'}
          message={
            'Debe seleccionar un tipo de cuenta para continuar con su registro.'
          }
          selector={'a'}
          displayToast={displayToast}
        />
      </div>
    </div>
  );
};

export default SelectAccountType;
