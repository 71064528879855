import './ResidenceInformationForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';

const ResidenceInformationForm = ({
  validateForm,
  setValidateForm,
  goToBack,
}) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [paisObtenidoDeLaApi, setPaisObtenidoDeLaApi] = useState('');

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState('');
  const [provinces, setProvinces] = useState([]);
  const [towns, setTowns] = useState([]);
  const [sectors, setSectors] = useState([]);

  const paisResidencia = watch('paisResidencia');
  const provincia = watch('provincia');
  const municipio = watch('municipio');
  const sector = watch('sector');
  const calle = watch('calle');
  const casaNumero = watch('casaNumero');
  const edificio = watch('edificio');
  const numeroApartamento = watch('numeroApartamento');

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-fisica/informacion-de-nacionalidad');
    }
  }, [goToBack]);

  // useEffect(() => {
  //   // if(paisResidencia !== "680da2a1-169c-e711-8111-c4346bdcf161") {

  //   // }
  //   setValue("calle", "");
  // }, [paisResidencia]);

  useEffect(() => {
    if (countries.length > 0) {
      if (paisObtenidoDeLaApi !== '') {
        //alert(paisObtenidoDeLaApi);
        setValue('paisResidencia', paisObtenidoDeLaApi);
        setPaisObtenidoDeLaApi('');
      }
    }
  }, [countries]);

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveDataInBD = () => {};

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12 pe-4 columns">
          <div className="field-form">
            <label htmlFor="pais-residencia" className="label-field">
              País de residencia <span>*</span>
            </label>
            <select
              id="pais-residencia"
              defaultValue={'DEFAULT'}
              {...register('paisResidencia', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {countries.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
            {errors.paisResidencia && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {
            // 680da2a1-169c-e711-8111-c4346bdcf161 es el Id de República Dominicana
            paisResidencia === '680da2a1-169c-e711-8111-c4346bdcf161' ? (
              <>
                <div className="field-form">
                  <label htmlFor="provincia" className="label-field">
                    Provincia <span>*</span>
                  </label>
                  <select
                    id="provincia"
                    defaultValue={'DEFAULT'}
                    {...register('provincia', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {provinces.map((province) => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </select>
                  {errors.provincia && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="municipio" className="label-field">
                    Municipio <span>*</span>
                  </label>
                  <select
                    id="municipio"
                    defaultValue={'DEFAULT'}
                    {...register('municipio', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {towns.map((town) => (
                      <option key={town.id} value={town.id}>
                        {town.name}
                      </option>
                    ))}
                  </select>
                  {errors.municipio && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="sector" className="label-field">
                    Sector <span>*</span>
                  </label>
                  <select
                    id="sector"
                    defaultValue={'DEFAULT'}
                    {...register('sector', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {sectors.map((sector) => (
                      <option key={sector.id} value={sector.id}>
                        {sector.name}
                      </option>
                    ))}
                  </select>
                  {errors.sector && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="calle" className="label-field">
                    Calle <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="calle"
                    {...register('calle', {
                      required: true,
                    })}
                  />
                  {errors.calle?.type === 'required' && (
                    <p className="text-danger">El campo Calle es requerido</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="casaNumero" className="label-field">
                    Casa No. <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="casaNumero"
                    {...register('casaNumero', {
                      required: true,
                    })}
                  />
                  {errors.casaNumero?.type === 'required' && (
                    <p className="text-danger">
                      El campo Casa No. es requerido
                    </p>
                  )}
                </div>

                <div className="minor-fields-container">
                  <div className="field-form minor-fields">
                    <label htmlFor="edificio" className="label-field">
                      Edificio
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="edificio"
                      {...register('edificio')}
                    />
                    {/* {errors.edificio?.type === 'required' && <p className='text-danger'>El campo Edificio es requerido</p>} */}
                  </div>

                  <div className="field-form minor-fields">
                    <label htmlFor="numeroApartamento" className="label-field">
                      Número apartamento
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="numeroApartamento"
                      {...register('numeroApartamento')}
                    />
                    {/* {errors.numeroApartamento?.type === 'required' && <p className='text-danger'>El campo Número apartamento es requerido</p>} */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="field-form">
                  <label htmlFor="calle" className="label-field">
                    Calle
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="calle"
                    {...register('calle', {
                      required: true,
                    })}
                  />
                  {errors.calle?.type === 'required' && (
                    <p className="text-danger">El campo Calle es requerido</p>
                  )}
                </div>
              </>
            )
          }
        </div>
      </div>
      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default ResidenceInformationForm;
