import { useEffect, useState } from 'react';
import './CustomCheckbox.css';

const CustomCheckbox = ({
  index,
  isInArray,
  idElement,
  nameElement,
  functionParam,
  disabled,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(isInArray);
  }, [isInArray]);

  const executeFunctionParam = () => {
    functionParam(idElement);
  };

  return (
    <div>
      <input
        type="checkbox"
        id={`checkboxOption${index}`}
        className="form-check-input me-2"
        onChange={executeFunctionParam}
        checked={isChecked}
        disabled={disabled}
      />
      <label htmlFor={`checkboxOption${index}`} className="form-check-label">
        {nameElement}
      </label>
    </div>
  );
};

export default CustomCheckbox;
