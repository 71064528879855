import './BusinessDescriptionForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import ModalInvestorProfile from '../../../../../shared/UIElements/ModalInvestorProfile/ModalInvestorProfile';
import {
  convertBooleanToInput,
  convertInputToBoolean,
} from '../../../../../shared/utils/functions';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import {
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { setTypeObligatedSubject } from '../../../../../shared/store/slices/clientePotencial';
import { useSelector } from 'react-redux';
import {
  GET_ANNUAL_SALES_LIST,
  GET_COMPANY_ECONOMIC_DETAILS_SECTION,
  GET_DUE_DILIGENCE_SECTION,
  GET_ECONOMIC_ACTIVITIES,
  GET_ECONOMIC_SECTORS,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_OBLIGATED_SUBJECTS_TYPES,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  SAVE_COMPANY_ECONOMIC_DETAILS_SECTION,
  SAVE_DUE_DELIGENCE_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';
import { Error } from '@mui/icons-material';

const BusinessDescriptionForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: economicActivities } = useQuery(GET_ECONOMIC_ACTIVITIES);
  const { data: economicSectors } = useQuery(GET_ECONOMIC_SECTORS);
  const { data: annualSalesList } = useQuery(GET_ANNUAL_SALES_LIST);
  const [getObligatedSubjectTypes, obligatedSubjectTypes] = useLazyQuery(
    GET_OBLIGATED_SUBJECTS_TYPES
  );
  const [formResponseId, setFormResponseId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [
    getCompanyEconomicDetailsSectionData,
    companyEconomicDetailsSectionData,
  ] = useLazyQuery(GET_COMPANY_ECONOMIC_DETAILS_SECTION);
  const [getDueDiligenceSectionData, dueDiligenceSectionData] = useLazyQuery(
    GET_DUE_DILIGENCE_SECTION
  );
  const [saveCompanyEconomicDetailsSection] = useMutation(
    SAVE_COMPANY_ECONOMIC_DETAILS_SECTION,
    {
      refetchQueries: [
        {
          query: GET_COMPANY_ECONOMIC_DETAILS_SECTION,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [SaveDueDiligenceSection] = useMutation(SAVE_DUE_DELIGENCE_SECTION, {
    refetchQueries: [
      {
        query: GET_DUE_DILIGENCE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const { isContrapart, isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );
  const [loadingSection, setLoadingSection] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);

  const [actividadEconomica, setActividadEconomica] = useState(null);
  const [inputActividadEconomica, setInputActividadEconomica] = useState('');

  const cantidadEmpleados = watch('cantidadEmpleados');
  const sector = watch('sector');
  const esSujetoObligado = watch('esSujetoObligado');
  const tipoSujetoObligado = watch('tipoSujetoObligado');
  const productosServicios = watch('productosServicios');
  const principalesClientes = watch('principalesClientes');
  const principalesProveedores = watch('principalesProveedores');
  const volumenVentasAnuales = watch('volumenVentasAnuales');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getObligatedSubjectTypes({
        variables: {
          applicantTypeId: selectedFormResponse?.lead?.companyLeadDetails?.applicantType?.id,
        },
      });

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data?.formResponses?.length) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );
      getSectionsData();
    }
  }, [formResponseByIdentification]);

  const getSectionsData = async () => {
    const parameter = {
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    };

    const promises = [
      getCompanyEconomicDetailsSectionData(parameter),
      getDueDiligenceSectionData(parameter),
    ];

    await Promise.all(promises);

    setLoadingSection(false);
  };

  useEffect(() => {
    if (
      companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection?.id
    ) {
      if (
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.economicActivity?.id
      ) {
        setActividadEconomica({
          label:
            companyEconomicDetailsSectionData?.data
              ?.companyEconomicDetailsSection?.economicActivity?.name,
          id: companyEconomicDetailsSectionData?.data
            ?.companyEconomicDetailsSection?.economicActivity?.id,
        });

        setValue(
          'autocompleteField',
          companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
            ?.economicActivity?.id
        );
      }
      setValue(
        'cantidadEmpleados',
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.employeesNumber
      );
      setValue(
        'productosServicios',
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.productsOrServices
      );
      setValue(
        'principalesClientes',
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.clientsAndCountries
      );
      setValue(
        'cantidadAccionistas',
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.sharedHoldersNumber
      );
      setValue(
        'principalesProveedores',
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.providers
      );
      if (
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.companyType?.id
      ) {
        setValue(
          'tipoEmpresa',
          companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
            ?.companyType?.id
        );
      }

      if (
        companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
          ?.annualSales?.id
      ) {
        setValue(
          'volumenVentasAnuales',
          companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
            ?.annualSales?.id
        );
      }
    }
  }, [companyEconomicDetailsSectionData]);

  useEffect(() => {
    if (dueDiligenceSectionData?.data?.dueDiligenceSection) {
      if (
        dueDiligenceSectionData?.data?.dueDiligenceSection
          ?.isObligatedSubject !== undefined
      ) {
        setValue(
          'esSujetoObligado',
          convertBooleanToInput(
            dueDiligenceSectionData?.data?.dueDiligenceSection
              ?.isObligatedSubject
          )
        );
      }

      if (
        dueDiligenceSectionData?.data?.dueDiligenceSection?.isObligatedSubject
      ) {
        setValue(
          'tipoSujetoObligado',
          dueDiligenceSectionData?.data?.dueDiligenceSection
            ?.dueDiligenceSectionDetails?.obligatedSubjectType?.id
        );
      }
    }
  }, [dueDiligenceSectionData]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-contacto-principal'
    );
  };

  const saveData = () => {
    if (isFormCompleted || isFormDisqualified) {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/direcciones-de-oficinas'
      );
      return;
    }

    let data = {};

    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (
      companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection?.id
    ) {
      // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
      data = {
        id: companyEconomicDetailsSectionData?.data
          ?.companyEconomicDetailsSection?.id,
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        economicActivityId: actividadEconomica?.id,
        economicSectorId: sector,
        clientsAndCountries: principalesClientes,
        providers: principalesProveedores,
        annualSalesId: volumenVentasAnuales,
        employeesNumber: Number(cantidadEmpleados),
        productsOrServices: productosServicios,
        companyTypeId:
          companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
            ?.companyType?.id,
      };
    } else {
      // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        economicActivityId: actividadEconomica?.id,
        economicSectorId: sector,
        clientsAndCountries: principalesClientes,
        providers: principalesProveedores,
        annualSalesId: volumenVentasAnuales,
        employeesNumber: Number(cantidadEmpleados),
        productsOrServices: productosServicios,
        companyTypeId:
          companyEconomicDetailsSectionData?.data?.companyEconomicDetailsSection
            ?.companyType?.id,
      };
    }

    saveCompanyEconomicDetailsSection({
      variables: {
        companyEconomicDetailsSection: data,
      },
    });

    // Guardado del sujeto obligado

    if (dueDiligenceSectionData?.data?.dueDiligenceSection?.id) {
      if (esSujetoObligado === 'Si') {
        data = {
          id: dueDiligenceSectionData?.data?.dueDiligenceSection?.id,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          isObligatedSubject: convertInputToBoolean(esSujetoObligado),
          obligatedSubjectTypeId: tipoSujetoObligado,
        };
      } else {
        data = {
          id: dueDiligenceSectionData?.data?.dueDiligenceSection?.id,
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          isObligatedSubject: convertInputToBoolean(esSujetoObligado),
          obligatedSubjectTypeId: null,
        };
      }
    } else {
      if (esSujetoObligado === 'Si') {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          isObligatedSubject: convertInputToBoolean(esSujetoObligado),
          obligatedSubjectTypeId: tipoSujetoObligado,
        };
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          isObligatedSubject: convertInputToBoolean(esSujetoObligado),
          obligatedSubjectTypeId: null,
        };
      }
    }
    if (convertInputToBoolean(esSujetoObligado)) {
      const obligated = obligatedSubjectTypes?.data?.obligatedSubjectTypes.find(
        (obligatedSubject) => obligatedSubject.id === tipoSujetoObligado
      );
      dispatch(setTypeObligatedSubject(obligated));
      sessionStorage.setItem('obligatedSubject', JSON.stringify(obligated));
    } else {
      dispatch(setTypeObligatedSubject(null));
      sessionStorage.removeItem('obligatedSubject');
    }

    SaveDueDiligenceSection({
      variables: {
        dueDiligenceSection: data,
      },
    });

    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/direcciones-de-oficinas'
    );
  };

  useEffect(() => {
    if (
      actividadEconomica &&
      economicActivities?.economicActivities?.length &&
      economicSectors?.economicSectors?.length
    ) {
      setValue(
        'sector',
        economicActivities?.economicActivities?.find(
          (ea) => ea.id === actividadEconomica.id
        )?.economicSector?.id
      );
    } else {
      setValue('sector', 'DEFAULT');
    }
  }, [actividadEconomica, economicSectors, economicActivities]);

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key !== 'clientErrors' && key !== 'isValid' && key !== '__typename') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const isSectionError = () => {
    return fieldsByComplexSections['Descripción del Negocio'].some((flied) =>
      validationErrors?.includes(flied)
    );
  };

  return (
    <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
      <div className="row fields-container" style={{ height: '82%' }}>
        <h3 className="formTitle">
          Descripción del Negocio
          <ModalInfo
            Icon={<FontAwesomeIcon icon={faCircleInfo} />}
            title={'Descripción del Negocio'}
            description={
              "El formulario 'Descripción del Negocio' abarca de manera integral los diversos aspectos que caracterizan la operación y el alcance de la empresa. Aquí se recoge información clave que proporciona una visión detallada de la entidad, incluyendo su actividad económica, número de empleados, aporte a capital, volumen de ventas anuales, tipo de empresa, productos y/o servicios, así como detalles sobre clientes y proveedores principales. Este formulario tiene como objetivo presentar de manera concisa pero completa, los rasgos distintivos que definen la esencia y el perfil operativo del negocio."
            }
          />
          {isSectionError() && (
            <span style={{ marginLeft: 30 }}>
              <ModalInfo
                color="white"
                bgcolor="#B90E0A"
                Icon={
                  <Error
                    style={{
                      color: '#B90E0A',
                      fontSize: 25,
                      marginTop: '-5px',
                    }}
                  />
                }
                description={'Hay campos incompletos en esta sección'}
              />
            </span>
          )}
        </h3>

        {loadingSection ? (
          <div className="w-100 d-flex align-items-center justify-content-center p-5">
            <CircularProgress color="warning" />
          </div>
        ) : (
          <div className="row">
            {!isContrapart ? (
              <>
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label
                    htmlFor="volumen-ventas-anuales"
                    className="label-field"
                  >
                    Volumen de ventas anuales <span>*</span>
                    <ModalInvestorProfile
                      title={'Volumen de ventas anuales *'}
                      message={'En RD$ ó su equivalente en US$'}
                    />
                  </label>
                  <div className="selectWrapper">
                    <select
                      id="volumen-ventas-anuales"
                      defaultValue={'DEFAULT'}
                      disabled={isFormCompleted || isFormDisqualified}
                      {...register('volumenVentasAnuales', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      {annualSalesList?.annualSalesList.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.volumenVentasAnuales && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                  {(!volumenVentasAnuales ||
                    volumenVentasAnuales === 'DEFAULT') &&
                    !errors.volumenVentasAnuales && (
                      <ErrorText hasError={hasError('annualSales')} />
                    )}
                </div>

                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="cantidad-empleados" className="label-field">
                    Cantidad de empleados <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="input-form"
                    placeholder=""
                    id="cantidad-empleados"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('cantidadEmpleados', { required: true })}
                  />
                  {errors.cantidadEmpleados?.type === 'required' && (
                    <p className="text-danger">El campo nombre es requerido</p>
                  )}
                  {!cantidadEmpleados &&
                    errors.cantidadEmpleados?.type !== 'required' && (
                      <ErrorText hasError={hasError('employeesNumber')} />
                    )}
                </div>
              </>
            ) : null}

            <div className="field-form col-lg-6 col-sm-12 mt-1 ps-4 special-autocomplete">
              <label htmlFor="actividad-economica" className="label-field">
                Actividad económica <span>*</span>
              </label>
              <Autocomplete
                disablePortal
                disabled={isFormCompleted || isFormDisqualified}
                id="controllable-states-demo"
                value={actividadEconomica}
                onChange={(event, newValue) => {
                  setActividadEconomica(newValue);
                }}
                inputValue={inputActividadEconomica}
                onInputChange={(event, newInputValue) => {
                  setInputActividadEconomica(newInputValue);
                }}
                options={
                  economicActivities?.economicActivities?.length
                    ? economicActivities?.economicActivities?.map((type) => ({
                        label: type.name,
                        id: type.id,
                      }))
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    placeholder={
                      economicActivities?.economicActivities?.length
                        ? 'Busca y selecciona'
                        : 'Cargando...'
                    }
                    {...params}
                    {...register('autocompleteField', { required: true })}
                  />
                )}
              />
              <p
                className="text-danger"
                style={{
                  marginTop: 8,
                  visibility: errors.autocompleteField ? 'visible' : 'hidden',
                }}
              >
                Debe seleccionar una opción
              </p>
              {!actividadEconomica && !errors.autocompleteField && (
                <ErrorText hasError={hasError('economicActivity')} />
              )}
            </div>

            <div className="field-form col-lg-6 col-sm-12 ps-4">
              <label htmlFor="sector" className="label-field">
                Sector <span>*</span>
              </label>
              <div className="selectWrapper">
                <select
                  disabled
                  id="sector"
                  defaultValue={'DEFAULT'}
                  {...register('sector', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {economicSectors?.economicSectors?.map((option) => (
                    <option
                      key={option.id}
                      title={option.name}
                      value={option.id}
                    >
                      {option.name.length > 50
                        ? option.name.slice(0, 50) + '...'
                        : option.name}
                    </option>
                  ))}
                </select>
              </div>
              {errors.sector && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
              {(!sector || sector === 'DEFAULT') && !errors.sector && (
                <ErrorText hasError={hasError('economicSector')} />
              )}
            </div>

            <div className="field-form col-lg-6 col-sm-12 ps-4">
              <label htmlFor="es-sujeto-obligado" className="label-field">
                ¿Es sujeto obligado? <span>*</span>
                <ModalInvestorProfile
                    title={'Sujeto obligado *'}
                    message={`
                      Se entiende por Sujeto Obligado la persona física o jurídica que, en virtud de la Ley 155-17 está obligada al cumplimiento de obligaciones destinadas a prevenir, detectar, evaluar y mitigar el riesgo de lavado de activos.
                    `}
                  />
              </label>
              <div className="selectWrapper">
                <select
                  id="es-sujeto-obligado"
                  defaultValue={'DEFAULT'}
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('esSujetoObligado', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  <option value="Si">Si</option>
                  <option value="No">No</option>
                </select>
              </div>
              {errors.esSujetoObligado && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
              {(!esSujetoObligado || esSujetoObligado === 'DEFAULT') &&
                !errors.esSujetoObligado && (
                  <ErrorText hasError={hasError('isObligatedSubject')} />
                )}
            </div>

            {esSujetoObligado === 'Si' ? (
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="tipo-sujeto-obligado" className="label-field">
                  Tipo de sujeto obligado *
                </label>
                <div className="selectWrapper">
                  <select
                    id="tipo-sujeto-obligado"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('tipoSujetoObligado', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {obligatedSubjectTypes?.data?.obligatedSubjectTypes?.map(
                      (option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
                {errors.tipoSujetoObligado && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!tipoSujetoObligado || tipoSujetoObligado === 'DEFAULT') &&
                  !errors.tipoSujetoObligado && (
                    <ErrorText hasError={hasError('isObligatedSubject')} />
                  )}
              </div>
            ) : null}

            {!isContrapart &&
            (esSujetoObligado === 'No' || esSujetoObligado === 'DEFAULT') ? (
              <div className="field-form col-lg-6 col-sm-12 ps-4 mt-4">
                <label htmlFor="productos-servicios" className="label-field">
                  Productos y/o servicios <span>*</span>
                </label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder=""
                  id="productos-servicios"
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('productosServicios', {
                    required: true,
                  })}
                ></textarea>
                {errors.productosServicios?.type === 'required' && (
                  <p className="text-danger">
                    El campo Productos y/o servicios es requerido
                  </p>
                )}
                {!productosServicios &&
                  errors.productosServicios?.type !== 'required' && (
                    <div className="mt-5">
                      <ErrorText hasError={hasError('productsOrServices')} />
                    </div>
                  )}
              </div>
            ) : null}

            {!isContrapart ? (
              <>
                <div className="field-form col-lg-6 col-sm-12 ps-4 mt-4">
                  <label htmlFor="principales-clientes" className="label-field">
                    Principales clientes y países <span>*</span>
                  </label>
                  <textarea
                    rows="5"
                    className="form-control"
                    placeholder=""
                    id="principales-clientes"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('principalesClientes', {
                      required: true,
                    })}
                  ></textarea>
                  {errors.principalesClientes?.type === 'required' && (
                    <p className="text-danger mt-2">
                      El campo Principales clientes y países es requerido
                    </p>
                  )}
                  {!principalesClientes &&
                    errors.principalesClientes?.type !== 'required' && (
                      <div className="mt-5">
                        <ErrorText hasError={hasError('clientsAndCountries')} />
                      </div>
                    )}
                </div>
                <div
                  className="field-form col-lg-6 col-sm-12 ps-4"
                  style={{ marginTop: '2rem' }}
                >
                  <label
                    htmlFor="principales-proveedores"
                    className="label-field"
                  >
                    Principales proveedores <span>*</span>
                  </label>
                  <textarea
                    rows="5"
                    className="form-control"
                    placeholder=""
                    id="principales-proveedores"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('principalesProveedores', {
                      required: true,
                    })}
                  ></textarea>
                  {errors.principalesProveedores?.type === 'required' && (
                    <p className="text-danger mt-2">
                      El campo Principales proveedores es requerido
                    </p>
                  )}
                  {!principalesProveedores &&
                    errors.principalesProveedores?.type !== 'required' && (
                      <div style={{ marginTop: 52 }}>
                        <ErrorText hasError={hasError('providers')} />
                      </div>
                    )}
                </div>
              </>
            ) : null}

            {!isContrapart && esSujetoObligado === 'Si' ? (
              <div className="field-form col-lg-6 col-sm-12 ps-4 mt-4 mb-5">
                <label htmlFor="productos-servicios" className="label-field">
                  Productos y/o servicios <span>*</span>
                </label>
                <textarea
                  rows="5"
                  className="form-control"
                  placeholder=""
                  id="productos-servicios"
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('productosServicios', {
                    required: true,
                  })}
                ></textarea>
                {errors.productosServicios?.type === 'required' && (
                  <p className="text-danger">
                    El campo Productos y/o servicios es requerido
                  </p>
                )}
                {!productosServicios &&
                  errors.productosServicios?.type !== 'required' && (
                    <div className="mt-5">
                      <ErrorText hasError={hasError('productsOrServices')} />
                    </div>
                  )}
              </div>
            ) : null}
          </div>
        )}
      </div>

      <div className="buttons-control-container">
        <button
          type="submit"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>
        <button
          type="submit"
          disabled={loadingSection}
          className={loadingSection ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default BusinessDescriptionForm;
