import './CardItems.css';

const CardItems = ({
  nameElement,
  identityDocument,
  idElement,
  deleteElement,
  tipoCuenta = '',
  handleEdit,
  setIsUpdating,
  showEditButton = true,
  showDeleteButton = true,
  forceEdit = false,
  detalle = '',
  extraButton = {
    show: false,
    text: '',
    click: () => {},
  },
}) => {
  const handleDeleteElement = () => {
    if (tipoCuenta === '') {
      deleteElement(idElement);
    } else {
      deleteElement(idElement, tipoCuenta);
    }
  };

  const handleEditElement = () => {
    handleEdit(idElement);
    setIsUpdating(true);
  };

  return (
    <div className="fill-form-way-card">
      {!showDeleteButton && !showEditButton ? null : (
        <div className="point-options-container">
          <span>...</span>
          <ul>
            {showEditButton ? (
              <li onClick={handleEditElement}>
                {!showDeleteButton & !forceEdit ? 'Detalles' : 'Editar'}
              </li>
            ) : null}
            {showDeleteButton ? (
              <li onClick={handleDeleteElement}>Eliminar</li>
            ) : null}
            {extraButton.show ? (
              <li onClick={() => extraButton.click(idElement)}>{extraButton.text}</li>
            ) : null}
          </ul>
        </div>
      )}

      {detalle && detalle !== '' ? <p>{detalle}</p> : null}

      <h4 className="rwf-card-title">{nameElement}</h4>
      <p>{identityDocument}</p>
    </div>
  );
};

export default CardItems;
