import './FatcaRelatedForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const FatcaRelatedForm = ({ validateForm, setValidateForm, goToBack }) => {
  const [optionSelected, setOptionSelected] = useState('');
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const tinOEin = watch('tinOEin');
  const fatcaOption = watch('fatcaOption');

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-juridica/designacion-cuenta-bancaria');
    }
  }, [goToBack]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const saveData = () => {};

  const selectOption = (option) => {
    setOptionSelected(option);

    if (option === 'Si') {
      setValue('fatcaOption', 'Si');
    } else {
      setValue('fatcaOption', 'No');
    }
  };

  return (
    <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-md-6 col-sm-12 pe-4 columns">
          <div className="fatca-options-container">
            <div
              className={
                optionSelected === 'Si'
                  ? 'fatca-option-selected'
                  : 'fatca-option'
              }
              onClick={() => selectOption('Si')}
              {...register('fatcaOption', {
                required: true,
              })}
            >
              <span>Si</span>
            </div>

            <div
              className={
                optionSelected === 'No'
                  ? 'fatca-option-selected'
                  : 'fatca-option'
              }
              onClick={() => selectOption('No')}
              {...register('fatcaOption', {
                required: true,
              })}
            >
              <span>No</span>
            </div>
          </div>
          <div className="fatca-option-error-message">
            {errors.fatcaOption?.type === 'required' && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {fatcaOption === 'Si' ? (
            <div className="field-form">
              <label htmlFor="tin-o-ein" className="label-field">
                Tax Identification Number (TIN) o Employer Identification Number
                (EIN)
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="tin-o-ein"
                {...register('tinOEin', {
                  required: true,
                })}
              />
              {errors.tinOEin?.type === 'required' && (
                <p className="text-danger">Este campo es requerido</p>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default FatcaRelatedForm;
