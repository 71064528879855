import './ErrorPage.css';
import { navigateToRegister } from '../../../shared/utils/functions';
import { useErrorContext } from '../../../context/ErrorContextProvider';
import React from 'react';

const ErrorPage = () => {
  const errors = useErrorContext();

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  return (
    <div className="page-container">
      <div className="logo-parval-container">
        <img src="/logo.png" className="logoParval2" />
      </div>

      <div className="principal-container">
        <div className="row error-page-row">
          <div className="col-sm-10 col-md-4">
            {errors.map((error, index) => (
              <div key={index} className="alert alert-danger error-message">
                {error}
              </div>
            ))}
          </div>

          <button
            type="button"
            className="btn-back-register"
            onClick={navigateToRegister}
          >
            Volver a registro
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
