import './BussinesRepresentativeAccountSummary.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  getDecodedCookie,
  modifyCookie,
  envars,
} from '../../../../shared/utils/functions';
import { useQuery } from '@apollo/client';
import {
  AZURE_SESSION_TIME_OUT,
  azureUserFlowUri,
} from '../../../../shared/constants/gobal-constants';
import { toast } from 'react-toastify';
import { GET_BUSINESS_REPRESENTATIVES_BY_FILTER } from '../../../../shared/graphQL/queries';
import { AccountCircleOutlined } from '@mui/icons-material';

export default function BussinesRepresentativeAccountSummary() {
  const navigate = useNavigate();
  const [agentSelected, setAgentSelected] = useState(null);
  const [animationOut, setAnimationOut] = useState(false);
  const [reload, setReload] = useState(false);

  const getOptionsSelected = () => {
    const configDecoded = getDecodedCookie('potencialCustomerConfigRC');
    return {
      branch: configDecoded?.branch?.id,
      personType: configDecoded?.accountType?.id
    }
  };

  const { loading, error, data } = useQuery(GET_BUSINESS_REPRESENTATIVES_BY_FILTER, {
    variables: {
      filters: {
        branchIds: [getOptionsSelected()?.branch], 
        personTypeId: getOptionsSelected()?.personType
      }
    },
  });

  useEffect(() => {
    const configDecoded = getDecodedCookie('potencialCustomerConfigRC');

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }

    if (!configDecoded.assistUnit) {
      navigate(-1);
    }

    if (configDecoded && configDecoded.businessRepresentative) {
      setReload(true);
      setAgentSelected(configDecoded.businessRepresentative);
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener los representantes de venta', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (agentSelected && !reload) {
      goTo();
    }
  }, [agentSelected, reload]);

  const goTo = () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    modifyCookie(
      'potencialCustomerConfigRC',
      'businessRepresentative',
      agentSelected,
      true,
      timeout
    );
    setAnimationOut(true);
    setTimeout(() => {
      navigate(
        `/onboarding-digital/dashboard/apertura-de-cuenta/verificacion-de-identidad`
      );
    }, 500);
  };

  const goBack = () => {
    navigate(
      '/onboarding-digital/dashboard/apertura-de-cuenta/tipo-de-empresa'
    );
  };

  return (
    <div className="accountSumary">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="">
            <div className="vAlign" style={{ padding: '2rem' }}>
              <strong className="formTitle">
                Elige tu Representante de Negocio
              </strong>
              <div className="mt-4 newForm">
                <div
                  style={{ justifyContent: 'flex-start' }}
                  className="cards-container-summary-account"
                >
                  {!loading &&
                  !error &&
                  data &&
                  data?.accountRepresentatives?.length ? (
                    <>
                      {data?.accountRepresentatives?.length < 1 ? <div
                        style={{
                          maxWidth: '32%',
                          margin: '0 0.5% 25px 0.5%',
                          padding: '1rem',
                        }}
                        className={`boxButton card-element`}
                        onClick={() => {
                          setReload(false);
                          setAgentSelected('auto');
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div>
                            <AccountCircleOutlined
                              color="gray"
                              style={{ color: '#cbcbcb', fontSize: 60 }}
                            />
                          </div>
                          <p
                            className="ms-3 sr-card-text"
                            style={{ color: 'gray' }}
                          >
                            Asígname un representante
                          </p>
                        </div>
                      </div> : null}
                      {data?.accountRepresentatives.map((agent) => {
                        return (
                          <div
                            style={{
                              maxWidth: '32%',
                              margin: '0 0.5% 25px 0.5%',
                              padding: '1rem',
                            }}
                            className={`boxButton card-element`}
                            onClick={() => {
                              setReload(false);
                              setAgentSelected(agent.id);
                            }}
                            key={agent.id}
                          >
                            <div
                              style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <div style={{ marginRight: '20px' }}>
                                <img
                                  src={
                                    agent.firstName.includes('Arturo')
                                      ? 'https://res.cloudinary.com/dkkvblucf/image/upload/v1681393445/h9vdym3rqe1vplu5vusk.png'
                                      : agent.imageURL
                                        ? `/middleware/image-crm${agent.imageURL}`
                                        : '/images/profile-picture.png'
                                  }
                                  alt=""
                                  className="sr-card-img"
                                />
                              </div>
                              <p className="sr-card-text">
                                {agent.firstName + ' ' + agent.lastName}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                  {!loading &&
                  !error &&
                  !data?.accountRepresentatives?.length ? (
                    <p className="subTitle mt-2 mb-5 fs-5 text-dark">
                      Esta sucursal no posee representantes de negocios todavía.
                    </p>
                  ) : null}
                </div>
              </div>
              <p className="linkBack bottom mt-3" onClick={goBack}>
                <FontAwesomeIcon icon={faAngleLeft} /> Volver
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
