import './InvestorProfileForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const InvestorProfilePhysicalPersonForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [patrimonyOptions, setPatrimonyOptions] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [destinyOptions, setDestinyOptions] = useState([]);
  const [liquidAssetsOptions, setLiquidAssetsOptions] = useState([]);
  const [loseCapacityOptions, setLoseCapacityOptions] = useState([]);
  const [objectiveOptions, setObjectiveOptions] = useState([]);
  const [accountsToPayOptions, setAccountsToPayOptions] = useState([]);
  const [riskToleranceOptions, setRiskToleranceOptions] = useState([]);
  const [desiredRentabilityOptions, setDesiredRentabilityOptions] = useState(
    []
  );
  const [marketInfoUsageOptions, setMarketInfoUsageOptions] = useState([]);
  const [titleOperations, setTitleOperations] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  // TODO algunos de esos campos llevan N/A como valor por defecto
  const experienciaInversionista = watch('experienciaInversionista');
  const patrimonio = watch('patrimonio');
  const horizonteInversion = watch('horizonteInversion');
  const monedaInversionInicial = watch('monedaInversionInicial');
  const montoInversionInicial = watch('montoInversionInicial');
  const destinoIngresos = watch('destinoIngresos');
  const activosLiquidos = watch('activosLiquidos');
  const capacidadAbsorcionPerdidas = watch('capacidadAbsorcionPerdidas');
  const objetivosInversion = watch('objetivosInversion');
  const cuentasPorPagar = watch('cuentasPorPagar');
  const toleranciaRiesgo = watch('toleranciaRiesgo');
  const rentabilidadDeseada = watch('rentabilidadDeseada');
  const mediosInformacionUtilizados = watch('mediosInformacionUtilizados');
  const operacionesTitulos = watch('operacionesTitulos');

  useEffect(() => {
    //getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveDataInBD = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/distribucion-de-activos-liquidos'
    );
  };

  const addRemoveTitleOperations = (option) => {
    if (titleOperations.includes(option)) {
      const index = titleOperations.indexOf(option);
      titleOperations.splice(index, 1);
      setTitleOperations([...titleOperations]);
      //console.log(titleOperations);
    } else {
      setTitleOperations((prevState) => [...prevState, option]);
    }
  };

  return (
    <form onSubmit={handleSubmit(saveDataInBD)} className="form-fileds-basic">
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle">Perfil del inversionista </h3>

          <div className="field-form">
            <label
              htmlFor="nivelAcademico"
              className="label-field text-justify"
            >
              Nivel Académico <span>*</span>
            </label>
            <select
              id="nivelAcademico"
              defaultValue={'DEFAULT'}
              {...register('nivelAcademico', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Opción 1">Opción 1</option>
              <option value="Opción 2">Opción 2</option>
              {/* {experienceOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)} */}
            </select>
            {errors.nivelAcademico && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {/* <div className='field-form'>
              <label htmlFor="experiencia-inversionista" className='label-field text-justify'>Experiencia y conocimiento como inversionista <span>*</span></label>
              <select  id='experiencia-inversionista' defaultValue={'DEFAULT'} {...register('experienciaInversionista', {
                  validate: validateNonSelectedOptionInDropdown
              })}>
                  <option value="DEFAULT" disabled>Selecciona</option>
                  {experienceOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
              </select>
              {errors.experienciaInversionista && <p className='text-danger'>Debe seleccionar una opción</p>}
            </div> */}

          <div className="field-form">
            <label htmlFor="patrimonio" className="label-field text-justify">
              Patrimonio total <span>*</span>
            </label>
            <select
              id="patrimonio"
              defaultValue={'DEFAULT'}
              {...register('patrimonio', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {patrimonyOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {errors.patrimonio && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {/* <div className='field-form'>
              <label htmlFor="horizonte-inversion" className='label-field text-justify'>En términos de tiempo el horizonte de inversión del cliente es <span>*</span></label>
              <select  id='horizonte-inversion' defaultValue={'DEFAULT'} {...register('horizonteInversion', {
                  validate: validateNonSelectedOptionInDropdown
              })}> 
                  <option value="DEFAULT" disabled>Selecciona</option>
                  {timeOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
              </select>
              {errors.horizonteInversion && <p className='text-danger'>Debe seleccionar una opción</p>}
            </div> */}

          {/* <div className='field-form'>
              <label htmlFor="moneda-inversion-inicial" className='label-field text-justify'>Moneda de inversión inicial <span>*</span></label>
              <select  id='moneda-inversion-inicial' defaultValue={'DEFAULT'} {...register('monedaInversionInicial', {
                  validate: validateNonSelectedOptionInDropdown
              })}> 
                  <option value="DEFAULT" disabled>Selecciona</option>
                  {currencies.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
              </select>
              {errors.monedaInversionInicial && <p className='text-danger'>Debe seleccionar una opción</p>}
            </div> */}

          {/* <div className='field-form'>
              <label htmlFor="monto-inversion-inicial" className='label-field'>Monto aproximado de inversión inicial <span>*</span></label>
              <input type="number" className='input-form' placeholder="" id="monto-inversion-inicial" {...register('montoInversionInicial', {
                  required: true
              })} />
              {errors.montoInversionInicial?.type === 'required' && <p className='text-danger'>Este campo es requerido</p>}
            </div> */}

          {/* <div className='field-form'>
              <label htmlFor="destino-ingresos" className='label-field text-justify'>Destino de los ingresos <span>*</span></label>
              <select  id='destino-ingresos' defaultValue={'DEFAULT'} {...register('destinoIngresos', {
                  validate: validateNonSelectedOptionInDropdown
              })}> 
                  <option value="DEFAULT" disabled>Selecciona</option>
                  {destinyOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
              </select>
              {errors.destinoIngresos && <p className='text-danger'>Debe seleccionar una opción</p>}
            </div> */}

          <div className="field-form">
            <label
              htmlFor="activos-liquidos"
              className="label-field text-justify"
            >
              Total de Activos Líquidos <span>*</span>
            </label>
            <select
              id="activos-liquidos"
              defaultValue={'DEFAULT'}
              {...register('activosLiquidos', {
                validate: validateNonSelectedOptionInDropdown,
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {liquidAssetsOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {errors.activosLiquidos && (
              <p className="text-danger">Debe seleccionar una opción</p>
            )}
          </div>

          {/* <div className='field-form'>
              <label htmlFor="capacidad-absorcion-perdidas" className='label-field text-justify'>Capacidad de absorción de perdidas <span>*</span></label>
              <select  id='capacidad-absorcion-perdidas' defaultValue={'DEFAULT'} {...register('capacidadAbsorcionPerdidas', {
                  validate: validateNonSelectedOptionInDropdown
              })}> 
                  <option value="DEFAULT" disabled>Selecciona</option>
                  {loseCapacityOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
              </select>
              {errors.capacidadAbsorcionPerdidas && <p className='text-danger'>Debe seleccionar una opción</p>}
            </div>           */}

          <div className="field-form">
            <label
              htmlFor="nivelConocimientoMercadoValores"
              className="label-field text-justify"
            >
              Indique su nivel de conocimiento en el mercado de valores{' '}
            </label>
            <select
              id="nivelConocimientoMercadoValores"
              defaultValue={'DEFAULT'}
              {...register('nivelConocimientoMercadoValores', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Opción 1">Opción 1</option>
              <option value="Opción 1">Opción 2</option>
              {/* {marketInfoUsageOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)} */}
            </select>
            {/* {errors.nivelConocimientoMercadoValores && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>
        </div>

        <div className="col-md-5 col-sm-12 pe-4 columns">
          <h3 className="formTitle" style={{ visibility: 'hidden' }}>
            Non visible
          </h3>

          {/* <div className='field-form'>
                <label htmlFor="objetivos-inversion" className='label-field text-justify'>Objetivos de inversión, de rentabilidad y riesgo <span>*</span></label>
                <select  id='objetivos-inversion' defaultValue={'DEFAULT'} {...register('objetivosInversion', {
                    validate: validateNonSelectedOptionInDropdown
                })}> 
                    <option value="DEFAULT" disabled>Selecciona</option>
                    {objectiveOptions.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
                </select>
                {errors.objetivosInversion && <p className='text-danger'>Debe seleccionar una opción</p>}
              </div> */}

          <div className="field-form">
            <label
              htmlFor="cuentas-por-pagar"
              className="label-field text-justify"
            >
              Cuentas por pagar RD${' '}
            </label>
            <select
              id="cuentas-por-pagar"
              defaultValue={'DEFAULT'}
              {...register('cuentasPorPagar', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {accountsToPayOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {/* {errors.cuentasPorPagar && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>

          <div className="field-form">
            <label
              htmlFor="tolerancia-riesgo"
              className="label-field text-justify"
            >
              Tolerancia al riesgo{' '}
            </label>
            <select
              id="tolerancia-riesgo"
              defaultValue={'DEFAULT'}
              {...register('toleranciaRiesgo', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {riskToleranceOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {/* {errors.toleranciaRiesgo && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>

          <div className="field-form">
            <label
              htmlFor="rentabilidad-deseada"
              className="label-field text-justify"
            >
              Rentabilidad deseada{' '}
            </label>
            <select
              id="rentabilidad-deseada"
              defaultValue={'DEFAULT'}
              {...register('rentabilidadDeseada', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {desiredRentabilityOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {/* {errors.rentabilidadDeseada && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>

          <div className="field-form">
            <label
              htmlFor="medios-informacion-utilizados"
              className="label-field text-justify"
            >
              Medios de información utilizados sobre mercados financieros{' '}
            </label>
            <select
              id="medios-informacion-utilizados"
              defaultValue={'DEFAULT'}
              {...register('mediosInformacionUtilizados', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              {marketInfoUsageOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
            {/* {errors.mediosInformacionUtilizados && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>

          {/* <div className='field-form'>
                <label htmlFor="operaciones-titulos" className='label-field mt-2 mb-3'>
                  Marque las operaciones de títulos, valores y financieras que desea realizar y comprende
                </label>
                <div className='checkbox-options-container' id='operaciones-titulos'>
                    <label htmlFor="certificados-inversion" className='label-field me-1'>
                      <input type="checkbox" id="certificados-inversion" value="Certificados de inversión" onClick={() => addRemoveTitleOperations("Certificados de inversión")} {...register('operacionesTitulos')} /> Certificados de inversión
                    </label>

                    <label htmlFor="bonos" className='label-field me-1'>
                      <input type="checkbox" id="bonos" value="Bonos" onClick={() => addRemoveTitleOperations("Bonos")} {...register('operacionesTitulos')} /> Bonos
                    </label>

                    <label htmlFor="fondos-mutuos" className='label-field me-1'>
                      <input type="checkbox" id="fondos-mutuos" value="Fondos mutuos" onClick={() => addRemoveTitleOperations("Fondos Mutuos y Cerrados")} {...register('operacionesTitulos')} /> Fondos Mutuos y Cerrados
                    </label>
                    
                    <label htmlFor="fondos-cerrados" className='label-field me-1'>
                      <input type="checkbox" id="fondos-cerrados" value="Fondos cerrados" onClick={() => addRemoveTitleOperations("Operaciones a Plazo (Forwards)")} {...register('operacionesTitulos')} /> Operaciones a Plazo (Forwards)
                    </label>

                    <label htmlFor="papeles-comerciales" className='label-field me-1'>
                      <input type="checkbox" id="papeles-comerciales" value="Papeles Comerciales" onClick={() => addRemoveTitleOperations("Papeles Comerciales")} {...register('operacionesTitulos')} /> Papeles Comerciales
                    </label>

                    <label htmlFor="prestamos-de-margen" className='label-field me-1'>
                      <input type="checkbox" id="prestamos-de-margen" value="Préstamos de margen" onClick={() => addRemoveTitleOperations("Préstamos de margen")} {...register('operacionesTitulos')} /> Préstamos de margen
                    </label>

                    <label htmlFor="acciones" className='label-field me-1'>
                      <input type="checkbox" id="acciones" value="Acciones" onClick={() => addRemoveTitleOperations("Acciones")} {...register('operacionesTitulos')} /> Acciones
                    </label>

                    <label htmlFor="prestamos-de-titulos" className='label-field me-1'>
                      <input type="checkbox" id="prestamos-de-titulos" value="Préstamos de Títulos Valores (Mutuo)" onClick={() => addRemoveTitleOperations("Préstamos de Títulos Valores (Mutuo)")} {...register('operacionesTitulos')} /> Préstamos de Títulos Valores (Mutuo) 
                    </label>

                    <label htmlFor="derivados-cobertura" className='label-field me-1'>
                      <input type="checkbox" id="derivados-cobertura" value="Derivados de Cobertura" onClick={() => addRemoveTitleOperations("Derivados de Cobertura")} {...register('operacionesTitulos')} /> Derivados de Cobertura
                    </label>
                </div>
            </div> */}
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="submit"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default InvestorProfilePhysicalPersonForm;
