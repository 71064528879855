import { formatName } from '../../../shared/utils/functions';
import './top.css';

export default function Top({ profile, breadcrumb, title }) {
  return (
    <div className="top">
      <div className="bread-scrumbs">{breadcrumb}</div>
      <div className="principal-info">
        {profile ? (
          <>
            {title}
            <div className="executive-info">
              <div className="executive-info-container">
                <strong>Su Corredor de Valores:</strong>
                <div className="executive-info-content mt-2">
                  <div className="executive-info-img"></div>
                  <div className="executive-info-text">
                    <p className="p-0 m-0">{formatName(profile.executive)}</p>
                    <p className="p-0 m-0">{profile.executiveContact}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="empty-container"></div>
    </div>
  );
}
