import { useSelector } from 'react-redux';
import './expirationModal.css';
import { Button } from '@mui/material';

export default function ExpirationModal({ event }) {
  const {
    session: {
      showSessionModal,
      sessionModal: { title, subtitle, button },
    },
  } = useSelector((state) => state.clienteRealSlice);

  const handleClick = () => {
    event();
  };

  return showSessionModal ? (
    <div className="cliente-real-expiration-modal">
      <div className="cliente-real-modal-background"></div>
      <div className="cliente-real-modal-container">
        <div className="cliente-real-modal-content">
          <div className="cliente-real-modal-title">
            <strong>{title ? title : 'Tu sesión ha finalizado'}</strong>
          </div>
          <div className="cliente-real-modal-subtitle">
            <strong>
              {subtitle
                ? subtitle
                : 'Se ha acado el tiempo, debes de iniciar sesión de nuevo para continuar.'}
            </strong>
          </div>
          <Button
            variant="contained"
            color="warning"
            onClick={() => handleClick()}
          >
            {button ? button : 'Entendido'}
          </Button>
        </div>
      </div>
    </div>
  ) : null;
}
