import './GeneralData.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  convertInputToBoolean,
  validateInputCedula,
} from '../../../../../shared/utils/functions';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { GET_CLIENTYPES } from '../../../../../shared/graphQL/queries';

const GeneralDataPhysicalPerson = () => {
  const [genders, setGenders] = useState([]);
  const [civilStatus, setCivilStatus] = useState([]);
  const [areYouLinkedToParvalOptions, setAreYouLinkedToParvalOptions] =
    useState([]);
  const [parvalEmployeeOptions, setParvalEmployeeOptions] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [countries, setCountries] = useState([]);
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [disabledButton, setDisabledButton] = useState(false);
  const { data: parvalClientTypes } = useQuery(GET_CLIENTYPES);

  // Nuevos valores
  const nombre = watch('nombre');
  const apellidos = watch('apellidos');
  const identityDocumentType = watch('identityDocumentType');
  const identityDocumentNumber = watch('identityDocumentNumber');
  const fechaNacimiento = watch('fechaNacimiento');
  const tieneSegundaNacionalidad = watch('tieneSegundaNacionalidad');
  const segundaNacionalidad = watch('segundaNacionalidad');
  const poseeTinOEin = watch('poseeTinOEin');

  useEffect(() => {
    if (fechaNacimiento) {
      const fechaNacimientoConvertida = new Date(fechaNacimiento);
      const yearDateBirth = Number(fechaNacimientoConvertida?.getFullYear());
      const currentYear = Number(new Date().getFullYear());
      const calculatedAge = currentYear - yearDateBirth;
      setValue('edad', calculatedAge);
    }
  }, [fechaNacimiento]);

  const saveData = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/informacion-laboral'
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div className="row fields-container">
          <div className="col-md-5 col-sm-12 columns">
            <h3 className="formTitle">Datos Generales</h3>

            <div className="field-form">
              <label htmlFor="nombre" className="label-field">
                Nombres <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="nombre"
                {...register('nombre', {
                  required: true,
                })}
              />
              {errors.nombre?.type === 'required' && (
                <p className="text-danger">El campo nombre es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="identityDocumentType" className="label-field">
                Tipo de Identificación <span>*</span>
              </label>
              <select
                defaultValue={'DEFAULT'}
                {...register('identityDocumentType', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {/* {identificationTypesForPhisicalPerson.map(identificationType => <option key={identificationType.id} value={identificationType.id}>{ identificationType.name }</option>)} */}
              </select>
              {errors.identityDocumentType && (
                <p className="text-danger">
                  Debe seleccionar el tipo de documento de identidad
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="identityDocumentNumber" className="label-field">
                Número de identificación <span>*</span>
              </label>
              <div
                className="input-group flex-nowrap"
                style={{ height: '10px !important' }}
              >
                {identityDocumentType === '100000001' ? (
                  <input
                    type="text"
                    id="cedula"
                    className="input-form"
                    maxLength="13"
                    pattern="[0-9]{3}-?[0-9]{7}-?[0-9]{1}"
                    placeholder="000-0000000-0"
                    onInput={validateInputCedula}
                    {...register('identityDocumentNumber', {
                      required: true,
                    })}
                  />
                ) : (
                  <input
                    type="text"
                    className="input-form"
                    aria-label="Username"
                    aria-describedby="addon-wrapping"
                    {...register('identityDocumentNumber', {
                      required: true,
                    })}
                  />
                )}
              </div>
              {errors.identityDocumentNumber?.type === 'required' && (
                <p className="text-danger">
                  Debe ingresar el número de su documento de identidad
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="fecha-nacimiento" className="label-field">
                Fecha de nacimiento
              </label>
              <input
                type="date"
                className="input-form"
                placeholder=""
                id="fecha-nacimiento"
                {...register('fechaNacimiento', {
                  required: true,
                })}
              />
              {errors.fechaNacimiento?.type === 'required' && (
                <p className="text-danger">
                  El campo Fecha de Nacimiento es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="edad" className="label-field">
                Edad{' '}
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="edad"
                readOnly
                {...register('edad', {
                  required: true,
                })}
              />
              {errors.edad?.type === 'required' && (
                <p className="text-danger">El campo Edad es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="sexo" className="label-field">
                Sexo <span>*</span>
              </label>
              <select
                id="sexo"
                defaultValue={'DEFAULT'}
                {...register('sexo', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {genders.map((gender) => (
                  <option key={gender.id} value={gender.id}>
                    {gender.name}
                  </option>
                ))}
              </select>
              {errors.sexo && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="telefono-residencia" className="label-field">
                Teléfono residencia
              </label>
              <input
                type="number"
                className="input-form"
                placeholder=""
                id="telefono-residencia"
                {...register('telefonoResidencia')}
              />
              {/* {errors.telefonoResidencia?.type === 'required' && <p className='text-danger'>El campo Teléfono residencia es requerido</p>} */}
            </div>

            <div className="field-form">
              <label htmlFor="telefono-celular" className="label-field">
                Teléfono celular
              </label>
              <input
                type="number"
                className="input-form"
                placeholder=""
                id="telefono-celular"
                {...register('telefonoCelular')}
              />
              {/* {errors.telefonoCelular?.type === 'required' && <p className='text-danger'>El Teléfono celular es requerido</p>} */}
            </div>
          </div>

          <div className="col-md-5 col-sm-12 columns">
            <h3 className="formTitle" style={{ visibility: 'hidden' }}>
              Non visible
            </h3>

            <div className="field-form">
              <label htmlFor="apellidos" className="label-field">
                Apellidos <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="apellidos"
                {...register('apellidos', {
                  required: true,
                })}
              />
              {errors.apellidos?.type === 'required' && (
                <p className="text-danger">El campo Apellidos es requerido</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="lugar-nacimiento" className="label-field">
                Lugar de Nacimiento
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="lugar-nacimiento"
                {...register('lugarNacimiento', {
                  required: true,
                })}
              />
              {errors.lugarNacimiento?.type === 'required' && (
                <p className="text-danger">
                  El campo Lugar de Nacimiento es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="estado-civil" className="label-field">
                Estado Civil <span>*</span>
              </label>
              <select
                id="estado-civil"
                defaultValue={'DEFAULT'}
                {...register('estadoCivil', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {/* {civilStatus.map(civilStatus => <option key={civilStatus.id} value={civilStatus.id}>{civilStatus.name}</option>)} */}
              </select>
              {errors.estadoCivil && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="ocupacion" className="label-field">
                Ocupación <span>*</span>
              </label>
              <select
                id="ocupacion"
                defaultValue={'DEFAULT'}
                {...register('ocupacion', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {/* {ocupations.map(ocupation => <option key={ocupation.id} value={ocupation.id}>{ocupation.name}</option>)} */}
              </select>
              {errors.ocupacion && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="profesion" className="label-field">
                Profesión <span>*</span>
              </label>
              <select
                id="profesion"
                defaultValue={'DEFAULT'}
                {...register('profesion', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                {/* {ocupations.map(ocupation => <option key={ocupation.id} value={ocupation.id}>{ocupation.name}</option>)} */}
              </select>
              {errors.profesion && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="tiene-segunda-nacionalidad"
                className="label-field"
              >
                ¿Posee una segunda nacionalidad?
              </label>
              <select
                id="tiene-segunda-nacionalidad"
                defaultValue={'DEFAULT'}
                {...register('tieneSegundaNacionalidad', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value={'Si'}>Si</option>
                <option value={'No'}>No</option>
              </select>
              {errors.tieneSegundaNacionalidad && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            {tieneSegundaNacionalidad === 'Si' ? (
              <>
                <div className="field-form">
                  <label htmlFor="segunda-nacionalidad" className="label-field">
                    Segunda nacionalidad
                  </label>
                  <select
                    id="segunda-nacionalidad"
                    defaultValue={'DEFAULT'}
                    {...register('segundaNacionalidad')}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Americana">Americana</option>
                    <option value="Opción 2">Opción 2</option>
                    {/* {nationalities.map(nationality => <option key={nationality.id} value={nationality.id}>{nationality.name}</option>)} */}
                  </select>
                  {errors.segundaNacionalidad && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="pasaporte" className="label-field">
                    Pasaporte
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="pasaporte"
                    {...register('pasaporte', {
                      required: true,
                    })}
                  />
                  {errors.pasaporte?.type === 'required' && (
                    <p className="text-danger">
                      El campo Pasaporte es requerido
                    </p>
                  )}
                </div>
              </>
            ) : null}

            <div className="field-form">
              <label htmlFor="telefono-adicional" className="label-field">
                Teléfono Adicional
              </label>
              <input
                type="number"
                className="input-form"
                placeholder=""
                id="telefono-adicional"
                {...register('telefonoAdicional')}
              />
              {/* {errors.telefonoAdicional?.type === 'required' && <p className='text-danger'>El campo Teléfono Adicional residencia es requerido</p>} */}
            </div>

            <div className="field-form">
              <label htmlFor="correo-electronico" className="label-field">
                Correo Electrónico
              </label>
              <input
                type="email"
                className="input-form"
                placeholder=""
                id="correo-electronico"
                {...register('correoElectronico')}
              />
              {/* {errors.correoElectronico?.type === 'required' && <p className='text-danger'>El campo Teléfono Adicional residencia es requerido</p>} */}
            </div>
          </div>

          {segundaNacionalidad === 'Americana' ? (
            <div className="row fields-container">
              <div className="col-10 ">
                <p className="subsection-title">
                  RELATIVO A FATCA (FOREIGN ACCOUNT TAX COMPLIANCE ACT){' '}
                </p>

                <div className="field-form">
                  <label htmlFor="posee-tin-o-ein" className="label-field">
                    ¿Posee la empresa un Tax Identification Number (TIN) o
                    Employer Identification Number (EIN)? <span>*</span>
                  </label>
                  <div className="selectWrapper">
                    <select
                      id="posee-tin-o-ein"
                      defaultValue={'DEFAULT'}
                      {...register('poseeTinOEin', {
                        validate: validateNonSelectedOptionInDropdown,
                      })}
                    >
                      <option value="DEFAULT" disabled>
                        Selecciona
                      </option>
                      <option value="Si">Si</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  {errors.poseeTinOEin && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                {poseeTinOEin === 'Si' ? (
                  <div className="field-form">
                    <label htmlFor="tin-o-ein" className="label-field">
                      TIN o EIN <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="input-form"
                      placeholder=""
                      id="tin-o-ein"
                      {...register('tinOEin', {
                        required: true,
                        maxLength: 30,
                      })}
                    />
                    {errors.tinOEin?.type === 'required' && (
                      <p className="text-danger">Este campo es requerido</p>
                    )}
                    {errors.tinOEin?.type === 'maxLength' && (
                      <p className="text-danger">
                        Solo puede ingresar 30 caracteres como máximo
                      </p>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>

        <div className="single-button-control-container">
          {/* <button type='submit' className='btn-back' onClick={handleBackNavigation}>
                    <FontAwesomeIcon icon={faAngleLeft} />
                    &#160; Atrás 
                </button> */}
          <button
            type="submit"
            disabled={disabledButton}
            className={disabledButton ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default GeneralDataPhysicalPerson;
