import './FinancialInstrumentsLpForm.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import {
  GENERATE_INVESTMENT_PROFILE,
  SAVE_FINANCIAL_INSTRUMENTS_SECTION,
} from '../../../../../shared/graphQL/mutations';
import {
  GET_FINANCIAL_INSTRUMENTS_SECTION_DATA,
  GET_FINANCIAL_INSTRUMENTS_TYPES,
  GET_FINANCIAL_INSTRUMENT_ANNUAL_OPERATIONS_LIST,
  GET_FINANCIAL_INSTRUMENT_EXPIRIENCE_LIST,
  GET_FINANCIAL_INSTRUMENT_KNOWLEDGELEVELS,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_INVESTMENT_PROFILE_SECTION,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Error } from '@mui/icons-material';

const FinancialInstrumentsLpFForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [formResponseId, setFormResponseId] = useState('');
  const [financialInstrumentSectionId, setFinancialInstrumentSectionId] =
    useState('');
  const [selectedIntrumentId, setSelectedIntrumentId] = useState('');
  const navigate = useNavigate();
  const btnCloseModalRef = useRef();
  const [saveFinancialInstrumentsSection] = useMutation(
    SAVE_FINANCIAL_INSTRUMENTS_SECTION,
    {
      refetchQueries: [
        {
          query: GET_FINANCIAL_INSTRUMENTS_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [getFinancialInstrumentsSectionData, financialInstrumentsSectionData] =
    useLazyQuery(GET_FINANCIAL_INSTRUMENTS_SECTION_DATA);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [generateInvestmentProfile] = useMutation(GENERATE_INVESTMENT_PROFILE, {
    refetchQueries: [
      {
        query: GET_INVESTMENT_PROFILE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const { data: financialInstrumentTypes } = useQuery(
    GET_FINANCIAL_INSTRUMENTS_TYPES
  );
  const { data: financialInstrumentExperienceList } = useQuery(
    GET_FINANCIAL_INSTRUMENT_EXPIRIENCE_LIST
  );
  const { data: financialInstrumentAnnualOperationsList } = useQuery(
    GET_FINANCIAL_INSTRUMENT_ANNUAL_OPERATIONS_LIST
  );
  const { data: financialInstrumentKnowledgeLevels } = useQuery(
    GET_FINANCIAL_INSTRUMENT_KNOWLEDGELEVELS
  );
  const [loadingSection, setLoadingSection] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );

  const instrumento = watch('instrumento');
  const tiempoExpInstrumentos = watch('tiempoExpInstrumentos');
  const cantidadOperacionesAnio = watch('cantidadOperacionesAnio');
  const nivelConocimientoInstrumentos = watch('nivelConocimientoInstrumentos');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );
      getFinancialInstrumentsSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then((data) => {
        if(data?.data?.financialInstrumentsSection?.id){
          setFinancialInstrumentSectionId(
            data?.data?.financialInstrumentsSection?.id
          );
        }
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos'
    );
  };

  const navigateToNextPage = () => {
    if(
      !isFormDisqualified &&
      !isFormCompleted &&
      !financialInstrumentSectionId &&
      !financialInstrumentsSectionData?.error
    ){
      const data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        financialInstrumentInputTypes: [],
      };
      saveFinancialInstrumentsSection({
        variables: {
          financialInstrumentsSection: data,
        },
      }).then((data) => {
        setFinancialInstrumentSectionId(
          data?.data?.saveFinancialInstrumentsSection?.id
        );
      });
    }
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/cliente-profesional'
    );
  };

  const addOrEditInstrument = (id) => {
    let data = {};

    const financialInstruments =
      financialInstrumentsSectionData?.data?.financialInstrumentsSection
        ?.financialInstruments;
    const arrayToSave = [];

    if (financialInstruments !== null && financialInstruments !== undefined) {
      for (let elem of financialInstruments) {
        arrayToSave.push({
          id: elem?.id,
          financialInstrumentAnnualOperationsId:
            elem?.financialInstrumentAnnualOperations?.id,
          financialInstrumentExperienceId:
            elem?.financialInstrumentExperience?.id,
          financialInstrumentKnowledgeLevelId:
            elem?.financialInstrumentKnowledgeLevel?.id,
          financialInstrumentTypeId: elem?.financialInstrumentType?.id,
        });
      }
    }

    if (id) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        id: financialInstrumentSectionId,
        financialInstrumentInputTypes: arrayToSave?.filter(
          (instrument) => instrument.id !== id
        ),
      };
    } else {
      //Si financialInstrumentSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
      if (financialInstrumentSectionId) {
        // Si selectedIntrumentId no tiene valor hay que agregar n nuevo instrmento, si tiene valor hay que editar ese instrumneto a partir de su id
        if (selectedIntrumentId) {
          arrayToSave.push({
            id: selectedIntrumentId,
            financialInstrumentAnnualOperationsId: cantidadOperacionesAnio,
            financialInstrumentExperienceId: tiempoExpInstrumentos,
            financialInstrumentKnowledgeLevelId: nivelConocimientoInstrumentos,
            financialInstrumentTypeId: instrumento,
          });

          data = {
            formResponseId:
              formResponseByIdentification?.data?.formResponses[0]?.id,
            id: financialInstrumentSectionId,
            financialInstrumentInputTypes: arrayToSave,
          };
        } else {
          arrayToSave.push({
            financialInstrumentAnnualOperationsId: cantidadOperacionesAnio,
            financialInstrumentExperienceId: tiempoExpInstrumentos,
            financialInstrumentKnowledgeLevelId: nivelConocimientoInstrumentos,
            financialInstrumentTypeId: instrumento,
          });

          data = {
            formResponseId:
              formResponseByIdentification?.data?.formResponses[0]?.id,
            id: financialInstrumentSectionId,
            financialInstrumentInputTypes: arrayToSave,
          };
        }
      } else {
        arrayToSave.push({
          financialInstrumentAnnualOperationsId: cantidadOperacionesAnio,
          financialInstrumentExperienceId: tiempoExpInstrumentos,
          financialInstrumentKnowledgeLevelId: nivelConocimientoInstrumentos,
          financialInstrumentTypeId: instrumento,
        });

        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          financialInstrumentInputTypes: arrayToSave,
        };
      }
    }

    saveFinancialInstrumentsSection({
      variables: {
        financialInstrumentsSection: data,
      },
    }).then((data) => {
      setFinancialInstrumentSectionId(
        data?.data?.saveFinancialInstrumentsSection?.id
      );
    });

    generateInvestmentProfile({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });

    btnCloseModalRef.current.click();
    reset();
  };

  const handleEdit = (id) => {
    setSelectedIntrumentId(id);
    const selectedInstrument =
      financialInstrumentsSectionData?.data?.financialInstrumentsSection?.financialInstruments?.find(
        (x) => x.id === id
      );
    if (id && selectedInstrument) {
      setValue('instrumento', selectedInstrument?.financialInstrumentType?.id);
      setValue(
        'tiempoExpInstrumentos',
        selectedInstrument?.financialInstrumentExperience?.id
      );
      setValue(
        'cantidadOperacionesAnio',
        selectedInstrument?.financialInstrumentAnnualOperations?.id
      );
      setValue(
        'nivelConocimientoInstrumentos',
        selectedInstrument?.financialInstrumentKnowledgeLevel?.id
      );
    }
  };

  const deleteCardElement = (id) => {
    addOrEditInstrument(id);
  };

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(selectedIntrumentId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const filterInstrumentsTypes = (instrument) => {
    let inUseTypes =
      financialInstrumentsSectionData?.data?.financialInstrumentsSection?.financialInstruments?.map(
        (instrumentType) => instrumentType.financialInstrumentType
      );

    if (instrument) {
      const instrumentToEdit =
        financialInstrumentsSectionData?.data?.financialInstrumentsSection?.financialInstruments?.find(
          (existingInstrument) => existingInstrument.id === selectedIntrumentId
        );
      inUseTypes = inUseTypes?.filter(
        (type) => type.id !== instrumentToEdit.financialInstrumentType.id
      );
    }

    const idsToRemove = new Set(
      inUseTypes?.length ? inUseTypes?.map((item) => item.id) : []
    );
    const typesToUse =
      financialInstrumentTypes?.financialInstrumentTypes?.filter(
        (item) => !idsToRemove.has(item.id)
      );

    return typesToUse;
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'financialInstrumentsSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  return (
    <div className="page-container" id="page-container">
      <div className="principal-container3">
        <h3 className="formTitle" style={{ color: '#3d675e' }}>
          Instrumentos financieros negociados previamente
          <ModalInfo
            Icon={<FontAwesomeIcon icon={faCircleInfo} />}
            title={'Instrumentos financieros negociados previamente'}
            description={
              "En el formulario 'Instrumentos Financieros Negociados Prevíamente', se realiza un análisis detallado de la experiencia y participación de la institución en negociaciones de instrumentos financieros. Este espacio identifica los instrumentos financieros con los que la institución ha negociado previamente, incluyendo detalles sobre la cantidad, nivel de experiencia y el conocimiento asociado con cada uno. La evaluación de esta información permite obtener una visión precisa del historial de negociación y el nivel de familiaridad con diversos instrumentos financieros por parte de la institución."
            }
          />
          {!!validationErrors?.length && (
            <span style={{ marginLeft: 30 }}>
              <ModalInfo
                color="white"
                bgcolor="#B90E0A"
                Icon={
                  <Error
                    style={{
                      color: '#B90E0A',
                      fontSize: 25,
                      marginTop: '-5px',
                    }}
                  />
                }
                description={
                  'Es necesario que agregues por lo menos un instrumento financiero.'
                }
              />
            </span>
          )}
        </h3>

        {loadingSection ? (
          <div className="w-100 d-flex justify-content-center p-5">
            <CircularProgress color="warning" />
          </div>
        ) : (
          <div className="add-card-container">
            {financialInstrumentsSectionData?.data?.financialInstrumentsSection?.financialInstruments?.map(
              (instrument, index) => {
                return (
                  <div
                    key={index}
                    data-bs-toggle={isUpdating ? 'modal' : ''}
                    id={instrument.id}
                    data-bs-target={isUpdating ? '#exampleModal' : ''}
                  >
                    <CardItems
                      key={index}
                      nameElement={instrument?.financialInstrumentType?.name}
                      idElement={instrument.id}
                      deleteElement={deleteCardElement}
                      handleEdit={handleEdit}
                      setIsUpdating={setIsUpdating}
                      showDeleteButton={!isFormCompleted && !isFormDisqualified}
                    />
                  </div>
                );
              }
            )}
            {!isFormCompleted && !isFormDisqualified &&
              (!financialInstrumentsSectionData?.data
                ?.financialInstrumentsSection ||
                financialInstrumentsSectionData?.data?.financialInstrumentsSection
                  ?.financialInstruments?.length < 3) ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    reset();
                    setSelectedIntrumentId('');
                    setIsUpdating(false);
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">
                    Agregar Instrumento Financiero
                  </h4>
                </div>
              ) : null
            }
            { (isFormCompleted || isFormDisqualified) && !financialInstrumentsSectionData?.data?.financialInstrumentsSection
                  ?.financialInstruments?.length ?
              <div className="add-card" style={{ pointerEvents: 'none' }}>
                <div className="image-icon-container">
                  <FontAwesomeIcon
                    icon={faWarning}
                    className="image-icon"
                  />
                </div>
                <h4 className="bk-card-title">
                  {' '}
                  No hay instrumentos que mostrar{' '}
                </h4>
              </div> : null
            }
          </div>
        )}

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="button"
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>

        <>
          <div
            className="modal fade financial-instrument"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              className="modal-dialog"
              style={{ height: '90%', display: 'flex', alignItems: 'center' }}
            >
              <div className="modal-content" style={{ padding: 15 }}>
                <form
                  onSubmit={handleSubmit(() => addOrEditInstrument())}
                  className="fields-modal-container"
                >
                  <div className="fields-modal-header">
                    <h4 className="modal-title fs-5" id="exampleModalLabel">
                      {!isFormCompleted && !isFormDisqualified
                        ? selectedIntrumentId
                          ? 'Editar'
                          : 'Agregar'
                        : 'Ver'}{' '}
                      instrumento financiero
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      className="btn-close"
                      onClick={() => {
                        reset();
                        setSelectedIntrumentId('');
                        setIsUpdating(false);
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div className="row fields-modal-content">
                    <div className="col-sm-12">
                      <div className="field-form">
                        <label htmlFor="instrumento" className="label-field">
                          Instrumento <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="instrumento"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('instrumento', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {filterInstrumentsTypes(selectedIntrumentId)?.map(
                              (option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.instrumento && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="tiempo-exp-instrmentos"
                          className="label-field"
                        >
                          Tiempo de experiencia en instrumentos
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="tiempo-exp-instrmentos"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tiempoExpInstrumentos', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {financialInstrumentExperienceList?.financialInstrumentExperienceList.map(
                              (option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.tiempoExpInstrumentos && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="cantidad-operaciones-anio"
                          className="label-field"
                        >
                          Cantidad de operaciones por año <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="cantidad-operaciones-anio"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('cantidadOperacionesAnio', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {financialInstrumentAnnualOperationsList?.financialInstrumentAnnualOperationsList.map(
                              (option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.cantidadOperacionesAnio && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>

                      <div className="field-form">
                        <label
                          htmlFor="nivel-conocimiento-instrumentos"
                          className="label-field"
                        >
                          Nivel de conocimiento instrumentos de riesgos
                          asociados <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="nivel-conocimiento-instrumentos"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('nivelConocimientoInstrumentos', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {financialInstrumentKnowledgeLevels?.financialInstrumentKnowledgeLevels.map(
                              (option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.nivelConocimientoInstrumentos && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="row d-flex mt-1 justify-content-center">
                      <button type="submit" className="mt-2 mb-4 btn-add">
                        Guardar
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default FinancialInstrumentsLpFForm;
