import React from 'react';
import { Nav } from 'react-bootstrap';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer>
      <strong>Contacte Servicio al Cliente</strong>
      <p style={{ marginBottom: 0 }}>809-560-0909 Ext.: 23333</p>
      <a
        href="/"
        style={{ textAlign: 'left', marginBottom: '20px' }}
        className="linkYellow"
      >
        Info@parval.com.do
      </a>
      <Nav>
        <Nav.Item>
          <Nav.Link className="px-2" href="/home">
            <a href="/logout">
              <LogoutIcon fontSize="small" style={{ color: '#5C5D5F' }} />{' '}
              Cerrar Sesión
            </a>
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link className="px-2" eventKey="link-1">
            <Link to="/profile">
              <PersonIcon fontSize="small" style={{ color: '#5C5D5F' }} />{' '}
              Información Personal
            </Link>
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </footer>
  );
}
