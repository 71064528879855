import './SubtypeAccountSummary.css';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import {
  getDecodedCookie,
  modifyCookie,
} from '../../../../shared/utils/functions';
import { GET_SUB_APPLICANT_TYPE } from '../../../../shared/graphQL/queries';
import {
  AZURE_SESSION_TIME_OUT,
  azureUserFlowUri,
} from '../../../../shared/constants/gobal-constants';

export default function SubtypeAccountSummary() {
  const [subtype, setSubtype] = useState(null);
  const { loading, error, data } = useQuery(GET_SUB_APPLICANT_TYPE);
  const [animationOut, setAnimationOut] = useState(false);
  const [reload, setReload] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const configDecoded = getDecodedCookie('potencialCustomerConfigRC');

    if (configDecoded && configDecoded.assistUnit) {
      setReload(true);
      setSubtype(configDecoded.subtype);
    }

    if (!configDecoded) {
      window.location.replace(azureUserFlowUri());
    }
  }, []);

  if (error) {
    toast('Hubo un problema al obtener los tipos de solicitantes', {
      type: 'error',
      theme: 'colored',
    });
  }

  useEffect(() => {
    if (subtype && !reload) {
      goTo();
    }
  }, [subtype, reload]);

  const goTo = async () => {
    const timeout = AZURE_SESSION_TIME_OUT;
    modifyCookie(
      'potencialCustomerConfigRC',
      'subtype',
      subtype,
      true,
      timeout
    );
    setTimeout(() => {
      navigate(
        `/onboarding-digital/dashboard/apertura-de-cuenta/tipo-de-empresa`
      );
    }, 500);
    setAnimationOut(true);
  };

  const goBack = () => {
    setAnimationOut(true);
    setTimeout(() => {
      navigate(
        '/onboarding-digital/dashboard/apertura-de-cuenta/unidad-de-asistencia'
      );
    }, 500);
  };

  return (
    <div className="accountSumary">
      <div
        className={
          'contBox ' +
          (animationOut
            ? 'animate__animated animate__fadeOutLeft'
            : 'animate__animated animate__fadeInRight')
        }
      >
        <Row className="p-0 m-0">
          <Col className="">
            <div className="vAlign" style={{ padding: '2rem' }}>
              <h3 className="formTitle">¿Qué tipo de solicitante eres?</h3>
              <div className="cliente-potencial-register-form">
                <div className="mt-2 newForm">
                  {!loading && !error && data && (
                    <div className="assist-unit cards-container-summary-account">
                      {data?.subApplicantTypes.map((p) => (
                        <div
                          key={p.id}
                          className={`boxButton card-element`}
                          onClick={() => {
                            setReload(false);
                            setSubtype(p);
                          }}
                        >
                          <div>
                            <h5 className="rwf-card-title">{p.name}</h5>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {loading && (
                    <div className="w-100 d-flex justify-content-center p-5">
                      <CircularProgress color="warning" />
                    </div>
                  )}
                </div>
              </div>
              <p className="linkBack bottom" onClick={goBack}>
                <FontAwesomeIcon icon={faAngleLeft} /> Volver
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
