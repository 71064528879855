import { gql } from '@apollo/client';

export const GET_APP_AUTH = gql`
  mutation AppClientToken($appId: String!) {
    appClientToken(appId: $appId) {
      token
    }
  }
`;

export const SAVE_PRIMARY_CONTACT = gql`
  mutation SavePrimaryContact($primaryContact: PrimaryContactInputType!) {
    savePrimaryContact(primaryContact: $primaryContact) {
      email
      firstName
      lastName
      primaryContactDetails {
        id
      }
    }
  }
`;

export const SAVE_LEAD = gql`
  mutation SaveLead($lead: LeadInputType!) {
    saveLead(lead: $lead) {
      primaryContact {
        email
      }
    }
  }
`;

export const SAVE_GENERAL_INFORMATION = gql`
  mutation SaveCompanyInformationSection(
    $companyInformationSection: CompanyInformationSectionInputType!
  ) {
    saveCompanyInformationSection(
      companyInformationSection: $companyInformationSection
    ) {
      id
      commercialName
      registeredNumber
      constitutionDate
      registeredNumberExpirationDate
      societyType {
        id
        name
      }
      country {
        id
        name
      }
      equityType {
        id
        name
      }
      companyOrigin {
        id
        name
      }
      primaryPhone
      secondaryPhone
      websiteUrl
      bearerShares
      hasBCRDAccount
      custodian
      clearingNumber
      accountHeld
      isListedOnStock
    }
  }
`;

export const SAVE_COMPANY_OFFICE_SECTION = gql`
  mutation SaveCompanyOfficesSection(
    $companyOfficesSection: CompanyOfficesSectionInputType!
  ) {
    saveCompanyOfficesSection(companyOfficesSection: $companyOfficesSection) {
      companyOffices {
        id
        creationDate
        modificationDate
        isPrincipal
        state
        city
        street
        buildingNumber
        buildingName
        apartment
        sector
        postalCode
        phoneNumber
        phoneNumberAlternative
        fax
        webPageUrl
        country {
          id
          name
        }
      }
    }
  }
`;

export const SAVE_COMPANY_SHAREHOLDER_SECTION = gql`
  mutation Mutation(
    $companyShareholdersSection: CompanyShareholdersSectionInputType!
  ) {
    saveCompanyShareholdersSection(
      companyShareholdersSection: $companyShareholdersSection
    ) {
      id
      shareholders {
        country {
          id
          name
        }
        id
        identification
        identificationType {
          id
          name
        }
        personType {
          id
          name
        }
        isInDarkList
        ownershipPercentage
        sharesNumber
        shareholderCompanyDetails {
          businessName
          controllingShareholders
          economicActivity {
            id
            name
          }
          hasBearerShares
          id
          parvalRelationship {
            id
            name
          }
          societyType {
            id
            name
          }
        }
        shareholderPersonDetails {
          charge
          firstName
          id
          lastName
          politicExposure {
            id
            isPep
            politicExposureDetails {
              id
              charge
              relativeName
              relativeRelationship
              institution
              active
              chargeStartDate
              chargeEndDate
              politicExposureType {
                id
                name
              }
            }
          }
        }
        beneficiaries {
          address
          email
          firstName
          id
          identificationNumber
          identificationType {
            id
            name
          }
          contactFatca {
            id
            haveUnitedStatesNationality
            isUnitedStatesCitizen
            isUnitedStatesResident
            socialSecurityNumber
            zipInUnitedStates
            phoneInUnitedStates
            haveRepresentativeInUnitedStates
            haveTaxIdentificationNumber
            taxIdentificationNumber
            greenCard
            addressInUnitedStates
          }
          lastName
          occupation
          phoneNumber
        }
        contactFatca {
          id
          haveUnitedStatesNationality
          isUnitedStatesCitizen
          isUnitedStatesResident
          socialSecurityNumber
          zipInUnitedStates
          phoneInUnitedStates
          haveRepresentativeInUnitedStates
          haveTaxIdentificationNumber
          taxIdentificationNumber
          greenCard
          addressInUnitedStates
        }
      }
      totalDistributedShares
      totalSharesIssued
    }
  }
`;
export const CHECK_LEXIS_NEXIS = gql`
  mutation CheckLexisNexis(
    $lexisNexisVerifyInputType: LexisNexisVerifyInputType!
  ) {
    checkLexisNexis(lexisNexisVerifyInputType: $lexisNexisVerifyInputType) {
      response
      quantity
      isInDarkList
      id
      shareholderId
      legalRepresentativeId
    }
  }
`;

export const SEND_LEGAL_REPRESENTATIVE_IDENTIFICATION = gql`
  mutation RequestLegalRepresentativeIdentityVerification(
    $legalRepresentativeId: String!
  ) {
    requestLegalRepresentativeIdentityVerification(
      legalRepresentativeId: $legalRepresentativeId
    ) {
      id
      creationDate
      modificationDate
      firstName
      lastName
      correspondenceEmail {
        id
        email
        correspondenceEmailType {
          id 
          name
        }
      }
      identification
      position
      identificationType {
        id
        creationDate
        modificationDate
        name
      }
      legalRepresentativeType {
        id
        creationDate
        modificationDate
        name
      }
      civilStatus {
        id
        creationDate
        modificationDate
        name
      }
      nationalityCountry {
        id
        creationDate
        modificationDate
        name
      }
      address {
        id
        country {
          name
        }
        province {
          name
        }
        sector {
          name
        }
        zipCode
        street
        building
        houseNumber
        apartmentNumber
      }
      verificationStatus
      verificationId
    }
  }
`;

export const SAVE_FORM_RESPONSE = gql`
  mutation SaveFormResponse($formResponse: FormResponseInputType!) {
    saveFormResponse(formResponse: $formResponse) {
      id
      creationDate
      modificationDate
      form {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        order
        personType {
          id
          creationDate
          modificationDate
          name
          parameterCode
          coreParameterCode
        }
      }
      client {
        id
        creationDate
        modificationDate
        codeLA
        codeRIF
        rnt
        cevaldomAccountNumber
      }
      riskLevel {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        altCoreParameterCode
      }
      previousPhase {
        id
        creationDate
        modificationDate
        name
      }
      phase {
        id
        creationDate
        modificationDate
        name
      }
      phaseChangeDate
      formattedFormResponseId
      formResponseStatus {
        id
        name
      }
      lead {
        identification
        identificationType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        accountRepresentative {
          id
          creationDate
          modificationDate
          firstName
          lastName
          SIVLicense
          email
          outlookId
          imageURL
          branches {
            id
            creationDate
            modificationDate
            name
            coreParameterCode
            shortName
            address
          }
          accountEnabled
          businessPhones
          personTypes {
            id
            creationDate
            modificationDate
            name
            parameterCode
            coreParameterCode
          }
        }
        primaryContact {
          email
          firstName
          lastName
          primaryContactDetails {
            id
            creationDate
            modificationDate
            phoneNumber
            secondaryPhoneNumber
            primaryContactEmail
          }
          creationDate
          modificationDate
        }
        companyLeadDetails {
          id
          creationDate
          modificationDate
          applicantType {
            id
            creationDate
            modificationDate
            name
          }
          subApplicantType {
            id
            creationDate
            modificationDate
            name
          }
        }
        creationDate
        modificationDate
        isRelatedToParval
        parvalRelationship {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
        linkageType {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
        leadManagementType {
          id
          creationDate
          modificationDate
          name
        }
        branch {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
          shortName
          address
          publicNotary {
            id
            creationDate
            modificationDate
            firstName
            lastName
            email
            collegeNumber
            zone
          }
        }
      }
    }
  }
`;

export const SAVE_LEGAL_REPRESENTATIVES_SECTION = gql`
  mutation SaveLegalRepresentativesSection(
    $legalRepresentativesSection: LegalRepresentativesSectionInputType!
  ) {
    saveLegalRepresentativesSection(
      legalRepresentativesSection: $legalRepresentativesSection
    ) {
      id
      legalRepresentatives {
        correspondenceEmail {
          id
          email
          correspondenceEmailType {
            id 
            name
          }
        }
        firstName
        id
        identification
        lastName
        position
        isInDarkList
        verificationStatus
        verificationId
        gender {
          id
          name
        }
        birthDate
        lexisNexisResponse {
          id
          response
          quantity
          isInDarkList
        }
        civilStatus {
          id
          name
        }
        identificationType {
          id
          name
        }
        nationalityCountry {
          id
          name
        }
        address {
          id
          addressType {
            id
            name
          }
          country {
            id
            name
          }
          province {
            id
            name
          }
          sector {
            id
            name
          }
          zipCode
          street
          building
          houseNumber
          apartmentNumber
        }
        politicExposure {
          id
          isPep
          politicExposureDetails {
            id
            charge
            relativeName
            relativeRelationship
            institution
            active
            chargeStartDate
            chargeEndDate
            politicExposureType {
              id
              name
            }
          }
        }
        contactFatca {
          id
          haveUnitedStatesNationality
          isUnitedStatesCitizen
          isUnitedStatesResident
          socialSecurityNumber
          zipInUnitedStates
          phoneInUnitedStates
          haveRepresentativeInUnitedStates
          haveTaxIdentificationNumber
          taxIdentificationNumber
          greenCard
          addressInUnitedStates
        }
        legalRepresentativeType {
          id
          name
        }
      }
    }
  }
`;

export const SAVE_POLITIC_EXPOSURE = gql`
  mutation SavePoliticExposure($politicExposure: PoliticExposureInputType!) {
    savePoliticExposure(politicExposure: $politicExposure) {
      id
      isPep
      politicExposureDetails {
        institution
        charge
        chargeEndDate
        chargeStartDate
      }
    }
  }
`;

export const SAVE_RELATED_COMPANIES_SECTION = gql`
  mutation SaveRelatedCompaniesSection(
    $relatedCompaniesSection: RelatedCompaniesSectionInputType!
  ) {
    saveRelatedCompaniesSection(
      relatedCompaniesSection: $relatedCompaniesSection
    ) {
      id
      relatedCompanies {
        id
        businessName
        identification
        ownerEmail
        ownerFirstName
        ownerLastName
        ownerPhoneNumber
      }
    }
  }
`;

export const SAVE_COMPANY_ECONOMIC_DETAILS_SECTION = gql`
  mutation SaveCompanyEconomicDetailsSection(
    $companyEconomicDetailsSection: CompanyEconomicDetailsSectionInputType!
  ) {
    saveCompanyEconomicDetailsSection(
      companyEconomicDetailsSection: $companyEconomicDetailsSection
    ) {
      economicActivity {
        id
        name
      }
    }
  }
`;

export const SAVE_PRIMARY_CONTACT_DETAILS = gql`
  mutation SavePrimaryContactDetails(
    $primaryContactDetails: PrimaryContactDetailsInputType!
  ) {
    savePrimaryContactDetails(primaryContactDetails: $primaryContactDetails) {
      id
    }
  }
`;

export const SAVE_FATCA_SECTION = gql`
  mutation SaveFATCASection($fatcaSection: FATCASectionInputType!) {
    saveFATCASection(fatcaSection: $fatcaSection) {
      id
      haveTaxIdentificationNumber
    }
  }
`;

export const CHECK_POLITIC_EXPOSURE = gql`
  mutation CheckPoliticExposure($identification: String!) {
    checkPoliticExposure(identification: $identification) {
      isPEP
      politicExposureType {
        id
        name
      }
      relativeRelationship
      charge
      errorMessage
      error
      relativeName
      responseCode
    }
  }
`;

export const SAVE_BENEFICIARY = gql`
  mutation SaveBeneficiary($beneficiary: BeneficiaryInputType!) {
    saveBeneficiary(beneficiary: $beneficiary) {
      address
      email
      firstName
      id
      identificationNumber
      identificationType {
        id
        name
      }
      contactFatca {
        id
        haveUnitedStatesNationality
        isUnitedStatesCitizen
        isUnitedStatesResident
        socialSecurityNumber
        zipInUnitedStates
        phoneInUnitedStates
        haveRepresentativeInUnitedStates
        haveTaxIdentificationNumber
        taxIdentificationNumber
        greenCard
        addressInUnitedStates
      }
      lastName
      occupation
      phoneNumber
      politicExposure {
        id
        isPep
        politicExposureDetails {
          id
          charge
          relativeName
          relativeRelationship
          institution
          active
          chargeStartDate
          chargeEndDate
          politicExposureType {
            id
            name
          }
        }
      }
    }
  }
`;

export const SAVE_BENEFICIARIES_SECTION = gql`
  mutation SaveBeneficiariesSection(
    $beneficiariesSection: BeneficiariesSectionInputType!
  ) {
    saveBeneficiariesSection(beneficiariesSection: $beneficiariesSection) {
      id
      beneficiaries {
        firstName
        lastName
        identificationNumber
        identificationType {
          id
          name
        }
        occupation
        phoneNumber
        id
      }
    }
  }
`;

export const SAVE_FINANCIAL_INSTRUMENTS_SECTION = gql`
  mutation SaveFinancialInstrumentsSection(
    $financialInstrumentsSection: FinancialInstrumentsSectionInputType!
  ) {
    saveFinancialInstrumentsSection(
      financialInstrumentsSection: $financialInstrumentsSection
    ) {
      id
      financialInstruments {
        id
        financialInstrumentType {
          id
          name
        }
        financialInstrumentKnowledgeLevel {
          id
          name
        }
        financialInstrumentExperience {
          id
          name
        }
        financialInstrumentAnnualOperations {
          id
          name
        }
      }
    }
  }
`;

export const SAVE_COMMERCIAL_REFERENCES_SECTION = gql`
  mutation SaveCommercialReferencesSection(
    $commercialReferencesSection: CommercialReferencesSectionInputType!
  ) {
    saveCommercialReferencesSection(
      commercialReferencesSection: $commercialReferencesSection
    ) {
      id
      commercialReferences {
        id
        email
        name
        phoneNumber
      }
    }
  }
`;

export const SAVE_BANK_ACCOUNT_SECTION = gql`
  mutation SaveBankAccountSection(
    $bankAccountSection: BankAccountSectionInputType!
  ) {
    saveBankAccountSection(bankAccountSection: $bankAccountSection) {
      id
      creationDate
      modificationDate
      bankAccounts {
        id
        creationDate
        modificationDate
        accountHolder
        accountNumber
        intermediaryBankAddress
        abaOrSwift
        hasIntermediaryBank
        intermediaryBank {
          id
          creationDate
          modificationDate
          name
          parameterCode
          businessName
          logoUrl
          isInternational
        }
        bank {
          id
          creationDate
          modificationDate
          name
          parameterCode
          businessName
          logoUrl
          isInternational
        }
        bankAccountType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        currency {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        bankAccountOriginType {
          id
          creationDate
          modificationDate
          name
        }
        bankAccountPersonType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
      }
    }
  }
`;

export const SAVE_DUE_DELIGENCE_SECTION = gql`
  mutation SaveDueDiligenceSection(
    $dueDiligenceSection: DueDiligenceSectionInputType!
  ) {
    saveDueDiligenceSection(dueDiligenceSection: $dueDiligenceSection) {
      isObligatedSubject
      id
    }
  }
`;

export const SAVE_INVESTMENT_PROFILE_DESCRIPTION_SECTION = gql`
  mutation SaveInvestmentProfileDescriptionSection(
    $investmentProfileDescriptionSection: InvestmentProfileDescriptionSectionInputType!
  ) {
    saveInvestmentProfileDescriptionSection(
      investmentProfileDescriptionSection: $investmentProfileDescriptionSection
    ) {
      id
    }
  }
`;

export const SAVE_INVESTMENT_FINANCIAL_DETAILS_SECTION = gql`
  mutation SaveInvestmentFinancialDetailsSection(
    $investmentFinancialDetailsSection: InvestmentFinancialDetailsSectionInputType!
  ) {
    saveInvestmentFinancialDetailsSection(
      investmentFinancialDetailsSection: $investmentFinancialDetailsSection
    ) {
      accountsPayable {
        id
        name
      }
      annualExpenses {
        id
        name
      }
      annualRevenue {
        id
        name
      }
      emergencyFunds {
        id
        name
      }
      id
      liquidAssets {
        id
        name
      }
      totalPatrimony {
        id
        name
      }
      liquidAssetsTypeDistributions {
        id
        liquidAssetsType {
          id
          name
        }
        percentage
      }
    }
  }
`;

export const SAVE_INVESTMENT_EXPERIENCE_SECTION = gql`
  mutation SaveInvestmentExperienceSection(
    $investmentExperienceSection: InvestmentExperienceSectionInputType!
  ) {
    saveInvestmentExperienceSection(
      investmentExperienceSection: $investmentExperienceSection
    ) {
      investmentExperience {
        id
        name
      }
      operationTime {
        id
        name
      }
    }
  }
`;

export const SAVE_INVESTOR_CLASIFICATION_SECTION = gql`
  mutation SaveInvestorClassificationSection(
    $investorClassificationSection: InvestorClassificationSectionInputType!
  ) {
    saveInvestorClassificationSection(
      investorClassificationSection: $investorClassificationSection
    ) {
      id
      investmentExperienceChecks {
        id
        name
      }
      investmentKnowledgeChecks {
        id
        name
      }
      patrimonialCapacityChecks {
        id
        name
      }
    }
  }
`;

export const SAVE_COMMUNICATION_SECTION = gql`
  mutation SaveCommunicationsSection(
    $communicationsSection: CommunicationsSectionInputType!
  ) {
    saveCommunicationsSection(CommunicationsSection: $communicationsSection) {
      correspondenceConfirmations {
        id
        name
      }
      correspondenceDelivery {
        id
        name
      }
      id
    }
  }
`;

export const SAVE_MARKETING_SECTION = gql`
  mutation SaveMarketingSection($marketingSection: MarketingSectionInputType!) {
    saveMarketingSection(marketingSection: $marketingSection) {
      id
    }
  }
`;

export const SAVE_SIGNATURES_SECTION = gql`
  mutation SaveSignaturesSection(
    $signaturesSection: SignaturesSectionInputType!
  ) {
    saveSignaturesSection(signaturesSection: $signaturesSection) {
      id
      signatureType {
        id
        name
      }
      signaturesCard {
        id
        signatories {
          email
          firstName
          id
          identification
          isRepresentative
          lastName
          orderNumber
          signatureType {
            id
            name
          }
        }
      }
    }
  }
`;

export const SAVE_ADDRESS = gql`
  mutation SaveAddress($address: AddressInputType!) {
    saveAddress(address: $address) {
      id
      creationDate
      modificationDate
      addressType {
        id
        creationDate
        modificationDate
        name
        personType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
      }
      country {
        id
        creationDate
        modificationDate
        name
        parameterCode
      }
      province {
        id
        creationDate
        modificationDate
        name
        parameterCode
      }
      sector {
        id
        creationDate
        modificationDate
        name
        parameterCode
      }
      street
      zipCode
      building
      houseNumber
      apartmentNumber
    }
  }
`;

export const SAVE_ADDRESSES_SECTION = gql`
  mutation SaveAddressesSection($addressesSection: AddressesSectionInputType!) {
    saveAddressesSection(addressesSection: $addressesSection) {
      id
      creationDate
      modificationDate
      addresses {
        id
        creationDate
        modificationDate
        addressType {
          id
          creationDate
          modificationDate
          name
          personType {
            id
            creationDate
            modificationDate
            name
            parameterCode
          }
        }
        country {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        province {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        sector {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        street
        zipCode
        building
        houseNumber
        apartmentNumber
      }
    }
  }
`;

export const GENERATE_INVESTMENT_PROFILE = gql`
  mutation GenerateInvestmentProfile($formResponseId: String!) {
    generateInvestmentProfile(formResponseId: $formResponseId) {
      id
      investmentProfile {
        id
        name
        description
      }
      investmentProfileType {
        id
        name
      }
      profileScore
      profileTypeScore
    }
  }
`;

export const REMOVE_COMNPANY_OFFICE = gql`
  mutation RemoveCompanyOffice($companyOfficeId: String!) {
    removeCompanyOffice(companyOfficeId: $companyOfficeId)
  }
`;

export const REMOVE_LEGAL_REPRESENTATIVE = gql`
  mutation RemoveLegalRepresentative($legalRepresentativeId: String!) {
    removeLegalRepresentative(legalRepresentativeId: $legalRepresentativeId)
  }
`;

export const REMOVE_RELATED_COMPANY = gql`
  mutation RemoveRelatedCompany($relatedCompanyId: String!) {
    removeRelatedCompany(relatedCompanyId: $relatedCompanyId)
  }
`;

export const REMOVE_BENEFICIARY = gql`
  mutation RemoveBeneficiary($beneficiaryId: String!) {
    removeBeneficiary(beneficiaryId: $beneficiaryId)
  }
`;

export const REMOVE_BANK_ACCOUNT = gql`
  mutation RemoveBankAccount($bankAccountId: String!) {
    removeBankAccount(bankAccountId: $bankAccountId)
  }
`;

export const REMOVE_LIQUID_ASSETS_TYPE_DISTRIBUTION = gql`
  mutation RemoveLiquidAssetsTypeDistribution(
    $liquidAssetsTypeDistributionId: String!
  ) {
    removeLiquidAssetsTypeDistribution(
      liquidAssetsTypeDistributionId: $liquidAssetsTypeDistributionId
    )
  }
`;

export const REMOVE_SHAREHOLDER = gql`
  mutation RemoveShareholder($shareholderId: String!) {
    removeShareholder(shareholderId: $shareholderId)
  }
`;

export const REMOVE_SIGNATORY = gql`
  mutation RemoveSignatory($signatoryId: String!) {
    removeSignatory(signatoryId: $signatoryId)
  }
`;

export const REMOVE_ADDRESSES = gql`
  mutation RemoveAddress($addressId: String!) {
    removeAddress(addressId: $addressId)
  }
`;

export const REMOVE_COMMERCIAL_REFERENCE = gql`
  mutation RemoveCommercialReference($commercialReferenceId: String!) {
    removeCommercialReference(commercialReferenceId: $commercialReferenceId)
  }
`;

export const SAVE_FILE_MANAGER_CLIENT = gql`
  mutation SaveFileManagerClient(
    $fileManagerClient: FileManagerClientInputType!
  ) {
    saveFileManagerClient(fileManagerClient: $fileManagerClient) {
      clientId
      clientAccounts {
        id
        client
        identification
        name
        email
        risk
        typeIdentification
      }
      clientPersonTypeName
      clientAccountTypeName
      executiveInfo {
        id
        firstName
        lastName
      }
      executiveOffice {
        id
        name
        address
      }
    }
  }
`;

export const SAVE_DOCUMENT_SECTION = gql`
  mutation SaveDocumentSection($documentSection: DocumentSectionInputType!) {
    saveDocumentSection(documentSection: $documentSection) {
      id
      creationDate
      modificationDate
      documentsCompletionOption {
        id
        name
      }
      documents {
        id
        creationDate
        modificationDate
        fileName
        fileManagerFileId
        modifiedBy
        lastActivity
        documentType {
          id
          creationDate
          modificationDate
          name
          fileManagerDocumentTypeId
          requireSignature
          isSelfGenerated
        }
        status {
          id
          creationDate
          modificationDate
          name
        }
      }
    }
  }
`;

export const REMOVE_DOCUMENT = gql`
  mutation RemoveDocument($documentId: String!) {
    removeDocument(documentId: $documentId)
  }
`;

export const SAVE_SIGNATURE_PROCESS = gql`
  mutation SaveSignatureProcess(
    $documentId: String!
    $signatureProcess: SignatureProcessInputType!
  ) {
    saveSignatureProcess(
      documentId: $documentId
      signatureProcess: $signatureProcess
    ) {
      id
      creationDate
      modificationDate
      documentId
      code
      sha
      signedID
      error
      status
      recipients {
        id
        creationDate
        modificationDate
        key
        messageCode
        status
        mail
        name
      }
      messages {
        id
        creationDate
        modificationDate
        code
        status
        commentReject
        auditTrailPage
        policies {
          id
          creationDate
          modificationDate
          evidences {
            id
            creationDate
            modificationDate
            type
            enabled
            visible
            code
            status
            recipientKey
            imageTextSize
            legalText
          }
          signatures {
            id
            creationDate
            modificationDate
            type
            code
            status
            helpText
          }
        }
      }
    }
  }
`;

export const REMOVE_SIGNATURE_PROCESS = gql`
  mutation RemoveSignatureProcess($signatureProcessId: String!) {
    removeSignatureProcess(signatureProcessId: $signatureProcessId)
  }
`;

export const UPDATE_ACCOUNT_STATUS_IN_FORMRESPONSE = gql`
mutation UpdateAccountStatusInFormResponse($formResponseStatusId: String!, $formResponseId: String!) {
  updateAccountStatusInFormResponse(formResponseStatusId: $formResponseStatusId, formResponseId: $formResponseId) {
      id
      formResponseStatus {
        id
        name
      }
      formattedFormResponseId
      lead {
        accountRepresentative {
          firstName
          lastName
          email
        }
        branch {
          id
          name
        }
      }
    }
  }
`;

export const SEND_NEW_REGISTER_NOTIFICATION = gql`
  mutation SendNewRegisterNotification(
    $registerNotification: RegisterNotificationInput!
  ) {
    sendNewRegisterNotification(registerNotification: $registerNotification) {
      status
    }
  }
`;

export const INSERT_INVESTMENT_PROFILE_SECTION = gql`
  mutation InsertInvestmentProfileSectionData(
    $investmentProfileSectionInputType: InvestmentProfileSectionInputType!
  ) {
    insertInvestmentProfileSectionData(
      InvestmentProfileSectionInputType: $investmentProfileSectionInputType
    ) {
      id
      creationDate
      modificationDate
      profileTypeScore
      profileScore
      profileCompletionPercentage
      investmentProfile {
        id
        creationDate
        modificationDate
        name
        description
      }
      investmentProfileType {
        id
        creationDate
        modificationDate
        name
        description
      }
    }
  }
`;

export const GET_KNOW_YOUR_CLIENT_PDF = gql`
  query KnowYourClientDownloadPDF($formResponseId: String!) {
    knowYourClientDownloadPDF(formResponseId: $formResponseId)
  }
`;

export const UPDATE_FORM_RESPONSE_RISK_LEVEL = gql`
  mutation UpdateRiskLevelInFormResponse(
    $riskLevelId: String!
    $formResponseId: String!
  ) {
    updateRiskLevelInFormResponse(
      riskLevelId: $riskLevelId
      formResponseId: $formResponseId
    ) {
      id
      riskLevel {
        id
        creationDate
        modificationDate
        name
        coreParameterCode
        altCoreParameterCode
      }
      formattedFormResponseId
      formResponseStatus {
        id
        name
      }
    }
  }
`;

export const SEND_COMPLETED_FORM_NOTIFICATION = gql`
  mutation SendFormCompletedNotification(
    $formCompletedNotification: FormCompletedNotificationInput!
  ) {
    sendFormCompletedNotification(
      formCompletedNotification: $formCompletedNotification
    ) {
      status
    }
  }
`;

export const SAVE_SETTLEMENT_INSTRUCTION_SECTION = gql`
  mutation SaveSettlementInstructionSection($settlementInstructionSection: SettlementInstructionSectionInputType!) {
    saveSettlementInstructionSection(settlementInstructionSection: $settlementInstructionSection) {
      id
      creationDate
      modificationDate
      settlementInstruction {
        id
        creationDate
        modificationDate
        name
      }
      accountNumber
      accountHolder
    }
  }
`;

export const SAVE_COUNSEL_MEMBERS_SECTION = gql`
  mutation SaveCounselMembersSection($counselMembersSection: CounselMembersSectionInputType!) {
    saveCounselMembersSection(counselMembersSection: $counselMembersSection) {
      id
      creationDate
      modificationDate
      counselMembers {
        id
        creationDate
        modificationDate
        firstName
        lastName
        identificationType {
          id
          creationDate
          modificationDate
          name
          parameterCode
        }
        identification
        position
        politicExposure {
          id
          creationDate
          modificationDate
          isPep
          politicExposureDetails {
            id
            creationDate
            modificationDate
            politicExposureType {
              id
              creationDate
              modificationDate
              name
            }
            charge
            relativeName
            relativeRelationship
            institution
            active
            chargeStartDate
            chargeEndDate
          }
        }
        nationalityCountry {
          id
          creationDate
          modificationDate
          name
          parameterCode
          coreParameterCode
        }
        contactFatca {
          id
          creationDate
          modificationDate
          haveUnitedStatesNationality
          isUnitedStatesCitizen
          isUnitedStatesResident
          socialSecurityNumber
          zipInUnitedStates
          phoneInUnitedStates
          haveRepresentativeInUnitedStates
          haveTaxIdentificationNumber
          taxIdentificationNumber
          greenCard
          addressInUnitedStates
        }
      }
    }
  }
`;

export const REMOVE_COUNSEL_MEMBER = gql`
  mutation RemoveCounselMember($counselMemberId: String!) {
    removeCounselMember(counselMemberId: $counselMemberId)
  }
`;

export const SAVE_CORRESPONDENCE_EMAIL_SECTION = gql`
  mutation SaveCorrespondenceEmailSection($correspondenceEmailSection: CorrespondenceEmailSectionInputType!) {
    saveCorrespondenceEmailSection(correspondenceEmailSection: $correspondenceEmailSection) {
      id
      creationDate
      modificationDate
      correspondenceEmails {
        id
        creationDate
        modificationDate
        email
        correspondenceEmailType {
          id
          creationDate
          modificationDate
          name
          coreParameterCode
        }
      }
    }
  }
`;

export const REMOVE_CORRESPONDENCE_EMAIL = gql`
  mutation RemoveCorrespondenceEmail($correspondenceEmailId: String!) {
    removeCorrespondenceEmail(correspondenceEmailId: $correspondenceEmailId)
  }
`;