import { Close } from '@mui/icons-material';
import './pdfViewer.css';
import { useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';

export default function PDFViewer({ src, close }) {
  const modalRef = useRef(null);

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [close]);

  return (
    <div className="pdf-viewer">
      <div className="document-preview" ref={modalRef}>
        <div className="document-preview-top d-flex align-items-center justify-content-between mb-3">
          <p
            className="p-0 m-0 fw-bold"
            style={{ color: '#4a7068', fontSize: 23 }}
          >
            Vista Previa
          </p>
          <IconButton
            style={{ backgroundColor: '#8bada6' }}
            onClick={() => close()}
          >
            <Close style={{ fontSize: 25, color: '#e7e7e7' }} />
          </IconButton>
        </div>
        <iframe title="PDFViewer" src={src} width="100%" height="700px" />
      </div>
    </div>
  );
}
