import './SupplementaryDataForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import {
  faAngleRight,
  faAngleLeft,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { setSelectedSection } from '../../../../../shared/store/slices/clientePotencial';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress } from '@mui/material';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import CustomCheckbox from '../../../../../shared/UIElements/CustomCheckbox/CustomCheckbox';
import {
  GET_GENERAL_INFORMATION,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_COMMUNICATIONS_SECTION,
  GET_CORRESPONDENCE_CONFIRMATIONS_OPTIONS,
  GET_CORRESPONDENCE_DELIVERY_OPTIONS,
  GET_REFERRAL_LIST,
  GET_LEAD_MANAGEMENT_TYPE_LIST,
  GET_MARKETING_SECTION,
  GET_INVESTMENT_PROFILE_SECTION,
  GET_SOCIAL_NETWORKS_LIST,
  GET_MARKETING_CHANNELS,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GENERATE_INVESTMENT_PROFILE,
  SAVE_COMMUNICATION_SECTION,
  SAVE_GENERAL_INFORMATION,
  SAVE_LEAD,
  SAVE_MARKETING_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { useSelector } from 'react-redux';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';
import { fieldsByComplexSections } from '../../../../../shared/utils/errorsValidation';
import { Error } from '@mui/icons-material';

const SupplementaryDataForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    isContrapart,
    hasBCRDAccount,
    isListedOnStock,
    isFormCompleted,
    isFormDisqualified,
    isForeign,
    validation,
  } = useSelector((state) => state.clientePotencialSlice);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [receiptOfConfirmations, setReceiptOfConfirmations] = useState([]);
  const [followingNetworks, setFollowingNetworks] = useState([]);
  const [myMarketingChannels, setMyMarketingChannels] = useState([]);
  const [formResponseId, setFormResponseId] = useState('');
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION, {
      fetchPolicy: 'network-only',
    });
  const [saveCompanyInformationSection] = useMutation(
    SAVE_GENERAL_INFORMATION,
    {
      refetchQueries: [
        {
          query: GET_GENERAL_INFORMATION,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [saveCommunicationsSection] = useMutation(SAVE_COMMUNICATION_SECTION, {
    refetchQueries: [
      {
        query: GET_COMMUNICATIONS_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [saveMarketingSection] = useMutation(SAVE_MARKETING_SECTION, {
    refetchQueries: [
      {
        query: GET_MARKETING_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [saveLead] = useMutation(SAVE_LEAD);
  const [getGeneralInformationData, generalInformationData] = useLazyQuery(
    GET_GENERAL_INFORMATION
  );
  const [getcommunicationsSectionData, communicationsSectionData] =
    useLazyQuery(GET_COMMUNICATIONS_SECTION);
  const [getMarketingSectionData, marketingSectionData] = useLazyQuery(
    GET_MARKETING_SECTION
  );
  const [generateInvestmentProfile] = useMutation(GENERATE_INVESTMENT_PROFILE, {
    refetchQueries: [
      {
        query: GET_INVESTMENT_PROFILE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const { data: correspondenceConfirmationOptions } = useQuery(
    GET_CORRESPONDENCE_CONFIRMATIONS_OPTIONS
  );
  const { data: referralList } = useQuery(GET_REFERRAL_LIST);
  const { data: socialNetworks } = useQuery(GET_SOCIAL_NETWORKS_LIST);
  const { data: marketingChannels } = useQuery(GET_MARKETING_CHANNELS);
  const { data: leadManagementTypes } = useQuery(GET_LEAD_MANAGEMENT_TYPE_LIST);
  const [getCorrespondenceDeliveryOptions, correspondenceDeliveryOptions] =
    useLazyQuery(GET_CORRESPONDENCE_DELIVERY_OPTIONS);
  const [loadingSection, setLoadingSection] = useState(true);
  const [triedSend, setTriedSend] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const telefono = watch('telefono');
  const telefonoAdicional = watch('telefonoAdicional');
  const sitioWeb = watch('sitioWeb');
  const entregaCorrespondencia = watch('entregaCorrespondencia');
  const parvalMarketing = watch('parvalMarketing');
  const tipoGestion = watch('tipoGestion');
  const referidoPor = watch('referidoPor');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });

      getCorrespondenceDeliveryOptions({
        variables: {
          personTypeId: selectedFormResponse?.form?.personType?.id,
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    setFormResponseId(formResponseByIdentification?.data?.formResponses[0]?.id);
    getGeneralInformationData({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });

    getcommunicationsSectionData({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });

    getMarketingSectionData({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (
      generalInformationData.data &&
      communicationsSectionData.data &&
      formResponseByIdentification.data &&
      marketingSectionData.data
    ) {
      getFormInfo();
    }
  }, [generalInformationData, communicationsSectionData, marketingSectionData]);

  useEffect(() => {
    if (communicationsSectionData?.data?.communicationsSection) {
      if (
        communicationsSectionData?.data?.communicationsSection
          ?.correspondenceConfirmations
      ) {
        const correspondenceConfirmations =
          communicationsSectionData?.data?.communicationsSection
            ?.correspondenceConfirmations;
        const ids = correspondenceConfirmations.map((elem) => elem.id);
        setReceiptOfConfirmations(ids);
      }
    }
  }, [communicationsSectionData]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/empresas-afiliadas'
    );
  };

  const getFormInfo = () => {
    if (generalInformationData?.data?.companyInformationSection) {
      setValue(
        'telefono',
        generalInformationData?.data?.companyInformationSection?.primaryPhone
      );
      setValue(
        'telefonoAdicional',
        generalInformationData?.data?.companyInformationSection?.secondaryPhone
      );
      setValue(
        'sitioWeb',
        generalInformationData?.data?.companyInformationSection?.websiteUrl
      );
    }

    if (communicationsSectionData?.data?.communicationsSection) {
      setValue(
        'entregaCorrespondencia',
        communicationsSectionData?.data?.communicationsSection
          ?.correspondenceDelivery?.id
      );
    }

    if (
      formResponseByIdentification?.data?.formResponses[0]?.lead
        .leadManagementType
    ) {
      setValue(
        'tipoGestion',
        formResponseByIdentification?.data?.formResponses[0]?.lead
          .leadManagementType?.id
      );
    }

    if (marketingSectionData?.data?.marketingSection) {
      setValue(
        'parvalMarketing',
        marketingSectionData?.data?.marketingSection.referral?.id
      );

      const marketings =
        marketingSectionData?.data?.marketingSection?.marketingChannels;
      const marketingsIds = marketings.map((elem) => elem.id);
      setMyMarketingChannels(marketingsIds);

      const socials =
        marketingSectionData?.data?.marketingSection?.socialNetworks;
      const socialsIds = socials.map((elem) => elem.id);
      setFollowingNetworks(socialsIds);

      if (
        marketingSectionData?.data?.marketingSection.referral?.name ===
        'Referido'
      ) {
        setValue(
          'referidoPor',
          marketingSectionData?.data?.marketingSection.referralInfo
        );
      }
    }
    setLoadingSection(false);
  };

  const saveData = async () => {
    const goto = () => {
      if (hasBCRDAccount) {
        if (isContrapart) {
          if (!isListedOnStock) {
            dispatch(setSelectedSection('Información de Cliente Parval'));
            navigate(
              '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
            );
          } else {
            navigate(
              '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/miembros-del-consejo'
            );
          }
        } else {
          dispatch(setSelectedSection('Información de Cliente Parval'));
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/referencias-comerciales'
          );
        }
      } else {
        if(!isContrapart){
          dispatch(setSelectedSection('Información de Cliente Parval'));
          navigate(
            '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/cuentas-bancarias'
          );
        }
        else{
          if (!isListedOnStock) {
            if(!isForeign){
              dispatch(setSelectedSection('Información de Cliente Parval'));
              navigate(
                '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/cuentas-bancarias'
              );
            }
            else{
              dispatch(setSelectedSection('Información de Cliente Parval'));
              navigate(
                '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
              );
            }
          } else {
            if(!isForeign){
              dispatch(setSelectedSection('Información de Cliente Parval'));
              navigate(
                '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/cuentas-bancarias'
              );
            }
            else{
              navigate(
                '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/documentos'
              );
            }
          }
        }
      }
    };

    if (isFormCompleted || isFormDisqualified) {
      goto();
      return;
    }

    setTriedSend(true);

    if (!followingNetworks.length) {
      return;
    }

    if (!myMarketingChannels.length) {
      return;
    }

    if (!receiptOfConfirmations.length) {
      return;
    }

    setTriedSend(false);

    let data = {};
    // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    if (generalInformationData?.data?.companyInformationSection?.id) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        id: generalInformationData.data.companyInformationSection?.id,
        primaryPhone: telefono,
        secondaryPhone: telefonoAdicional,
        websiteUrl: sitioWeb === undefined ? null : sitioWeb,
      };
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        primaryPhone: telefono,
        secondaryPhone: telefonoAdicional,
        websiteUrl: sitioWeb === undefined ? null : sitioWeb,
      };
    }

    saveCompanyInformationSection({
      variables: {
        companyInformationSection: data,
      },
    });

    // Commnications section
    if (communicationsSectionData?.data?.communicationsSection?.id) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        id: communicationsSectionData?.data?.communicationsSection?.id,
        correspondenceDeliveryId: entregaCorrespondencia,
        correspondenceConfirmationsIds: receiptOfConfirmations,
      };
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        correspondenceDeliveryId: entregaCorrespondencia,
        correspondenceConfirmationsIds: receiptOfConfirmations,
      };
    }

    saveCommunicationsSection({
      variables: {
        communicationsSection: data,
      },
    });

    // Lead section
    data = {
      primaryContactEmail: selectedFormResponse?.lead?.primaryContact?.email,
      identification: selectedFormResponse?.lead?.identification,
      identificationTypeId: selectedFormResponse?.lead?.identificationType?.id,
      leadManagementTypeId: tipoGestion,
    };

    saveLead({
      variables: {
        lead: data,
      },
    }).then(() => {
      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    });

    // Marketing section
    if (marketingSectionData?.data?.marketingSection?.id) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        id: marketingSectionData?.data?.marketingSection?.id,
        referralId: parvalMarketing,
        marketingChannelIds: myMarketingChannels,
        socialNetworkIds: followingNetworks,
        referralInfo: referidoPor,
      };
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        referralId: parvalMarketing,
        marketingChannelIds: myMarketingChannels,
        socialNetworkIds: followingNetworks,
        referralInfo: referidoPor,
      };
    }

    saveMarketingSection({
      variables: {
        marketingSection: data,
      },
    });

    generateInvestmentProfile({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });

    goto();
  };

  const AddOrDeleteConfirmations = (optionId) => {
    if (optionId && optionId !== null) {
      if (!receiptOfConfirmations.includes(optionId)) {
        setReceiptOfConfirmations((prevState) => [...prevState, optionId]);
      } else {
        setReceiptOfConfirmations((prevState) =>
          prevState?.filter((id) => id !== optionId)
        );
      }
    }
  };

  const AddOrDeleteNetworks = (optionId) => {
    if (optionId && optionId !== null) {
      if (!followingNetworks.includes(optionId)) {
        setFollowingNetworks((prevState) => [...prevState, optionId]);
      } else {
        setFollowingNetworks((prevState) =>
          prevState?.filter((id) => id !== optionId)
        );
      }
    }
  };

  const AddOrDeleteMerketing = (optionId) => {
    if (optionId && optionId !== null) {
      if (!myMarketingChannels.includes(optionId)) {
        setMyMarketingChannels((prevState) => [...prevState, optionId]);
      } else {
        setMyMarketingChannels((prevState) =>
          prevState?.filter((id) => id !== optionId)
        );
      }
    }
  };

  const isReferido = () => {
    return (
      referralList?.referralList?.find(
        (referral) => referral.id === parvalMarketing
      )?.name === 'Referido'
    );
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key !== 'clientErrors' && key !== 'isValid' && key !== '__typename') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  const isSectionError = () => {
    return fieldsByComplexSections['Descripción del Negocio'].some((flied) =>
      validationErrors?.includes(flied)
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div
          className="row fields-container"
          style={{ height: loadingSection ? '80%' : 'auto' }}
        >
          <h3 className="formTitle">
            Datos Complementarios
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Datos Complementarios'}
              description={
                "El formulario 'Datos Complementarios' proporciona un espacio centralizado para capturar información adicional y de contacto clave asociada a la empresa. En esta sección, se recopilan datos esenciales como números telefónicos, correos electrónicos, el sitio web de la empresa, y preferencias relacionadas con la correspondencia y estados de cuenta. El objetivo de este formulario es brindar una visión rápida y accesible de la información de contacto crucial, así como de las preferencias y detalles adicionales que complementan la identidad operativa de la empresa."
              }
            />
            {isSectionError() && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={'Hay campos incompletos en esta sección'}
                />
              </span>
            )}
          </h3>

          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="row">
              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="telefono" className="label-field">
                  Teléfono <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="telefono"
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('telefono', {
                    required: true,
                  })}
                />
                {errors.telefono?.type === 'required' && (
                  <p className="text-danger">El campo Teléfono es requerido</p>
                )}
                {!telefono && errors.telefono?.type !== 'required' && (
                  <ErrorText hasError={hasError('primaryPhone')} />
                )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="telefono-adicional" className="label-field">
                  Teléfono adicional
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="telefono-adicional"
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('telefonoAdicional')}
                />
              </div>

              <div className="field-form col-md-6 col-sm-12 ps-4">
                <label
                  htmlFor="entrega-correspondencia"
                  className="label-field"
                >
                  Entrega correspondencia y estados de cuenta <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="entrega-correspondencia"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('entregaCorrespondencia', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {correspondenceDeliveryOptions?.data?.correspondenceDeliveryOptions?.map(
                      (option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      )
                    )}
                  </select>
                </div>
                {errors.entregaCorrespondencia && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!entregaCorrespondencia ||
                  entregaCorrespondencia === 'DEFAULT') &&
                  !errors.entregaCorrespondencia && (
                    <ErrorText hasError={hasError('correspondenceDelivery')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="sitio-web" className="label-field">
                  Sitio web
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="sitio-web"
                  disabled={isFormCompleted || isFormDisqualified}
                  {...register('sitioWeb')}
                />
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="parval-marketing" className="label-field">
                  ¿Cómo se enteró de Parval? <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="parval-marketing"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('parvalMarketing', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {referralList?.referralList?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.parvalMarketing && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!parvalMarketing || parvalMarketing === 'DEFAULT') &&
                  !errors.parvalMarketing && (
                    <ErrorText hasError={hasError('referral')} />
                  )}
              </div>

              {isReferido() ? (
                <div className="field-form col-lg-6 col-sm-12 ps-4">
                  <label htmlFor="referido-por" className="label-field">
                    Referido Por <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="referido-por"
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('referidoPor', {
                      required: true,
                    })}
                  />
                  {errors.referidoPor?.type === 'required' && (
                    <p className="text-danger">
                      El campo Referido Por es requerido
                    </p>
                  )}
                  {!referidoPor && errors.referidoPor?.type !== 'required' && (
                    <ErrorText hasError={hasError('referralInfo')} />
                  )}
                </div>
              ) : null}

              <div className="field-form col-lg-6 col-sm-12 ps-4">
                <label htmlFor="tipo-gestion" className="label-field">
                  ¿Cómo deseas completar el proceso de apertura de cuenta?{' '}
                  <span>*</span>
                </label>
                <div className="selectWrapper">
                  <select
                    id="tipo-gestion"
                    defaultValue={'DEFAULT'}
                    disabled={isFormCompleted || isFormDisqualified}
                    {...register('tipoGestion', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    {leadManagementTypes?.leadManagementTypes?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.tipoGestion && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
                {(!tipoGestion || tipoGestion === 'DEFAULT') &&
                  !errors.tipoGestion && (
                    <ErrorText hasError={hasError('leadManagementType')} />
                  )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4 mb-4">
                <label htmlFor="redes-sociales" className="label-field">
                  ¿Por qué medios nos sigue? <span>*</span>
                </label>
                {socialNetworks?.socialNetworks?.map((option) => (
                  <CustomCheckbox
                    key={option.id}
                    index={option.id}
                    isInArray={followingNetworks.includes(option.id)}
                    idElement={option.id}
                    nameElement={option?.name}
                    functionParam={AddOrDeleteNetworks}
                    disabled={isFormCompleted || isFormDisqualified}
                  />
                ))}
                {triedSend && !followingNetworks.length && (
                  <p className="text-danger mt-2">
                    Debe seleccionar por lo menos una opción
                  </p>
                )}
                {!triedSend && !followingNetworks.length && (
                  <div className="mt-5">
                    <ErrorText hasError={hasError('socialNetworks')} />
                  </div>
                )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4 mb-4">
                <label
                  htmlFor="recepcion-confirmaciones"
                  className="label-field"
                >
                  Elije tus métodos preferidos de contacto <span>*</span>
                </label>
                {marketingChannels?.marketingChannels?.map((option) => (
                  <CustomCheckbox
                    key={option.id}
                    index={option.id}
                    isInArray={myMarketingChannels.includes(option.id)}
                    idElement={option.id}
                    nameElement={option?.name}
                    functionParam={AddOrDeleteMerketing}
                    disabled={isFormCompleted || isFormDisqualified}
                  />
                ))}
                {triedSend && !myMarketingChannels.length && (
                  <p className="text-danger mt-2">
                    Debe seleccionar por lo menos una opción
                  </p>
                )}
                {!triedSend && !myMarketingChannels.length && (
                  <div className="mt-5">
                    <ErrorText hasError={hasError('marketingChannels')} />
                  </div>
                )}
              </div>

              <div className="field-form col-lg-6 col-sm-12 ps-4 mb-4">
                <label
                  htmlFor="recepcion-confirmaciones"
                  className="label-field"
                >
                  Recepción de confirmaciones y envío de instrucciones{' '}
                  <span>*</span>
                </label>
                {correspondenceConfirmationOptions?.correspondenceConfirmationOptions?.map(
                  (option) => (
                    <CustomCheckbox
                      key={option.id}
                      index={option.id}
                      isInArray={receiptOfConfirmations.includes(option.id)}
                      idElement={option.id}
                      nameElement={option?.name}
                      functionParam={AddOrDeleteConfirmations}
                      disabled={isFormCompleted || isFormDisqualified}
                    />
                  )
                )}
                {triedSend && !receiptOfConfirmations.length && (
                  <p className="text-danger mt-2">
                    Debe seleccionar por lo menos una opción
                  </p>
                )}
                {!triedSend && !receiptOfConfirmations.length && (
                  <div className="mt-5">
                    <ErrorText
                      hasError={hasError('correspondenceConfirmations')}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            disabled={loadingSection}
            className={loadingSection ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default SupplementaryDataForm;
