import './FinalBeneficiaryForm.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import {
  GET_COUNTRIES,
  GET_IDENTITYTYPES,
} from '../../../../../shared/graphQL/queries';

const FinalBeneficiaryForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [
    parvalMarketingSocialMediaOptions,
    setParvalMarketingSocialMediaOptions,
  ] = useState([]);
  const [preferredContacMethods, setPreferredContacMethods] = useState([]);
  const [marketingAboutParvalOptions, setMarketingAboutParvalOptions] =
    useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const { data: countries } = useQuery(GET_COUNTRIES);
  const { data: identificationTypeList } = useQuery(GET_IDENTITYTYPES);

  const navigate = useNavigate();
  const formRef = useRef();

  const esUstedBeneficiarioFinal = watch('esUstedBeneficiarioFinal');

  useEffect(() => {
    getFormInfo();
  }, []);

  const handleBackNavigation = () => {
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos");
  };

  const getFormInfo = () => {};

  const saveData = () => {
    // let data = {};
  };

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="form-fileds-basic"
      ref={formRef}
    >
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle text-center">Beneficiario Final</h3>

          <div className="field-form">
            <label htmlFor="esUstedBeneficiarioFinal" className="label-field">
              ¿Es Ud. el Beneficiario FINAL?
            </label>
            <select
              id="esUstedBeneficiarioFinal"
              defaultValue={'DEFAULT'}
              {...register('esUstedBeneficiarioFinal', {
                // validate: validateNonSelectedOptionInDropdown
              })}
            >
              <option value="DEFAULT" disabled>
                Selecciona
              </option>
              <option value="Si">Si</option>
              <option value="No">No</option>
            </select>
            {/* {errors.esUstedBeneficiarioFinal && <p className='text-danger'>Debe seleccionar una opción</p>} */}
          </div>

          {esUstedBeneficiarioFinal === 'No' ? (
            <>
              <div className="field-form">
                <label htmlFor="nombresApellidos" className="label-field">
                  Nombre(s) y Apellido(s) <span>*</span>
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="nombresApellidos"
                  {...register('nombresApellidos', {
                    required: true,
                  })}
                />
                {errors.nombresApellidos?.type === 'required' && (
                  <p className="text-danger">
                    El campo Nombre(s) y Apellido(s) es requerido
                  </p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="tipo-documento" className="label-field">
                  Tipo de documento <span>*</span>
                </label>
                <select
                  id="tipo-documento"
                  defaultValue={'DEFAULT'}
                  {...register('tipoDocumento', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {identificationTypeList?.identificationTypeList.map(
                    (identificationType) => {
                      if (
                        identificationType.name !== 'RNC' &&
                        identificationType.name !== 'Numero Unico de Identidad'
                      ) {
                        return (
                          <option
                            key={identificationType.id}
                            value={identificationType.id}
                          >
                            {identificationType.name}
                          </option>
                        );
                      }
                    }
                  )}
                </select>
                {errors.tipoDocumento && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="numero-doc" className="label-field">
                  Número de identificación
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="numero-doc"
                  {...register('numeroDoc', {
                    // required: true
                  })}
                />
                {/* {errors.numeroDoc?.type === 'required' && <p className='text-danger'>El campo Número de documento es requerido</p>} */}
              </div>
            </>
          ) : null}
        </div>
        <div className="col-md-5 col-sm-12">
          <h3 className="formTitle" style={{ visibility: 'hidden' }}>
            Non visible
          </h3>

          {esUstedBeneficiarioFinal === 'No' ? (
            <>
              <div className="field-form">
                <label htmlFor="telefono" className="label-field">
                  Teléfono
                </label>
                <input
                  type="number"
                  className="input-form"
                  placeholder=""
                  id="telefono"
                  {...register('numeroDoc', {
                    // required: true
                  })}
                />
                {/* {errors.telefono?.type === 'required' && <p className='text-danger'>El campo Número de documento es requerido</p>} */}
              </div>

              <div className="field-form">
                <label htmlFor="nacionalidad" className="label-field">
                  Nacionalidad{' '}
                </label>
                <select
                  id="nacionalidad"
                  defaultValue={'DEFAULT'}
                  {...register('nacionalidad')}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {countries?.countries.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                {/* {errors.nacionalidad && <p className='text-danger'>Debe seleccionar una opción</p>} */}
              </div>

              <div className="field-form">
                <label htmlFor="ocupacion" className="label-field">
                  Ocupación{' '}
                </label>
                <select
                  id="ocupacion"
                  defaultValue={'DEFAULT'}
                  {...register('ocupacion', {
                    // validate: validateNonSelectedOptionInDropdown
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  {/* {ocupations.map(ocupation => <option key={ocupation.id} value={ocupation.id}>{ocupation.name}</option>)} */}
                </select>
                {errors.ocupacion && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>

              <div className="field-form">
                <label htmlFor="direccion" className="label-field">
                  Dirección{' '}
                </label>
                <input
                  type="text"
                  className="input-form"
                  placeholder=""
                  id="direccion"
                  {...register('direccion')}
                />
                {/* {errors.direccion?.type === 'required' && <p className='text-danger'>El campo Dirección es requerido</p>} */}
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div className="buttons-control-container">
        <button
          type="button"
          className="btn-back"
          onClick={handleBackNavigation}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          &#160; Atrás
        </button>

        <button
          type="submit"
          disabled={disabledButton}
          className={disabledButton ? 'button-disabled' : 'btn-continue'}
        >
          Siguiente &#160;
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </form>
  );
};

export default FinalBeneficiaryForm;
