import './ActivityBar.css';
import { useSubscription } from '@apollo/client';
import { GET_NOTE_CREATED } from '../../../shared/graphQL/subscriptions';
import { useNotification } from '../../../context/NotificationContext';
import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_NOTES_By_ENTITYID } from '../../../shared/graphQL/queries';
import { formatFullDate } from '../../../shared/utils/functions';
import { GET_FORM_RESPONSES_BY_PRIMARY_CONTACT } from '../../../shared/graphQL/queries';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import { TimelineOppositeContent } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const ActivityBar = () => {
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const { notifyInfo } = useNotification();
  const [formResponses, setFormResponses] = useState([]);
  const [getFormResponsesByPrimaryContact, formResponsesByPrimaryContact] =
    useLazyQuery(GET_FORM_RESPONSES_BY_PRIMARY_CONTACT);
  const [notes, setNotes] = useState([]);
  const [
    getNotesByIdentification,
    { data: notesByIdentification, loading: loadingNotesByIdentification },
  ] = useLazyQuery(GET_NOTES_By_ENTITYID);
  const [
    getNotesByFormResponseLead,
    { data: notesByFormResponseLead, loading: loadingNotesByFormresponseLead },
  ] = useLazyQuery(GET_NOTES_By_ENTITYID);
  const [
    getNotesByPrimaryContact,
    { data: notesByPrimaryContact, loading: loadingNotesByPrimaryContact },
  ] = useLazyQuery(GET_NOTES_By_ENTITYID);

  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    getNotesByIdentification({
      variables: {
        entityId: selectedFormResponse?.lead?.identification,
      },
    });
    getNotesByPrimaryContact({
      variables: {
        entityId: selectedFormResponse?.lead?.primaryContact?.email,
      },
    });
    getFormResponsesByPrimaryContact({
      variables: {
        primaryContactEmail: selectedFormResponse?.primaryContact?.email,
      },
    });
  }, [selectedFormResponse]);

  useEffect(() => {
    if (
      formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact &&
      formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
        ?.length > 0
    ) {
      setFormResponses(
        formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
      );
    }
  }, [formResponsesByPrimaryContact]);

  useEffect(() => {
    for (let i = 0; i < formResponses.length; i++) {
      getNotesByFormResponseLead({
        variables: {
          entityId: formResponses[i].lead.identification,
        },
      });
    }
  }, [formResponses]);

  useEffect(() => {
    let allNotes = [];
    if (notesByIdentification?.notes) {
      allNotes = [...allNotes, ...notesByIdentification.notes];
    }
    if (notesByFormResponseLead?.notes) {
      allNotes = [...allNotes, ...notesByFormResponseLead.notes];
    }
    if (notesByPrimaryContact?.notes) {
      allNotes = [...allNotes, ...notesByPrimaryContact.notes];
    }
    const sortedNotes = allNotes.sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );
    setNotes(sortedNotes);
  }, [notesByIdentification, notesByFormResponseLead, notesByPrimaryContact]);

  const { data, loading } = useSubscription(GET_NOTE_CREATED, {
    variables: { entityId: formResponses[0]?.lead?.primaryContact?.email },
  });

  useEffect(() => {
    if (!loading && data && data.noteCreated) {
      const newNote = data.noteCreated;
      notifyInfo(
        `Tienes una nueva nota de parte de ${
          newNote.author.firstName + ' ' + newNote.author.lastName
        }`
      );
      setNotes([newNote, ...notes]);
    }
  }, [data, loading]);

  return (
    <div className="activity-bar">
      <div className="activity-bar-title">
        <p>Actividades</p>
      </div>

      {notes.length > 0 &&
      !loadingNotesByFormresponseLead &&
      !loadingNotesByIdentification &&
      !loadingNotesByPrimaryContact ? (
        <div className="activity-bar-timeline">
          <Timeline align="left">
            {notes.map((note, index) => (
              <TimelineItem key={index} s>
                <TimelineSeparator>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineOppositeContent
                  style={{ flex: 0 }}
                ></TimelineOppositeContent>
                <TimelineContent style={{ flex: 1 }}>
                  <Paper elevation={1}>
                    <Typography>
                      <h3>
                        {note.author.firstName + ' ' + note.author.lastName}
                      </h3>
                      <p>{note.messageType.name}</p>
                    </Typography>
                    <Typography>
                      <b>{note.title}</b>
                    </Typography>
                    <Typography className="message-container" component="p">
                      {note.text}
                    </Typography>
                    <Typography component="p">
                      {formatFullDate(new Date(note.creationDate))}
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </div>
      ) : (
        <h3>No hay Actividades</h3>
      )}
    </div>
  );
};

export default ActivityBar;
