import './InvestorProfileForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';
import ModalInvestorProfile from '../../../../../shared/UIElements/ModalInvestorProfile/ModalInvestorProfile';

const InvestorProfileForm = ({ validateForm, setValidateForm, goToBack }) => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const accountType = localStorage.getItem('accountTypeToStartRegister');
  const [sourcesOfFunds, setSourcesOfFunds] = useState([]);
  const [titleOperations, setTitleOperations] = useState([]);
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);

  const ingresosAnuales = watch('ingresosAnuales');
  const totalActivosLiquidosAnioCurso = watch('totalActivosLiquidosAnioCurso');
  const totalActivosLiquidosAnioAnterior = watch(
    'totalActivosLiquidosAnioAnterior'
  );
  const cuentasPorPagar = watch('cuentasPorPagar');
  const gastosAnuales = watch('gastosAnuales');
  const destinoIntereses = watch('destinoIntereses');
  const nivelExperienciaInversionista = watch('nivelExperienciaInversionista');
  const patrimonioTotalAnioCurso = watch('patrimonioTotalAnioCurso');
  const patrimonioTotalAnioAnterior = watch('patrimonioTotalAnioAnterior');
  const horizonteInversion = watch('horizonteInversion');
  const mantieneFondoEmergencia = watch('mantieneFondoEmergencia');
  const capacidadAbsorberPerdidasCapital = watch(
    'capacidadAbsorberPerdidasCapital'
  );
  const objetivosToleranciaRiesgo = watch('objetivosToleranciaRiesgo');
  const origenFondos = watch('origenFondos');
  const operacionesTitulos = watch('operacionesTitulos');
  const montoInicialInversion = watch('montoInicialInversion');
  const conformeConPerfilAsignado = watch('conformeConPerfilAsignado');
  const consideraPerfilCorrecto = watch('consideraPerfilCorrecto');

  useEffect(() => {
    getFormInfo();
  }, []);

  useEffect(() => {
    const identityDocumentNumber = localStorage.getItem(
      'identityDocumentNumber'
    );
    setValue('rnc', identityDocumentNumber);
  }, []);

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/persona-juridica/marketing-sobre-parval');
    }
  }, [goToBack]);

  const getFormInfo = () => {};

  const saveData = () => {};

  const addRemoveSourcesOfFunds = (option) => {
    if (sourcesOfFunds.includes(option)) {
      const index = sourcesOfFunds.indexOf(option);
      sourcesOfFunds.splice(index, 1);
      setSourcesOfFunds([...sourcesOfFunds]);
      //console.log(sourcesOfFunds);
    } else {
      setSourcesOfFunds((prevState) => [...prevState, option]);
      //console.log(sourcesOfFunds);
    }
  };

  const addRemoveTitleOperations = (option) => {
    if (titleOperations.includes(option)) {
      const index = titleOperations.indexOf(option);
      titleOperations.splice(index, 1);
      setTitleOperations([...titleOperations]);
      //console.log(titleOperations);
    } else {
      setTitleOperations((prevState) => [...prevState, option]);
      //console.log(sourcesOfFunds);
    }
  };

  return (
    <>
      {showSpinner ? <Spinner /> : null}

      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        <div className="row fields-container">
          <div className="col-md-6 col-sm-12 pe-4 columns">
            <div className="field-form">
              <label htmlFor="ingresos-anuales" className="label-field">
                Ingresos anuales *
                <ModalInvestorProfile
                  title={'Ingresos anuales *'}
                  message={'En RD$ ó su equivalente en US$'}
                />
              </label>
              <select
                id="ingresos-anuales"
                defaultValue={'DEFAULT'}
                {...register('ingresosAnuales', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a RD$ 5,000,000.00 ">
                  Menor a RD$ 5,000,000.00
                </option>
                <option value="De RD$ 5,000,001.00 a RD$ 20,000,000.00">
                  De RD$ 5,000,001.00 a RD$ 20,000,000.00
                </option>
                <option value="De RD$ 20,000,001.00 a RD$ 50,000,000.00">
                  De RD$ 20,000,001.00 a RD$ 50,000,000.00
                </option>
                <option value="Mayor a RD$ 50,000,000.00">
                  Mayor a RD$ 50,000,000.00
                </option>
              </select>
              {errors.ingresosAnuales && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="total-activos-liquidos-anio-curso"
                className="label-field"
              >
                Total activos liquidos (año en curso) *
                <ModalInvestorProfile
                  title={'Total activos liquidos (año en curso) *'}
                  message={
                    'En RD$ ó su equivalente en US$ (Incluye Cuentas Bancarias y Certificados Financieros)'
                  }
                />
              </label>
              <select
                id="total-activos-liquidos-anio-curso"
                defaultValue={'DEFAULT'}
                {...register('totalActivosLiquidosAnioCurso', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a RD$ 3,000,000.00">
                  Menor a RD$ 3,000,000.00{' '}
                </option>
                <option value="De RD$ 3,000,001.00 a RD$ 10,000,000.00">
                  De RD$ 3,000,001.00 a RD$ 10,000,000.00
                </option>
                <option value="De RD$ 10,000,001.00 a RD$ 30,000,000.00">
                  De RD$ 10,000,001.00 a RD$ 30,000,000.00
                </option>
                <option value="De RD$ 30,000,001.00 a RD$ 50,000,000.00">
                  De RD$ 30,000,001.00 a RD$ 50,000,000.00
                </option>
                <option value="De RD$ 3,000,001.00 a RD$ 10,000,000.00">
                  Mayor a RD$ 50,000,000.00
                </option>
              </select>
              {errors.totalActivosLiquidosAnioCurso && (
                <p className="text-danger">
                  El campo Total activos liquidos (año en curso) es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="total-activos-liquidos-anio-anterior"
                className="label-field"
              >
                Total activos liquidos (año anterior) *
                <ModalInvestorProfile
                  title={'Total activos liquidos (año anterior) *'}
                  message={
                    'En RD$ ó su equivalente en US$ (Incluye Cuentas Bancarias y Certificados Financieros)'
                  }
                />
              </label>
              <select
                id="total-activos-liquidos-anio-anterior"
                defaultValue={'DEFAULT'}
                {...register('totalActivosLiquidosAnioAnterior', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a RD$ 3,000,000.00">
                  Menor a RD$ 3,000,000.00{' '}
                </option>
                <option value="De RD$ 3,000,001.00 a RD$ 10,000,000.00">
                  De RD$ 3,000,001.00 a RD$ 10,000,000.00
                </option>
                <option value="De RD$ 10,000,001.00 a RD$ 30,000,000.00">
                  De RD$ 10,000,001.00 a RD$ 30,000,000.00
                </option>
                <option value="De RD$ 30,000,001.00 a RD$ 50,000,000.00">
                  De RD$ 30,000,001.00 a RD$ 50,000,000.00
                </option>
                <option value="De RD$ 3,000,001.00 a RD$ 10,000,000.00">
                  Mayor a RD$ 50,000,000.00
                </option>
              </select>
              {errors.totalActivosLiquidosAnioAnterior && (
                <p className="text-danger">
                  El campo Total activos liquidos (año en anterior) es requerido
                </p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="cuentas-por-pagar" className="label-field">
                Cuentas por pagar *
                <ModalInvestorProfile
                  title={'Cuentas por pagar *'}
                  message={'En RD$ ó su equivalente en US$'}
                />
              </label>
              <select
                id="cuentas-por-pagar"
                defaultValue={'DEFAULT'}
                {...register('cuentasPorPagar', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a RD$ 5,000,000.00">
                  Menor a RD$ 5,000,000.00
                </option>
                <option value="De RD$ 5,000,001.00 a RD$ 20,000,000.00">
                  De RD$ 5,000,001.00 a RD$ 20,000,000.00
                </option>
                <option value="Mayor a RD$ 20,000,000.00">
                  Mayor a RD$ 20,000,000.00
                </option>
              </select>
              {errors.cuentasPorPagar && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="gastos-anuales" className="label-field">
                Gastos Anuales *
                <ModalInvestorProfile
                  title={'Gastos Anuales *'}
                  message={'En RD$ ó su equivalente en US$'}
                />
              </label>
              <select
                id="gastos-anuales"
                defaultValue={'DEFAULT'}
                {...register('gastosAnuales', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a RD$ 5,000,000.00">
                  Menor a RD$ 5,000,000.00
                </option>
                <option value="De RD$ 5,000,001.00 a RD$ 15,000,000.00">
                  De RD$ 5,000,001.00 a RD$ 15,000,000.00
                </option>
                <option value="Mayor a RD$ 15,000,000.00">
                  Mayor a RD$ 15,000,000.00
                </option>
              </select>
              {errors.gastosAnuales && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="destino-intereses" className="label-field">
                Destino de los intereses <span>*</span>
              </label>
              <select
                id="destino-intereses"
                defaultValue={'DEFAULT'}
                {...register('destinoIntereses', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Utilizar los intereses periódicamente">
                  Utilizar los intereses periódicamente
                </option>
                <option value="Utilizar una parte y reinvertir el resto">
                  Utilizar una parte y reinvertir el resto
                </option>
                <option value="Reinvertir todo">Reinvertir todo</option>
              </select>
              {errors.destinoIntereses && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="objetivos-tolerancia-riesgo"
                className="label-field mt-3 mb-4"
              >
                Objetivos / Tolerancia al riesgo ¿Cuál de estos criterios se
                ajusta más a sus ideas e rentabilidad / riesgo? *
              </label>
              <div id="objetivos-tolerancia-riesgo">
                <div>
                  <input
                    type="radio"
                    id="objetivo1"
                    name="objetivo1"
                    value="Conservar su capital con la menor volatilidad y riesgo posibles."
                    className="radio-options me-2"
                    {...register('objetivosToleranciaRiesgo', {
                      required: true,
                    })}
                  />
                  <label htmlFor="objetivo1">
                    Conservar su capital con la menor volatilidad y riesgo
                    posibles.
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="objetivo2"
                    name="objetivo2"
                    value="Crecimiento sostenido en su patrimonio. Acepta riesgo moderado por un mayor rendimiento"
                    className="me-2"
                    {...register('objetivosToleranciaRiesgo', {
                      required: true,
                    })}
                  />
                  <label htmlFor="objetivo2">
                    Crecimiento sostenido en su patrimonio. Acepta riesgo
                    moderado por un mayor rendimiento
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="objetivo3"
                    name="objetivo3"
                    value="Rendimiento a largo plazo. Combinación balanceada de instrumentos tomando mayor riesgo"
                    className="me-2"
                    {...register('objetivosToleranciaRiesgo', {
                      required: true,
                    })}
                  />
                  <label htmlFor="objetivo3">
                    Rendimiento a largo plazo. Combinación balanceada de
                    instrumentos tomando mayor riesgo
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="objetivo4"
                    name="objetivo4"
                    value="Formar un patrimonio a largo plazo con una selección agresiva de instrumentos financieros"
                    className="me-2"
                    {...register('objetivosToleranciaRiesgo', {
                      required: true,
                    })}
                  />
                  <label htmlFor="objetivo4">
                    Formar un patrimonio a largo plazo con una selección
                    agresiva de instrumentos financieros
                  </label>
                </div>
                {errors.objetivosToleranciaRiesgo?.type === 'required' && (
                  <p className="text-danger mt-2">Debe elegir una opción.</p>
                )}
              </div>
            </div>

            <div className="field-form">
              <label htmlFor="origen-fondos" className="label-field mt-4 mb-3">
                Origen de los fondos *
              </label>
              <div className="checkbox-options-container" id="origen-fondos">
                <label htmlFor="capital-trabajo" className="label-field">
                  <input
                    type="checkbox"
                    id="capital-trabajo"
                    value="Capital de trabajo"
                    onClick={() =>
                      addRemoveSourcesOfFunds('Capital de trabajo')
                    }
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Capital de trabajo
                </label>

                <label htmlFor="inversiones-anteriores" className="label-field">
                  <input
                    type="checkbox"
                    id="inversiones-anteriores"
                    value="Inversiones anteriores"
                    onClick={() =>
                      addRemoveSourcesOfFunds('Inversiones anteriores')
                    }
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Inversiones anteriores
                </label>

                <label htmlFor="venta-activos" className="label-field">
                  <input
                    type="checkbox"
                    id="venta-activos"
                    value="Venta de activos"
                    onClick={() => addRemoveSourcesOfFunds('Venta de activos')}
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Venta de activos
                </label>

                <label htmlFor="operaciones-tesorería" className="label-field">
                  <input
                    type="checkbox"
                    id="operaciones-tesorería"
                    value="Operaciones de tesorería"
                    onClick={() =>
                      addRemoveSourcesOfFunds('Operaciones de tesorería')
                    }
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Operaciones de tesorería
                </label>

                <label htmlFor="vencimientos-inversion" className="label-field">
                  <input
                    type="checkbox"
                    id="vencimientos-inversion"
                    value="Vencimientos de inversión"
                    onClick={() =>
                      addRemoveSourcesOfFunds('Vencimientos de inversión')
                    }
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Vencimientos de inversión
                </label>

                <label
                  htmlFor="actividades-propias-del-negocio"
                  className="label-field"
                >
                  <input
                    type="checkbox"
                    id="actividades-propias-del-negocio"
                    value="Actividades propias del negocio"
                    onClick={() =>
                      addRemoveSourcesOfFunds('Actividades propias del negocio')
                    }
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Actividades propias del negocio
                </label>

                <label
                  htmlFor="ingresos-extraordinarios"
                  className="label-field"
                >
                  <input
                    type="checkbox"
                    id="ingresos-extraordinarios"
                    value="Ingresos extraordinarios"
                    onClick={() =>
                      addRemoveSourcesOfFunds('Ingresos extraordinarios')
                    }
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Ingresos extraordinarios
                </label>

                <label htmlFor="otros" className="label-field">
                  <input
                    type="checkbox"
                    id="otros"
                    value="Otros"
                    onClick={() => addRemoveSourcesOfFunds('Otros')}
                    {...register('origenFondos', {
                      required: true,
                    })}
                  />{' '}
                  Otros
                </label>
              </div>
              {errors.origenFondos?.type === 'required' && (
                <p className="text-danger mt-1">
                  Debe seleccionar por lo menos una opción
                </p>
              )}
            </div>
          </div>

          <div className="col-md-6 col-sm-12 ps-4 columns">
            <div className="field-form">
              <label
                htmlFor="nivel-experiencia-inversionista"
                className="label-field"
              >
                Nivel de experiencia y conocimiento como inversionista{' '}
                <span>*</span>
              </label>
              <select
                id="nivel-experiencia-inversionista"
                defaultValue={'DEFAULT'}
                {...register('nivelExperienciaInversionista', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Ninguna">Ninguna</option>
                <option value="Limitada">Limitada</option>
                <option value="Buena">Buena</option>
                <option value="Excelente">Excelente</option>
              </select>
              {errors.nivelExperienciaInversionista && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="patrimonio-total-anio-curso"
                className="label-field"
              >
                Patrimonio total (año en curso) *
                <ModalInvestorProfile
                  title={'Patrimonio total (año en curso) *'}
                  message={
                    'En RD$ ó su equivalente en US$ (Incluye Propiedades, Muebles e Inmuebles)'
                  }
                />
              </label>
              <select
                id="patrimonio-total-anio-curso"
                defaultValue={'DEFAULT'}
                {...register('patrimonioTotalAnioCurso', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a RD$ 5,000,000.00">
                  Menor a RD$ 5,000,000.00
                </option>
                <option value="De RD$ 5,000,001.00 a RD$ 20,000,000.00">
                  De RD$ 5,000,001.00 a RD$ 20,000,000.00
                </option>
                <option value="De RD$ 20,000,001.00 a RD$ 50,000,000.00">
                  De RD$ 20,000,001.00 a RD$ 50,000,000.00
                </option>
                <option value="Mayor a RD$ 50,000,000.00">
                  Mayor a RD$ 50,000,000.00
                </option>
              </select>
              {errors.patrimonioTotalAnioCurso && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="patrimonio-total-anio-anterior"
                className="label-field"
              >
                Patrimonio total (año anterior) *
                <ModalInvestorProfile
                  title={'Patrimonio total (año anterior) *'}
                  message={
                    'En RD$ ó su equivalente en US$ (Incluye Propiedades, Muebles e Inmuebles)'
                  }
                />
              </label>
              <select
                id="patrimonio-total-anio-anterior"
                defaultValue={'DEFAULT'}
                {...register('patrimonioTotalAnioAnterior', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a RD$ 5,000,000.00">
                  Menor a RD$ 5,000,000.00
                </option>
                <option value="De RD$ 5,000,001.00 a RD$ 20,000,000.00">
                  De RD$ 5,000,001.00 a RD$ 20,000,000.00
                </option>
                <option value="De RD$ 20,000,001.00 a RD$ 50,000,000.00">
                  De RD$ 20,000,001.00 a RD$ 50,000,000.00
                </option>
                <option value="Mayor a RD$ 50,000,000.00">
                  Mayor a RD$ 50,000,000.00
                </option>
              </select>
              {errors.patrimonioTotalAnioAnterior && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label htmlFor="horizonte-inversion" className="label-field">
                Horizonte de inversión <span>*</span>
              </label>
              <select
                id="horizonte-inversion"
                defaultValue={'DEFAULT'}
                {...register('horizonteInversion', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Menor a un año">Menor a un año</option>
                <option value="De uno a tres años">De uno a tres años</option>
                <option value="Mayor a tres años">Mayor a tres años</option>
              </select>
              {errors.horizonteInversion && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="mantiene-fondo-emergencia"
                className="label-field"
              >
                ¿Mantiene un fondo de emergencia para cubrir gastos imprevistos?{' '}
                <span>*</span>
              </label>
              <select
                id="mantiene-fondo-emergencia"
                defaultValue={'DEFAULT'}
                {...register('mantieneFondoEmergencia', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="Sí, mayor a seis meses de los gastos.">
                  Sí, mayor a seis meses de los gastos.
                </option>
                <option value="Sí, máximo seis meses de los gastos.">
                  Sí, máximo seis meses de los gastos.
                </option>
                <option value="No, no cubriría los gastos del próximo mes.">
                  No, no cubriría los gastos del próximo mes.
                </option>
              </select>
              {errors.mantieneFondoEmergencia && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="capacidad-absorber-perdidas-capital"
                className="label-field"
              >
                Capacidad de absorber pérdidas de capital *
                <ModalInvestorProfile
                  title={'Capacidad de absorber pérdidas de capital *'}
                  message={'A Mayor Rentabilidad, Mayor Riesgo'}
                />
              </label>
              <select
                id="capacidad-absorber-perdidas-capital"
                defaultValue={'DEFAULT'}
                {...register('capacidadAbsorberPerdidasCapital', {
                  validate: validateNonSelectedOptionInDropdown,
                })}
              >
                <option value="DEFAULT" disabled>
                  Selecciona
                </option>
                <option value="5%">5%</option>
                <option value="10%">10%</option>
                <option value="15%">15%</option>
                <option value="20%">20%</option>
                <option value="30%">30%</option>
                <option value="Más del 30%">Más del 30%</option>
              </select>
              {errors.capacidadAbsorberPerdidasCapital && (
                <p className="text-danger">Debe seleccionar una opción</p>
              )}
            </div>

            <div className="field-form">
              <label
                htmlFor="operaciones-titulos"
                className="label-field mt-2 mb-3"
              >
                Operaciones de títulos valores que comprende
              </label>
              <div
                className="checkbox-options-container"
                id="operaciones-titulos"
              >
                <label
                  htmlFor="certificados-inversion"
                  className="label-field me-1"
                >
                  <input
                    type="checkbox"
                    id="certificados-inversion"
                    value="Certificados de inversión"
                    onClick={() =>
                      addRemoveTitleOperations('Certificados de inversión')
                    }
                    {...register('operacionesTitulos')}
                  />{' '}
                  Certificados de inversión
                </label>

                <label htmlFor="bonos" className="label-field me-1">
                  <input
                    type="checkbox"
                    id="bonos"
                    value="Bonos (Gobierno Central/Corporativos)"
                    onClick={() =>
                      addRemoveTitleOperations(
                        'Bonos (Gobierno Central/Corporativos)'
                      )
                    }
                    {...register('operacionesTitulos')}
                  />{' '}
                  Bonos (Gobierno Central/Corporativos)
                </label>

                <label htmlFor="fondos-mutuos" className="label-field me-1">
                  <input
                    type="checkbox"
                    id="fondos-mutuos"
                    value="Fondos mutuos"
                    onClick={() => addRemoveTitleOperations('Fondos mutuos')}
                    {...register('operacionesTitulos')}
                  />{' '}
                  Fondos mutuos
                </label>

                <label htmlFor="fondos-cerrados" className="label-field me-1">
                  <input
                    type="checkbox"
                    id="fondos-cerrados"
                    value="Fondos cerrados"
                    onClick={() => addRemoveTitleOperations('Fondos cerrados')}
                    {...register('operacionesTitulos')}
                  />{' '}
                  Fondos cerrados
                </label>

                <label
                  htmlFor="papeles-comerciales"
                  className="label-field me-1"
                >
                  <input
                    type="checkbox"
                    id="papeles-comerciales"
                    value="Papeles comerciales"
                    onClick={() =>
                      addRemoveTitleOperations('Papeles comerciales')
                    }
                    {...register('operacionesTitulos')}
                  />{' '}
                  Papeles comerciales
                </label>

                <label
                  htmlFor="prestamos-de-margen"
                  className="label-field me-1"
                >
                  <input
                    type="checkbox"
                    id="prestamos-de-margen"
                    value="Préstamos de margen"
                    onClick={() =>
                      addRemoveTitleOperations('Préstamos de margen')
                    }
                    {...register('operacionesTitulos')}
                  />{' '}
                  Préstamos de margen
                </label>

                <label
                  htmlFor="prestamos-de-titulos"
                  className="label-field me-1"
                >
                  <input
                    type="checkbox"
                    id="prestamos-de-titulos"
                    value="Préstamos de títulos valores (mutuo)"
                    onClick={() =>
                      addRemoveTitleOperations(
                        'Préstamos de títulos valores (mutuo)'
                      )
                    }
                    {...register('operacionesTitulos')}
                  />{' '}
                  Préstamos de títulos valores (mutuo)
                </label>

                <label htmlFor="acciones" className="label-field me-1">
                  <input
                    type="checkbox"
                    id="acciones"
                    value="Acciones"
                    onClick={() => addRemoveTitleOperations('Acciones')}
                    {...register('operacionesTitulos')}
                  />{' '}
                  Acciones
                </label>

                <label
                  htmlFor="operaciones-a-plazo"
                  className="label-field me-1"
                >
                  <input
                    type="checkbox"
                    id="operaciones-a-plazo"
                    value="Operaciones a plazo (forwards)"
                    onClick={() =>
                      addRemoveTitleOperations('Operaciones a plazo (forwards)')
                    }
                    {...register('operacionesTitulos')}
                  />{' '}
                  Operaciones a plazo (forwards)
                </label>
              </div>

              <div className="field-form">
                <label
                  htmlFor="monto-inicial-inversion"
                  className="label-field mt-3"
                >
                  Monto inicial de inversión *
                  <ModalInvestorProfile
                    title={'Ingresos anuales *'}
                    message={'En RD$ ó su equivalente en US$'}
                  />
                </label>
                <select
                  id="monto-inicial-inversion"
                  defaultValue={'DEFAULT'}
                  {...register('montoInicialInversion', {
                    validate: validateNonSelectedOptionInDropdown,
                  })}
                >
                  <option value="DEFAULT" disabled>
                    Selecciona
                  </option>
                  <option value="Hasta RD$1,000,000.00">
                    Hasta RD$1,000,000.00
                  </option>
                  <option value="De RD$1,000,001.00 a RD$8,000,000.00">
                    De RD$1,000,001.00 a RD$8,000,000.00
                  </option>
                  <option value="De RD$8,000,001.00 a RD$25,000,000.00">
                    De RD$8,000,001.00 a RD$25,000,000.00
                  </option>
                  <option value="Mas de RD$25,000,001.00">
                    Mas de RD$25,000,001.00
                  </option>
                </select>
                {errors.montoInicialInversion && (
                  <p className="text-danger">Debe seleccionar una opción</p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="row">
                <div className='assigned-investor-profile-container'>
                <p className='aip-profile-text'>Perfil del inversionista asignado</p>
                    <p className='aip-profile-number'>13</p>
                    <div className='assigned-investor-profile-info'>
                        <div className='aip-profile1'>
                            <div className='aip-profile1-number'>13-33</div>
                            <p>Conservador</p> 
                        </div>
                        <div className='aip-profile2'>
                            <div className='aip-profile2-number'>34-52</div>
                            <p>Moderado</p> 
                        </div>
                        <div className='aip-profile3'>
                            <div className='aip-profile3-number'>53-65</div>
                            <p>Agresivo</p> 
                        </div>
                    </div>
                </div>
            </div> */}

        {/* <div className="row fields-container">
                <div className="col-md-6 col-sm-12 pe-4 columns">
                    <div className='field-form'>
                        <label htmlFor="conforme-con-perfil-asignado" className='label-field mt-3'>
                            ¿Está conforme con el perfil asignado? *
                        </label>
                        <select  id='conforme-con-perfil-asignado' defaultValue={'DEFAULT'} {...register('conformeConPerfilAsignado', {
                            validate: validateNonSelectedOptionInDropdown
                        })}>
                            <option value="DEFAULT" disabled>Selecciona</option>
                            <option value="Si">Si</option>
                            <option value="No">No</option>
                        </select>
                        {errors.conformeConPerfilAsignado && <p className='text-danger'>Debe seleccionar una opción</p>}
                    </div>
                </div>

                <div className="col-md-6 col-sm-12 pe-4 columns">
                    {
                        conformeConPerfilAsignado === "No" ?
                            <div className='field-form'>
                                <label htmlFor="considera-perfil-correcto" className='label-field mt-3'>
                                    ¿Cuál considera que es el perfil correcto para usted?
                                </label>
                                <select  id='considera-perfil-correcto' defaultValue={'DEFAULT'} {...register('consideraPerfilCorrecto')}>
                                    <option value="DEFAULT" disabled>Selecciona</option>
                                    <option value="Conservador">Conservador</option>
                                    <option value="Moderado">Moderado</option>
                                    <option value="Agresivo">Agresivo</option>
                                </select>
                            </div>
                        : null
                    }
                </div>

            </div> */}

        <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
          submit
        </button>
      </form>
    </>
  );
};

export default InvestorProfileForm;
