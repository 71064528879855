import './BankAccountDesignationForm.css';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { setSelectedSection } from '../../../../../shared/store/slices/clientePotencial';
import { useDispatch } from 'react-redux';
import {
  REMOVE_BANK_ACCOUNT,
  SAVE_BANK_ACCOUNT_SECTION,
} from '../../../../../shared/graphQL/mutations';
import {
  GET_BANKS,
  GET_BANK_ACCOUNTS_ORIGIN_TYPES,
  GET_BANK_ACCOUNTS_PERSON_TYPES,
  GET_BANK_ACCOUNTS_SECTION,
  GET_BANK_ACCOUNTS_TYPES,
  GET_CURRENCIES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import CardBankAccounts from '../../../CardBankAccounts/CardBankAccounts';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  convertBooleanToInput,
  convertInputToBoolean,
} from '../../../../../shared/utils/functions';
import { Error } from '@mui/icons-material';
import { ErrorText } from '../../../../../shared/UIElements/ErrorText/ErrorText';

const BankAccountDesignationForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const [formResponseId, setFormResponseId] = useState('');
  const btnCloseModalRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: currencies } = useQuery(GET_CURRENCIES);
  const { data: banks } = useQuery(GET_BANKS);
  const { data: bankAccountTypes } = useQuery(GET_BANK_ACCOUNTS_TYPES);
  const { data: bankAccountOriginTypes } = useQuery(
    GET_BANK_ACCOUNTS_ORIGIN_TYPES
  );
  const { data: bankAccountPersonTypes } = useQuery(
    GET_BANK_ACCOUNTS_PERSON_TYPES
  );
  const [currencyFilter, setCurrencyFilter] = useState(null);
  const [saveBankAccountSection] = useMutation(SAVE_BANK_ACCOUNT_SECTION, {
    refetchQueries: [
      {
        query: GET_BANK_ACCOUNTS_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [removeBankAccount] = useMutation(REMOVE_BANK_ACCOUNT, {
    refetchQueries: [
      {
        query: GET_BANK_ACCOUNTS_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
      {
        query: VALIDATE_FORM,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [getBankAccountsSectionData, bankAccountsSectionData] = useLazyQuery(
    GET_BANK_ACCOUNTS_SECTION,
    {
      fetchPolicy: 'cache-and-network',
    }
  );
  const [bankAccountSectionId, setBankAccountSectionId] = useState('');
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [bankAccountId, setBankAccountId] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [loadingSection, setLoadingSection] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const {
    companyName,
    isFormCompleted,
    isFormDisqualified,
    validation,
    isContrapart,
    isListedOnStock,
  } = useSelector((state) => state.clientePotencialSlice);

  const origenCuenta = watch('origenCuenta');
  const monedasCuentasBancarias = watch('monedasCuentasBancarias');
  const titularCuenta = watch('titularCuenta');
  const institucion = watch('institucion');
  const numeroCuentaBancaria = watch('numeroCuentaBancaria');
  const tipoCuentaBancaria = watch('tipoCuentaBancaria');
  const abaOSwift = watch('abaOSwift');
  const tieneIntermediario = watch('tieneIntermediario');
  const bancoIntermediario = watch('bancoIntermediario');
  const direccionBancoIntermediario = watch('direccionBancoIntermediario');
  const tipoPersona = watch('tipoPersona');

  useEffect(() => {
    if (origenCuenta && currencies?.currencies) {
      const currenciesByOrigin = checkNational()
        ? currencies?.currencies
        : currencies?.currencies.filter((c) => c.name !== 'DOP');

      setCurrencyFilter(currenciesByOrigin);
    }
  }, [origenCuenta, currencies]);

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      getBankAccountsSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  useEffect(() => {
    if (bankAccountsSectionData?.data?.bankAccountSection) {
      setBankAccountSectionId(
        bankAccountsSectionData?.data?.bankAccountSection?.id
      );
    }
  }, [bankAccountsSectionData]);

  useEffect(() => {
    if (isUpdating) {
      const referenciaCard = document.getElementById(bankAccountId);
      referenciaCard.click();
      setIsUpdating(false);
    }
  }, [isUpdating]);

  const checkNational = () => {
    return origenCuenta
      ? bankAccountOriginTypes?.bankAccountOriginTypes
          ?.find((b) => b.id === origenCuenta)
          ?.name.includes('Nacional')
      : false;
  };

  const handleBackNavigation = () => {
    dispatch(setSelectedSection('Información De Mi Empresa'));
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/datos-complementarios'
    );
  };

  const navigateToNextPage = () => {
    if (isContrapart) {
      if (!isListedOnStock) {
        navigate(
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/declaracion-de-beneficiarios'
        );
      } else {
        navigate(
          '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/miembros-del-consejo'
        );
      }
    } else {
      navigate(
        '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/referencias-comerciales'
      );
    }
  };

  const addBankAccount = () => {
    const bankAccountsByCurrency =
      bankAccountsSectionData?.data?.bankAccountSection?.bankAccounts?.find(
        (b) => b.currency.id === monedasCuentasBancarias
      );

    if (
      bankAccountsByCurrency &&
      bankAccountsByCurrency?.id !== bankAccountId
    ) {
      toast(
        `Ya existe una cuenta bancaria con esta moneda (${bankAccountsByCurrency.currency.name}).`,
        {
          type: 'error',
          theme: 'colored',
        }
      );
      return;
    }

    btnCloseModalRef.current.click();
    reset();

    let data = {};
    //Si bankAccountSectionId tiene algun valor es por la section ya existe y se debe actualizar cada vez que se guarse, en caso contrario se debe crear una nueva seccion para guardar la informacion
    if (bankAccountSectionId && bankAccountSectionId !== '') {
      // Si bankAccountId tiene valor, entonces esta editando, en caso contrario esta agregando
      if (bankAccountId && bankAccountId !== '') {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: bankAccountSectionId,
          bankAccountInputTypes: {
            id: bankAccountId,
            accountHolder: titularCuenta,
            accountNumber: numeroCuentaBancaria,
            bankAccountTypeId: tipoCuentaBancaria,
            bankAccountPersonTypeId: tipoPersona,
            bankAccountOriginTypeId: origenCuenta,
            bankId: institucion,
            currencyId: monedasCuentasBancarias,
            abaOrSwift: checkNational() ? null : abaOSwift,
            hasIntermediaryBank: !checkNational()
              ? convertInputToBoolean(tieneIntermediario)
              : false,
            intermediaryBankId:
              !tieneIntermediario || tieneIntermediario === 'No'
                ? null
                : bancoIntermediario,
            intermediaryBankAddress:
              !tieneIntermediario || tieneIntermediario === 'No'
                ? null
                : direccionBancoIntermediario,
          },
        };

        setBankAccountId('');
      } else {
        data = {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
          id: bankAccountSectionId,
          bankAccountInputTypes: {
            accountHolder: titularCuenta,
            accountNumber: numeroCuentaBancaria,
            bankAccountTypeId: tipoCuentaBancaria,
            bankAccountPersonTypeId: tipoPersona,
            bankAccountOriginTypeId: origenCuenta,
            bankId: institucion,
            currencyId: monedasCuentasBancarias,
            abaOrSwift: checkNational() ? null : abaOSwift,
            hasIntermediaryBank: !checkNational()
              ? convertInputToBoolean(tieneIntermediario)
              : false,
            intermediaryBankId:
              !tieneIntermediario || tieneIntermediario === 'No'
                ? null
                : bancoIntermediario,
            intermediaryBankAddress:
              !tieneIntermediario || tieneIntermediario === 'No'
                ? null
                : direccionBancoIntermediario,
          },
        };
      }
    } else {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        bankAccountInputTypes: {
          accountHolder: titularCuenta,
          accountNumber: numeroCuentaBancaria,
          bankAccountTypeId: tipoCuentaBancaria,
          bankAccountOriginTypeId: origenCuenta,
          bankAccountPersonTypeId: tipoPersona,
          bankId: institucion,
          currencyId: monedasCuentasBancarias,
          abaOrSwift: checkNational() ? null : abaOSwift,
          hasIntermediaryBank: !checkNational()
            ? convertInputToBoolean(tieneIntermediario)
            : false,
          intermediaryBankId:
            !tieneIntermediario || tieneIntermediario === 'No'
              ? null
              : bancoIntermediario,
          intermediaryBankAddress:
            !tieneIntermediario || tieneIntermediario === 'No'
              ? null
              : direccionBancoIntermediario,
        },
      };
    }

    setIsUpdating(false);

    saveBankAccountSection({
      variables: {
        bankAccountSection: data,
      },
    });
  };

  const deleteCardElement = (id) => {
    removeBankAccount({
      variables: {
        bankAccountId: id,
      },
    });
  };

  const handleEdit = (id) => {
    setBankAccountId(id);
    const selectedBankAccount =
      bankAccountsSectionData?.data?.bankAccountSection?.bankAccounts?.find(
        (x) => x.id === id
      );

    if (id && selectedBankAccount) {
      setValue('origenCuenta', selectedBankAccount?.bankAccountOriginType?.id);
      setValue('titularCuenta', selectedBankAccount?.accountHolder);
      setValue('institucion', selectedBankAccount?.bank?.id);
      setValue('numeroCuentaBancaria', selectedBankAccount?.accountNumber);
      setValue('tipoCuentaBancaria', selectedBankAccount?.bankAccountType?.id);
      setValue('tipoPersona', selectedBankAccount?.bankAccountPersonType?.id);
      setValue('monedasCuentasBancarias', selectedBankAccount?.currency?.id);
      setValue('abaOSwift', selectedBankAccount?.abaOrSwift);
      setValue(
        'tieneIntermediario',
        convertBooleanToInput(selectedBankAccount?.hasIntermediaryBank)
      );
      setValue('bancoIntermediario', selectedBankAccount?.intermediaryBank?.id);
      setValue(
        'direccionBancoIntermediario',
        selectedBankAccount?.intermediaryBankAddress
      );
    }
  };

  const filterBanks = () => {
    if (checkNational()) {
      return banks?.banks?.filter((bank) => !bank.isInternational);
    } else {
      return banks?.banks?.filter((bank) => bank.isInternational);
    }
  };

  const setPersonType = () => {
    if (
      bankAccountPersonTypes?.bankAccountPersonTypes?.length &&
      !bankAccountId
    ) {
      const person = bankAccountPersonTypes?.bankAccountPersonTypes.find(
        (type) => type.name === 'Persona Jurídica'
      );
      setValue('tipoPersona', person?.id);
    }
  };

  const setOwner = () => {
    setValue('titularCuenta', companyName);
  };

  const filterCurrency = (bankAccount) => {
    let currenciesInUse =
      bankAccountsSectionData?.data?.bankAccountSection?.bankAccounts?.map(
        (bankAccount) => bankAccount.currency
      );

    if (bankAccount) {
      const bankAccountToEdit =
        bankAccountsSectionData?.data?.bankAccountSection?.bankAccounts?.find(
          (bankAccount) => bankAccount.id === bankAccountId
        );
      currenciesInUse = currenciesInUse?.filter(
        (currency) => currency.id !== bankAccountToEdit.currency.id
      );
    }

    const idsToRemove = new Set(
      currenciesInUse?.length ? currenciesInUse?.map((item) => item.id) : []
    );
    const currenciesToUse = currencyFilter?.filter(
      (item) => !idsToRemove.has(item.id)
    );

    return currenciesToUse;
  };

  const clean = () => {
    reset();
    setBankAccountId('');
    setIsUpdating(false);
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'bankAccountSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  const hasError = (field) => {
    return validationErrors?.includes(field);
  };

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Cuentas Bancarias
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={'Cuentas Bancarias'}
              description={
                "En el formulario 'Cuentas Bancarias', se concentra de manera centralizada toda la información relevante sobre las cuentas bancarias asociadas a la empresa. Este espacio sirve como un registro completo que abarca detalles como números de cuenta, nombres de las instituciones financieras, y otros datos esenciales."
              }
            />
            {!!validationErrors?.length && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    validationErrors.length === 1 &&
                    validationErrors[0] === 'bankAccounts'
                      ? 'Es necesario que agregues por lo menos una cuenta de banco.'
                      : `Hay una o varias cuentas de banco con campos incompletos.`
                  }
                />
              </span>
            )}
          </h3>
          {loadingSection ? (
            <div className="w-100 d-flex align-items-center justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {bankAccountsSectionData?.data?.bankAccountSection?.bankAccounts?.map(
                (account, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={account?.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardBankAccounts
                        key={index}
                        nameElement={account?.accountHolder}
                        identityDocument={account?.accountNumber}
                        idElement={account?.id}
                        deleteElement={deleteCardElement}
                        showDeleteButton={!isFormCompleted && !isFormDisqualified}
                        monedaCuenta={account?.currency?.name}
                        localAccount={account?.bankAccountOriginType?.name.includes(
                          'Nacional'
                        )}
                        logoBanco={account?.bank?.logoUrl}
                        nombreBanco={
                          account?.bank
                            ? account?.bank?.name
                            : account.internationalBankName
                        }
                        tipoCuenta={
                          'Cuenta ' +
                          (account?.bankAccountType?.name === 'Ahorros'
                            ? ' de '
                            : '') +
                          account?.bankAccountType?.name
                        }
                        handleEdit={handleEdit}
                        setIsUpdating={setIsUpdating}
                      />
                    </div>
                  );
                }
              )}
              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  onClick={() => {
                    clean();
                    setPersonType();
                    setOwner();
                  }}
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">Agregar Cuenta</h4>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>
          <button
            type="button"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <form
              onSubmit={handleSubmit(addBankAccount)}
              className="fields-modal-container"
            >
              <div className="fields-modal-header">
                <h4 className="modal-title" id="exampleModalLabel">
                  {!isFormCompleted && !isFormDisqualified
                    ? bankAccountId
                      ? 'Editar'
                      : 'Agregar'
                    : 'Ver'}{' '}
                  Cuenta Bancaria
                </h4>
                <button
                  type="button"
                  ref={btnCloseModalRef}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    clean();
                  }}
                ></button>
              </div>

              <div>
                <div className="row">
                  <div className="field-form col-lg-6 col-sm-12 ps-4">
                    <label htmlFor="origen-cuenta" className="label-field">
                      Origen de la cuenta <span>*</span>
                    </label>
                    <div className="selectWrapper">
                      <select
                        id="origen-cuenta"
                        defaultValue={'DEFAULT'}
                        disabled={isFormCompleted || isFormDisqualified}
                        {...register('origenCuenta', {
                          validate: validateNonSelectedOptionInDropdown,
                        })}
                      >
                        <option value="DEFAULT" disabled>
                          Selecciona
                        </option>
                        {bankAccountOriginTypes?.bankAccountOriginTypes?.map(
                          (option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    {errors.origenCuenta && (
                      <p className="text-danger">Debe seleccionar una opción</p>
                    )}
                    {bankAccountId &&
                      (!origenCuenta || !errors.origenCuenta === 'DEFAULT') && (
                        <ErrorText hasError={hasError('bankAccountType')} />
                      )}
                  </div>
                </div>
              </div>

              <div className="  fields-modal-content">
                {origenCuenta !== 'DEFAULT' && origenCuenta !== undefined ? (
                  <>
                    <div className="row">
                      <div className="field-form col-lg-6 col-sm-12 ps-4">
                        <label htmlFor="titular-cuenta" className="label-field">
                          Titular de la cuenta <span>*</span>
                        </label>
                        <input
                          type="text"
                          className="input-form"
                          placeholder=""
                          id="titular-cuenta"
                          disabled={isFormCompleted || isFormDisqualified || companyName}
                          {...register('titularCuenta', {
                            required: true,
                          })}
                        />
                        {errors.titularCuenta?.type === 'required' && (
                          <p className="text-danger">
                            El campo Titular de la cuenta es requerido
                          </p>
                        )}
                        {bankAccountId &&
                          !titularCuenta &&
                          errors.titularCuenta?.type !== 'required' && (
                            <ErrorText hasError={hasError('accountHolder')} />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12 ps-4">
                        <label htmlFor="tipo-persona" className="label-field">
                          Tipo de persona <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="tipo-persona"
                            disabled // en persona fisica modificar
                            defaultValue={'DEFAULT'}
                            {...register('tipoPersona', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {bankAccountPersonTypes?.bankAccountPersonTypes?.map(
                              (option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.tipoPersona && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {bankAccountId &&
                          (!tipoPersona || tipoPersona === 'DEFAULT') &&
                          errors.tipoPersona && (
                            <ErrorText
                              hasError={hasError('bankAccountPersonType')}
                            />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12 ps-4">
                        <label
                          htmlFor="tipo-cuenta-bancaria"
                          className="label-field"
                        >
                          Tipo de cuenta <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="tipo-cuenta-bancaria"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('tipoCuentaBancaria', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {bankAccountTypes?.bankAccountTypes?.map(
                              (option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.tipoCuentaBancaria && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {bankAccountId &&
                          (!tipoCuentaBancaria ||
                            tipoCuentaBancaria === 'DEFAULT') &&
                          errors.tipoCuentaBancaria && (
                            <ErrorText
                              hasError={hasError('bankAccountPersonType')}
                            />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12 ps-4">
                        <label
                          htmlFor="numero-cuenta-bancaria"
                          className="label-field"
                        >
                          Número de cuenta bancaria <span>*</span>
                        </label>
                        <input
                          type="number"
                          className="input-form"
                          placeholder=""
                          id="numero-cuenta-bancaria"
                          disabled={isFormCompleted || isFormDisqualified}
                          {...register('numeroCuentaBancaria', {
                            required: true,
                          })}
                        />
                        {errors.numeroCuentaBancaria?.type === 'required' && (
                          <p className="text-danger">
                            El campo Número de cuenta bancaria es requerido
                          </p>
                        )}
                        {bankAccountId &&
                          !numeroCuentaBancaria &&
                          errors.numeroCuentaBancaria?.type !== 'required' && (
                            <ErrorText hasError={hasError('accountNumber')} />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12 ps-4">
                        <label htmlFor="institucion" className="label-field">
                          Entidad bancaria <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          {' '}
                          <select
                            id="institucion"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('institucion', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {filterBanks()
                              ? filterBanks()?.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.name}
                                  </option>
                                ))
                              : null}
                          </select>
                        </div>
                        {errors.institucion && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {bankAccountId &&
                          (!institucion || institucion === 'DEFAULT') &&
                          errors.institucion && (
                            <ErrorText hasError={hasError('bank')} />
                          )}
                      </div>

                      <div className="field-form col-lg-6 col-sm-12 ps-4">
                        <label
                          htmlFor="moneda-cuentas-bancarias"
                          className="label-field"
                        >
                          Moneda <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="moneda-cuentas-bancarias"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('monedasCuentasBancarias', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {filterCurrency(bankAccountId)?.map((option) => (
                              <option key={option.id} value={option.id}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.monedasCuentasBancarias && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                        {bankAccountId &&
                          (!monedasCuentasBancarias ||
                            monedasCuentasBancarias === 'DEFAULT') &&
                          errors.monedasCuentasBancarias && (
                            <ErrorText hasError={hasError('currency')} />
                          )}
                      </div>

                      {!checkNational() ? (
                        <div className="field-form col-lg-6 col-sm-12 ps-4">
                          <label htmlFor="aba-o-swift" className="label-field">
                            ABA o SWIFT <span>*</span>
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="aba-o-swift"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('abaOSwift', {
                              required: true,
                            })}
                          />
                          {errors.abaOSwift?.type === 'required' && (
                            <p className="text-danger">
                              El campo ABA o SWIFT es requerido
                            </p>
                          )}
                          {bankAccountId &&
                            !abaOSwift &&
                            errors.abaOSwift?.type !== 'required' && (
                              <ErrorText hasError={hasError('abaOrSwift')} />
                            )}
                        </div>
                      ) : null}

                      {!checkNational() ? (
                        <div className="field-form col-lg-6 col-sm-12 ps-4">
                          <label
                            htmlFor="tiene-intermediario"
                            className="label-field"
                          >
                            ¿Tiene Banco Intermediario? <span>*</span>
                          </label>
                          <div className="selectWrapper">
                            <select
                              id="tiene-intermediario"
                              defaultValue={'DEFAULT'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('tieneIntermediario', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona
                              </option>
                              <option value="Si">Si</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                          {errors.tieneIntermediario && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )}
                          {bankAccountId &&
                            (!tieneIntermediario ||
                              tieneIntermediario === 'DEFAULT') &&
                            errors.tieneIntermediario && (
                              <ErrorText
                                hasError={hasError('hasIntermediaryBank')}
                              />
                            )}
                        </div>
                      ) : null}

                      {tieneIntermediario === 'Si' ? (
                        <div className="field-form col-lg-6 col-sm-12 ps-4">
                          <label
                            htmlFor="intermediario"
                            className="label-field"
                          >
                            Banco intermediario <span>*</span>
                          </label>
                          <div className="selectWrapper">
                            {' '}
                            <select
                              id="intermediario"
                              defaultValue={'DEFAULT'}
                              disabled={isFormCompleted || isFormDisqualified}
                              {...register('bancoIntermediario', {
                                validate: validateNonSelectedOptionInDropdown,
                              })}
                            >
                              <option value="DEFAULT" disabled>
                                Selecciona
                              </option>
                              {banks?.banks?.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {errors.bancoIntermediario && (
                            <p className="text-danger">
                              Debe seleccionar una opción
                            </p>
                          )}
                          {bankAccountId &&
                            (!bancoIntermediario ||
                              bancoIntermediario === 'DEFAULT') &&
                            errors.bancoIntermediario && (
                              <ErrorText
                                hasError={hasError('intermediaryBank')}
                              />
                            )}
                        </div>
                      ) : null}

                      {tieneIntermediario === 'Si' ? (
                        <div className="field-form col-lg-6 col-sm-12 ps-4">
                          <label
                            htmlFor="direccion-banco-internacional"
                            className="label-field"
                          >
                            Dirección banco intermediario{' '}
                          </label>
                          <input
                            type="text"
                            className="input-form"
                            placeholder=""
                            id="direccion-banco-internacional"
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('direccionBancoIntermediario')}
                          />
                        </div>
                      ) : null}
                    </div>
                  </>
                ) : null}
              </div>

              {!isFormCompleted && !isFormDisqualified ? (
                <div className="row d-flex justify-content-center">
                  <button type="submit" className="mt-3 mb-5 btn-add">
                    Guardar
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccountDesignationForm;
