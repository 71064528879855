import './progressBar.css';

const ProgressBar = ({ width }) => {
  return (
    <>
      <div className="new-cliente-potencial-progress-bar-container">
        <div className="progress" style={{ height: '7px' }}>
          <div
            className="progress-bar bg-warning"
            role="progressbar"
            style={{ width: width, height: 'auto', border: 'none' }}
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
