import React from './FatcaRelatedPage.css';
import { useState } from 'react';
import ProgressBar from '../../../../../components/ClientePotencial/ProgressBar/ProgressBar';
import FatcaRelatedForm from '../../../../../components/ClientePotencial/forms/Legal person/FatcaRelatedForm/FatcaRelatedForm';

const FatcaRelatedPage = () => {
  const [validateForm, setValidateForm] = useState(false);
  const [goToBack, setGoToBack] = useState(false);

  // Assigning the div that hosts the content of the page 100% height of the screen
  document.addEventListener('DOMContentLoaded', function (event) {
    //código a ejecutar cuando existe la certeza de que el DOM está listo para recibir acciones
    let pageContainer = document.getElementById('page-container');
    const height = window.height;
    pageContainer.height = height;
  });

  const handleFormValidation = () => {
    setValidateForm(true);
  };

  const handleBackNavigation = () => {
    setGoToBack(true);
  };

  return (
    <div className="page-container" id="page-container">
      <div>
        <img src="/logo.png" className="logoParval2" alt="" />
      </div>

      <div className="principal-container">
        <h3 className="bff-title">
          Relativo a FATCA (Foreign Account Tax Compliance Act)
        </h3>

        <h4 className="bff-sub-title">
          ¿Posee la empresa un Tax Identification Number (TIN) o Employer
          Identification Number (EIN)?
        </h4>

        <FatcaRelatedForm
          validateForm={validateForm}
          setValidateForm={setValidateForm}
          goToBack={goToBack}
        />

        <ProgressBar
          handleClick={handleFormValidation}
          handleBackNavigation={handleBackNavigation}
          width={'81.25%'}
          percentage={'81.25%'}
        />
      </div>
    </div>
  );
};

export default FatcaRelatedPage;
