import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_NOTES_By_ENTITYID } from '../../../shared/graphQL/queries';
import { formatFullDate } from '../../../shared/utils/functions';
import { GET_FORM_RESPONSES_BY_PRIMARY_CONTACT } from '../../../shared/graphQL/queries';
import './Notes.css';

const Notes = () => {
  const [formResponses, setFormResponses] = useState([]);
  const [getFormResponsesByPrimaryContact, formResponsesByPrimaryContact] =
    useLazyQuery(GET_FORM_RESPONSES_BY_PRIMARY_CONTACT);
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [notes, setNotes] = useState([]);
  const [getNotesByIdentification, { data: notesByIdentification }] =
    useLazyQuery(GET_NOTES_By_ENTITYID);
  const [getNotesByFormResponseLead, { data: notesByFormResponseLead }] =
    useLazyQuery(GET_NOTES_By_ENTITYID);
  const [getNotesByPrymaryContact, { data: notesByPrymaryContact }] =
    useLazyQuery(GET_NOTES_By_ENTITYID);
  useEffect(() => {
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    if (selectedFormResponse?.lead?.identification) {
      getNotesByIdentification({
        variables: {
          entityId: selectedFormResponse?.lead?.identification,
        },
      });
    }
    getFormResponsesByPrimaryContact({
      variables: {
        primaryContactEmail: selectedFormResponse?.lead?.primaryContact?.email,
      },
    });
    if (selectedFormResponse?.lead?.primaryContact?.email) {
      getNotesByPrymaryContact({
        variables: {
          entityId: selectedFormResponse?.lead?.primaryContact?.email,
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (
      formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact &&
      formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
        ?.length > 0
    ) {
      setFormResponses(
        formResponsesByPrimaryContact?.data?.formResponsesByPrimaryContact
      );
    }
  }, [formResponsesByPrimaryContact]);

  useEffect(() => {
    for (let i = 0; i < formResponses.length; i++) {
      getNotesByFormResponseLead({
        variables: {
          entityId: formResponses[i].lead.identification,
        },
      });
    }
  }, [formResponses]);

  useEffect(() => {
    let allNotes = [];
    if (notesByIdentification?.notes) {
      allNotes = [...allNotes, ...notesByIdentification.notes];
    }
    if (notesByFormResponseLead?.notes) {
      allNotes = [...allNotes, ...notesByFormResponseLead.notes];
    }
    if (notesByPrymaryContact?.notes) {
      allNotes = [...allNotes, ...notesByPrymaryContact.notes];
    }
    const sortedNotes = allNotes.sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );
    setNotes(sortedNotes);
  }, [notesByIdentification, notesByFormResponseLead, notesByPrymaryContact]);

  return (
    <div className="sub-dashboard">
      <div className="sub-dashboard-header">
        <h3>Notas Rapidas</h3>
      </div>
      {notes.length > 0 ? (
        notes.map((note, index) => (
          <div className="card-notes" key={index}>
            <div className="text-card-summary">
              <b className="message-type"> {note.messageType.name} </b>
              <div className="info">
                <span className="author">
                  {note.author.firstName + ' ' + note.author.lastName}
                </span>
                <span>{formatFullDate(new Date(note.creationDate))}</span>
              </div>
            </div>

            <div className="note-content">
              <b>
                <h6>{note.title}</h6>
              </b>
              <p>{note.text}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="card-notes">
          <b>
            <div className="sub-dashboard-header">
              <h3>No hay notas</h3>
            </div>
          </b>
        </div>
      )}
    </div>
  );
};

export default Notes;
