import './ParvalMarketingForm.css';
import { useEffect, useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const ParvalMarketingForm = ({ validateForm, setValidateForm, goToBack }) => {
  const [parvalMarketingOptions, setParvalMarketingOptions] = useState([]);
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const formRef = useRef();

  const referidoPor = watch('referidoPor');
  const otros = watch('otros');

  useEffect(() => {
    if (validateForm === true) {
      const validateFormBtn = document.getElementById('validateFormBtn');
      validateFormBtn.click();
      setValidateForm(false);
    }
  }, [validateForm]);

  useEffect(() => {
    if (goToBack === true) {
      navigate('/formularios/referencias-comerciales');
    }
  }, [goToBack]);

  useEffect(() => {
    getFormInfo();
  }, []);

  const getFormInfo = () => {};

  const addRemoveParvalMarketingOptions = (option) => {
    if (parvalMarketingOptions?.includes(option)) {
      const index = parvalMarketingOptions.indexOf(option);
      parvalMarketingOptions.splice(index, 1);
      setParvalMarketingOptions([...parvalMarketingOptions]);
      //console.log(parvalMarketingOptions);
    } else {
      setParvalMarketingOptions((prevState) => prevState?.concat(option));
      //console.log(parvalMarketingOptions);
    }
  };

  const saveData = () => {};

  return (
    <form
      onSubmit={handleSubmit(saveData)}
      className="form-fileds-basic"
      ref={formRef}
    >
      <div className="row fields-container mb-4">
        <div className="col-md-6 col-sm-12">
          <div className="checkbox-options-container">
            <label htmlFor="prensa" className="label-field">
              <input
                type="checkbox"
                id="prensa"
                value="Prensa"
                checked={
                  parvalMarketingOptions?.includes('Prensa') ? 'checked' : ''
                }
                onClick={() => addRemoveParvalMarketingOptions('Prensa')}
                {...register('parvalMarketingOption', {
                  required: true,
                })}
              />{' '}
              Prensa
            </label>

            <label htmlFor="web" className="label-field">
              <input
                type="checkbox"
                id="web"
                value="Web"
                checked={
                  parvalMarketingOptions?.includes('Web') ? 'checked' : ''
                }
                onClick={() => addRemoveParvalMarketingOptions('Web')}
                {...register('parvalMarketingOption', {
                  required: true,
                })}
              />{' '}
              Web
            </label>

            <label htmlFor="referido" className="label-field">
              <input
                type="checkbox"
                id="referido"
                value="Referido"
                checked={
                  parvalMarketingOptions?.includes('Referido') ? 'checked' : ''
                }
                onClick={() => addRemoveParvalMarketingOptions('Referido')}
                {...register('parvalMarketingOption', {
                  required: true,
                })}
              />{' '}
              Referido
            </label>

            <label htmlFor="visitas" className="label-field">
              <input
                type="checkbox"
                id="visitas"
                value="Visitas"
                checked={
                  parvalMarketingOptions?.includes('Visitas') ? 'checked' : ''
                }
                onClick={() => addRemoveParvalMarketingOptions('Visitas')}
                {...register('parvalMarketingOption', {
                  required: true,
                })}
              />{' '}
              Visitas
            </label>

            <label htmlFor="correo-masivo" className="label-field">
              <input
                type="checkbox"
                id="correo-masivo"
                value="Correo Masivo"
                checked={
                  parvalMarketingOptions?.includes('Correo Masivo')
                    ? 'checked'
                    : ''
                }
                onClick={() => addRemoveParvalMarketingOptions('Correo Masivo')}
                {...register('parvalMarketingOption', {
                  required: true,
                })}
              />{' '}
              Correo Masivo
            </label>

            <label htmlFor="otros" className="label-field">
              <input
                type="checkbox"
                id="otros"
                value="Otros"
                checked={
                  parvalMarketingOptions?.includes('Otros') ? 'checked' : ''
                }
                onClick={() => addRemoveParvalMarketingOptions('Otros')}
                {...register('parvalMarketingOption', {
                  required: true,
                })}
              />{' '}
              Otros
            </label>
          </div>
          {errors.parvalMarketingOption?.type === 'required' && (
            <p className="text-danger">
              Debe seleccionar por lo menos una opción
            </p>
          )}
        </div>
      </div>
      <div className="row fields-container">
        <div className="col-md-5 col-sm-12">
          {parvalMarketingOptions?.includes('Referido') ? (
            <div className="field-form">
              <label htmlFor="referido-por" className="label-field">
                Referido por <span>*</span>
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="referido-por"
                {...register('referidoPor', {
                  required: true,
                })}
              />
              {errors.referidoPor?.type === 'required' && (
                <p className="text-danger">
                  El campo Correo electrónico es requerido
                </p>
              )}
            </div>
          ) : null}

          {parvalMarketingOptions?.includes('Otros') ? (
            <div className="field-form">
              <label htmlFor="otros" className="label-field">
                Otros
              </label>
              <input
                type="text"
                className="input-form"
                placeholder=""
                id="otros"
                {...register('otros')}
              />
              {/* {errors.referidoPor?.type === 'required' && <p className='text-danger'>El campo Correo electrónico por correspondencia es requerido</p>} */}
            </div>
          ) : null}
        </div>
      </div>

      <button type="submit" id="validateFormBtn" style={{ display: 'none' }}>
        submit
      </button>
    </form>
  );
};

export default ParvalMarketingForm;
