import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import DataContext from '../context/DataContext';

export default function Menu() {
  const { selectedPage, setSelectedPage } = useContext(DataContext);
  return (
    <>
      <ul className="menu">
        <li>
          <Link
            onClick={() => setSelectedPage('home')}
            className={selectedPage === 'home' ? `active` : null}
            to="/dashboard"
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setSelectedPage('historico-de-operaciones')}
            className={
              selectedPage === 'historico-de-operaciones' ? `active` : null
            }
            to="/historico-de-operaciones"
          >
            Historico de Operaciones
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setSelectedPage('tasas-productos')}
            className={selectedPage === 'tasas-productos' ? `active` : null}
            to="/dashboard"
          >
            Tasas y Productos
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setSelectedPage('agenda-cita')}
            className={selectedPage === 'agenda-cita' ? `active` : null}
            to="/dashboard"
          >
            Agenda tu cita
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setSelectedPage('solicitar-carta-certificaciones')}
            className={
              selectedPage === 'solicitar-carta-certificaciones'
                ? `active`
                : null
            }
            to="/dashboard"
          >
            Solicitar Carta y Certificaciones
          </Link>
        </li>
        <li>
          <Link
            onClick={() => setSelectedPage('guias-instructivos')}
            className={selectedPage === 'guias-instructivos' ? `active` : null}
            to="/dashboard"
          >
            Guías e Instructivos
          </Link>
        </li>
      </ul>
    </>
  );
}
