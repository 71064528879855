import './WorkingInformationForm.css';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  convertBooleanToInput,
  convertInputToBoolean,
  getDateFormatted,
} from '../../../../../shared/utils/functions';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../../../shared/UIElements/Spinner/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@mui/material';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GET_COMPANY_ORIGINS,
  GET_COUNTRIES,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_GENERAL_INFORMATION,
  GET_IDENTIFICATION,
  GET_SOCIETY_TYPES,
} from '../../../../../shared/graphQL/queries';
import { SAVE_GENERAL_INFORMATION } from '../../../../../shared/graphQL/mutations';

const WorkingInformationForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();
  const [savedCountry, setSavedCountry] = useState('');
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const {
    loading,
    error,
    data: companyOrigins,
  } = useQuery(GET_COMPANY_ORIGINS);
  const { data: countries } = useQuery(GET_COUNTRIES);
  const { data: societyTypeList } = useQuery(GET_SOCIETY_TYPES);
  const [getGeneralInformationData, generalInformationData] = useLazyQuery(
    GET_GENERAL_INFORMATION
  );
  //const [ getIdentification,  identification ] = useLazyQuery(GET_IDENTIFICATION);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [saveCompanyInformationSection] = useMutation(SAVE_GENERAL_INFORMATION);
  const [leads, setLeads] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  const nombre = watch('nombre');
  const numRegistroMercantil = watch('numRegistroMercantil');
  const origenEmpresa = watch('origenEmpresa');
  const fechaConstitucion = watch('fechaConstitucion');
  const rnc = watch('rnc');
  const tipoSociedad = watch('tipoSociedad');
  const poseeAccionesPortador = watch('poseeAccionesPortador');
  const lugarConstitucion = watch('lugarConstitucion');
  const domiciliadoEnRd = watch('domiciliadoEnRd');
  const fechaVencimientoNumRegistroMercantil = watch(
    'fechaVencimientoNumRegistroMercantil'
  );

  // useEffect(() => {
  //     if(nombre && numRegistroMercantil && origenEmpresa !== "DEFAULT" && fechaConstitucion && domiciliadoEnRd && rnc && lugarConstitucion !== "DEFAULT") {
  //         setDisabledButton(false);
  //     }
  //     else {
  //         setDisabledButton(true);
  //     }
  // }, [nombre, numRegistroMercantil, origenEmpresa, fechaConstitucion, domiciliadoEnRd, rnc, lugarConstitucion]);

  // useEffect(() => {
  //    if((countries?.countries?.length > 0) && (savedCountry !== "")) {
  //         setValue("lugarConstitucion", savedCountry);
  //    }
  // }, [countries])

  useEffect(() => {
    getFormResponseByIdentification({
      variables: {
        pagination: {
          filter: leads[0]?.identification,
        },
      },
    });
  }, [leads]);

  // useEffect(() => {
  //     getGeneralInformationData({
  //         variables: {
  //             formResponseId: formResponseByIdentification?.data?.formResponses[0]?.id
  //         }
  //     });
  // }, [formResponseByIdentification]);

  // useEffect(() => {
  //     // alert("Data: " + JSON.stringify(generalInformationData?.data));
  //     getFormInfo();
  // }, [generalInformationData]);

  // useEffect(() => {
  //     if(origenEmpresa === "d8035784-3562-11ee-8839-4a4f881b8c6b") {
  //         setValue("lugarConstitucion", "cff082c6-356f-11ee-8839-4a4f881b8c6b");
  //     }
  // }, []);

  // const getFormInfo = () => {
  //     if(generalInformationData?.data !== undefined) {
  //         const {id, commercialName, registeredNumber, companyOrigin, constitutionDate, societyType, bearerShares, country, registeredNumberExpirationDate } = generalInformationData.data?.companyInformationSection;
  //         setValue("nombre", commercialName);
  //         setValue("numRegistroMercantil", registeredNumber);
  //         setValue("origenEmpresa", companyOrigin?.id);
  //         setValue("fechaConstitucion", getCurrentDateFormatted(new Date(constitutionDate)));
  //         setValue("tipoSociedad", societyType?.id);
  //         setValue("lugarConstitucion", country?.id);
  //         setValue("poseeAccionesPortador", convertBooleanToInput(bearerShares));
  //         if(registeredNumberExpirationDate) {
  //             setValue("domiciliadoEnRd", "Si");
  //         }
  //         else {
  //             setValue("domiciliadoEnRd", "No");
  //         }
  //         setValue("fechaVencimientoNumRegistroMercantil", getCurrentDateFormatted(new Date(registeredNumberExpirationDate)));
  //         setSavedCountry(country?.id);
  //     }
  // }

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-fisica/datos-generales'
    );
  };

  const saveData = () => {
    // let data = {};
    // // Si todavia no se ha guardado el formulario lo crea, si ya esta guardado lo actualiza
    // if(generalInformationData?.data?.companyInformationSection?.commercialName === undefined) {
    //     // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
    //     data = {
    //         "formResponseId": formResponseByIdentification?.data?.formResponses[0]?.id,
    //         "commercialName": nombre,
    //         "registeredNumber": numRegistroMercantil,
    //         "companyOriginId": origenEmpresa,
    //         "societyTypeId": tipoSociedad,
    //         "countryId": lugarConstitucion,
    //         "constitutionDate": fechaConstitucion,
    //         "registeredNumberExpirationDate": domiciliadoEnRd === "Si" ? fechaVencimientoNumRegistroMercantil : null,
    //         "bearerShares": convertInputToBoolean(poseeAccionesPortador)
    //     };
    //     saveCompanyInformationSection({ variables: {
    //         companyInformationSection: data
    //     }});
    // }
    // else {
    //     // console.log("Entro aqui");
    //     // TODO encontrar una forma de que el formResponseId llegue dinamicamente de acuerdo al usuario que este logueado y no este siempre fijo
    //     data = {
    //         "formResponseId": formResponseByIdentification?.data?.formResponses[0]?.id,
    //         "id": generalInformationData.data.companyInformationSection.id,
    //         "commercialName": nombre,
    //         "registeredNumber": numRegistroMercantil,
    //         "companyOriginId": origenEmpresa,
    //         "societyTypeId": tipoSociedad,
    //         "countryId": lugarConstitucion,
    //         "constitutionDate": fechaConstitucion,
    //         "registeredNumberExpirationDate": domiciliadoEnRd === "Si" ? fechaVencimientoNumRegistroMercantil : null,
    //         "bearerShares": convertInputToBoolean(poseeAccionesPortador)
    //     };
    //     saveCompanyInformationSection({ variables: {
    //         companyInformationSection: data
    //     }});
    // }
    // console.log(data);
    // navigate("/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/informacion-contacto-principal");
  };

  return (
    <>
      {/* {
            showSpinner ? 
                <Spinner />
            : null
        } */}

      <form onSubmit={handleSubmit(saveData)} className="form-fileds-basic">
        {showSpinner ? (
          <div className="w-100 d-flex justify-content-center p-5">
            <CircularProgress color="warning" />
          </div>
        ) : (
          <>
            <div className="row fields-container">
              <div className="col-md-5 col-sm-12 ">
                <h3 className="formTitle">Información Laboral</h3>

                <div className="field-form">
                  <label htmlFor="estado-laboral" className="label-field">
                    Indique su estado laboral <span>*</span>
                  </label>
                  <select
                    id="estado-laboral"
                    defaultValue={'DEFAULT'}
                    {...register('estadoLaboral', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Opción 1">Opción 1</option>
                    <option value="Opción 2">Opción 2</option>
                  </select>
                  {errors.estadoLaboral && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="nombreInstitucion" className="label-field">
                    Nombre de la Institución <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="nombreInstitucion"
                    {...register('nombreInstitucion', {
                      required: true,
                    })}
                  />
                  {errors.nombreInstitucion?.type === 'required' && (
                    <p className="text-danger">
                      El campo Nombre de la Institución es requerido
                    </p>
                  )}
                </div>

                <div className="field-form">
                  <label
                    htmlFor="correoElectronicoLaboral"
                    className="label-field"
                  >
                    Correo electrónico laboral <span>*</span>
                  </label>
                  <input
                    type="email"
                    className="input-form"
                    placeholder=""
                    id="correoElectronicoLaboral"
                    {...register('correoElectronicoLaboral', {
                      required: true,
                    })}
                  />
                  {errors.correoElectronicoLaboral?.type === 'required' && (
                    <p className="text-danger">
                      El campo Correo electrónico laboral es requerido
                    </p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="cargo" className="label-field">
                    Cargo <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="input-form"
                    placeholder=""
                    id="cargo"
                    {...register('cargo', {
                      required: true,
                    })}
                  />
                  {errors.cargo?.type === 'required' && (
                    <p className="text-danger">El campo Cargo es requerido</p>
                  )}
                </div>
              </div>

              <div className="col-md-5 col-sm-12 ps-4 columns">
                <h3 className="formTitle" style={{ visibility: 'hidden' }}>
                  Non visible
                </h3>

                <div className="field-form">
                  <label htmlFor="fechaIngreso" className="label-field">
                    Fecha de ingreso <span>*</span>
                  </label>
                  <input
                    type="date"
                    className="input-form"
                    placeholder=""
                    id="fechaIngreso"
                    {...register('fechaIngreso', {
                      required: true,
                    })}
                  />
                  {errors.fechaIngreso?.type === 'required' && (
                    <p className="text-danger">
                      El campo RNC o equivalente es requerido
                    </p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="telefonoOficina" className="label-field">
                    Teléfono Oficina <span>*</span>
                  </label>
                  <input
                    type="number"
                    className="input-form"
                    placeholder=""
                    id="telefonoOficina"
                    {...register('telefonoOficina', {
                      required: true,
                    })}
                  />
                  {errors.telefonoOficina?.type === 'required' && (
                    <p className="text-danger">
                      El campo Teléfono Oficina es requerido
                    </p>
                  )}
                </div>

                <div className="field-form">
                  <label htmlFor="ingresosMensuales" className="label-field">
                    Ingresos Mensuales <span>*</span>
                  </label>
                  <select
                    id="ingresosMensuales"
                    defaultValue={'DEFAULT'}
                    {...register('ingresosMensuales', {
                      validate: validateNonSelectedOptionInDropdown,
                    })}
                  >
                    <option value="DEFAULT" disabled>
                      Selecciona
                    </option>
                    <option value="Opción 1">Opción 1</option>
                    <option value="Opción 2">Opción 2</option>
                  </select>
                  {errors.ingresosMensuales && (
                    <p className="text-danger">Debe seleccionar una opción</p>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            disabled={disabledButton}
            className={disabledButton ? 'button-disabled' : 'btn-continue'}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </form>
    </>
  );
};

export default WorkingInformationForm;
