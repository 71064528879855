import { useEffect, useState } from 'react';
import {
  envars,
  gatewayError,
  requestCanceled,
} from '../../../shared/utils/functions';
import { Skeleton } from '@mui/material';
import { Container, Col, Row, Breadcrumb } from 'react-bootstrap';
import axios from 'axios';
import './ratesProducts.css';
import CortoPlazo from '../../../components/ClienteReal/CortoPlazo/CortoPlazo';
import RentaFija from '../../../components/ClienteReal/RentaFija/RentaFija';
import RentaVariable from '../../../components/ClienteReal/RentaVariable/RentaVariable';

export const LOGOS_3P = {
  'Acero Estrella': '/logos/Acero Estrella.png',
  'Banco Central': '/logos/Banco Central.png',
  'Banco Promerica': '/logos/Banco Promerica.png',
  'Cesar Iglesias': '/logos/Cesar Iglesias.png',
  'Consorcio Minero Dominicano (E1)':
    '/logos/Consorcio Minero Dominicano (E1).jpg',
  'Consorcio Minero Dominicano (E5)':
    '/logos/Consorcio Minero Dominicano (E5).jpg',
  Default: '/logos/Default.png',
  'DPP (E-47)': '/logos/DPP (E-47).jpg',
  'EGE ITABO': '/logos/EGE ITABO.png',
  'FIC Advanced Desarrollo Op. Inversion II':
    '/logos/FIC Advanced Desarrollo Op. Inversion II.png',
  'FIC Advanced Desarrollo Op. Inversion':
    '/logos/FIC Advanced Desarrollo Op. Inversion.png',
  'FIC Gam Energía': '/logos/FIC Gam Energía.png',
  'FIC Gam Inmobiliario I': '/logos/FIC Gam Inmobiliario I.png',
  'FIC Gam Renta Fija': '/logos/FIC Gam Renta Fija.png',
  'FIC Inmobiliario Advanced I': '/logos/FIC Inmobiliario Advanced I.png',
  'Fondo Inmobiliario Excel I': '/logos/Fondo Inmobiliario Excel I.png',
  'Fondo Inmobiliario Excel II': '/logos/Fondo Inmobiliario Excel II.png',
  'Gulfstream Petroleum Dominicana':
    '/logos/Gulfstream Petroleum Dominicana.png',
  'Haina Investment Co. Ltd. (E2)': '/logos/Haina Investment Co. Ltd. (E2).png',
  'Haina Investment Co. Ltd. (E3)': '/logos/Haina Investment Co. Ltd. (E3).png',
  'Haina Investment Co. Ltd. (E4)': '/logos/Haina Investment Co. Ltd. (E4).png',
  'Haina Investment': '/logos/Haina Investment.jpg',
  'Ministerio de Hacienda': '/logos/Ministerio de Hacienda.png',
};

export default function TasaProductos() {
  const options = [
    { id: 1, title: 'CORTO PLAZO' },
    { id: 2, title: 'RENTA FIJA' },
    { id: 6, title: 'RENTA VARIABLE' },
  ];
  const [dataOptions, setDataOptions] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    await getRates();
  };

  const mapResponse = (data) => {
    const mapData = data
      .map((x) => {
        return x.products.map((e) => {
          e.category = { id: x.id, name: x.name };
          return e;
        });
      })
      .reduce((acc, e) => [...acc, ...e]);

    mapData.forEach((p) => {
      p.table.table.data.forEach((d, i) => {
        if (
          Object.getOwnPropertyNames(d)
            .map((x) => x.toUpperCase())
            .indexOf('EMISOR') >= 0
        ) {
          d['Emisor Logo'] =
            `${envars('BACKEND_API_URL')}${LOGOS_3P[d['Emisor'].trim()] ?? LOGOS_3P['Default']}`;
        }
        if (
          Object.getOwnPropertyNames(d)
            .map((x) => x.toUpperCase())
            .indexOf('IMAGE') >= 0
        ) {
          delete p.table.table.data[i]['image'];
        }
      });
    });

    return mapData;
  };

  const getRates = async () => {
    setDataLoading(true);
    try {
      const res = await axios.get('/middleware/rate-yield/list-category');
      if (res.data) {
        setData(mapResponse(res.data));
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
      if (!requestCanceled(error)) {
        await gatewayError(getRates, error);
      }
    }
    setDataLoading(false);
  };

  const setData = (data) => {
    const result = options.map((o) => {
      return {
        optionId: o.id,
        data: data.filter((d) => d.category.id === o.id),
      };
    });
    setDataOptions(result);
  };

  const routerOptions = (option) => {
    let selected;
    switch (option.id) {
      case options[0].id:
      default:
        selected = (
          <CortoPlazo
            data={dataOptions.find((d) => d.optionId === options[0].id)}
          />
        );
        break;
      case options[1].id:
        selected = (
          <RentaFija
            data={dataOptions.find((d) => d.optionId === options[1].id)}
          />
        );
        break;
      case options[2].id:
        selected = (
          <RentaVariable
            data={dataOptions.find((d) => d.optionId === options[2].id)}
          />
        );
        break;
    }

    return selected;
  };

  return (
    <div className="cliente-potencial-dashboard-tasa-producto">
      <div className="content" style={{ paddingTop: 50 }}>
        <div className="cliente-potencial-tasa-producto-container">
          <div className="row sub-dashboard-soa-header">
            <div className="col-9">
              <Breadcrumb>
                <Breadcrumb.Item active>
                  <strong>Tasas y Productos /</strong>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className="cliente-potencial-tasa-producto-box box p-0">
            <div className="cliente-potencial-tasa-producto-top">
              {options.map((o) => (
                <div
                  className={
                    'cliente-potencial-tasa-producto-btn ms-3' +
                    (o.id === selectedOption.id
                      ? ' cliente-potencial-tasa-producto-active'
                      : '')
                  }
                  key={o.id}
                  onClick={() => setSelectedOption(o)}
                >
                  <p className="p-0 m-0">{o.title}</p>
                </div>
              ))}
            </div>
            <div className="cliente-potencial-tasa-producto-content">
              <Container fluid>
                <Row>
                  {dataLoading ? (
                    <Col
                      style={{
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingBottom: 30,
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        height={590}
                        animation="wave"
                        style={{ backgroundColor: '#dddddd', borderRadius: 10 }}
                      />
                    </Col>
                  ) : (
                    <Col>{routerOptions(selectedOption)}</Col>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
