import './TitleOperationsForm.css';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalInfo from '../../../../../shared/UIElements/ModalInfo/ModalInfo';
import { CircularProgress } from '@mui/material';
import CardItems from '../../../../ClientePotencial/CardItems/CardItems';
import {
  faAngleRight,
  faAngleLeft,
  faAdd,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';
import { validateNonSelectedOptionInDropdown } from '../../../../../shared/utils/validators';
import {
  GET_FINANCIAL_OPERATIONS_LIST,
  GET_FORM_RESPONSE_BY_IDENTIFICATION,
  GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA,
  GET_INVESTMENT_PROFILE_SECTION,
  VALIDATE_FORM,
} from '../../../../../shared/graphQL/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  GENERATE_INVESTMENT_PROFILE,
  SAVE_INVESTMENT_PROFILE_DESCRIPTION_SECTION,
} from '../../../../../shared/graphQL/mutations';
import { useSelector } from 'react-redux';
import { Error } from '@mui/icons-material';

const TitleOperationsForm = () => {
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = useForm();
  const { data: financialOperations } = useQuery(GET_FINANCIAL_OPERATIONS_LIST);
  const navigate = useNavigate();
  const [formResponseId, setFormResponseId] = useState('');
  const btnCloseModalRef = useRef();
  const [saveInvestmentProfileDescriptionSection] = useMutation(
    SAVE_INVESTMENT_PROFILE_DESCRIPTION_SECTION,
    {
      refetchQueries: [
        {
          query: GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA,
          variables: {
            formResponseId: formResponseId,
          },
        },
        {
          query: VALIDATE_FORM,
          variables: {
            formResponseId: formResponseId,
          },
        },
      ],
    }
  );
  const [
    getInvestmentProfileDescriptionSectionData,
    investmentProfileDescriptionSectionData,
  ] = useLazyQuery(GET_INVESTMENT_PROFILE_DESCRIPTION_SECTION_DATA);
  const [getFormResponseByIdentification, formResponseByIdentification] =
    useLazyQuery(GET_FORM_RESPONSE_BY_IDENTIFICATION);
  const [generateInvestmentProfile] = useMutation(GENERATE_INVESTMENT_PROFILE, {
    refetchQueries: [
      {
        query: GET_INVESTMENT_PROFILE_SECTION,
        variables: {
          formResponseId: formResponseId,
        },
      },
    ],
  });
  const [selectedFormResponse, setSelectedFormResponse] = useState({});
  const [isUpdating] = useState(false);
  const [loadingSection, setLoadingSection] = useState(true);
  const [validationErrors, setValidationErrors] = useState([]);
  const { isFormCompleted, isFormDisqualified, validation } = useSelector(
    (state) => state.clientePotencialSlice
  );

  const titleOperation = watch('titleOperation');

  useEffect(() => {
    setLoadingSection(true);
    setSelectedFormResponse(JSON.parse(sessionStorage.getItem('selectedFormResponse')));
  }, []);

  useEffect(() => {
    setValue('rnc', selectedFormResponse?.lead?.identification);

    if (selectedFormResponse?.lead?.identification) {
      if (
        Object.keys(selectedFormResponse).length > 0 &&
        selectedFormResponse?.form?.personType?.name !== 'Persona Jurídica'
      ) {
        navigate(-1);
      }

      getFormResponseByIdentification({
        variables: {
          pagination: {
            filter: selectedFormResponse?.lead?.identification,
          },
        },
      });
    }
  }, [selectedFormResponse]);

  useEffect(() => {
    if (formResponseByIdentification?.data) {
      setFormResponseId(
        formResponseByIdentification?.data?.formResponses[0]?.id
      );

      getInvestmentProfileDescriptionSectionData({
        variables: {
          formResponseId:
            formResponseByIdentification?.data?.formResponses[0]?.id,
        },
      }).then(() => {
        setLoadingSection(false);
      });
    }
  }, [formResponseByIdentification]);

  const handleBackNavigation = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/objetivos-tolerancia-al-riesgo'
    );
  };

  const navigateToNextPage = () => {
    navigate(
      '/onboarding-digital/dashboard/datos-de-la-cuenta/formularios/persona-juridica/distribucion-de-activos-liquidos'
    );
  };

  const addTitleOperation = () => {
    btnCloseModalRef.current.click();
    reset();

    let data = {};

    const sectionId =
      investmentProfileDescriptionSectionData.data
        ?.investmentProfileDescriptionSection?.id;
    const savedOperations =
      investmentProfileDescriptionSectionData?.data
        ?.investmentProfileDescriptionSection?.financialOperations;
    const ids = [];

    if (savedOperations !== undefined && savedOperations !== null) {
      for (let operation of savedOperations) {
        ids.push(operation.id);
      }
    }

    if (sectionId === undefined || sectionId === null) {
      data = {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        financialOperationIds: [...ids, titleOperation],
      };
    } else {
      data = {
        id: sectionId,
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        financialOperationIds: [...ids, titleOperation],
      };
    }

    saveInvestmentProfileDescriptionSection({
      variables: {
        investmentProfileDescriptionSection: data,
      },
    });

    generateInvestmentProfile({
      variables: {
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
      },
    });
  };

  const deleteCardElement = (id) => {
    const sectionId =
      investmentProfileDescriptionSectionData.data
        ?.investmentProfileDescriptionSection?.id;
    const savedOperations =
      investmentProfileDescriptionSectionData?.data
        ?.investmentProfileDescriptionSection?.financialOperations;
    const ids = [];

    if (savedOperations !== undefined && savedOperations !== null) {
      for (let operation of savedOperations) {
        if (operation?.id !== id) {
          ids.push(operation?.id);
        }
      }

      const data = {
        id: sectionId,
        formResponseId:
          formResponseByIdentification?.data?.formResponses[0]?.id,
        financialOperationIds: [...ids],
      };

      saveInvestmentProfileDescriptionSection({
        variables: {
          investmentProfileDescriptionSection: data,
        },
      });
    }
  };

  const filterFinancialOperations = () => {
    let inUseTypes =
      investmentProfileDescriptionSectionData?.data
        ?.investmentProfileDescriptionSection?.financialOperations;

    const idsToRemove = new Set(
      inUseTypes?.length ? inUseTypes?.map((item) => item.id) : []
    );
    const typesToUse = financialOperations?.financialOperations?.filter(
      (item) => !idsToRemove.has(item.id)
    );

    return typesToUse;
  };

  useEffect(() => {
    const complexSectionsErrors = [];
    for (const key in validation) {
      if (key === 'investmentProfileDescriptionSectionErrors') {
        complexSectionsErrors.push(
          ...validation[key]?.map((validation) => validation.fieldId)
        );
      }
    }
    setValidationErrors(complexSectionsErrors);
  }, [validation]);

  return (
    <>
      <div className="page-container" id="page-container">
        <div className="principal-container3">
          <h3 className="formTitle">
            Marque las operaciones de títulos valores y financieras que desea
            realizar y comprende
            <ModalInfo
              Icon={<FontAwesomeIcon icon={faCircleInfo} />}
              title={
                'Marque las operaciones de títulos valores y financieras que desea realizar y comprende'
              }
              description={
                "El formulario 'Marque las operaciones de títulos valores y financieras que desea realizar y comprende' se centra en documentar las operaciones relacionadas con los títulos valores de la empresa y otras transacciones financieras. Este espacio proporciona una visión detallada de las actividades financieras que involucran la gestión y negociación de valores, así como otras operaciones financieras relevantes para la entidad."
              }
            />
            {!!validationErrors?.find(
              (violationError) => violationError === 'financialOperations'
            ) && (
              <span style={{ marginLeft: 30 }}>
                <ModalInfo
                  color="white"
                  bgcolor="#B90E0A"
                  Icon={
                    <Error
                      style={{
                        color: '#B90E0A',
                        fontSize: 25,
                        marginTop: '-5px',
                      }}
                    />
                  }
                  description={
                    'Es necesario que agregues por lo menos una operación.'
                  }
                />
              </span>
            )}
          </h3>
          {loadingSection ? (
            <div className="w-100 d-flex justify-content-center p-5">
              <CircularProgress color="warning" />
            </div>
          ) : (
            <div className="add-card-container">
              {investmentProfileDescriptionSectionData?.data?.investmentProfileDescriptionSection?.financialOperations?.map(
                (titleOperation, index) => {
                  return (
                    <div
                      key={index}
                      data-bs-toggle={isUpdating ? 'modal' : ''}
                      id={titleOperation.id}
                      data-bs-target={isUpdating ? '#exampleModal' : ''}
                    >
                      <CardItems
                        key={index}
                        nameElement={titleOperation.name}
                        idElement={titleOperation.id}
                        deleteElement={deleteCardElement}
                        showEditButton={false}
                        showDeleteButton={!isFormCompleted && !isFormDisqualified}
                      />
                    </div>
                  );
                }
              )}
              {!isFormCompleted && !isFormDisqualified ? (
                <div
                  className="add-card"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  <div className="image-icon-container">
                    <FontAwesomeIcon icon={faAdd} className="image-icon" />
                  </div>
                  <h4 className="bk-card-title">Agregar Operación</h4>
                </div>
              ) : null}
            </div>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <form
                  onSubmit={handleSubmit(addTitleOperation)}
                  className="fields-modal-container frm-main-share-holders"
                >
                  <div className="fields-modal-header mb-3">
                    <h4 className="modal-title" id="exampleModalLabel">
                      Agregar Operación
                    </h4>
                    <button
                      type="button"
                      ref={btnCloseModalRef}
                      onClick={() => reset()}
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="row fields-modal-content">
                    <div className="col-12">
                      <div className="field-form">
                        <label
                          htmlFor="title-operation"
                          className="label-field"
                        >
                          Seleccione la operación que desea agregar{' '}
                          <span>*</span>
                        </label>
                        <div className="selectWrapper">
                          <select
                            id="title-operation"
                            defaultValue={'DEFAULT'}
                            disabled={isFormCompleted || isFormDisqualified}
                            {...register('titleOperation', {
                              validate: validateNonSelectedOptionInDropdown,
                            })}
                          >
                            <option value="DEFAULT" disabled>
                              Selecciona
                            </option>
                            {filterFinancialOperations()?.map(
                              (financialOperation) => (
                                <option
                                  key={financialOperation.id}
                                  value={financialOperation.id}
                                >
                                  {financialOperation.name}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                        {errors.titleOperation && (
                          <p className="text-danger">
                            Debe seleccionar una opción
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  {!isFormCompleted && !isFormDisqualified ? (
                    <div className="row d-flex justify-content-center">
                      <button type="submit" className="mt-5 mb-5 btn-add">
                        Guardar
                      </button>
                    </div>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="buttons-control-container">
          <button
            type="submit"
            className="btn-back"
            onClick={handleBackNavigation}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
            &#160; Atrás
          </button>

          <button
            type="submit"
            className={`btn-continue`}
            onClick={navigateToNextPage}
          >
            Siguiente &#160;
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
      </div>
    </>
  );
};

export default TitleOperationsForm;
