import axios from 'axios';
import {
  azureUserFlowUri,
  CLIENTTYPE,
  COOKIENAMES,
  PERSONTYPE,
  VARIABLEFIJAAPIDATE,
} from '../constants/gobal-constants';
import { setSessionModal } from '../store/slices/clienteReal';
import jwt_encode from 'jwt-encode';
import jwt_decode from 'jwt-decode';
import format from 'date-fns/format';
import { parse } from 'date-fns';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';

export const convertInputToBoolean = (inputString) => {
  const options = {
    Si: true,
    No: false,
  };

  let valueBoolean = options[inputString];
  return valueBoolean;
};

export const convertBooleanToInput = (booleanValue) => {
  const options = {
    true: 'Si',
    false: 'No',
  };

  let valueString = options[booleanValue];
  return valueString;
};

export const getDateFormatted = (date) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const year = date?.getFullYear();
  const month = date?.getMonth() + 1;
  const day = date?.getDate() + 1;
  const formatDate =
    year +
    '-' +
    (month < 10 ? '0' : '') +
    month +
    '-' +
    (day < 10 ? '0' : '') +
    day;
  return formatDate;
};

export function formatSimple(date) {
  if (!date) {
    return '';
  }

  return format(date, 'yyyy-MM-dd');
}

export function parseSimple(date) {
  if (!date) {
    return null;
  }

  return parse(date, 'dd/MM/yyyy', new Date());
}

export const formatFullDate = (date) => {
  if (!date) {
    return '';
  }

  return format(date, 'dd/MM/yyyy hh:mm a');
};

export const preFormat = (date) => {
  const dates = date.split('-');
  return dates.reverse().join('/');
};

export const getFieldJwt = (field) => {
  const token = localStorage.getItem('access_token');

  if (!token) {
    return '';
  }

  let dataToken = JSON.parse(atob(token.split('.')[1]));
  return dataToken[field];
};

export const getAccessToken = () => {
  const session = sessionStorage.getItem('access_token');

  if (!session) {
    return false;
  }

  const tokenObject = JSON.parse(session);

  const exp = new Date(Number(tokenObject.exp) * 1000);

  if (new Date() > exp) {
    return false;
  }

  return tokenObject.access_token;
};

export const deleteAccessToken = () => {
  for (let i = 0; i < 3; i++) {
    deleteCookie(`A_T_${i}`);
  }
};

export const expiresCookie = (time) => {
  const d = new Date();
  d.setTime(d.getTime() + time * 1000);
  return d.toUTCString();
};

export const validateEmail = (email) => {
  // Define our regular expression.
  const validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

  // Using test we can check if the text match the pattern
  if (!validEmail.test(email)) {
    return false;
  }

  return true;
};

export const getAuthorizationConfig = (
  accessToken = localStorage.getItem('access_token')
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return config;
};

export const getAuthorizationConfigCookie = () => {
  const token = getCookie(COOKIENAMES.user).token;
  return getAuthorizationConfig(token);
};

export const getCookie = (name, isObject = true) => {
  let matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line no-useless-escape
      '(?:^|; )' +
        // eslint-disable-next-line no-useless-escape
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  const result = matches
    ? decodeURIComponent(matches[1])
    : isObject
      ? '{}'
      : '';
  return isObject ? JSON.parse(result) : result;
};

export const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const deleteCookie = (name) => {
  setCookie(name, '', {
    'max-age': -1,
  });
};

export const formatName = (name) => {
  const elements = name.split(' ');
  const settedElements = elements.map(
    (e) => e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()
  );
  return settedElements.join(' ');
};

export const getDomain = () => {
  let port = window.location.port !== '' ? ':' + window.location.port : '';
  let currentDomain =
    window.location.protocol + '//' + window.location.hostname + port;
  return currentDomain;
};

export const navigateToRegister = () => {
  const currentDomain = getDomain();
  window.location.href = currentDomain + '/register';
};

export const validateInputCedula = (option) => {
  var cedula = document.getElementById(option ? option : 'cedula');
  // Eliminar cualquier carácter no numérico
  cedula.value = cedula.value.replace(/[^0-9]/g, '');

  // Eliminar guiones
  cedula.value = cedula.value.replace(/-/g, '');

  // Agregar guiones después de los caracteres 3 y 10
  if (cedula.value.length > 3) {
    cedula.value = cedula.value.slice(0, 3) + '-' + cedula.value.slice(3);
  }
  if (cedula.value.length > 11) {
    cedula.value = cedula.value.slice(0, 11) + '-' + cedula.value.slice(11);
  }
};

export const validateInputRnc = (option) => {
  var rnc = document.getElementById(
    option && (typeof option === 'string' || option instanceof String)
      ? option
      : 'rnc'
  );
  // Eliminar cualquier carácter no numérico
  rnc.value = rnc.value.replace(/[^0-9]/g, '');

  // Eliminar guiones
  rnc.value = rnc.value.replace(/-/g, '');

  // Agregar guiones después de los caracteres 3 y 10
  if (rnc.value.length > 3) {
    rnc.value = rnc.value.slice(0, 3) + '-' + rnc.value.slice(3);
  }
  if (rnc.value.length > 9) {
    rnc.value = rnc.value.slice(0, 9) + '-' + rnc.value.slice(9);
  }
};

export const currencyFormat = (num, decimals = 2, symbol = true) => {
  const result = num
    .toFixed(decimals)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return symbol ? '$' + result : result;
};

export const handleClienteRealLogOut = (fromIndex = false) => {
  !fromIndex && window.location.replace(azureUserFlowUri());
  deleteCookie(COOKIENAMES.profile);
  deleteCookie(COOKIENAMES.user);
};

export const handleClientePotencialLogOut = () => {
  // Token for logout
  sessionStorage.removeItem('selectedFormResponse');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('password');
  sessionStorage.removeItem('hasInvestmentProfile');
  sessionStorage.removeItem('obligatedSubject');
  sessionStorage.removeItem('access_token');
  deleteCookie('potencialCustomerConfig');
  deleteAccessToken();
};

export const notAuthorizedTokenClienteReal = (error, dispatch, modalIsUp) => {
  if (error.response && !modalIsUp && error.response.status === 401) {
    deleteCookie(COOKIENAMES.profile);
    deleteCookie(COOKIENAMES.user);
    dispatch(
      setSessionModal({
        showSessionModal: true,
        sessionModal: {
          subtitle: `Al parecer se ha iniciado sesión en otro dispositivo con esta cuenta.
        Inicia sesión de nuevo para continuar en este dispositivo.`,
        },
      })
    );
  }
};

export const gatewayError = (callableFunction, error) => {
  if (error.response.status === 504) {
    return callableFunction();
  }
};

export const requestCanceled = (error) => {
  return error.message === 'canceled';
};

export const ram = () => {
  return Math.floor(Math.random() * 10000);
};

export const variableFijoFecha = (date) => {
  const parseLang = date
    .replace(/Ene|ene/g, 'jan')
    .replace(/ene/g, 'jan')
    .replace(/abr/g, 'apr')
    .replace(/ago/g, 'aug')
    .replace(/dic/g, 'dec');
  let formatDate;
  if (date.length === 10) {
    formatDate = 'yyyy-MM-dd';
  } else if (/[a-zA-Z]/.test(date)) {
    formatDate = 'dd-MMM-yy';
  } else {
    formatDate = 'dd-MM-yy';
  }
  const parsedDate = parse(parseLang, formatDate, new Date());
  const newDate = format(parsedDate, 'dd-MM-yyyy');
  const elements = newDate.split('-');
  const day = elements[0];
  const month = formatName(VARIABLEFIJAAPIDATE[elements[1].toLowerCase()]);
  const year = elements[2];
  return `${day} ${month} ${year}`;
};

export const modifyCookie = (cookieName, fieldName, value, jwt, maxAge) => {
  let cookie = getCookie(cookieName, !jwt);
  if (!cookie) {
    return false;
  }

  if (jwt) {
    cookie = jwt_decode(cookie);
  }

  cookie[fieldName] = value;

  if (jwt) {
    cookie = jwt_encode(cookie, envars('SECRETINNERJWT'));
  }

  const options = { path: '/' };
  maxAge && (options['max-age'] = maxAge);

  setCookie(cookieName, cookie, options);
};

export const getDecodedCookie = (name) => {
  const config = getCookie(name, false);
  const configDecoded = config ? jwt_decode(config) : null;
  return configDecoded;
};

export const envars = (variable) => {
  const name = `REACT_APP_${variable}`;
  return process.env[name];
};

export const getRedirectURIOnboarding = (cookiConfig) => {
  const rep = (toast, navigate) => {
    return { toast, navigate };
  };

  if (!cookiConfig.accountType) {
    return rep(
      'Debe seleccionar un tipo de cuenta',
      '/onboarding-digital/person-type'
    );
  } else {
    if (PERSONTYPE[cookiConfig.accountType.name].isLegal) {
      if (!cookiConfig.assistUnit) {
        return rep(
          'Debe seleccionar una unidad de asistencia',
          '/onboarding-digital/assistance-unit'
        );
      }
      if (!cookiConfig.branch) {
        return rep(
          'Debe seleccionar una sucursal',
          '/onboarding-digital/select-branch'
        );
      }
      if (
        CLIENTTYPE[
          cookiConfig.assistUnit.name
            .split(' ')
            [cookiConfig.assistUnit.name.split(' ').length - 1].toLowerCase()
        ].isBusiness &&
        !cookiConfig.businessRepresentative
      ) {
        return rep(
          'Debe seleccionar un representante de negocios',
          '/onboarding-digital/select-business-representative'
        );
      }
      if (
        cookiConfig.assistUnit.name.includes('Tesorería') &&
        !cookiConfig.subtype
      ) {
        return rep(
          'Debe seleccionar un sub-tipo de solicitante',
          '/onboarding-digital/sub-type'
        );
      }
      if (!cookiConfig.companyType) {
        return rep(
          'Debe seleccionar un tipo de empresa',
          '/onboarding-digital/company-type'
        );
      }
      if (!cookiConfig.identityVerification) {
        return rep(
          'Debe verificar su identidad',
          '/onboarding-digital/identity-verification'
        );
      }
    } else {
      if (!cookiConfig.branch) {
        return rep(
          'Debe seleccionar una sucursal',
          '/onboarding-digital/select-branch'
        );
      }
      if (!cookiConfig.identityVerification) {
        return rep(
          'Debe verificar su identidad',
          '/onboarding-digital/identity-verification'
        );
      }
    }
  }
};

export const readFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const base64toFile = (base64String, filename) => {
  const binaryString = base64String.split(',');
  const mime = binaryString[0].match(/:(.*?);/)[1];
  const bstr = Buffer.from(binaryString[1], 'base64');

  return new File([bstr], filename, { type: mime });
};

export const setFileManagerToken = async () => {
  const access_token = getCookie('FILE_MANAGER', false);
  if (!access_token) {
    const response = await axios.post(
      '/middleware/archivo-central/security/oauth/token',
      {},
      {
        params: { grant_type: 'client_credentials' },
      }
    );

    if (response.status === 200) {
      const { access_token, expires_in } = response.data;

      setCookie('FILE_MANAGER', access_token, { 'max-age': expires_in });
    }
  }
};

export const encode64 = (text) => {
  return Buffer.from(text).toString('base64');
};

export const decode64 = (text64) => {
  return Buffer.from(text64, 'base64').toString('utf-8');
};

export const mergeObjectsWithSameId = (array) => {
  const idMap = new Map();

  array.forEach((item) => {
    if (idMap.has(item.identification)) {
      const existingItem = idMap.get(item.identification);
      const mergedItem = { ...existingItem, ...item };
      idMap.set(item.identification, mergedItem);
    } else {
      idMap.set(item.identification, item);
    }
  });

  return Array.from(idMap.values());
};

export const comprexAddress = (address) => {
  return (address.street ?? '') +
    ' ' +
    (address.houseNumber ? '#' + address.houseNumber + ' ' : '') +
    (address.building ? address.building + ' ' : '') +
    (address.apartmentNumber ? address.apartmentNumber + ' ' : '') +
    (address.sector?.name ? address.sector?.name + ', ' : '') +
    (address.provincia?.label ? address.provincia?.label + ' ' : '') +
    (address.zipCode ? address.zipCode + ', ' : '') +
    (address.country?.name ?? '');
}

export const setErrorNotify = (message) => {
  toast(message, {
    type: 'error',
    theme: 'colored',
  });
};

export const setSuccessNotify = (message) => {
  toast(message, {
    type: 'success',
    theme: 'colored',
  });
};